import { ClientRoutes } from "@/data/enums/router";
import type { Component, CreateElement } from "vue";
// Components
import ClientDefaultLayout from "@/skeleton/client/loggedIn/defaultLayout.vue";
import ClientProductsBillboard from "@/components/app/client/contractProductsNeedsConfirmation.vue";

export default [
  {
    path: "dashboard",
    components: {
      default: {
        name: "ClientDashboardWrapper",
        render: (h: CreateElement) => h("router-view")
      } as Component,
      layout: {
        render: h =>
          h(ClientDefaultLayout, {
            scopedSlots: {
              billboard: () => h(ClientProductsBillboard)
            }
          })
      } as Component
    },
    meta: {},
    children: [
      {
        path: "",
        name: ClientRoutes.DASHBOARD,
        components: {
          default: () => import("@/views/client/dashboard/index.vue")
        },
        meta: {
          title: "_.dashboard",
          breadcrumb: {
            label: "_.dashboard"
          }
        }
      }
    ]
  }
];
