<template>
  <transition enter-active-class="fadeInDown" leave-active-class="fadeOut">
    <div
      v-show="isActive"
      class="snackbar u-notification is-top-right is-dark"
      :class="{ 'admin-u-notification': isAdmin }"
      @mouseover="$store.commit('ui/setNotificationIsFocused', true)"
      @mouseleave="$store.commit('ui/setNotificationIsFocused', false)"
      @click="markAsRead"
    >
      <u-object-router-link
        :class="{ 'is-unread': !status }"
        :object="$_.get(userNotification, 'object')"
        :object-type="$_.get(userNotification, 'object_type')"
        :object-brand-id="$_.get(userNotification, 'sender_brand_id')"
      >
        <div class="u-notification-body">
          <p>
            <pulse v-if="!status" class="has-margin-right-50" />
            <strong v-if="subject.length">{{ subject }}</strong>
            <b-icon
              icon="times"
              size="is-small"
              class="is-pulled-right"
              @click.native.stop.prevent="markAsRead"
            />
          </p>
          <div>
            <span>{{ body }}</span>
            <a
              v-if="hasMoreBody"
              class="read-more"
              @click.stop.prevent="readMore = true"
            >
              <strong>
                <u>{{ $t("_action.read_more").toLowerCase() }}</u>
              </strong>
            </a>
          </div>
        </div>
        <div class="u-notification-footer">
          <div>
            <a
              v-if="!status"
              class="u-notification-dismiss"
              @click.stop.prevent="markAsRead"
            >
              {{ $t("_action.dismiss") }}
            </a>
          </div>
          <datetime :datetime="dateCreated" class="u-notification-date" />
        </div>
      </u-object-router-link>
    </div>
  </transition>
</template>

<script lang="ts">
import Vue, { defineComponent } from "vue";
import { mapState } from "vuex";
import Context from "@/mixins/context";
import UserNotification from "@/components/app/global/userNotifications/userNotification.vue";
import { DataModules } from "@/store/modules/data/modules";
import { removeElement } from "buefy/src/utils/helpers";
import Snackbar from "buefy/src/components/snackbar/Snackbar.vue";
import type { IState } from "@/store";

const SnackbarProgrammatic = Vue.extend(Snackbar as unknown as Vue);

export default defineComponent({
  name: "UNotification",
  mixins: [UserNotification, SnackbarProgrammatic, Context],
  computed: {
    ...mapState({
      notificationIsFocused: (state: IState) => state.ui.notificationIsFocused
    })
  },
  methods: {
    shouldQueue() {
      return false;
    },
    markAsRead() {
      this.$store.dispatch(`data/${DataModules.USER_NOTIFICATIONS}/markRead`, {
        notification: this.userNotification,
        remove: true
      });

      this.close(true);
    },
    close(ignoreFocused = false) {
      clearTimeout(this.timer);

      if (this.notificationIsFocused && !ignoreFocused) {
        this.timer = setTimeout(() => this.close(), this.newDuration);
        return;
      }

      this.isActive = false;
      this.$emit("close");

      // Timeout for the animation complete before destroying
      setTimeout(() => {
        this.$destroy();
        removeElement(this.$el);
      }, 150);
    }
  }
});
</script>

<style lang="scss">
.notices.is-top {
  @include mobile {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
</style>

<style lang="scss" scoped>
.u-notification {
  display: block;
  margin: 0.5em 1rem 0 0;
  font-size: $size-7;
  padding: 0.75rem 1rem;

  @include mobile {
    margin-right: 0;
    border-radius: $radius;
  }

  &.admin-u-notification:first-of-type {
    margin-top: 1rem;
  }

  &:not(.admin-u-notification):first-of-type {
    margin-top: 7rem;
    @include mobile {
      margin-top: 1rem;
    }
  }

  .u-notification-body {
    color: $white;
    word-break: break-word;

    .read-more {
      color: $white;
    }
  }

  .u-notification-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.25em;

    .u-notification-date {
      font-size: $size-8;
      white-space: nowrap;
      color: $grey;
    }

    span.icon {
      color: $grey-light;
      transition: transform ease-out 0.12s;
    }
  }

  .u-notification-dismiss {
    color: $link;
    font-weight: $weight-semibold;
    text-decoration: underline;
  }
}
</style>
