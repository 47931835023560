import { DocCategories } from "@/data/enums/docs";
import type { Route } from "vue-router";
import { codeParam } from "@/data/filters/reports";

export default [
  {
    path: "insights",
    components: {
      default: {
        name: "AdminInsights",
        template: `<router-view />`
      },
      menu: () => import("@/components/base/MenuComponent.vue")
    },
    props: {
      menu: {
        routes: require("./menu").routes
      }
    },
    meta: {
      breadcrumb: {
        label: "_.insights"
      }
    },
    children: [
      {
        path: "",
        name: "adminInsights",
        redirect: { name: "monthlyReport" }
      },
      {
        path: "monthly-report",
        component: () => import("@/views/admin/insights/monthlyReport.vue"),
        name: "monthlyReport"
      },
      {
        path: "sales-listing",
        component: () => import("@/views/admin/insights/salesListing.vue"),
        children: [
          {
            path: "",
            name: "salesListing",
            component: () =>
              import(
                "@/components/app/global/invoices/invoicesProductsTable.vue"
              ),
            props: {
              id: "SL1"
            },
            meta: {
              categorySlug: DocCategories.USING_UPMIND,
              docsSlugs: ["reporting"]
            }
          }
        ]
      },
      {
        path: "tracked-referrals",
        name: "upmTrackedReferrals",
        component: () => import("@/views/admin/insights/trackedReferrals.vue"),
        props: {
          routes: require("./menu").upmTrackingTabigation
        },
        redirect: { name: "upmTrackedReferralsClients" },
        children: [
          {
            path: "clients",
            name: "upmTrackedReferralsClients",
            component: () =>
              import("@/components/app/admin/upm/trackedReferralsTable.vue"),

            props: () => ({
              id: "UPMTRS1",
              type: "signup"
            }),
            meta: {
              categorySlug: DocCategories.USING_UPMIND,
              docsSlugs: ["reporting"]
            }
          },
          {
            path: "orders",
            name: "upmTrackedReferralsOrders",
            component: () =>
              import("@/components/app/admin/upm/trackedReferralsTable.vue"),

            props: () => ({
              id: "UPMTRO1",
              type: "order"
            }),
            meta: {
              categorySlug: DocCategories.USING_UPMIND,
              docsSlugs: ["reporting"]
            }
          }
        ]
      },
      {
        path: "reports",
        name: "upmReports",
        redirect: { name: "upmReportsList" },
        components: {
          default: {
            name: "AdminInsightsReports",
            template: `<router-view />`
          }
        },
        meta: {
          requiresBrand: true
        },
        children: [
          {
            path: "list",
            name: "upmReportsList",
            component: () => import("@/views/admin/insights/reports/index.vue")
          },
          {
            path: `:reportCode`,
            name: "upmReportsReportOverview",
            component: () =>
              import("@/components/app/admin/insights/reportOverview.vue"),
            props: (route: Route) => ({
              reportCode: route.params.reportCode,
              routeFilters: () => ({
                ...codeParam(route.params.reportCode)
              })
            })
          }
        ]
      },
      {
        path: "mrr",
        component: () => import("@/views/admin/insights/monthlyRunRate.vue"),
        name: "monthlyRunRate",
        meta: {
          categorySlug: DocCategories.USING_UPMIND,
          docsSlugs: ["reporting"]
        }
      },
      {
        path: "stats",
        component: () => import("@/views/admin/insights/stats.vue"),
        name: "adminInsightsStats",
        meta: {
          categorySlug: DocCategories.USING_UPMIND,
          docsSlugs: ["reporting"]
        }
      },
      {
        path: "*",
        component: () => import("@/views/error/index.vue"),
        props: { code: 404 }
      }
    ]
  }
];
