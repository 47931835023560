import { RegexMatch } from "@/data/enums/router";
import { DocCategories } from "@/data/enums/docs";
import { QUERY_PARAMS } from "@/data/constants";
import type { Route } from "vue-router";
import _ from "@/boot/lodash";

function guardFromComplete(to, from, next) {
  if (from.name === "adminOrderComplete")
    return next({
      name: "adminOrderShop",
      params: { basketId: RegexMatch.NEW }
    });
  next();
}

export default [
  {
    path: "baskets",
    components: {
      default: {
        name: "AdminBaskets",
        template: `<router-view />`
      }
    },
    meta: {
      breadcrumb: {
        label: "_.baskets"
      }
    },
    children: [
      {
        path: "",
        name: "adminBaskets",
        component: () => import("@/views/admin/baskets/index.vue"),
        meta: {
          auth: ["staff_list_orders"],
          title: "_.baskets",
          backTo: "_.baskets",
          categorySlug: DocCategories.SETUP_CONFIGURATION,
          docsSlugs: ["orders-services"]
        }
      },
      {
        path: `:basketId(${RegexMatch.UUID}|${RegexMatch.NEW})`,
        name: "adminOrderBasket",
        meta: {
          auth: ["staff_get_order"],
          title: "_.basket",
          backTo: "_.basket"
        },
        component: () =>
          import("@/components/app/global/baskets/basket/index.vue"),
        props: (to: Route) => ({
          basketRoutes: require("./menu").default,
          routeClientId: to.query[QUERY_PARAMS.CLIENT_ID] || "",
          routeAccountId: to.query[QUERY_PARAMS.ACCOUNT_ID] || "",
          routeBasketId:
            to.query[QUERY_PARAMS.BASKET_ID] || to.params["basketId"] || "",
          quantity: _.toNumber(to.query[QUERY_PARAMS.QUANTITY]) || 1,
          billingTerm:
            _.toNumber(to.query[QUERY_PARAMS.BILLING_CYCLE_MONTHS]) || 0,
          routeOrderId: to.query[QUERY_PARAMS.ORDER_ID] || "",
          routeCrossSell: !!to.query[QUERY_PARAMS.CROSS_SELL_PRODUCT] || false,
          routeReadMore: !!to.query[QUERY_PARAMS.READ_MORE] || false,
          routeCurrencyCode: _.upperCase(
            _.toString(to.query[QUERY_PARAMS.CURRENCY_CODE] || "")
          ),
          routeCoupons: to.query[QUERY_PARAMS.COUPONS] || ""
        }),
        children: [
          {
            path: "shop",
            name: "adminOrderShop",
            component: () =>
              import("@/components/app/global/baskets/basket/shop/index.vue"),
            meta: {
              title: "_.shop"
            }
          },
          {
            path: "product",
            name: "adminOrderProductView",
            component: () =>
              import(
                "@/components/app/global/baskets/basket/productView/index.vue"
              ),
            meta: {
              title: "_.product"
            },
            beforeEnter: guardFromComplete
          },
          {
            path: "basket",
            name: "adminOrderBasketView",
            component: () =>
              import(
                "@/components/app/global/baskets/basket/basketView/index.vue"
              ),
            meta: {
              title: "_.basket"
            },
            beforeEnter: guardFromComplete
          },
          {
            path: "checkout",
            name: "adminOrderCheckout",
            component: () =>
              import(
                "@/components/app/global/baskets/basket/checkout/index.vue"
              ),
            meta: {
              title: "_.checkout"
            },
            beforeEnter: guardFromComplete
          },
          {
            path: "complete",
            name: "adminOrderComplete",
            component: () =>
              import(
                "@/components/app/global/baskets/basket/complete/index.vue"
              ),
            meta: {
              title: "_.complete"
            }
          }
        ]
      },
      {
        path: "403",
        component: () => import("@/views/error/index.vue"),
        meta: { error: 403 }
      },
      {
        path: "*",
        component: () => import("@/views/error/index.vue"),
        meta: { error: 404 }
      }
    ]
  }
];
