import type { IFilter } from "@/models/tables";
import { FilterOperators, FilterTypes } from "@/data/table";
import i18n from "@/i18n";
import { DataModules } from "@/store/modules/data/modules";
import _ from "@/boot/lodash";
import store from "@/store";
import { TemplateTypes } from "../enums/templates";

export const emailTypeId = () => ({
  "filter[sent]": true
});

export const IsSentParam = () => ({
  "filter[sent]": true,
  "filter[bounced]": false
});

export const IsBouncedParam = () => ({
  "filter[bounced]": true
});

export const IsFailedParam = () => ({
  "filter[error_id|neq]": "null"
});

const emailsFilters = (config: any = {}): IFilter[] => {
  return [
    {
      key: "subject",
      label: i18n.t("_.subject"),
      type: FilterTypes.STRING,
      operator: FilterOperators.CONTAINS
    },
    {
      key: "created_at",
      label: i18n.t("_.created"),
      type: FilterTypes.DATE,
      operator: FilterOperators.EQUAL
    },
    {
      key: "recipient.firstname",
      label: i18n.t("_.firstname"),
      type: FilterTypes.STRING,
      operator: FilterOperators.CONTAINS
    },
    {
      key: "recipient.lastname",
      label: i18n.t("_.lastname"),
      type: FilterTypes.STRING,
      operator: FilterOperators.CONTAINS
    },
    {
      key: "recipient.email",
      label: i18n.t("_.email"),
      type: FilterTypes.STRING,
      operator: FilterOperators.CONTAINS
    },
    {
      if: () => !config.templateId,
      key: "template_id",
      label: i18n.t("_.template"),
      type: FilterTypes.SELECT_MULTI,
      operator: FilterOperators.EQUAL,
      optionsFunc: async () => {
        const templates = await store
          .dispatch(`data/${DataModules.TEMPLATES}/list`, {
            storeData: false,
            params: {
              "filter[type]": TemplateTypes.NOTIFICATION,
              limit: 0
            }
          })
          .catch(err => err);

        return _.map(templates || [], template => ({
          id: template.id,
          label: template.name
        }));
      }
    }
  ];
};

export default emailsFilters;
