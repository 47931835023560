import type { IDataState } from "@/store/modules/data";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import type { ApiPathGetter } from "@/models/api";
import { DataModules } from "@/store/modules/data/modules";
import { Methods } from "@/models/methods";
import { ConfirmModalModes } from "@/data/enums";
import i18n from "@/i18n";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath: (): ApiPathGetter => () => {
    const admin = `api/admin/spam_conditions`;
    return { admin };
  },
  listScope: () => () => {
    return "$spamConditions";
  }
};

export const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath().admin,
        storeModule: DataModules.SPAM_CONDITIONS
      },
      { root: true }
    );
  },
  create: ({ dispatch, getters }, { data }) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.POST,
        path: getters.apiPath().admin,
        storeModule: DataModules.SPAM_CONDITIONS,
        requestConfig: { data }
      },
      { root: true }
    );
  },
  update: ({ dispatch, getters }, { id, data }) => {
    return dispatch(
      "data/update",
      {
        path: `${getters.apiPath().admin}/${id}`,
        storeModule: DataModules.SPAM_CONDITIONS,
        data
      },
      { root: true }
    );
  },
  remove: ({ dispatch, getters }, { id }) => {
    return dispatch(
      "data/remove",
      {
        path: `${getters.apiPath().admin}/${id}`,
        storeModule: DataModules.SPAM_CONDITIONS
      },
      { root: true }
    );
  },
  deleteSpamCondition: async ({ dispatch }, { id, onSuccess }) => {
    const confirm = await dispatch(
      "ui/open/confirmModal",
      {
        config: {
          props: {
            autoClose: false,
            mode: ConfirmModalModes.PIN,
            message: i18n.t("_sentence.delete_spam_condition")
          },
          canCancel: [],
          events: {
            confirmed: async () => {
              try {
                await dispatch("remove", { id });

                dispatch(
                  "toast/show",
                  { message: i18n.t("_.spam_condition_successfully_removed") },
                  { root: true }
                );

                onSuccess();
                confirm.close();
              } catch (error) {
                dispatch("api/handleError", error, { root: true });
              }
            }
          }
        }
      },
      { root: true }
    );
  },
  openManageAdvancedSpamRuleModal: ({ dispatch }, payload) => {
    dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/global/tickets/manageAdvancedSpamRuleModal.vue"
            ),
          ...payload
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
