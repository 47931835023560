import { AdminRoutes, RegexMatch } from "@/data/enums/router";
import type { Route } from "vue-router";
import { DefaultCProdFilters } from "@/data/filters/contractProducts";
import type { IFilter } from "@/models/tables";
import type { CreateElement } from "vue";
import ContractProductsComponent from "@/views/admin/contractProducts/index.vue";
import _ from "lodash";
import { OrgFeatureKeys } from "@/data/constants";

let defaultCProdFiltersCache: IFilter[] | null = null;

const binDefaultCProdFilters = () => (defaultCProdFiltersCache = null);

export const getDefaultCProdFilters = () => {
  if (!defaultCProdFiltersCache)
    defaultCProdFiltersCache = DefaultCProdFilters();
  return defaultCProdFiltersCache;
};

export default [
  {
    path: "products/bulk-actions",
    component: {
      name: "AdminProductBulkActions",
      template: `<router-view />`
    },
    meta: {
      requiredFeatureKeys: [OrgFeatureKeys.BULK_NOTIFICATIONS_ENABLED]
    },
    redirect: { name: "adminProductBulkNotifications" },
    children: [
      {
        path: "",
        name: "adminProductBulkNotifications",
        component: () =>
          import("@/views/admin/contractProducts/bulkActions/index.vue"),
        meta: {
          auth: ["list_notification_channels"]
        }
      },
      {
        path: `:bulkActionId(${RegexMatch.UUID})`,
        name: "adminProductBulkActionsEdit",
        redirect: { name: "adminCProdsBulkActionOverview" },
        component: () =>
          import(
            "@/views/admin/contractProducts/bulkActions/bulkAction/index.vue"
          ),
        props: (to: Route) => {
          return {
            bulkActionId: to.params.bulkActionId,
            routes: require("./menu").bulkActionTabigation
          };
        },
        children: [
          {
            path: "overview",
            name: "adminCProdsBulkActionOverview",
            component: () =>
              import(
                "@/views/admin/contractProducts/bulkActions/bulkAction/overview/index.vue"
              )
          },
          {
            path: "logs",
            name: "adminCProdsBulkActionLogs",
            component: () =>
              import(
                "@/views/admin/contractProducts/bulkActions/bulkAction/logs/index.vue"
              )
          },
          {
            path: "activity-log",
            name: "adminCProdsBulkActionActivityLog",
            component: () =>
              import(
                "@/views/admin/contractProducts/bulkActions/bulkAction/activityLog/index.vue"
              )
          }
        ]
      }
    ]
  },
  {
    path: "products/bulk-updates",
    name: AdminRoutes.CPROD_BULK_UPDATES,
    component: () =>
      import("@/views/admin/contractProducts/scheduledActions/index.vue"),
    meta: {
      auth: ["list_contract_product_scheduled_actions"],
      requiresBrand: true
    }
  },
  {
    path: "products",
    name: "adminProductsAndServices",
    redirect: { name: "adminCProds" },
    component: {
      name: "AdminProductsAndServices",
      render(h: CreateElement) {
        return h(ContractProductsComponent, {
          props: {
            routes: require("./menu").contractProductsTabigation
          }
        });
      },
      beforeRouteLeave(to, from, next) {
        binDefaultCProdFilters();
        next();
      }
    },
    children: _.concat(
      require("./subscriptions").default,
      require("./otps").default,
      /**
       * Important! Must be injected after OTP and Subscriptions
       */
      require("./all").default
    )
  }
];
