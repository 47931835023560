import { AdminRoutes } from "@/data/enums/router";
import type { Route } from "vue-router";

export default [
  {
    path: "",
    name: "admin__clientSettings",
    redirect: { name: AdminRoutes.CLIENT_SETTINGS_PROFILE },
    component: () => import("@/views/admin/clients/client/settings/index.vue"),
    props: {
      routes: require("./menu").clientSettingsOverview
    },
    meta: {
      title: "_.settings"
    },
    children: [
      {
        path: "profile",
        name: AdminRoutes.CLIENT_SETTINGS_PROFILE,
        component: () =>
          import("@/views/admin/clients/client/profile/index.vue"),
        meta: {
          title: "_.profile",
          breadcrumb: {
            label: "_action.edit"
          }
        }
      },
      {
        path: "security",
        name: AdminRoutes.CLIENT_SETTINGS_SECURITY,
        component: () =>
          import("@/views/admin/clients/client/security/index.vue"),
        meta: {
          title: "_.security",
          breadcrumb: {
            label: "_action.edit"
          }
        }
      },
      {
        path: "billing-settings",
        name: AdminRoutes.CLIENT_SETTINGS_BILLING,
        component: () =>
          import("@/views/admin/clients/client/billing/settings/index.vue"),
        meta: {
          title: "_.billing",
          breadcrumb: {
            label: "_action.edit"
          }
        }
      },
      {
        path: "notifications",
        name: AdminRoutes.CLIENT_NOTIFICATIONS,
        component: () =>
          import(
            "@/views/admin/clients/client/settings/notifications/index.vue"
          ),
        props: (route: Route) => ({
          clientId: route.params?.clientId
        }),
        meta: {
          title: "_.notifications",
          breadcrumb: {
            label: "_action.edit"
          }
        }
      }
    ]
  }
];
