export enum ISO_4217_CURRENCY_CODE {
  /** United Arab Emirates Dirham */
  AED = "AED",
  /** Angolan Kwanza */
  AOA = "AOA",
  /** Australian Dollar */
  AUD = "AUD",
  /** Azerbaijani Manat */
  AZN = "AZN",
  /** Bangladeshi Taka */
  BDT = "BDT",
  /** Bulgarian Lev */
  BGN = "BGN",
  /** Brazilian Real */
  BRL = "BRL",
  /** Canadian Dollar */
  CAD = "CAD",
  /** Swiss Franc */
  CHF = "CHF",
  /** Chilean Peso */
  CLP = "CLP",
  /** Yuan Renminbi */
  CNY = "CNY",
  /** Colombian Peso */
  COP = "COP",
  /** Czech Koruna */
  CZK = "CZK",
  /** Danish Krone */
  DKK = "DKK",
  /** Algerian Dinar */
  DZD = "DZD",
  /** Egyptian Pound */
  EGP = "EGP",
  /** Ethiopian Birr */
  ETB = "ETB",
  /** Euro */
  EUR = "EUR",
  /** Fijian Dollar */
  FJD = "FJD",
  /** British Pound */
  GBP = "GBP",
  /** Ghanaian Cedi */
  GHS = "GHS",
  /** Hong Kong Dollar */
  HKD = "HKD",
  /** Hungarian Forint */
  HUF = "HUF",
  /** Indonesian Rupiah */
  IDR = "IDR",
  /** Israeli New Shekel */
  ILS = "ILS",
  /** Indian Rupee */
  INR = "INR",
  /** Icelandic Króna */
  ISK = "ISK",
  /** Japanese Yen */
  JPY = "JPY",
  /** Kenyan Shilling */
  KES = "KES",
  /** Moroccan Dirham */
  MAD = "MAD",
  /** Maldivian Rufiyaa */
  MVR = "MVR",
  /** Mexican Peso */
  MXN = "MXN",
  /** Malaysian Ringgit */
  MYR = "MYR",
  /** Mozambican Metical */
  MZN = "MZN",
  /** Nigerian Naira */
  NGN = "NGN",
  /** Norwegian Krone */
  NOK = "NOK",
  /** Nepalese Rupee */
  NPR = "NPR",
  /** New Zealand Dollar */
  NZD = "NZD",
  /** Pakistani Rupee */
  PKR = "PKR",
  /** Polish Złoty */
  PLN = "PLN",
  /** Paraguayan Guarani */
  PYG = "PYG",
  /** Romanian Leu */
  RON = "RON",
  /** Russian Ruble */
  RUB = "RUB",
  /** Rwandan Franc */
  RWF = "RWF",
  /** Swedish Krona */
  SEK = "SEK",
  /** Singapore Dollar */
  SGD = "SGD",
  /** Thai Baht */
  THB = "THB",
  /** Turkish Lira */
  TRY = "TRY",
  /** Trinidad and Tobago Dollar */
  TTD = "TTD",
  /** New Taiwan Dollar */
  TWD = "TWD",
  /** Tanzanian Shilling */
  TZS = "TZS",
  /** Ukrainian Hryvnia */
  UAH = "UAH",
  /** Ugandan Shilling */
  UGX = "UGX",
  /** US Dollar */
  USD = "USD",
  /** Central African CFA Franc */
  XAF = "XAF",
  /** West African CFA Franc */
  XOF = "XOF",
  /** South African Rand */
  ZAR = "ZAR"
}
