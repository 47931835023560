import type { IFilter } from "@/models/tables";
import { FilterOperators, FilterTypes } from "@/data/table";
import i18n from "@/i18n";
import { CreatedAtFilter } from "@/data/filters/object";

export const NameFilter: () => IFilter = () => ({
  key: "name",
  label: i18n.t("_.name"),
  type: FilterTypes.STRING,
  operator: FilterOperators.CONTAINS
});

export const DefaultFilters = () => [NameFilter(), CreatedAtFilter()];

export default DefaultFilters;
