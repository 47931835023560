import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import type { ApiPathGetter } from "@/models/api";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext, isMockClientContext }): ApiPathGetter =>
    () => {
      const admin = `api/admin/brand/tickets/departments`;
      const client = `api/brand/tickets/departments`; // check
      const contextual =
        isAdminContext && !isMockClientContext() ? admin : client;
      return { client, admin, contextual };
    }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath().contextual,
        storeModule: DataModules.SUPPORT_DEPARTMENTS_BRAND
      },
      { root: true }
    );
  },

  update: ({ dispatch, getters }, { supportDepartmentId, data }) => {
    return dispatch(
      "data/update",
      {
        path: `${getters.apiPath().admin}/${supportDepartmentId}`,
        storeModule: DataModules.SUPPORT_DEPARTMENTS_BRAND,
        data
      },
      { root: true }
    );
  },

  assign: ({ dispatch, getters }, { data }) => {
    return dispatch(
      "data/create",
      {
        path: getters.apiPath().admin,
        storeModule: DataModules.SUPPORT_DEPARTMENTS_BRAND,
        data
      },
      { root: true }
    );
  },

  unassign: ({ dispatch, getters }, { supportDepartmentId, brandId }) => {
    return dispatch(
      "data/remove",
      {
        path: `${getters.apiPath().admin}/${supportDepartmentId}`,
        storeModule: DataModules.SUPPORT_DEPARTMENTS_BRAND,
        data: {
          brand_id: brandId
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
