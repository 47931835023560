import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import type { ApiPathGetter } from "@/models/api";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath: (): ApiPathGetter => () => {
    const admin = `api/admin/affiliate_tier`;
    return { admin };
  },
  scope:
    () =>
    ({ tierId }) => {
      return `$affiliate_tier_${tierId}`;
    }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath().admin,
        storeModule: DataModules.AFFILIATES_TIERS
      },
      { root: true }
    );
  },
  get: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload.id}`,
        storeModule: DataModules.AFFILIATES_TIERS
      },
      { root: true }
    );
  },
  create: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/create",
      {
        ...payload,
        path: getters.apiPath().admin,
        storeModule: DataModules.AFFILIATES_TIERS
      },
      { root: true }
    );
  },
  update: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/update",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload.id}`,
        storeModule: DataModules.AFFILIATES_TIERS
      },
      { root: true }
    );
  },
  remove: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/remove",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload.id}`,
        storeModule: DataModules.AFFILIATES_TIERS
      },
      { root: true }
    );
  },
  openAddEditModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          width: 540,
          canCancel: ["button", "x"],
          component: () =>
            import(
              "@/components/app/admin/settings/affiliates/tiers/addEditAffiliateTierModal.vue"
            ),
          ...payload
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  modules: {
    conditions: require("./tierConditions").default
  },
  getters,
  actions
};
