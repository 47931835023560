import type { ActionTree } from "vuex";
import { ToastProgrammatic as Toast } from "buefy";
import type { IState } from "@/store";

const defaultConfig = {
  message: "",
  position: "is-bottom",
  type: "is-dark",
  queue: false
};

const actions: ActionTree<IState, IState> = {
  show: (context, options) => {
    const config = { ...defaultConfig, ...options };
    Toast.open(config);
  },

  info: ({ dispatch }, options) => {
    dispatch("show", { ...options, type: "is-info" });
  },

  warning: ({ dispatch }, options) => {
    dispatch("show", { ...options, type: "is-warning" });
  },

  error: ({ dispatch }, options) => {
    dispatch("show", { ...options, type: "is-danger" });
  }
};

export default {
  namespaced: true,
  actions
};
