import type { ISorter } from "@/models/tables";
import i18n from "@/i18n";

export const CreatedAtSorter: () => ISorter = () => ({
  field: "created_at",
  label: i18n.t("_.date_created").toString()
});

export const UpdatedAtSorter: () => ISorter = () => ({
  field: "updated_at",
  label: i18n.t("_.last_updated").toString()
});

export const DefaultSorters: () => ISorter[] = () => [
  CreatedAtSorter(),
  UpdatedAtSorter()
];
