import * as Sentry from "@sentry/vue";
import router from "@/router";
import $store from "@/store";
import _ from "@/boot/lodash";
import type { EventHint } from "@sentry/browser";

const IgnoredSources = [
  "https://cdn.mouseflow.com/",
  "https://www.googletagmanager.com/gtag/js"
];

function isFromIgnoredSource(exception: EventHint["originalException"]) {
  const matches = `${_.get(exception, "stack")}`.match(
    new RegExp(`at (${IgnoredSources.join("|")})`, "g")
  );
  if (matches && matches.length) return true;
  return false;
}

function isIgnoredRouterError(error: EventHint["originalException"]): boolean {
  if (!_.get(error, "_isRouter")) return false;
  const ignoreList = ["NavigationDuplicated", "NavigationCancelled"];
  if (ignoreList.includes(_.get(error, "name"))) return true;
  return false;
}

export default {
  install: (Vue: any) => {
    const SENTRY_ENV =
      import.meta.env.VITE_APP_SENTRY_ENV || import.meta.env.NODE_ENV;
    if (["development", "{ENV}", undefined].includes(SENTRY_ENV)) return;

    function getAccessToken(): string {
      const state = _.get($store, "state");
      const context = _.get(state, "context");
      return _.get(state, `auth.${context}.token.access_token`);
    }

    function setUserJti(event) {
      const access_token = getAccessToken();
      if (access_token) {
        const jwt_payload = access_token.split(".")[1];
        const jwt_payload_decoded = JSON.parse(atob(jwt_payload));
        const jti = _.get(jwt_payload_decoded, "jti");
        _.set(event, "user.jti", jti);
      }
    }

    Sentry.init({
      Vue,
      dsn: import.meta.env.VITE_APP_SENTRY_DSN,
      // integrations: [new VueIntegration({ Vue, attachProps: true })],
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration()
      ],
      trackComponents: true,
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 0.2, // Capture Replay for 100% of sessions with an error
      ignoreErrors: [
        /^ResizeObserver.*$/i, // Ignore any errors starting with ResizeObserver
        /^Request failed with status code 403$/i,
        /^Network Error$/i,
        /^Request aborted$/i
      ],
      release: "upmind@" + import.meta.env.RELEASE,
      environment: SENTRY_ENV,
      denyUrls: [
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i
      ],
      beforeSend(event, hint) {
        if (isFromIgnoredSource(hint?.originalException)) return null;
        if (isIgnoredRouterError(hint?.originalException)) return null;
        try {
          setUserJti(event);
        } catch (err) {
          // Silently catch error
        }
        return event;
      }
    });
  }
};
