import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import _ from "@/boot/lodash";
import type { ApiPathGetter } from "@/models/api";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath: (): ApiPathGetter => () => {
    const admin = `api/admin/bulk_actions`;
    return { admin };
  },
  scope: () => id => {
    return `$bulkActionResults_${id}`;
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    if (!_.has(payload, "id")) throw new Error("Bulk action id not provided");
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload?.id}/results`,
        storeModule: DataModules.BULK_ACTIONS_RESULTS
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
