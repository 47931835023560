<script lang="ts">
import { defineComponent } from "vue";
import { submitProgrammaticForm } from "@/helpers/forms";

export default defineComponent({
  name: "ExternalAuthHandler",
  methods: {
    /**
     * @name redirectToExternalUrl
     * @desc Here we redirect to an external URL (eg Stripe) and intentionally do
     * NOT resolve the function promise, ensuring the payment processing state
     * remains unchanged whilst the page offloads
     */
    redirectToExternalUrl({ url, method, fields = {} }) {
      if (!fields) fields = {};
      return new Promise<void>(() => {
        url = new URL(url);
        url.searchParams.forEach((v, k) => (fields[k] = v));
        submitProgrammaticForm({
          url: [url.origin, url.pathname].join(""),
          method,
          fields
        });
      });
    },
    /**
     * @name submitProgrammaticForm
     * @desc Here we programmatically create, insert and submit a new form element
     * so we can reliably hand-off to third party origins without encountering any
     * cross-origin (CORS) issues.
     */
    submitProgrammaticForm({ url, method = "GET", fields = {} }) {
      const form = document.createElement("form");
      form.target = "_self";
      form.method = method;
      form.action = url;
      form.style.display = "none";

      for (const key in fields) {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = fields[key];
        form.appendChild(input);
      }

      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form);
    }
  }
});
</script>
