import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import { Methods } from "@/models/methods";
import _ from "@/boot/lodash";
import type { ApiPathGetter } from "@/models/api";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext, isMockClientContext }): ApiPathGetter =>
    () => {
      const admin = `api/admin/ticket_messages/files`;
      const client = `api/ticket_messages/files`;
      const contextual =
        isAdminContext && !isMockClientContext() ? admin : client;
      return { client, admin, contextual };
    }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, rootGetters }, payload) => {
    const { ticketId } = payload;
    return dispatch(
      "data/list",
      {
        ...payload,
        path: rootGetters.isAdminContext
          ? `api/admin/tickets/${ticketId}/files`
          : `api/tickets/${ticketId}/files`,
        storeModule: DataModules.TICKETS_FILES
      },
      { root: true }
    );
  },
  uploadFile: async ({ dispatch, commit, getters }, payload) => {
    const file = await dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.POST,
        path: getters.apiPath().contextual,
        storeModule: DataModules.TICKETS_FILES,
        requestConfig: _.merge(
          { data: payload.data },
          _.get(payload, "onUploadProgress")
            ? { onUploadProgress: payload.onUploadProgress }
            : {},
          _.has(payload, "handle401Error")
            ? { handle401Error: payload.handle401Error }
            : {}
        )
      },
      { root: true }
    );

    if (_.has(payload, "scope")) {
      await commit(
        `data/replaceData`,
        {
          data: file,
          scope: _.get(payload, "scope"),
          storeModule: DataModules.TICKETS_FILES
        },
        { root: true }
      );
    }

    return file;
  },
  download: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.GET,
        returnData: false,
        path: `${getters.apiPath().contextual}/${payload.fileId}/download`,
        storeModule: DataModules.TICKETS_FILES,
        requestConfig: { params: payload.params, responseType: "arraybuffer" }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
