<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <columns class="is-marginless">
        <column class="is-paddingless">
          <span class="modal-card-title">{{ title }}</span>
        </column>
        <column v-if="progress !== null" class="is-paddingless">
          <b-progress :type="progressType" :value="progress"></b-progress>
        </column>
      </columns>
    </header>
    <section class="modal-card-body">
      <slot />
    </section>
    <footer class="modal-card-foot has-content-justified-between">
      <button
        v-if="canCancelByButton"
        :disabled="isProcessing"
        type="button"
        class="button is-light"
        tabindex="-1"
        @click="parentModal.cancel('button')"
      >
        {{ $t(cancelText) }}
      </button>
      <span v-else />
      <slot name="footer" />
    </footer>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "SlideModalCard",
  props: {
    blockCancellation: { type: Boolean, default: false },
    title: { type: String, required: true },
    progressType: { type: String, default: "is-dark" },
    progress: { type: Number, default: null },
    isProcessing: { type: Boolean, default: false },
    cancelText: { type: String, default: "_action.cancel" },
    type: { type: String, default: null }
  },
  computed: {
    canCancelByButton() {
      if (!this.parentModal) {
        return false;
      }
      const cancelOptions = this.parentModal.cancelOptions;
      return cancelOptions && cancelOptions.includes("button");
    },
    parentModal() {
      return this.getParentModal(this);
    }
  },
  watch: {
    blockCancellation: { handler: "onBlockCancellation", immediate: true }
  },
  methods: {
    onBlockCancellation(newVal: boolean) {
      if (
        this.$_.isFunction(
          this.$_.get(this, "parentModal.setBlockCancellation")
        )
      ) {
        this.parentModal.setBlockCancellation(newVal);
      }
    },
    bubbleEmit(event, detail) {
      try {
        this.$el.dispatchEvent(new CustomEvent(event, { detail }));
      } catch (e) {
        // handle IE not supporting Event constructor
        const evt = document.createEvent("CustomEvent");
        evt.initCustomEvent(event, true, false, detail);
        this.$el.dispatchEvent(evt);
      }
    },
    getParentModal(vue) {
      if (!vue.$parent) {
        return vue;
      }
      return ["SlideModal"].includes(
        this.$_.get(vue, "constructor.options.name")
      )
        ? vue
        : this.getParentModal(vue.$parent);
    }
  }
});
</script>

<style lang="scss" scoped>
.modal-card {
  height: 100vh;
  display: flex;
  flex-direction: column;
  .modal-card-head {
    border-radius: 0px;
    background-color: $primary;
    background-color: var(--brand-color);
    .modal-card-title {
      color: $primary-invert;
      color: var(--brand-color-contrast);
    }
    .columns {
      width: 100%;
    }
  }
  .modal-card-foot {
    border-radius: 0px;
  }
}
</style>
