import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionContext, ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import type { ApiPathGetter } from "@/models/api";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  scope: () => id => {
    return `$stat_${id}`;
  },
  apiPath:
    (s, g, rS, { isAdminContext, isMockClientContext }): ApiPathGetter =>
    () => {
      const admin = `api/admin/stats`;
      const client = `api/stats`;
      const contextual =
        isAdminContext && !isMockClientContext() ? admin : client;
      return { client, admin, contextual };
    }
};

const actions: ActionTree<IDataState, IState> = {
  get: ({ dispatch, getters }: ActionContext<IDataState, IState>, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath().contextual}`,
        storeModule: DataModules.STATS
      },
      { root: true }
    );
  },
  openDateRangeModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/windowModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/global/stats/charts/selectRangeAndGroupModal.vue"
            ),
          width: 940,
          canCancel: ["escape", "outside"],
          ...payload.config
        }
      },
      {
        root: true
      }
    );
  },
  openStatSingleDateModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/windowModal",
      {
        config: {
          component: () =>
            import("@/components/app/global/date/selectDateModal.vue"),
          width: 440,
          canCancel: ["escape", "outside"],
          ...payload.config
        }
      },
      {
        root: true
      }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
