import type { ApiPathGetter } from "@/models/api";
import type { IState } from "@/store";
import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (): ApiPathGetter =>
    ({ templateId }) => {
      const admin = `api/admin/templates/${templateId}/contents`;
      return { admin };
    },
  scope: () => id => {
    return `$templateContent_${id}`;
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath(payload).admin,
        storeModule: DataModules.TEMPLATES_CONTENTS
      },
      { root: true }
    );
  },
  get: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath(payload).admin}/${payload?.id}`,
        storeModule: DataModules.TEMPLATES_CONTENTS
      },
      { root: true }
    );
  },
  create: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/create",
      {
        ...payload,
        path: getters.apiPath(payload).admin,
        storeModule: DataModules.TEMPLATES_CONTENTS
      },
      { root: true }
    );
  },
  update: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/update",
      {
        ...payload,
        path: `${getters.apiPath(payload).admin}/${payload?.id}`,
        storeModule: DataModules.TEMPLATES_CONTENTS
      },
      { root: true }
    );
  },
  remove: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/remove",
      {
        ...payload,
        path: `${getters.apiPath(payload).admin}/${payload?.id}`,
        storeModule: DataModules.TEMPLATES_CONTENTS
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
