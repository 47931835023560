import type { IDataState } from "@/store/modules/data";
import { DEFAULT_LIST_SCOPE } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import { ProductTypes } from "@/data/enums/products";
import type { ActionTree, GetterTree } from "vuex";
import type { ActionContext } from "vuex";
import type { IState } from "@/store";
import { Methods } from "@/models/methods";
import i18n from "@/i18n";
import _ from "@/boot/lodash";
import type { IProductPrice } from "@/models/products";
import type { IAvailableTerm } from "@/models/providers/products";
import type { TranslateResult } from "vue-i18n";
import type { IBrand } from "@upmind-automation/types";
import type { IProduct, IProductCategory } from "@/models/products";
import { AppHookCodes } from "@/data/enums/hooks";
import type { ApiPathGetter } from "@/models/api";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext, isMockClientContext }): ApiPathGetter =>
    (slug = "products") => {
      const admin = `api/admin/${slug}`;
      const client = `api/${slug}`;
      const contextual =
        isAdminContext && !isMockClientContext() ? admin : client;
      return { client, admin, contextual };
    },
  scope: () => (id: IProduct["id"]) => {
    return `$product_${id}`;
  },
  allProducts: () => {
    return `$all_products`;
  },
  productParams: (state: IDataState, getters) => (id: IProduct["id"]) => {
    return _.get(state, `${getters.scope(id)}.params`, {});
  },
  productsScope:
    () =>
    (
      brand_id: IBrand["id"],
      categoryId: IProductCategory["id"],
      productTypes: ProductTypes[]
    ) => {
      const productType: string = productTypes.includes(
        ProductTypes.PRODUCT_OPTION
      )
        ? "options"
        : productTypes.includes(ProductTypes.PRODUCT_ATTRIBUTE)
        ? "attributes"
        : "products";

      return `$${productType}_${categoryId}_${brand_id}`;
    },
  attachedProductsScope:
    () => (productId: IProduct["id"], productType: number) => {
      return `$product_${productId}_attached_${
        productType === ProductTypes.PRODUCT_OPTION ? "options" : "attributes"
      }`;
    },
  relatedProductsScope: () => (productId: IProduct["id"]) => {
    return `$product_${productId}_related`;
  },
  listParams: (state: IDataState) => () => {
    return _.get(state, `${DEFAULT_LIST_SCOPE}.params`, {});
  },
  warningMessages: (state: IDataState, getters) => (id: IProduct["id"]) => {
    return _.get(state, `${getters.scope(id)}.messages`, null);
  },
  productTermFromPrice:
    (state, getters, rootState, rootGetters) =>
    (
      term: IAvailableTerm,
      asLMFP: boolean // asLMFP = as "Lowest Monthly From Price"
    ): TranslateResult => {
      const fromPrice = asLMFP
        ? _.get(term, "monthly_price_from_discounted_formatted") ||
          _.get(term, "monthly_price_from_formatted")
        : _.get(term, "price_discounted_formatted") ||
          _.get(term, "price_formatted");

      return i18n.tc(
        "_plurals.from_price",
        term.billing_cycle_months
          ? asLMFP
            ? 1
            : term.billing_cycle_months
          : 0,
        {
          price: rootGetters["utils/money/trimTrailingZeros"](fromPrice),
          term: rootGetters["constants/getBillingCycleName"](
            term.billing_cycle_months
          ).toLowerCase()
        }
      );
    },
  productTermSavings: () => (term: IProductPrice) => {
    const { price, price_discounted, promotions = [] } = term;
    const coupons = _.map(promotions, promo => `'${promo.code}'`);
    const discountedPrice = _.isNil(price_discounted)
      ? price
      : price_discounted;
    const saving = ((price - discountedPrice) / price) * 100;
    const saving_formatted = `${Math.round(saving)}%`;
    return {
      saving,
      saving_formatted,
      saving_summary: saving
        ? i18n.tc("_plurals.coupon_saving", coupons.length, {
            saving: saving_formatted,
            coupons: i18n.tc("_plurals.coupon", coupons.length, {
              coupon: coupons.shift(),
              coupons: coupons.join(", ")
            })
          })
        : null
    };
  },
  withRelatedScope: () => (id: IProduct["id"]) => {
    return `$product_${id}_related`;
  },
  getTotal: (state: IDataState) => () => {
    return _.get(state, `${DEFAULT_LIST_SCOPE}.total`, 0);
  },
  entity:
    () =>
    (product: IProduct): string => {
      return (
        product?.provision_blueprint?.service_name ||
        i18n.t("_.product").toString().toLocaleLowerCase()
      );
    }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    const { isBasketContext } = payload;
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath(isBasketContext ? "basket/products" : "products")
          .contextual,
        storeModule: DataModules.CATALOGUE
      },
      { root: true }
    );
  },
  get: ({ dispatch, getters }, payload) => {
    const { isBasketContext } = payload;
    payload.params = _.merge(
      {
        with: [
          "allowed_migrations",
          "allowed_migrations.migration_product",
          "allowed_migrations.migration_product.image",
          "allowed_migrations.migration_product.prices",
          "allowed_migrations.migration_product.provision_category",
          "allowed_migrations.migration_product.provision_provider",
          "allowed_migrations.migration_product.provision_provider.logos",
          "allowed_migrations.migration_product.provision_configuration",
          `category${".top_category".repeat(4)}`,
          "image",
          "images",
          "import.credentials",
          "import.source",
          "prices",
          "products_attributes",
          "products_options",
          "products_options.prices",
          "provision_blueprint",
          "set_products",
          "sets",
          "trial_migration_rule",
          "trial_migration_rule.new_product",
          "trial_migration_rule.new_product.prices"
        ].join(),
        with_staged_imports: 1
      },
      payload.params
    );
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${
          getters.apiPath(isBasketContext ? "basket/products" : "products")
            .contextual
        }/${payload?.id}`,
        storeModule: DataModules.CATALOGUE
      },
      { root: true }
    );
  },
  search: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.GET,
        path: `${getters.apiPath().contextual}/search`,
        ...payload,
        requestConfig: {
          params: payload.params
        },
        storeModule: DataModules.CATALOGUE
      },
      { root: true }
    );
  },
  create: async (
    { dispatch, getters }: ActionContext<IDataState, IState>,
    payload
  ) => {
    const product = await dispatch(
      "data/create",
      {
        ...payload,
        path: getters.apiPath().admin,
        storeModule: DataModules.CATALOGUE
      },
      { root: true }
    );

    dispatch(
      "ui/userflow/track",
      { event: AppHookCodes.ADD_CATALOGUE_PRODUCT_COMPLETED },
      { root: true }
    );

    return product;
  },
  update: (
    { dispatch, getters }: ActionContext<IDataState, IState>,
    payload
  ) => {
    return dispatch(
      "data/update",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload?.id}`,
        storeModule: DataModules.CATALOGUE
      },
      { root: true }
    );
  },
  duplicate: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.POST,
        path: `${getters.apiPath().admin}/duplicate`,
        requestConfig: {
          data: _.merge(
            {
              product_id: payload?.id
            },
            _.get(payload, "unitId")
              ? {
                  unit_id: _.get(payload, "unitId")
                }
              : {}
          )
        }
      },
      { root: true }
    );
  },
  openAddProductModal: ({ dispatch }, payload) => {
    return new Promise(resolve => {
      dispatch(
        "ui/open/slideModal",
        {
          config: {
            component: () =>
              import(
                "@/components/app/admin/catalogue/products/addProductModal.vue"
              ),
            onCancel: () => {
              resolve({
                cancelled: true
              });
            },
            events: {
              success: (results: any) => {
                resolve({
                  cancelled: false,
                  results
                });
              }
            },
            ...payload
          }
        },
        { root: true }
      );
    });
  },
  reorder: (
    { dispatch, getters }: ActionContext<IDataState, IState>,
    payload
  ) => {
    if (!_.has(payload, "data.product_ids")) {
      throw new Error("Product order not provided");
    }

    return dispatch(
      "data/callApi",
      {
        method: Methods.PATCH,
        path: `${getters.apiPath().admin}/reorder`,
        storeModule: DataModules.CATALOGUE,
        requestConfig: { data: payload.data }
      },
      { root: true }
    );
  },
  remove: async (
    { dispatch, commit, getters }: ActionContext<IDataState, IState>,
    payload
  ) => {
    const result = await dispatch(
      "data/remove",
      {
        path: `${getters.apiPath().admin}/${payload?.id}`,
        storeModule: DataModules.CATALOGUE
      },
      { root: true }
    );

    await commit(
      "data/binSingleItemList",
      {
        scope: payload.scope,
        path: `data.${payload?.id}`,
        storeModule: DataModules.CATALOGUE
      },
      { root: true }
    );

    return result;
  },
  listSettings: ({ dispatch, getters }, payload) => {
    if (!_.has(payload, "id")) {
      throw new Error("Product id not provided");
    }

    if (!_.has(payload, "productType")) {
      throw new Error("Product type not provided");
    }

    return dispatch(
      "data/list",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload?.id}/${
          _.get(payload, "productType") === 4 ? "options" : "attributes"
        }`,
        storeModule: DataModules.CATALOGUE,
        params: {
          with_staged_imports: 1,
          ...payload.params
        }
      },
      { root: true }
    );
  },
  attachProductSettings: (
    { dispatch, getters }: ActionContext<IDataState, IState>,
    payload
  ) => {
    if (!_.has(payload, "id")) {
      throw new Error("Product id not provided");
    }

    if (!_.has(payload, "settingType")) {
      throw new Error("Product type not provided");
    }

    return dispatch(
      "data/callApi",
      {
        method: Methods.POST,
        path: `${getters.apiPath().admin}/${payload?.id}/${_.get(
          payload,
          "settingType"
        )}`,
        storeModule: DataModules.CATALOGUE,
        requestConfig: { data: payload.data }
      },
      { root: true }
    );
  },
  detachProductSettings: (
    { dispatch, getters }: ActionContext<IDataState, IState>,
    payload
  ) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.DELETE,
        path: `${getters.apiPath().admin}/${payload?.id}/${_.get(
          payload,
          "settingType"
        )}/${_.get(payload, "settingId")}`,
        storeModule: DataModules.CATALOGUE
      },
      { root: true }
    );
  },
  reorderProductSettings: ({ dispatch, getters }, payload) => {
    const data: Record<string, any> = {};
    data[
      `${
        _.get(payload, "settingType") === ProductTypes.PRODUCT_OPTION
          ? "option"
          : "attribute"
      }_ids`
    ] = _.get(payload, "settingIds");

    return dispatch(
      "data/callApi",
      {
        method: Methods.PUT,
        path: `${getters.apiPath().admin}/${payload?.id}/${
          _.get(payload, "settingType") === ProductTypes.PRODUCT_OPTION
            ? "options"
            : "attributes"
        }`,
        storeModule: DataModules.CATALOGUE,
        requestConfig: { data }
      },
      { root: true }
    );
  },
  getCrossSells: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `${getters.apiPath("basket/products").contextual}/${
          payload?.id
        }/related`,
        storeModule: DataModules.CATALOGUE
      },
      { root: true }
    );
  },
  reorderRelatedProducts: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PATCH,
        path: `${getters.apiPath().admin}/${payload?.id}/related`,
        storeModule: DataModules.CATALOGUE,
        requestConfig: { data: payload.data }
      },
      { root: true }
    );
  },

  attachRelatedProducts: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.POST,
        path: `${getters.apiPath().admin}/${payload?.id}/related`,
        storeModule: DataModules.CATALOGUE,
        requestConfig: { data: payload.data }
      },
      { root: true }
    );
  },

  detachRelatedProduct: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.DELETE,
        path: `${getters.apiPath().admin}/${payload?.productId}/related/${
          payload?.id
        }`,
        storeModule: DataModules.CATALOGUE
      },
      { root: true }
    );
  },

  openSelectProductsModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/admin/catalogue/products/multilevelCategories/select/selectProductsModal.vue"
            ),
          ...payload
        }
      },
      { root: true }
    );
  },

  // Migrations

  attachAllowedMigrations: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.POST,
        path: `${getters.apiPath().admin}/${_.get(
          payload,
          "productId"
        )}/allowed_migrations`,
        storeModule: DataModules.CATALOGUE,
        requestConfig: { data: payload.data }
      },
      { root: true }
    );
  },

  detachAllowedMigration: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/remove",
      {
        path: `${getters.apiPath().admin}/${_.get(
          payload,
          "productId"
        )}/allowed_migrations/${_.get(payload, "migrationProductId")}`,
        storeModule: DataModules.CATALOGUE
      },
      { root: true }
    );
  },

  reorderAllowedMigrations: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PATCH,
        path: `${getters.apiPath().admin}/${_.get(
          payload,
          "id"
        )}/allowed_migrations`,
        storeModule: DataModules.CATALOGUE,
        requestConfig: { data: payload.data }
      },
      { root: true }
    );
  },
  openManageCategoriesAndProductsModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/admin/catalogue/products/relatedProducts/multilevelSelectorModal.vue"
            ),
          ...payload
        }
      },
      { root: true }
    );
  },

  openEditRelatedObjectModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/admin/catalogue/products/relatedProducts/editRelatedObjectModal.vue"
            ),
          ...payload
        }
      },
      { root: true }
    );
  },
  updateRelatedObject: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/update",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload?.productId}/related/${
          payload?.id
        }`,
        storeModule: DataModules.CATALOGUE
      },
      { root: true }
    );
  },

  confirmDelete: async (
    { dispatch },
    {
      props,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onConfirm = () => {}
    }: {
      props: any;
      onConfirm: Function;
    }
  ) => {
    const confirm = await dispatch(
      "ui/open/confirmModal",
      {
        config: {
          props: {
            autoClose: false,
            action: i18n.t("_action.delete"),
            ...props
          },
          events: {
            confirmed: async () => {
              await onConfirm();
              confirm.close();
            }
          }
        }
      },
      { root: true }
    );
  },

  openAutoPopulateModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/admin/catalogue/products/billing/autoPopulatePricesModal.vue"
            ),
          width: 540,
          ...payload
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  modules: {
    categories: require("./categories").default,
    mainProductCategories: require("./mainProductCategories").default,
    promotions: require("./promotions").default
  }
};
