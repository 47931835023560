import Buefy from "buefy";

export default {
  install: (Vue: any) => {
    Vue.use(Buefy, {
      defaultIconPack: "fas",
      defaultNoticeQueue: false,
      defaultToastDuration: 2000,
      defaultToastPosition: "is-bottom",
      defaultModalCanCancel: ["button", "x"],
      defaultSnackbarDuration: 4000,
      defaultSnackbarPosition: "is-bottom"
    });
  }
};
