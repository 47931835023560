import { ProductTypes } from "@/data/enums/products";
import { DocCategories } from "@/data/enums/docs";
import { DataModules } from "@/store/modules/data/modules";
import _ from "@/boot/lodash";
import store from "@/store";
import { RegexMatch, AdminRoutes } from "@/data/enums/router";
import { deSerialisedConfig } from "@/helpers/table";
import type { Location, Route } from "vue-router";
import type { CreateElement } from "vue";
import DefaultFilters from "@/data/filters/userGroups";
import DefaultSorters from "@/data/sorters/userGroups";
import { OrgFeatureKeys } from "@/data/constants";

// Components
import AdminPromotionsView from "@/views/admin/settings/promotions/list/index.vue";
import PromotionsListing from "@/components/app/admin/catalogue/promotions/promotionsListing.vue";
import CProdsListing from "@/components/app/global/contractProducts/cProdsListing.vue";

// Menus
import { AdminPromotionsRoutes } from "@/router/admin/settings/menu";

// Sorters
import {
  ActiveSorter,
  DefaultPromotionsSorters
} from "@/data/sorters/promotions";
import { DefaultOrderTemplatesSorters } from "@/data/sorters/orderTemplates";
import {
  DateCancelledSorter,
  DatePurchasedSorter,
  DefaultCProdSorters,
  StatusSorter
} from "@/data/sorters/contractProducts";

// Filters
import {
  IsActiveParam,
  IsInactiveParam,
  IsDeletedPromotionParam,
  ActiveFilter,
  DefaultPromotionsFilters
} from "@/data/filters/promotions";
import { DefaultOrderTemplatesFilters } from "@/data/filters/orderTemplates";
import {
  statusParam,
  CatalogueProductIdParam
} from "@/data/filters/contractProducts";
import { CatalogueProductCProdFilters } from "@/data/filters/contractProducts";

import EmailsHistoryProvider from "@/components/app/global/emailHistory/emailsHistoryProvider.vue";
import EmailHistoryTable from "@/components/app/global/emailHistory/emailHistoryTable.vue";
import { EmailTypes } from "@/data/enums/emails";
import {
  IsSentParam,
  IsBouncedParam,
  IsFailedParam
} from "@/data/filters/emails";
import { ContractStatusCodes } from "@/models/contracts";
import TicketsListing from "@/components/app/global/tickets/ticketsListing.vue";
import {
  IsClosedParam,
  IsActiveParam as IsActiveTicketParam,
  IsUserParam,
  IsLeadUserParam,
  IsNotLeadUserParam
} from "@/data/filters/tickets";
import type { ITicket } from "@upmind-automation/types";

const renderEmailHistory = ({ manualFilters = {} }) => ({
  render(h: CreateElement) {
    return h(EmailsHistoryProvider, {
      props: {
        emailType: EmailTypes.USER,
        emailTypeId: (this as any).$route.params.userId,
        manualFilters: manualFilters || {}
      },
      scopedSlots: {
        default: () =>
          h(EmailHistoryTable, {
            props: {
              rowProps: email => ({
                to: { name: "adminUserEmailLog", params: { emailId: email.id } }
              })
            }
          })
      }
    });
  }
});

const openUserGroupModal = (options: {
  viewRouteName: string;
  userGroupCode?: string;
  params: any;
}) => {
  store.dispatch(`data/${DataModules.USER_GROUPS}/openAddEditModal`, {
    props: {
      viewRoute: () => ({
        name: options.viewRouteName,
        params: options.params
      }),
      userGroupCode: options.userGroupCode
    }
  });
};

export default [
  {
    path: "settings",
    components: {
      default: {
        name: "AdminSettings",
        template: `<router-view />`
      }
    },
    children: [
      {
        path: "",
        name: "adminSettings",
        component: () => import("@/views/admin/settings/index.vue"),
        props: {
          routes: require("./menu").routes
        },
        meta: {
          backTo: "_.settings",
          title: "_.settings",
          categorySlug: DocCategories.SETUP_CONFIGURATION,
          docsSlugs: ["configuration-notes"]
        }
      },
      {
        name: "brandGeneralSettings",
        path: "general",
        meta: {
          auth: ["get_brand"],
          title: "_.general_settings",
          requiresBrand: true,
          categorySlug: DocCategories.SETUP_CONFIGURATION,
          docsSlugs: ["branding"]
        },
        props: true,
        component: () => import("@/views/admin/settings/brand/index.vue")
      },
      {
        name: "brandClientsSettings",
        path: "clients",
        meta: {
          auth: [],
          title: "_.client_settings",
          requiresBrand: true
        },
        props: {
          dynamicShowOnOrg: false,
          predefinedShowOnOrg: false,
          settingsCode: "clients"
        },
        component: () => import("@/views/admin/settings/dynamic/index.vue")
      },
      {
        name: "brandStoreSettings",
        path: "store",
        props: true,
        component: () =>
          import("@/views/admin/settings/ecommerce/store/index.vue"),
        meta: {
          auth: ["get_brand"],
          title: "_.business_settings",
          requiresBrand: true
        }
      },
      {
        path: "payment-providers",
        name: "brandPaymentProvidersSettings",
        component: () =>
          import("@/views/admin/settings/paymentProviders/index.vue"),
        props: true,
        meta: {
          auth: ["get_brand", "list_brand_gateways"],
          title: "_.payment_providers_settings",
          requiresBrand: true
        },
        children: [
          {
            path: RegexMatch.NEW,
            name: "brandPaymentProvidersSettingsOpenModal",
            component: () =>
              import(
                "@/components/app/admin/settings/payments/openAddEditGatewayModal.vue"
              )
          }
        ]
      },
      {
        path: "terms-and-conditions",
        name: AdminRoutes.SETTINGS_TERMS_AND_CONDITIONS,
        component: () =>
          import("@/views/admin/settings/legal/termsAndConditions/index.vue"),
        meta: {
          auth: ["get_brand", "get_current_terms_and_conditions"],
          title: "_.terms_and_conditions",
          requiresBrand: true,
          categorySlug: DocCategories.SETUP_CONFIGURATION,
          docsSlugs: ["other-setup-items.how-to-create-terms-and-conditions"]
        }
      },
      {
        name: AdminRoutes.CUSTOM_CLIENT_FIELDS_SETTINGS,
        path: "custom-client-fields",
        props: true,
        component: () =>
          import("@/views/admin/settings/customClientFields/index.vue"),
        meta: {
          auth: ["list_client_fields"],
          title: "_.custom_fields",
          categorySlug: DocCategories.SETUP_CONFIGURATION,
          docsSlugs: [
            "configuration-notes",
            "branding",
            "branding.how-to-configure-your-brand"
          ],
          requiresBrand: true
        }
      },
      {
        name: AdminRoutes.CUSTOM_BASKET_FIELDS_SETTINGS,
        path: "custom-basket-fields",
        props: true,
        component: () =>
          import("@/views/admin/settings/customBasketFields/index.vue"),
        meta: {
          auth: ["list_basket_fields"],
          title: "_.basket_custom_fields",
          requiresBrand: true
        }
      },
      {
        name: AdminRoutes.CUSTOM_CANCELLATION_FIELDS_SETTINGS,
        path: "custom-cancellation-fields",
        props: true,
        component: () =>
          import("@/views/admin/settings/customFields/index.vue"),
        meta: {
          auth: [],
          title: "_.custom_cancellation_fields",
          requiresBrand: true
        }
      },
      {
        name: "orderTemplatesSettings",
        path: "order-templates",
        component: () =>
          import("@/views/admin/settings/orderTemplates/index.vue"),
        props: {
          availableSorters: DefaultOrderTemplatesSorters,
          availableFilters: DefaultOrderTemplatesFilters
        },
        meta: {
          auth: [],
          title: "_.order_templates",
          requiresBrand: true
        }
      },
      {
        name: "invoiceTemplateSettings",
        path: "invoice-template",
        component: () =>
          import("@/views/admin/settings/invoiceTemplate/index.vue"),
        meta: {
          auth: [],
          title: "_.invoice_template",
          requiresBrand: true
        }
      },
      {
        path: "domains",
        name: "brandDomainsSettings",
        component: () => import("@/views/admin/settings/domains/index.vue"),
        meta: {
          auth: ["list_oauth_clients"],
          title: "_.domains",
          backTo: "_.domains",
          requiresBrand: true,
          categorySlug: DocCategories.SETUP_CONFIGURATION,
          docsSlugs: ["branding.how-to-add-a-unique-domain-name"]
        }
      },
      {
        path: "currencies",
        name: "brandCurrenciesSettings",
        meta: {
          auth: ["get_brand"],
          title: "_.currencies",
          requiresBrand: false,
          categorySlug: DocCategories.CURRENCIES,
          docsSlugs: ["currencies"]
        },
        props: {
          settingsCode: "currencies",
          dynamicShowOnOrg: false
        },
        component: () => import("@/views/admin/settings/dynamic/index.vue")
      },
      {
        path: "spam-conditions",
        name: "admin__settingsSpamConditions",
        meta: {
          auth: [],
          title: "_.spam_conditions",
          backTo: "_.spam_conditions",
          requiresBrand: true
        },
        component: () =>
          import("@/views/admin/settings/spamConditions/index.vue")
      },
      {
        path: "imports",
        component: {
          name: "AdminImportsWrapper",
          template: `<router-view />`
        },
        children: [
          {
            path: "",
            name: "adminImports",
            component: () => import("@/views/admin/settings/imports/index.vue"),
            meta: {
              auth: ["list_imports"],
              title: "_.imports",
              backTo: "_.imports",
              requiresBrand: true,
              categorySlug: DocCategories.SETUP_CONFIGURATION,
              docsSlugs: ["importing-data"]
            }
          },
          {
            path: `:importId(${RegexMatch.UUID})`,
            component: () =>
              import("@/views/admin/settings/imports/import/index.vue"),
            props: {
              routes: require("./menu").importTabigation
            },
            meta: {
              auth: ["get_import"],
              requiresBrand: true
            },
            children: [
              {
                path: "",
                redirect: "overview"
              },
              {
                path: "overview",
                name: "adminImport",
                component: () =>
                  import(
                    "@/views/admin/settings/imports/import/overview/index.vue"
                  ),
                meta: {
                  title: "_.import_overview",
                  backTo: "_.import_overview"
                }
              },
              {
                path: "logs",
                name: "adminImportLogs",
                component: () =>
                  import(
                    "@/views/admin/settings/imports/import/logs/index.vue"
                  ),
                meta: {
                  title: "_.import_logs",
                  backTo: "_.import_logs"
                }
              }
            ]
          }
        ]
      },
      {
        path: "client-templates",
        component: () =>
          import("@/views/admin/settings/clientTemplates/index.vue"),
        props: {
          routes: require("./menu").ClientTemplatesTabigation
        },
        meta: {
          title: "_.client_area_templates",
          backTo: "_.client_area_templates",
          requiresBrand: true
        },
        children: [
          {
            path: "",
            name: "admin__clientTemplates",
            redirect: "slots"
          },
          {
            path: "slots",
            name: "admin__clientTemplateSlots",
            component: () =>
              import("@/views/admin/settings/clientTemplates/slots/index.vue"),
            auth: ["list_client_area_template_slots"]
          },
          {
            path: "templates",
            name: "admin__clientTemplateTemplates",
            component: () =>
              import(
                "@/views/admin/settings/clientTemplates/templates/index.vue"
              ),
            auth: ["list_templates"]
          }
        ]
      },
      {
        path: "email-templates",
        component: {
          name: AdminRoutes.EMAIL_TEMPLATES_SETTINGS,
          template: `<router-view :key="$route.path" />`,
          requiresBrand: true
        },
        children: [
          {
            path: "",
            name: "adminEmailTemplates",
            component: () =>
              import("@/views/admin/settings/emailTemplates/index.vue"),
            meta: {
              auth: ["list_notification_channels", "list_templates"],
              categorySlug: DocCategories.SETUP_CONFIGURATION,
              docsSlugs: ["email-messages"]
            }
          },
          {
            path: "add",
            name: AdminRoutes.EMAIL_TEMPLATE_ADD,
            meta: {
              auth: [
                "list_notification_channels",
                "list_templates",
                "create_template"
              ]
            },
            component: () =>
              import(
                "@/views/admin/settings/emailTemplates/emailTemplate/add/index.vue"
              )
          },
          {
            path: `:templateId(${RegexMatch.UUID})`,
            name: AdminRoutes.EMAIL_TEMPLATE_SETTINGS,
            redirect: { name: AdminRoutes.EMAIL_TEMPLATE_EDIT },
            meta: {
              auth: [
                "list_notification_channels",
                "list_templates",
                "get_template"
              ]
            },
            component: () =>
              import(
                "@/views/admin/settings/emailTemplates/emailTemplate/index.vue"
              ),
            props: (to: Route) => {
              return {
                routes: require("./menu").emailTemplatesTabigations,
                templateId: to.params.templateId
              };
            },
            children: [
              {
                path: `edit`,
                name: AdminRoutes.EMAIL_TEMPLATE_EDIT,
                component: () =>
                  import(
                    "@/views/admin/settings/emailTemplates/emailTemplate/edit/index.vue"
                  )
              },
              {
                path: `email-history`,
                name: AdminRoutes.EMAIL_TEMPLATE_HISTORY,
                component: () =>
                  import(
                    "@/views/admin/settings/automation/actions/action/emailHistory/index.vue"
                  )
              }
            ]
          }
        ]
      },
      {
        name: AdminRoutes.SUPPORT_DEPARTMENTS,
        path: "support-departments",
        props: true,
        component: () =>
          import("@/views/admin/settings/ticketDepartments/index.vue"),
        meta: {
          auth: [],
          title: "_.support_departments",
          backTo: "_.support_departments"
        }
      },
      {
        name: "adminCataloguePriceLists",
        path: "price-lists",
        components: {
          default: () => import("@/views/admin/settings/priceLists/index.vue")
        },
        meta: {
          auth: ["list_pricelists"],
          title: "_.price_lists",
          backTo: "_.price_lists"
        }
      },
      {
        name: "adminCatalogueProvisioning",
        path: "provider-configurations",
        components: {
          default: () => import("@/views/admin/settings/provisioning/index.vue")
        },
        meta: {
          auth: [],
          title: "_.provision_configurations",
          backTo: "_.provision_configurations",
          requiresBrand: true
        }
      },
      {
        name: "adminBrandProvisioning",
        path: "brand-provisioning",
        components: {
          default: () =>
            import("@/views/admin/settings/brandProvisioning/index.vue")
        },
        meta: {
          auth: [],
          title: "_.brand_provisioning_fields",
          backTo: "_.brand_provisioning_fields",
          requiresBrand: true
        },
        children: [
          {
            path: `:categoryId(${RegexMatch.UUID})`,
            name: "adminBrandProvisioningPreview",
            beforeEnter: (to, from, next) => {
              next({ name: "adminBrandProvisioning" });
              store.dispatch("ui/endRouting");
              store.dispatch(
                `data/${DataModules.PROVISIONING_BRAND}/openManageBrandFieldsModal`,
                {
                  blueprintId: _.get(to.params, "categoryId")
                }
              );
            }
          }
        ]
      },
      {
        path: "automation/webhooks",
        name: AdminRoutes.WEBHOOKS_SETTINGS,
        component: () =>
          import("@/views/admin/settings/automation/webhooks/index.vue"),
        meta: {
          auth: ["list_webhooks"],
          title: "_.webhooks",
          backTo: "_.webhooks",
          requiresBrand: true,
          requiredFeatureKeys: [OrgFeatureKeys.WEBHOOKS]
        }
      },
      {
        path: `automation/webhooks/:webhookId(${RegexMatch.UUID})`,
        name: AdminRoutes.WEBHOOKS_SETTINGS_WEBHOOK,
        redirect: { name: AdminRoutes.WEBHOOKS_SETTINGS_WEBHOOK_EDIT },
        component: () =>
          import(
            "@/views/admin/settings/automation/webhooks/webhook/index.vue"
          ),
        props: (to: Route) => {
          return {
            routes: require("./menu").automationWebhookTabigations,
            webhookId: to.params.webhookId
          };
        },
        meta: {
          requiresBrand: true,
          auth: ["get_template"],
          requiredFeatureKeys: [OrgFeatureKeys.WEBHOOKS]
        },
        children: [
          {
            path: "edit",
            name: AdminRoutes.WEBHOOKS_SETTINGS_WEBHOOK_EDIT,
            component: () =>
              import(
                "@/views/admin/settings/automation/webhooks/webhook/edit/index.vue"
              ),
            meta: {
              title: "_.webhook_overview",
              backTo: "_.webhook_overview"
            }
          },
          {
            path: "events",
            name: AdminRoutes.WEBHOOKS_SETTINGS_WEBHOOK_EVENTS,
            component: () =>
              import(
                "@/views/admin/settings/automation/webhooks/webhook/events/index.vue"
              ),
            meta: {
              title: "_.webhook_events",
              backTo: "_.webhook_events"
            }
          }
        ]
      },
      {
        path: `automation/triggers/:hookId(${RegexMatch.UUID})`,
        name: "adminAutomationHook",
        component: () =>
          import(
            "@/views/admin/settings/automation/triggers/trigger/index.vue"
          ),
        meta: {
          auth: ["get_notifiable_hook", "list_templates"],
          requiresBrand: true
        }
      },
      {
        path: "automation/actions/add",
        component: () =>
          import("@/views/admin/settings/automation/actions/action/index.vue"),
        redirect: { name: "adminAutomationAddAction" },
        meta: {
          requiresBrand: true
        },
        props: (to: Route) => {
          const config = deSerialisedConfig(
            (to.query["AAT"] as string) || "",
            []
          );
          const channelCode = config?.constFilters?.find(
            i => i.key === "channel.code"
          )?.value;

          return {
            channelCode,
            isCreate: true
          };
        },
        children: [
          {
            path: ``,
            name: "adminAutomationAddAction",
            component: () =>
              import(
                "@/views/admin/settings/automation/actions/action/editAction/index.vue"
              ),

            meta: {
              auth: ["create_template"]
            }
          }
        ]
      },
      {
        path: `automation/actions/:templateId(${RegexMatch.UUID})`,
        name: "adminAutomationAction",
        redirect: { name: "adminEditAutomationAction" },
        component: () =>
          import("@/views/admin/settings/automation/actions/action/index.vue"),
        props: (to: Route) => {
          return {
            routes: require("./menu").automationActionTabigations,
            templateId: to.params.templateId,
            channelCode: _.get(to.query, "channel.code")
          };
        },
        meta: {
          requiresBrand: true,
          auth: ["get_template"]
        },
        children: [
          {
            path: `edit`,
            name: "adminEditAutomationAction",
            component: () =>
              import(
                "@/views/admin/settings/automation/actions/action/editAction/index.vue"
              )
          },
          {
            path: `email-history`,
            name: "adminAutomationActionEmailHistory",
            component: () =>
              import(
                "@/views/admin/settings/automation/actions/action/emailHistory/index.vue"
              )
          }
        ]
      },
      {
        path: "automation",
        name: "adminAutomation",
        redirect: { name: "adminAutomationChannels" },
        component: () => import("@/views/admin/settings/automation/index.vue"),
        props: {
          routes: require("./menu").automationRoutes
        },
        meta: {
          requiresBrand: true
        },
        children: [
          {
            path: "channels",
            component: {
              name: "AdminSettingsAutomationChannels",
              template: `<router-view :key="$route.path" />`
            },
            children: [
              {
                path: "",
                meta: {
                  auth: ["list_notification_channels", "list_templates"],
                  categorySlug: DocCategories.SETUP_CONFIGURATION,
                  docsSlugs: ["notifications"]
                },
                name: "adminAutomationChannels",
                component: () =>
                  import("@/views/admin/settings/automation/channels/index.vue")
              }
            ]
          },
          {
            path: "triggers",
            name: "adminAutomationTriggers",
            component: () =>
              import("@/views/admin/settings/automation/triggers/index.vue"),
            meta: {
              auth: ["list_notifiable_hooks"],
              categorySlug: DocCategories.SETUP_CONFIGURATION,
              docsSlugs: ["notifications"]
            }
          }
        ]
      },
      {
        path: `users/:userId(${RegexMatch.UUID})`,
        props: true,
        component: {
          name: "AdminUser",
          props: ["userId"],
          template: "<router-view :key='`user_${userId}`' />"
        },
        meta: {
          title: "_.user"
        },
        children: [
          {
            path: "",
            name: "adminUser",
            props: route => ({
              userId: route.params.userId,
              routes: require("./menu").userRoutes
            }),
            redirect: { name: "adminUserOverview" },
            component: () =>
              import("@/views/admin/settings/users/user/index.vue"),
            children: [
              {
                path: "overview",
                name: "adminUserOverview",
                component: () =>
                  import(
                    "@/views/admin/settings/users/user/overview/index.vue"
                  ),
                meta: {
                  title: "_.overview",
                  backTo: "_.overview"
                }
              },
              {
                path: "tickets",
                component: {
                  name: "AdminUserTicketsWrapper",
                  template: `<router-view v-bind="$attrs" />`
                },
                children: [
                  {
                    path: "",
                    name: "adminUserSupportTickets",
                    component: () =>
                      import(
                        "@/views/admin/settings/users/user/tickets/index.vue"
                      ),
                    meta: {
                      title: "_.tickets",
                      breadcrumb: {
                        label: "_action.edit"
                      }
                    },
                    redirect: { name: "admin__userAssignedTickets" },
                    props: (route: Route) => ({
                      routes: require("./menu").usersTicketsTabigation,
                      userId: route.params.userId
                    }),
                    children: [
                      {
                        path: "assigned",
                        name: "admin__userAssignedTickets",
                        component: TicketsListing,
                        props: (route: Route) => ({
                          routeFilters: () => ({
                            ...IsLeadUserParam(route.params),
                            ...IsActiveTicketParam()
                          }),
                          ticketRoute: (ticket: ITicket) => ({
                            name: AdminRoutes.SUPPORT_TICKET,
                            params: {
                              ticketId: ticket.id
                            }
                          }),
                          showStaged: true
                        }),
                        meta: {
                          backTo: "_.assigned_tickets",
                          title: "_.assigned_tickets"
                        }
                      },
                      {
                        path: "watched",
                        name: "admin__userWatchedTickets",
                        component: TicketsListing,
                        props: (route: Route) => ({
                          routeFilters: () => ({
                            ...IsUserParam(route.params),
                            ...IsNotLeadUserParam(route.params),
                            ...IsActiveTicketParam()
                          }),
                          ticketRoute: (ticket: ITicket) => ({
                            name: AdminRoutes.SUPPORT_TICKET,
                            params: {
                              ticketId: ticket.id
                            }
                          }),
                          showStaged: true
                        }),
                        meta: {
                          backTo: "_.watched_tickets",
                          title: "_.watched_tickets"
                        }
                      },
                      {
                        path: "closed",
                        name: "admin__userClosedTickets",
                        component: TicketsListing,
                        props: (route: Route) => ({
                          routeFilters: () => ({
                            ...IsUserParam(route.params),
                            ...IsClosedParam()
                          }),
                          ticketRoute: (ticket: ITicket) => ({
                            name: AdminRoutes.SUPPORT_TICKET,
                            params: {
                              ticketId: ticket.id
                            }
                          }),
                          showStaged: true
                        }),
                        meta: {
                          backTo: "_.closed_tickets",
                          title: "_.closed_tickets"
                        }
                      }
                    ]
                  }
                ]
              },
              {
                path: "email-history",
                name: "adminUserEmailHistory",
                component: () =>
                  import(
                    "@/views/admin/settings/users/user/email-history/index.vue"
                  ),
                meta: {
                  title: "_.email_history",
                  backTo: "_.email_history"
                },
                redirect: { name: "adminUserEmailHistoryAll" },
                children: [
                  {
                    path: "failed",
                    name: "adminUserEmailHistoryFailed",
                    component: renderEmailHistory({
                      manualFilters: {
                        ...IsFailedParam()
                      }
                    })
                  },
                  {
                    path: "bounced",
                    name: "adminUserEmailHistoryBounced",
                    component: renderEmailHistory({
                      manualFilters: {
                        ...IsBouncedParam()
                      }
                    })
                  },
                  {
                    path: "sent",
                    name: "adminUserEmailHistorySent",
                    component: renderEmailHistory({
                      manualFilters: {
                        ...IsSentParam()
                      }
                    })
                  },
                  {
                    path: "all",
                    name: "adminUserEmailHistoryAll",
                    component: renderEmailHistory({}),
                    children: [
                      {
                        path: `:emailId(${RegexMatch.UUID})`,
                        name: "adminUserEmailLog",
                        beforeEnter: async (
                          to: Route,
                          from: Route,
                          next: (
                            options: boolean | Location | Location["path"]
                          ) => void
                        ) => {
                          const { emailId } = to.params;
                          if (emailId) {
                            store.dispatch("ui/open/slideModal", {
                              config: {
                                component: () =>
                                  import(
                                    "@/components/app/global/emailHistory/viewEmailModal.vue"
                                  ),
                                canCancel: ["button"],
                                props: {
                                  viewRoute: () => ({
                                    name: "adminUserEmailLog",
                                    params: to.params
                                  }),
                                  emailId
                                }
                              }
                            });
                          }
                          store.dispatch("ui/endRouting");
                          return next(!from.matched.length); // Important to maintain pagination/filters etc on parent list view
                        }
                      }
                    ]
                  }
                ]
              },
              {
                path: "activity-log",
                name: "adminUserActivityLog",
                component: () =>
                  import(
                    "@/views/admin/settings/users/user/activityLog/index.vue"
                  ),
                meta: {
                  title: "_.activity_log",
                  backTo: "_.activity_log"
                }
              },
              {
                path: "notifications",
                name: "adminUserNotifications",
                component: () =>
                  import(
                    "@/views/admin/settings/users/user/notifications/index.vue"
                  ),
                meta: {
                  title: "_.notifications",
                  backTo: "_.notifications"
                }
              }
            ]
          }
        ]
      },
      {
        path: "users",
        redirect: { name: "adminUsers" },
        component: () => import("@/views/admin/settings/users/index.vue"),
        props: {
          routes: require("./menu").usersTabigation
        },
        meta: {
          auth: ["list_users"]
        },
        children: [
          {
            path: "all",
            name: "adminUsers",
            component: () =>
              import("@/components/app/admin/users/usersListing.vue"),
            props: { id: "UT" },
            meta: {
              title: "_.all_staff_users",
              backTo: "_.all_staff_users",
              categorySlug: DocCategories.SETUP_CONFIGURATION,
              docsSlugs: ["staff-users"]
            }
          },
          {
            path: "active",
            name: "adminUsersActive",
            component: () =>
              import("@/components/app/admin/users/usersListing.vue"),
            props: { id: "UT", active: true },
            meta: {
              title: "_.active_staff_users",
              backTo: "_.active_staff_users",
              categorySlug: DocCategories.SETUP_CONFIGURATION,
              docsSlugs: ["staff-users"]
            }
          },
          {
            path: "inactive",
            name: "adminUsersInactive",
            component: () =>
              import("@/components/app/admin/users/usersListing.vue"),
            props: { id: "UT", active: false },
            meta: {
              title: "_.inactive_staff_users",
              backTo: "_.inactive_staff_users",
              categorySlug: DocCategories.SETUP_CONFIGURATION,
              docsSlugs: ["staff-users"]
            }
          }
        ]
      },
      {
        path: "user-groups",
        component: {
          name: "AdminSettingsUserGroups",
          template: `<router-view :key="$route.path" />`
        },
        meta: {
          auth: ["list_user_groups"]
        },
        children: [
          {
            path: "",
            name: "listUserGroups",
            component: () =>
              import("@/views/admin/settings/userGroups/index.vue"),
            props: () => ({
              availableFilters: () => DefaultFilters(),
              availableSorters: () => DefaultSorters()
            }),
            meta: {
              categorySlug: DocCategories.SETUP_CONFIGURATION,
              docsSlugs: ["staff-users"]
            },
            children: [
              {
                path: "new",
                name: "adminUserGroupAdd",
                beforeEnter: async (
                  to: Route,
                  from: Route,
                  next: (options: boolean | Location | Location["path"]) => void
                ) => {
                  openUserGroupModal({
                    viewRouteName: "adminUserGroupAdd",
                    userGroupCode: to.params.userGroupCode,
                    params: to.params
                  });
                  store.dispatch("ui/endRouting");
                  return next(!from.matched.length);
                }
              },
              {
                path: `:userGroupCode`,
                name: "adminUserGroup",
                beforeEnter: async (
                  to: Route,
                  from: Route,
                  next: (options: boolean | Location | Location["path"]) => void
                ) => {
                  openUserGroupModal({
                    viewRouteName: "adminUserGroup",
                    userGroupCode: to.params.userGroupCode,
                    params: to.params
                  });
                  store.dispatch("ui/endRouting");
                  return next(!from.matched.length); // Important to maintain pagination/filters etc on parent list view
                }
              }
            ]
          }
        ]
      },
      {
        path: "api-tokens",
        redirect: { name: "adminApiTokens" },
        component: () => import("@/views/admin/settings/apiTokens/index.vue"),
        props: {
          routes: require("./menu").apiTokensTabigation
        },
        meta: {
          requiredFeatureKeys: [OrgFeatureKeys.CREATE_USER_API_TOKENS],
          auth: ["list_users"]
        },
        children: [
          {
            path: "all",
            name: "adminApiTokens",
            component: () =>
              import("@/components/app/admin/users/usersListing.vue"),
            props: {
              id: "APITL1",
              isApiToken: true
            },
            meta: {
              title: "_.all_api_tokens",
              backTo: "_.all_api_tokens",
              categorySlug: DocCategories.SETUP_CONFIGURATION,
              docsSlugs: ["staff-users"]
            }
          },
          {
            path: "active",
            name: "adminApiTokensActive",
            component: () =>
              import("@/components/app/admin/users/usersListing.vue"),
            props: {
              id: "APITL1",
              isApiToken: true,
              active: true,
              revoked: false
            },
            meta: {
              title: "_.active_api_tokens",
              backTo: "_.active_api_tokens",
              categorySlug: DocCategories.SETUP_CONFIGURATION,
              docsSlugs: ["staff-users"]
            }
          },
          {
            path: "inactive",
            name: "adminApiTokensInactive",
            component: () =>
              import("@/components/app/admin/users/usersListing.vue"),
            props: {
              id: "APITL1",
              isApiToken: true,
              active: false,
              revoked: false
            },
            meta: {
              title: "_.inactive_api_tokens",
              backTo: "_.inactive_api_tokens",
              categorySlug: DocCategories.SETUP_CONFIGURATION,
              docsSlugs: ["staff-users"]
            }
          },
          {
            path: "revoked",
            name: "adminApiTokensRevoked",
            component: () =>
              import("@/components/app/admin/users/usersListing.vue"),
            props: {
              id: "APITL1",
              isApiToken: true,
              revoked: true
            },
            meta: {
              title: "_.revoked_api_tokens",
              backTo: "_.revoked_api_tokens",
              categorySlug: DocCategories.SETUP_CONFIGURATION,
              docsSlugs: ["staff-users"]
            }
          }
        ]
      },
      {
        path: "brands",
        name: "listAdminBrands",
        component: () => import("@/views/admin/settings/brands/index.vue"),
        meta: {
          auth: ["list_brands"],
          categorySlug: DocCategories.SETUP_CONFIGURATION,
          docsSlugs: ["branding.how-to-add-brands"]
        }
      },
      {
        path: "promotions",
        name: "admin__promotions",
        redirect: { name: "admin__allPromotions" },
        component: {
          name: "AdminPromotionsWrapper",
          template: "<router-view />"
        },
        meta: {
          requiresBrand: true
        },
        children: [
          {
            path: "list",
            name: "admin__listPromotions",
            component: AdminPromotionsView,
            props: {
              routes: AdminPromotionsRoutes
            },
            children: [
              {
                path: "all",
                name: "admin__allPromotions",
                component: PromotionsListing,
                props: {
                  availableSorters: () => [
                    ActiveSorter(),
                    ...DefaultPromotionsSorters()
                  ],
                  availableFilters: () => [
                    ActiveFilter(),
                    ...DefaultPromotionsFilters()
                  ]
                },
                meta: {
                  title: "_.all_promotions",
                  backTo: "_.all_promotions"
                }
              },
              {
                path: "active",
                name: "admin__activePromotions",
                component: PromotionsListing,
                props: {
                  availableSorters: DefaultPromotionsSorters,
                  availableFilters: DefaultPromotionsFilters,
                  routeFilters: () => ({
                    ...IsActiveParam()
                  })
                },
                meta: {
                  title: "_.active_promotions",
                  backTo: "_.active_promotions"
                }
              },
              {
                path: "inactive",
                name: "admin__inactivePromotions",
                component: PromotionsListing,
                props: {
                  availableSorters: DefaultPromotionsSorters,
                  availableFilters: DefaultPromotionsFilters,
                  routeFilters: () => ({
                    ...IsInactiveParam()
                  })
                },
                meta: {
                  title: "_.inactive_promotions",
                  backTo: "_.inactive_promotions"
                }
              },
              {
                path: "deleted",
                name: "admin__deletedPromotions",
                component: PromotionsListing,
                props: {
                  availableSorters: DefaultPromotionsSorters,
                  availableFilters: DefaultPromotionsFilters,
                  routeFilters: () => ({
                    ...IsDeletedPromotionParam()
                  })
                },
                meta: {
                  title: "_.deleted_promotions",
                  backTo: "_.deleted_promotions"
                }
              }
            ]
          },
          {
            path: "add",
            name: "admin__addPromotion",
            components: {
              default: () =>
                import("@/views/admin/settings/promotions/add/index.vue")
            },
            meta: {
              title: "_action.add_promotion",
              backTo: "_action.add_promotion",
              auth: [
                "create_promotion",
                "staff_list_products",
                "list_product_categories"
              ],
              breadcrumb: {
                label: "_.add"
              }
            },
            props: true
          },
          {
            path: `:promotionId(${RegexMatch.UUID})`,
            name: "admin__promotion",
            components: {
              default: () =>
                import("@/views/admin/settings/promotions/promotion/index.vue")
            },
            meta: {
              auth: ["get_promotion"],
              breadcrumb: {
                label: "_action.edit"
              }
            },
            props: {
              default: (route: Route) => ({
                promotionId: route.params.promotionId,
                routes: require("./menu").promotionTabigation
              })
            },
            redirect: { name: "adminPromotionConfiguration" },
            children: [
              {
                path: "configuration",
                name: "adminPromotionConfiguration",
                components: {
                  default: () =>
                    import(
                      "@/components/app/admin/catalogue/promotions/managePromotion.vue"
                    )
                },
                meta: {
                  backTo: "_.promotion_configuration",
                  title: "_.promotion_configuration",
                  breadcrumb: {
                    label: "_.configuration"
                  }
                }
              },
              {
                path: "uses",
                name: "adminPromotionUses",
                components: {
                  default: () =>
                    import(
                      "@/views/admin/settings/promotions/promotion/uses/index.vue"
                    )
                },
                meta: {
                  backTo: "_.promotion_uses",
                  title: "_.promotion_uses",
                  breadcrumb: {
                    label: "_.uses"
                  }
                }
              },
              {
                path: "activity-log",
                name: "adminPromotionActivity",
                components: {
                  default: () =>
                    import(
                      "@/views/admin/settings/promotions/promotion/activity/index.vue"
                    )
                },
                meta: {
                  backTo: "_.promotion_activity",
                  title: "_.promotion_activity",
                  breadcrumb: {
                    label: "_.activity_log"
                  }
                }
              }
            ]
          },
          {
            path: "*",
            component: () => import("@/views/error/index.vue"),
            props: { code: 404 }
          }
        ]
      },
      {
        path: "catalogue",
        name: "adminProductCatalogue",
        components: {
          default: () => import("@/views/admin/settings/catalogue/index.vue")
        },
        meta: {
          requiresBrand: true,
          title: "_.catalogue",
          backTo: "_.catalogue"
        },
        props: {
          default: {
            routes: require("./menu").catalogueTabigation
          }
        },
        redirect: { name: "adminCatalogueProducts" },
        children: [
          {
            path: "products",
            name: "adminCatalogueProducts",
            component: () =>
              import("@/views/admin/settings/catalogue/products/index.vue"),
            props: {
              productTypes: [
                ProductTypes.SINGLE_PRODUCT,
                ProductTypes.PRODUCT_BUNDLE
              ],
              routes: require("./menu").productsQuickFilters,
              id: "PR1"
            },
            meta: {
              title: "_.products",
              backTo: "_.products",
              auth: ["staff_list_products", "list_product_categories"]
            }
          },
          {
            path: "options",
            name: "adminCatalogueOptions",
            component: () =>
              import("@/views/admin/settings/catalogue/products/index.vue"),
            props: {
              productTypes: [ProductTypes.PRODUCT_OPTION],
              routes: require("./menu").productOptionsQuickFilters,
              id: "PRO1"
            },
            meta: {
              title: "_.product_options",
              backTo: "_.product_options",
              auth: ["staff_list_products", "list_product_options_categories"]
            }
          },
          {
            path: "attributes",
            name: "adminCatalogueAttributes",
            component: () =>
              import("@/views/admin/settings/catalogue/products/index.vue"),
            props: {
              productTypes: [ProductTypes.PRODUCT_ATTRIBUTE],
              routes: require("./menu").productAttributesQuickFilters,
              id: "PRA1"
            },
            meta: {
              title: "_.product_attributes",
              backTo: "_.product_attributes",
              auth: [
                "staff_list_products",
                "list_product_attributes_categories"
              ]
            }
          }
        ]
      },
      {
        path: `catalogue/product/:productId(${RegexMatch.UUID})`,
        name: "adminCatalogueProduct",
        component: () =>
          import("@/views/admin/settings/catalogue/product/index.vue"),
        props: {
          productTypes: [
            ProductTypes.SINGLE_PRODUCT,
            ProductTypes.PRODUCT_BUNDLE
          ],
          routes: require("./menu").productOverview
        },
        redirect: { name: "adminCatalogueProductDetails" },
        meta: {
          auth: ["staff_get_product", "list_product_categories"],
          backTo: "_.catalogue",
          requiresBrand: true,
          title: "_.catalogue"
        },
        children: [
          {
            path: "details",
            name: "adminCatalogueProductDetails",
            component: () =>
              import(
                "@/views/admin/settings/catalogue/product/details/index.vue"
              ),
            meta: {
              title: "_.product_details",
              backTo: "_.product_details"
            }
          },
          {
            path: "billing",
            name: "adminCatalogueProductBilling",
            component: () =>
              import(
                "@/views/admin/settings/catalogue/product/billing/index.vue"
              ),
            meta: {
              title: "_.product_billing",
              backTo: "_.product_billing"
            }
          },
          {
            path: "automation",
            name: "adminCatalogueProductAutomation",
            component: () =>
              import(
                "@/views/admin/settings/catalogue/product/automation/index.vue"
              ),
            meta: {
              title: "_.product_automation",
              backTo: "_.product_automation"
            }
          },
          {
            path: "options",
            name: "adminCatalogueProductOptions",
            component: () =>
              import(
                "@/views/admin/settings/catalogue/product/options/index.vue"
              ),
            props: true,
            meta: {
              title: "_.product_options",
              backTo: "_.product_options"
            }
          },
          {
            path: "attributes",
            name: "adminCatalogueProductAttributes",
            component: () =>
              import(
                "@/views/admin/settings/catalogue/product/attributes/index.vue"
              ),
            props: true,
            meta: {
              title: "_.product_attributes",
              backTo: "_.product_attributes"
            }
          },
          {
            path: "recommendations",
            name: "adminCatalogueProductRecommendations",
            component: () =>
              import(
                "@/views/admin/settings/catalogue/product/relatedProducts/index.vue"
              ),
            meta: {
              title: "_.product_recommendations",
              backTo: "_.product_recommendations"
            }
          },
          {
            path: "provisioning",
            name: "adminCatalogueProductProvisioning",
            component: () =>
              import(
                "@/views/admin/settings/catalogue/product/provisioning/index.vue"
              ),
            meta: {
              title: "_.product_provisioning",
              backTo: "_.product_provisioning"
            }
          },
          {
            path: "notifications",
            name: "adminCatalogueProductNotifications",
            component: () =>
              import(
                "@/views/admin/settings/catalogue/product/triggers/index.vue"
              ),
            meta: {
              title: "_.product_notifications",
              backTo: "_.product_notifications"
            }
          },
          {
            path: "templates",
            component: () =>
              import(
                "@/views/admin/settings/catalogue/product/templates/index.vue"
              ),
            props: {
              routes: require("./menu").CatalogueProductTemplatesTabigation
            },
            meta: {
              title: "_.client_area_templates",
              backTo: "_.client_area_templates"
            },
            children: [
              {
                path: "",
                name: "admin__catalogueProductTemplates",
                redirect: "slots"
              },
              {
                path: "slots",
                name: "admin__catalogueProductTemplateSlots",
                component: () =>
                  import(
                    "@/views/admin/settings/catalogue/product/templates/slots/index.vue"
                  ),
                auth: ["list_client_area_template_slots"]
              },
              {
                path: "templates",
                name: "admin__catalogueProductTemplateTemplates",
                component: () =>
                  import(
                    "@/views/admin/settings/catalogue/product/templates/templates/index.vue"
                  ),
                auth: ["list_templates"]
              }
            ]
          },
          {
            path: "upgrade-downgrade",
            name: "admin__catalogueProductUpgradeDowngrade",
            component: () =>
              import(
                "@/views/admin/settings/product-catalogue/product/migrations/index.vue"
              )
          },
          {
            path: "products",
            name: "adminCatalogueProductContractProducts",
            component: () =>
              import(
                "@/views/admin/settings/catalogue/product/contractProducts/index.vue"
              ),
            props: () => ({
              routes: require("./menu").catalogueProductsCPsTabigation
            }),
            redirect: { name: AdminRoutes.CATALOGUE_PRODUCT_ALL_CPS },
            meta: {
              title: "_.products_and_services",
              backTo: "_.products_and_services"
            },
            children: [
              {
                path: "all",
                name: AdminRoutes.CATALOGUE_PRODUCT_ALL_CPS,
                component: CProdsListing,
                props: (route: Route) => ({
                  showQuickSearch: false,
                  availableSorters: DefaultCProdSorters,
                  availableFilters: () => CatalogueProductCProdFilters(),
                  routeFilters: () => ({
                    ...CatalogueProductIdParam(route.params.productId)
                  })
                }),
                meta: {
                  backTo: "_.products_and_services",
                  title: "_.products_and_services"
                }
              },
              {
                path: "active",
                name: AdminRoutes.CATALOGUE_PRODUCT_ACTIVE_CPS,
                component: CProdsListing,
                props: (route: Route) => ({
                  showQuickSearch: false,
                  availableSorters: DefaultCProdSorters,
                  availableFilters: () => CatalogueProductCProdFilters(),
                  routeFilters: () => ({
                    ...CatalogueProductIdParam(route.params.productId),
                    ...statusParam([
                      ContractStatusCodes.ACTIVE,
                      ContractStatusCodes.AWAITING_ACTIVATION,
                      ContractStatusCodes.PENDING,
                      ContractStatusCodes.SUSPENDED
                    ])
                  })
                }),
                meta: {
                  backTo: "_.active_products_and_services",
                  title: "_.active_products_and_services"
                }
              },
              {
                path: "cancelled",
                name: AdminRoutes.CATALOGUE_PRODUCT_CANCELLED_CPS,
                component: CProdsListing,
                props: (route: Route) => ({
                  showQuickSearch: false,
                  availableSorters: () => [
                    StatusSorter(),
                    DateCancelledSorter(),
                    DatePurchasedSorter()
                  ],
                  availableFilters: () => CatalogueProductCProdFilters(),
                  routeFilters: () => ({
                    ...CatalogueProductIdParam(route.params.productId),
                    ...statusParam([
                      ContractStatusCodes.CANCELLED,
                      ContractStatusCodes.CLOSED
                    ])
                  })
                }),
                meta: {
                  backTo: "_.cancelled_products_and_services",
                  title: "_.cancelled_products_and_services"
                }
              }
            ]
          }
        ]
      },
      {
        path: `catalogue/category/:categoryId(${RegexMatch.UUID})`,
        name: "adminCatalogueCategory",
        component: () =>
          import("@/views/admin/settings/catalogue/category/index.vue"),
        props: (route: Route) => ({
          categoryId: route.params.categoryId,
          productTypes: [
            ProductTypes.SINGLE_PRODUCT,
            ProductTypes.PRODUCT_BUNDLE
          ],
          routes: require("./menu").categoryOverview
        }),
        redirect: { name: "adminCatalogueCategoryDetails" },
        meta: {
          auth: ["get_product_category", "list_product_categories"],
          backTo: "_.catalogue",
          requiresBrand: true,
          title: "_.catalogue"
        },
        children: [
          {
            path: "details",
            name: "adminCatalogueCategoryDetails",
            component: () =>
              import(
                "@/views/admin/settings/catalogue/category/details/index.vue"
              ),
            meta: {
              title: "_.category_details",
              backTo: "_.category_details"
            }
          },
          {
            path: "products",
            name: "adminCatalogueCategoryProducts",
            component: () =>
              import(
                "@/views/admin/settings/catalogue/category/products/index.vue"
              ),
            meta: {
              title: "_.category_products",
              backTo: "_.category_products"
            }
          },
          {
            path: "provisioning",
            name: "adminCatalogueCategoryProvisioning",
            component: () =>
              import(
                "@/views/admin/settings/catalogue/category/provisioning/index.vue"
              ),
            meta: {
              title: "_.category_provisioning",
              backTo: "_.category_provisioning"
            }
          }
        ]
      },
      {
        path: `catalogue/option/:productId(${RegexMatch.UUID})`,
        name: "adminCatalogueOption",
        component: () =>
          import("@/views/admin/settings/catalogue/product/index.vue"),
        props: {
          productTypes: [ProductTypes.PRODUCT_OPTION],
          routes: require("./menu").productOptionOverview
        },
        redirect: { name: "adminCatalogueOptionDetails" },
        meta: {
          auth: ["staff_get_product", "list_product_options_categories"],
          backTo: "_.catalogue",
          requiresBrand: true,
          title: "_.catalogue"
        },
        children: [
          {
            path: "details",
            name: "adminCatalogueOptionDetails",
            component: () =>
              import(
                "@/views/admin/settings/catalogue/product/details/index.vue"
              ),
            meta: {
              title: "_.product_option_details",
              backTo: "_.product_option_details"
            }
          },
          {
            path: "billing",
            name: "adminCatalogueOptionBilling",
            component: () =>
              import(
                "@/views/admin/settings/catalogue/product/billing/index.vue"
              ),
            meta: {
              title: "_.product_option_billing",
              backTo: "_.product_option_billing"
            }
          }
        ]
      },
      {
        path: `catalogue/option-category/:categoryId(${RegexMatch.UUID})`,
        name: "adminCatalogueOptionCategory",
        component: () =>
          import("@/views/admin/settings/catalogue/category/index.vue"),
        props: (route: Route) => ({
          categoryId: route.params.categoryId,
          productTypes: [ProductTypes.PRODUCT_OPTION],
          routes: require("./menu").optionCategoryOverview
        }),
        redirect: { name: "adminCatalogueOptionCategoryDetails" },
        meta: {
          auth: [
            "get_product_options_category",
            "list_product_options_categories"
          ],
          backTo: "_.catalogue",
          requiresBrand: true,
          title: "_.catalogue"
        },
        children: [
          {
            path: "details",
            name: "adminCatalogueOptionCategoryDetails",
            component: () =>
              import(
                "@/views/admin/settings/catalogue/category/details/index.vue"
              ),
            meta: {
              title: "_.category_details",
              backTo: "_.category_details"
            }
          },
          {
            path: "products",
            name: "adminCatalogueOptionCategoryProducts",
            component: () =>
              import(
                "@/views/admin/settings/catalogue/category/products/index.vue"
              ),
            meta: {
              title: "_.category_options",
              backTo: "_.category_options"
            }
          }
        ]
      },
      {
        path: `catalogue/attribute/:productId(${RegexMatch.UUID})`,
        name: "adminCatalogueAttribute",
        component: () =>
          import("@/views/admin/settings/catalogue/product/index.vue"),
        props: {
          productTypes: [ProductTypes.PRODUCT_ATTRIBUTE],
          routes: require("./menu").productAttributeOverview
        },
        meta: {
          auth: ["staff_get_product", "list_product_attributes_categories"],
          backTo: "_.catalogue",
          requiresBrand: true,
          title: "_.catalogue"
        },
        redirect: { name: "adminCatalogueAttributeDetails" },
        children: [
          {
            path: "details",
            name: "adminCatalogueAttributeDetails",
            component: () =>
              import(
                "@/views/admin/settings/catalogue/product/details/index.vue"
              ),
            meta: {
              title: "_.product_attribute_details",
              backTo: "_.product_attribute_details"
            }
          }
        ]
      },
      {
        path: `catalogue/attribute-category/:categoryId(${RegexMatch.UUID})`,
        name: "adminCatalogueAttributeCategory",
        component: () =>
          import("@/views/admin/settings/catalogue/category/index.vue"),
        props: (route: Route) => ({
          categoryId: route.params.categoryId,
          productTypes: [ProductTypes.PRODUCT_ATTRIBUTE],
          routes: require("./menu").attributeCategoryOverview
        }),
        redirect: { name: "adminCatalogueAttributeCategoryDetails" },
        meta: {
          auth: [
            "get_product_attributes_category",
            "list_product_attributes_categories"
          ],
          backTo: "_.catalogue",
          requiresBrand: true,
          title: "_.catalogue"
        },
        children: [
          {
            path: "details",
            name: "adminCatalogueAttributeCategoryDetails",
            component: () =>
              import(
                "@/views/admin/settings/catalogue/category/details/index.vue"
              ),
            meta: {
              title: "_.category_details",
              backTo: "_.category_details"
            }
          },
          {
            path: "products",
            name: "adminCatalogueAttributeCategoryProducts",
            component: () =>
              import(
                "@/views/admin/settings/catalogue/category/products/index.vue"
              ),
            meta: {
              title: "_.category_attributes",
              backTo: "_.category_attributes"
            }
          }
        ]
      },
      {
        path: "demo-data",
        name: AdminRoutes.DEMO_DATA_SETTINGS,
        component: () => import("@/views/admin/settings/demoData/index.vue"),
        meta: {
          requiresBrand: true,
          backTo: "_.seed_demo_data",
          title: "_.seed_demo_data"
        }
      },
      {
        path: "retention-reasons",
        name: AdminRoutes.SETTINGS_RETENTION_REASONS,
        component: () =>
          import("@/views/admin/settings/retentionReasons/index.vue"),
        meta: {
          auth: ["list_retention_reasons"],
          backTo: "_.retention_reasons",
          title: "_.retention_reasons"
        }
      },
      {
        path: `:settingId(${RegexMatch.SLUG})`,
        name: "adminDynamicSettings",
        component: () => import("@/views/admin/settings/dynamic/index.vue"),
        meta: {
          requiresBrand: true
        }
      },
      {
        path: "*",
        component: () => import("@/views/error/index.vue"),
        props: { code: 404 }
      }
    ]
  }
];
