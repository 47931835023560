import type { ISorter } from "@/models/tables";
import { CreatedAtSorter } from "@/data/sorters/object";
import i18n from "@/i18n";

export const UsernameSorter = (override?: Partial<ISorter>) =>
  ({
    field: "username",
    label: i18n.t("_.username").toString(),
    ...override
  } as ISorter);

export const FirstNameSorter = (override?: Partial<ISorter>) =>
  ({
    field: "firstname",
    label: i18n.t("_.firstname").toString(),
    ...override
  } as ISorter);

export const DefaultEmailSorter = (override?: Partial<ISorter>) =>
  ({
    field: "default_email.email",
    label: i18n.t("_.email").toString(),
    ...override
  } as ISorter);

export const LastNameSorter = (override?: Partial<ISorter>) =>
  ({
    field: "lastname",
    label: i18n.t("_.lastname").toString(),
    ...override
  } as ISorter);

export const VerifiedSorter = (override?: Partial<ISorter>) =>
  ({
    field: "verified",
    label: i18n.t("_.verified").toString(),
    ...override
  } as ISorter);

export const DefaultSorters: () => ISorter[] = () => [
  UsernameSorter(),
  FirstNameSorter(),
  LastNameSorter(),
  CreatedAtSorter(),
  VerifiedSorter()
];
