import _ from "@/boot/lodash";
import { QUERY_PARAMS } from "@/data/constants";

const BasketRoutes = [
  {
    label: "_.shop",
    to: (vm: any) => {
      return {
        name: "adminOrderShop",
        query: _.omit(vm.$route.query, [
          QUERY_PARAMS.PRODUCT_ID,
          QUERY_PARAMS.BASKET_PRODUCT_ID,
          QUERY_PARAMS.CROSS_SELL_PRODUCT,
          QUERY_PARAMS.SUBPRODUCT_IDS,
          QUERY_PARAMS.READ_MORE
        ])
      };
    },
    isActive: (currentRoute, isActive) => {
      if (
        currentRoute.name === "adminOrderProductView" ||
        currentRoute.name === "adminOrderCrossSellView"
      )
        return true;
      return isActive;
    },
    isDisabled: vm => {
      return vm.basketData.basketIsComplete;
    }
  },
  {
    label: "_.basket",
    isDisabled: vm => {
      return !vm.basketData.basket || vm.basketData.basketIsComplete;
    },
    to: (vm: any) => {
      return {
        name: "adminOrderBasketView",
        query: _.omit(vm.$route.query, [
          QUERY_PARAMS.CATEGORY_ID,
          QUERY_PARAMS.PRODUCT_ID,
          QUERY_PARAMS.CROSS_SELL_PRODUCT,
          QUERY_PARAMS.SUBPRODUCT_IDS,
          QUERY_PARAMS.READ_MORE
        ])
      };
    }
  },
  {
    label: "_.checkout",
    isDisabled: vm => {
      return !vm.basketData.canEnterCheckout;
    },
    to: (vm: any) => {
      return {
        name: "adminOrderCheckout",
        query: _.omit(vm.$route.query, [
          QUERY_PARAMS.CATEGORY_ID,
          QUERY_PARAMS.PRODUCT_ID,
          QUERY_PARAMS.CROSS_SELL_PRODUCT,
          QUERY_PARAMS.SUBPRODUCT_IDS,
          QUERY_PARAMS.READ_MORE
        ])
      };
    }
  },
  {
    label: "_.complete",
    isDisabled: vm => {
      return !vm.basketData.basketIsComplete;
    },
    to: (vm: any) => {
      return {
        name: "adminOrderComplete",
        query: vm.$route.query
      };
    }
  }
];

export default BasketRoutes;
