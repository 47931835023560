import { UpmindObjectTypes } from "./objects";

export enum DelegateObjectTypes {
  CONTRACT_PRODUCT = UpmindObjectTypes.CONTRACTS_PRODUCT,
  TICKET = UpmindObjectTypes.TICKET,
}

export enum DelegateCreatorObjectTypes {
  BRAND = "brand",
  CLIENT = "client",
  USER = "user",
}
