import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import _ from "@/boot/lodash";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import { Methods } from "@/models/methods";
import { forceAxiosArray } from "@/helpers/arrays";
import type { ApiPathGetter } from "@/models/api";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext }): ApiPathGetter =>
    (slug = "orders") => {
      const admin = `api/admin/${slug}`;
      const client = `api/${slug}`;
      const contextual = isAdminContext ? admin : client;
      return { client, admin, contextual };
    },
  scope: () => (basketId, productId) => {
    return `$basket_${basketId}_product_${productId}_fields`;
  },
  productScope: () => productId => {
    return `$product_${productId}_fields`;
  },
  valuesScope: () => (basketId, productId) => {
    return `$basket_${basketId}_product_${productId}_fields_values`;
  }
};

const actions: ActionTree<IDataState, IState> = {
  listFields: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `${getters.apiPath().contextual}/${payload?.basketId}/products/${
          payload?.productId
        }/provision_fields`,
        params: {
          ...(_.isNull(payload.subProductIds)
            ? {}
            : {
                sub_product_ids: forceAxiosArray(payload.subProductIds)
              }),
          limit: 0
        },
        storeModule: DataModules.PROVISIONING_BASKET_PRODUCTS
      },
      { root: true }
    );
  },

  listProductFields: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `${getters.apiPath("basket/products").contextual}/${
          payload?.productId
        }/provision_fields`,
        params: _.merge(
          {
            client_id: _.get(payload, "clientId"),
            limit: 0
          },
          _.isNull(payload.subProductIds)
            ? {}
            : {
                sub_product_ids: forceAxiosArray(payload.subProductIds)
              }
        ),
        storeModule: DataModules.PROVISIONING_BASKET_PRODUCTS
      },
      { root: true }
    );
  },

  getFieldsValues: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath().contextual}/${payload?.basketId}/products/${
          payload?.productId
        }/provision_fields/values`,
        params: {
          ...(_.isNull(payload.subProductIds)
            ? {}
            : {
                sub_product_ids: forceAxiosArray(payload.subProductIds)
              }),
          limit: 0
        },
        storeModule: DataModules.PROVISIONING_BASKET_PRODUCTS
      },
      { root: true }
    );
  },

  updateValues: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/update",
      {
        ...payload,
        path: `${getters.apiPath().contextual}/${payload?.basketId}/products/${
          payload?.productId
        }/provision_fields/values`,
        storeModule: DataModules.PROVISIONING_BASKET_PRODUCTS
      },
      { root: true }
    );
  },

  checkConfiguration: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.PATCH,
        path: `${getters.apiPath().contextual}/${
          payload?.basketId
        }/provision_fields/values/check`
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
