import {
  AdminRoutes,
  ClientRoutes,
  UpmindClientRoutes
} from "@/data/enums/router";
import type { GetterTree } from "vuex";
import type { IContractProduct } from "@/models/contracts";
import type { IDataState } from "@/store/modules/data";
import type { IState } from "@/store";
import type { IRouteGetter } from "@/models/routes";
import router from "@/router";

function getCProdParams(cProd: IContractProduct) {
  return {
    clientId: cProd.client_id as string,
    contractId: cProd.contract_id,
    productId: cProd.id
  };
}

export const getters: GetterTree<IDataState, IState> = {
  route:
    (s, g, rS, { isMockClientContext, isAdminContext }): IRouteGetter =>
    (cProd: IContractProduct) => ({
      to: cProd && {
        name: isMockClientContext()
          ? UpmindClientRoutes.CONTRACT_PRODUCT
          : isAdminContext
          ? AdminRoutes.CONTRACT_PRODUCT_OVERVIEW
          : ClientRoutes.CONTRACT_PRODUCT,
        params: getCProdParams(cProd)
      }
    }),
  routeToOverview:
    (s, g, rS, { isMockClientContext, isAdminContext }): IRouteGetter =>
    (cProd: IContractProduct, params) => ({
      disabled: [
        AdminRoutes.CONTRACT_PRODUCT_OVERVIEW,
        ClientRoutes.CONTRACT_PRODUCT_OVERVIEW,
        UpmindClientRoutes.CONTRACT_PRODUCT_OVERVIEW
      ].includes(router.currentRoute.name as any),
      to: cProd && {
        name: isMockClientContext()
          ? UpmindClientRoutes.CONTRACT_PRODUCT_OVERVIEW
          : isAdminContext
          ? AdminRoutes.CONTRACT_PRODUCT_OVERVIEW
          : ClientRoutes.CONTRACT_PRODUCT_OVERVIEW,
        params: {
          ...getCProdParams(cProd),
          ...params
        }
      }
    }),
  routeToBilling:
    (s, g, rS, { isMockClientContext, isAdminContext }): IRouteGetter =>
    (cProd: IContractProduct) => ({
      disabled: [
        AdminRoutes.CONTRACT_PRODUCT_INVOICES,
        ClientRoutes.CONTRACT_PRODUCT_BILLING,
        UpmindClientRoutes.CONTRACT_PRODUCT_BILLING
      ].includes(router.currentRoute.name as any),
      to: cProd && {
        name: isMockClientContext()
          ? UpmindClientRoutes.CONTRACT_PRODUCT_BILLING
          : isAdminContext
          ? AdminRoutes.CONTRACT_PRODUCT_INVOICES
          : ClientRoutes.CONTRACT_PRODUCT_BILLING,
        params: getCProdParams(cProd)
      }
    }),
  routeToProvisioning:
    (s, g, rS, { isAdminContext }): IRouteGetter =>
    (cProd: IContractProduct) => ({
      disabled: [
        AdminRoutes.CONTRACT_PRODUCT_PROVISIONING,
        ClientRoutes.CONTRACT_PRODUCT_OVERVIEW
      ].includes(router.currentRoute.name as any),
      to: cProd && {
        name: isAdminContext
          ? AdminRoutes.CONTRACT_PRODUCT_PROVISIONING
          : ClientRoutes.CONTRACT_PRODUCT_OVERVIEW,
        params: getCProdParams(cProd)
      }
    }),
  routeToSetup:
    (s, g, rS, { isAdminContext }): IRouteGetter =>
    (cProd: IContractProduct) => ({
      disabled: [
        AdminRoutes.CONTRACT_PRODUCT_SETUP,
        ClientRoutes.CONTRACT_PRODUCT_SETUP
      ].includes(router.currentRoute.name as any),
      to: cProd && {
        name: isAdminContext
          ? AdminRoutes.CONTRACT_PRODUCT_SETUP
          : ClientRoutes.CONTRACT_PRODUCT_SETUP,
        params: getCProdParams(cProd)
      }
    }),
  routeToOrder:
    (s, g, rS, { isMockClientContext, isAdminContext }): IRouteGetter =>
    (cProd: IContractProduct) => ({
      disabled: [
        AdminRoutes.ORDER,
        ClientRoutes.ORDER,
        UpmindClientRoutes.ORDER
      ].includes(router.currentRoute.name as any),
      to: cProd && {
        name: isMockClientContext()
          ? UpmindClientRoutes.ORDER
          : isAdminContext
          ? AdminRoutes.ORDER
          : ClientRoutes.ORDER,
        params: {
          clientId: cProd.client_id as string,
          orderId: cProd.main_invoice_id
        }
      }
    })
};
