import { defineComponent } from "vue";
import { mapState } from "vuex";
import RequestPromises from "@/mixins/requestPromises";
import Context from "@/mixins/context";
import { DataModules } from "@/store/modules/data/modules";
import { ProductTypes } from "@/data/enums/products";
import type { PropType } from "vue";
import type { IProductCategory } from "@/models/products";
import type { IState } from "@/store";
import type { IFilter } from "@/models/tables";
import type { IBrand } from "@upmind-automation/types";
import type { ICurrency } from "@upmind-automation/types";
import type { IAccount } from "@upmind-automation/types";

export default defineComponent({
  name: "MultilevelCategoriesFetcher",
  mixins: [RequestPromises, Context],
  props: {
    productTypes: {
      type: Array as PropType<ProductTypes[]>,
      default: () => [ProductTypes.SINGLE_PRODUCT, ProductTypes.PRODUCT_BUNDLE]
    },
    isBasketContext: { type: Boolean, default: false },
    accountId: { type: String as PropType<IAccount["id"]>, default: null },
    currencyId: { type: String as PropType<ICurrency["id"]>, default: null }
  },
  data: () => ({
    categoriesModule: DataModules.CATALOGUE_CATEGORIES,
    filters: [] as IFilter[]
  }),
  computed: {
    ...mapState({
      rootCategories(state: IState, getters): IProductCategory[] {
        return getters[`data/listArray`]({
          storeModule: this.categoriesModule,
          scope: this.rootCategoriesScope
        });
      },
      rootCategoriesScope(state: IState, getters): string {
        return getters[`data/${this.categoriesModule}/rootCategoriesScope`](
          this.brandId,
          this.productTypes
        );
      },
      brandId(state: IState): IBrand["id"] {
        return state.auth.admin.brandId;
      }
    }),
    multilevelCategoriesAsOptions() {
      return this.flatCategories(this.rootCategories);
    }
  },
  beforeDestroy() {
    this.$store.dispatch("data/binList", {
      storeModule: this.categoriesModule,
      scope: this.rootCategoriesScope,
      vm: this
    });
  },
  methods: {
    getRootCategories(ignoreStored = false, withStagedImports = false) {
      return this.$store.dispatch(`data/${this.categoriesModule}/list`, {
        productTypes: this.productTypes,
        scope: this.rootCategoriesScope,
        isBasketContext: this.isBasketContext,
        params: {
          order: "order",
          limit: 0,
          "filter[level]": 1,
          ...(withStagedImports ? { with_staged_imports: 1 } : {}),
          brandId: this.brandId,
          ...(this.currencyId ? { currency_id: this.currencyId } : {}),
          ...(this.accountId ? { account_id: this.accountId } : {})
        },
        vm: this,
        ignoreStored
      });
    },
    flatCategories(categories) {
      return this.$_.map(categories, category => {
        return this.$_.merge(
          {
            id: category.id.toString(),
            label: category.name
          },
          this.$_.get(category, "subcategories", []).length
            ? {
                children: this.flatCategories(category.subcategories)
              }
            : {}
        );
      });
    },
    filteredFilters(filters) {
      return this.$_.filter(this.$_.cloneDeep(filters), filter => {
        const canShow = this.$_.get(filter, "if");
        return canShow ? canShow(this) : true;
      }) as IFilter[];
    },
    getProductCategoryFilledFilters(
      usedFilters,
      isContractProduct,
      isPurchase = false
    ) {
      return this.$_.map(
        this.filteredFilters(usedFilters),
        (filter: IFilter) => {
          if (isPurchase) {
            filter.group = "purchase";
          }

          if (
            filter.key.indexOf("product.category.id") !== -1 ||
            filter.key.indexOf("category_id") !== -1
          ) {
            filter.options = this.multilevelCategoriesAsOptions;
          }

          return filter;
        }
      );
    }
  }
});
