import { FraudStatus } from "@/data/enums";
import { DocCategories } from "@/data/enums/docs";
import OrdersListing from "@/components/app/global/orders/ordersListing.vue";
import { DefaultOrderSorters } from "@/data/sorters/order";
import { FraudStatusParam as OrderFraudStatusParam } from "@/data/filters/order";
import { FraudStatusParam as InvoiceFraudStatusParam } from "@/data/filters/order";
import ClientsListing from "@/components/app/admin/clients/clientsListing.vue";
import InvoicesListing from "@/components/app/global/invoices/invoicesListing.vue";

import {
  FraudStatusParam as ClientFraudStatusParam,
  DefaultFilters as ClientsDefaultFilters
} from "@/data/filters/client";

export default [
  {
    path: "fraudmind",
    components: {
      default: {
        name: "AdminFraud",
        template: `<router-view />`
      }
    },
    children: [
      {
        path: "",
        name: "adminFraudmind",
        component: () => import("@/views/admin/fraud/index.vue"),
        props: {
          routes: require("./menu").fraudmindTabigation
        },
        meta: {
          title: "_.fraud"
        },
        redirect: { name: "adminFraudmindAttentionRequired" },
        children: [
          {
            path: "attention",
            name: "adminFraudmindAttentionRequired",
            redirect: require("./menu").ordersQuickFilters[0].to,
            components: {
              default: () =>
                import("@/views/admin/fraud/attentionRequired/index.vue")
            },
            props: {
              default: {
                clientsQuickFilters: require("./menu").clientsQuickFilters,
                ordersQuickFilters: require("./menu").ordersQuickFilters,
                invoicesQuickFilters: require("./menu").invoicesQuickFilters
              }
            },
            children: [
              {
                path: "clients",
                name: "adminFraudmindClients",
                component: {
                  name: "AdminOrdersView",
                  template: "<router-view/>"
                },
                children: [
                  {
                    path: "pending-review",
                    name: "adminFraudmindClients_pending_review",
                    component: ClientsListing,
                    props: () => ({
                      availableFilters: () =>
                        ClientsDefaultFilters().filter(
                          i => !["fraud_status"].includes(i.key)
                        ),
                      manualFilters: () => ({
                        ...ClientFraudStatusParam(FraudStatus.REVIEW)
                      })
                    }),
                    meta: {
                      categorySlug: DocCategories.SETUP_CONFIGURATION,
                      docsSlugs: [
                        "orders-services.fraudmindtm-fraud-prevention-and-security"
                      ]
                    }
                  },
                  {
                    path: "blocked",
                    name: "adminFraudmindClients_blocked",
                    component: ClientsListing,
                    props: () => ({
                      availableFilters: () =>
                        ClientsDefaultFilters().filter(
                          i => !["fraud_status"].includes(i.key)
                        ),
                      manualFilters: () => ({
                        ...ClientFraudStatusParam(FraudStatus.FRAUD)
                      })
                    }),
                    meta: {
                      categorySlug: DocCategories.SETUP_CONFIGURATION,
                      docsSlugs: [
                        "orders-services.fraudmindtm-fraud-prevention-and-security"
                      ]
                    }
                  }
                ]
              },
              {
                path: "orders",
                name: "adminFraudmindOrders",
                component: {
                  name: "AdminOrdersView",
                  template: "<router-view/>"
                },
                meta: {
                  categorySlug: DocCategories.SETUP_CONFIGURATION,
                  docsSlugs: [
                    "orders-services.fraudmindtm-fraud-prevention-and-security"
                  ]
                },
                children: [
                  {
                    path: "pending-review",
                    name: "adminFraudmindOrders_pending_review",
                    component: OrdersListing,
                    props: () => ({
                      availableSorters: DefaultOrderSorters,
                      manualFilters: () =>
                        OrderFraudStatusParam(FraudStatus.REVIEW)
                    }),
                    meta: {
                      categorySlug: DocCategories.SETUP_CONFIGURATION,
                      docsSlugs: [
                        "orders-services.fraudmindtm-fraud-prevention-and-security"
                      ]
                    }
                  },
                  {
                    path: "blocked",
                    name: "adminFraudmindOrders_blocked",
                    component: OrdersListing,
                    props: () => ({
                      availableSorters: DefaultOrderSorters,
                      manualFilters: () =>
                        OrderFraudStatusParam(FraudStatus.FRAUD)
                    }),
                    meta: {
                      categorySlug: DocCategories.SETUP_CONFIGURATION,
                      docsSlugs: [
                        "orders-services.fraudmindtm-fraud-prevention-and-security"
                      ]
                    }
                  }
                ]
              },
              {
                path: "invoices",
                name: "adminFraudmindInvoices",
                component: {
                  name: "AdminFraudInvoicesView",
                  template: "<router-view/>"
                },
                meta: {
                  categorySlug: DocCategories.SETUP_CONFIGURATION,
                  docsSlugs: [
                    "orders-services.fraudmindtm-fraud-prevention-and-security"
                  ]
                },
                children: [
                  {
                    path: "pending-review",
                    name: "adminFraudmindInvoices_pending_review",
                    component: InvoicesListing,
                    props: () => ({
                      manualFilters: () =>
                        InvoiceFraudStatusParam(FraudStatus.REVIEW)
                    }),
                    meta: {
                      categorySlug: DocCategories.SETUP_CONFIGURATION,
                      docsSlugs: [
                        "orders-services.fraudmindtm-fraud-prevention-and-security"
                      ]
                    }
                  },
                  {
                    path: "blocked",
                    name: "adminFraudmindInvoices_blocked",
                    component: InvoicesListing,
                    props: () => ({
                      manualFilters: () =>
                        InvoiceFraudStatusParam(FraudStatus.FRAUD)
                    }),
                    meta: {
                      categorySlug: DocCategories.SETUP_CONFIGURATION,
                      docsSlugs: [
                        "orders-services.fraudmindtm-fraud-prevention-and-security"
                      ]
                    }
                  }
                ]
              }
            ]
          },
          {
            path: "events",
            name: "adminFraudmindEvents",
            components: {
              default: () =>
                import("@/views/admin/fraud/allFraudEvents/index.vue")
            },
            meta: {
              title: "_.all_events",
              backTo: "_.all_fraud_events",
              auth: ["get_brand"],
              requiresBrand: true,
              categorySlug: DocCategories.SETUP_CONFIGURATION,
              docsSlugs: [
                "orders-services.fraudmindtm-fraud-prevention-and-security"
              ]
            }
          }
        ]
      },

      {
        path: "*",
        component: () => import("@/views/error/index.vue"),
        props: { code: 404 }
      }
    ]
  }
];
