import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import { Methods } from "@/models/methods";
import type { ApiPathGetter } from "@/models/api";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath: (): ApiPathGetter => () => {
    const admin = `api/admin/translations`;
    return { admin };
  },
  scope: () => id => {
    return `$translations_${id}`;
  }
};

const actions: ActionTree<IDataState, IState> = {
  get: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload?.objectCodeType}/${
          payload?.id
        }`,
        storeModule: DataModules.TRANSLATIONS
      },
      { root: true }
    );
  },
  update: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.POST,
        path: `${getters.apiPath().admin}/${payload?.objectCodeType}/${
          payload?.id
        }`,
        storeModule: DataModules.TRANSLATIONS,
        requestConfig: { data: payload.data }
      },
      { root: true }
    );
  },
  openTranslationsModal: ({ rootGetters, dispatch }, payload) => {
    if (
      !rootGetters["user/can"]("create_object_translation") ||
      !rootGetters["user/can"]("get_object_translation") ||
      !rootGetters["user/can"]("list_object_translations")
    )
      return;

    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/global/translations/editTranslationsModal.vue"
            ),
          ...payload
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
