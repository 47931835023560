<template>
  <is-loading :if="isLoading">
    <contract-categories-provider :handle-loading="false">
      <template v-slot:default="{ $categoriesData }">
        <columns>
          <template v-if="$store.getters['ui/isTabletPlus']">
            <column class="is-12">
              <collapse
                :collapsable="false"
                :cta="
                  $store.getters['brand/showStore'] &&
                  $t('_action.place_new_order')
                "
                :title="$t('_.my_products_and_services')"
                @click="$router.push({ name: 'order' }).catch(err => err)"
              />
            </column>
          </template>
          <column class="is-12 is-4-desktop is-3-widescreen">
            <box>
              <!-- Default routes -->
              <u-menu :routes="defaultRoutes" />
              <!-- Category routes -->
              <transition name="fade">
                <div v-if="!!$categoriesData.total">
                  <u-divide-with-text class="has-margin-y-75">
                    <u-i18n path="_.browse_by_category" />
                  </u-divide-with-text>
                  <u-menu :routes="$categoriesData.routes" />
                  <template v-if="hasDelegatedProducts">
                    <!-- Delegated toggle -->
                    <hr />
                    <b-switch
                      :value="form.exclude_delegated"
                      :true-value="0"
                      :false-value="1"
                      class="is-size-7"
                      @input="onToggleDelegated"
                    >
                      <u-i18n path="_action.show_delegated_products" />
                    </b-switch>
                  </template>
                </div>
              </transition>
            </box>
          </column>
          <column class="is-12 is-8-desktop is-9-widescreen">
            <box>
              <tabigation :routes="routes()" />
              <router-view :key="$route.path" :parent-filters="form" />
            </box>
          </column>
        </columns>
      </template>
    </contract-categories-provider>
  </is-loading>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { DataModules } from "@/store/modules/data/modules";
import { UserMetaKeys } from "@/data/enums/users";
import { ClientRoutes } from "@/data/enums/router";
import { QUERY_PARAMS } from "@/data/constants";
import type { PropType } from "vue";
import type { IMenuRoute } from "@/models/routes";

export default defineComponent({
  name: "ClientContractProducts",
  components: {
    "contract-categories-provider": () =>
      import(
        "@/components/app/global/contractCategories/contractCategoriesProvider.vue"
      )
  },
  props: {
    routes: {
      type: Function as PropType<() => IMenuRoute[]>,
      default: () => {}
    }
  },
  data: () => ({
    form: {},
    isLoading: true
  }),
  computed: {
    ...mapGetters({
      hasDelegatedProducts: "auth/client/hasDelegatedProducts"
    }),
    defaultRoutes(): IMenuRoute[] {
      return [
        {
          label: this.$t("_.all_products_and_services"),
          to: {
            name: ClientRoutes.PRODUCTS
          }
        },
        {
          label: this.$t("_.subscriptions"),
          to: {
            name: ClientRoutes.SUBSCRIPTIONS
          }
        },
        {
          label: this.$t("_.one_time_purchases"),
          to: {
            name: ClientRoutes.ONE_TIME_PURCHASES
          }
        }
      ];
    }
  },
  created() {
    this.form = this.initForm();
    this.checkForSolitaryProduct();
  },
  methods: {
    async checkForSolitaryProduct() {
      try {
        // We skip this check if we know there are delegated products as
        // performance of listing delegated items needs optimisation.
        const { total, data } = this.hasDelegatedProducts
          ? await Promise.resolve({ total: null })
          : await this.$store.dispatch(
              `data/${DataModules.CONTRACTS_PRODUCTS}/list`,
              {
                ignoreStored: true,
                returnData: false,
                storeData: false,
                params: {
                  limit: 1
                },
                vm: this
              }
            );
        // Redirect if solitary product
        if (total === 1)
          return this.$router.replace({
            name: ClientRoutes.CONTRACT_PRODUCT,
            query: this.$_.pick(this.$route.query, [QUERY_PARAMS.INIT]),
            params: {
              contractId: data[0].contract_id as string,
              productId: data[0].id as string
            }
          });
      } catch {
        // Silent catch – check non-critical
      } finally {
        this.isLoading = false;
      }
    },
    initForm() {
      return {
        exclude_delegated: parseInt(
          this.$store.getters["user/meta"](
            UserMetaKeys.UI_PRODUCTS_EXCLUDE_DELEGATES
          ) ?? `0`
        )
      };
    },
    onToggleDelegated(val: number) {
      this.$set(this.form, "exclude_delegated", val);
      this.$store.dispatch("user/addMeta", {
        [UserMetaKeys.UI_PRODUCTS_EXCLUDE_DELEGATES]: `${val}`
      });
    }
  }
});
</script>
