import _ from "@/boot/lodash";
import { Contexts } from "@upmind-automation/types";
import { Methods } from "@/models/methods";
import { GrantTypes } from "@/data/enums/tokens";
import { getCookie } from "@/helpers/cookies";
import { AppHookCodes } from "@/data/enums/hooks";
import tokenState from "@/store/modules/auth/tokenState";
import type { MutationTree, GetterTree, ActionTree } from "vuex";
import type { IToken } from "@/models/token";
import type { IState } from "@/store";

export interface IAuthGuestState {
  token: IToken;
}

export const initialState = (): IAuthGuestState => {
  return {
    token: tokenState(Contexts.GUEST)
  };
};

const getters: GetterTree<IAuthGuestState, IState> = {
  isAuthenticated: (state, getters, rootState, rootGetters): boolean => {
    return (
      !_.isEmpty(state.token.access_token) &&
      !rootGetters["auth/hasExpiredSession"](Contexts.GUEST)
    );
  }
};

const actions: ActionTree<IAuthGuestState, IState> = {
  self: ({ dispatch }) => {
    return dispatch(
      "api/call",
      {
        method: Methods.GET,
        path: "api/self",
        requestConfig: {
          params: {
            with: "actor,accounts"
          }
        }
      },
      { root: true }
    );
  },
  createToken: async ({ dispatch }) => {
    const { token }: { token: IToken } = await dispatch(
      "auth/createAccessToken",
      {
        data: {
          grant_type: GrantTypes.GUEST
        }
      },
      { root: true }
    );
    await dispatch(
      "auth/saveToken",
      { token, context: Contexts.GUEST },
      { root: true }
    );
    return Promise.resolve();
  },
  register: ({ dispatch, rootState }, data) => {
    const referral_cookie = getCookie("upm_aff");
    const tracking = _.get(rootState, "upm.track");
    return dispatch(
      "api/call",
      {
        method: Methods.POST,
        path: "api/clients/register/guest",
        requestConfig: {
          data: {
            ...data,
            ...(referral_cookie ? { referral_cookie } : {}),
            ...(tracking ? { tracking } : {})
          }
        }
      },
      { root: true }
    ).then(async result => {
      // Fire app hook
      dispatch(`ui/hooks/${AppHookCodes.CLIENT_REGISTERED}`, result?.data?.id, {
        root: true
      });
      return result;
    });
  },
  completeFullRegistration: ({ dispatch }, { userData, clientId }) => {
    return dispatch(
      "api/call",
      {
        method: Methods.POST,
        path: `api/clients/${clientId}/complete_registration`,
        requestConfig: { data: userData }
      },
      { root: true }
    );
  }
};

const mutations: MutationTree<IAuthGuestState> = {
  reset: state => {
    for (const property in localStorage) {
      if (property.startsWith("guest/auth/")) {
        localStorage.removeItem(property);
      }
    }
    Object.assign(state, initialState());
  }
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations
};
