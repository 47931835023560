import Vue from "vue";
import VueI18n from "vue-i18n";
import EssentialKeys from "./essentialKeys.json";
import SupportedLocaleCodes from "@/locales";
import _ from "@/boot/lodash";
import store from "@/store";

Vue.use(VueI18n);

export default new VueI18n({
  locale: SupportedLocaleCodes.EN,
  silentTranslationWarn: import.meta.env.NODE_ENV === "production",
  missing: (locale, key) => {
    if (locale === SupportedLocaleCodes.EN)
      return _.get(EssentialKeys, key, key);
    return key;
  },
  postTranslation: (str, key) => {
    if (!key) return str;
    const meta = store.getters["brand/meta"];
    const item = meta?.i18n[key];
    if (typeof item === "string") return item;
    return item?.__default || str;
  }
});
