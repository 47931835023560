import i18n from "@/i18n";
import type { IMenuRoute } from "@/models/routes";

export function bulkActionTabigation(): IMenuRoute[] {
  return [
    {
      label: i18n.t("_.overview"),
      to: {
        name: `adminClientsBulkActionOverview`
      }
    },
    {
      label: i18n.t("_.logs"),
      to: {
        name: `adminClientsBulkActionLogs`
      }
    },
    {
      label: i18n.t("_.activity_log"),
      to: {
        name: `adminClientsBulkActionActivityLog`
      }
    }
  ];
}
