import type { ActionTree } from "vuex";
import { Contexts } from "@upmind-automation/types";
import type { IAccount } from "@upmind-automation/types";
import type { ICurrency } from "@upmind-automation/types";
import type { IInvoice } from "@/models/invoices";
import type { IRequestsState } from "@/store/modules/api/requests";
import { Methods } from "@/models/methods";
import { tryArrayBufferToJson } from "@/helpers/file";

import type { IState } from "@/store";
import $store from "@/store";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import Axios from "axios";
import type Vue from "vue";
import _ from "@/boot/lodash";
import hash from "object-hash";
import router from "@/router";
import type { IToken } from "@/models/token";
import { AccessRoleTypes as ART } from "@/data/enums";

interface IApiCallConfig {
  rawResponse?: boolean;
  withAccessToken?: boolean;
  customAccessToken?: string;
  context?: Contexts;
  vueInstance?: Vue;
}

const defaultApiCallConfig: IApiCallConfig = {
  rawResponse: false,
  withAccessToken: true,
  customAccessToken: ""
};

export const BASE_URL = import.meta.env.VITE_APP_API_SERVER;

export interface IApiState {
  errors: {};
  requests: IRequestsState;
}

interface ICallApiPayload {
  method: Methods;
  path: string;
  requestConfig?: AxiosRequestConfig;
  callConfig?: IApiCallConfig;
}

interface CustomRequestConfig extends AxiosRequestConfig {
  context?: Contexts;
}

function getRootPath() {
  return _.get(router, "history.pending.path", router.currentRoute.path);
}

function setRequestHeaders(
  headers = {},
  context,
  token = "",
  withAccessToken = true
) {
  if (!withAccessToken) return;
  // If no token in callConfig, use token from store
  if (!token && context) token = $store.state.auth[context].token.access_token;
  // If no token in store, use token from localStorage
  if (_.isEmpty(token) && context) {
    token = localStorage.getItem(`${context}/auth/token/access_token`) || "";
  }
  // If we have token, set Authorization header
  if (!_.isEmpty(token)) {
    _.set(headers, `Authorization`, `Bearer ${token}`);
  }
  if (getRootPath().startsWith("/admin")) {
    _.set(headers, `Run-As`, `user`);
  }
  return headers;
}

function setupCallConfig(payload) {
  if (payload.callConfig === undefined) {
    payload.callConfig = {};
  }
  payload.callConfig = _.merge({}, defaultApiCallConfig, payload.callConfig);
  if (!payload.callConfig.context) {
    payload.callConfig.context =
      $store.state.context ||
      (getRootPath().startsWith("/admin") ? Contexts.ADMIN : Contexts.CLIENT);
  }
}

function setupRequestConfig(payload) {
  setupCallConfig(payload);
  if (payload.requestConfig === undefined) {
    payload.requestConfig = {};
  }
  _.merge(payload.requestConfig, {
    context: payload.callConfig.context,
    method: payload.method,
    url: `${BASE_URL}${payload.path}`,
    headers: setRequestHeaders(
      {},
      payload.callConfig.context,
      payload.callConfig.customAccessToken,
      payload.callConfig.withAccessToken
    )
  });
}

function createRequestHash(payload) {
  return hash.sha1(
    payload.method === Methods.GET || payload.path.startsWith("oauth")
      ? payload.requestConfig
      : window.$rootVue?.$uuid()
  );
}

Axios.interceptors.request.use((config: AxiosRequestConfig) => {
  if (!config.url?.includes(import.meta.env.VITE_APP_API_SERVER as string))
    return config;
  /** Here we intercept all requests made in a guest context OR those without
   * an authorisation token, and pass the active locale code to ensure correct
   * translations are returned (where applicable). */
  if ($store.getters.isGuestContext || !config.headers?.Authorization) {
    _.set(config, "params.lang", $store?.state?.i18n?.activeLocaleCode);
  }
  return config;
});

Axios.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError) => {
    const status = _.get(error, "response.status");
    const config: CustomRequestConfig = _.get(error, "config");
    const context = config?.context;
    const handle401Error = _.get(config, "handle401Error", true);
    const handle403IPError = _.get(config, "handle403IPError", true);
    const handle409FeatureError = _.get(config, "handle409FeatureError", true);

    if (handle403IPError && $store.getters["api/hasBlockedIP"](error)) {
      // Open blocked IP modal
      await $store.dispatch(
        "ui/openBlockedIPModal",
        $store.getters["api/errorMessage"](error),
        { root: true }
      );
    }

    if (handle401Error) {
      // If unauthorized token error
      if (config && status === 401) {
        try {
          const token: IToken = await $store.dispatch(
            "auth/refreshAccessToken"
          );

          /** Here we check the new token actor_type matches the request
           * context. If it doesn't match we will NOT retry the request. This
           * scenario can occur when going from eg. an authenticated client
           * context to guest context. Without this guard, you could end up
           * with a silent network loop. */

          if (token?.actor_type === ART.GUEST && context !== Contexts.GUEST)
            throw Error;
          setRequestHeaders(config.headers, context);
          return Axios.request(config);
        } catch (e) {
          throw new Axios.Cancel("Re-auth failed – operation cancelled.");
        }
      }
    }

    /** Here we intercept and automatically check for missing feature flags when
     * encountering a 409 as an admin user. If a specific feature flag is
     * required we open the package upgrade modal. */

    if (status === 409 && handle409FeatureError && context === Contexts.ADMIN) {
      /** Here we safely try and convert from ArrayBuffer in case the 409 was
       * encountered in the context of downloading a file blob. Eg 'This file
       * has not yet been scanned for viruses'. */
      const responseData = tryArrayBufferToJson(error?.response?.data);
      /** Convert ArrayBuffer into JSON and place into response data object
       * in case we need to map error message Eg. 'This file
       * could not be scanned for viruses' */
      if (error.response && responseData) error.response.data = responseData;
      /** Check is the upgrade needed. */
      const { feature_required } = responseData?.error?.data;
      if (feature_required)
        await $store.dispatch("org/openUpgradeModal", {
          props: {
            missingKeys: [feature_required]
          }
        });
    }

    return Promise.reject(error);
  }
);

const actions: ActionTree<IApiState, IState> = {
  call: ({ state, commit }, payload: ICallApiPayload): Promise<any> => {
    setupRequestConfig(payload);
    const requestHash = createRequestHash(payload);
    let requestPromise: Promise<any> = _.get(
      state,
      `requests.${requestHash}.promise`,
      null
    );
    const vueInstance = _.get(payload.callConfig, "vueInstance", null) as Vue;
    const requestPromises = _.get(
      vueInstance,
      "$data.requestPromises",
      undefined
    );
    const hasRequestPromises = _.isObject(requestPromises);
    if (hasRequestPromises) {
      vueInstance.$set(
        requestPromises,
        requestHash,
        _.get(requestPromises, requestHash, 0) + 1
      );
    }

    if (requestPromise) {
      commit("setRequest", {
        hash: requestHash,
        promise: requestPromise
      });
      if (hasRequestPromises) {
        requestPromise.finally(() => {
          vueInstance.$delete(requestPromises, requestHash);
        });
      }
      return requestPromise;
    }
    let cancelRequest;
    requestPromise = new Promise((resolve, reject) => {
      const CancelToken = Axios.CancelToken;
      return Axios.request(
        _.merge({}, payload.requestConfig, {
          cancelToken: new CancelToken(c => (cancelRequest = c))
        })
      )
        .then((response: AxiosResponse) => {
          resolve(
            _.get(payload, "callConfig.rawResponse", undefined)
              ? response
              : response.data
          );
        })
        .catch(error => {
          if (Axios.isCancel(error)) return;
          return reject(error);
        })
        .then(() => {
          commit("unsetRequest", requestHash);
          if (hasRequestPromises) {
            vueInstance.$delete(requestPromises, requestHash);
          }
        });
    });

    commit("setRequest", {
      hash: requestHash,
      promise: requestPromise,
      cancelRequest
    });

    return requestPromise;
  },
  calculate: (
    { dispatch, rootGetters },
    {
      prices,
      account_id = "",
      currency_id = "",
      invoice_id = undefined,
      currency_code = "",
      returnOnly = "",
      vm
    }: {
      prices: number[];
      account_id?: IAccount["id"];
      currency_id?: ICurrency["id"];
      invoice_id?: IInvoice["id"];
      currency_code?: string;
      returnOnly?: string;
      vm?: Vue;
    }
  ) => {
    const data: any = { prices };

    if (account_id) {
      data.account_id = account_id;
    }

    if (currency_id) {
      data.currency_id = currency_id;
    }

    if (invoice_id) {
      data.invoice_id = invoice_id;
    }

    if (currency_code.length) {
      data.currency_code = currency_code;
    }

    return new Promise((resolve, reject) => {
      dispatch(
        "data/callApi",
        {
          method: Methods.POST,
          path: rootGetters.isAdminContext
            ? "api/admin/cart/calculate"
            : "api/cart/calculate",
          requestConfig: { data },
          vm
        },
        { root: true }
      )
        .then(data => {
          resolve(returnOnly.length ? data[returnOnly] : data);
        })
        .catch(reject);
    });
  }
};

export default {
  namespaced: true,
  state: () => ({}),
  actions,
  modules: {
    errors: require("./errors").default,
    requests: require("./requests").default
  }
};
