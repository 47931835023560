import { mapState } from "vuex";
import { Contexts } from "@upmind-automation/types";
import type { IState } from "@/store";

export default {
  computed: {
    ...mapState({
      context(state: IState) {
        return state.context;
      },
      guestAccessToken(state: IState) {
        return this.$_.get(state, "auth.guest.token.access_token");
      }
    }),
    isMockClientContext(): boolean {
      return this.$store.getters["isMockClientContext"]();
    },
    isAdmin(): boolean {
      return !this.isMockClientContext && this.context === Contexts.ADMIN;
    },
    isClient(): boolean {
      return !!this.isMockClientContext || this.context === Contexts.CLIENT;
    },
    isGuest(): boolean {
      return this.context === Contexts.GUEST;
    }
  }
};
