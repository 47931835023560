import i18n from "@/i18n";

export function contractProductsTabigation() {
  return [
    {
      label: i18n.t("_.all"),
      to: {
        name: `adminCProds`
      }
    },
    {
      label: i18n.t("_.subscriptions"),
      to: {
        name: `adminSubscriptions`
      }
    },
    {
      label: i18n.t("_.one_time_purchases"),
      to: {
        name: `adminOneTimePurchases`
      }
    }
  ];
}

export function bulkActionTabigation() {
  return [
    {
      label: i18n.t("_.overview"),
      to: {
        name: `adminCProdsBulkActionOverview`
      }
    },
    {
      label: i18n.t("_.logs"),
      to: {
        name: `adminCProdsBulkActionLogs`
      }
    },
    {
      label: i18n.t("_.activity_log"),
      to: {
        name: `adminCProdsBulkActionActivityLog`
      }
    }
  ];
}
