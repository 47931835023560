import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import type { ApiPathGetter } from "@/models/api";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath: (): ApiPathGetter => () => {
    const admin = "api/admin/currencies/manual_exchange_rates";
    return { admin };
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `${getters.apiPath().admin}`,
        storeModule: DataModules.MANUAL_EXCHANGE_RATES
      },
      { root: true }
    );
  },
  create: ({ dispatch, getters }, { data }) => {
    return dispatch(
      "data/create",
      {
        path: getters.apiPath().admin,
        storeModule: DataModules.MANUAL_EXCHANGE_RATES,
        data
      },
      { root: true }
    );
  },
  update: ({ dispatch, getters }, { data, exchangeRateId }) => {
    return dispatch(
      "data/update",
      {
        path: `${getters.apiPath().admin}/${exchangeRateId}`,
        storeModule: DataModules.MANUAL_EXCHANGE_RATES,
        data
      },
      { root: true }
    );
  },
  delete: async ({ dispatch, getters }, { exchangeRateId }) => {
    return dispatch(
      "data/remove",
      {
        path: `${getters.apiPath().admin}/${exchangeRateId}`,
        storeModule: DataModules.MANUAL_EXCHANGE_RATES
      },
      { root: true }
    );
  },
  openExchangeRatesListingModal: ({ dispatch }, modalConfig) => {
    dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/admin/settings/currencies/exchangeRatesListingModal.vue"
            ),
          parent: window.$rootVue,
          width: 540,
          hasModalCard: true,
          ...modalConfig
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  getters
};
