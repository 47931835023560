import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import { Methods } from "@/models/methods";
// import { ApiPathGetter } from "@/models/api";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  scope: () => {
    return `$reports`;
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: "api/admin/reports/index_prepared",
        storeModule: DataModules.REPORTS
      },
      { root: true }
    );
  },
  get: ({ dispatch }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `api/admin/reports`,
        storeModule: DataModules.REPORTS
      },
      { root: true }
    );
  },

  run: ({ dispatch }, payload) => {
    return dispatch(
      "data/create",
      {
        ...payload,
        path: `api/admin/reports/${payload.reportCode}`,
        storeModule: DataModules.REPORTS
      },
      { root: true }
    );
  },

  createReport: ({ dispatch }, payload) => {
    return dispatch(
      "data/create",
      {
        ...payload,
        path: `api/admin/reports/${payload.reportCode}/download_request`,
        storeModule: DataModules.REPORTS
      },
      { root: true }
    );
  },

  listRecords: ({ dispatch }, payload) => {
    return dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.POST,
        returnData: false,
        path: `api/admin/reports/${payload.reportCode}`,
        storeModule: DataModules.REPORTS
      },
      { root: true }
    );
  },

  getReportHelp: ({ dispatch }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `api/admin/reports/${payload.reportCode}/help`,
        storeModule: DataModules.REPORTS
      },
      { root: true }
    );
  },

  download: ({ dispatch }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.GET,
        returnData: false,
        path: `api/admin/reports/${payload.params.report_id}/download`,
        storeModule: DataModules.REPORTS,
        requestConfig: { responseType: "arraybuffer" }
      },
      { root: true }
    );
  },

  remove: ({ dispatch }, payload) => {
    return dispatch(
      "data/remove",
      {
        // ...payload,
        path: `api/admin/reports/${payload.reportId}/delete`,
        storeModule: DataModules.REPORTS
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  modules: {
    revenue: require("./revenue").default
  }
};
