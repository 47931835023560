import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import { UPM_DATA_LAYER } from "@/boot/gtm";
import type { IPaymentDetail } from "@/models/paymentDetails";
import type { ApiPathGetter } from "@/models/api";
import _ from "@/boot/lodash";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext, isMockClientContext }): ApiPathGetter =>
    (clientId, slug = "payment_details") => {
      const admin = `api/admin/clients/${clientId}/${slug}`;
      const client = `api/clients/${clientId}/${slug}`;
      const contextual =
        isAdminContext && !isMockClientContext() ? admin : client;
      return { client, admin, contextual };
    },
  clientScope: () => id => {
    return `$client_${id}`;
  },
  params: (state, getters) => (id: IPaymentDetail["id"]) => {
    return _.get(state, `${getters.clientScope(id)}.params`, {});
  }
};

const actions: ActionTree<IDataState, IState> = {
  get: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath(payload?.clientId).contextual}/${
          payload?.paymentDetailId
        }`,
        storeModule: DataModules.CLIENTS_PAYMENT_DETAILS,
        params: {
          ...payload.params,
          with_staged_imports: 1
        }
      },
      { root: true }
    );
  },
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: payload?.activeOnly
          ? getters.apiPath(payload?.id, "available_payment_details").contextual
          : getters.apiPath(payload?.id).contextual,
        storeModule: DataModules.CLIENTS_PAYMENT_DETAILS,
        params: {
          ...payload.params,
          with_staged_imports: 1
        }
      },
      { root: true }
    );
  },
  create: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/create",
      {
        ...payload,
        path: getters.apiPath(payload?.id).contextual,
        storeModule: DataModules.CLIENTS_PAYMENT_DETAILS
      },
      { root: true }
    ).then(paymentMethod => {
      UPM_DATA_LAYER.push({ ecommerce: null });
      UPM_DATA_LAYER.push({ event: "add_payment_info" });
      return paymentMethod;
    });
  },
  update: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/update",
      {
        ...payload,
        path: `${getters.apiPath(payload?.id).contextual}/${
          payload?.payment_details_id
        }`,
        storeModule: DataModules.CLIENTS_PAYMENT_DETAILS
      },
      { root: true }
    );
  },
  remove: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/remove",
      {
        ...payload,
        path: `${getters.apiPath(payload?.id).contextual}/${
          payload?.payment_details_id
        }`,
        storeModule: DataModules.CLIENTS_PAYMENT_DETAILS
      },
      { root: true }
    );
  },
  createManual: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/create",
      {
        ...payload,
        path: getters.apiPath(payload?.id, "manual_payment_details").admin,
        storeModule: DataModules.CLIENTS_PAYMENT_DETAILS
      },
      { root: true }
    ).then(paymentMethod => {
      return paymentMethod;
    });
  },
  openAddModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/modal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/global/payments/addPaymentMethodModal.vue"
            ),
          canCancel: ["button"],
          ...payload
        }
      },
      { root: true }
    );
  },

  openAddManualModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/modal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/global/payments/addManualPaymentMethodModal.vue"
            ),
          canCancel: ["escape", "outside", "button"],
          ...payload
        }
      },
      { root: true }
    );
  },

  openEditModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/windowModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/global/payments/editPaymentMethodModal.vue"
            ),
          width: 400,
          ...payload
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
