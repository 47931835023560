import { ClientRoutes, RegexMatch } from "@/data/enums/router";
import CProdsListing from "@/components/app/global/contractProducts/cProdsListing.vue";
import ClientProductsView from "@/views/client/products/index.vue";
import {
  ClientCategoryProductsTabigation,
  ClientOneTimePurchasesTabigation,
  ClientProductsTabigation,
  ClientSubscriptionsTabigation
} from "@/router/client/products/menu";

import {
  IsActiveParam,
  IsInactiveParam,
  IsSubscriptionParam,
  IsOneTimePurchaseParam,
  IsCategoryParam,
  ProductNameFilter,
  ProductCategoryNameFilter,
  StatusCodesFilter,
  ActiveStatusCodesFilter,
  CancelledStatusCodesFilter,
  PriceFilter,
  DatePurchasedFilter,
  NextDueDateFilter,
  IsSubscriptionFilter,
  IsOneTimeFilter,
  ProductCategoryFilter
} from "@/data/filters/contractProducts";
import {
  DateCancelledSorter,
  DatePurchasedSorter,
  DefaultCProdSorters,
  StatusSorter
} from "@/data/sorters/contractProducts";
import { $bus } from "@/boot/event-bus";
import type { Component } from "vue";
import type { NavigationGuardNext, Route } from "vue-router";

// Components
import ClientCProdLayout from "@/components/app/global/contractProducts/cProdClientLayout.vue";
import ClientDefaultLayout from "@/skeleton/client/loggedIn/defaultLayout.vue";
import ClientProductsBillboard from "@/components/app/client/contractProductsNeedsConfirmation.vue";
import store from "@/store";

export default [
  {
    path: "products",
    name: ClientRoutes.CONTRACT_PRODUCTS,
    redirect: { name: ClientRoutes.PRODUCTS_ACTIVE },
    components: {
      default: {
        name: "ClientProductsWrapper",
        render: h => h("router-view")
      } as Component,
      layout: {
        render: h =>
          h(ClientDefaultLayout, {
            scopedSlots: {
              billboard: () => h(ClientProductsBillboard)
            }
          })
      } as Component
    },
    meta: {},
    children: [
      /** ALL products */
      {
        path: "all",
        name: ClientRoutes.PRODUCTS,
        redirect: { name: ClientRoutes.PRODUCTS_ACTIVE },
        component: ClientProductsView,
        props: {
          routes: ClientProductsTabigation
        },
        meta: {
          title: "_.products_and_services"
        },
        children: [
          {
            path: "",
            name: ClientRoutes.PRODUCTS_ALL,
            component: CProdsListing,
            props: {
              availableSorters: DefaultCProdSorters,
              availableFilters: () => [
                DatePurchasedFilter(),
                NextDueDateFilter(),
                PriceFilter(),
                ProductNameFilter(),
                ProductCategoryNameFilter(),
                StatusCodesFilter()
              ]
            }
          },
          {
            path: "active",
            name: ClientRoutes.PRODUCTS_ACTIVE,
            component: CProdsListing,
            props: {
              availableSorters: DefaultCProdSorters,
              availableFilters: () => [
                DatePurchasedFilter(),
                NextDueDateFilter(),
                PriceFilter(),
                ProductNameFilter(),
                ProductCategoryNameFilter(),
                ActiveStatusCodesFilter()
              ],
              routeFilters: () => ({
                ...IsActiveParam()
              })
            }
          },
          {
            path: "cancelled",
            name: ClientRoutes.PRODUCTS_CANCELLED,
            component: CProdsListing,
            props: {
              availableSorters: () => [
                DatePurchasedSorter(),
                DateCancelledSorter(),
                StatusSorter()
              ],
              availableFilters: () => [
                DatePurchasedFilter(),
                PriceFilter(),
                ProductNameFilter(),
                ProductCategoryNameFilter(),
                CancelledStatusCodesFilter()
              ],
              routeFilters: () => ({
                ...IsInactiveParam()
              })
            }
          }
        ]
      },
      /** SUBSCRIPTION products */
      {
        path: "subscriptions",
        name: ClientRoutes.SUBSCRIPTIONS,
        redirect: { name: ClientRoutes.SUBSCRIPTIONS_ACTIVE },
        component: ClientProductsView,
        props: {
          routes: ClientSubscriptionsTabigation
        },
        meta: {
          title: "_.subscriptions"
        },
        children: [
          {
            path: "",
            name: ClientRoutes.SUBSCRIPTIONS_ALL,
            component: CProdsListing,
            props: {
              availableSorters: DefaultCProdSorters,
              availableFilters: () => [
                DatePurchasedFilter(),
                NextDueDateFilter(),
                PriceFilter(),
                ProductNameFilter(),
                ProductCategoryNameFilter(),
                StatusCodesFilter(),
                IsSubscriptionFilter()
              ],
              routeFilters: () => ({
                ...IsSubscriptionParam()
              })
            }
          },
          {
            path: "active",
            name: ClientRoutes.SUBSCRIPTIONS_ACTIVE,
            component: CProdsListing,
            props: {
              availableSorters: DefaultCProdSorters,
              availableFilters: () => [
                DatePurchasedFilter(),
                NextDueDateFilter(),
                PriceFilter(),
                ProductNameFilter(),
                ProductCategoryNameFilter(),
                ActiveStatusCodesFilter(),
                IsSubscriptionFilter()
              ],
              routeFilters: () => ({
                ...IsSubscriptionParam(),
                ...IsActiveParam()
              })
            }
          },
          {
            path: "cancelled",
            name: ClientRoutes.SUBSCRIPTIONS_CANCELLED,
            component: CProdsListing,
            props: {
              availableSorters: () => [
                DatePurchasedSorter(),
                DateCancelledSorter(),
                StatusSorter()
              ],
              availableFilters: () => [
                DatePurchasedFilter(),
                PriceFilter(),
                ProductNameFilter(),
                ProductCategoryNameFilter(),
                CancelledStatusCodesFilter(),
                IsSubscriptionFilter()
              ],
              routeFilters: () => ({
                ...IsSubscriptionParam(),
                ...IsInactiveParam()
              })
            }
          }
        ]
      },
      /** ONE-TIME PURCHASE products */
      {
        path: "one-time-purchases",
        name: ClientRoutes.ONE_TIME_PURCHASES,
        redirect: { name: ClientRoutes.ONE_TIME_PURCHASES_ACTIVE },
        component: ClientProductsView,
        props: {
          routes: ClientOneTimePurchasesTabigation
        },
        meta: {
          title: "_.one_time_purchases"
        },
        children: [
          {
            path: "",
            name: ClientRoutes.ONE_TIME_PURCHASES_ALL,
            component: CProdsListing,
            props: {
              availableSorters: () => [DatePurchasedSorter(), StatusSorter()],
              availableFilters: () => [
                DatePurchasedFilter(),
                PriceFilter(),
                ProductNameFilter(),
                ProductCategoryNameFilter(),
                StatusCodesFilter(),
                IsOneTimeFilter()
              ],
              routeFilters: () => ({
                ...IsOneTimePurchaseParam()
              })
            }
          },
          {
            path: "active",
            name: ClientRoutes.ONE_TIME_PURCHASES_ACTIVE,
            component: CProdsListing,
            props: {
              availableSorters: () => [DatePurchasedSorter(), StatusSorter()],
              availableFilters: () => [
                DatePurchasedFilter(),
                PriceFilter(),
                ProductNameFilter(),
                ProductCategoryNameFilter(),
                ActiveStatusCodesFilter(),
                IsOneTimeFilter()
              ],
              routeFilters: () => ({
                ...IsOneTimePurchaseParam(),
                ...IsActiveParam()
              })
            }
          },
          {
            path: "cancelled",
            name: ClientRoutes.ONE_TIME_PURCHASES_CANCELLED,
            component: CProdsListing,
            props: {
              availableSorters: () => [
                DatePurchasedSorter(),
                DateCancelledSorter(),
                StatusSorter()
              ],
              availableFilters: () => [
                DatePurchasedFilter(),
                PriceFilter(),
                ProductNameFilter(),
                ProductCategoryNameFilter(),
                CancelledStatusCodesFilter(),
                IsOneTimeFilter()
              ],
              routeFilters: () => ({
                ...IsOneTimePurchaseParam(),
                ...IsInactiveParam()
              })
            }
          }
        ]
      },
      /** CATEGORY products */
      {
        path: `category/:categoryId(${RegexMatch.UUID})`,
        name: ClientRoutes.CATEGORY_PRODUCTS,
        redirect: { name: ClientRoutes.CATEGORY_PRODUCTS_ACTIVE },
        component: ClientProductsView,
        props: {
          routes: ClientCategoryProductsTabigation
        },
        meta: {
          title: "_.products_by_category"
        },
        children: [
          {
            path: "",
            name: ClientRoutes.CATEGORY_PRODUCTS_ALL,
            component: CProdsListing,
            props: {
              availableSorters: DefaultCProdSorters,
              availableFilters: () => [
                ProductCategoryFilter(),
                DatePurchasedFilter(),
                NextDueDateFilter(),
                PriceFilter(),
                ProductNameFilter(),
                StatusCodesFilter()
              ],
              routeFilters: vm => ({
                ...IsCategoryParam(vm)
              })
            }
          },
          {
            path: "active",
            name: ClientRoutes.CATEGORY_PRODUCTS_ACTIVE,
            component: CProdsListing,
            props: {
              availableSorters: DefaultCProdSorters,
              availableFilters: () => [
                ProductCategoryFilter(),
                DatePurchasedFilter(),
                NextDueDateFilter(),
                PriceFilter(),
                ProductNameFilter(),
                ActiveStatusCodesFilter()
              ],
              routeFilters: vm => ({
                ...IsCategoryParam(vm),
                ...IsActiveParam()
              })
            }
          },
          {
            path: "cancelled",
            name: ClientRoutes.CATEGORY_PRODUCTS_CANCELLED,
            component: CProdsListing,
            props: {
              availableSorters: () => [
                DatePurchasedSorter(),
                DateCancelledSorter(),
                StatusSorter()
              ],
              availableFilters: () => [
                ProductCategoryFilter(),
                DatePurchasedFilter(),
                PriceFilter(),
                ProductNameFilter(),
                CancelledStatusCodesFilter()
              ],
              routeFilters: vm => ({
                ...IsCategoryParam(vm),
                ...IsInactiveParam()
              })
            }
          }
        ]
      }
    ]
  },
  {
    path: `products/:contractId(${RegexMatch.UUID})/:productId(${RegexMatch.UUID})`,
    components: {
      default: {
        name: "ClientCProdWrapper",
        render: h => h("router-view")
      } as Component,
      layout: {
        functional: true,
        render: h =>
          h(ClientCProdLayout, {
            props: {
              routes: require("./menu").cProdTabigation
            }
          })
      } as Component
    },
    children: [
      {
        path: "",
        name: ClientRoutes.CONTRACT_PRODUCT,
        /* Note: Smart redirection handled from within component */
        component: () => import("@/views/client/products/product/index.vue"),
        beforeEnter: async (
          to: Route,
          from: Route,
          next: NavigationGuardNext
        ) => {
          if (to?.query?.orderComplete)
            await store.dispatch("ui/open/windowModal", {
              config: {
                component: () =>
                  import(
                    "@/components/app/global/orders/orderCompleteConfettiModal.vue"
                  ),
                width: 480,
                canCancel: ["escape", "outside"],
                props: {
                  orderNum: to?.query?.orderComplete
                }
              }
            });
          return next();
        },
        meta: {
          title: ({ cProdName }) => cProdName
        },
        children: [
          {
            path: "manage", // Redirect for old 'manage' route
            redirect: "overview"
          },
          {
            path: "overview",
            name: ClientRoutes.CONTRACT_PRODUCT_OVERVIEW,
            component: () =>
              import("@/views/client/products/product/overview/index.vue"),
            beforeEnter: (
              to: Route,
              from: Route,
              next: NavigationGuardNext
            ) => {
              const doBusReload =
                (!!from.matched.length && to.params.doBusReload) ?? true;
              if (doBusReload) $bus.$emit("cProdProvider::reloadData");
              return next();
            }
          },
          {
            path: "setup",
            name: ClientRoutes.CONTRACT_PRODUCT_SETUP,
            component: () =>
              import("@/views/client/products/product/setup/index.vue")
          },
          {
            path: "billing",
            alias: ["invoices", "credit-notes"],
            name: ClientRoutes.CONTRACT_PRODUCT_BILLING,
            component: () =>
              import("@/views/client/products/product/billing/index.vue")
          },
          {
            path: "settings",
            alias: ["delegates"],
            name: ClientRoutes.CONTRACT_PRODUCT_SETTINGS,
            component: () =>
              import("@/views/client/products/product/settings/index.vue")
          }
        ]
      }
    ]
  }
];
