import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import type { ApiPathGetter } from "@/models/api";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext }): ApiPathGetter =>
    ({ accountId }) => {
      const admin = `api/admin/accounts/${accountId}/affiliate/links`;
      const client = `api/accounts/${accountId}/affiliate/links`;
      const contextual = isAdminContext ? admin : client;
      return { client, admin, contextual };
    },
  scope:
    () =>
    ({ accountId, affiliateLinkId }) => {
      return `$affiliate_${accountId}_links_${affiliateLinkId}`;
    }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath(payload).contextual,
        storeModule: DataModules.AFFILIATES_LINKS,
        params: {
          with_staged_imports: 1,
          ...payload.params
        }
      },
      { root: true }
    );
  },
  get: ({ dispatch, getters, rootGetters }, payload) => {
    if (!rootGetters["user/can"]("get_affiliate_account_link")) return;

    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath(payload).contextual}/${payload.id}`,
        storeModule: DataModules.AFFILIATES_LINKS
      },
      { root: true }
    );
  },
  create: ({ dispatch, getters, rootGetters }, payload) => {
    if (!rootGetters["user/can"]("create_affiliate_account_link")) return;
    return dispatch(
      "data/create",
      {
        ...payload,
        path: getters.apiPath(payload).contextual,
        storeModule: DataModules.AFFILIATES_LINKS
      },
      { root: true }
    );
  },
  update: ({ dispatch, getters, rootGetters }, payload) => {
    if (!rootGetters["user/can"]("update_affiliate_account_link")) return;
    return dispatch(
      "data/update",
      {
        ...payload,
        path: `${getters.apiPath(payload).contextual}/${payload.id}`,
        storeModule: DataModules.AFFILIATES_LINKS
      },
      { root: true }
    );
  },
  remove: ({ dispatch, getters, rootGetters }, payload) => {
    if (!rootGetters["user/can"]("delete_affiliate_account_link")) return;

    return dispatch(
      "data/remove",
      {
        ...payload,
        path: `${getters.apiPath(payload).contextual}/${payload.id}`,
        storeModule: DataModules.AFFILIATES_LINKS
      },
      { root: true }
    );
  },
  openAddLinkModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/windowModal",
      {
        config: {
          width: 540,
          component: () =>
            import(
              "@/components/app/global/affiliate/addEditAffiliateLinkModal.vue"
            ),
          ...payload
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
