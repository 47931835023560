import { InvoiceStatusGroups } from "@/data/enums/invoice";
import { RegexMatch } from "@/data/enums/router";
import InvoicesListing from "@/components/app/global/invoices/invoicesListing.vue";
import {
  InvoiceStatusParam,
  DefaultInvoicesFilters,
  InvoiceStatusFilter
} from "@/data/filters/invoice";
import type { NavigationGuardNext } from "vue-router";
import { $bus } from "@/boot/event-bus";

export default [
  {
    path: "billing",
    component: {
      name: "AdminUpmindBilling",
      template: `<router-view v-bind="$attrs" />`
    },
    children: [
      {
        path: "",
        name: "admin__upmindBilling",
        redirect: { name: "admin__upmindInvoices" }
      },
      {
        path: "orders",
        component: {
          name: "AdminUpmindOrdersWrapper",
          template: `<router-view v-bind="$attrs" />`
        },
        children: [
          {
            path: "",
            name: "admin__upmindOrders",
            component: () =>
              import("@/views/admin/upmind/billing/orders/index.vue"),
            meta: {
              title: "_.orders",
              backTo: "_.orders"
            }
          },
          {
            path: `:orderId(${RegexMatch.UUID})`,
            name: "admin__upmindOrder",
            redirect: { name: "admin__upmindOrderSummary" },
            component: () =>
              import("@/views/admin/upmind/billing/orders/order/index.vue"),
            props: {
              routes: require("./menu").UpmindOrderTabigation
            },
            meta: {
              title: "_.order_id",
              backTo: "_.order",
              breadcrumb: {
                label: "_.order"
              }
            },
            children: [
              {
                path: "summary",
                name: "admin__upmindOrderSummary",
                component: () =>
                  import("@/components/app/global/orders/orderSummary.vue"),
                props: true,
                meta: {
                  title: "_.summary",
                  backTo: "_.summary"
                },
                beforeEnter: async (to, from, next: NavigationGuardNext) => {
                  $bus.$emit("orderProvider::reloadData");
                  next();
                }
              },
              {
                path: "invoices",
                name: "admin__upmindOrderInvoices",
                props: true,
                component: () =>
                  import("@/components/app/global/orders/orderInvoices.vue"),
                meta: {
                  title: "_.invoices",
                  backTo: "_.invoices"
                }
              },
              {
                path: "credit-notes",
                name: "admin__upmindOrderCreditNotes",
                props: true,
                component: () =>
                  import("@/components/app/global/orders/orderCreditNotes.vue"),
                meta: {
                  title: "_.credit_notes",
                  backTo: "_.credit_notes"
                }
              }
            ]
          }
        ]
      },
      {
        path: "invoices",
        name: "admin__upmindInvoices",
        redirect: { name: "admin__upmindAllInvoices" },
        component: () =>
          import("@/views/admin/upmind/billing/invoices/index.vue"),
        props: {
          routes: require("./menu").UpmindInvoicesRoutes
        },
        meta: {
          title: "_.invoices",
          backTo: "_.invoices"
        },
        children: [
          {
            path: "all",
            name: "admin__upmindAllInvoices",
            component: InvoicesListing,
            props: {
              availableFilters: () => [
                ...DefaultInvoicesFilters(),
                InvoiceStatusFilter()
              ]
            },
            meta: {
              title: "_.all_invoices",
              backTo: "_.all_invoices"
            }
          },
          {
            path: "paid",
            name: "admin__upmindPaidInvoices",
            component: InvoicesListing,
            props: {
              manualFilters: () =>
                InvoiceStatusParam(...InvoiceStatusGroups.PAID)
            },
            meta: {
              title: "_.paid_invoices",
              backTo: "_.paid_invoices"
            }
          },
          {
            path: "unpaid",
            name: "admin__upmindUnpaidInvoices",
            component: InvoicesListing,
            props: {
              manualFilters: () =>
                InvoiceStatusParam(...InvoiceStatusGroups.UNPAID)
            },
            meta: {
              title: "_.unpaid_invoices",
              backTo: "_.unpaid_invoices"
            }
          },
          {
            path: "credited",
            name: "admin__upmindCreditedInvoices",
            component: InvoicesListing,
            props: {
              manualFilters: () =>
                InvoiceStatusParam(...InvoiceStatusGroups.CREDITED)
            },
            meta: {
              title: "_.credited_invoices",
              backTo: "_.credited_invoices"
            }
          },
          {
            path: `:invoiceId(${RegexMatch.UUID})`,
            component: {
              name: "AdminUpmindInvoice",
              /** `<router-view :key />` is required to prevent component re-use */
              template: `<router-view v-bind="$attrs" :key='$route.params.invoiceId' />`
            },
            meta: {
              title: "_.invoice_#",
              breadcrumb: {
                label: "_action.edit"
              }
            },
            props: true,
            children: [
              {
                path: "",
                name: "admin__upmindInvoice",
                components: {
                  default: () =>
                    import(
                      "@/views/admin/upmind/billing/invoices/invoice/index.vue"
                    )
                }
              }
            ]
          },
          {
            path: "*",
            component: () => import("@/views/error/index.vue"),
            props: { code: 404 }
          }
        ]
      },
      {
        path: "credit-notes",
        component: {
          name: "AdminUpmindCreditNotesWrapper",
          template: `<router-view v-bind="$attrs" />`
        },
        children: [
          {
            path: "",
            name: "admin__upmindCreditNotes",
            component: () =>
              import("@/views/admin/upmind/billing/creditNotes/index.vue"),
            meta: {
              title: "_.credit_notes",
              backTo: "_.credit_notes"
            }
          },
          {
            path: `:invoiceId(${RegexMatch.UUID})`,
            name: "admin__upmindCreditNote",
            component: () =>
              import(
                "@/views/admin/upmind/billing/creditNotes/creditNote/index.vue"
              ),
            meta: {
              title: "_.credit_note_num"
            },
            props: true
          }
        ]
      },
      {
        path: "payment-methods",
        name: "admin__upmindPaymentMethods",
        component: () =>
          import("@/views/admin/upmind/billing/paymentMethods/index.vue"),
        meta: {
          title: "_.payment_methods",
          backTo: "_.payment_methods"
        }
      }
    ]
  }
];
