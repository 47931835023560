import _ from "@/boot/lodash";
import i18n from "@/i18n";
import type { ActionTree, GetterTree } from "vuex";
import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ApiPathGetter } from "@/models/api";
import type { IEmail } from "@/models/emails";
import type { IState } from "@/store";
import { Methods } from "@/models/methods";
import { ToastProgrammatic as $toast } from "buefy";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext, isMockClientContext }): ApiPathGetter =>
    clientId => {
      const admin = `api/admin/clients/${clientId}/emails`;
      const client = `api/clients/${clientId}/emails`;
      const contextual =
        isAdminContext && !isMockClientContext() ? admin : client;
      return { client, admin, contextual };
    },
  clientScope: () => id => {
    return `$client_${id}`;
  },
  selectorScope: () => id => {
    return `$client_${id}_selector`;
  },
  params: (state: IDataState, getters) => (id: IEmail["id"]) => {
    return _.get(state, `${getters.clientScope(id)}.params`, {});
  },
  selectorParams: (state: IDataState, getters) => (id: IEmail["id"]) => {
    return _.get(state, `${getters.selectorScope(id)}.params`, {});
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath(payload?.id).contextual,
        storeModule: DataModules.CLIENTS_EMAILS,
        params: {
          ...payload.params,
          with_staged_imports: 1
        }
      },
      { root: true }
    );
  },
  create: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/create",
      {
        ...payload,
        path: getters.apiPath(payload?.id).contextual,
        storeModule: DataModules.CLIENTS_EMAILS
      },
      { root: true }
    );
  },
  update: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/update",
      {
        ...payload,
        path: `${getters.apiPath(payload?.id).contextual}/${payload?.email_id}`,
        storeModule: DataModules.CLIENTS_EMAILS
      },
      { root: true }
    );
  },
  remove: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/remove",
      {
        ...payload,
        path: `${getters.apiPath(payload?.id).contextual}/${payload?.email_id}`,
        storeModule: DataModules.CLIENTS_EMAILS
      },
      { root: true }
    );
  },
  openEditModal: ({ dispatch }, email: IEmail) => {
    dispatch(
      "ui/open/windowModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/global/client/addEditClientEmailsModal.vue"
            ),
          props: {
            data: _.pick(email, [
              "email",
              "email_code",
              "email_country_code",
              "type"
            ]),
            clientId: email.client_id,
            actionType: "update",
            emailId: email.id
          },
          width: 480
        }
      },
      { root: true }
    );
  },
  confirmDelete: async (
    { dispatch, rootGetters },
    {
      email,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onConfirm = () => {},
      onSuccess = () => {
        $toast.open({
          message: i18n.t("_.object_successfully_removed", {
            object: i18n.t("_.email")
          }) as string
        });
      },
      onError = error => {
        dispatch("api/handleValidationError", { error }, { root: true });
      }
    }: {
      email: IEmail;
      onConfirm: Function;
      onSuccess: Function;
      onError: Function;
    }
  ) => {
    if (!rootGetters["user/can"]("delete_client_email")) return;
    const confirm = await dispatch(
      "ui/open/confirmModal",
      {
        config: {
          props: {
            autoClose: false,
            mode: "button",
            title: i18n.t("_action.delete_object", {
              object: (i18n.t("_.email") as string).toLowerCase()
            }),
            confirmButtonText: i18n.t("_action.delete"),
            message: i18n.t("_sentence.delete_confirmation", {
              object: (i18n.t("_.email") as string).toLowerCase()
            })
          },
          events: {
            confirmed: async () => {
              try {
                await dispatch("remove", {
                  id: email.client_id,
                  email_id: email.id
                });
                await onSuccess();
              } catch (error) {
                await onError(error);
              }
              await onConfirm();
              confirm.close();
            }
          }
        }
      },
      { root: true }
    );
  },
  makeDefault: async (
    { dispatch, rootGetters },
    {
      email,
      onSuccess = () =>
        $toast.open({
          message: i18n.t("_.default_object_updated", {
            object: (i18n.t("_.email") as string).toLowerCase()
          }) as string
        }),
      onError = (error: Error) =>
        dispatch("api/handleValidationError", { error }, { root: true })
    }: {
      email: IEmail;
      onSuccess: Function;
      onError: Function;
    }
  ) => {
    if (!rootGetters["user/can"]("update_client_email")) return;
    try {
      await dispatch("update", {
        id: email.client_id,
        email_id: email.id,
        data: { default: true }
      });
      await onSuccess();
      return Promise.resolve(true);
    } catch (error) {
      await onError(error);
      return;
    }
  },
  resendVerificationEmail: async (
    { dispatch, rootGetters, getters },
    {
      email,
      onSuccess = () =>
        $toast.open({
          message: i18n.t("_.email_resent") as string
        }),
      onError = (error: Error) =>
        dispatch("api/handleValidationError", { error }, { root: true })
    }: {
      email: IEmail;
      onSuccess: Function;
      onError: Function;
    }
  ) => {
    if (!rootGetters["user/can"]("request_verification_client_email")) return;
    try {
      await dispatch(
        "data/callApi",
        {
          method: Methods.PATCH,
          path: `${getters.apiPath(email.client_id).contextual}/${
            email.id
          }/send_verify`,
          storeModule: DataModules.CLIENTS_EMAILS
        },
        { root: true }
      );
      await onSuccess();
      return Promise.resolve(true);
    } catch (error) {
      await onError(error);
      return;
    }
  },
  markAsVerified: async (
    { dispatch, rootGetters },
    {
      email,
      onSuccess = () =>
        $toast.open({
          message: i18n.t("_.email_verified") as string
        }),
      onError = (error: Error) =>
        dispatch("api/handleValidationError", { error }, { root: true })
    }: {
      email: IEmail;
      onSuccess: Function;
      onError: Function;
    }
  ) => {
    if (!rootGetters["user/can"]("update_client_email")) return;
    try {
      await dispatch("update", {
        id: email.client_id,
        email_id: email.id,
        data: { verified: 1 }
      });
      await onSuccess();
      return Promise.resolve(true);
    } catch (error) {
      await onError(error);
      return;
    }
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
