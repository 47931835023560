import type { ActionTree, GetterTree } from "vuex";
import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ApiPathGetter } from "@/models/api";
import type { IState } from "@/store";
import { Methods } from "@/models/methods";
import { WALLET_REFUND_SOURCE } from "@/data/enums/wallet";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext, isMockClientContext }): ApiPathGetter =>
    ({ accountId }) => {
      const admin = `api/admin/wallet/${accountId}`;
      const client = `api/wallet`;
      const contextual =
        isAdminContext && !isMockClientContext() ? admin : client;
      return { client, admin, contextual };
    },
  balanceScope:
    () =>
    (accountId = "") => {
      return `$balance_${accountId}`;
    },
  transactionsListScope:
    () =>
    (accountId = "") => {
      return `$transactions_list_${accountId}`;
    }
};

const actions: ActionTree<IDataState, IState> = {
  balance: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        scope: payload.scope || getters["balanceScope"](payload),
        path: `${getters.apiPath(payload).contextual}/balance`,
        storeModule: DataModules.WALLETS,
        params: {
          with_staged_imports: 1,
          ...payload.params
        }
      },
      { root: true }
    );
  },
  refund: ({ dispatch, getters }, payload) => {
    const path =
      payload.refundSource === WALLET_REFUND_SOURCE.ORIGINAL_SOURCE
        ? "refund"
        : payload.refundSource === WALLET_REFUND_SOURCE.MANUAL
        ? "manual_refund"
        : "";

    return dispatch(
      "data/callApi",
      {
        method: Methods.POST,
        path: `${getters.apiPath(payload).admin}/${path}`,
        storeModule: DataModules.WALLETS,
        requestConfig: { data: payload.data }
      },
      { root: true }
    );
  },
  adjustWalletBalance: ({ dispatch, getters }, { accountId, data }) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.POST,
        path: `${getters.apiPath({ accountId }).admin}/adjust_balance`,
        storeModule: DataModules.WALLETS,
        requestConfig: { data }
      },
      { root: true }
    );
  },
  transactionsList: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        scope:
          payload.scope || getters["transactionsListScope"](payload.accountId),
        ...payload,
        path: `${getters.apiPath(payload).contextual}/transactions`,
        storeModule: DataModules.WALLETS,
        params: {
          with_staged_imports: 1,
          ...payload.params
        }
      },
      { root: true }
    );
  },
  openTransactionsModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/global/wallets/walletTransactionsModal.vue"
            ),
          ...payload
        }
      },
      { root: true }
    );
  },
  openTopUpModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/modal",
      {
        config: {
          component: () =>
            import("@/components/app/global/wallets/topUpWalletModal.vue"),
          width: 740,
          ...payload
        }
      },
      { root: true }
    );
  },
  openAdjustWalletBalanceModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/windowModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/global/wallets/adjustWalletBalanceModal.vue"
            ),
          width: 480,
          ...payload
        }
      },
      { root: true }
    );
  },
  openRefundFromWalletModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/modal",
      {
        config: {
          component: () =>
            import("@/components/app/global/wallets/refundFromWalletModal.vue"),
          width: 740,
          ...payload
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
