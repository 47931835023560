import Vue from "vue";
import type { ActionTree, MutationTree } from "vuex";
import type { IState } from "@/store";

export interface IUtilsScriptState {
  errored: Record<string, boolean>;
  loaded: Record<string, string>;
  loading: Record<string, Promise<void>>;
}

const initialState = (): IUtilsScriptState => {
  return {
    errored: {},
    loaded: {},
    loading: {}
  };
};

const actions: ActionTree<IUtilsScriptState, IState> = {
  load: (
    { commit, state },
    {
      id,
      key,
      src,
      onError,
      onSuccess
    }: {
      id?: string;
      key: string;
      src: string;
      onSuccess?: Function;
      onError?: Function;
    }
  ) => {
    if (state.errored[key]) return Promise.reject();
    if (state.loaded[key]) return Promise.resolve();
    if (Object.keys(state.loading).includes(key)) return state.loading[key];
    commit("loading", {
      key,
      promise: new Promise<void>((resolve, reject) => {
        const script = document.createElement("script");
        if (id?.length) script.id = id;
        script.setAttribute("src", src);
        script.addEventListener("error", async () => {
          commit("errored", key);
          if (typeof onError === "function") await onError();
          return reject();
        });
        script.addEventListener("load", async () => {
          commit("loaded", { key, src });
          if (typeof onSuccess === "function") await onSuccess();
          return resolve();
        });
        script.async = true;
        document.head.appendChild(script);
      }).finally(() => commit("loading", { key }))
    });
    return state.loading[key];
  }
};

const mutations: MutationTree<IUtilsScriptState> = {
  errored: (state, key: string) => {
    Vue.set(state.errored, key, true);
  },
  loaded: (state, { key, src }: { key: string; src: string }) => {
    Vue.set(state.loaded, key, src);
  },
  loading: (
    state,
    { key, promise }: { key: string; promise?: Promise<any> }
  ) => {
    if (!promise) return Vue.delete(state.loading, key);
    Vue.set(state.loading, key, promise);
  }
};

export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations
};
