import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import { UUID } from "@/data/enums";
import type { ApiPathGetter } from "@/models/api";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  scope: () => id => {
    return `$stat_${id}`;
  },
  apiPath:
    (s, g, rS, { isAdminContext, isMockClientContext }): ApiPathGetter =>
    ({ brandId, accountId, clientId, userId } = {}) => {
      const prepend =
        isAdminContext && !isMockClientContext() ? "api/admin" : "api";
      if (userId) return `api/admin/hooks/logs/user/${userId}`;
      if (accountId) return `${prepend}/hooks/logs/account/${accountId}`;
      if (clientId) return `${prepend}/hooks/logs/client/${clientId}`;
      if (!!brandId && brandId !== UUID.ORG)
        return `api/admin/hooks/logs/brand/${brandId}`;
      return `${prepend}/hooks/logs`; // IMPORTANT - THIS WILL ONLY RETURN LOGS FOR EVENTS BY LOGGED IN USER/ACTOR
    }
};

const actions: ActionTree<IDataState, IState> = {
  get: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath(payload)}/${payload?.id}`,
        storeModule: DataModules.HOOKS_LOGS
      },
      { root: true }
    );
  },
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath(payload),
        storeModule: DataModules.HOOKS_LOGS,
        splitCount: true,
        params: {
          with_staged_imports: 1,
          ...payload.params
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
