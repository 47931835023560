import i18n from "@/i18n";
import type { IMenuRoute } from "@/models/routes";

export function ticketsTabigation(): (IMenuRoute & { exact?: boolean })[] {
  return [
    {
      label: i18n.t("_.active_tickets"),
      to: { name: "admin__upmindActiveSupportTickets" }
    },
    {
      label: i18n.t("_.closed_tickets"),
      to: {
        name: "admin__upmindClosedSupportTickets"
      }
    }
  ];
}
