import { FraudDetectionTypes } from "@/data/enums";
import { FraudDetectionEntities as FraudDetectionEntitiesEnum } from "@/data/enums";
import { FraudEventTypes as FraudEventTypesEnum } from "@/data/enums";
import { FraudStatus } from "@/data/enums";
import { FraudPolicies } from "@/data/enums";
import i18n from "@/i18n";

const FraudTypes = () => {
  return [
    {
      id: FraudDetectionTypes.COUNTRY,
      label: i18n.t("_.country").toString()
    },
    { id: FraudDetectionTypes.IP, label: i18n.t("_.ip").toString() }
  ];
};

const FraudEntities = () => {
  return [
    {
      id: FraudDetectionEntitiesEnum.ORGANISATION,
      label: i18n.t("_.organisation")
    },
    { id: FraudDetectionEntitiesEnum.BRAND, label: i18n.t("_.brand") }
  ];
};

const FraudLevels = () => {
  return [
    {
      id: 1,
      label: i18n.t("_.whitelist"),
      desc: i18n.t("_sentence.fraud_whitelist_desc")
    },
    {
      id: 2,
      label: i18n.t("_.greylist"),
      desc: i18n.t("_sentence.fraud_greylist_desc")
    },
    {
      id: 3,
      label: i18n.t("_.blacklist"),
      desc: i18n.t("_sentence.fraud_blacklist_desc")
    }
  ];
};

const FraudEventTypes = () => {
  return [
    {
      id: FraudEventTypesEnum.CLIENT_POLICY,
      label: i18n.t("_.client_policy").toString(),
      eventDescPath: "_sentence.fraud_event_client_policy"
    },
    {
      id: FraudEventTypesEnum.CLIENT_STATUS,
      label: i18n.t("_.client_status").toString(),
      eventDescPath: "_sentence.fraud_event_client_status"
    },
    {
      id: FraudEventTypesEnum.CONTRACT_STATUS,
      label: i18n.t("_.contract_status").toString(),
      eventDescPath: "_sentence.fraud_event_contract_status"
    },
    {
      id: FraudEventTypesEnum.IP_MATCH,
      label: i18n.t("_.ip_match").toString(),
      eventDescPath: "_sentence.fraud_event_ip_match"
    },
    {
      id: FraudEventTypesEnum.IP_COUNTRY_MATCH,
      label: i18n.t("_.country_match").toString(),
      eventDescPath: "_sentence.fraud_event_ip_country_match"
    },
    {
      id: FraudEventTypesEnum.FRAUD_SCROE,
      label: i18n.t("_.fraud_score").toString(),
      eventDescPath: "_sentence.fraud_event_fraud_score"
    },
    {
      id: FraudEventTypesEnum.MANUAL_OVERRIDE,
      label: i18n.t("_.manual_override").toString(),
      eventDescPath: "_sentence.fraud_event_manual_override"
    },
    {
      id: FraudEventTypesEnum.MISSING_FRAUD_SERVICE,
      label: i18n.t("_.missing_fraud_service").toString(),
      eventDescPath: "_sentence.fraud_event_missing_fraud_service"
    },
    {
      id: FraudEventTypesEnum.MISSING_COUNTRY_IP,
      label: i18n.t("_.missing_country_ip").toString(),
      eventDescPath: "_sentence.fraud_event_missing_country_ip"
    },
    {
      id: FraudEventTypesEnum.INHERITED_FROM_CLIENT,
      label: i18n.t("_.inherited_from_client").toString(),
      eventDescPath: "_sentence.fraud_event_inherited_from_client"
    },
    {
      id: FraudEventTypesEnum.INHERITED_FROM_CONTRACT,
      label: i18n.t("_.inherited_from_contract").toString(),
      eventDescPath: "_sentence.fraud_event_inherited_from_contract"
    }
  ];
};

const FraudStatusMap = () => {
  return {
    [FraudStatus.NOT_FRAUD]: {
      id: FraudStatus.NOT_FRAUD,
      label: i18n.t("_.approved"),
      desc: object =>
        i18n.t("_sentence.fraud_status_approved_desc", { object }),
      type: "is-success",
      icon: "check-circle",
      class: "has-text-success"
    },
    [FraudStatus.REVIEW]: {
      id: FraudStatus.REVIEW,
      label: i18n.t("_.review"),
      desc: object => i18n.t("_sentence.fraud_status_review_desc", { object }),
      type: "is-warning",
      icon: "exclamation-circle",
      class: "has-text-warning"
    },
    [FraudStatus.FRAUD]: {
      id: FraudStatus.FRAUD,
      label: i18n.t("_.blocked"),
      desc: object => i18n.t("_sentence.fraud_status_blocked_desc", { object }),
      type: "is-danger",
      icon: "ban",
      class: "has-text-danger"
    }
  };
};

const FraudPoliciesMap = () => {
  return {
    [FraudPolicies.NEUTRAL]: {
      id: FraudPolicies.NEUTRAL,
      desc: i18n.t("_sentence.fraud_policy_neutral_desc"),
      order: 1
    },
    [FraudPolicies.REVIEW]: {
      id: FraudPolicies.REVIEW,
      desc: i18n.t("_sentence.fraud_policy_review_desc"),
      order: 2
    },
    [FraudPolicies.WHITELIST]: {
      id: FraudPolicies.WHITELIST,
      desc: i18n.t("_sentence.fraud_policy_whitelist_desc"),
      order: 3
    }
  };
};

export {
  FraudStatus,
  FraudPolicies,
  FraudDetectionTypes,
  FraudEntities,
  FraudLevels,
  FraudTypes,
  FraudEventTypes,
  FraudPoliciesMap,
  FraudStatusMap
};
