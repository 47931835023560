import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import type { ApiPathGetter } from "@/models/api";
import { Methods } from "@/models/methods";
import { ModalCancelBy } from "@/data/enums";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath: (): ApiPathGetter => () => {
    const admin = `api/admin/users`;
    return { admin };
  },
  scope: () => id => {
    return `$api_token_${id}`;
  }
};

const actions: ActionTree<IDataState, IState> = {
  get: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload.id}/api_token`,
        storeModule: DataModules.API_TOKENS
      },
      { root: true }
    );
  },
  revoke: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/remove",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload.id}/api_token`,
        storeModule: DataModules.API_TOKENS
      },
      { root: true }
    );
  },
  refreshApiToken: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.POST,
        path: `${getters.apiPath().admin}/${payload.id}/api_token`
      },
      { root: true }
    );
  },
  show: ({ dispatch }, token) => {
    return dispatch(
      "ui/open/windowModal",
      {
        config: {
          component: () =>
            import("@/components/app/admin/users/apiTokenModal.vue"),
          width: 640,
          canCancel: [ModalCancelBy.BUTTON],
          props: { token }
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
