import type { ActionTree, GetterTree, MutationTree } from "vuex";
import type { IState } from "@/store";
import _ from "@/boot/lodash";
import { SortableItems } from "@/data/enums/sortableItems";
import { setProp, deleteProp, DEFAULT_LIST_SCOPE } from "@/store/modules/data";

function findPath(data, categoryId) {
  let path = "";
  function dive(_data, _path) {
    for (let i = 0; i < _data.length; i++) {
      if (
        _data[i].id === categoryId &&
        _data[i].type === SortableItems.CATEGORY
      ) {
        path = _path + `${i}`;
      } else if (_.has(_data[i], "items")) {
        dive(_data[i].items, _path + `${i}.items.`);
      }
    }
  }

  dive(data, "");

  return path.split(".");
}

function findSelection(data, objectId) {
  let path = "";
  function dive(_data, _path) {
    for (let i = 0; i < _data.length; i++) {
      if (_data[i].isSelected && _data[i].id !== objectId) {
        path = _path + `${i}`;
      } else if (_.has(_data[i], "items")) {
        dive(_data[i].items, _path + `${i}.items.`);
      }
    }
  }

  dive(data, "");

  return path.split(".");
}

// Update Items Total
function updateParentCategoryTotal(state, scope, parentCategoryId, total) {
  const categoryDataPaths = _.concat(
    [scope, "data"],
    findPath(state[scope].data, parentCategoryId)
  );
  const categoryDataPath = categoryDataPaths.join("/").replace(/\//g, ".");
  const categoryScopeData = _.get(state, categoryDataPath, {});

  setProp(state, categoryDataPaths, {
    itemsTotal: _.get(categoryScopeData, "itemsTotal", 0) + total
  });
}

function getRemovedData(oldParent, movedCategory, categoryId) {
  return _.map(
    _.filter(
      _.get(
        oldParent,
        _.get(movedCategory, "level") === 1 ? "data" : "items",
        []
      ),
      (item: any) => item.id !== categoryId
    ),
    (item, index) => {
      item.index = index;
      if (item.type === SortableItems.ITEM) {
        item.order = index;
      }
      return item;
    }
  );
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISortableCategoriesState {}

const initialState: ISortableCategoriesState = {};

const getters: GetterTree<ISortableCategoriesState, IState> = {
  list:
    state =>
    ({ scope }) => {
      scope = scope || DEFAULT_LIST_SCOPE;
      const dataPath = [scope, "data"].join("/").replace(/\//g, ".");
      return _.get(state, dataPath, null);
    },
  listArray:
    (state, getters) =>
    ({ scope }) => {
      scope = scope || DEFAULT_LIST_SCOPE;
      return _.orderBy(_.values(getters.list({ scope })), "_order");
    },
  scope:
    () =>
    (brandId, type, scopeEnding = "") => {
      return `$${type}_${brandId}${scopeEnding ? `_${scopeEnding}` : ""}`;
    },
  getMainScope: state => {
    return _.find(_.keys(state), key => {
      return key.includes("main");
    });
  },
  getCategoryItems:
    state =>
    ({ scope, categoryId }) => {
      const dataPaths = _.concat(
        [scope, "data"],
        findPath(state[scope].data, categoryId)
      );
      const dataPath = dataPaths.join("/").replace(/\//g, ".");
      const scopeData = _.get(state, dataPath, {});
      return _.get(scopeData, "items", []);
    }
};

const actions: ActionTree<ISortableCategoriesState, IState> = {
  binList: ({ commit }, payload) => {
    commit("binData", { scope: payload.scope });
  }
};

const mutations: MutationTree<ISortableCategoriesState> = {
  binData: (state, { scope }) => {
    const dataPath = [scope].join("/").replace(/\//g, ".");
    deleteProp(state, dataPath.split("."));
  },

  setInitialState: (state, { scope, data, total }) => {
    setProp(state, [scope], {
      data: _.orderBy(data, "order", "asc"),
      total: total
    });
  },

  updateCategory: (state, { scope, categoryId, data }) => {
    const dataPaths = _.concat(
      [scope, "data"],
      findPath(state[scope].data, categoryId)
    );

    setProp(state, dataPaths, {
      ...data
    });
  },

  updateItem: (state, { scope, categoryId, itemId, data }) => {
    const dataPaths = _.concat(
      [scope, "data"],
      findPath(state[scope].data, categoryId)
    );

    const dataPath = dataPaths.join("/").replace(/\//g, ".");
    const scopeData = _.get(state, dataPath, {});

    setProp(state, dataPaths, {
      items: _.map(_.get(scopeData, "items", []), item => {
        if (item.id === itemId) {
          item = _.merge(item, data);
        }
        return item;
      })
    });
  },

  addNewCategory: (state, { scope, categoryId, data }) => {
    const dataPaths = _.concat(
      [scope, "data"],
      categoryId ? findPath(state[scope].data, categoryId) : []
    );

    const dataPath = dataPaths.join("/").replace(/\//g, ".");
    const scopeData = _.get(state, dataPath, {});

    setProp(state, dataPaths, {
      items: _.map(
        _.concat(_.get(scopeData, "items", []), [
          {
            ...data,
            isOpened: false,
            isLoading: false,
            isInitialising: false,
            isFullyLoaded: false,
            offset: 0,
            type: SortableItems.CATEGORY,
            itemsTotal: 0,
            level: _.get(scopeData, "level", 0) + 1,
            parentCategories: _.concat(
              _.get(scopeData, "parentCategories", []),
              [_.pick(scopeData, ["id", "index", "level", "name"])]
            ),
            items: []
          }
        ]),
        (item, index) => {
          item.index = index;
          return item;
        }
      )
    });
  },

  addParentCategory: (state, { scope, data }) => {
    const dataPaths = [scope];
    const dataPath = dataPaths.join("/").replace(/\//g, ".");
    const scopeData = _.get(state, dataPath, {});

    setProp(state, dataPaths, {
      data: _.map(
        _.concat(_.get(scopeData, "data", []), [
          {
            ...data,
            isOpened: false,
            isLoading: false,
            isInitialising: false,
            isFullyLoaded: false,
            offset: 0,
            type: SortableItems.CATEGORY,
            itemsTotal: 0,
            level: 1,
            parentCategories: [],
            items: []
          }
        ]),
        (item, index) => {
          item.index = index;
          return item;
        }
      ),
      total: _.get(scopeData, "total") + 1
    });
  },

  updateCategoryItems: (state, { scope, categoryId, data }) => {
    const dataPaths = _.concat(
      [scope, "data"],
      findPath(state[scope].data, categoryId)
    );
    const dataPath = dataPaths.join("/").replace(/\//g, ".");
    const scopeData = _.get(state, dataPath, {});

    const items = _.filter(
      _.get(scopeData, "items", []),
      item => item.type === SortableItems.ITEM
    );

    const categories = _.filter(
      _.get(scopeData, "items", []),
      item => item.type === SortableItems.CATEGORY
    );

    setProp(state, dataPaths, {
      items: _.map(
        _.concat(
          _.orderBy(items, ["order", "asc"]),
          data.items,
          _.orderBy(categories, ["order", "asc"])
        ),
        (item, index) => {
          item.index = index;
          return item;
        }
      ),
      leftToLoad: data.total - data.items.length - items.length,
      totalToLoad: data.total,
      isFullyLoaded: items.length + data.items.length === data.total,
      offset: _.get(scopeData, "offset") + data.items.length,
      isLoading: false,
      isInitialising: false
    });
  },

  addNewItem: (state, { scope, categoryId, data }) => {
    if (!state[scope]) return;

    const dataPaths = _.concat(
      [scope, "data"],
      findPath(state[scope].data, categoryId)
    );

    const dataPath = dataPaths.join("/").replace(/\//g, ".");
    const scopeData = _.get(state, dataPath, {});

    setProp(
      state,
      dataPaths,
      _.merge(
        {
          itemsTotal: _.get(scopeData, "itemsTotal", 0) + 1
        },
        _.get(scopeData, "isFullyLoaded")
          ? {
              items: _.map(
                _.concat(
                  _.orderBy(
                    _.filter(
                      _.get(scopeData, "items", []),
                      item => item.type === SortableItems.ITEM
                    ),
                    ["order", "asc"]
                  ),
                  [
                    {
                      ...data,
                      type: SortableItems.ITEM
                    }
                  ],
                  _.orderBy(
                    _.filter(
                      _.get(scopeData, "items", []),
                      item => item.type === SortableItems.CATEGORY
                    ),
                    ["order", "asc"]
                  )
                ),
                (item, index) => {
                  item.index = index;
                  return item;
                }
              )
            }
          : {}
      )
    );

    _.forEach(_.get(scopeData, "parentCategories", []), parentCategory => {
      updateParentCategoryTotal(state, scope, parentCategory.id, 1);
    });
  },

  deleteItem: (state, { scope, categoryId, data }) => {
    const dataPaths = _.concat(
      [scope, "data"],
      findPath(state[scope].data, categoryId)
    );
    const dataPath = dataPaths.join("/").replace(/\//g, ".");
    const scopeData = _.get(state, dataPath, {});

    setProp(state, dataPaths, {
      items: _.map(
        _.filter(
          _.get(scopeData, "items", []),
          (item: any) => item.id !== data.id
        ),
        (item, index) => {
          item.index = index;
          if (item.type === SortableItems.ITEM) {
            item.order = index;
          }
          return item;
        }
      ),
      itemsTotal: _.get(scopeData, "itemsTotal") - 1,
      offset: _.get(scopeData, "offset", 0) - 1
    });

    _.forEach(_.get(scopeData, "parentCategories", []), parentCategory => {
      updateParentCategoryTotal(state, scope, parentCategory.id, -1);
    });
  },

  deleteCategory: (state, { scope, categoryId }) => {
    if (!state[scope]) {
      return;
    }

    let dataPaths = _.concat(
      [scope, "data"],
      findPath(state[scope].data, categoryId)
    );
    let dataPath = dataPaths.join("/").replace(/\//g, ".");
    const scopeData = _.get(state, dataPath, {});

    dataPaths = dataPaths.slice(0, -2);
    dataPath = dataPaths.join("/").replace(/\//g, ".");

    const newScopeData = _.get(state, dataPath, {});
    const newData = getRemovedData(newScopeData, scopeData, categoryId);

    setProp(
      state,
      dataPaths,
      _.get(scopeData, "level") === 1 ? { data: newData } : { items: newData }
    );

    _.forEach(_.get(scopeData, "parentCategories", []), parentCategory => {
      updateParentCategoryTotal(
        state,
        scope,
        parentCategory.id,
        -_.get(scopeData, "itemsTotal", 0)
      );
    });
  },

  pasteItem: (state, { scope, categoryId, data }) => {
    const dataPaths = _.concat(
      [scope, "data"],
      findPath(state[scope].data, categoryId)
    );
    const dataPath = dataPaths.join("/").replace(/\//g, ".");
    const scopeData = _.get(state, dataPath, {});

    _.get(scopeData, "items", []).splice(data.index, 0, data.item);

    setProp(state, dataPaths, {
      itemsTotal: _.get(scopeData, "itemsTotal", 0) + 1,
      items: _.map(_.get(scopeData, "items", []), (item, index) => {
        item.index = index;
        if (item.type === SortableItems.ITEM) {
          item.order = index;
        }
        return item;
      }),
      offset: _.get(scopeData, "offset", 0) + 1
    });

    _.forEach(_.get(scopeData, "parentCategories", []), parentCategory => {
      updateParentCategoryTotal(state, scope, parentCategory.id, 1);
    });
  },

  pasteCategory: (state, { scope, categoryId, data }) => {
    let dataPaths = _.concat(
      [scope, "data"],
      findPath(state[scope].data, categoryId)
    );

    if (categoryId === "") {
      dataPaths = dataPaths.slice(0, -2);
    }

    const dataPath = dataPaths.join("/").replace(/\//g, ".");
    const scopeData = _.get(state, dataPath, {});

    _.get(scopeData, categoryId === "" ? "data" : "items", []).splice(
      data.index,
      0,
      data.category
    );

    const newData = _.map(
      _.get(scopeData, categoryId === "" ? "data" : "items", []),
      (item, index) => {
        item.index = index;

        if (item.type === SortableItems.ITEM) {
          item.order = index;
        }

        item.level = _.get(scopeData, "level", 0) + 1;

        if (item.id === data.category.id) {
          item.parentCategories =
            categoryId === ""
              ? []
              : _.concat(_.get(scopeData, "parentCategories", []), [
                  _.pick(scopeData, ["id", "index", "level", "name"])
                ]);
        }

        return item;
      }
    );

    setProp(
      state,
      dataPaths,
      categoryId === "" ? { data: newData } : { items: newData }
    );

    if (categoryId) {
      updateParentCategoryTotal(
        state,
        scope,
        categoryId,
        _.get(data, "category.itemsTotal", 0)
      );
    }

    _.forEach(_.get(scopeData, "parentCategories", []), parentCategory => {
      updateParentCategoryTotal(
        state,
        scope,
        parentCategory.id,
        _.get(data, "category.itemsTotal", 0)
      );
    });
  },

  updateCategoryName: (state, { scope, categoryId, data }) => {
    const dataPaths = _.concat(
      [scope, "data"],
      findPath(state[scope].data, categoryId)
    );
    const dataPath = dataPaths.join("/").replace(/\//g, ".");
    const scopeData = _.get(state, dataPath, {});

    const updateParentCategoryName = (categoryId, parentCategoryId, name) => {
      const newDataPaths = _.concat(
        [scope, "data"],
        findPath(state[scope].data, categoryId)
      );
      const newDataPath = newDataPaths.join("/").replace(/\//g, ".");
      const newScopeData = _.get(state, newDataPath, {});

      setProp(state, newDataPaths, {
        parentCategories: _.map(
          _.get(newScopeData, "parentCategories", []),
          parentCategory => {
            if (parentCategory.id === parentCategoryId) {
              parentCategory.name = name;
            }
            return parentCategory;
          }
        )
      });

      _.forEach(
        _.filter(
          _.get(newScopeData, "items"),
          item => item.type === SortableItems.CATEGORY
        ),
        category => {
          updateParentCategoryName(category.id, parentCategoryId, name);
        }
      );
    };

    _.forEach(
      _.filter(
        _.get(scopeData, "items"),
        item => item.type === SortableItems.CATEGORY
      ),
      category => {
        updateParentCategoryName(category.id, categoryId, data.name);
      }
    );

    setProp(state, dataPaths, {
      ...data
    });
  },

  moveCategory: (state, { scope, categoryId, data }) => {
    const movedCategoryPaths = _.concat(
      [scope, "data"],
      findPath(state[scope].data, categoryId)
    );
    const movedCategoryPath = movedCategoryPaths.join("/").replace(/\//g, ".");
    const movedCategory = _.get(state, movedCategoryPath, {});

    // remove from old place
    const oldParentPaths = movedCategoryPaths.slice(0, -2);
    const oldParentPath = oldParentPaths.join("/").replace(/\//g, ".");

    const oldParent = _.get(state, oldParentPath, {});

    const removedData = getRemovedData(oldParent, movedCategory, categoryId);

    setProp(
      state,
      oldParentPaths,
      _.get(movedCategory, "level") === 1
        ? { data: removedData }
        : { items: removedData }
    );

    // move to a new place
    const newParentCategoryPaths = data.parentCategoryId
      ? _.concat(
          [scope, "data"],
          findPath(state[scope].data, data.parentCategoryId)
        )
      : [scope];

    const newParentCategoryPath = newParentCategoryPaths
      .join("/")
      .replace(/\//g, ".");
    const newParentCategory = _.get(state, newParentCategoryPath, {});

    _.get(
      newParentCategory,
      data.parentCategoryId ? "items" : "data",
      []
    ).splice(
      _.filter(
        _.get(newParentCategory, data.parentCategoryId ? "items" : "data", []),
        item => item.type === SortableItems.ITEM
      ).length + data.newCategoryOrder,
      0,
      movedCategory
    );

    const newData = _.map(
      _.get(newParentCategory, data.parentCategoryId ? "items" : "data", []),
      (item, index) => {
        item.index = index;

        if (item.type === SortableItems.ITEM) {
          item.order = index;
        }

        item.level = _.get(newParentCategory, "level", 0) + 1;

        if (item.id === categoryId) {
          item.parentCategories = data.parentCategoryId
            ? _.concat(_.get(newParentCategory, "parentCategories", []), [
                _.pick(newParentCategory, ["id", "index", "level", "name"])
              ])
            : [];
        }

        return item;
      }
    );

    setProp(
      state,
      newParentCategoryPaths,
      data.parentCategoryId ? { items: newData } : { data: newData }
    );
  },

  clearAllSelections: (state, { scope, objectId }) => {
    const dataPaths = _.concat(
      [scope, "data"],
      findSelection(state[scope].data, objectId)
    );

    if (_.get(dataPaths, "[2]", "") === "") return;

    setProp(state, dataPaths, {
      isSelected: false
    });
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
};
