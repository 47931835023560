import type { ActionTree, GetterTree } from "vuex";
import type { IDataState } from "@/store/modules/data/index";
import { DataModules } from "@/store/modules/data/modules";
import type { ApiPathGetter } from "@/models/api";
import type { IState } from "@/store";
import { ModalCancelByAll } from "@/data/enums";
import { ToastProgrammatic as $toast } from "buefy";
import i18n from "@/i18n";
import type { IChildAccount, IClient } from "@upmind-automation/types";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext, isMockClientContext }): ApiPathGetter =>
    clientId => {
      const admin = `api/admin/clients/${clientId}/child_configs`;
      const client = `api/clients/${clientId}/child_configs`;
      const contextual =
        isAdminContext && !isMockClientContext() ? admin : client;
      return { client, admin, contextual };
    }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath(payload?.id).contextual,
        storeModule: DataModules.CLIENTS_CHILD_ACCOUNTS
      },
      { root: true }
    );
  },
  create: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/create",
      {
        ...payload,
        path: getters.apiPath(payload?.id).contextual,
        storeModule: DataModules.CLIENTS_CHILD_ACCOUNTS
      },
      { root: true }
    );
  },
  update: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/update",
      {
        ...payload,
        path: `${getters.apiPath(payload?.id).contextual}/${payload?.configId}`,
        storeModule: DataModules.CLIENTS_CHILD_ACCOUNTS
      },
      { root: true }
    );
  },
  remove: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/remove",
      {
        ...payload,
        path: `${getters.apiPath(payload?.id).contextual}/${payload?.configId}`,
        storeModule: DataModules.CLIENTS_CHILD_ACCOUNTS
      },
      { root: true }
    );
  },
  detachChildAccount: async (
    { dispatch },
    {
      parentId,
      configId,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onConfirm = () => {},
      onSuccess = () => {
        $toast.open({
          message: i18n.t(
            "_sentence.related_accounts.child_account_successfully_detached"
          ) as string
        });
      },
      onError = error => {
        dispatch("api/handleValidationError", { error }, { root: true });
      }
    }: {
      parentId: IClient["id"];
      configId: IChildAccount["id"];
      onConfirm: Function;
      onSuccess: Function;
      onError: Function;
    }
  ) => {
    const confirm = await dispatch(
      "ui/open/confirmModal",
      {
        config: {
          props: {
            autoClose: false,
            mode: "button",
            title: i18n.t("_.detach_from_parent"),
            confirmButtonText: i18n.t("_action.detach"),
            message: i18n.t("_sentence.detach_child_account_confirmation")
          },
          events: {
            confirmed: async () => {
              try {
                await dispatch("remove", {
                  id: parentId,
                  configId
                });
                await onSuccess();
              } catch (error) {
                await onError(error);
              }
              await onConfirm();
              confirm.close();
            }
          }
        }
      },
      { root: true }
    );
  },
  openManageChildAccountModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/modal",
      {
        config: {
          width: 540,
          canCancel: ModalCancelByAll,
          component: () =>
            import(
              "@/components/app/global/client/addEditChildAccountModal.vue"
            ),
          ...payload
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
