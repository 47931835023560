import _ from "@/boot/lodash";
import type { IState } from "@/store";
import type { ActionTree, GetterTree } from "vuex";
import type { AxiosError } from "axios";
import { SnackbarProgrammatic as $snackbar } from "buefy";
import i18n from "@/i18n";
import dompurify from "dompurify";

export enum UpmindErrorCodes {
  IP_ADDRESS_DISALLOWED = "ip_address_disallowed"
}

const getters: GetterTree<{}, IState> = {
  hasBlockedIP: (state, getters) => (error: AxiosError | Error) => {
    const hasBlockedIP =
      (error as AxiosError)?.response?.data?.error?.code ===
      UpmindErrorCodes.IP_ADDRESS_DISALLOWED;
    return getters["is403"](error) && hasBlockedIP;
  },
  is401:
    (state, getters) =>
    (error: object): boolean =>
      getters["is"](error, 401),
  is403:
    (state, getters) =>
    (error: object): boolean =>
      getters["is"](error, 403),
  is404:
    (state, getters) =>
    (error: object): boolean =>
      getters["is"](error, 404),
  is409:
    (state, getters) =>
    (error: object): boolean =>
      getters["is"](error, 409),
  is429:
    (state, getters) =>
    (error: object): boolean =>
      getters["is"](error, 429),
  is:
    () =>
    (error: object, code: number): boolean =>
      _.get(error, "response.status") === code,
  errorMessage:
    () =>
    (error: object): string =>
      typeof error === "string"
        ? error
        : _.get(
            error,
            "response.data.error.message",
            _.get(error, "message", "")
          )
};

const actions: ActionTree<{}, IState> = {
  isNetworkError: (context, error: AxiosError | Error) => {
    return (
      (!!_.get(error, "isAxiosError") && !_.get(error, "response")) ||
      error.message === "Network Error"
    );
  },
  handleErrorSnackbar: ({ getters, dispatch }, error: string | object) => {
    const errorMessage = getters["errorMessage"](error);
    if (!errorMessage.length) return;
    dispatch("ui/showErrorSnackbar", { errorMessage }, { root: true });
  },
  handleError: ({ getters }, error: string | object) => {
    const errorMessage = getters["errorMessage"](error);
    if (!errorMessage.length) return;
    $snackbar.open({
      actionText: i18n.t("_action.dismiss") as string,
      duration: 3600,
      message: dompurify.sanitize(errorMessage),
      type: "is-danger"
    });
  },
  handleValidationError: ({ dispatch }, { error, vm, showToast = true }) => {
    const isValidationError = _.get(error, "response.status") === 422;
    const validationErrors = _.get(error, "response.data.error.data");
    if (showToast) {
      // If there is exactly one validation error
      if (isValidationError && _.keys(validationErrors).length === 1) {
        for (const errorKey in validationErrors) {
          dispatch("handleError", `${validationErrors[errorKey]}`);
        }
      } else {
        dispatch("handleError", error);
      }
    }
    if (isValidationError) {
      if (
        typeof validationErrors !== "object" ||
        _.isEmpty(_.get(vm, "observer", {}))
      ) {
        return;
      }
      vm.observer.setErrors(validationErrors);
    }
  }
};

export default {
  namespaced: false,
  getters,
  actions
};
