import _ from "@/boot/lodash";
import { BrandConfigKeys, CheckoutFlows, QUERY_PARAMS } from "@/data/constants";
import store from "@/store";

const isOnePageCheckoutFlow = () =>
  [CheckoutFlows.ONE_PAGE].includes(
    store.getters["brand/config"][BrandConfigKeys.CHECKOUT_FLOW]
  );

const BasketRoutes = [
  {
    label: "_.shop",
    to: (vm: any) => {
      return {
        name: "orderShopStep",
        query: _.omitBy(
          _.omit(vm.$route.query, [
            QUERY_PARAMS.PRODUCT_ID,
            QUERY_PARAMS.BASKET_PRODUCT_ID,
            QUERY_PARAMS.BILLING_CYCLE_MONTHS,
            QUERY_PARAMS.CROSS_SELL_PRODUCT,
            QUERY_PARAMS.SUBPRODUCT_IDS,
            QUERY_PARAMS.READ_MORE,
            QUERY_PARAMS.QUANTITY
          ]),
          (value, key) => _.includes(key, QUERY_PARAMS.SUBPRODUCT_QUANTITY)
        )
      };
    },
    isActive: (currentRoute, isActive) => {
      if (
        currentRoute.name === "orderProductViewStep" ||
        currentRoute.name === "orderCrossSellStep"
      )
        return true;
      return isActive;
    },
    isDisabled: vm => {
      return vm.basketData.basketIsComplete;
    }
  },
  {
    label: "_.checkout",
    if: isOnePageCheckoutFlow,
    isDisabled: vm => {
      if (!vm.basketData.basket) return true;
      if (vm.basketData.isEmptyBasket) return true;
      if (vm.basketData.basketIsComplete) return true;
      return false;
    },
    to: (vm: any) => {
      return {
        name: "orderCheckoutStep",
        query: _.omitBy(
          _.omit(vm.$route.query, [
            QUERY_PARAMS.CATEGORY_ID,
            QUERY_PARAMS.PRODUCT_ID,
            QUERY_PARAMS.BASKET_PRODUCT_ID,
            QUERY_PARAMS.BILLING_CYCLE_MONTHS,
            QUERY_PARAMS.CROSS_SELL_PRODUCT,
            QUERY_PARAMS.SUBPRODUCT_IDS,
            QUERY_PARAMS.READ_MORE,
            QUERY_PARAMS.QUANTITY
          ]),
          (value, key) => _.includes(key, QUERY_PARAMS.SUBPRODUCT_QUANTITY)
        )
      };
    }
  },
  {
    label: "_.basket",
    isDisabled: vm => {
      return !vm.basketData.basket || vm.basketData.basketIsComplete;
    },
    if: () => !isOnePageCheckoutFlow(),
    to: (vm: any) => {
      return {
        name: "orderBasketStep",
        query: _.omitBy(
          _.omit(vm.$route.query, [
            QUERY_PARAMS.CATEGORY_ID,
            QUERY_PARAMS.PRODUCT_ID,
            QUERY_PARAMS.BASKET_PRODUCT_ID,
            QUERY_PARAMS.BILLING_CYCLE_MONTHS,
            QUERY_PARAMS.CROSS_SELL_PRODUCT,
            QUERY_PARAMS.SUBPRODUCT_IDS,
            QUERY_PARAMS.READ_MORE,
            QUERY_PARAMS.QUANTITY
          ]),
          (value, key) => _.includes(key, QUERY_PARAMS.SUBPRODUCT_QUANTITY)
        )
      };
    }
  },
  {
    label: "_.authenticate",
    if: vm => {
      return vm.basketData.basket && vm.isGuest && !isOnePageCheckoutFlow();
    },
    to: () => {
      return {
        name: "orderAuthenticateStep"
      };
    }
  },
  {
    label: "_.checkout",
    isDisabled: vm => {
      return !vm.basketData.canEnterCheckout;
    },
    if: () => !isOnePageCheckoutFlow(),
    to: (vm: any) => {
      return {
        name: "orderCheckoutStep",
        query: _.omitBy(
          _.omit(vm.$route.query, [
            QUERY_PARAMS.CATEGORY_ID,
            QUERY_PARAMS.PRODUCT_ID,
            QUERY_PARAMS.BASKET_PRODUCT_ID,
            QUERY_PARAMS.BILLING_CYCLE_MONTHS,
            QUERY_PARAMS.CROSS_SELL_PRODUCT,
            QUERY_PARAMS.SUBPRODUCT_IDS,
            QUERY_PARAMS.READ_MORE,
            QUERY_PARAMS.QUANTITY
          ]),
          (value, key) => _.includes(key, QUERY_PARAMS.SUBPRODUCT_QUANTITY)
        )
      };
    }
  },
  {
    label: "_.complete",
    isDisabled: vm => {
      return !vm.basketData.basketIsComplete;
    },
    to: (vm: any) => {
      return {
        name: "orderCompleteStep",
        query: vm.$route.query
      };
    }
  }
];

export default BasketRoutes;
