import type { IUtilsRecaptchaState } from "@/store/modules/utils/recaptcha";
import type { IUtilsScriptState } from "@/store/modules/utils/script";
import type { IUtilsTimeState } from "@/store/modules/utils/time";

export interface IUtilsState {
  recaptcha: IUtilsRecaptchaState;
  script: IUtilsScriptState;
  time: IUtilsTimeState;
}

export default {
  namespaced: true,
  modules: {
    money: require("@/store/modules/utils/money").default,
    recaptcha: require("@/store/modules/utils/recaptcha").default,
    script: require("@/store/modules/utils/script").default,
    time: require("@/store/modules/utils/time").default
  }
};
