import i18n from "@/i18n";
import { FraudStatus } from "@/data/enums";
import { serialiseConfig } from "@/helpers/table";
import { InvoiceStatus } from "@/data/enums/invoice";
import { FilterOperators, Sorters } from "@/data/table";

export function invoicesTabigation() {
  return [
    {
      exact: true,
      label: i18n.t("_.overview"),
      to: {
        name: `adminInvoicesOverview`
      }
    },
    {
      label: i18n.t("_.invoices_list"),
      to: {
        name: "adminInvoicesList"
      }
    }
  ];
}

export const overdueInvoicesQuickFilter = {
  name: "adminInvoicesList",
  query: {
    IT1: serialiseConfig({
      constFilters: [
        {
          key: "status.code",
          operator: FilterOperators.EQUAL,
          value: [InvoiceStatus.OVERDUE]
        }
      ]
    })
  }
};

export const invoicesQuickFilters = [
  {
    label: "_.all",
    to: { name: "adminInvoicesList" }
  },
  {
    // paid
    label: "_.paid",
    to: {
      name: "adminInvoicesList",
      query: {
        IT1: serialiseConfig({
          constFilters: [
            {
              key: "status.code",
              operator: FilterOperators.EQUAL,
              value: [InvoiceStatus.PAID]
            }
          ]
        })
      }
    }
  },
  {
    // unpaid
    label: "_.unpaid",
    to: {
      name: "adminInvoicesList",
      query: {
        IT1: serialiseConfig({
          constFilters: [
            {
              key: "status.code",
              operator: FilterOperators.EQUAL,
              value: [InvoiceStatus.UNPAID, InvoiceStatus.ADJUSTED]
            }
          ]
        })
      }
    }
  },
  {
    label: "_.overdue",
    to: overdueInvoicesQuickFilter
  },
  {
    label: "_.cancelled",
    to: {
      name: "adminInvoicesList",
      query: {
        IT1: serialiseConfig({
          constFilters: [
            {
              key: "status.code",
              operator: FilterOperators.EQUAL,
              value: [InvoiceStatus.CANCELLED]
            }
          ]
        })
      }
    }
  },
  {
    label: "_.refunded",
    to: {
      name: "adminInvoicesList",
      query: {
        IT1: serialiseConfig({
          constFilters: [
            {
              key: "status.code",
              operator: FilterOperators.EQUAL,
              value: [InvoiceStatus.REFUNDED]
            }
          ]
        })
      }
    }
  },
  {
    label: "_.from_last_30_days",
    to: {
      name: "adminInvoicesList",
      query: {
        IT1: serialiseConfig({
          constFilters: [
            {
              key: "create_datetime",
              operator: FilterOperators.AFTER_RELATIVE,
              value: "-1_months"
            }
          ]
        })
      }
    }
  },
  {
    label: "_.pending_review",
    to: {
      name: "adminInvoicesList",
      query: {
        IT1: serialiseConfig({
          constFilters: [
            {
              key: "fraud_status",
              operator: FilterOperators.EQUAL,
              value: `${FraudStatus.REVIEW}`
            }
          ]
        })
      }
    }
  },
  {
    label: "_.blocked",
    to: {
      name: "adminInvoicesList",
      query: {
        IT1: serialiseConfig({
          constFilters: [
            {
              key: "fraud_status",
              operator: FilterOperators.EQUAL,
              value: `${FraudStatus.FRAUD}`
            }
          ]
        })
      }
    }
  }
];

export const creditNotesQuickFilters = [
  {
    label: "_.all",
    to: () => {
      return {
        name: "adminCreditNotes"
      };
    }
  },
  {
    label: "_.from_last_30_days",
    to: () => {
      return {
        name: "adminCreditNotes",
        query: {
          CNT1: serialiseConfig({
            sort: {
              field: "create_datetime",
              direction: Sorters.DESCENDING
            },
            constFilters: [
              {
                key: "create_datetime",
                operator: FilterOperators.AFTER_RELATIVE,
                value: "-1_months"
              }
            ]
          })
        }
      };
    }
  }
];

export const transactionsQuickFilters = [
  {
    label: "_.all",
    to: { name: "adminTransactions" }
  },
  {
    label: "_.from_last_30_days",
    to: {
      name: "adminTransactions",
      query: {
        TT1: serialiseConfig({
          constFilters: [
            {
              key: "created_at",
              operator: FilterOperators.AFTER_RELATIVE,
              value: "-1_months"
            }
          ]
        })
      }
    }
  }
];
