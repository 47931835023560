import type { Location, Route, RouteConfig } from "vue-router";
import { RegexMatch } from "@/data/enums/router";
import store from "@/store";
import _ from "@/boot/lodash";

export default [
  {
    path: `clients/:clientId(${RegexMatch.UUID})`,
    component: {
      name: "AdminClient",
      template: "<router-view :key='$route.params.clientId' />"
    },
    children: [
      {
        path: "",
        name: "adminClient",
        redirect: { name: "adminClientOverview" },
        component: () => import("@/views/admin/clients/client/index.vue"),
        props: {
          routes: require("./menu").clientOverview
        },
        meta: {
          title: ({ publicName }) => publicName
        },
        children: _.union(
          [
            {
              path: "overview",
              name: "adminClientOverview",
              component: () =>
                import("@/views/admin/clients/client/overview/index.vue"),
              props: { id: "ACV" },
              meta: {
                breadcrumb: {
                  label: "_action.edit"
                }
              }
            }
          ],
          require("./orders").default,
          require("./products").default,
          require("./billing").default,
          require("./support").default,
          require("./accounts").default,
          require("./logs").default,
          require("./settings").default
        )
      },
      {
        path: "",
        name: "adminClientQuickLookModal",
        beforeEnter: async (
          to: Route,
          from: Route,
          next: (options: boolean | Location | Location["path"]) => void
        ) => {
          const { clientId } = to.params;
          if (clientId) {
            store.dispatch("ui/open/slideModal", {
              config: {
                component: () =>
                  import(
                    "@/components/app/admin/clients/clientQuickLookModal.vue"
                  ),
                width: 480,
                canCancel: ["button", "escape", "outside"],
                props: to.params
              }
            });
          }
          store.dispatch("ui/endRouting");
          return next(false);
        }
      }
    ]
  }
] as RouteConfig[];
