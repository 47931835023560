import i18n from "@/i18n";
import type { IImportStep } from "@/models/imports";

export enum ImportStepCodes {
  AFFILIATE_ACCOUNTS = "affiliate_accounts",
  CLIENTS = "clients",
  CLIENT_PAYMENT_DETAILS = "client_payment_details",
  CONTRACTS = "contracts",
  INVOICES = "invoices",
  PRODUCTS = "products",
  TICKETS = "tickets",
  USERS = "users"
}

export const ImportStepMap = () => {
  return {
    [ImportStepCodes.CLIENTS]: {
      key: "clients",
      label: i18n.t("_.clients")
    },
    [ImportStepCodes.CLIENT_PAYMENT_DETAILS]: {
      key: "client_payment_details",
      label: i18n.t("_.client_payment_details")
    },
    [ImportStepCodes.CONTRACTS]: {
      key: "contracts",
      label: i18n.t("_.contracts")
    },
    [ImportStepCodes.PRODUCTS]: {
      key: "products",
      label: i18n.t("_.products")
    },
    [ImportStepCodes.USERS]: {
      key: "users",
      label: i18n.t("_.users")
    },
    [ImportStepCodes.INVOICES]: {
      key: "invoices",
      label: i18n.t("_.invoices")
    },
    [ImportStepCodes.TICKETS]: {
      key: "tickets",
      label: i18n.t("_.tickets")
    }
  };
};

export enum ImportStatusCodes {
  CANCELLED = "import_cancelled",
  COMPLETE = "import_complete",
  ERROR = "import_error",
  EXPORTING = "import_exporting",
  IN_PROGRESS = "import_in_progress",
  MAPPINGS_REQUIRED = "import_mappings_required",
  PENDING = "import_pending",
  READY = "import_step_ready",
  STEP_SKIPPED = "import_step_skipped",
  STAGED = "import_staged"
}

export enum ImportLogLevels {
  INFO = "info",
  WARNING = "warning",
  ERROR = "error"
}

export enum ImportFileScanStatus {
  ERROR = "error",
  SAFE = "safe",
  SCANNING = "new",
  UNSAFE = "unsafe"
}

export const ImportStatusMap = () => ({
  [ImportStatusCodes.PENDING]: {
    key: "pending",
    label: i18n.t("_.pending"),
    summary: values =>
      i18n.t("_sentence.imports.pending_status_summary", values),
    type: "is-caution"
  },
  [ImportStatusCodes.EXPORTING]: {
    key: "exporting",
    label: i18n.t("_.exporting"),
    summary: values =>
      i18n.t("_sentence.imports.exporting_status_summary", values),
    type: "is-info"
  },
  [ImportStatusCodes.MAPPINGS_REQUIRED]: {
    key: "mappings_required",
    label: i18n.t("_.mappings_required"),
    summary: (step: IImportStep) =>
      i18n.t("_sentence.imports.mappings_required_status_summary", step),
    type: "is-warning"
  },
  [ImportStatusCodes.IN_PROGRESS]: {
    key: "in_progress",
    label: i18n.t("_.in_progress"),
    summary: (step: IImportStep) =>
      i18n.t("_sentence.imports.importing_status_summary", step),
    type: "is-info"
  },
  [ImportStatusCodes.CANCELLED]: {
    key: "cancelled",
    label: i18n.t("_.cancelled"),
    summary: (step: IImportStep) =>
      i18n.t("_sentence.imports.cancelled_status_summary", step),
    type: "is-grey"
  },
  [ImportStatusCodes.ERROR]: {
    key: "error",
    label: i18n.t("_.error"),
    summary: (step: IImportStep) =>
      i18n.t("_sentence.imports.error_status_summary", step),
    type: "is-danger"
  },
  [ImportStatusCodes.STAGED]: {
    key: "staged",
    label: i18n.t("_.staged"),
    summary: (step: IImportStep) =>
      i18n.t("_sentence.imports.staged_status_summary", step),
    type: "is-dark"
  },
  [ImportStatusCodes.COMPLETE]: {
    key: "complete",
    label: i18n.t("_.complete"),
    summary: (step: IImportStep) =>
      i18n.t("_sentence.imports.complete_status_summary", {
        records: i18n.tc("_plurals.records", step.total_count).toString()
      }),
    type: "is-success"
  },
  [ImportStatusCodes.STEP_SKIPPED]: {
    key: "skipped",
    label: i18n.t("_.skipped"),
    summary: (step: IImportStep) =>
      i18n.t("_sentence.imports.step_skipped_summary", step),
    type: "is-grey"
  },
  [ImportStatusCodes.READY]: {
    key: "awaiting_continuation",
    label: i18n.t("_.awaiting_continuation"),
    summary: (step: IImportStep) =>
      i18n.t("_sentence.imports.awaiting_continuation_status_summary", step),
    type: "is-grey"
  }
});

export enum ImportTaxSettingTypes {
  INHERIT = 0,
  INCLUSIVE = 1,
  EXCLUSIVE = 2
}

export enum ImportTypes {
  CSV_DATA = "csv_data"
}
