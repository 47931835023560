import { FilterTypes } from "@/data/table";

export enum CustomFieldsMajorTypes {
  BASKET = "basket",
  CANCEL_REQUEST = "contract_request",
  CLIENT = "client",
  INVOICE = "invoice"
}

export enum CustomFieldsTypes {
  TEXT = 1,
  PASSWORD = 2,
  SELECT = 3,
  SELECT_RADIO = 4,
  TEXTAREA = 5,
  DATE = 6,
  NUMBER = 7,
  IMAGE = 8
}

export const CustomFieldsFilterTypes: {
  [key in CustomFieldsTypes]: string;
} = {
  [CustomFieldsTypes.TEXT]: FilterTypes.STRING,
  [CustomFieldsTypes.PASSWORD]: FilterTypes.STRING,
  [CustomFieldsTypes.SELECT]: FilterTypes.SELECT,
  [CustomFieldsTypes.SELECT_RADIO]: FilterTypes.SELECT_RADIO,
  [CustomFieldsTypes.TEXTAREA]: FilterTypes.STRING,
  [CustomFieldsTypes.DATE]: FilterTypes.DATE,
  [CustomFieldsTypes.NUMBER]: FilterTypes.NUMBER,
  [CustomFieldsTypes.IMAGE]: ""
};
