import _ from "@/boot/lodash";

export default {
  install: (Vue: any) => {
    // Lodash – https://www.npmjs.com/package/lodash
    Object.defineProperty(Vue.prototype, "$_", {
      value: _
    });
  }
};
