import _ from "@/boot/lodash";
import moment from "moment";
import {
  ApiOperators,
  CONSTANT_FILTERS_START_CHAR,
  FILTER_ARRAY_VALUES_SEPARATOR,
  FRONTEND_FILTER_ARRAY_VALUES_SEPARATOR,
  FILTER_OPERATORS,
  FILTER_TYPES_WITH_ARRAY_VALUES,
  FilterOperators,
  FilterTypes,
  RELATIVE_DATE_OPERATORS,
  RELATIVE_TIME_REGEX,
  ROUTE_QUERY_SEPARATOR,
  ROUTER_OPERATOR_SEPARATOR,
  Sorters
} from "@/data/table";
import type {
  IFilter,
  ISorting,
  ISimpleFilter,
  IOperator,
  ITableConfig
} from "@/models/tables";
import { BACKEND_DATETIME_FORMAT } from "@/data/date";

export function defaultTemplate(
  filter: any,
  operator: string,
  prefix = "filter",
  brackets = ["[", "]"]
) {
  const openBracket = brackets[0];
  const closeBracket = brackets[1];

  // filter[name|like]
  return `${prefix}${openBracket}${filter.key}${operator}${closeBracket}`;
}

export function filterOperatorToApiOperator(
  filterOperatorKey: FilterOperators
) {
  const operator = _.find(FILTER_OPERATORS(), ["key", filterOperatorKey]);
  if (operator === undefined) {
    throw `Operator with key '${filterOperatorKey}' doesn't exist!`;
  }
  return operator.apiOperator;
}

export function apiOperatorString(filterOperatorKey: FilterOperators) {
  const apiOperator = filterOperatorToApiOperator(filterOperatorKey);
  return apiOperator !== ApiOperators.DEFAULT ? `|${apiOperator}` : "";
}

/**
 * If we use multiple filters grouped into one
 * e.g bulkactions Complete show complete and incomplete
 * e.g bulk_action_complete&&bulk_action_incomplete will be replaced with bulk_action_complete,bulk_action_incomplete
 */
export function parseFrontEndFilterGroup(value: string) {
  if (!_.isString(value)) return value;
  return value.replace(
    new RegExp(FRONTEND_FILTER_ARRAY_VALUES_SEPARATOR, "g"),
    FILTER_ARRAY_VALUES_SEPARATOR
  );
}

export function parseObjectToFiltersMap(
  filters: Object,
  availableFilters: IFilter[]
) {
  return _.filter(
    _.map(filters, (value, filterKey) => {
      const regex = /^filter\[(.+?)(?:\|(.+?))?\]$/;
      const match = filterKey.match(regex);
      const key = match ? match[1] : null;
      const operatorKey = match ? match[2] : null;
      const availableFilter = _.find(availableFilters, ["key", key]) || {};
      let filterOperator =
        _.find(FILTER_OPERATORS(), ["key", operatorKey])?.key ||
        FilterOperators.EQUAL;

      if (
        availableFilter.type === FilterTypes.DATE &&
        ["+", "-"].includes(value[0])
      ) {
        filterOperator =
          value[0] === "-"
            ? FilterOperators.AFTER_RELATIVE
            : FilterOperators.BEFORE_RELATIVE;
      }

      if (
        availableFilter.type === FilterTypes.CUSTOM_NEGATIVE &&
        ["+", "-"].includes(value[0])
      ) {
        filterOperator =
          value[0] === "-"
            ? FilterOperators.AFTER_RELATIVE
            : FilterOperators.BEFORE_RELATIVE;
      }

      return {
        key,
        label: "",
        ...availableFilter,
        operator: filterOperator,
        value: [FilterTypes.SELECT_CHECKBOX, FilterTypes.SELECT_MULTI].includes(
          availableFilter.type
        )
          ? value.split(FILTER_ARRAY_VALUES_SEPARATOR)
          : value
      };
    }),
    parsedFilter => !!parsedFilter.type
  );
}

export function filterValueToApiValue(
  filterOperatorKey: FilterOperators,
  filterType: FilterTypes,
  value: any
) {
  value =
    filterType === FilterTypes.DATE &&
    !RELATIVE_DATE_OPERATORS.includes(filterOperatorKey)
      ? moment(
          moment(value, BACKEND_DATETIME_FORMAT, true).isValid() ? value : null
        ).format(BACKEND_DATETIME_FORMAT)
      : value;

  switch (filterOperatorKey) {
    case FilterOperators.CONTAINS:
      return `%${value}%`;

    case FilterOperators.STARTS_WITH:
      return `${value}%`;

    case FilterOperators.ENDS_WITH:
      return `%${value}`;

    case FilterOperators.GREATER_THAN:
    case FilterOperators.AFTER:
    case FilterOperators.LESS_THAN:
    case FilterOperators.BEFORE:
    case FilterOperators.NOT_EQUAL:
    case FilterOperators.LESS_THAN_OR_EQUAL_TO:
    case FilterOperators.OR_ON_BEFORE_DATE:
    case FilterOperators.GREATER_THAN_OR_EQUAL_TO:
    case FilterOperators.OR_ON_AFTER_DATE:
    case FilterOperators.ALL:
    case FilterOperators.BEFORE_RELATIVE:
    case FilterOperators.AFTER_RELATIVE:
    default:
      return _.isArray(value)
        ? value
            .map(i => parseFrontEndFilterGroup(i))
            .join(FILTER_ARRAY_VALUES_SEPARATOR)
        : parseFrontEndFilterGroup(value);
  }
}

export function getFiltersWithOperators({
  filters,
  template = defaultTemplate
}: {
  filters: IFilter[];
  template?: (
    filter: any,
    operator: string,
    prefix?: string,
    brackets?: []
  ) => string;
}) {
  if (_.isEmpty(filters)) {
    return null;
  }
  const newFilters: any = {};

  _.forEach(filters, (filter: IFilter) => {
    if (filter.requires) {
      Object.keys(filter.requires).forEach(key => {
        newFilters[key] = _.get(filter.requires, key);
      });
    }
    newFilters[template(filter, apiOperatorString(filter.operator))] =
      filterValueToApiValue(filter.operator, filter.type, filter.value);
  });

  return newFilters;
}

export function getOperatorFromValueAndApiOperator(
  value,
  operator: ApiOperators
): FilterOperators {
  if (operator === ApiOperators.LIKE) {
    return /^%\S+%$/.test(value)
      ? FilterOperators.CONTAINS
      : /^\S+%$/.test(value)
      ? FilterOperators.STARTS_WITH
      : /^%\S+$/.test(value)
      ? FilterOperators.ENDS_WITH
      : FilterOperators.EQUAL;
  }

  if (operator === ApiOperators.GREATER_THAN) {
    return moment(value, BACKEND_DATETIME_FORMAT, true).isValid()
      ? FilterOperators.AFTER
      : FilterOperators.GREATER_THAN;
  }

  if (operator === ApiOperators.LESS_THAN) {
    return moment(value, BACKEND_DATETIME_FORMAT, true).isValid()
      ? FilterOperators.BEFORE
      : FilterOperators.LESS_THAN;
  }

  if (operator === ApiOperators.LESS_THAN_OR_EQUAL_TO) {
    return moment(value, BACKEND_DATETIME_FORMAT, true).isValid()
      ? FilterOperators.OR_ON_BEFORE_DATE
      : FilterOperators.LESS_THAN_OR_EQUAL_TO;
  }

  if (operator === ApiOperators.GREATER_THAN_OR_EQUAL_TO) {
    return moment(value, BACKEND_DATETIME_FORMAT, true).isValid()
      ? FilterOperators.OR_ON_AFTER_DATE
      : FilterOperators.GREATER_THAN_OR_EQUAL_TO;
  }

  if (operator === ApiOperators.NOT_EQUAL) {
    return FilterOperators.NOT_EQUAL;
  }

  if (operator === ApiOperators.BEFORE) {
    return FilterOperators.BEFORE_RELATIVE;
  }

  if (operator === ApiOperators.AFTER) {
    return FilterOperators.AFTER_RELATIVE;
  }

  if (operator === ApiOperators.ALL) {
    return FilterOperators.ALL;
  }

  return FilterOperators.EQUAL;
}

export function serialiseFilter(filter: ISimpleFilter) {
  return `${filter.key}${ROUTER_OPERATOR_SEPARATOR}${
    filter.operator
  }${ROUTER_OPERATOR_SEPARATOR}${
    _.isArray(filter.value)
      ? (filter.value as string[]).join(FILTER_ARRAY_VALUES_SEPARATOR)
      : filter.value
  }`;
}

export function serialiseConfig({
  page = 1,
  limit = 10,
  querySeparator = ROUTE_QUERY_SEPARATOR,
  filters = [],
  constFilters = [],
  sort = {
    field: "created_at",
    direction: Sorters.DESCENDING
  }
}: {
  page?: number;
  limit?: number;
  querySeparator?: string;
  filters?: ISimpleFilter[];
  constFilters?: ISimpleFilter[];
  sort?: ISorting;
}) {
  let newQuery = `${page}${querySeparator}${limit}`;

  newQuery += `${querySeparator}${
    sort?.direction === Sorters.DESCENDING && sort.field
      ? Sorters.DESCENDING
      : ""
  }${sort?.field ? sort.field : "none"}`;

  _.forEach(filters, filter => {
    newQuery += `${querySeparator}${serialiseFilter(filter)}`;
  });

  _.forEach(constFilters, constFilter => {
    newQuery += `${querySeparator}${CONSTANT_FILTERS_START_CHAR}${serialiseFilter(
      constFilter
    )}`;
  });

  return newQuery;
}

export function deSerialiseFilter(param: string, filters: IFilter[]): IFilter {
  const filterParams: string[] = param.split(ROUTER_OPERATOR_SEPARATOR);
  const filter: IFilter = (_.find(filters, ["key", filterParams[0]]) ||
    {}) as IFilter;
  const type = _.get(filter, "type", FilterTypes.STRING);
  const value = FILTER_TYPES_WITH_ARRAY_VALUES.includes(type)
    ? filterParams[2].split(FILTER_ARRAY_VALUES_SEPARATOR)
    : filterParams[2];
  const operator = _.find(FILTER_OPERATORS(), (operator: IOperator) => {
    const operatorMatch =
      operator.key === filterParams[1] && operator.types.includes(type);

    if (!operatorMatch) return false;
    if (type !== FilterTypes.DATE) return true;

    return RELATIVE_TIME_REGEX.test(value as string)
      ? RELATIVE_DATE_OPERATORS.includes(operator.key)
      : !RELATIVE_DATE_OPERATORS.includes(operator.key);
  });

  return {
    ...filter,
    key: filterParams[0],
    value: value,
    operator: _.get(operator, "key", FilterOperators.EQUAL)
  };
}

export function deSerialisedConfig(
  serialisedConfig: string,
  filters: IFilter[],
  querySeparator = ROUTE_QUERY_SEPARATOR
): ITableConfig {
  if (_.isEmpty(serialisedConfig)) {
    return {
      filters: [],
      constFilters: []
    };
  }

  const queryParams: string[] = serialisedConfig.split(querySeparator);

  const config: ITableConfig = {
    page: +queryParams[0],
    limit: +queryParams[1],
    filters: [],
    constFilters: [],
    sort: {}
  };

  _.each(_.takeRight(queryParams, queryParams.length - 2), (param: string) => {
    if (_.includes(param, ROUTER_OPERATOR_SEPARATOR)) {
      if (param[0] !== CONSTANT_FILTERS_START_CHAR) {
        config.filters?.push(deSerialiseFilter(param, filters));
      } else {
        config.constFilters?.push(deSerialiseFilter(param.substr(1), filters));
      }
    } else {
      let field: string | null =
        param[0] === Sorters.DESCENDING
          ? param.substring(1, param.length)
          : param;

      field = field === "none" ? "" : field;

      config.sort = {
        field,
        direction:
          param[0] === Sorters.DESCENDING && field
            ? Sorters.DESCENDING
            : Sorters.ASCENDING
      };
    }
  });

  return config;
}
