import { mapState } from "vuex";
import type { ICurrency } from "@upmind-automation/types";
import type { IState } from "@/store";
import type { IBrand } from "@upmind-automation/types";

export default {
  computed: {
    ...mapState({
      brand: (state: IState): IBrand => state.brand
    }),
    brandId() {
      return this.brand?.id;
    },
    brandCurrencies() {
      return this.$_.orderBy(this.brand.currencies, "name");
    },
    brandCurrencyId() {
      return this.brand?.currency_id;
    },
    brandCurrency() {
      return this.$_.find(this.brandCurrencies, [
        "id",
        this.brandCurrencyId
      ]) as ICurrency | undefined;
    },
    brandCurrencyCode() {
      return this.brandCurrency?.code || "GBP";
    },
    brandCurrencyName() {
      return this.brandCurrency?.name || this.$t("_.brand_currency");
    },
    brandPriceListId() {
      return this.brand?.pricelist_id;
    }
  }
};
