import type { ActionTree, GetterTree } from "vuex";
import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ApiPathGetter } from "@/models/api";
import type { IState } from "@/store";
import { OnlineGatewayTypes } from "@/data/enums";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext, isMockClientContext }): ApiPathGetter =>
    (brandId, slug = "gateways") => {
      const admin = `api/admin/brands/${brandId}/${slug}`;
      const client = `api/brands/${brandId}/${slug}`;
      const contextual =
        isAdminContext && !isMockClientContext() ? admin : client;
      return { client, admin, contextual };
    },
  scope: () => id => {
    return `$brand_payment_providers_${id}`;
  }
};

export const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: payload?.stored
          ? getters.apiPath(payload?.brandId, "stored_gateways").contextual
          : getters.apiPath(payload?.brandId).contextual,
        storeModule: DataModules.BRAND_GATEWAYS,
        params: {
          limit: 0,
          ...payload.params
        }
      },
      { root: true }
    );
  },
  hasOnline: async ({ dispatch, getters }, payload) => {
    const response = await dispatch(
      "data/list",
      {
        params: {
          limit: "count",
          [`filter[gateway.type]`]: OnlineGatewayTypes.join(",")
        },
        path: getters.apiPath(payload?.brandId).contextual,
        storeModule: DataModules.BRAND_GATEWAYS,
        storeData: false,
        returnData: false
      },
      { root: true }
    );

    return response.total > 0;
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
