import { mapState } from "vuex";
import RequestPromises from "@/mixins/requestPromises";
import { DataModules } from "@/store/modules/data/modules";
import { FilterOperators, FilterTypes } from "@/data/table";
import { UUID } from "@/data/enums";
import type { PropType } from "vue";
import type { ITag } from "@/models/tags";
import type { IState } from "@/store";
import type { IFilter } from "@/models/tables";
import type { TranslateResult } from "vue-i18n";
import type { IBrand } from "@upmind-automation/types";

export default {
  mixins: [RequestPromises],
  props: {
    tagsBrandId: { type: String as PropType<IBrand["id"]>, default: UUID.ORG }
  },
  data: () => ({
    tagFilter: {} as IFilter
  }),
  computed: {
    ...mapState({
      tags(state: IState, getters): ITag[] {
        return getters[`data/listArray`]({
          storeModule: DataModules.TAGS,
          scope: this.scope
        });
      }
    })
  },
  created() {
    this.tagFilter = {
      operator: FilterOperators.ANY,
      type: FilterTypes.TAG,
      options: this.tags
    };
  },
  methods: {
    getTags(ignoreStored = false, withStagedImports = false) {
      return this.$store.dispatch(`data/${DataModules.TAGS}/list`, {
        params: this.$_.merge(
          { limit: 0, offset: 0, order: "-name" },
          withStagedImports ? { with_staged_imports: 1 } : {},
          this.tagsBrandId !== UUID.ORG
            ? { "filter[brand_id]": this.tagsBrandId }
            : {}
        ),
        vm: this,
        ignoreStored
      });
    },
    createTagFilter(
      key: string,
      label: string | TranslateResult,
      group: string
    ): IFilter {
      return this.$_.merge({}, this.tagFilter, {
        key,
        label,
        group,
        options: this.tags
      });
    }
  },
  beforeDestroy() {
    this.$store.dispatch("data/binList", {
      storeModule: DataModules.TAGS,
      vm: this
    });
  }
};
