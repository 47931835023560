import { mapState } from "vuex";
import type { IState } from "@/store";

export default {
  computed: {
    ...mapState({
      brand: (state: IState) => state.brand
    }),
    brandId() {
      return this.$_.get(this.brand, "id");
    },
    defaultPriceListId() {
      return this.$_.get(this.brand, "pricelist_id");
    },
    brandCurrencyId() {
      return this.$_.get(this.brand, "currency_id");
    },
    brandCurrencies() {
      return this.$_.orderBy(this.$_.get(this.brand, "currencies"), [
        "name",
        "asc"
      ]);
    },
    brandCurrency() {
      return this.$_.find(this.brandCurrencies, ["id", this.brandCurrencyId]);
    }
  }
};
