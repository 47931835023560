import type { ApiPathGetter } from "@/models/api";
import type { ICustomPage } from "@upmind-automation/types";
import type { IState } from "@/store";
import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { GetterTree } from "vuex";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (
      s,
      g,
      rS,
      { isAuthenticatedAdminContext, isMockClientContext }
    ): ApiPathGetter =>
    () => {
      const admin = "api/admin/custom_pages";
      const client = "api/custom_pages";
      const contextual =
        isAuthenticatedAdminContext && !isMockClientContext() ? admin : client;
      return { client, admin, contextual };
    },
  scope: () => (slug: string) => {
    return `$custom_page_${slug}`;
  }
};

const actions = {
  get: (
    { dispatch, getters },
    { slug, payload }: { slug: ICustomPage["slug"]; payload: any }
  ) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath().contextual}/${slug}`,
        storeModule: DataModules.CUSTOM_PAGES
      },
      { root: true }
    );
  },
  list: ({ dispatch, getters }, payload: any) => {
    return dispatch(
      "data/list",
      {
        path: getters.apiPath().contextual,
        storeModule: DataModules.CUSTOM_PAGES,
        callConfig: { withAccessToken: false },
        ...payload
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
