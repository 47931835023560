import { DocCategories } from "@/data/enums/docs";

export default [
  {
    path: "dashboard",
    redirect: { name: "adminDashboard" },
    components: {
      default: {
        name: "AdminDashboard",
        template: `<router-view />`
      },
      menu: () => import("@/components/base/MenuComponent.vue")
    },
    props: {
      menu: {
        routes: require("./menu").default
      }
    },
    meta: {
      breadcrumb: {
        label: "_.dashboard"
      }
    },
    children: [
      {
        path: "",
        name: "adminDashboard",
        components: {
          default: () => import("@/views/admin/dashboard/index.vue")
        },
        meta: {
          auth: ["list_clients", "list_users"],
          backTo: "_.dashboard",
          title: "_.dashboard",
          categorySlug: DocCategories.USING_UPMIND
        }
      },
      {
        path: "*",
        component: () => import("@/views/error/index.vue"),
        props: { code: 404 }
      }
    ]
  }
];
