import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import _ from "@/boot/lodash";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import { Methods } from "@/models/methods";
import i18n from "@/i18n";
import { ConfirmModalModes } from "@/data/enums";
import type { ApiPathGetter } from "@/models/api";
import { ToastProgrammatic as $toast } from "buefy";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext, isMockClientContext }): ApiPathGetter =>
    ({ contractId, productId }) => {
      const admin = `api/admin/contracts/${contractId}/products/${productId}`;
      const client = `api/contracts/${contractId}/products/${productId}`;
      const contextual =
        isAdminContext && !isMockClientContext() ? admin : client;
      return { client, admin, contextual };
    },
  apiProvRequestsPath:
    (s, getters, rS, { isAdminContext }) =>
    ({ contractId, productId } = {} as unknown) => {
      if (contractId && productId)
        return `${
          getters.apiPath({
            contractId,
            productId
          }).contextual
        }/provision_requests`;
      else return `${isAdminContext ? "api/admin" : "api"}/provision/requests`;
    },
  contractProductFieldsScope: () => (contractId, productId) => {
    return `$contract_${contractId}_product_${productId}_fields`;
  },
  contractProductFieldsValuesScope: () => (contractId, productId) => {
    return `$contract_${contractId}_product_${productId}_fields_values`;
  },
  contractProductFunctionsScope: () => (contractId, productId) => {
    return `$contract_${contractId}_product_${productId}_functions`;
  },
  contractProductProvisionRequestsScope: () => (contractId, productId) => {
    return `$contract_${contractId}_product_${productId}_requests`;
  },
  contractProductFunctionFieldsScope:
    () => (contractId, productId, functionId) => {
      return `$contract_${contractId}_product_${productId}_functions_${functionId}`;
    },
  configurationsScope: () => {
    return "$configurations";
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiProvRequestsPath(payload),
        storeModule: DataModules.PROVISIONING_CONTRACT_PRODUCTS,
        splitCount: true,
        params: {
          with_staged_imports: 1,
          ...payload.params
        }
      },
      { root: true }
    );
  },

  listContractProductFields: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `${getters.apiPath(payload).contextual}/provision_fields`,
        params: {
          limit: 0,
          with_staged_imports: 1
        },
        storeModule: DataModules.PROVISIONING_CONTRACT_PRODUCTS
      },
      { root: true }
    );
  },

  listContractProductFieldsValues: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `${getters.apiPath(payload).contextual}/provision_fields/values`,
        params: {
          limit: 0,
          with_staged_imports: 1
        },
        storeModule: DataModules.PROVISIONING_CONTRACT_PRODUCTS
      },
      { root: true }
    );
  },

  updateContractProductFieldsValues: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/update",
      {
        ...payload,
        path: `${getters.apiPath(payload).contextual}/provision_fields/values`,
        storeModule: DataModules.PROVISIONING_CONTRACT_PRODUCTS
      },
      { root: true }
    );
  },

  updateContractProductConfiguration: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PATCH,
        path: `${getters.apiPath(payload).contextual}/provision_configuration`,
        requestConfig: { data: payload.data },
        storeModule: DataModules.PROVISIONING_CONTRACT_PRODUCTS
      },
      { root: true }
    );
  },

  listContractProductFunctions: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `${getters.apiPath(payload).contextual}/provision_functions`,
        params: {
          limit: 0,
          with_staged_imports: 1,
          ...(payload.params || {})
        },
        storeModule: DataModules.PROVISIONING_CONTRACT_PRODUCTS
      },
      { root: true }
    );
  },

  listContractProductFunctionFields: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `${getters.apiPath(payload).contextual}/provision_functions/${
          payload?.functionId
        }/fields`,
        params: {
          limit: 0,
          with_staged_imports: 1
        },
        storeModule: DataModules.PROVISIONING_CONTRACT_PRODUCTS
      },
      { root: true }
    );
  },

  executeProvisioningFunction: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.POST,
        path: `${getters.apiPath(payload).contextual}/provision_requests`,
        requestConfig: {
          data: _.get(payload, "data")
        }
      },
      { root: true }
    );
  },

  runClientAreaProvisionRequests: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.POST,
        path: `${
          getters.apiPath(payload).client
        }/provision_requests/client_area`
      },
      { root: true }
    );
  },

  listContractProductProvisionRequests: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `${getters.apiPath(payload).contextual}/provision_requests`,
        storeModule: DataModules.PROVISIONING_CONTRACT_PRODUCTS,
        params: {
          with_staged_imports: 1,
          ...payload.params
        }
      },
      { root: true }
    );
  },

  getContractProductProvisionRequest: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath(payload).contextual}/provision_requests/${
          payload?.uuid
        }`,
        params: {
          with: "action_logs.action,results,status",
          with_staged_imports: 1
        },
        storeModule: DataModules.PROVISIONING_CONTRACT_PRODUCTS
      },
      { root: true }
    );
  },

  listProviderConfigurations: ({ dispatch, rootGetters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `${
          rootGetters.isAdminContext ? "api/admin" : "api"
        }/provision/configurations`,
        params: {
          limit: "0",
          [`filter[category.id]`]: _.get(payload, "categoryId"),
          with: "category,provider,contract_product_counts",
          order: "name",
          with_staged_imports: 1
        },
        storeModule: DataModules.PROVISIONING_CONTRACT_PRODUCTS
      },
      { root: true }
    );
  },

  openProductFunctionSetupModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/modal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/global/client/contractProducts/contractProductFunctionFieldsModal.vue"
            ),
          ...payload
        }
      },
      { root: true }
    );
  },

  openDisplayFieldsModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/modal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/global/client/contractProducts/contractProductDisplayFieldsModal.vue"
            ),
          ...payload
        }
      },
      { root: true }
    );
  },

  updateRequest: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/update",
      {
        ...payload,
        path: `${getters.apiProvRequestsPath()}/${payload.requestId}`,
        storeModule: DataModules.PROVISIONING_CONTRACT_PRODUCTS
      },
      { root: true }
    );
  },

  openCancelRequestModal: async ({ dispatch }, { requestId, onSuccess }) => {
    const confirm = await dispatch(
      "ui/open/confirmModal",
      {
        config: {
          props: {
            autoClose: false,
            confirmButtonText: i18n.t("_action.confirm"),
            mode: ConfirmModalModes.BUTTON,
            message: i18n.t("_sentence.cancel_provision_request")
          },
          canCancel: [],
          events: {
            confirmed: async () => {
              try {
                await dispatch("cancelRequest", { id: requestId });
                $toast.open({
                  message: i18n.t("_.cancellation_request_submitted") as string
                });
                onSuccess();
              } catch (error) {
                dispatch("api/handleError", error, {
                  root: true
                });
              } finally {
                confirm.close();
              }
            }
          }
        }
      },
      { root: true }
    );
  },

  cancelRequest: ({ dispatch, getters }, { id }) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PATCH,
        path: `${getters.apiProvRequestsPath()}/${id}/cancel`
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
