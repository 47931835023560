import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import type { ApiPathGetter } from "@/models/api";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext }): ApiPathGetter =>
    () => {
      const admin = "api/admin/accounts";
      const client = "api/accounts";
      const contextual = isAdminContext ? admin : client;
      return { client, admin, contextual };
    }
};

const actions: ActionTree<IDataState, IState> = {
  update: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/update",
      {
        path: `${getters.apiPath().contextual}/${payload.accountId}`,
        storeModule: DataModules.ACCOUNTS,
        data: payload.data
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
