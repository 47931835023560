export default {
  data: () => ({
    observer: {},
    failSilentIfObserverNotFound: false
  }),
  mounted() {
    this.$nextTick(() => {
      this.setValidationObserver();
    });
  },
  computed: {
    validationErrors() {
      return this.$_.isEmpty(this.observer) ? {} : this.observer.errors;
    },
    hasErrors() {
      let hasErrors = false;
      this.$_.forEach(this.validationErrors, error => {
        hasErrors = error.length !== 0;
        if (hasErrors) {
          return false;
        }
      });
      return hasErrors;
    }
  },
  methods: {
    getForm(vue) {
      if (!vue) return {};
      return this.$_.isEmpty(vue.$refs.observer)
        ? this.getForm(vue.$parent)
        : vue.$refs.observer;
    },
    setValidationObserver() {
      const observer = this.getForm(this);
      if (this.$_.isEmpty(observer) && !this.failSilentIfObserverNotFound)
        throw new Error("ValidationObserver not found!");
      this.observer = observer;
    },
    validate(silent = false) {
      if (this.$_.isEmpty(this.observer))
        throw new Error("ValidationObserver not found!");
      return this.observer.validate({ silent });
    },
    clearErrors() {
      this.$_.forEach(this.validationErrors, (error, key) => {
        this.validationErrors[key] = [];
      });
    },
    hasError(key) {
      return !this.$_.isEmpty(this.getError(key));
    },
    getError(key) {
      return this.$_.get(this.validationErrors, key, []);
    },
    getFirstError(key) {
      return this.getError(key)[0];
    },
    removeError(key) {
      if (!this.$_.has(this.validationErrors, key)) return;
      this.validationErrors[key] = [];
    }
  }
};
