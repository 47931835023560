import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionContext, ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import { Methods } from "@/models/methods";
import type { ApiPathGetter } from "@/models/api";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext, isMockClientContext }): ApiPathGetter =>
    ({ ticketId }) => {
      const admin = `api/admin/tickets/${ticketId}/messages`;
      const client = `api/tickets/${ticketId}/messages`;
      const contextual =
        isAdminContext && !isMockClientContext() ? admin : client;
      return { client, admin, contextual };
    }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }: ActionContext<IDataState, IState>, payload) => {
    const { ticketId } = payload;
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath({ ticketId }).contextual,
        storeModule: DataModules.TICKETS_MESSAGES
      },
      { root: true }
    );
  },
  get: ({ dispatch, getters }: ActionContext<IDataState, IState>, payload) => {
    const { ticketId, messageId } = payload;
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath({ ticketId }).contextual}/${messageId}`,
        storeModule: DataModules.TICKETS_MESSAGES,
        params: {
          with: "files"
        }
      },
      { root: true }
    );
  },
  deleteMessage: (
    { dispatch, getters }: ActionContext<IDataState, IState>,
    payload
  ) => {
    const { ticketId, messageId } = payload;
    return dispatch(
      "data/remove",
      {
        path: `${getters.apiPath({ ticketId }).contextual}/${messageId}`,
        storeModule: DataModules.TICKETS_MESSAGES,
        data: payload.data
      },
      { root: true }
    );
  },
  deleteFile: (
    { dispatch, getters }: ActionContext<IDataState, IState>,
    payload
  ) => {
    const { ticketId, messageId, fileId } = payload;
    return dispatch(
      "data/remove",
      {
        path: `${
          getters.apiPath({ ticketId }).contextual
        }/${messageId}/files/${fileId}`,
        storeModule: DataModules.TICKETS_MESSAGES
      },
      { root: true }
    );
  },
  openDeleteModal: ({ dispatch }, payload) => {
    dispatch(
      "ui/open/windowModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/global/tickets/deleteTicketMessageModal.vue"
            ),
          width: 480,
          ...payload
        }
      },
      { root: true }
    );
  },
  openDeletedMessageModal: ({ dispatch }, payload) => {
    dispatch(
      "ui/open/windowModal",
      {
        config: {
          component: () =>
            import("@/components/app/global/tickets/deletedMessageModal.vue"),
          canCancel: ["escape", "outside", "button"],
          width: 540,
          ...payload
        }
      },
      { root: true }
    );
  },
  downloadEmailFile: ({ dispatch, getters }, payload) => {
    const { ticketId, messageId } = payload;
    return dispatch(
      "data/callApi",
      {
        method: Methods.GET,
        path: `${getters.apiPath({ ticketId }).admin}/${messageId}/download`,
        returnData: false,
        requestConfig: { responseType: "arraybuffer" }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
