import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree } from "vuex";
import type { IState } from "@/store";
import type { IImport } from "@/models/imports";

const getApiPath = (importId: IImport["id"]) => `api/admin/imports/${importId}`;

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch }, { importId, payload }) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `${getApiPath(importId)}/logs`,
        storeModule: DataModules.IMPORTS_LOGS,
        params: {
          with: ["object"].join(),
          ...payload.params
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: {},
  actions
};
