import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import _ from "@/boot/lodash";
import type { ApiPathGetter } from "@/models/api";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath: (): ApiPathGetter => () => {
    const admin = "api/admin/provision";
    return { admin };
  },
  scope: () => categoryId => {
    return `$provision_category_${categoryId}_providers`;
  },
  providerFieldsScope: () => providerId => {
    return `$provision_providers_${providerId}_fields`;
  },
  configurationScope: () => configurationId => {
    return `$provision_configuration_${configurationId}`;
  },
  providerConfigurationsScope: () => providerId => {
    return `$provision_providers_${providerId}_configurations`;
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `${getters.apiPath().admin}/categories/${_.get(
          payload,
          "categoryId"
        )}/providers`,
        params: {
          limit: 0,
          with: ["logos"].join(),
          ...payload.params
        },
        storeModule: DataModules.PROVISIONING_PROVIDERS
      },
      { root: true }
    );
  },
  get: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${_.get(payload, "id")}`,
        storeModule: DataModules.PROVISIONING_PROVIDERS
      },
      { root: true }
    );
  },

  listProviderFields: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `${getters.apiPath().admin}/categories/${_.get(
          payload,
          "categoryId"
        )}/providers/${_.get(payload, "providerId")}/configuration_fields`,
        params: {
          limit: 0
        },
        storeModule: DataModules.PROVISIONING_PROVIDERS
      },
      { root: true }
    );
  },

  listProviderConfigurations: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `${getters.apiPath().admin}/categories/${_.get(
          payload,
          "categoryId"
        )}/providers/${_.get(payload, "providerId")}/configurations`,
        params: {
          limit: 0,
          order: "name"
        },
        storeModule: DataModules.PROVISIONING_PROVIDERS
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
