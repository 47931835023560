import type { ISorter } from "@/models/tables";
import i18n from "@/i18n";

export const NameSorter: () => ISorter = () => ({
  field: "name",
  label: i18n.t("_.name") as string
});

export const DateCreatedSorter: () => ISorter = () => ({
  field: "created_at",
  label: i18n.t("_.date_created") as string
});

export const DefaultSorters: () => ISorter[] = () => [
  DateCreatedSorter(),
  NameSorter()
];

export default DefaultSorters;
