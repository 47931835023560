import { RegexMatch } from "@/data/enums/router";
import TicketsListing from "@/components/app/global/tickets/ticketsListing.vue";
import { IsActiveParam, IsClosedParam } from "@/data/filters/tickets";
import type { Route } from "vue-router";

export default [
  {
    path: "tickets",
    component: {
      name: "AdminClientTicketsWrapper",
      template: `<router-view v-bind="$attrs" />`
    },
    children: [
      {
        path: "",
        name: "adminClientSupportTickets",
        component: () =>
          import("@/views/admin/clients/client/tickets/index.vue"),
        meta: {
          title: "_.tickets",
          breadcrumb: {
            label: "_action.edit"
          }
        },
        redirect: { name: "admin__clientActiveSupportTickets" },
        props: {
          routes: require("./menu").ticketsTabigation
        },
        children: [
          {
            path: "active",
            name: "admin__clientActiveSupportTickets",
            component: TicketsListing,
            props: (route: Route) => ({
              clientId: route.params?.clientId,
              routeFilters: () => ({
                ...IsActiveParam()
              }),
              ticketRoute: ticket => ({
                name: "adminClientSupportTicket",
                params: {
                  ticketId: ticket.id,
                  clientId: ticket.client_id
                }
              }),
              showStaged: true
            }),
            meta: {
              backTo: "_.client_active_support_tickets",
              title: "_.client_active_support_tickets"
            }
          },
          {
            path: "closed",
            name: "admin__clientClosedSupportTickets",
            component: TicketsListing,
            props: (route: Route) => ({
              clientId: route.params?.clientId,
              routeFilters: () => ({
                ...IsClosedParam()
              }),
              ticketRoute: ticket => ({
                name: "adminClientSupportTicket",
                params: {
                  ticketId: ticket.id,
                  clientId: ticket.client_id
                }
              }),
              showStaged: true
            }),
            meta: {
              backTo: "_.client_closed_support_tickets",
              title: "_.client_closed_support_tickets"
            }
          }
        ]
      },
      {
        path: `:ticketId(${RegexMatch.UUID})`,
        name: "adminClientSupportTicket",
        component: () =>
          import("@/views/admin/clients/client/tickets/ticket/index.vue"),
        props: true
      }
    ]
  }
];
