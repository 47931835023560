import store from "@/store";
import type { Component } from "vue";
import { ContractStatusCodes } from "@/models/contracts";
import type { Location, NavigationGuardNext, Route } from "vue-router";
import { AdminRoutes, RegexMatch } from "@/data/enums/router";
import { $bus } from "@/boot/event-bus";
import { BrandConfigKeys } from "@/data/constants";
import _ from "@/boot/lodash";

import CProdsListing from "@/components/app/global/contractProducts/cProdsListing.vue";
import {
  statusParam,
  IsSubscriptionParam,
  IsCategoryParam,
  IsOneTimePurchaseParam,
  IsSubscriptionFilter,
  IsOneTimeFilter,
  ClientIdFilter
} from "@/data/filters/contractProducts";

import { getDefaultCProdFilters } from "@/router/admin/contractProducts";
import {
  ContractProductsTypes,
  ContractProductsKinds
} from "@/data/enums/contractProducts";

import {
  DefaultCProdSorters,
  StatusSorter,
  DatePurchasedSorter,
  DateCancelledSorter
} from "@/data/sorters/contractProducts";

import CProdProvidersWrapper from "@/components/app/global/contractProducts/cProdProvidersWrapper.vue";

export default [
  {
    path: "products",
    name: "adminClientCProds",
    redirect: { name: "admin__clientAllProducts" }
  },
  {
    path: "products/all",
    name: "admin__clientAllProducts",
    redirect: { name: "admin__clientAllActiveProducts" },
    component: () =>
      import("@/views/admin/clients/client/contractProducts/index.vue"),
    props: () => ({
      routes: require("./menu").cProdAllProductsTabigation
    }),
    children: [
      {
        path: "active",
        component: () =>
          import(
            "@/views/admin/clients/client/contractProducts/products/index.vue"
          ),
        props: (route: Route) => ({
          clientId: route.params.clientId,
          cpType: ContractProductsTypes.ALL,
          cpKind: ContractProductsKinds.ACTIVE,
          routeName: "admin__clientAllActiveCategoryProducts",
          goBackRoute: { name: "admin__clientAllActiveProducts" }
        }),
        children: [
          {
            path: "",
            name: "admin__clientAllActiveProducts",
            component: CProdsListing,
            props: (route: Route) => ({
              clientId: route.params.clientId,
              availableSorters: DefaultCProdSorters,
              availableFilters: () => [
                ...getDefaultCProdFilters(),
                ClientIdFilter()
              ],
              routeFilters: () => ({
                ...statusParam([
                  ContractStatusCodes.ACTIVE,
                  ContractStatusCodes.AWAITING_ACTIVATION,
                  ContractStatusCodes.PENDING,
                  ContractStatusCodes.SUSPENDED
                ])
              })
            }),
            meta: {
              backTo: "_.active_products_and_services",
              title: "_.active_products_and_services"
            }
          },
          {
            path: `category/:categoryId(${RegexMatch.UUID})`,
            name: "admin__clientAllActiveCategoryProducts",
            component: CProdsListing,
            props: (route: Route) => ({
              clientId: route.params.clientId,
              availableSorters: DefaultCProdSorters,
              availableFilters: () => [
                ...getDefaultCProdFilters(),
                ClientIdFilter()
              ],
              routeFilters: vm => ({
                ...IsCategoryParam(vm),
                ...statusParam([
                  ContractStatusCodes.ACTIVE,
                  ContractStatusCodes.AWAITING_ACTIVATION,
                  ContractStatusCodes.PENDING,
                  ContractStatusCodes.SUSPENDED
                ])
              })
            }),
            meta: {
              backTo: "_.active_products_and_services",
              title: "_.active_products_and_services"
            }
          }
        ]
      },
      {
        path: "cancelled",
        component: () =>
          import(
            "@/views/admin/clients/client/contractProducts/products/index.vue"
          ),
        props: (route: Route) => ({
          clientId: route.params.clientId,
          cpType: ContractProductsTypes.ALL,
          cpKind: ContractProductsKinds.CANCELLED,
          routeName: "admin__clientAllCancelledCategoryProducts",
          goBackRoute: { name: "admin__clientAllCancelledProducts" }
        }),
        children: [
          {
            path: "",
            name: "admin__clientAllCancelledProducts",
            component: CProdsListing,
            props: (route: Route) => ({
              clientId: route.params.clientId,
              availableSorters: () => [
                StatusSorter(),
                DateCancelledSorter(),
                DatePurchasedSorter()
              ],
              availableFilters: () => [
                ...getDefaultCProdFilters(),
                ClientIdFilter()
              ],
              routeFilters: () => ({
                ...statusParam([
                  ContractStatusCodes.CANCELLED,
                  ContractStatusCodes.CLOSED
                ])
              })
            }),
            meta: {
              backTo: "_.cancelled_products_and_services",
              title: "_.cancelled_products_and_services"
            }
          },
          {
            path: `category/:categoryId(${RegexMatch.UUID})`,
            name: "admin__clientAllCancelledCategoryProducts",
            component: CProdsListing,
            props: (route: Route) => ({
              clientId: route.params.clientId,
              availableSorters: DefaultCProdSorters,
              availableFilters: () => [
                ...getDefaultCProdFilters(),
                ClientIdFilter()
              ],
              routeFilters: vm => ({
                ...IsCategoryParam(vm),
                ...statusParam([
                  ContractStatusCodes.CANCELLED,
                  ContractStatusCodes.CLOSED
                ])
              })
            }),
            meta: {
              backTo: "_.cancelled_products_and_services",
              title: "_.cancelled_products_and_services"
            }
          }
        ]
      },
      {
        path: "all",
        component: () =>
          import(
            "@/views/admin/clients/client/contractProducts/products/index.vue"
          ),
        props: (route: Route) => ({
          clientId: route.params.clientId,
          cpType: ContractProductsTypes.ALL,
          cpKind: ContractProductsKinds.ALL,
          routeName: "admin__clientAllCategoryProducts",
          goBackRoute: { name: "admin__clientAllAllProducts" }
        }),
        children: [
          {
            path: "",
            name: "admin__clientAllAllProducts",
            component: CProdsListing,
            props: (route: Route) => ({
              clientId: route.params.clientId,
              availableSorters: DefaultCProdSorters,
              availableFilters: () => [
                ...getDefaultCProdFilters(),
                ClientIdFilter()
              ],
              routeFilters: () => ({})
            }),
            meta: {
              backTo: "_.all_products_and_services",
              title: "_.all_products_and_services"
            }
          },
          {
            path: `category/:categoryId(${RegexMatch.UUID})`,
            name: "admin__clientAllCategoryProducts",
            component: CProdsListing,
            props: (route: Route) => ({
              clientId: route.params.clientId,
              availableSorters: DefaultCProdSorters,
              availableFilters: () => [
                ...getDefaultCProdFilters(),
                ClientIdFilter()
              ],
              routeFilters: vm => ({
                ...IsCategoryParam(vm)
              })
            }),
            meta: {
              backTo: "_.all_products_and_services",
              title: "_.all_products_and_services"
            }
          }
        ]
      }
    ]
  },
  {
    path: "products/subscriptions",
    name: "admin__clientSubscriptions",
    redirect: { name: "admin__clientActiveSubscriptions" },
    component: () =>
      import("@/views/admin/clients/client/contractProducts/index.vue"),
    props: () => ({
      routes: require("./menu").cProdSubscriptionsTabigation
    }),
    children: [
      {
        path: "active",
        component: () =>
          import(
            "@/views/admin/clients/client/contractProducts/products/index.vue"
          ),
        props: (route: Route) => ({
          clientId: route.params.clientId,
          cpType: ContractProductsTypes.SUBSCRIPTIONS,
          cpKind: ContractProductsKinds.ACTIVE,
          routeName: "admin__clientActiveSubscriptionsCategoryProducts",
          goBackRoute: { name: "admin__clientActiveSubscriptions" }
        }),
        children: [
          {
            path: "",
            name: "admin__clientActiveSubscriptions",
            component: CProdsListing,
            props: (route: Route) => ({
              clientId: route.params.clientId,
              availableSorters: DefaultCProdSorters,
              availableFilters: () => [
                ...getDefaultCProdFilters(),
                IsSubscriptionFilter(),
                ClientIdFilter()
              ],
              routeFilters: () => ({
                ...IsSubscriptionParam(),
                ...statusParam([
                  ContractStatusCodes.ACTIVE,
                  ContractStatusCodes.AWAITING_ACTIVATION,
                  ContractStatusCodes.PENDING,
                  ContractStatusCodes.SUSPENDED
                ])
              })
            }),
            meta: {
              backTo: "_.active_subscriptions",
              title: "_.active_subscriptions"
            }
          },
          {
            path: `category/:categoryId(${RegexMatch.UUID})`,
            name: "admin__clientActiveSubscriptionsCategoryProducts",
            component: CProdsListing,
            props: (route: Route) => ({
              clientId: route.params.clientId,
              availableSorters: DefaultCProdSorters,
              availableFilters: () => [
                ...getDefaultCProdFilters(),
                IsSubscriptionFilter(),
                ClientIdFilter()
              ],
              routeFilters: vm => ({
                ...IsCategoryParam(vm),
                ...IsSubscriptionParam(),
                ...statusParam([
                  ContractStatusCodes.ACTIVE,
                  ContractStatusCodes.AWAITING_ACTIVATION,
                  ContractStatusCodes.PENDING,
                  ContractStatusCodes.SUSPENDED
                ])
              })
            }),
            meta: {
              backTo: "_.active_subscriptions",
              title: "_.active_subscriptions"
            }
          }
        ]
      },
      {
        path: "cancelled",
        component: () =>
          import(
            "@/views/admin/clients/client/contractProducts/products/index.vue"
          ),
        props: (route: Route) => ({
          clientId: route.params.clientId,
          cpType: ContractProductsTypes.SUBSCRIPTIONS,
          cpKind: ContractProductsKinds.CANCELLED,
          routeName: "admin__clientCancelledSubscriptionsCategoryProducts",
          goBackRoute: { name: "admin__clientCancelledSubscriptions" }
        }),
        children: [
          {
            path: "",
            name: "admin__clientCancelledSubscriptions",
            component: CProdsListing,
            props: (route: Route) => ({
              clientId: route.params.clientId,
              availableSorters: () => [
                StatusSorter(),
                DateCancelledSorter(),
                DatePurchasedSorter()
              ],
              availableFilters: () => [
                ...getDefaultCProdFilters(),
                IsSubscriptionFilter(),
                ClientIdFilter()
              ],
              routeFilters: () => ({
                ...IsSubscriptionParam(),
                ...statusParam([
                  ContractStatusCodes.CANCELLED,
                  ContractStatusCodes.CLOSED
                ])
              })
            }),
            meta: {
              backTo: "_.cancelled_subscriptions",
              title: "_.cancelled_subscriptions"
            }
          },
          {
            path: `category/:categoryId(${RegexMatch.UUID})`,
            name: "admin__clientCancelledSubscriptionsCategoryProducts",
            component: CProdsListing,
            props: (route: Route) => ({
              clientId: route.params.clientId,
              availableSorters: DefaultCProdSorters,
              availableFilters: () => [
                ...getDefaultCProdFilters(),
                IsSubscriptionFilter(),
                ClientIdFilter()
              ],
              routeFilters: vm => ({
                ...IsCategoryParam(vm),
                ...IsSubscriptionParam(),
                ...statusParam([
                  ContractStatusCodes.CANCELLED,
                  ContractStatusCodes.CLOSED
                ])
              })
            }),
            meta: {
              backTo: "_.cancelled_subscriptions",
              title: "_.cancelled_subscriptions"
            }
          }
        ]
      },
      {
        path: "all",
        component: () =>
          import(
            "@/views/admin/clients/client/contractProducts/products/index.vue"
          ),
        props: (route: Route) => ({
          clientId: route.params.clientId,
          cpType: ContractProductsTypes.SUBSCRIPTIONS,
          cpKind: ContractProductsKinds.ALL,
          routeName: "admin__clientAllSubscriptionsCategoryProducts",
          goBackRoute: { name: "admin__clientAllSubscriptions" }
        }),
        children: [
          {
            path: "",
            name: "admin__clientAllSubscriptions",
            component: CProdsListing,
            props: (route: Route) => ({
              clientId: route.params.clientId,
              availableSorters: DefaultCProdSorters,
              availableFilters: () => [
                ...getDefaultCProdFilters(),
                IsSubscriptionFilter(),
                ClientIdFilter()
              ],
              routeFilters: () => ({
                ...IsSubscriptionParam()
              })
            }),
            meta: {
              backTo: "_.all_subscriptions",
              title: "_.all_subscriptions"
            }
          },
          {
            path: `category/:categoryId(${RegexMatch.UUID})`,
            name: "admin__clientAllSubscriptionsCategoryProducts",
            component: CProdsListing,
            props: (route: Route) => ({
              clientId: route.params.clientId,
              availableSorters: DefaultCProdSorters,
              availableFilters: () => [
                ...getDefaultCProdFilters(),
                IsSubscriptionFilter(),
                ClientIdFilter()
              ],
              routeFilters: vm => ({
                ...IsCategoryParam(vm),
                ...IsSubscriptionParam()
              })
            }),
            meta: {
              backTo: "_.all_subscriptions",
              title: "_.all_subscriptions"
            }
          }
        ]
      }
    ]
  },
  {
    path: "products/otps",
    name: "admin__clientOTPs",
    redirect: { name: "admin__clientActiveOTPs" },
    component: () =>
      import("@/views/admin/clients/client/contractProducts/index.vue"),
    props: () => ({
      routes: require("./menu").cProdOTPsTabigation
    }),
    children: [
      {
        path: "active",
        component: () =>
          import(
            "@/views/admin/clients/client/contractProducts/products/index.vue"
          ),
        props: (route: Route) => ({
          clientId: route.params.clientId,
          cpType: ContractProductsTypes.OTPS,
          cpKind: ContractProductsKinds.ACTIVE,
          routeName: "admin__clientActiveOTPsCategoryProducts",
          goBackRoute: { name: "admin__clientActiveOTPs" }
        }),
        children: [
          {
            path: "",
            name: "admin__clientActiveOTPs",
            component: CProdsListing,
            props: (route: Route) => ({
              clientId: route.params.clientId,
              availableSorters: () => [StatusSorter(), DatePurchasedSorter()],
              availableFilters: () => [
                ...getDefaultCProdFilters(),
                IsOneTimeFilter(),
                ClientIdFilter()
              ],
              routeFilters: () => ({
                ...IsOneTimePurchaseParam(),
                ...statusParam([
                  ContractStatusCodes.ACTIVE,
                  ContractStatusCodes.AWAITING_ACTIVATION,
                  ContractStatusCodes.PENDING,
                  ContractStatusCodes.SUSPENDED
                ])
              })
            }),
            meta: {
              backTo: "_.active_one_time_purchases",
              title: "_.active_one_time_purchases"
            }
          },
          {
            path: `category/:categoryId(${RegexMatch.UUID})`,
            name: "admin__clientActiveOTPsCategoryProducts",
            component: CProdsListing,
            props: (route: Route) => ({
              clientId: route.params.clientId,
              availableSorters: () => [StatusSorter(), DatePurchasedSorter()],
              availableFilters: () => [
                ...getDefaultCProdFilters(),
                IsOneTimeFilter(),
                ClientIdFilter()
              ],
              routeFilters: vm => ({
                ...IsOneTimePurchaseParam(),
                ...IsCategoryParam(vm),
                ...statusParam([
                  ContractStatusCodes.ACTIVE,
                  ContractStatusCodes.AWAITING_ACTIVATION,
                  ContractStatusCodes.PENDING,
                  ContractStatusCodes.SUSPENDED
                ])
              })
            }),
            meta: {
              backTo: "_.active_one_time_purchases",
              title: "_.active_one_time_purchases"
            }
          }
        ]
      },
      {
        path: "cancelled",
        component: () =>
          import(
            "@/views/admin/clients/client/contractProducts/products/index.vue"
          ),
        props: (route: Route) => ({
          clientId: route.params.clientId,
          cpType: ContractProductsTypes.OTPS,
          cpKind: ContractProductsKinds.CANCELLED,
          routeName: "admin__clientCancelledOTPsCategoryProducts",
          goBackRoute: { name: "admin__clientCancelledOTPs" }
        }),
        children: [
          {
            path: "",
            name: "admin__clientCancelledOTPs",
            component: CProdsListing,
            props: (route: Route) => ({
              clientId: route.params.clientId,
              availableSorters: () => [
                StatusSorter(),
                DateCancelledSorter(),
                DatePurchasedSorter()
              ],
              availableFilters: () => [
                ...getDefaultCProdFilters(),
                IsOneTimeFilter(),
                ClientIdFilter()
              ],
              routeFilters: () => ({
                ...IsOneTimePurchaseParam(),
                ...statusParam([
                  ContractStatusCodes.CANCELLED,
                  ContractStatusCodes.CLOSED
                ])
              })
            }),
            meta: {
              backTo: "_.cancelled_one_time_purchases",
              title: "_.cancelled_one_time_purchases"
            }
          },
          {
            path: `category/:categoryId(${RegexMatch.UUID})`,
            name: "admin__clientCancelledOTPsCategoryProducts",
            component: CProdsListing,
            props: (route: Route) => ({
              clientId: route.params.clientId,
              availableSorters: () => [StatusSorter(), DatePurchasedSorter()],
              availableFilters: () => [
                ...getDefaultCProdFilters(),
                IsOneTimeFilter(),
                ClientIdFilter()
              ],
              routeFilters: vm => ({
                ...IsOneTimePurchaseParam(),
                ...IsCategoryParam(vm),
                ...statusParam([
                  ContractStatusCodes.CANCELLED,
                  ContractStatusCodes.CLOSED
                ])
              })
            }),
            meta: {
              backTo: "_.cancelled_one_time_purchases",
              title: "_.cancelled_one_time_purchases"
            }
          }
        ]
      },
      {
        path: "all",
        component: () =>
          import(
            "@/views/admin/clients/client/contractProducts/products/index.vue"
          ),
        props: (route: Route) => ({
          clientId: route.params.clientId,
          cpType: ContractProductsTypes.OTPS,
          cpKind: ContractProductsKinds.ALL,
          routeName: "admin__clientAllOTPsCategoryProducts",
          goBackRoute: { name: "admin__clientAllOTPs" }
        }),
        children: [
          {
            path: "",
            name: "admin__clientAllOTPs",
            component: CProdsListing,
            props: (route: Route) => ({
              clientId: route.params.clientId,
              availableSorters: () => [StatusSorter(), DatePurchasedSorter()],
              availableFilters: () => [
                ...getDefaultCProdFilters(),
                IsOneTimeFilter(),
                ClientIdFilter()
              ],
              routeFilters: () => ({
                ...IsOneTimePurchaseParam()
              })
            }),
            meta: {
              backTo: "_.all_one_time_purchases",
              title: "_.all_one_time_purchases"
            }
          },
          {
            path: `category/:categoryId(${RegexMatch.UUID})`,
            name: "admin__clientAllOTPsCategoryProducts",
            component: CProdsListing,
            props: (route: Route) => ({
              clientId: route.params.clientId,
              availableSorters: () => [StatusSorter(), DatePurchasedSorter()],
              availableFilters: () => [
                ...getDefaultCProdFilters(),
                IsOneTimeFilter(),
                ClientIdFilter()
              ],
              routeFilters: vm => ({
                ...IsOneTimePurchaseParam(),
                ...IsCategoryParam(vm)
              })
            }),
            meta: {
              backTo: "_.all_one_time_purchases",
              title: "_.all_one_time_purchases"
            }
          }
        ]
      }
    ]
  },
  {
    path: `products/:contractId(${RegexMatch.UUID})/:productId(${RegexMatch.UUID})`,
    component: {
      render: h =>
        h(CProdProvidersWrapper, {
          props: { handleLoading: true },
          scopedSlots: {
            default: () => h("router-view")
          }
        })
    } as Component,
    children: [
      {
        path: "",
        name: "admin__clientCProd",
        /* Note: Smart redirection handled from within component */
        component: () =>
          import(
            "@/views/admin/clients/client/contractProducts/product/index.vue"
          ),
        props: (route: Route) => ({
          contractId: route.params.contractId,
          productId: route.params.productId,
          routes: require("./menu").cProdTabigation
        }),
        meta: {
          title: ({ cProdName }) => cProdName
        },
        children: [
          {
            path: "overview",
            name: AdminRoutes.CONTRACT_PRODUCT_OVERVIEW,
            component: () =>
              import(
                "@/components/app/global/contractProducts/cProdOverview.vue"
              ),
            meta: {
              backTo: "_.overview"
            },
            beforeEnter: (
              to: Route,
              from: Route,
              next: NavigationGuardNext
            ) => {
              const doBusReload =
                (!!from.matched.length && to.params.doBusReload) ?? true;
              if (doBusReload) $bus.$emit("cProdProvider::reloadData");
              return next();
            }
          },
          {
            path: "setup",
            name: AdminRoutes.CONTRACT_PRODUCT_SETUP,
            component: () =>
              import(
                "@/views/admin/clients/client/contractProducts/product/setup/index.vue"
              )
          },
          {
            path: "invoices",
            name: AdminRoutes.CONTRACT_PRODUCT_INVOICES,
            component: () =>
              import(
                "@/components/app/global/client/contractProducts/contractProductInvoices.vue"
              ),
            meta: {
              backTo: "_.invoices"
            }
          },
          {
            path: "scheduled-changes",
            name: AdminRoutes.CONTRACT_PRODUCT_SCHEDULED_CHANGES,
            component: () =>
              import(
                "@/views/admin/clients/client/contractProducts/product/scheduledActions/index.vue"
              ),
            meta: {
              backTo: "_.scheduled_changes"
            }
          },
          {
            path: "credit-notes",
            name: "adminClientCProdCreditNotes",
            component: () =>
              import(
                "@/components/app/global/client/contractProducts/contractProductCreditNotes.vue"
              ),
            meta: {
              backTo: "_.credit_notes"
            }
          },
          {
            path: "settings",
            name: "adminClientCProdSettings",
            component: () =>
              import(
                "@/components/app/global/client/contractProducts/contractProductSettings.vue"
              ),
            meta: {
              backTo: "_.settings"
            }
          },
          {
            path: "activity-log",
            name: "adminClientCProdActivities",
            component: () =>
              import(
                "@/views/admin/clients/client/contractProducts/activities/index.vue"
              ),
            meta: {
              backTo: "_.activity_log"
            }
          },
          {
            path: "manage",
            name: AdminRoutes.CONTRACT_PRODUCT_PROVISIONING,
            component: () =>
              import(
                "@/views/admin/clients/client/contractProducts/cProdProvisioning/index.vue"
              ),
            meta: {
              backTo: "_.settings"
            }
          },
          {
            path: "delegates",
            name: "adminClientCProdDelegates",
            component: () =>
              import(
                "@/views/admin/clients/client/contractProducts/product/delegates/index.vue"
              ),
            meta: {
              backTo: "_.delegates"
            }
          },
          {
            path: "notes",
            name: "adminClientCProdVault",
            component: () =>
              import(
                "@/views/admin/clients/client/contractProducts/vault/index.vue"
              ),
            meta: {
              backTo: "_.notes_and_secrets"
            },
            beforeEnter: async (to: any, from: any, next: any) => {
              const config = store.getters["brand/config"];
              next(
                _.get(config, BrandConfigKeys.CLIENT_NOTES_AND_SECRETS_ENABLED)
                  ? {}
                  : {
                      name: AdminRoutes.CONTRACT_PRODUCT_OVERVIEW,
                      params: to.params
                    }
              );
            }
          }
        ]
      },
      {
        path: "",
        name: "adminClientCProdQuickLookModal",
        beforeEnter: async (
          to: Route,
          from: Route,
          next: (options: boolean | Location | Location["path"]) => void
        ) => {
          const { clientId, contractId, productId } = to.params;
          if (clientId && contractId && productId) {
            store.dispatch("ui/open/slideModal", {
              config: {
                component: () =>
                  import(
                    "@/components/app/global/contractProducts/cProdQuickLookModal.vue"
                  ),
                canCancel: ["button", "escape", "outside"],
                props: to.params
              }
            });
          }
          store.dispatch("ui/endRouting");
          return next(false);
        }
      }
    ]
  }
];
