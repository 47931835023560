import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import type { ApiPathGetter } from "@/models/api";
import i18n from "@/i18n";
import { ConfirmModalModes } from "@/data/enums";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath: (): ApiPathGetter => () => {
    const admin = `api/admin/retention_reasons`;
    return { admin };
  },
  listScope: () => () => {
    return `$retention_reasons`;
  }
};

export const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `${getters.apiPath().admin}`,
        params: {
          limit: 0,
          ...(payload.params || {})
        },
        storeModule: DataModules.RETENTIONS_REASONS
      },
      { root: true }
    );
  },
  create: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/create",
      {
        ...payload,
        path: getters.apiPath().admin,
        storeModule: DataModules.RETENTIONS_REASONS
      },
      { root: true }
    );
  },
  update: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/update",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload.id}`,
        storeModule: DataModules.RETENTIONS_REASONS
      },
      { root: true }
    );
  },
  confirmDelete: async ({ dispatch }, { retentionReasonId, onSuccess }) => {
    const confirm = await dispatch(
      "ui/open/confirmModal",
      {
        config: {
          props: {
            autoClose: false,
            confirmButtonText: i18n.t("_action.confirm"),
            mode: ConfirmModalModes.BUTTON,
            message: i18n.t("_sentence.retentions.delete_reason_confirmation")
          },
          canCancel: [],
          events: {
            confirmed: async () => {
              try {
                await dispatch("remove", { retentionReasonId });

                dispatch(
                  "toast/show",
                  {
                    message: i18n.t(
                      "_sentence.retentions.reason_successfully_deleted"
                    )
                  },
                  { root: true }
                );

                onSuccess();
              } catch (error) {
                dispatch("api/handleError", error, {
                  root: true
                });
              } finally {
                confirm.close();
              }
            }
          }
        }
      },
      { root: true }
    );
  },
  remove: ({ dispatch, getters }, { retentionReasonId }) => {
    return dispatch(
      "data/remove",
      {
        path: `${getters.apiPath().admin}/${retentionReasonId}`,
        storeModule: DataModules.RETENTIONS_REASONS
      },
      { root: true }
    );
  },
  openManageModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/admin/retentions/manageRetentionReasonModal.vue"
            ),
          width: 640,
          ...payload
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
