import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import type { ApiPathGetter } from "@/models/api";
import type { IWebhook } from "@/models/webhooks";
import { Methods } from "@/models/methods";
import { WebhookStatuses } from "@/data/enums/webhooks";
import i18n from "@/i18n";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  scope: () => id => {
    return `$webhook_${id}_event`;
  },
  payloadScope: () => id => {
    return `$webhook_${id}_event_payload`;
  },
  apiPath: (): ApiPathGetter => () => {
    const admin = `api/admin/webhooks/events`;
    return { admin };
  },
  listApiPath: (): ApiPathGetter => (webhookId: IWebhook["id"]) => {
    const admin = `api/admin/webhooks/${
      webhookId ? `${webhookId}/` : ""
    }events`;
    return { admin };
  },
  getEventMappedStatus: () => status => {
    switch (status) {
      case WebhookStatuses.FAILED:
        return {
          class: "has-text-danger",
          icon: "times-circle",
          label: i18n.t("_.failed"),
          type: "is-danger"
        };
      case WebhookStatuses.PENDING:
        return {
          class: "has-text-warning",
          icon: "clock",
          label: i18n.t("_.pending"),
          type: "is-warning"
        };
      case WebhookStatuses.SUCCESS:
        return {
          class: "has-text-success",
          icon: "check-circle",
          label: i18n.t("_.success"),
          type: "is-success"
        };
      default:
        return {};
    }
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.listApiPath(payload.id).admin,
        splitCount: true,
        storeModule: DataModules.WEBHOOKS_EVENTS
      },
      { root: true }
    );
  },
  get: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload?.id}`,
        storeModule: DataModules.WEBHOOKS_EVENTS
      },
      { root: true }
    );
  },
  getEventPayload: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload?.id}/payload`,
        storeModule: DataModules.WEBHOOKS_EVENTS
      },
      { root: true }
    );
  },
  retry: ({ dispatch, getters }, { params }) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PATCH,
        path: `${getters.apiPath().admin}/retry`,
        storeModule: DataModules.WEBHOOKS_EVENTS,
        requestConfig: { params }
      },
      { root: true }
    );
  },
  openViewModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/admin/settings/webhooks/viewWebhookEventModal.vue"
            ),
          ...payload,
          width: 540
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
