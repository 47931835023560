import _ from "@/boot/lodash";
import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import { Methods } from "@/models/methods";
import { UPM_DATA_LAYER } from "@/boot/gtm";
import type { IBasket, IBasketProduct } from "@/models/baskets";
import type { IInvoice } from "@/models/invoices";
import { getCookie } from "@/helpers/cookies";
import type { ApiPathGetter } from "@/models/api";
import { AppHookCodes } from "@/data/enums/hooks";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext }): ApiPathGetter =>
    () => {
      const admin = `api/admin/orders`;
      const client = `api/orders`;
      const contextual = isAdminContext ? admin : client;
      return { client, admin, contextual };
    },
  scope:
    (s, g, rS, { isAdminContext }): ApiPathGetter =>
    basketId => {
      return isAdminContext ? `$basket_${basketId}` : "$current_basket";
    }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath().contextual,
        splitCount: true,
        storeModule: DataModules.BASKETS
      },
      { root: true }
    );
  },
  get: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath().contextual}/${payload.id}`,
        params: {
          with: [
            "account.brand.image",
            "account.pricelist",
            "client.image",
            "client.phones",
            "contract",
            "currency",
            "custom_fields.field",
            "phone",
            "products.product.image",
            `products.product.category${".top_category".repeat(4)}`,
            "products.product.images",
            "products.product.prices",
            "products.product.products_attributes",
            "products.product.products_attributes.category",
            "products.product.products_options",
            "products.product.products_options.category",
            "products.product.products_options.prices",
            "products.tags",
            "promotions",
            "status",
            "payments",
            "taxes",
            "taxes.tax_tag_data"
          ].join()
        },
        storeModule: DataModules.BASKETS
      },
      { root: true }
    );
  },
  create: async ({ dispatch, getters, rootState }, { data, isClient }) => {
    const tracking = _.get(rootState, "upm.track");

    const basket: IBasket = await dispatch(
      "data/create",
      {
        storeModule: DataModules.BASKETS,
        path: getters.apiPath().contextual,
        data: {
          ...data,
          ...(tracking && isClient ? { tracking } : {})
        }
      },
      { root: true }
    );

    if (basket.products?.length)
      dispatch(
        `ui/hooks/${AppHookCodes.ADD_TO_BASKET_SUCCESS}`,
        basket.products[0],
        { root: true }
      );

    return basket;
  },
  update: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/update",
      {
        ...payload,
        path: `${getters.apiPath().contextual}/${payload.id}`,
        storeModule: DataModules.BASKETS
      },
      { root: true }
    );
  },
  updateCurrency: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/update",
      {
        ...payload,
        path: `${getters.apiPath().contextual}/${payload.id}/currency`,
        storeModule: DataModules.BASKETS
      },
      { root: true }
    );
  },
  remove: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/remove",
      {
        ...payload,
        path: `${getters.apiPath().contextual}/${payload.id}`,
        storeModule: DataModules.BASKETS
      },
      { root: true }
    );
  },
  hideWarnings: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PUT,
        path: `${getters.apiPath().contextual}/${payload.id}/warnings/hide`,
        storeModule: DataModules.BASKETS,
        requestConfig: { data: { ids: payload.warningIds } }
      },
      { root: true }
    );
  },
  addPromotion: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.POST,
        path: `${getters.apiPath().contextual}/${payload.id}/promotions`,
        requestConfig: {
          data: {
            promocode: payload.promocode
          }
        }
      },
      { root: true }
    );
  },
  createAutoPromotion: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.POST,
        path: `${getters.apiPath().contextual}/${payload.id}/promo_balance`,
        requestConfig: {
          data: {
            total: payload.total
          }
        }
      },
      { root: true }
    );
  },
  deletePromotion: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.DELETE,
        path: `${getters.apiPath().contextual}/${payload.id}/promotions/${
          payload.promotionId
        }`
      },
      { root: true }
    );
  },
  convertToInvoice: (
    { rootState, dispatch, getters },
    { id, isClient, data }
  ) => {
    const referral_cookie = getCookie("upm_aff");
    const tracking = _.get(rootState, "upm.track");
    return dispatch(
      "data/callApi",
      {
        method: Methods.PATCH,
        path: `${getters.apiPath().contextual}/${id}/convert`,
        requestConfig: {
          data: {
            ...data,
            ...(referral_cookie ? { referral_cookie } : {}),
            ...(tracking && isClient ? { tracking } : {})
          }
        }
      },
      { root: true }
    ).then((invoice: IInvoice) => {
      if (isClient) dispatch("upm/deleteTrackCookie", null, { root: true });
      return invoice;
    });
  },
  addProduct: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.POST,
        path: `${getters.apiPath().contextual}/${payload.id}/products`,
        requestConfig: { data: payload.product }
      },
      { root: true }
    ).then(result => {
      const basketProduct = _.last(
        _.filter(
          result.products || [],
          product => product.product_id === payload.product.product_id
        )
      ) as IBasketProduct;

      dispatch(
        `ui/hooks/${AppHookCodes.ADD_TO_BASKET_SUCCESS}`,
        basketProduct,
        { root: true }
      );

      return result;
    });
  },
  updateProduct: (
    { getters, dispatch },
    { basketId, basketProductId, productConfig }
  ) => {
    return dispatch(
      "data/update",
      {
        path: `${
          getters.apiPath().contextual
        }/${basketId}/products/${basketProductId}`,
        data: productConfig
      },
      { root: true }
    );
  },
  claimGuestBaskets: (
    { dispatch, getters, rootGetters, rootState },
    payload
  ) => {
    if (
      !rootGetters["auth/guest/isAuthenticated"] ||
      !rootGetters["auth/client/isAuthenticated"]
    ) {
      return;
    }
    return dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.PATCH,
        path: `${getters.apiPath().client}/claim`,
        requestConfig: {
          data: {
            guest_token: rootState.auth.guest.token.access_token
          }
        }
      },
      { root: true }
    );
  },
  deleteProduct: ({ dispatch, getters }, basketProduct: IBasketProduct) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.DELETE,
        path: `${getters.apiPath().contextual}/${
          basketProduct.invoice_id
        }/products/${basketProduct.id}`
      },
      { root: true }
    ).then(result => {
      UPM_DATA_LAYER.push({ ecommerce: null });
      UPM_DATA_LAYER.push({
        event: "remove_from_cart",
        ecommerce: {
          currency: basketProduct.base_currency_code,
          value: basketProduct.configuration_net_amount_discounted_converted,
          items: [
            {
              item_id: basketProduct.product.id,
              item_name: basketProduct.product.name, // For reporting purposes we intentionally pass untranslated product name
              item_category: basketProduct.product.category.name, // For reporting purposes we intentionally pass untranslated category name
              quantity: basketProduct.quantity,
              discount:
                basketProduct.configuration_net_amount_discount_converted,
              price: basketProduct.configuration_net_amount_converted,
              upm_report_code_1: basketProduct.product.report_code_1, // item-scoped custom Upmind parameter
              upm_report_code_2: basketProduct.product.report_code_2 // item-scoped custom Upmind parameter
            }
          ]
        }
      });
      return result;
    });
  },
  current: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.GET,
        path: `${getters.apiPath().client}/current`
      },
      { root: true }
    );
  },
  currentBasket: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath().client}/current`,
        params: {
          with: [
            "account.brand.image",
            "account.pricelist",
            "brand.image",
            "client.image",
            "client.phones",
            "contract",
            "currency",
            "custom_fields.field",
            "phone",
            `products.product.category${".top_category".repeat(4)}`,
            "products.product.image",
            "products.product.images",
            "products.product.prices",
            "products.product.products_attributes",
            "products.product.products_attributes.category",
            "products.product.products_options",
            "products.product.products_options.category",
            "products.product.products_options.prices",
            "products.tags",
            "promotions",
            "status",
            "taxes.tax_tag_data"
          ].join()
        },
        storeModule: DataModules.BASKETS
      },
      { root: true }
    );
  },
  openConfigureProductModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/global/baskets/basket/quickOrderFlow/basketProductConfigureModal.vue"
            ),
          width: 840,
          ...payload
        }
      },
      { root: true }
    );
  },
  openQuickOrderModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/global/baskets/basket/quickOrderFlow/quickOrderModal.vue"
            ),
          width: 840,
          canCancel: ["button"],
          ...payload
        }
      },
      { root: true }
    );
  },
  openQuickInvoiceModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/global/baskets/basket/quickOrderFlow/quickInvoiceModal.vue"
            ),
          width: 840,
          canCancel: ["button"],
          ...payload
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
