import Sortable from "sortablejs";
import _ from "@/boot/lodash";

const createSortableForm = (el, options, vnode) => {
  return Sortable.create(el, {
    ...options,
    onMove: evt => {
      if (evt.related) {
        return !evt.related.classList.contains("is-locked");
      }
    },
    onEnd: evt => {
      const data = vnode.context.form.values;
      const item = data[evt.oldIndex];

      if (evt.newIndex > evt.oldIndex) {
        for (let i = evt.oldIndex; i < evt.newIndex; i++) {
          data[i] = data[i + 1];
        }
      } else {
        for (let i = evt.oldIndex; i > evt.newIndex; i--) {
          data[i] = data[i - 1];
        }
      }
      data[evt.newIndex] = item;
      vnode.context.onDragDrop();
    }
  });
};

const createSortableElement = (el, options, vnode) => {
  return Sortable.create(el, {
    ...options,
    onMove: evt => {
      if (evt.related) {
        return !evt.related.classList.contains("is-locked");
      }
    },
    onEnd: evt => {
      const data = vnode.context.sortElems;

      const item = data[evt.oldIndex];

      if (evt.newIndex > evt.oldIndex) {
        for (let i = evt.oldIndex; i < evt.newIndex; i++) {
          data[i] = data[i + 1];
        }
      } else {
        for (let i = evt.oldIndex; i > evt.newIndex; i--) {
          data[i] = data[i - 1];
        }
      }
      data[evt.newIndex] = item;

      if (evt.oldIndex !== evt.newIndex && !!vnode.context.onDragDrop) {
        vnode.context.onDragDrop(evt.newIndex);
      }
    }
  });
};

const createSortable = (el, options, vnode) => {
  return Sortable.create(el, {
    ...options,
    onMove: evt => {
      if (evt.related) {
        return !evt.related.classList.contains("is-locked");
      }
    },
    onEnd: evt => {
      const data =
        vnode.context.table && vnode.context.table.data
          ? vnode.context.table.data
          : vnode.context.tableData;

      const item = data[evt.oldIndex];

      if (evt.newIndex > evt.oldIndex) {
        for (let i = evt.oldIndex; i < evt.newIndex; i++) {
          data[i] = data[i + 1];
        }
      } else {
        for (let i = evt.oldIndex; i > evt.newIndex; i--) {
          data[i] = data[i - 1];
        }
      }
      data[evt.newIndex] = item;

      if (evt.oldIndex !== evt.newIndex && !!vnode.context.onDragDrop) {
        vnode.context.onDragDrop(evt.newIndex);
      }
    }
  });
};

const getCategory = (classNames: string) => {
  const categoryClasses = _.find(classNames.split(" "), name =>
    name.includes("is-category-")
  );

  return categoryClasses ? categoryClasses.replace("is-category-", "") : "";
};

const getOldIndex = (sortElements, classNames: string) => {
  const itemClasses = _.find(classNames.split(" "), name =>
    name.includes("item-")
  );

  return itemClasses
    ? _.findIndex(
        sortElements,
        (elem: any) => elem.id === itemClasses.replace("item-", "")
      )
    : null;
};

const checkRelatedCategory = (allDraggedClasses, allRelatedClasses) => {
  const draggedClass = _.find(allDraggedClasses.split(" "), name =>
    name.includes("related-category-")
  );

  const relatedClass = _.find(allRelatedClasses.split(" "), name =>
    name.includes("related-category-")
  );

  return draggedClass === relatedClass;
};

const getNewIndex = (sortElements, classNames: string, index) => {
  const containerClasses = _.find(classNames.split(" "), name =>
    name.includes("button-index-")
  );

  const buttonIndex = containerClasses
    ? containerClasses.replace("button-index-", "")
    : null;

  return buttonIndex === null ? index : index > buttonIndex ? index - 1 : index;
};

const getValueFromClass = (className: string, searchedClassName: string) => {
  const fullClassName = _.find(className.split(" "), name =>
    name.includes(searchedClassName)
  );
  return fullClassName ? fullClassName.replace(searchedClassName, "") : null;
};

const getCategoryIndex = (
  containerClassNames: string,
  itemClassNames: string,
  index: number
) => {
  const buttonIndex: any =
    getValueFromClass(containerClassNames, "button-index-") || 0;
  const loadedProducts: any = getValueFromClass(
    containerClassNames,
    "loaded-products-"
  );

  if (
    _.find(itemClassNames.split(" "), className => className === "is-category")
  ) {
    if (buttonIndex === 0 && loadedProducts === 0) {
      return index;
    }

    return index - (buttonIndex ? buttonIndex : loadedProducts) - 1 < 0
      ? 0
      : index - (buttonIndex ? buttonIndex : loadedProducts) - 1;
  }

  return null;
};

const getItemIndex = (
  containerClassNames: string,
  itemClassNames: string,
  index: number
) => {
  const loadedProducts: any = getValueFromClass(
    containerClassNames,
    "loaded-products-"
  );

  return _.find(itemClassNames.split(" "), className => className === "is-item")
    ? index > loadedProducts - 1
      ? loadedProducts - 1
      : index
    : null;
};

const createMultilevelSortableElement = (el, options, vnode) => {
  return Sortable.create(el, {
    ...options,
    onMove: evt => {
      if (evt.related) {
        return (
          !evt.related.classList.contains("is-locked") &&
          (evt.dragged.classList.contains("is-category") ||
            (!evt.dragged.classList.contains("is-category") &&
              !evt.related.classList.contains("is-level-0"))) &&
          (!evt.dragged.classList.contains("is-locked-to-parent") ||
            (evt.dragged.classList.contains("is-locked-to-parent") &&
              ((evt.dragged.classList.contains("is-category") &&
                evt.related.classList.contains("is-level-0")) ||
                (evt.dragged.classList.contains("is-item") &&
                  evt.related.classList.contains("is-item") &&
                  checkRelatedCategory(
                    evt.dragged.classList.value,
                    evt.related.classList.value
                  )))))
        );
      }
    },
    onEnd: evt => {
      const data = vnode.context.sortElems;
      const oldIndex = getOldIndex(data, evt.item.className); // Fix if load more button
      const newIndex = getNewIndex(data, evt.to.className, evt.newIndex); // Fix if load more button

      if (
        vnode.context.onDragDrop &&
        !(
          getCategory(evt.from.className) === getCategory(evt.to.className) &&
          evt.newIndex === evt.oldIndex
        )
      ) {
        vnode.context.onDragDrop({
          itemMoved: oldIndex !== null ? data[oldIndex] : {},
          newIndex: newIndex,
          oldCategory: getCategory(evt.from.className),
          newCategory: getCategory(evt.to.className),
          categoryOrder: getCategoryIndex(
            evt.to.className,
            evt.item.className,
            evt.newIndex
          ),
          itemOrder: getItemIndex(
            evt.to.className,
            evt.item.className,
            evt.newIndex
          )
        });
      }
    }
  });
};

export default {
  install: (Vue: any) => {
    Vue.directive("focus", {
      inserted(el: any, binding: any) {
        if (binding.value === false) return;
        if (!["input", "button"].includes(el.tagName.toLowerCase())) {
          el = el.querySelector("input");
        }
        if (el.getAttribute("tabindex")) {
          el.setAttribute("tabindex", -1);
        }
        el.focus();
      }
    });

    Vue.directive("visible", (el: any, binding: any) => {
      let fadeIn = 0;
      let fadeOut = 0;
      if (binding.arg.length) {
        const argArray = binding.arg.split(",");
        fadeIn = parseInt(argArray[0], 10);
        if (argArray.length === 2) {
          fadeOut = parseInt(argArray[1], 10);
        }
      }
      if (binding.value) {
        el.style.transition = "opacity " + fadeOut + "ms ease";
        el.style.opacity = "1";
        el.style.visibility = "visible";
      } else {
        el.style.transition =
          "visibility 0s " + fadeIn + "ms, opacity " + fadeIn + "ms ease";
        el.style.opacity = "0";
        el.style.visibility = "hidden";
      }
    });

    Vue.directive("click-outside", {
      bind: (el: any, binding: any, vnode: any) => {
        el.clickOutsideEvent = (event: any) => {
          // here I check that click was outside the el and his childrens
          if (!(el === event.target || el.contains(event.target))) {
            // and if it did, call method provided in attribute value
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener("click", el.clickOutsideEvent);
      },
      unbind: (el: any) => {
        document.body.removeEventListener("click", el.clickOutsideEvent);
      }
    });

    Vue.directive("sortable-table", {
      bind(el: any, binding: any, vnode: any) {
        const table = el.querySelector("table");
        table._sortable = createSortable(
          table.querySelector("tbody"),
          binding.value,
          vnode
        );
      },
      update(el: any, binding: any, vnode: any) {
        const table = el.querySelector("table");
        table._sortable.destroy();
        table._sortable = createSortable(
          table.querySelector("tbody"),
          binding.value,
          vnode
        );
      },
      unbind(el: any) {
        const table = el.querySelector("table");
        table._sortable.destroy();
      }
    });

    Vue.directive("u-sortable", {
      name: "u-sortable",
      bind(el: any, binding: any, vnode: any) {
        const container = el.querySelector(".sortable-container");
        container._sortable = createSortableElement(
          container,
          binding.value,
          vnode
        );
      },
      update(el: any, binding: any, vnode: any) {
        const container = el.querySelector(".sortable-container");
        container._sortable.destroy();
        container._sortable = createSortableElement(
          container,
          binding.value,
          vnode
        );
      },
      unbind(el: any) {
        const container = el.querySelector(".sortable-container");
        container._sortable.destroy();
      }
    });

    Vue.directive("sortable-form", {
      bind(el: any, binding: any, vnode: any) {
        const table = el.querySelector("table");
        table._sortable = createSortableForm(
          table.querySelector("tbody"),
          binding.value,
          vnode
        );
      },
      update(el: any, binding: any, vnode: any) {
        const table = el.querySelector("table");
        table._sortable.destroy();
        table._sortable = createSortableForm(
          table.querySelector("tbody"),
          binding.value,
          vnode
        );
      },
      unbind(el: any) {
        const table = el.querySelector("table");
        table._sortable.destroy();
      }
    });

    Vue.directive("multilevel-sortable-list", {
      name: "multilevel-sortable-list",
      bind(el: any, binding: any, vnode: any) {
        const container = el.querySelector(".sortable-container");
        container._sortable = createMultilevelSortableElement(
          container,
          binding.value,
          vnode
        );
      },
      update(el: any, binding: any, vnode: any) {
        const container = el.querySelector(".sortable-container");
        container._sortable.destroy();
        container._sortable = createMultilevelSortableElement(
          container,
          binding.value,
          vnode
        );
      },
      unbind(el: any) {
        const container = el.querySelector(".sortable-container");
        container._sortable.destroy();
      }
    });

    Vue.directive("columns-resizable", require("./columns-resizable").default);
  }
};
