import type { ISorter } from "@/models/tables";
import { CreatedAtSorter } from "@/data/sorters/object";
import i18n from "@/i18n";

const orderSorters: () => ISorter[] = () => [
  {
    field: "id",
    label: i18n.t("_.order_id", {})
  },
  {
    field: "total_amount",
    label: i18n.t("_.total")
  },
  {
    field: "status_id",
    label: i18n.t("_.status")
  },
  {
    field: "created_at",
    label: i18n.t("_.date_created")
  }
];

export const IdSorter: () => ISorter = () => ({
  field: "id",
  label: i18n.t("_.order_id", {})
});
export const TotalAmountSorter: () => ISorter = () => ({
  field: "total_amount",
  label: i18n.t("_.total")
});

export const StatusSorter: () => ISorter = () => ({
  field: "status_id",
  label: i18n.t("_.status")
});

export const DefaultOrderSorters: () => ISorter[] = () => [
  IdSorter(),
  TotalAmountSorter(),
  StatusSorter(),
  CreatedAtSorter()
];

export default orderSorters;
