import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import { Methods } from "@/models/methods";
import type { ApiPathGetter } from "@/models/api";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath: (): ApiPathGetter => () => {
    const admin = `api/admin/gateway_providers`;
    return { admin };
  }
};

export const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath().admin,
        storeModule: DataModules.GATEWAYS_PROVIDERS
      },
      { root: true }
    );
  },
  testCredentials: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.POST,
        path: `${getters.apiPath().admin}/${payload?.id}/test`,
        requestConfig: {
          data: payload.data
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
