<template>
  <cprod-providers-wrapper>
    <div class="u-client-cprod-layout">
      <!-- Billboard -->
      <cprod-billboard />
      <!-- Tabigation -->
      <cprod-tabigation :routes="routes" />
      <!-- Notification -->
      <cprod-condition-notif />
      <!-- Secondary Content -->
      <template v-if="$store.getters['ui/isDesktopPlus']">
        <div class="u-client-cprod-secondary is-hidden-touch">
          <div>
            <cprod-highlighted-actions-component tag="u-aside" />
            <cprod-details-list-component tag="u-aside" />
            <cprod-about-component tag="u-aside" />
            <cprod-assistance-component tag="u-aside" />
          </div>
        </div>
      </template>
      <!-- Primary Content -->
      <div class="u-client-cprod-primary">
        <router-view />
      </div>
    </div>
  </cprod-providers-wrapper>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
import type { IMenuRoute } from "@/models/routes";

export default defineComponent({
  name: "CProdClientLayout",
  components: {
    "cprod-providers-wrapper": () =>
      import(
        "@/components/app/global/contractProducts/cProdProvidersWrapper.vue"
      ),
    "cprod-tabigation": () =>
      import("@/components/app/global/contractProducts/cProdTabigation.vue"),
    "cprod-condition-notif": () =>
      import(
        "@/components/app/global/contractProducts/cProdConditionNotif.vue"
      ),
    "cprod-billboard": () =>
      import(
        "@/components/app/global/contractProducts/cProdClientBillboard.vue"
      ),
    "cprod-highlighted-actions-component": () =>
      import(
        "@/components/app/global/contractProducts/cProdHighlightedActionsComp.vue"
      ),
    "cprod-details-list-component": () =>
      import(
        `@/components/app/global/contractProducts/cProdDetailsListComp.vue`
      ),
    "cprod-about-component": () =>
      import(`@/components/app/global/contractProducts/cProdAboutComp.vue`),
    "cprod-assistance-component": () =>
      import(`@/components/app/global/contractProducts/cProdAssistanceComp.vue`)
  },
  props: {
    routes: {
      type: Function as PropType<(vm: any) => IMenuRoute[]>,
      default: () => []
    }
  },
  created() {
    this.$bus.$on("scrollClientCProdView", this.scrollClientCProdView);
  },
  beforeDestroy() {
    this.$bus.$off("scrollClientCProdView", this.scrollClientCProdView);
  },
  methods: {
    scrollClientCProdView() {
      const offset = this.$store.getters["ui/isTabletPlus"] ? 125 : 136;
      const { y, top } = document
        ?.getElementsByClassName("u-client-cprod-primary")[0]
        ?.getBoundingClientRect();
      const targetY = top + window.scrollY - offset;
      if (y - offset < 0) window.scrollTo(0, targetY);
    }
  }
});
</script>

<style lang="scss" scoped>
$gap: 32px;

.u-client-cprod-layout {
  display: grid;
  grid-template-columns: 100%;
  column-gap: $gap;
  margin-bottom: $gap;

  @include desktop {
    $secondaryWidth: 360px;
    $primaryWidth: $desktop - (3 * $gap) - $secondaryWidth - 32px;
    grid-template-columns: 1fr minmax(auto, $primaryWidth) $secondaryWidth 1fr;
    grid-template-rows: auto auto auto 1fr;
  }

  @include widescreen {
    $secondaryWidth: 400px;
    $primaryWidth: $widescreen - (3 * $gap) - $secondaryWidth - 32px;
    grid-template-columns: 1fr minmax(auto, $primaryWidth) $secondaryWidth 1fr;
  }

  @include fullhd {
    $secondaryWidth: 400px;
    $primaryWidth: $fullhd - (3 * $gap) - $secondaryWidth - 32px;
    grid-template-columns: 1fr minmax(auto, $primaryWidth) $secondaryWidth 1fr;
  }

  .u-client-cprod-billboard::v-deep {
    display: contents;

    &::before,
    .u-client-cprod-billboard-content {
      grid-row: 1;
      grid-column: 1;
    }

    &::before {
      content: "";
      background: $white;
      @include mobile {
        border-bottom: 1px solid $border;
      }
      @include tablet {
        border-top: 1px solid $border;
      }
      @include desktop {
        grid-row: 1;
        grid-column: 1 / span 4;
      }
    }

    .u-client-cprod-billboard-content {
      @include desktop {
        grid-column: 2;
        grid-row: 1;
      }
    }
  }

  .u-cptabs-wrapper::v-deep {
    display: contents;

    &::before,
    .u-cptabs-content {
      grid-row: 2;
      grid-column: 1;
      position: sticky;
      top: 4.5rem;
      z-index: 5;
    }

    &::before {
      content: "";
      background: $white;
      border-bottom: 1px solid $border;
      @include desktop {
        grid-row: 2;
        grid-column: 1 / span 4;
      }
    }

    .u-cptabs-content {
      @include mobile {
        padding: 0.75rem 1rem 0.75rem 1.25rem;
      }
      @include tablet {
        padding: 0 1.5rem;
      }
      @include desktop {
        grid-row: 2;
        grid-column: 2;
        padding: 0;
      }
    }
  }

  & > .u-cpcn-wrapper::v-deep {
    display: contents;

    &::before {
      content: "";
      grid-row: 3;
      grid-column: 1;
      background: $white;
      border-bottom: 3px solid $border;
      @include desktop {
        grid-row: 3;
        grid-column: 1 / span 4;
      }
    }

    .u-cpcn-content {
      grid-row: 3;
      grid-column: 1;
      padding: 1.125rem 1.5rem;

      @include desktop {
        grid-row: 3;
        grid-column: 2;
        padding: 1.125rem 0;
      }
    }
  }

  .u-client-cprod-primary {
    grid-row: 5;
    grid-column: 1;

    @include touch {
      padding: 1rem;
    }

    @include desktop {
      grid-row: 4;
      grid-column: 2 / 3;
      padding-top: $gap;
    }

    &::v-deep > div section.box:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
  .u-client-cprod-secondary {
    grid-row: 4;
    grid-column: 1;
    padding: 3rem 1rem;
    background: $white;
    border: 1px solid mix($border, $white, 50%);
    border-bottom-left-radius: $radius-large;
    border-bottom-right-radius: $radius-large;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);

    & > div {
      position: sticky;
      top: 6.25rem;
    }

    @include touch {
      margin: 1.5rem;
    }

    @include desktop {
      grid-row: 1 / span 4;
      grid-column: 3;
      z-index: 5;
    }
  }
}
</style>
