import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import { Methods } from "@/models/methods";
import type { ApiPathGetter } from "@/models/api";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath: (): ApiPathGetter => () => {
    const admin = "api/admin/fraud";
    return { admin };
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `${getters.apiPath().admin}/events/brand`,
        storeModule: DataModules.FRAUD_DETECTION_EVENTS
      },
      { root: true }
    );
  },
  resolveFraudulentEvent: async (
    { dispatch, getters, rootGetters },
    payload
  ) => {
    if (!rootGetters["user/can"]("create_fraud_resolver_for_event")) return;
    return dispatch(
      "data/callApi",
      {
        method: Methods.POST,
        path: `${getters.apiPath().admin}/event/${
          payload.type === "order" ? "invoice" : payload.type
        }`,
        storeModule: DataModules.FRAUD_DETECTION,
        requestConfig: { data: payload.data }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
