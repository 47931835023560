<template>
  <card :is-paddingless="true" :title="title()">
    <div class="has-padding-50">
      <menu-component :routes="routes" background="light" />
    </div>
  </card>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import i18n from "@/i18n";
import type { PropType } from "vue";

export default defineComponent({
  name: "ClientMenu",
  props: {
    title: {
      type: Function as PropType<() => string>,
      default: () => i18n.t("_.menu")
    },
    routes: { type: Array as PropType<any[]>, required: true }
  }
});
</script>
