import i18n from "@/i18n";
import type { IMenuRoute } from "@/models/routes";

export function clientOrderTabigation(): IMenuRoute[] {
  return [
    {
      label: i18n.t("_.summary"),
      to: (vm: any) => {
        return {
          name: "admin__orderSummary",
          params: vm.$route.params
        };
      }
    },
    {
      label: i18n.t("_.invoices"),
      to: (vm: any) => {
        return {
          name: "admin__orderInvoices",
          params: vm.$route.params
        };
      }
    },
    {
      label: i18n.t("_.credit_notes"),
      to: (vm: any) => {
        return {
          name: "admin__orderCreditNotes",
          params: vm.$route.params
        };
      }
    }
  ];
}
