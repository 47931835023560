import BusVue from "vue";

export const $bus = new BusVue({ name: "EventBus" });

export default {
  install: (Vue: any) => {
    // Event Bus
    Object.defineProperty(Vue.prototype, "$bus", {
      value: $bus
    });
  }
};
