export enum SegmentTypes {
  CLIENT = "client",
  CONTRACTS_PRODUCTS = "contracts_product",
  CONTRACTS_PRODUCTS_SUBSCRIPTION = "contracts_product.subscription",
  CONTRACTS_PRODUCTS_SALE = "contracts_product.sale",
  ORDER = "invoice.order"
}

export enum ISegmentRouteTypes {
  CLIENT = "adminClientsList",
  CONTRACTS_PRODUCTS = "adminProductsServices",
  CONTRACTS_PRODUCTS_SUBSCRIPTION = "adminSubscriptions",
  CONTRACTS_PRODUCTS_SALE = "adminSales",
  ORDER = "adminOrdersList"
}

export enum INewSegmentRouteTypes {
  CLIENT = "adminClientsSegments",
  CONTRACTS_PRODUCTS = "adminCProdsSegmentList",
  CONTRACTS_PRODUCTS_SUBSCRIPTION = "adminSubscriptionSegmentList",
  CONTRACTS_PRODUCTS_SALE = "adminOneTimePurchasesSegmentList",
  ORDER = "adminOrdersSegments"
}
