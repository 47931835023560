import { mapState } from "vuex";
import _ from "@/boot/lodash";
import type { IState } from "@/store";
import type { ICurrency } from "@upmind-automation/types";

export default {
  computed: {
    ...mapState({
      currencies: (state: IState): ICurrency[] => state.constants.currencies
    })
  },
  data: () => ({
    currenciesLoaded: false,
    isLoggedOut: false
  }),
  async created() {
    await this.getCurrencies();
    this.currenciesLoaded = true;
  },
  methods: {
    getCurrencies() {
      return this.$store.dispatch("constants/getCurrencies", {
        isLoggedOut: this.isLoggedOut
      });
    },
    getCurrencyCode(currencyId) {
      return _.get(_.find(this.currencies, ["id", currencyId]), "code", "");
    }
  }
};
