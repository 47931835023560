import type { ApiPathGetter } from "@/models/api";
import type { IState } from "@/store";
import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext }): ApiPathGetter =>
    hasToken => {
      const admin = `api/admin/notifications/topics`;
      const client = `api/notifications/topics`;
      const contextual = isAdminContext && !hasToken ? admin : client;
      return { client, admin, contextual };
    },
  scope: () => {
    return "$notification_topics";
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath(!!payload.token).contextual,
        storeModule: DataModules.NOTIFICATIONS_TOPICS
      },
      { root: true }
    );
  },
  get: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath().contextual}/${payload?.id}`,
        storeModule: DataModules.NOTIFICATIONS_TOPICS
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
