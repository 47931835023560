import i18n from "@/i18n";
import store from "@/store";
import { BrandConfigKeys } from "@/data/constants";
import type { IMenuRoute } from "@/models/routes";
import { ClientRoutes, GuestRoutes } from "@/data/enums/router";

const ifSupportEnabled: IMenuRoute["if"] = () => {
  const configKey = BrandConfigKeys.UI_CLIENT_APP_DISABLE_SUPPORT_SYSTEM;
  return !(store.getters["brand/config"][configKey] ?? false);
};

export function ClientPrimaryTabigation(): IMenuRoute[] {
  return [
    {
      icon: "home",
      label: i18n.t("_.dashboard"),
      to: { name: ClientRoutes.DASHBOARD }
    },
    {
      icon: "box-open",
      label: i18n.t("_.products_and_services"),
      to: { name: ClientRoutes.CONTRACT_PRODUCTS }
    },
    {
      icon: "receipt",
      label: i18n.t("_.billing"),
      to: { name: ClientRoutes.INVOICES }
    },
    {
      icon: "user-circle",
      label: i18n.t("_.my_account"),
      to: { name: ClientRoutes.ACCOUNT }
    },
    {
      icon: "comments",
      if: ifSupportEnabled,
      label: i18n.t("_.support"),
      to: { name: ClientRoutes.SUPPORT }
    },
    {
      icon: "shopping-basket",
      if: () => store.getters["brand/showStore"],
      label: i18n.t("_action.place_new_order"),
      to: { name: GuestRoutes.STORE }
    }
  ];
}
