import _ from "@/boot/lodash";
import i18n from "@/i18n";
import { setProp, deleteProp } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import { ProvisionRequestStatusCodes as PRSC } from "@/data/enums/contractProducts";
import { SnackbarProgrammatic as $snackbar } from "buefy";
import { ProvisionRequestActionTypes } from "@/data/enums/provisioning";
import { HttpMethods, Targets } from "@/data/enums/forms";
import { submitProgrammaticForm } from "@/helpers/forms";
import type { ActionTree, MutationTree } from "vuex";
import type { IState } from "@/store";
import type { ISocketMessage } from "@/models/socket";
import type { IDataState } from "@/store/modules/data";

export interface IProvisionFunctionsState {
  executing: object;
}

const initialState = (): IProvisionFunctionsState => {
  return {
    executing: {}
  };
};

const actions: ActionTree<IDataState, IState> = {
  executeFunction: async (
    { dispatch, commit },
    { vm, provFunction, contractId, productId, events = {} }
  ) => {
    if (provFunction.isDisabled) return;

    const cuid = vm.$cuid || "CUID_UNSET";

    try {
      // Check if provision function needs to collect data first
      const fields = await dispatch(
        `data/${DataModules.PROVISIONING_CONTRACT_PRODUCTS}/listContractProductFunctionFields`,
        {
          contractId,
          productId,
          functionId: provFunction.id,
          returnData: true,
          storeData: false
        },
        { root: true }
      );

      if (!fields.length) {
        return dispatch("executeStoreFunction", {
          cuid,
          contractId,
          productId,
          provFunction,
          provFunctionFields: {},
          events
        });
      }

      // Collect field values
      dispatch(
        `data/${DataModules.PROVISIONING_CONTRACT_PRODUCTS}/openProductFunctionSetupModal`,
        {
          props: {
            cuid,
            contractId,
            productId,
            executedFunction: provFunction,
            functionFields: fields
          },
          events: {
            success: executedFunction => {
              commit("addToExecutedFunctions", {
                scope: executedFunction.uuid,
                data: {
                  _cuid: cuid,
                  async: executedFunction.async,
                  contractId,
                  events,
                  id: executedFunction.id,
                  productId,
                  timeout: null
                }
              });

              // Handle results
              dispatch("handleExecutedFunction", {
                scope: executedFunction.uuid,
                executedFunction,
                events,
                refresh: false
              });
            }
          }
        },
        { root: true }
      );
    } catch (error) {
      dispatch("api/handleError", error, { root: true });
    }
  },
  executeStoreFunction: async (
    { dispatch, commit },
    { cuid, contractId, productId, provFunction, provFunctionFields, events }
  ) => {
    // Execute function
    const executedFunction = await dispatch(
      `data/${DataModules.PROVISIONING_CONTRACT_PRODUCTS}/executeProvisioningFunction`,
      {
        contractId,
        productId,
        data: {
          provision_field_values: provFunctionFields,
          blueprint_function_id: provFunction.id
        }
      },
      { root: true }
    );

    // Handle error and exit method
    if (executedFunction.status?.code === PRSC.PROVISION_REQUEST_ERROR) {
      const errorResult = _.find(
        executedFunction.results || [],
        r => !!r.error_id
      );
      return $snackbar.open({
        message:
          errorResult?.message || i18n.t("_sentence.something_went_wrong"),
        actionText: i18n.t("_action.dismiss").toString(),
        type: "is-danger",
        indefinite: true
      });
    } else {
      commit("addToExecutedFunctions", {
        scope: executedFunction.uuid,
        data: {
          _cuid: cuid,
          async: executedFunction.async,
          contractId,
          events,
          id: executedFunction.id,
          productId,
          timeout: null
        }
      });

      const message = _.get(executedFunction, "result_message");
      if (message) dispatch("toast/show", { message }, { root: true });
    }

    // Handle results
    dispatch("handleExecutedFunction", {
      scope: executedFunction.uuid,
      executedFunction,
      events,
      refresh: false
    });
  },
  handleExecutedFunction: async (
    { dispatch, commit },
    { scope, executedFunction, events, refresh = true }
  ) => {
    if (events?.refreshRequestTable) events?.refreshRequestTable();

    if (executedFunction.async && !refresh) {
      commit("setFunctionTimeout", {
        scope,
        data: setTimeout(() => {
          dispatch("handleExecutedFunction", {
            scope,
            executedFunction,
            events
          });
        }, 120000)
      });

      return;
    }

    if (!_.isEmpty(executedFunction) && executedFunction.action_logs?.length) {
      dispatch("executeActions", { actionLogs: executedFunction.action_logs });
    }

    commit("removeFromExecutedFunctions", {
      scope
    });

    if (events?.refresh) events.refresh();
  },
  executeActions: async ({ dispatch }, { actionLogs }) => {
    _.forEach(actionLogs, ({ success, action, data }) => {
      if (!success) return;
      switch (action?.type) {
        case ProvisionRequestActionTypes.DISPLAY_RETURN_FIELDS:
          return dispatch("executeDisplayFields", data);
        case ProvisionRequestActionTypes.REDIRECT:
          return dispatch("executeRedirect", data);
        case ProvisionRequestActionTypes.FORM_POST:
          return dispatch("executeFormPost", data);
        default:
          break;
      }
    });
  },
  executeDisplayFields: ({ dispatch }, { display_fields }) => {
    if (!display_fields.length) return;
    dispatch(
      `data/${DataModules.PROVISIONING_CONTRACT_PRODUCTS}/openDisplayFieldsModal`,
      {
        props: { fields: display_fields }
      },
      { root: true }
    );
  },
  executeRedirect: ({ dispatch }, { redirect_url }) => {
    const spawned = window.open(redirect_url, Targets.BLANK);
    if (!spawned || typeof spawned.closed === "undefined") {
      dispatch("ui/openAllowPopupsModal", () => location.assign(redirect_url));
    }
  },
  executeFormPost: (state, { post_fields, post_url }) => {
    submitProgrammaticForm({
      url: post_url,
      fields: post_fields,
      method: HttpMethods.POST,
      target: Targets.BLANK
    });
  },
  handleSocketMessage: async (
    { state, dispatch },
    socketMessage: ISocketMessage
  ) => {
    const data = JSON.parse(socketMessage.message);
    const executedFunction = state.executing[data.request_uuid];

    if (!executedFunction) return;

    try {
      const request = await dispatch(
        `data/${DataModules.PROVISIONING_CONTRACT_PRODUCTS}/getContractProductProvisionRequest`,
        {
          contractId: executedFunction.contractId,
          productId: executedFunction.productId,
          uuid: data.request_uuid,
          returnData: true,
          storeData: false
        },
        { root: true }
      );

      dispatch(
        "toast/show",
        {
          message: _.get(request, "results[0].message")
        },
        { root: true }
      );

      dispatch("handleExecutedFunction", {
        scope: data.request_uuid,
        executedFunction: request,
        events: executedFunction.events
      });
    } catch (error) {
      dispatch("api/handleError", error, { root: true });
    }
  },
  killExecutingFunctions: ({ state, commit }, { vm }) => {
    const cuid = vm.$cuid || "CUID_UNSET";

    _.forEach(_.keys(state.executing), key => {
      if (_.get(state.executing, `${key}._cuid`) !== cuid) return;
      commit("removeFromExecutedFunctions", { scope: key });
    });
  }
};

const mutations: MutationTree<IProvisionFunctionsState> = {
  addToExecutedFunctions: (state, { scope, data }) => {
    setProp(state, ["executing", scope], data);
  },
  removeFromExecutedFunctions: (state, { scope }) => {
    clearTimeout(state.executing[scope]?.timeout);
    deleteProp(state, ["executing", scope]);
  },
  setFunctionTimeout: (state, { scope, data }) => {
    setProp(state, ["executing", scope, "timeout"], data);
  }
};

export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations
};
