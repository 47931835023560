export enum UpmindModuleCodes {
  WEB_HOSTING = "web_hosting"
}

export enum UpmindProductCodes {
  FREE_SINGLE_BRAND_PRODUCT = "free_package",
  INTERNAL_PRODUCT = "free_package_internal",
  SINGLE_BRAND_PRODUCT = "non_free_package",
  MULTI_BRAND_PRODUCT = "non_free_package_multibrand",
  REMOVE_BRANDING_OPTION = "remove_branding"
}
