import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import type { ApiPathGetter } from "@/models/api";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext }): ApiPathGetter =>
    ({ accountId }) => {
      const admin = `api/admin/accounts/${accountId}`;
      const client = `api/accounts/${accountId}`;
      const contextual = isAdminContext ? admin : client;
      return { client, admin, contextual };
    }
};

const actions: ActionTree<IDataState, IState> = {
  update: ({ dispatch, getters }, { accountId, data }) => {
    return dispatch(
      "data/update",
      {
        path: getters.apiPath({ accountId }).contextual,
        storeModule: DataModules.CLIENTS_ACCOUNTS,
        data
      },
      { root: true }
    );
  },
  assignPricelist: ({ dispatch, getters }, { accountId, data }) => {
    return dispatch(
      "data/update",
      {
        path: `${getters.apiPath({ accountId }).contextual}/pricelists`,
        data
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  getters
};
