export enum ActionTypes {
  UPDATE = "update",
  CREATE = "create"
}

export enum AccountTypeCodes {
  CLIENT = "client",
  RESELLER_CLIENT = "reseller_client",
  RESELLER_HIDDEN_CLIENT = "reseller_client_hidden",
  RESELLER_TYPE1 = "reseller_type1",
  RESELLER_TYPE2 = "reseller_type2",
  RESELLER_TYPE3 = "reseller_type3",
  RESELLER_TYPE4 = "reseller_type4"
}

export enum AccessRoleTypes {
  GUEST = "guest",
  CLIENT = "client",
  RESELLER = "reseller",
  USER = "user"
}

export enum PaymentActionTypes {
  LIST = "list",
  MANAGE = "manage",
  SELECT = "select"
}

export enum GatewayTypes { // PaymentTypes
  CARD = 1,
  BANK_TRANSFER = 2,
  DIRECT_DEBIT = 3,
  SEPA = 4,
  OFFLINE = 5,
  MOBILE = 6,
  WALLET = 7,
  // PAY_OUT = 8,
  // EXTERNAL_SUBSCRIPTION = 9,
  AWAITING_CLIENT = 10
}

export enum AdjustTypes {
  SUBTRACT = "subtract",
  ADD = "add"
}

export const OnlineGatewayTypes = [
  GatewayTypes.CARD,
  GatewayTypes.MOBILE,
  GatewayTypes.DIRECT_DEBIT,
  GatewayTypes.AWAITING_CLIENT,
  GatewayTypes.SEPA
];

export enum ProductOrderTypes {
  SINGLE_OPTION = 1,
  QUANTITY_BASED = 2,
  INHERIT = 3
}

export enum FraudPolicies {
  NEUTRAL = 1,
  REVIEW = 2,
  WHITELIST = 3,
  LOCKED = 4
}

export enum FraudDetectionTypes {
  IP = "ip",
  COUNTRY = "country"
}

export enum FraudDetectionEntities {
  ORGANISATION = "organisation",
  BRAND = "brand"
}

export enum ModalTypes {
  SLIDE = "slide",
  WINDOW = "window",
  CONFIRM = "confirm"
}

export enum ModalCancelBy {
  BUTTON = "button",
  ESCAPE = "escape",
  OUTSIDE = "outside",
  X = "x"
}

export const ModalCancelByAll = Object.values(ModalCancelBy);

export enum ConfirmModalModes {
  BUTTON = "button",
  PIN = "pin"
}

export enum FraudEventTypes {
  CLIENT_POLICY = 1,
  CLIENT_STATUS = 2,
  CONTRACT_STATUS = 3,
  IP_MATCH = 4,
  IP_COUNTRY_MATCH = 5,
  FRAUD_SCROE = 6,
  MANUAL_OVERRIDE = 7,
  MISSING_FRAUD_SERVICE = 8,
  MISSING_COUNTRY_IP = 9,
  INHERITED_FROM_CLIENT = 10,
  INHERITED_FROM_CONTRACT = 11
}

export enum FraudStatus {
  NOT_FRAUD = 1,
  REVIEW = 2,
  FRAUD = 3
}

export enum FraudObjectType {
  CLIENT = "client",
  ORDER = "order",
  INVOICE = "invoice",
  CONTRACT = "contract",
  BRAND = "brand"
}

export enum DaysOfWeekTypes {
  MONDAY = "monday",
  TUESDAY = "tuesday",
  WEDNESDAY = "wednesday",
  THURSDAY = "thursday",
  FRIDAY = "friday",
  SATURDAY = "saturday",
  SUNDAY = "sunday"
}

/**
 * let say we have product 100 net + 20 % brand tax = 120
 * in 0 - price is 100
 * in 1 price is 120 but on order time if client use 23% tax can change to 123
 * in 2 we keep 120 even in case on client using 23%
 */
export enum BrandTaxType {
  EXCLUDE_TAX = 0,
  INCLUDE_TAX_RESPECT_CLIENT_TAX = 1,
  INCLUDE_TAX_IGNORE_CLIENT_TAX = 2
}

export enum UUID {
  UPMIND = "upmind", // Successor for `-1`
  ORG = "org", // Successor for `0`
  SYS = "sys" // Successor for `1`
}
