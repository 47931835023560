import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import type { ApiPathGetter } from "@/models/api";
import type { IWebhook } from "@/models/webhooks";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  scope: () => id => {
    return `$webhook_${id}_trigger`;
  },
  apiPath: (): ApiPathGetter => (webhookId: IWebhook["id"]) => {
    const admin = `api/admin/webhooks/${webhookId}/subscriptions`;
    return { admin };
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath(payload.id).admin,
        storeModule: DataModules.WEBHOOKS_TRIGGERS
      },
      { root: true }
    );
  },
  get: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: getters.apiPath(payload.id).admin,
        storeModule: DataModules.WEBHOOKS_TRIGGERS
      },
      { root: true }
    );
  },
  updateCategoryTriggers: ({ dispatch, getters }, { id, data }) => {
    return dispatch(
      "data/update",
      {
        path: `${getters.apiPath(id).admin}`,
        storeModule: DataModules.WEBHOOKS_TRIGGERS,
        data
      },
      { root: true }
    );
  },
  remove: ({ dispatch, getters }, { id, triggerId }) => {
    return dispatch(
      "data/remove",
      {
        path: `${getters.apiPath(id).admin}/${triggerId}`,
        storeModule: DataModules.WEBHOOKS_TRIGGERS
      },
      { root: true }
    );
  },
  openManageModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/modal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/admin/settings/webhooks/manageWebhookTriggerModal.vue"
            ),
          ...payload,
          width: 640
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
