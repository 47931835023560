import type { IDataState } from "@/store/modules/data";

const initialState = {} as IDataState;

export default {
  namespaced: true,
  state: initialState,
  modules: {
    slots: require("./slots").default,
    mappings: require("./mappings").default,
    render: require("./render").default
  }
};
