<template>
  <invoices-provider
    :id="id"
    ref="invoicesProvider"
    v-bind="$attrs"
    :data-scope="dataScope"
    :client-id="clientId"
    :contract-id="contractId"
    :product-id="productId"
    :available-filters="invoicesFilters"
    :available-sorters="invoicesSorters"
    :manual-filters="manualFiltersComputed"
  >
    <template v-slot:default="{ $invoicesData, $invoicesMethods }">
      <div>
        <div class="has-margin-bottom-50">
          <b-input
            v-focus="autofocus && $store.getters['ui/isTabletPlus']"
            :value="$invoicesData.searchQuery"
            :icon-right="$invoicesData.searchQuery ? 'close-circle' : null"
            :placeholder="$t('_placeholder.invoices_quick_search')"
            :icon-right-clickable="true"
            @icon-right-click="$invoicesMethods.doQuickSearch()"
            @input="$invoicesMethods.debounceSearch"
          />
        </div>
        <filter-sort-controls
          v-if="$invoicesData.enableFiltering || $invoicesData.enableSorting"
          :active-filters="$invoicesData.activeFilters"
          :enable-filtering="$invoicesData.enableFiltering"
          :enable-sorting="$invoicesData.enableSorting"
          :pre-defined-filters="$invoicesData.constQueryFilters"
          :filters="$invoicesData.availableFilters"
          :sorters="$invoicesData.availableSorters"
          :table="$invoicesData.table"
          class="has-margin-bottom-100"
          @onRefresh="$invoicesMethods.reloadData"
          @onFiltersChange="$invoicesMethods.onFiltersChange"
          @onSort="$invoicesMethods.onSort"
        />
        <invoices-table-view
          v-bind="$attrs"
          :is-selectable="isSelectable"
          :selected-ids="selectedIds"
          :client-id="clientId"
          :is-selected-linked="isSelectedLinked"
          @updateSelectedIds="$emit('updateSelectedIds', $event)"
        />
        <u-pagination
          :current.sync="$invoicesData.page"
          :per-page="$invoicesData.limit"
          :total="$invoicesData.total"
          :default-limit="$invoicesData.defaults.limit"
          :visible-total="$invoicesData.table.data.length"
          @change-page="$invoicesMethods.onPageChange"
          @change-per-page="$invoicesMethods.onLimitChange"
        />
      </div>
    </template>
  </invoices-provider>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Context from "@/mixins/context";
import InvoicesProvider from "@/components/app/global/invoices/invoicesProvider.vue";
import { DefaultInvoicesFilters } from "@/data/filters/invoice";
import { DefaultInvoicesSorters } from "@/data/sorters/invoices";
import type { PropType } from "vue";
import type { IClient } from "@upmind-automation/types";
import type { IContract, IContractProduct } from "@/models/contracts";
import type { IInvoice } from "@/models/invoices";
import type { ISorter, IFilter } from "@/models/tables";

const InvoicesListing = defineComponent({
  name: "InvoicesListing",
  components: {
    InvoicesProvider,
    "invoices-table-view": () =>
      import("@/components/app/global/invoices/invoicesTableView.vue")
  },
  mixins: [Context],
  props: {
    id: { type: String, default: `IP1` },
    dataScope: { type: String, default: undefined },
    clientId: { type: String as PropType<IClient["id"]>, default: null },
    contractId: { type: String as PropType<IContract["id"]>, default: null },
    productId: {
      type: String as PropType<IContractProduct["id"]>,
      default: undefined
    },
    autofocus: { type: Boolean, default: true },
    isSelectable: { type: Boolean, default: false },
    selectedIds: {
      type: Array as PropType<IInvoice["id"][]>,
      default: () => []
    },
    availableFilters: {
      type: Function as PropType<(vm: typeof InvoicesListing) => IFilter[]>,
      default: DefaultInvoicesFilters
    },
    availableSorters: {
      type: Function as PropType<(vm: typeof InvoicesListing) => ISorter[]>,
      default: DefaultInvoicesSorters
    },
    manualFilters: {
      type: Function as PropType<
        (
          vm?: typeof InvoicesListing
        ) => Record<string, string | number | boolean>
      >,
      default: () => ({})
    },
    isSelectedLinked: { type: Boolean, default: false }
  },
  data: () => ({
    invoicesFilters: [] as IFilter[],
    invoicesSorters: [] as ISorter[]
  }),
  computed: {
    /**
     * Important: Note that manualFilters is passed as a function, thus we must
     * compute the value before passing to the invoicesProvider
     */
    manualFiltersComputed() {
      return this.manualFilters();
    }
  },
  created() {
    this.invoicesFilters = this.availableFilters(this);
    this.invoicesSorters = this.availableSorters(this);
  },
  methods: {
    reloadData() {
      this.$refs?.invoicesProvider?.reloadData();
    }
  }
});

export default InvoicesListing;
</script>
