export default [
  {
    label: "_.dashboard",
    meta: {
      auth: ["list_clients", "list_users"]
    },
    children: [
      {
        label: "_.overview",
        meta: {
          auth: ["list_clients", "list_users"]
        },
        to: {
          name: "adminDashboard"
        }
      }
    ]
  }
];
