import type { ISorter } from "@/models/tables";
import i18n from "@/i18n";

const emailSorters = (): ISorter[] => [
  {
    field: "subject",
    label: i18n.t("_.subject").toString()
  },
  {
    field: "created_at",
    label: i18n.t("_.date").toString()
  }
];

export default emailSorters;
