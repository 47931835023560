import type { ActionTree, GetterTree } from "vuex";
import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { IState } from "@/store";
import { Methods } from "@/models/methods";
import _ from "@/boot/lodash";
import type { ApiPathGetter } from "@/models/api";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath: (): ApiPathGetter => () => {
    const admin = `api/admin/oauth_applications/authorizations`;
    return { admin };
  },
  scope: () => code => {
    return `$oauth_auth_${code}`;
  }
};

const actions: ActionTree<IDataState, IState> = {
  get: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ..._.merge(payload, {
          params: {
            with: "access_token"
          }
        }),
        path: `${getters.apiPath().admin}/${payload.uuid}`,
        storeModule: DataModules.OAUTH_AUTHORIZATIONS
      },
      { root: true }
    );
  },
  initNewAuthAuthorization: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.POST,
        path: getters.apiPath().admin,
        requestConfig: { data: payload.data },
        storeModule: DataModules.OAUTH_AUTHORIZATIONS
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
