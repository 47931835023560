import { FilterOperators, FilterTypes } from "@/data/table";
import type { IFilter } from "@/models/tables";
import i18n from "@/i18n";

export const CreatedAtFilter = (override?: Partial<IFilter>) =>
  ({
    key: "created_at",
    label: i18n.t("_.date_created"),
    type: FilterTypes.DATE,
    operator: FilterOperators.AFTER,
    ...override
  } as IFilter);
