import _ from "@/boot/lodash";
import type { IFilter, IFiltersOptions } from "@/models/tables";
import { FilterOperators, FilterTypes, ApiOperators } from "@/data/table";
import { FraudStatusMap } from "@/data/fraud";
import { InvoiceStatus } from "@/data/enums/invoice";
import type { FraudStatus } from "@/data/enums";
import { BoolTypes } from "@/data/enums/filters";
import i18n from "@/i18n";

export const InvoiceStatusParam = (...statuses: InvoiceStatus[]) => ({
  "filter[status.code]": statuses.join(",")
});

export const FraudStatusParam = (status: FraudStatus) => ({
  "filter[fraud_status]": status
});

export const FromLast30DaysParam = () => ({
  [`filter[created_at|${ApiOperators.AFTER}]`]: "-1_months"
});

export const IdFilter: () => IFilter = () => ({
  key: "id",
  label: i18n.t("_.invoice_id"),
  type: FilterTypes.STRING,
  operator: FilterOperators.EQUAL
});

export const IsProforma: () => IFilter = () => ({
  key: "proforma",
  label: i18n.t("_.is_proforma"),
  options: [
    { value: BoolTypes.TRUE, label: "_.yes" },
    { value: BoolTypes.FALSE, label: "_.no" }
  ],
  value: BoolTypes.TRUE,
  type: FilterTypes.SELECT_RADIO,
  operator: FilterOperators.EQUAL
});

export const NumberFilter: () => IFilter = () => ({
  key: "number",
  label: i18n.t("_.invoice_number"),
  type: FilterTypes.STRING,
  operator: FilterOperators.EQUAL
});

export const TotalAmountFilter: () => IFilter = () => ({
  key: "total_amount",
  label: i18n.t("_.total"),
  type: FilterTypes.NUMBER,
  operator: FilterOperators.EQUAL
});

export const NetAmountFilter: () => IFilter = () => ({
  key: "net_amount",
  label: i18n.t("_.subtotal"),
  type: FilterTypes.NUMBER,
  operator: FilterOperators.EQUAL
});

export const TotalDiscountAmountFilter: () => IFilter = () => ({
  key: "total_discount_amount",
  label: i18n.t("_.discount"),
  type: FilterTypes.NUMBER,
  operator: FilterOperators.EQUAL
});

export const FraudStatusFilter: () => IFilter = () => ({
  key: "fraud_status",
  label: i18n.t("_.fraud_status"),
  type: FilterTypes.SELECT_CHECKBOX,
  operator: FilterOperators.EQUAL,
  options: _.map(_.orderBy(FraudStatusMap(), ["id", "asc"]), status => ({
    value: `${status.id}`,
    label: status.label
  })) as IFiltersOptions[]
});

export const DateCreatedFilter: () => IFilter = () => ({
  key: "create_datetime",
  label: i18n.t("_.date_created"),
  type: FilterTypes.DATE,
  operator: FilterOperators.EQUAL
});

export const DueDateFilter: () => IFilter = () => ({
  key: "due_date",
  label: i18n.t("_.due_date"),
  type: FilterTypes.DATE,
  operator: FilterOperators.EQUAL
});

export const InvoiceStatusFilter: (statuses?: InvoiceStatus[]) => IFilter = (
  statuses = []
) => ({
  key: "status.code",
  label: i18n.t("_.status"),
  type: FilterTypes.SELECT_CHECKBOX,
  options: _.filter(
    [
      { value: InvoiceStatus.PAID, label: i18n.t("_.paid") },
      {
        value: [InvoiceStatus.UNPAID, InvoiceStatus.ADJUSTED],
        label: i18n.t("_.unpaid")
      },
      { value: InvoiceStatus.OVERDUE, label: i18n.t("_.overdue") },
      { value: InvoiceStatus.CANCELLED, label: i18n.t("_.cancelled") },
      { value: InvoiceStatus.REFUNDED, label: i18n.t("_.refunded") }
    ],
    option => {
      if (!statuses?.length) return true;
      return statuses?.includes(option.value as InvoiceStatus);
    }
  ) as IFiltersOptions[],
  operator: FilterOperators.EQUAL
});

export const DefaultInvoicesFilters: () => IFilter[] = () =>
  _.orderBy(
    [
      DateCreatedFilter(),
      DueDateFilter(),
      FraudStatusFilter(),
      IdFilter(),
      IsProforma(),
      NetAmountFilter(),
      NumberFilter(),
      TotalAmountFilter(),
      TotalDiscountAmountFilter()
    ],
    ["label"],
    ["asc"]
  );

export const LegacyInvoicesFilters = () =>
  _.filter(DefaultInvoicesFilters(), filter =>
    ["number", "total_amount", "create_datetime"].includes(filter.key)
  );
