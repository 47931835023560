import type { ActionTree, GetterTree } from "vuex";
import type { IDataState } from "@/store/modules/data/index";
import { DataModules } from "@/store/modules/data/modules";
import type { IState } from "@/store";
import type { ApiPathGetter } from "@/models/api";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext, isMockClientContext }): ApiPathGetter =>
    () => {
      const admin = `api/admin/object_meta`;
      const client = `api/object_meta`;
      const contextual =
        isAdminContext && !isMockClientContext() ? admin : client;
      return { client, admin, contextual };
    },
  scope: () => (type, id) => {
    return `$meta_data_${type}_${id}`;
  }
};

const actions: ActionTree<IDataState, IState> = {
  get: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath().contextual}/${payload.type}/${payload.id}`,
        storeModule: DataModules.META_DATA
      },
      { root: true }
    );
  },
  update: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/update",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload.id}`,
        storeModule: DataModules.META_DATA
      },
      { root: true }
    );
  },
  create: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/create",
      {
        ...payload,
        path: getters.apiPath().admin,
        storeModule: DataModules.META_DATA
      },
      { root: true }
    );
  },
  manageInvoiceMetaData: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import("@/components/app/global/invoices/invoiceMetaDataModal.vue"),
          width: 540,
          ...payload
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
