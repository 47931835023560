import Vue from "vue";
import type { ActionTree, GetterTree, MutationTree } from "vuex";
import type { IState } from "@/store";
import { SnackbarProgrammatic as $snackbar } from "buefy";
import i18n from "@/i18n";

export interface IUtilsRecaptchaState {
  grecaptcha: any;
}

const siteKey = import.meta.env.VITE_APP_GOOGLE_RECAPTCHA_V3_SITE_KEY;

const initialState = (): IUtilsRecaptchaState => {
  return {
    grecaptcha: null
  };
};

const getters: GetterTree<IUtilsRecaptchaState, IState> = {
  hasLoadError: (s, g, { utils }) => utils.script.errored["grecaptcha"] ?? false
};

const actions: ActionTree<IUtilsRecaptchaState, IState> = {
  init: async ({ commit, state, dispatch }) => {
    if (state.grecaptcha) return Promise.resolve(state.grecaptcha);
    return new Promise((resolve, reject) =>
      dispatch(
        "utils/script/load",
        {
          key: "grecaptcha",
          src: `https://www.google.com/recaptcha/api.js?render=${siteKey}`,
          onSuccess: () => {
            window["grecaptcha"].ready(() => {
              commit("grecaptcha", window["grecaptcha"]);
              return resolve(state.grecaptcha);
            });
          }
        },
        { root: true }
      ).catch(reject)
    );
  },
  generateToken: async (
    { dispatch, getters },
    action = ""
  ): Promise<string> => {
    try {
      const grecaptcha = await dispatch("init");
      return grecaptcha.execute(siteKey, { action });
    } catch {
      if (getters.hasLoadError)
        $snackbar.open({
          actionText: i18n.t("_action.dismiss") as string,
          indefinite: true,
          message: i18n.t("_sentence.grecaptcha_unavailable_msg") as string,
          type: "is-danger"
        });
      return Promise.reject();
    }
  }
};

const mutations: MutationTree<IUtilsRecaptchaState> = {
  grecaptcha: (state, payload: IUtilsRecaptchaState["grecaptcha"]) => {
    Vue.set(state, "grecaptcha", payload);
  }
};

export default {
  namespaced: true,
  state: initialState(),
  actions,
  getters,
  mutations
};
