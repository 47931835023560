<template>
  <u-object-router-link
    class="u-notification"
    :class="{ 'is-unread': !status }"
    :object="$_.get(userNotification, 'object')"
    :object-type="$_.get(userNotification, 'object_type')"
    :object-brand-id="$_.get(userNotification, 'sender_brand_id')"
  >
    <p class="u-notification-body">
      <pulse v-if="!status" class="has-margin-right-50" />
      <strong v-if="subject.length">{{ subject }}:</strong>
      <span>
        {{ body }}
        <a v-if="hasMoreBody" @click.stop.prevent="readMore = true">
          <strong>
            <u>{{ $t("_action.read_more").toLowerCase() }}</u>
          </strong>
        </a>
      </span>
    </p>
    <div class="u-notification-footer">
      <datetime :datetime="dateCreated" class="u-notification-date" />
      <div>
        <a
          v-if="!status"
          class="u-notification-dismiss"
          @click.stop.prevent="$emit('markAsRead', userNotification)"
        >
          {{ $t("_action.dismiss") }}
        </a>
        <b-icon icon="arrow-right" size="is-small" />
      </div>
    </div>
  </u-object-router-link>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
import type { IUserNotification } from "@/models/userNotifications";

/**
 * IMPORTANT: this component is used as mixin for `src/plugins/UNotification/UNotification.vue`
 * please if you change something make sure that the notification popup is working.
 * Not the native browser one, but the one that we fire when the user
 * haven not enabled the native notifications.
 */
export default defineComponent({
  name: "UserNotificationMixin",
  props: {
    userNotification: {
      type: Object as PropType<IUserNotification>,
      required: true
    }
  },
  data: () => ({
    charLimit: 150,
    readMore: false
  }),
  computed: {
    status() {
      return this.userNotification.status;
    },
    subject() {
      return this.userNotification.subject;
    },
    notificationMessage() {
      return this.$_.get(this.userNotification, "message");
    },
    hasMoreBody() {
      return this.notificationMessage.length > this.charLimit && !this.readMore;
    },
    body() {
      if (!this.hasMoreBody) return this.notificationMessage;
      return `${this.notificationMessage.substr(
        0,
        this.notificationMessage.lastIndexOf(" ", this.charLimit)
      )}... `;
    },
    dateCreated() {
      return this.$_.get(this.userNotification, "created_at");
    }
  }
});
</script>

<style lang="scss" scoped>
.u-notification {
  display: block;
  font-size: $size-7;
  padding: 0.75rem 1rem;
  background: $white;
  border-left: 4px solid $grey-lighter;
  transition: border-left-color ease-out 0.12s;
  & + & {
    margin-top: 0.5rem;
  }
  .u-notification-body {
    color: $text;
    word-break: break-word;
  }
  .u-notification-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.25em;
    .u-notification-date {
      font-size: $size-8;
      white-space: nowrap;
      color: $grey;
    }
    span.icon {
      color: $grey-light;
      transition: transform ease-out 0.12s;
    }
  }

  .u-notification-dismiss {
    font-weight: $weight-semibold;
    text-decoration: underline;
  }
}
.u-notification.is-unread {
  background: $light;
  border-left-color: $link;
}
div.u-notification {
  span.icon {
    display: none;
  }
}
a.u-notification:hover {
  border-color: $link;
  span.icon {
    color: $link;
    transform: translateX(0.25rem);
  }
}
</style>
