import { $bus } from "@/boot/event-bus";
import { AdminRoutes, RegexMatch } from "@/data/enums/router";
import type { NavigationGuardNext, Route } from "vue-router";

export default [
  {
    path: "orders",
    component: {
      name: "AdminClientOrdersWrapper",
      template: `<router-view v-bind="$attrs" />`
    },
    children: [
      {
        path: "",
        name: "adminClientOrders",
        component: () =>
          import("@/views/admin/clients/client/orders/index.vue"),
        meta: {
          title: "_.orders",
          breadcrumb: {
            label: "_action.edit"
          }
        }
      },
      {
        path: `:orderId(${RegexMatch.UUID})`,
        name: AdminRoutes.ORDER,
        meta: {
          title: "_.order_id"
        },
        component: () =>
          import("@/views/admin/clients/client/orders/order/index.vue"),
        props: {
          routes: require("./menu").clientOrderTabigation
        },
        redirect: { name: "admin__orderSummary" },
        children: [
          {
            path: "overview",
            name: "admin__orderSummary",
            component: () =>
              import("@/components/app/global/orders/orderSummary.vue"),
            props: true,
            meta: {
              auth: ["get_account_contract"],
              backTo: "_.order",
              breadcrumb: {
                label: "_.overview"
              }
            },
            beforeEnter: async (
              to: Route,
              from: Route,
              next: NavigationGuardNext
            ) => {
              $bus.$emit("orderProvider::reloadData");
              next();
            }
          },
          {
            path: "invoices",
            name: "admin__orderInvoices",
            component: () =>
              import("@/components/app/global/orders/orderInvoices.vue"),
            props: true,
            meta: {
              auth: ["get_account_contract"],
              title: "_.invoices",
              breadcrumb: {
                label: "_.invoices"
              }
            }
          },
          {
            path: "credit-notes",
            name: "admin__orderCreditNotes",
            component: () =>
              import("@/components/app/global/orders/orderCreditNotes.vue"),
            props: true,
            meta: {
              auth: ["get_account_contract"],
              title: "_.credit_notes",
              breadcrumb: {
                label: "_.credit_notes"
              }
            }
          }
        ]
      }
    ]
  },
  {
    path: "baskets",
    name: "adminClientBaskets",
    component: () =>
      import("@/views/admin/clients/client/billing/baskets/index.vue"),
    meta: {
      title: "_.baskets"
    }
  }
];
