import type { ActionTree } from "vuex";
import type { IState } from "@/store";
import { ModalTypes } from "@/data/enums";
import type { BModalConfig } from "buefy/types/components";

const actions: ActionTree<IState, IState> = {
  modal: ({ dispatch }, { config }: { config: BModalConfig }) => {
    return dispatch("ui/openModal", { config }, { root: true });
  },
  windowModal: ({ dispatch }, { config }: { config: BModalConfig }) => {
    return dispatch(
      "ui/openModal",
      { type: ModalTypes.WINDOW, config },
      { root: true }
    );
  },
  slideModal: ({ dispatch }, { config }: { config: BModalConfig }) => {
    return dispatch(
      "ui/openModal",
      { type: ModalTypes.SLIDE, config },
      { root: true }
    );
  },
  confirmModal: ({ dispatch }, { config }: { config: BModalConfig }) => {
    return dispatch(
      "ui/openModal",
      { type: ModalTypes.CONFIRM, config },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  actions
};
