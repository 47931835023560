import { AdminRoutes } from "@/data/enums/router";
import i18n from "@/i18n";
import type { IMenuRoute } from "@/models/routes";

export function clientInvoicesTabigation(): IMenuRoute[] {
  return [
    {
      label: i18n.t("_.all"),
      to: {
        name: `adminClientInvoicesAll`
      }
    },
    {
      label: i18n.t("_.paid"),
      to: {
        name: `adminClientInvoicesPaid`
      }
    },
    {
      label: i18n.t("_.unpaid"),
      to: {
        name: AdminRoutes.CLIENT_BILLING_UNPAID_INVOICES
      }
    },
    {
      label: i18n.t("_.credited"),
      to: {
        name: `adminClientInvoicesCredited`
      }
    }
  ];
}

export function transactionsTabigation(): IMenuRoute[] {
  return [
    {
      label: i18n.t("_.successful_payments"),
      to: {
        name: "adminClientSuccessfulPayments"
      }
    },
    {
      label: i18n.t("_.failed_payments"),
      to: {
        name: "adminClientFailedPayments"
      }
    },
    {
      label: i18n.t("_.refunds"),
      to: { name: "adminClientRefunds" }
    },
    {
      label: i18n.t("_.all"),
      to: {
        name: "adminClientAllTransactions"
      }
    }
  ];
}
