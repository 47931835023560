<template>
  <earth-layout>
    <template slot="A">
      <u-header
        :title="$t('_.promotions')"
        :has-brand-switcher="true"
        :cta="cta"
        @click="addPromotion"
      />
      <bermuda-columns>
        <template slot="A">
          <div class="u-promotions-list-filters">
            <u-menu :routes="routes()" />
          </div>
        </template>
        <template slot="B">
          <router-view :key="$route.path" />
        </template>
      </bermuda-columns>
    </template>
  </earth-layout>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
import type { IMenuRoute } from "@/models/routes";

export default defineComponent({
  name: "PromotionsListView",
  props: {
    routes: {
      type: Function as PropType<() => IMenuRoute[]>,
      default: () => {}
    }
  },
  computed: {
    canCreatePromotion() {
      return this.$userCan("create_promotion");
    },
    cta() {
      return this.canCreatePromotion ? this.$t("_.add_promotion") : "";
    }
  },
  methods: {
    addPromotion() {
      if (!this.canCreatePromotion) return;

      this.$router.push({
        name: "admin__addPromotion"
      });
    }
  }
});
</script>

<style lang="scss" scoped>
.u-promotions-list-filters {
  position: sticky;
  top: 2rem;
}
</style>
