<script lang="ts">
import { defineComponent } from "vue";
import CProdProvider from "@/components/app/global/contractProducts/cProdProvider.vue";
import CProdProvProvider from "@/components/app/global/contractProducts/cProdProvProvider.vue";
import type { CreateElement } from "vue";

export default defineComponent({
  name: "CProdProvidersWrapper",
  props: {
    handleLoading: { type: Boolean, default: false }
  },
  render(h: CreateElement) {
    const contractId = this.$route.params?.contractId;
    const productId = this.$route.params?.productId;
    const props = { ...this.$props, contractId, productId };
    return h(CProdProvider, { props, key: productId }, [
      h(CProdProvProvider, { props }, [
        h("div", this.$scopedSlots?.default && this.$scopedSlots.default({}))
      ])
    ]);
  }
});
</script>
