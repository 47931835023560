import type { ISorter } from "@/models/tables";
import i18n from "@/i18n";

export const StatusSorter: () => ISorter = () => ({
  field: "status",
  label: i18n.t("_.status") as string
});

export const DatePurchasedSorter: () => ISorter = () => ({
  field: "created_at",
  label: i18n.t("_.date_purchased") as string
});

export const DateCancelledSorter: () => ISorter = () => ({
  field: "cancelled_date",
  label: i18n.t("_.date_cancelled") as string
});

export const DateLapsedSorter: () => ISorter = () => ({
  field: "closed_date",
  label: i18n.t("_.date_lapsed") as string
});

export const NextDueDateSorter: () => ISorter = () => ({
  field: "next_due_date",
  label: i18n.t("_.next_due_date") as string
});

export const DefaultCProdSorters: () => ISorter[] = () => [
  StatusSorter(),
  DatePurchasedSorter(),
  NextDueDateSorter()
];
