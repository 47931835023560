import _ from "@/boot/lodash";
import i18n from "@/i18n";
import moment from "moment";
import type { ActionTree, GetterTree } from "vuex";
import { BASE_URL } from "@/store/modules/api";
import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { IState } from "@/store";
import { ImageObjectTypes } from "@/models/images";
import { Methods } from "@/models/methods";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext }) =>
    (imageType: ImageObjectTypes, objectId: string, isDefault: boolean) => {
      let path = "images";
      switch (imageType) {
        case ImageObjectTypes.CLIENT:
          path = `clients/${objectId}/images`;
          break;
        case ImageObjectTypes.USER:
          path = `users/${objectId}/images`;
          break;
        case ImageObjectTypes.PRODUCT:
          path = `products/${objectId}/images`;
          break;
        case ImageObjectTypes.PRODUCT_CATEGORY:
          path = `products_categories/${objectId}/images`;
          break;
        case ImageObjectTypes.BRAND:
          path = `brands/${objectId}/images`;
          break;
        case ImageObjectTypes.BRAND_FAVICON:
          path = `brands/${objectId}/images/favicon`;
          break;
        case ImageObjectTypes.BRAND_EMAIL_LOGO:
          path = `brands/${objectId}/images/email_logo`;
          break;
        case ImageObjectTypes.CLIENT_CUSTOM_FIELD:
          path = objectId
            ? `clients/fields/${objectId}/image`
            : `clients/fields/images`;
          break;
      }
      const prepend = isAdminContext ? "api/admin" : "api";
      const append = isDefault ? "default" : "";
      return _.compact([prepend, path, append]).join("/");
    },
  apiPathFunctionality:
    () =>
    (imageType: ImageObjectTypes, actionType: string, isDefault: boolean) => {
      switch (imageType) {
        case ImageObjectTypes.CLIENT:
          return actionType === "upload"
            ? isDefault
              ? "upload_client_default_image"
              : "upload_client_image"
            : "delete_client_image";
        case ImageObjectTypes.USER:
          return actionType === "upload"
            ? isDefault
              ? "upload_user_default_image"
              : "upload_user_image"
            : "delete_user_image";
        case ImageObjectTypes.PRODUCT:
          return actionType === "upload"
            ? isDefault
              ? "upload_product_default_image"
              : "upload_product_image"
            : "delete_product_image";
        case ImageObjectTypes.PRODUCT_CATEGORY:
          return actionType === "upload"
            ? isDefault
              ? "upload_product_default_image"
              : "upload_product_image"
            : "delete_product_image";
        case ImageObjectTypes.BRAND:
          return actionType === "upload"
            ? isDefault
              ? "upload_brand_default_image"
              : "upload_brand_image"
            : "delete_brand_image";
        case ImageObjectTypes.BRAND_FAVICON:
          return actionType === "upload"
            ? "upload_brand_favicon"
            : "delete_brand_favicon";
        case ImageObjectTypes.BRAND_EMAIL_LOGO:
          return actionType === "upload"
            ? "upload_brand_email_logo"
            : "delete_brand_email_logo";
      }
    },
  scope: () => (objectId: string, imageType: ImageObjectTypes) => {
    return `$image_${imageType}_${objectId}`;
  },
  imagesScope: () => (objectId: string, imageType: ImageObjectTypes) => {
    return `$images_${imageType}_${objectId}`;
  },
  hash:
    (state: IDataState, getters) =>
    (objectId: string, imageType: ImageObjectTypes) => {
      return _.get(
        state,
        `${getters.scope(objectId, imageType)}.data.hash`,
        ""
      );
    },
  src:
    (state: IDataState) =>
    (image = {}, scope: string) => {
      const imageObj = _.isEmpty(image)
        ? _.get(state, `${scope}.data`, {})
        : image;
      if (_.isEmpty(_.get(imageObj, "image_url", ""))) {
        return "";
      }
      const src = `${BASE_URL}${_.get(imageObj, "image_url", "")}`;
      if (
        _.isEmpty(_.get(imageObj, "updated_at", "")) ||
        moment
          .utc(_.get(imageObj, "updated_at", ""))
          .diff(moment.now(), "days") > 1
      ) {
        return src;
      }
      return encodeURI(`${src}?t=${_.get(imageObj, "updated_at")}`);
    }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `${getters.apiPath(payload?.imageType, payload?.typeId, false)}`,
        storeModule: DataModules.IMAGES,
        params: {
          with_staged_imports: 1,
          ...payload.params
        }
      },
      { root: true }
    );
  },

  getImage: ({ dispatch, getters }, payload) => {
    if (!payload?.typeId) return;

    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath(
          payload?.imageType,
          payload?.typeId,
          !!payload?.isDefault
        )}`,
        storeModule: DataModules.IMAGES,
        params: {
          with_staged_imports: 1,
          ...payload.params
        }
      },
      { root: true }
    );
  },

  getImageFromHash: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath()}/${payload?.hash}`,
        storeModule: DataModules.IMAGES
      },
      { root: true }
    );
  },

  getImageFromHashDownload: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath()}/${payload?.hash}/download`,
        storeModule: DataModules.IMAGES
      },
      { root: true }
    );
  },

  getCustomFieldImage: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: getters.apiPath(ImageObjectTypes.CLIENT_CUSTOM_FIELD),
        storeModule: DataModules.IMAGES
      },
      { root: true }
    );
  },

  uploadImage: async ({ commit, dispatch, getters, rootGetters }, payload) => {
    if (
      rootGetters["user/can"](
        getters.apiPathFunctionality(
          payload?.imageType,
          "upload",
          !!payload?.isDefault
        )
      )
    ) {
      const image = await dispatch(
        "data/callApi",
        {
          method: Methods.POST,
          path: `${getters.apiPath(
            payload?.imageType,
            payload?.typeId,
            !!payload?.isDefault
          )}`,
          storeModule: DataModules.IMAGES,
          requestConfig: _.merge(
            { data: payload.data },
            _.get(payload, "onUploadProgress")
              ? { onUploadProgress: payload.onUploadProgress }
              : {}
          )
        },
        { root: true }
      );

      if (_.has(payload, "scope")) {
        await commit(
          `data/replaceData`,
          {
            data: image,
            scope: _.get(payload, "scope"),
            storeModule: DataModules.IMAGES
          },
          { root: true }
        );
      }
      return image;
    } else {
      throw i18n.t("_.invalid_permissions");
    }
  },

  uploadCustomFieldImage: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      _.merge(
        {
          method: Methods.POST,
          path: getters.apiPath(
            ImageObjectTypes.CLIENT_CUSTOM_FIELD,
            payload?.fieldId
          ),
          storeModule: DataModules.IMAGES,
          requestConfig: {
            data: payload.data,
            onUploadProgress: payload.onUploadProgress
          }
        },
        _.has(payload, "guestToken")
          ? {
              callConfig: {
                customAccessToken: _.get(payload, "guestToken")
              }
            }
          : {}
      ),
      { root: true }
    );
  },

  deleteImage: ({ dispatch, getters, rootGetters }, payload) => {
    const imageType = _.get(payload, "imageType", "");

    if (
      rootGetters["user/can"](
        getters.apiPathFunctionality(imageType, "delete", false)
      )
    ) {
      return dispatch(
        "data/remove",
        {
          path: `${getters.apiPath(
            imageType,
            payload?.typeId,
            !!payload?.isDefault
          )}${
            ![
              ImageObjectTypes.BRAND_FAVICON,
              ImageObjectTypes.BRAND_EMAIL_LOGO
            ].includes(imageType)
              ? `/${_.get(payload, "hash")}`
              : ""
          }`,
          storeModule: DataModules.IMAGES,
          scope: _.get(payload, "scope")
        },
        { root: true }
      );
    } else {
      throw i18n.t("_.invalid_permissions");
    }
  },

  // eslint-disable-next-line no-empty-pattern
  generateSrc: ({}, payload) => {
    if (_.isEmpty(_.get(payload, "image.image_url", ""))) {
      return "";
    }
    const src = `${BASE_URL}${_.get(payload, "image.image_url", "")}`;
    if (
      _.isEmpty(_.get(payload, "image.updated_at", "")) ||
      moment
        .utc(_.get(payload, "image.updated_at", ""))
        .diff(moment.now(), "days") > 1
    ) {
      return src;
    }
    return `${src}?t=${_.get(payload, "image.updated_at")}`;
  },

  openImagePreviewModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/windowModal",
      {
        config: {
          component: () =>
            import("@/components/app/global/fields/imagePreviewModal.vue"),
          canCancel: ["escape", "outside", "x"],
          width: "100%",
          ...payload
        }
      },
      { root: true }
    );
  },

  setDefault: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PATCH,
        path: `${getters.apiPath(
          payload?.imageType,
          payload?.typeId,
          false
        )}/${_.get(payload, "hash")}/default`,
        storeModule: DataModules.IMAGES,
        requestConfig: { data: payload.params }
      },
      { root: true }
    );
  },

  reorder: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PUT,
        path: `${getters.apiPath(
          payload?.imageType,
          payload?.typeId,
          false
        )}/order`,
        storeModule: DataModules.IMAGES,
        requestConfig: { data: payload.data }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
