import _ from "@/boot/lodash";
import type { IFilter, IFiltersOptions } from "@/models/tables";
import { FilterOperators, FilterTypes, ApiOperators } from "@/data/table";
import { InvoiceStatus } from "@/data/enums/invoice";
import { FraudStatusMap } from "@/data/fraud";
import type { FraudStatus } from "@/data/enums";
import i18n from "@/i18n";
import { CreatedAtFilter } from "@/data/filters/object";

export const OrderStatusParam = (...statuses: InvoiceStatus[]) => ({
  "filter[status.code]": statuses.join(",")
});

export const FromLast24HoursParam = () => ({
  [`filter[created_at|${ApiOperators.AFTER}]`]: "-1_days"
});

export const FromLast7DaysParam = () => ({
  [`filter[created_at|${ApiOperators.AFTER}]`]: "-1_weeks"
});

export const FromLast30DaysParam = () => ({
  [`filter[created_at|${ApiOperators.AFTER}]`]: "-1_months"
});

export const FraudStatusParam = (status: FraudStatus) => ({
  "filter[fraud_status]": status
});

export const IdFilter: () => IFilter = () => ({
  key: "id",
  label: {
    get: () => i18n.t("_.order__id")
  },
  type: FilterTypes.STRING,
  operator: FilterOperators.CONTAINS,
  group: "order"
});

export const NumberFilter: () => IFilter = () => ({
  key: "number",
  label: i18n.t("_.order_number"),
  type: FilterTypes.STRING,
  operator: FilterOperators.CONTAINS,
  group: "order"
});

export const TotalAmountFilter: () => IFilter = () => ({
  key: "total_amount",
  label: i18n.t("_.order_total"),
  type: FilterTypes.NUMBER,
  operator: FilterOperators.EQUAL,
  group: "order"
});

export const FraudStatusFilter: () => IFilter = () => ({
  key: "fraud_status",
  label: i18n.t("_.order_fraud_status"),
  type: FilterTypes.SELECT_CHECKBOX,
  operator: FilterOperators.EQUAL,
  options: _.map(_.orderBy(FraudStatusMap(), ["id", "asc"]), status => ({
    value: `${status.id}`,
    label: status.label
  })) as IFiltersOptions[],
  group: "order"
});

export const StatusCodeFilter: () => IFilter = () => ({
  key: "status.code",
  label: i18n.t("_.order_status"),
  type: FilterTypes.SELECT_CHECKBOX,
  operator: FilterOperators.EQUAL,
  options: [
    { value: InvoiceStatus.PAID, label: i18n.t("_.paid").toString() },
    {
      value: [InvoiceStatus.UNPAID, InvoiceStatus.ADJUSTED],
      label: i18n.t("_.unpaid").toString()
    },
    { value: InvoiceStatus.OVERDUE, label: i18n.t("_.overdue").toString() },
    {
      value: InvoiceStatus.CANCELLED,
      label: i18n.t("_.cancelled").toString()
    },
    { value: InvoiceStatus.REFUNDED, label: i18n.t("_.refunded").toString() }
  ],
  group: "order"
});

const orderFilters: () => IFilter[] = () => [
  {
    key: "id",
    label: i18n.t("_.order__id"),
    type: FilterTypes.STRING,
    operator: FilterOperators.CONTAINS,
    group: "order"
  },
  {
    key: "number",
    label: i18n.t("_.order_number"),
    type: FilterTypes.STRING,
    operator: FilterOperators.CONTAINS,
    group: "order"
  },
  {
    key: "total_amount",
    label: i18n.t("_.order_total"),
    type: FilterTypes.NUMBER,
    operator: FilterOperators.EQUAL,
    group: "order"
  },
  {
    key: "status.code",
    label: i18n.t("_.order_status"),
    type: FilterTypes.SELECT_CHECKBOX,
    operator: FilterOperators.EQUAL,
    options: [
      { value: InvoiceStatus.PAID, label: i18n.t("_.paid").toString() },
      {
        value: [InvoiceStatus.UNPAID, InvoiceStatus.ADJUSTED],
        label: i18n.t("_.unpaid").toString()
      },
      { value: InvoiceStatus.OVERDUE, label: i18n.t("_.overdue").toString() },
      {
        value: InvoiceStatus.CANCELLED,
        label: i18n.t("_.cancelled").toString()
      },
      { value: InvoiceStatus.REFUNDED, label: i18n.t("_.refunded").toString() }
    ],
    group: "order"
  },
  {
    key: "created_at",
    label: i18n.t("_.order_date_purchased"),
    type: FilterTypes.DATE,
    operator: FilterOperators.EQUAL,
    group: "order"
  },
  {
    key: "paid_datetime",
    label: i18n.t("_.order_date_paid"),
    type: FilterTypes.DATE,
    operator: FilterOperators.AFTER,
    group: "order"
  },
  {
    key: "fraud_status",
    label: i18n.t("_.order_fraud_status"),
    type: FilterTypes.SELECT_CHECKBOX,
    operator: FilterOperators.EQUAL,
    options: _.map(_.orderBy(FraudStatusMap(), ["id", "asc"]), status => ({
      value: `${status.id}`,
      label: status.label
    })) as IFiltersOptions[],
    group: "order"
  }
];

const orderItemsFilters: () => IFilter[] = () => [
  {
    key: "products.product.name",
    label: i18n.t("_.order_item_name"),
    type: FilterTypes.STRING,
    operator: FilterOperators.CONTAINS,
    group: "order_item"
  },
  {
    key: "products.product.category.name",
    label: i18n.t("_.order_item_category_name"),
    type: FilterTypes.STRING,
    operator: FilterOperators.CONTAINS,
    group: "order_item",
    if: context => context.isClient
  },
  {
    key: "products.product.category_id",
    label: i18n.t("_.order_item_category"),
    type: FilterTypes.SELECT_MULTI,
    operator: FilterOperators.EQUAL,
    group: "order_item",
    value: [],
    if: context => context.isAdmin
  },
  {
    key: "products.service_identifier",
    label: i18n.t("_.order_item_service_identifier"),
    type: FilterTypes.STRING,
    operator: FilterOperators.CONTAINS,
    group: "order_item"
  }
];

const orderClientsFilters: () => IFilter[] = () => [
  {
    key: "client.username",
    label: i18n.t("_.client_username"),
    type: FilterTypes.STRING,
    operator: FilterOperators.CONTAINS,
    group: "client"
  },
  {
    key: "client.has_login",
    label: i18n.t("_.client_has_login"),
    type: FilterTypes.SELECT_RADIO,
    operator: FilterOperators.EQUAL,
    value: "1",
    group: "client"
  },
  {
    key: "client.firstname",
    label: i18n.t("_.client_firstname"),
    type: FilterTypes.STRING,
    operator: FilterOperators.CONTAINS,
    group: "client"
  },
  {
    key: "client.lastname",
    label: i18n.t("_.client_lastname"),
    type: FilterTypes.STRING,
    operator: FilterOperators.CONTAINS,
    group: "client"
  },
  {
    key: "client.created_at",
    label: i18n.t("_.client_created"),
    type: FilterTypes.DATE,
    operator: FilterOperators.AFTER,
    group: "client"
  },
  {
    key: "client.id",
    label: i18n.t("_.client_id"),
    type: FilterTypes.STRING,
    operator: FilterOperators.EQUAL,
    group: "client"
  },
  {
    key: "client.verified",
    label: i18n.t("_.client_verified"),
    type: FilterTypes.SELECT_RADIO,
    operator: FilterOperators.EQUAL,
    value: "1",
    group: "client"
  },
  {
    key: "client.fraud_status",
    label: i18n.t("_.client_fraud_status"),
    type: FilterTypes.SELECT_CHECKBOX,
    operator: FilterOperators.EQUAL,
    options: _.map(_.orderBy(FraudStatusMap(), ["id", "asc"]), status => ({
      value: `${status.id}`,
      label: status.label
    })) as IFiltersOptions[],
    group: "order"
  }
];

export const DefaultOrderFilters: () => IFilter[] = () => [
  IdFilter(),
  NumberFilter(),
  TotalAmountFilter(),
  CreatedAtFilter({ group: "order", label: i18n.t("_.order_date_purchased") }),
  FraudStatusFilter(),
  StatusCodeFilter()
];

export { orderFilters, orderItemsFilters, orderClientsFilters };
