import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import _ from "@/boot/lodash";
import { Methods } from "@/models/methods";
import type { IContractProduct } from "@/models/contracts";
import { ContractStatusCodes as CSC } from "@/models/contracts";
import type { IClient } from "@upmind-automation/types";
import type { ApiPathGetter } from "@/models/api";
import i18n from "@/i18n";
import { CancellationRequestStatusCodes as CRSC } from "@upmind-automation/types";
import { ModalCancelByAll } from "@/data/enums";
import { TrialEndActionTypes } from "@/data/enums/products";
import objectHash from "object-hash";
import { IsActiveParam } from "@/data/filters/contractProducts";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext, isMockClientContext }): ApiPathGetter =>
    ({ contractId, contractProductId }) => {
      const admin = contractId
        ? `api/admin/contracts/${contractId}/products/${contractProductId}`
        : `api/admin/contract_products/${contractProductId}`;
      const client = contractId
        ? `api/contracts/${contractId}/products/${contractProductId}`
        : `api/contract_products/${contractProductId}`;
      const contextual =
        isAdminContext && !isMockClientContext() ? admin : client;
      return { client, admin, contextual };
    },
  listScope: () => (clientId: IClient["id"]) => {
    return `$client_${clientId}_contract_products`;
  },
  scope: () => (id: IContractProduct["id"]) => {
    return `$contract_product_${id}`;
  },
  withParam:
    (s, g, rS, rootGetters) =>
    (
      config: {
        clientId?: IClient["id"];
      } = {}
    ) => {
      return _.compact([
        !config?.clientId && "clients,clients.image,clients.brand",
        rootGetters.isAdminContext &&
          !rootGetters.isMockClientContext() &&
          "provision_provider.logos",
        rootGetters.isAdminContext &&
          "unresolved_provision_requests,provision_configuration",
        "status",
        "product.image",
        "product.brand.currency",
        "product.provision_blueprint",
        "contract_request", // Required for knowledge of pending cancellations
        "moved_to_contract_product",
        "moved_to_contract_product.clients"
      ]).join();
    },
  isActive:
    () =>
    (cProd?: IContractProduct): boolean => {
      return cProd?.status?.code === CSC.ACTIVE;
    },
  isAwaitingActivation:
    () =>
    (cProd?: IContractProduct): boolean => {
      return cProd?.status?.code === CSC.AWAITING_ACTIVATION;
    },
  isAwaitingSetup:
    () =>
    (cProd?: IContractProduct): boolean => {
      return !(cProd?.provision_setup_fields_confirmed ?? true);
    },
  isCancelled:
    () =>
    (cProd?: IContractProduct): boolean => {
      return cProd?.status?.code === CSC.CANCELLED;
    },
  isImported:
    (state, getters) =>
    (cProd?: IContractProduct): boolean => {
      return !getters.isStaged(cProd) && !!cProd?.import_id;
    },
  isLapsed:
    () =>
    (cProd?: IContractProduct): boolean => {
      return cProd?.status?.code === CSC.CLOSED;
    },
  isPending:
    () =>
    (cProd?: IContractProduct): boolean => {
      return cProd?.status?.code === CSC.PENDING;
    },
  isStaged:
    () =>
    (cProd?: IContractProduct): boolean => {
      return !!cProd?.staged_import;
    },
  isSubscription:
    () =>
    (cProd: IContractProduct): boolean => {
      return _.get(cProd, "billing_cycle_months") > 0;
    },
  isSuspended:
    () =>
    (cProd?: IContractProduct): boolean => {
      return cProd?.status?.code === CSC.SUSPENDED;
    },
  isOnTrial:
    () =>
    (cProd?: IContractProduct): boolean => {
      return !!cProd?.in_trial;
    },
  isOnTerminatingTrial:
    (state, getters) =>
    (cProd?: IContractProduct): boolean => {
      return (
        getters.isOnTrial(cProd) &&
        cProd?.trial_end_action === TrialEndActionTypes.CANCEL
      );
    },
  hasAutoRenewDisabled:
    () =>
    (cProd?: IContractProduct): boolean => {
      return !cProd?.auto_create_renew_invoice;
    },
  hasActiveCancellationRequest:
    () =>
    (cProd?: IContractProduct): boolean => {
      return [
        CRSC.REQUEST_END_OF_BILLING_CYCLE,
        CRSC.REQUEST_CANCELLATION_REQUEST
      ].includes(cProd?.contract_request?.status?.code as CRSC);
    },
  hasHardCancellationRequest:
    () =>
    (cProd?: IContractProduct): boolean => {
      return [CRSC.REQUEST_CANCELLATION_REQUEST].includes(
        cProd?.contract_request?.status?.code as CRSC
      );
    },
  hasSoftCancellationRequest:
    () =>
    (cProd?: IContractProduct): boolean => {
      return [CRSC.REQUEST_END_OF_BILLING_CYCLE].includes(
        cProd?.contract_request?.status?.code as CRSC
      );
    },
  hasAutoExpireEnabled:
    (state, getters) =>
    (cProd?: IContractProduct): boolean => {
      if (!getters.isSubscription(cProd)) return false;
      if (getters.isCancelled(cProd)) return false;
      if (getters.isLapsed(cProd)) return false;
      return !cProd?.renew && !!cProd?.calculated_cancel_date;
    },
  hasMoved:
    () =>
    (cProd?: IContractProduct): boolean => {
      return !!cProd?.moved;
    },
  hasUnpaidRecurringInvoices:
    () =>
    (cProd?: IContractProduct): boolean => {
      return !!cProd?.unpaid_recurring_invoices?.length;
    },
  entity:
    () =>
    (cProd?: IContractProduct): string => {
      return (
        cProd?.product?.provision_blueprint?.service_name ||
        i18n.t("_.product").toString().toLocaleLowerCase()
      );
    },
  name:
    () =>
    (cProd?: IContractProduct): string => {
      return _.compact([
        cProd?.name || cProd?.product?.name_translated || cProd?.product?.name,
        cProd?.service_identifier ? `(${cProd?.service_identifier})` : null
      ]).join(" ");
    }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, rootGetters }, payload) => {
    const isClientContext = rootGetters["isClientContext"];
    const hasDelegatedProducts =
      rootGetters["auth/client/hasDelegatedProducts"];
    if (isClientContext && !hasDelegatedProducts) {
      _.set(payload, "params.exclude_delegated", 1);
    }
    return dispatch(
      "data/list",
      {
        ...payload,
        path: [
          rootGetters.isAdminContext && !rootGetters.isMockClientContext()
            ? "api/admin"
            : "api",
          payload?.clientId
            ? `clients/${payload.clientId}/contracts/products`
            : "contracts_products"
        ].join("/"),
        splitCount: true,
        storeModule: DataModules.CONTRACTS_PRODUCTS
      },
      { root: true }
    );
  },
  groupAndCount: async (
    { commit, dispatch, getters, rootGetters, rootState },
    { scope, clientId, vm }
  ) => {
    const withParam = getters["withParam"]({ clientId });
    const params = {
      with: withParam,
      limit: "count",
      order: "service_identifier",
      group_count: "products.category_id,service_identifier",
      ...IsActiveParam()
    };
    const path = [
      rootGetters.isAdminContext && !rootGetters.isMockClientContext()
        ? "api/admin"
        : "api",
      clientId ? `clients/${clientId}/contracts/products` : "contracts_products"
    ].join("/");

    const dataHash = objectHash.sha1({
      params,
      path,
      brandId: rootState.auth.admin.brandId
    });

    const result = await dispatch(
      "data/list",
      {
        path,
        params,
        storeData: false,
        returnData: false,
        storeModule: DataModules.CONTRACTS_PRODUCTS
      },
      { root: true }
    );

    commit(
      `data/setData`,
      {
        storeModule: DataModules.CONTRACTS_PRODUCTS,
        scope: scope,
        data: _.map(_.get(result, "total"), (totalObj, index) => {
          return { ...totalObj, id: index };
        }),
        total: _.get(result, "total"),
        messages: _.get(result, "messages", null),
        params,
        hash: dataHash
      },
      { root: true }
    );

    commit(
      `data/setDependant`,
      {
        storeModule: DataModules.CONTRACTS_PRODUCTS,
        scope: scope,
        dependant: _.get(vm, "$cuid", "CUID_UNSET")
      },
      { root: true }
    );

    return Promise.resolve();
  },

  get: async ({ dispatch, rootGetters }, payload) => {
    return dispatch(
      "data/get",
      _.merge(
        {
          params: {
            with: [
              "clients",
              "clients.image",
              "contract_request",
              "product",
              "product.brand.currency",
              "product.image"
            ].join(",")
          }
        },
        payload,
        {
          path:
            rootGetters.isAdminContext && !rootGetters.isMockClientContext()
              ? `api/admin/contract_products/${payload.id}`
              : `api/contract_products/${payload.id}`,
          storeModule: DataModules.CONTRACTS_PRODUCTS
        }
      ),
      { root: true }
    );
  },
  update: ({ dispatch, getters, rootGetters }, payload) => {
    if (!rootGetters["user/can"]("update_contract_product"))
      throw new Error(i18n.t("_.invalid_permissions") as string);

    return dispatch(
      "data/update",
      {
        ...payload,
        path: getters.apiPath(payload).contextual,
        storeModule: DataModules.CONTRACTS_PRODUCTS
      },
      { root: true }
    );
  },
  updateProperties: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PUT,
        path: `${getters.apiPath(payload).contextual}/properties`,
        requestConfig: { data: payload.data }
      },
      { root: true }
    );
  },
  modify: (
    { dispatch, getters },
    { contractId, contractProductId, payload }
  ) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PUT,
        path: `${
          getters.apiPath({ contractId, contractProductId }).admin
        }/modify`,
        requestConfig: { data: payload }
      },
      { root: true }
    );
  },
  cancel: ({ dispatch, getters }, { contractId, contractProductId, data }) => {
    return dispatch(
      "data/callApi",
      {
        requestConfig: { data },
        method: Methods.PUT,
        path: `${
          getters.apiPath({ contractId, contractProductId }).admin
        }/cancel`
      },
      { root: true }
    );
  },
  updateTerms: (
    { dispatch, getters },
    { contractId, contractProductId, data }
  ) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PATCH,
        path: `${
          getters.apiPath({ contractId, contractProductId }).admin
        }/terms`,
        requestConfig: { data }
      },
      { root: true }
    );
  },
  modify_renew: (
    { dispatch, getters },
    { contractId, contractProductId, payload }
  ) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PUT,
        path: `${
          getters.apiPath({ contractId, contractProductId }).contextual
        }/modify_renew`,
        requestConfig: { data: payload }
      },
      { root: true }
    );
  },
  toggleAutoRenew: (
    { dispatch, getters },
    { contractId, contractProductId, payload }
  ) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PUT,
        path: `${
          getters.apiPath({ contractId, contractProductId }).contextual
        }/stop_start_invoicing`,
        requestConfig: { data: payload }
      },
      { root: true }
    );
  },
  changeDates: (
    { dispatch, getters },
    { contractId, contractProductId, data }
  ) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PUT,
        path: `${
          getters.apiPath({ contractId, contractProductId }).contextual
        }/dates`,
        requestConfig: {
          data
        }
      },
      { root: true }
    );
  },
  changeAddressOrCompany: ({ dispatch, rootGetters }, { contractId, data }) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PUT,
        path:
          rootGetters.isAdminContext && !rootGetters.isMockClientContext()
            ? `api/admin/contracts/${contractId}/address_company_vat`
            : `api/contracts/${contractId}/address_company_vat`,
        requestConfig: {
          data
        }
      },
      { root: true }
    );
  },
  createNextInvoice: (
    { dispatch, rootGetters },
    { contractId, contractProductId }
  ) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.POST,
        path: [
          rootGetters?.isAdminContext && !rootGetters.isMockClientContext()
            ? "api/admin/invoices/contract"
            : "api/contracts",
          `${contractId}/products/${contractProductId}/recurring`
        ].join("/")
      },
      { root: true }
    );
  },
  activateProduct: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PUT,
        path: `${getters.apiPath(payload).admin}/activate`
      },
      { root: true }
    );
  },
  endTrialEarly: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.POST,
        path: `${getters.apiPath(payload).contextual}/trial_end_action_manual`
      },
      { root: true }
    );
  },
  openManualStatusModal: ({ dispatch }, modalConfig) => {
    return dispatch(
      "ui/open/windowModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/global/contractProducts/cProdManualStatusModal.vue"
            ),
          width: 540,
          canCancel: ["x", "button", "outside"],
          ...modalConfig
        }
      },
      { root: true }
    );
  },
  openRestoreStatusModal: ({ dispatch }, modalConfig) => {
    return dispatch(
      "ui/open/windowModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/global/contractProducts/cProdRestoreStatusModal.vue"
            ),
          width: 540,
          canCancel: ["x", "button", "outside"],
          ...modalConfig
        }
      },
      { root: true }
    );
  },
  setManualStatus: (
    { dispatch, getters },
    { contractId, contractProductId, data }
  ) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PATCH,
        path: `${
          getters.apiPath({ contractId, contractProductId }).admin
        }/manual_status_on`,
        requestConfig: {
          data
        }
      },
      { root: true }
    );
  },
  unsetManualStatus: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PATCH,
        path: `${getters.apiPath(payload).admin}/manual_status_off`,
        requestConfig: {
          data: {
            real_time: true,
            ...(payload.data ? payload.data : {})
          }
        }
      },
      { root: true }
    );
  },
  openModifyProductModal: ({ dispatch }, modalConfig) => {
    return dispatch(
      "ui/open/modal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/global/contractProducts/modifyProductModal.vue"
            ),
          width: 720,
          canCancel: ["x", "button", "outside"],
          ...modalConfig
        }
      },
      { root: true }
    );
  },
  openUpgradeDowngradeModal: ({ dispatch }, modalConfig) => {
    return dispatch(
      `ui/open/windowModal`,
      {
        config: {
          component: () =>
            import(
              "@/components/app/global/contractProducts/upgradeDowngradeModal.vue"
            ),
          width: 540,
          canCancel: ["x", "button", "outside"],
          ...modalConfig
        }
      },
      { root: true }
    );
  },
  changeProduct: (
    { dispatch, getters },
    { contractId, contractProductId, data }
  ) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PUT,
        path: `${
          getters.apiPath({ contractId, contractProductId }).contextual
        }/change`,
        requestConfig: {
          data
        }
      },
      { root: true }
    );
  },
  reactivateProduct: (
    { dispatch, getters },
    { contractId, contractProductId, data }
  ) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PUT,
        path: `${
          getters.apiPath({ contractId, contractProductId }).admin
        }/reactivate`,
        requestConfig: {
          data
        }
      },
      { root: true }
    );
  },
  openReactivationModal: ({ dispatch }, modalConfig) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/global/contractProducts/cProdReactivationModal.vue"
            ),
          canCancel: ["button", "x", "outside"],
          ...modalConfig
        }
      },
      { root: true }
    );
  },
  openQuickLookModal: (
    { dispatch },
    {
      contractId,
      productId,
      productAttributeId,
      productOptionId,
      cProdIdentifier
    }
  ) => {
    dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/global/contractProducts/cProdQuickLookModal.vue"
            ),
          props: {
            contractId,
            productId,
            productAttributeId,
            productOptionId,
            cProdIdentifier
          }
        }
      },
      { root: true }
    );
  },
  openNotesModal: ({ dispatch }, modalConfig) => {
    return dispatch(
      "ui/open/windowModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/global/contractProducts/cProdNotesModal.vue"
            ),
          width: 720,
          canCancel: ModalCancelByAll,
          ...modalConfig
        }
      },
      { root: true }
    );
  },
  openSecretsModal: ({ dispatch }, modalConfig) => {
    return dispatch(
      "ui/open/windowModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/global/contractProducts/cProdSecretsModal.vue"
            ),
          width: 720,
          canCancel: ModalCancelByAll,
          ...modalConfig
        }
      },
      { root: true }
    );
  },
  openChangeCurrencyModal: ({ dispatch }, modalConfig) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/global/contractProducts/cProdChangeCurrencyModal.vue"
            ),
          canCancel: ["button", "x", "outside"],
          ...modalConfig
        }
      },
      { root: true }
    );
  },
  switchCurrency: (
    { dispatch, getters },
    { contractId, contractProductId, data }
  ) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.POST,
        path: `${
          getters.apiPath({ contractId, contractProductId }).admin
        }/switch_currency`,
        requestConfig: { data }
      },
      { root: true }
    );
  },
  transferOwnership: (
    { dispatch, getters },
    { contractId, contractProductId, data }
  ) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.POST,
        path: `${
          getters.apiPath({ contractId, contractProductId }).admin
        }/switch_ownership`,
        requestConfig: { data }
      },
      { root: true }
    );
  },
  openSelectContractProductsModal: ({ dispatch }, modalConfig) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/global/contractProducts/selectContractProductsModal.vue"
            ),
          width: 720,
          canCancel: ModalCancelByAll,
          ...modalConfig
        }
      },
      { root: true }
    );
  },
  openScheduleActionModal: ({ dispatch }, modalConfig) => {
    return dispatch(
      "ui/open/modal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/global/contractProducts/cProdScheduleActionModal.vue"
            ),
          width: 720,
          canCancel: ["x", "button", "outside"],
          ...modalConfig
        }
      },
      { root: true }
    );
  },
  openBulkScheduleActionModal: ({ dispatch }, modalConfig) => {
    return dispatch(
      "ui/open/modal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/global/contractProducts/cProdBulkScheduleActionModal.vue"
            ),
          width: 720,
          canCancel: ["x", "button", "outside"],
          ...modalConfig
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters: {
    ...getters,
    ...require("./cProdCondition").getters,
    ...require("./cProdRoutes").getters
  },
  actions
};
