import store from "@/store";
import type { Location, Route } from "vue-router";
import { RegexMatch, AdminRoutes } from "@/data/enums/router";
import { EmailTypes } from "@/data/enums/emails";
import {
  IsSentParam,
  IsBouncedParam,
  IsFailedParam
} from "@/data/filters/emails";

import EmailsHistoryProvider from "@/components/app/global/emailHistory/emailsHistoryProvider.vue";
import EmailHistoryTable from "@/components/app/global/emailHistory/emailHistoryTable.vue";

const renderEmailHistoryTable = (h, clientId) =>
  h(EmailHistoryTable, {
    props: {
      rowProps: email => ({
        to: {
          name: "adminClientEmailLog",
          params: { emailId: email.id, clientId }
        }
      })
    }
  });

export default [
  {
    path: "logs",
    component: {
      name: "AdminClientLogs",
      template: `<router-view v-bind="$attrs" />`,
      inheritattrs: false
    },
    children: [
      {
        path: "",
        name: "adminClientLogs",
        redirect: { name: "adminClientEmailHistory" }
      },
      {
        path: "email-history",
        name: "adminClientEmailHistory",
        redirect: { name: "adminClientEmailHistoryAll" },
        component: () =>
          import("@/views/admin/clients/client/logs/emailHistory/index.vue"),
        meta: {
          title: "_.email_history",
          backTo: "_.email_history",
          breadcrumb: {
            label: "_action.edit"
          }
        },
        children: [
          {
            path: "failed",
            name: "adminClientEmailHistoryFailed",
            component: {
              render(h) {
                return h(EmailsHistoryProvider, {
                  props: {
                    emailType: EmailTypes.CLIENT,
                    emailTypeId: (this as any).$route.params.clientId,
                    manualFilters: {
                      ...IsFailedParam()
                    }
                  },
                  scopedSlots: {
                    default: ({ $emailsData }) =>
                      renderEmailHistoryTable(h, $emailsData.emailTypeId)
                  }
                });
              }
            }
          },
          {
            path: "bounced",
            name: "adminClientEmailHistoryBounced",
            component: {
              render(h) {
                return h(EmailsHistoryProvider, {
                  props: {
                    emailTypeId: (this as any).$route.params.clientId,
                    emailType: EmailTypes.CLIENT,
                    manualFilters: {
                      ...IsBouncedParam()
                    }
                  },
                  scopedSlots: {
                    default: ({ $emailsData }) =>
                      renderEmailHistoryTable(h, $emailsData.emailTypeId)
                  }
                });
              }
            }
          },
          {
            path: "sent",
            name: "adminClientEmailHistorySent",
            component: {
              render(h) {
                return h(EmailsHistoryProvider, {
                  props: {
                    emailType: EmailTypes.CLIENT,
                    emailTypeId: (this as any).$route.params.clientId,
                    manualFilters: {
                      ...IsSentParam()
                    }
                  },
                  scopedSlots: {
                    default: ({ $emailsData }) =>
                      renderEmailHistoryTable(h, $emailsData.emailTypeId)
                  }
                });
              }
            }
          },
          {
            path: "all",
            name: "adminClientEmailHistoryAll",
            component: {
              render(h) {
                return h(EmailsHistoryProvider, {
                  props: {
                    emailType: EmailTypes.CLIENT,
                    emailTypeId: (this as any).$route.params.clientId
                  },
                  scopedSlots: {
                    default: ({ $emailsData }) =>
                      renderEmailHistoryTable(h, $emailsData.emailTypeId)
                  }
                });
              }
            },
            children: [
              {
                path: `:emailId(${RegexMatch.UUID})`,
                name: "adminClientEmailLog",
                beforeEnter: async (
                  to: Route,
                  from: Route,
                  next: (options: boolean | Location | Location["path"]) => void
                ) => {
                  const { emailId } = to.params;
                  if (emailId) {
                    store.dispatch("ui/open/slideModal", {
                      config: {
                        component: () =>
                          import(
                            "@/components/app/global/emailHistory/viewEmailModal.vue"
                          ),
                        canCancel: ["button"],
                        props: {
                          viewRoute: () => ({
                            name: "adminClientEmailLog",
                            params: to.params
                          }),
                          emailId
                        }
                      }
                    });
                  }
                  store.dispatch("ui/endRouting");
                  return next(!from.matched.length); // Important to maintain pagination/filters etc on parent list view
                }
              }
            ]
          }
        ]
      },
      {
        path: "provision-requests",
        name: AdminRoutes.CLIENT_LOGS_PROVISION_REQUESTS,
        beforeEnter: async (to, from, next) => {
          if (!store.getters[`org/hasProvisioningEnabled`])
            return next({ name: "adminClient" });
          next();
        },
        component: () =>
          import(
            "@/views/admin/clients/client/logs/provisionRequests/index.vue"
          ),
        props: (route: Route) => ({
          clientId: route.params.clientId
        }),
        meta: {
          backTo: "_.provision_requests_log",
          title: "_.provision_requests_log"
        }
      }
    ]
  }
];
