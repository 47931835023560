import _ from "@/boot/lodash";
import i18n from "@/i18n";
import type { ActionTree, GetterTree } from "vuex";
import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ApiPathGetter } from "@/models/api";
import type { IInvoice } from "@/models/invoices";
import type { IState } from "@/store";
import { Methods } from "@/models/methods";
import { ToastProgrammatic as $toast } from "buefy";
import { downloadBlob, convertObjAb2Json } from "@/helpers/file";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext, isMockClientContext }): ApiPathGetter =>
    (slug = "invoices") => {
      const admin = `api/admin/${slug}`;
      const client = `api/${slug}`;
      const contextual =
        isAdminContext && !isMockClientContext() ? admin : client;
      return { client, admin, contextual };
    }
};

const actions: ActionTree<IDataState, IState> = {
  download: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.GET,
        returnData: false,
        path: `${getters.apiPath().contextual}/${payload?.invoiceId}/download`,
        storeModule: DataModules.INVOICES_PDFS,
        requestConfig: { params: payload.params, responseType: "arraybuffer" }
      },
      { root: true }
    );
  },
  downloadLegacy: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.GET,
        returnData: false,
        path: `${getters.apiPath("import_invoice_data").contextual}/${
          payload?.invoiceId
        }/download_pdf`,
        storeModule: DataModules.INVOICES_PDFS,
        requestConfig: { params: payload.params, responseType: "arraybuffer" }
      },
      { root: true }
    );
  },
  tryDownload: async ({ dispatch }, invoice: IInvoice) => {
    try {
      const data = await dispatch("download", { invoiceId: invoice.id });
      const blob = new Blob([data], {
        type: "application/pdf;"
      });
      const filename = `${invoice.number}.pdf`;
      downloadBlob(blob, filename);
    } catch (error) {
      // Convert ArrayBuffer data to JSON
      convertObjAb2Json(error as any, "response.data");
      // Check for 404 (not found)
      if (_.get(error, "response.status") === 404)
        return $toast.open({
          message: i18n.t(
            "_sentence.pdf_is_being_generated_and_will_be_available_shortly"
          ) as string
        });
      dispatch("api/handleError", error, { root: true });
    }
    return;
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
