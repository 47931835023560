import VueClipboard from "vue-clipboard2";
import store from "@/store";

export default {
  install: (Vue: any) => {
    Vue.use(VueClipboard);
    Object.defineProperty(Vue.prototype, "$copyToClipboard", {
      value: (text?: string) => store.dispatch("ui/copyToClipboard", text)
    });
  }
};
