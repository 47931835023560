import type { IDataModuleState, IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { GetterTree, MutationTree } from "vuex";
import type Vue from "vue";
import { Methods } from "@/models/methods";
import type { ActionTree } from "vuex";
import type { IState } from "@/store";
import type { CreateElement } from "vue";
import ObjectNotificationProvider from "@/components/app/admin/objectNotifications/objectNotificationProvider.vue";
import AddEditModal from "@/components/app/admin/objectNotifications/addEditObjectNotificationMappingModal.vue";
import type { ApiPathGetter } from "@/models/api";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath: (): ApiPathGetter => () => {
    const admin = `api/admin/notifications/object_mappings`;
    return { admin };
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload.objectType}/${
          payload.objectId
        }`,
        storeModule: DataModules.OBJECT_NOTIFICATIONS,
        params: {
          with_staged_imports: 1,
          ...payload.params
        }
      },
      { root: true }
    );
  },
  get: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload.id}`,
        storeModule: DataModules.OBJECT_NOTIFICATIONS
      },
      { root: true }
    );
  },
  create: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/create",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload.objectType}/${
          payload.objectId
        }`,
        storeModule: DataModules.OBJECT_NOTIFICATIONS
      },
      { root: true }
    );
  },
  remove: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/remove",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload.id}`,
        storeModule: DataModules.OBJECT_NOTIFICATIONS
      },
      { root: true }
    );
  },
  templateCategories: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.GET,
        path: `${getters.apiPath().admin}/${
          payload.objectType
        }/template_categories`,
        requestConfig: { params: payload.params },
        storeModule: DataModules.OBJECT_NOTIFICATIONS
      },
      { root: true }
    );
  },
  templates: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.GET,
        path: `${getters.apiPath().admin}/${payload.objectType}/templates`,
        requestConfig: { params: payload.params },
        storeModule: DataModules.OBJECT_NOTIFICATIONS
      },
      { root: true }
    );
  },
  openAddEditModal: ({ dispatch }, payload) => {
    dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: {
            render(h: CreateElement) {
              const self = this as unknown as Vue;

              return h(ObjectNotificationProvider, {
                props: payload.props,
                scopedSlots: {
                  default: () =>
                    h(AddEditModal, {
                      attrs: self.$attrs,
                      on: self.$listeners
                    })
                }
              });
            }
          },
          width: 480,
          ...payload
        }
      },
      { root: true }
    );
  }
};

const mutations: MutationTree<IDataModuleState> = {};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
};
