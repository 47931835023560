import _ from "@/boot/lodash";
import type { IDataState } from "@/store/modules/data";
import { DEFAULT_LIST_SCOPE } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionContext, ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  scope: () => id => {
    return `$segmentResults_${id}`;
  },
  listParams: (state: IDataState) => () => {
    return _.get(state, `${DEFAULT_LIST_SCOPE}.params`, {});
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch }: ActionContext<IDataState, IState>, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `api/admin/segments/${payload.id}/results`,
        storeModule: DataModules.SEGMENTS_RESULTS
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
