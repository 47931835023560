import Vue from "vue";
import moment from "moment";
import _ from "@/boot/lodash";
import router from "@/router";
import { Contexts } from "@upmind-automation/types";
import { DataModules } from "@/store/modules/data/modules";
import { GrantTypes } from "@/data/enums/tokens";
import { Methods } from "@/models/methods";
import { UpmindObjectTypes } from "@/data/enums/objects";
import { UPM_DATA_LAYER } from "@/boot/gtm";
import { getCookie } from "@/helpers/cookies";
import { AppHookCodes } from "@/data/enums/hooks";
import { BrandConfigKeys, QUERY_PARAMS } from "@/data/constants";
import { ClientRoutes } from "@/data/enums/router";
import tokenState from "@/store/modules/auth/tokenState";
import type { ActionTree, GetterTree, MutationTree } from "vuex";
import type { IAccount } from "@upmind-automation/types";
import type { IAuthenticate } from "@/models/auth";
import type { IChildAccount, IClient } from "@upmind-automation/types";
import type { IEmail } from "@/models/emails";
import type { IState } from "@/store";
import type { IToken } from "@/models/token";
import type { IUser } from "@/models/users";
import type { IDomain } from "@/models/domains";

export interface IAuthClientState {
  accounts: IAccount[];
  accountId: IAccount["id"];
  delegatedIds: Record<UpmindObjectTypes, string[]> | {};
  isImpersonated: boolean;
  token: IToken;
}

export const initialState = (): IAuthClientState => {
  return {
    accounts: [],
    accountId: _.get(localStorage, "client/auth/accountId", ""),
    delegatedIds: {},
    isImpersonated: false,
    token: tokenState(Contexts.CLIENT)
  };
};

enum PostLoginDestinations {
  ACCOUNT = "account",
  BILLING = "billing",
  DASHBOARD = "dashboard",
  PRODUCTS = "products",
  SUPPORT = "support"
}

const getters: GetterTree<IAuthClientState, IState> = {
  isAuthenticated: (state, getters, rootState, rootGetters): boolean => {
    return (
      !_.isEmpty(state.token.access_token) &&
      !rootGetters["auth/hasExpiredSession"](Contexts.CLIENT)
    );
  },
  isGuestCustomerAuthenticated: (state, getters, rootState): boolean => {
    return (
      getters["isAuthenticated"] && _.get(rootState.user, "is_guest", false)
    );
  },
  account: state => {
    return _.find(state.accounts, ["id", state.accountId]) || null;
  },
  accountIds: state => {
    return _.map(state.accounts, account => account.id);
  },
  currentOauthClient: () => {
    return window.location.hostname;
  },
  defaultOauthClient: (state, getters) => {
    const defaultClient = _.find(getters["oauthClients"], ["default", true]);
    return _.get(defaultClient, "origin", window.location.host);
  },
  defaultOauthClientRedirect: (state, getters) => {
    return `http://${getters["defaultOauthClient"]}${window.location.pathname}${window.location.search}`;
  },
  hasDelegatedProducts: state => {
    return !!state.delegatedIds[UpmindObjectTypes.CONTRACTS_PRODUCT]?.length;
  },
  isSupportedOauthClient: (state, { currentOauthClient, oauthClients }) => {
    // Here we map origins to lowercase to prevent valid mismatch
    return _.map(oauthClients as IDomain[], ({ origin }) =>
      origin.toLowerCase()
    ).includes(currentOauthClient.toLowerCase());
  },
  oauthClients: (state, getters, rootState) => {
    return _.get(rootState, "brand.oauth_clients", []);
  }
};

const actions: ActionTree<IAuthClientState, IState> = {
  getUser: async ({ commit, dispatch, rootGetters }) => {
    const self = await dispatch(
      "api/call",
      {
        method: Methods.GET,
        path: "api/self",
        requestConfig: {
          params: {
            with_count: "actor.child_client_configs", // Relation required for determining child relation
            with: [
              "actor",
              "actor.account", // Relation required for determining `topup_enabled` value
              "actor.brand", // Relation required for determining `topup_enabled` value
              "actor.parent_client_config.parent_client", // Relation required for determining parent relation
              "actor.parent_client_config.parent_client.image",
              "accounts",
              "delegated_ids",
              "enabled_modules"
            ].join()
          }
        }
      },
      { root: true }
    );
    const user: IUser = _.get(self.data, `actor`);
    commit("user", user, { root: true });
    // Push 'actor_identified' event + set `upmind.user_id`
    UPM_DATA_LAYER.push({
      event: "upmind.actor_identified",
      upmind: {
        user_id: user.id
      }
    });
    const accounts = _.get(self.data, `accounts`);
    commit("accounts", accounts);
    // Commit whether client is being impersonated
    const isImpersonated = !!_.get(self.data, `impersonator_id`);
    commit("isImpersonated", isImpersonated);
    const localeCode = rootGetters["user/locale"];
    dispatch("i18n/setLanguage", localeCode, { root: true });
    const delegatedIds = _.get(self.data, `delegated_ids`);
    commit("delegatedIds", delegatedIds);
    return self;
  },
  selectAccount: async ({ dispatch, state, getters }) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async resolve => {
      // If only 1 account, select that account
      if (state.accounts.length === 1) {
        return dispatch("setAccount", getters["accountIds"][0]).then(resolve);
      }
      // If previous accountId is in memory
      if (state.accountId) {
        // And its a valid account option
        if (getters["accountIds"].includes(state.accountId)) {
          return dispatch("setAccount", state.accountId).then(resolve);
        }
      }
      // Otherwise, select account
      const modal = await dispatch(
        "ui/open/windowModal",
        {
          config: {
            width: 400,
            hasModalCard: false,
            component: () =>
              import("@/components/app/client/tenancy/selectAccountModal.vue"),
            onCancel: () => {
              // If cancelled, default to first account
              return dispatch("setAccount", getters["accountIds"][0]).then(
                resolve
              );
            },
            events: {
              "select-account": (accountId: IAccount["id"]) => {
                return dispatch("setAccount", accountId)
                  .then(resolve)
                  .then(() => modal.close());
              }
            }
          }
        },
        { root: true }
      );
    });
  },
  setAccount: async (
    { dispatch, commit, getters, rootGetters },
    accountId: IAccount["id"]
  ) => {
    const data = { account_id: accountId };
    await dispatch(
      "api/call",
      {
        method: Methods.POST,
        path: "api/accounts/select",
        requestConfig: { data }
      },
      { root: true }
    );
    commit("accountId", accountId);
    await dispatch(
      "brand/get",
      { ignoreStored: getters.account?.brand_id !== rootGetters["brand/id"] },
      { root: true }
    );
    dispatch("auth/userReady", {}, { root: true });
    return Promise.resolve();
  },
  reauthenticate: async ({ state, dispatch }, payload: IAuthenticate) => {
    const token = await dispatch("auth/getToken", payload, { root: true });
    const data = { account_id: state.accountId };
    await dispatch(
      "api/call",
      {
        method: Methods.POST,
        path: "api/accounts/select",
        requestConfig: { data },
        callConfig: {
          customAccessToken: token.access_token
        }
      },
      { root: true }
    );
    await dispatch(
      "auth/saveToken",
      { token, context: Contexts.CLIENT },
      { root: true }
    );
    return Promise.resolve();
  },
  handleLogin: async ({ rootGetters, dispatch }) => {
    const lastRoute = _.last(router.currentRoute.matched);
    if (_.get(lastRoute, "path", "").startsWith("/auth/")) {
      const redirect = _.get(
        router.currentRoute,
        "query.redirect",
        ""
      ) as string;
      const blacklist =
        /^(\/auth)?\/(login|register|forgotten-password|reset-password|verify|relay|logout)/gi;
      router
        .replace(
          redirect && !redirect.match(blacklist)
            ? redirect.replace(/^\\+/, "")
            : await dispatch(
                "getPostLoginDestination",
                rootGetters["brand/config"][
                  BrandConfigKeys.UI_CLIENT_APP_PAGE_AFTER_LOGIN
                ]
              )
        )
        .catch(err => err);
    }
  },
  getPostLoginDestination: (context, destination: PostLoginDestinations) => {
    switch (destination) {
      case PostLoginDestinations.ACCOUNT:
        return { name: ClientRoutes.ACCOUNT };
      case PostLoginDestinations.BILLING:
        return { name: ClientRoutes.BILLING };
      case PostLoginDestinations.PRODUCTS:
        return {
          name: ClientRoutes.CONTRACT_PRODUCTS,
          query: _.pick(router.currentRoute.query, [QUERY_PARAMS.INIT])
        };
      case PostLoginDestinations.SUPPORT:
        return { name: ClientRoutes.SUPPORT };
      case PostLoginDestinations.DASHBOARD:
      default:
        return { name: ClientRoutes.DASHBOARD };
    }
  },
  prepare: async ({ dispatch, commit, rootGetters }) => {
    // Get client user data before entering "authenticated" client route
    await dispatch("getUser");
    // Select client account
    await dispatch("selectAccount");
    // If coming from a "GUEST" session...
    if (rootGetters["auth/guest/isAuthenticated"]) {
      // Claim any unclaimed baskets
      await dispatch(
        `data/${DataModules.BASKETS}/claimGuestBaskets`,
        {},
        { root: true }
      );
      // Reset "GUEST" localStorage, *before* resetting auth state
      commit(`auth/clearLocalStorage`, Contexts.GUEST, { root: true });
      // Reset "GUEST" auth state
      commit(`auth/${Contexts.GUEST}/reset`, {}, { root: true });
    }
    return;
  },
  register: ({ dispatch, rootState }, { form, accessToken }) => {
    const referral_cookie = getCookie("upm_aff");
    const tracking = _.get(rootState, "upm.track");
    return dispatch(
      "api/call",
      {
        method: Methods.POST,
        path: "api/clients/register",
        requestConfig: {
          data: {
            ...form,
            ...(referral_cookie ? { referral_cookie } : {}),
            ...(tracking ? { tracking } : {})
          }
        },
        callConfig: {
          customAccessToken: accessToken
        }
      },
      { root: true }
    ).then(async result => {
      // Fire app hook
      dispatch(`ui/hooks/${AppHookCodes.CLIENT_REGISTERED}`, result?.data?.id, {
        root: true
      });
      return result;
    });
  },
  verifyRegHash: (
    { dispatch },
    data: {
      username: IAuthenticate["username"];
      reg_hash: IAuthenticate["reg_hash"];
    }
  ) => {
    return dispatch(
      "api/call",
      {
        method: Methods.PATCH,
        path: "api/clients/reg_hash/verify",
        requestConfig: {
          data
        }
      },
      { root: true }
    );
  },
  verifyEmailAddress: (
    { dispatch, rootGetters },
    {
      reg_hash,
      client_id,
      email_id
    }: {
      reg_hash: IAuthenticate["reg_hash"];
      client_id: IClient["id"];
      email_id: IEmail["id"];
    }
  ) => {
    return dispatch(
      "api/call",
      {
        method: Methods.PATCH,
        path: rootGetters.isAdminContext
          ? `api/admin/clients/${client_id}/emails/${email_id}/check_verify`
          : `api/clients/${client_id}/emails/${email_id}/check_verify`,
        requestConfig: {
          data: {
            reg_hash
          }
        }
      },
      { root: true }
    );
  },
  completeRegistration: async (
    { dispatch },
    {
      reg_hash,
      username,
      password
    }: {
      reg_hash: IAuthenticate["reg_hash"];
      username: IAuthenticate["username"];
      password: IAuthenticate["password"];
    }
  ) => {
    const { token }: { token: IToken } = await dispatch(
      "auth/createAccessToken",
      {
        data: {
          grant_type: GrantTypes.COMPLETE_REGISTRATION,
          password,
          reg_hash,
          username
        }
      },
      { root: true }
    );
    await dispatch(
      "auth/saveToken",
      { token, context: Contexts.CLIENT },
      { root: true }
    );
    dispatch("handleLogin");
    return Promise.resolve();
  },
  impersonateChild: async (
    { state, dispatch },
    { child_client_id: childId }: IChildAccount
  ) => {
    try {
      const context = Contexts.CLIENT;
      // First, we take a local copy of the current parent client token.
      const parentToken = state.token;
      // Next, we mint a new child client impersonation token.
      const childToken: IToken = await dispatch("mintChildToken", childId);
      // Next, we set the current client token to 'unauthorized' as to prevent
      // the subsequent logout operation from actually terminating the session.
      // Instead, it will just do the usual housekeeping (state resets etc).
      await dispatch(
        "auth/saveToken",
        { token: { unauthorized: true }, context },
        { root: true }
      );
      // Next, we run the logout operation
      await dispatch("auth/logout", context, { root: true });
      // Next, we save the new child token into state & localStorage
      await dispatch(
        "auth/saveToken",
        { token: childToken, context },
        { root: true }
      );
      // Next, we backup the old parent token so it can later be restored
      await dispatch(
        "auth/backupToken",
        { token: parentToken, context },
        { root: true }
      );
      // Finally, we route the user to a special boomerang route (`/login-as`).
      // We do this because when a user (re)enters any authenticated client route,
      // we run a whole bunch of setup logic. Search for `auth/client/prepare` to
      // get a better context.
      return router.replace({ name: ClientRoutes.LOGIN_AS }).catch(err => err);
    } catch (error) {
      dispatch("api/handleError", error, { root: true });
    }
  },
  mintChildToken: async ({ dispatch }, childId: IClient["id"]) => {
    const token: IToken = await dispatch(
      "data/callApi",
      {
        method: Methods.POST,
        path: `api/clients/${childId}/access_token`
      },
      { root: true }
    );
    token.created_at = moment().unix();
    return token;
  }
};

const mutations: MutationTree<IAuthClientState> = {
  accounts: (state, accounts: IAccount[]) => {
    Vue.set(state, "accounts", accounts);
  },
  accountId: (state, accountId: IAccount["id"]) => {
    Vue.set(state, "accountId", accountId);
    localStorage.setItem("client/auth/accountId", accountId);
  },
  delegatedIds: (state, delegatedIds: IAuthClientState["delegatedIds"]) => {
    Vue.set(state, "delegatedIds", delegatedIds ?? {});
  },
  isImpersonated: (state, val: boolean) => {
    Vue.set(state, "isImpersonated", val);
  },
  reset: state => {
    for (const property in localStorage) {
      if (property.startsWith("client/auth/")) {
        localStorage.removeItem(property);
      }
    }
    Object.assign(state, initialState());
  }
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations
};
