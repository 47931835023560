import type { ISorter } from "@/models/tables";
import i18n from "@/i18n";

export const NameSorter: () => ISorter = () => ({
  field: "name",
  label: i18n.t("_.name") as string
});

export const CodeSorter: () => ISorter = () => ({
  field: "code",
  label: i18n.t("_.code") as string
});

export const DefaultOrderTemplatesSorters: () => ISorter[] = () => [
  NameSorter(),
  CodeSorter()
];
