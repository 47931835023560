import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree } from "vuex";
import type { IState } from "@/store";
import { Methods } from "@/models/methods";

const apiPath = taxTagId => `api/admin/tax_tags/${taxTagId}/locations`;

const initialState = {} as IDataState;

const getters = {
  scope: () => id => {
    return `$taxLocation${id}`;
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: apiPath(payload.taxTagId),
        storeModule: DataModules.TAX_LOCATIONS
      },
      { root: true }
    );
  },
  update: ({ dispatch }, payload) => {
    return dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.PUT,
        path: apiPath(payload.taxTagId),
        storeModule: DataModules.TAX_LOCATIONS,
        requestConfig: { data: payload.data }
      },
      { root: true }
    );
  },
  openAddEditModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/global/tax/locations/addEditTaxLocationModal.vue"
            ),
          width: 540,
          ...payload
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
