import { AdminRoutes } from "@/data/enums/router";
import i18n from "@/i18n";
import type { IMenuRoute } from "@/models/routes";

export function affiliateAccountTabigation(): IMenuRoute[] {
  return [
    {
      label: i18n.t("_.overview"),
      to: { name: AdminRoutes.CLIENT_ACCOUNTS_AFFILIATE_ACCOUNT_OVERVIEW }
    },
    {
      label: i18n.t("_.commissions"),
      to: { name: AdminRoutes.CLIENT_ACCOUNTS_AFFILIATE_ACCOUNT_COMMISSIONS }
    },
    {
      label: i18n.t("_.payouts"),
      to: { name: AdminRoutes.CLIENT_ACCOUNTS_AFFILIATE_ACCOUNT_PAYOUTS }
    },
    {
      label: i18n.t("_.settings"),
      to: { name: AdminRoutes.CLIENT_ACCOUNTS_AFFILIATE_ACCOUNT_SETTINGS }
    }
  ];
}
