import type { ActionTree, GetterTree } from "vuex";
import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { IState } from "@/store";
import type { ApiPathGetter } from "@/models/api";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (): ApiPathGetter =>
    ({ id }) => {
      const admin = `api/admin/contracts/scheduled_actions/bulk/${id}`;
      return { admin };
    }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath({
          id: payload.id
        }).admin,
        storeModule: DataModules.CONTRACTS_PRODUCTS_SCHEDULED_ACTION_PRODUCTS
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
