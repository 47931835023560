import { ToastProgrammatic as $toast } from "buefy";
import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import i18n from "@/i18n";
import { Methods } from "@/models/methods";
import { UUID } from "@/data/enums";
import _ from "@/boot/lodash";
import type { ApiPathGetter } from "@/models/api";
import { OrgFeatureKeys } from "@/data/constants";
import { AppHookCodes } from "@/data/enums/hooks";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext, isMockClientContext }): ApiPathGetter =>
    (slug = "brands") => {
      const admin = `api/admin/${slug}`;
      const client = `api/${slug}`;
      const contextual =
        isAdminContext && !isMockClientContext() ? admin : client;
      return { client, admin, contextual };
    },
  brandScope: () => id => {
    return `$brand_${id}`;
  },
  configurationScope: () => id => {
    return `$brand_${id}_configuration`;
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters, rootGetters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath(
          rootGetters.isUpmindContext && payload.clientId
            ? `clients/${payload.clientId}/upmind_brands`
            : undefined
        ).admin,
        storeModule: DataModules.BRANDS
      },
      { root: true }
    );
  },

  get: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload?.id}`,
        storeModule: DataModules.BRANDS
      },
      { root: true }
    );
  },

  openCreateBrandModal: async ({ rootGetters, dispatch }) => {
    if (!rootGetters["user/can"]("create_brand")) {
      return dispatch("ui/showErrorMessage", i18n.t("_.invalid_permissions"), {
        root: true
      });
    }

    const { hasMissingKeys } = await dispatch(
      "org/guardMissingFeatureKeys",
      { featureKeys: [OrgFeatureKeys.MULTI_BRAND_ENABLED] },
      { root: true }
    );

    if (hasMissingKeys) return;

    dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/admin/settings/brands/createBrandModal.vue"
            )
        }
      },
      { root: true }
    );
  },

  checkDomain: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.GET,
        path: getters.apiPath("checkdomain").contextual,
        requestConfig: {
          params: payload.data
        }
      },
      { root: true }
    );
  },

  getInvoiceNumberAndPrefix: ({ dispatch, getters }) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.GET,
        path: getters.apiPath("brand/invoice_number").admin
      },
      { root: true }
    );
  },

  updateInvoiceNumberAndPrefix: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PUT,
        path: getters.apiPath("brand/invoice_number").admin,
        requestConfig: {
          data: payload.data
        }
      },
      { root: true }
    );
  },

  getBrandSettings: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        method: Methods.GET,
        path: payload.id
          ? `${getters.apiPath().contextual}/${payload?.id}/settings`
          : getters.apiPath("brand/settings").contextual,
        ...payload,
        storeModule: DataModules.BRANDS
      },
      { root: true }
    );
  },

  getBrandConfiguration: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        method: Methods.GET,
        path: getters.apiPath("config/brand/values").contextual,
        storeModule: DataModules.BRANDS
      },
      { root: true }
    );
  },

  getObjectNumber: ({ dispatch }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.GET,
        path: `api/admin/object_number/${payload.id}/${payload.type}`,
        rootGetters: {
          brand_id: payload.id,
          object_type: payload.type
        }
      },
      { root: true }
    );
  },

  updateObjectNumber: ({ dispatch }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PUT,
        path: `api/admin/object_number/${payload.id}/${payload.type}`,
        requestConfig: { data: payload.data }
      },
      { root: true }
    );
  },

  create: async ({ dispatch, getters, rootGetters }, payload) => {
    if (!rootGetters["user/can"]("create_brand")) {
      throw new Error(i18n.t("_.invalid_permissions") as string);
    }
    const brand = await dispatch(
      "data/create",
      {
        ...payload,
        path: getters.apiPath().admin,
        storeModule: DataModules.BRANDS
      },
      { root: true }
    );
    // Get user to retrieve new functionalities
    await dispatch("auth/admin/getUser", null, { root: true });
    // Switch to new brand
    await dispatch("auth/admin/setBrand", brand.id, { root: true });
    return brand;
  },

  update: async ({ dispatch, getters, rootGetters }, payload) => {
    if (!rootGetters["user/can"]("update_brand"))
      throw new Error(i18n.t("_.invalid_permissions") as string);
    return dispatch(
      "data/update",
      {
        path: `${getters.apiPath().admin}/${payload?.id}`,
        storeModule: DataModules.BRANDS,
        data: payload.data
      },
      { root: true }
    );
  },

  remove: ({ rootState, dispatch, getters, rootGetters }, brandId) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        if (!rootGetters["user/can"]("delete_brand")) {
          throw new Error(i18n.t("_.invalid_permissions") as string);
        }
        await dispatch(
          "data/remove",
          {
            path: `${getters.apiPath().admin}/${brandId}`,
            storeModule: DataModules.BRANDS
          },
          { root: true }
        );
        // Switch to org mode if user was on deleted brand
        if (rootState.auth.admin.brandId === brandId) {
          await dispatch("auth/admin/setBrand", UUID.ORG, {
            root: true
          });
        }
        // Get user to update functionalities
        await dispatch("auth/admin/getUser", null, { root: true });
        // Resolve promise
        resolve(true);
        $toast.open({
          message: i18n.t("_.object_successfully_removed", {
            object: i18n.t("_.brand") as string
          }) as string
        });
      } catch (error) {
        const errorMsg = _.get(
          error,
          rootGetters["api/is409"](error)
            ? "response.data.error.message"
            : "message",
          error
        );
        $toast.open({
          message: errorMsg,
          type: "is-danger"
        });
        reject();
      }
    });
  },

  wipeData: ({ dispatch }, brandId) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.DELETE,
        path: `api/admin/brands/${brandId}/wipe`
      },
      { root: true }
    );
  },

  seedDemoData: async ({ dispatch, getters }, { brandId }) => {
    // Seed data
    await dispatch(
      "data/create",
      {
        path: `${getters.apiPath().admin}/${brandId}/demo_data`,
        storeModule: DataModules.BRANDS
      },
      { root: true }
    );
    // Add track event
    await dispatch(
      "ui/userflow/track",
      { event: AppHookCodes.DEMO_DATA_SEEDED },
      { root: true }
    );
    return Promise.resolve();
  },

  deleteDemoData: async ({ dispatch, getters }, { brandId }) => {
    return dispatch(
      "data/remove",
      {
        path: `${getters.apiPath().admin}/${brandId}/demo_data`,
        storeModule: DataModules.BRANDS
      },
      { root: true }
    );
  },

  openOAuthClientsModal: ({ dispatch }, config) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: _.merge(
          {
            component: () =>
              import("@/components/app/admin/upmind/domainsModal.vue"),
            width: 740
          },
          config
        )
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
