import { UpmindClientRoutes } from "@/data/enums/router";
import i18n from "@/i18n";
import type { ICProdProvider } from "@/models/providers/contractProduct";
import type { IMenuRoute } from "@/models/routes";

const isActive: IMenuRoute["isActive"] = (currentRoute, isActive) => {
  if ((currentRoute.name || "").match(/__upmind(Cancelled|Active)/g))
    return false;
  return isActive;
};

export function UpmindClientProductsTabigation() {
  return [
    {
      label: i18n.t("_.active"),
      to: {
        name: "admin__upmindActiveProducts"
      }
    },
    {
      label: i18n.t("_.cancelled"),
      to: {
        name: "admin__upmindCancelledProducts"
      }
    },
    {
      isActive,
      label: i18n.t("_.all"),
      to: {
        name: "admin__upmindAllProducts"
      }
    }
  ];
}

export function UpmindClientSubscriptionsTabigation() {
  return [
    {
      label: i18n.t("_.active"),
      to: {
        name: "admin__upmindActiveSubscriptions"
      }
    },
    {
      label: i18n.t("_.cancelled"),
      to: {
        name: "admin__upmindCancelledSubscriptions"
      }
    },
    {
      isActive,
      label: i18n.t("_.all"),
      to: {
        name: "admin__upmindAllSubscriptions"
      }
    }
  ];
}

export function UpmindClientOneTimePurchasesTabigation() {
  return [
    {
      label: i18n.t("_.active"),
      to: {
        name: "admin__upmindActiveOneTimePurchases"
      }
    },
    {
      label: i18n.t("_.cancelled"),
      to: {
        name: "admin__upmindCancelledOneTimePurchases"
      }
    },
    {
      isActive,
      label: i18n.t("_.all"),
      to: {
        name: "admin__upmindAllOneTimePurchases"
      }
    }
  ];
}

export function UpmindClientCProdTabigation({
  $cProdData
}: {
  $cProdData: ICProdProvider;
}) {
  return [
    {
      label: i18n.t("_.overview"),
      to: {
        name: UpmindClientRoutes.CONTRACT_PRODUCT_OVERVIEW
      }
    },
    {
      label: i18n.t("_.billing"),
      to: {
        name: UpmindClientRoutes.CONTRACT_PRODUCT_BILLING
      }
    },
    {
      label: i18n.t("_.settings"),
      to: {
        name: UpmindClientRoutes.CONTRACT_PRODUCT_SETTINGS
      },
      if: () => {
        return $cProdData.canModifySettings;
      }
    }
  ];
}
