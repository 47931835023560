import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import i18n from "@/i18n";
import { ConfirmModalModes } from "@/data/enums";

const initialState = {} as IDataState;
const API_PATH = "api/admin/invoice_templates";

const getters: GetterTree<IDataState, IState> = {
  scope: () => {
    return "$order_templates";
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: API_PATH,
        storeModule: DataModules.ORDERS_TEMPLATES
      },
      { root: true }
    );
  },
  create: ({ dispatch }, { data }) => {
    return dispatch(
      "data/create",
      {
        data,
        path: API_PATH,
        storeModule: DataModules.ORDERS_TEMPLATES
      },
      { root: true }
    );
  },
  update: ({ dispatch }, { templateId, data }) => {
    return dispatch(
      "data/update",
      {
        data,
        path: `${API_PATH}/${templateId}`,
        storeModule: DataModules.ORDERS_TEMPLATES
      },
      { root: true }
    );
  },
  remove: ({ dispatch }, { templateId }) => {
    return dispatch(
      "data/remove",
      {
        path: `${API_PATH}/${templateId}`,
        storeModule: DataModules.ORDERS_TEMPLATES
      },
      { root: true }
    );
  },
  deleteOrderTemplate: async ({ dispatch }, { templateId, onSuccess }) => {
    const confirm = await dispatch(
      "ui/open/confirmModal",
      {
        config: {
          props: {
            autoClose: false,
            confirmButtonText: i18n.t("_action.confirm"),
            mode: ConfirmModalModes.BUTTON,
            message: i18n.t("_sentence.delete_order_template")
          },
          canCancel: [],
          events: {
            confirmed: async () => {
              try {
                await dispatch("remove", { templateId });

                dispatch(
                  "toast/show",
                  { message: i18n.t("_.order_template_successfully_removed") },
                  { root: true }
                );

                onSuccess();
                confirm.close();
              } catch (error) {
                dispatch("api/handleError", error, { root: true });
              }
            }
          }
        }
      },
      { root: true }
    );
  },
  openManageOrderTemplateModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/admin/settings/orderTemplates/manageOrderTemplateModal.vue"
            ),
          ...payload
        }
      },
      { root: true }
    );
  },
  openSelectOrderModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/admin/settings/orderTemplates/selectOrderModal.vue"
            ),
          ...payload
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
