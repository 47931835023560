import { serialiseConfig } from "@/helpers/table";
import { FilterOperators } from "@/data/table";
import i18n from "@/i18n";
import type { IMenuRoute } from "@/models/routes";

export const leadOverview = [
  {
    label: "_.overview",
    to: (vm: any) => {
      return {
        name: "adminLeadOverview",
        params: vm.$route.params
      };
    }
  },
  {
    label: "_.tickets",
    to: (vm: any) => {
      return {
        name: "adminLeadSupportTickets",
        params: vm.$route.params
      };
    }
  },
  {
    label: "_.email_history",
    to: (vm: any) => {
      return {
        name: "adminLeadEmailHistory",
        params: vm.$route.params
      };
    }
  }
];

export const leadsQuickFilters = [
  {
    label: "_.all_leads",
    to: {
      name: "adminLeadsList"
    }
  },
  {
    label: "_.leads_from_last_30_days",
    to: {
      name: "adminLeadsList",
      query: {
        LT1: serialiseConfig({
          constFilters: [
            {
              key: "created_at",
              operator: FilterOperators.AFTER_RELATIVE,
              value: "-1_months"
            }
          ]
        })
      }
    }
  },
  {
    label: "_.hidden_leads",
    to: {
      name: "adminLeadsList",
      query: {
        LT1: "1;10;-created_at;$is_hidden|=|1"
      }
    }
  }
];

export function ticketsTabigation(): (IMenuRoute & { exact?: boolean })[] {
  return [
    {
      label: i18n.t("_.active_tickets"),
      to: { name: "admin__leadActiveSupportTickets" }
    },
    {
      label: i18n.t("_.closed_tickets"),
      to: {
        name: "admin__leadClosedSupportTickets"
      }
    }
  ];
}
