import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import { Methods } from "@/models/methods";
import type { ApiPathGetter } from "@/models/api";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext }): ApiPathGetter =>
    ({ accountId }) => {
      const admin = `api/admin/accounts/${accountId}/affiliate/referrals`;
      const client = `api/accounts/${accountId}/affiliate/referrals`;
      const contextual = isAdminContext ? admin : client;
      return { client, admin, contextual };
    },
  scope: () => accountId => {
    return `$affiliate_${accountId}_referrals`;
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath(payload).contextual,
        storeModule: DataModules.AFFILIATES_REFERRALS
      },
      { root: true }
    );
  },
  linkReferrer: ({ dispatch }, { clientId, affiliateAccountId }) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.POST,
        path: `api/admin/clients/${clientId}/create_affiliate_referral`,
        storeModule: DataModules.AFFILIATES_COMMISSIONS,
        requestConfig: {
          data: {
            affiliate_account_id: affiliateAccountId
          }
        }
      },
      { root: true }
    );
  },
  detachReferral: ({ dispatch }, { clientId, affiliateAccountId }) => {
    return dispatch(
      "data/remove",
      {
        path: `api/admin/affiliate_accounts/${affiliateAccountId}/detach_referral`,
        storeModule: DataModules.AFFILIATES_REFERRALS,
        data: {
          client_id: clientId
        }
      },
      { root: true }
    );
  },
  openCalculatePrevCommissionsModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/global/affiliate/calculatePrevCommissionsModal.vue"
            ),
          ...payload
        }
      },
      { root: true }
    );
  },
  calculatePrevCommissions: ({ dispatch }, { clientId, invoiceIds }) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.POST,
        path: `api/admin/clients/${clientId}/referral/calculate_previous_commissions`,
        storeModule: DataModules.AFFILIATES_COMMISSIONS,
        requestConfig: {
          data: {
            invoice_ids: invoiceIds,
            real_time: true
          }
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
