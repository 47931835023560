import type { ISorter } from "@/models/tables";
import i18n from "@/i18n";

export const ActiveSorter: () => ISorter = () => ({
  field: "active",
  label: i18n.t("_.status") as string
});

export const NameSorter: () => ISorter = () => ({
  field: "name",
  label: i18n.t("_.name") as string
});

export const CodeSorter: () => ISorter = () => ({
  field: "code",
  label: i18n.t("_.code") as string
});

export const AutoApplySorter: () => ISorter = () => ({
  field: "autoapply",
  label: i18n.t("_.autoapply") as string
});

export const FromDateSorter: () => ISorter = () => ({
  field: "from_date",
  label: i18n.t("_.starts") as string
});

export const ToDateSorter: () => ISorter = () => ({
  field: "to_date",
  label: i18n.t("_.ends") as string
});

export const DateCreatedSorter: () => ISorter = () => ({
  field: "created_at",
  label: i18n.t("_.date_created") as string
});

export const DefaultPromotionsSorters: () => ISorter[] = () => [
  NameSorter(),
  CodeSorter(),
  AutoApplySorter(),
  FromDateSorter(),
  ToDateSorter(),
  DateCreatedSorter()
];
