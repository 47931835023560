export function registerComponents(
  Vue,
  components, // glob
  manualImports = []
) {
  Object.entries(components).forEach(([path, definition]) => {
    // Get name of component, based on filename
    // "./components/Fruits.vue" will become "Fruits"

    const componentName = path
      ?.split("/")
      ?.pop()
      ?.replace(/\.\w+$/, "");

    if (manualImports.includes(componentName)) return;

    // Register component on this Vue instance
    Vue.component(componentName, definition?.default || definition);
  });
}
