import store from "@/store";
import { InvoiceStatusGroups } from "@/data/enums/invoice";
import type { Location, Route } from "vue-router";
import { AdminRoutes, RegexMatch } from "@/data/enums/router";
import {
  InvoiceStatusParam,
  DefaultInvoicesFilters,
  InvoiceStatusFilter
} from "@/data/filters/invoice";
import InvoicesListing from "@/components/app/global/invoices/invoicesListing.vue";
import {
  DateCancelledSorter,
  DefaultInvoicesSorters
} from "@/data/sorters/invoices";
import _ from "@/boot/lodash";

export default [
  {
    path: "billing",
    name: "adminClientBilling",
    redirect: { name: "adminClientBillingOverview" },
    component: {
      name: "AdminClientBilling",
      template: `<router-view v-bind="$attrs" />`,
      inheritattrs: false
    },
    meta: {
      title: "_.billing",
      breadcrumb: {
        label: "_action.edit"
      }
    },
    children: [
      {
        path: "overview",
        name: "adminClientBillingOverview",
        component: () =>
          import("@/views/admin/clients/client/billing/overview/index.vue")
      },
      {
        path: "payment-methods",
        name: "adminClientPaymentMethods",
        component: () =>
          import(
            "@/views/admin/clients/client/billing/paymentMethods/index.vue"
          )
      },
      {
        path: "account-credit",
        name: "adminClientAccountCredit",
        component: () =>
          import("@/views/admin/clients/client/billing/accountCredit/index.vue")
      },
      {
        path: "invoices",
        component: {
          name: "AdminClientInvoices",
          template: `<router-view v-bind="$attrs" />`,
          inheritattrs: false
        },
        children: [
          {
            path: "",
            redirect: { name: "adminClientInvoicesAll" },
            name: "adminClientInvoices",
            component: () =>
              import("@/views/admin/clients/client/billing/invoices/index.vue"),
            props: {
              routes: require("./menu").clientInvoicesTabigation
            },
            children: [
              {
                path: "all",
                name: "adminClientInvoicesAll",
                component: InvoicesListing,
                props: (to: Route) => ({
                  clientId: to.params.clientId,
                  availableFilters: () => [
                    ...DefaultInvoicesFilters(),
                    InvoiceStatusFilter()
                  ]
                }),
                meta: {
                  title: "_.invoices",
                  backTo: "_.invoices"
                }
              },
              {
                path: "paid",
                name: "adminClientInvoicesPaid",
                component: InvoicesListing,
                props: (to: Route) => ({
                  clientId: to.params.clientId,
                  manualFilters: () =>
                    InvoiceStatusParam(...InvoiceStatusGroups.PAID)
                }),
                meta: {
                  title: "_.paid_invoices",
                  backTo: "_.paid_invoices"
                }
              },
              {
                path: "unpaid",
                name: AdminRoutes.CLIENT_BILLING_UNPAID_INVOICES,
                component: InvoicesListing,
                props: (to: Route) => ({
                  clientId: to.params.clientId,
                  manualFilters: () =>
                    InvoiceStatusParam(...InvoiceStatusGroups.UNPAID)
                }),
                meta: {
                  title: "_.unpaid_invoices",
                  backTo: "_.unpaid_invoices"
                }
              },
              {
                path: "credited",
                name: "adminClientInvoicesCredited",
                component: InvoicesListing,
                props: (to: Route) => ({
                  clientId: to.params.clientId,
                  availableSorters: () =>
                    _.orderBy(
                      [...DefaultInvoicesSorters(), DateCancelledSorter()],
                      ["label"],
                      ["asc"]
                    ),
                  manualFilters: () =>
                    InvoiceStatusParam(...InvoiceStatusGroups.CREDITED)
                }),
                meta: {
                  title: "_.credited_invoices",
                  backTo: "_.credited_invoices"
                }
              }
            ]
          },
          {
            path: `:invoiceId(${RegexMatch.UUID})`,
            component: {
              name: "AdminClientInvoiceWrapper",
              template: `<router-view :key="$route.params.invoiceId" />`
            },
            children: [
              {
                path: "view",
                name: AdminRoutes.INVOICE,
                component: () =>
                  import(
                    "@/views/admin/clients/client/billing/invoices/invoice/index.vue"
                  ),
                meta: {
                  title: "_.invoice_#"
                },
                children: [
                  {
                    path: RegexMatch.NEW,
                    name: "openAdminInvoice",
                    props: {
                      redirectToRouteName: AdminRoutes.INVOICE
                    },
                    component: () =>
                      import(
                        "@/components/app/global/invoices/openInvoiceModal.vue"
                      )
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: "legacy-invoices",
        name: "adminClientLegacyInvoices",
        component: () =>
          import(
            "@/views/admin/clients/client/billing/legacyInvoices/index.vue"
          ),
        meta: {
          title: "_.legacy_invoices"
        },
        children: [
          {
            path: `:invoiceId(${RegexMatch.UUID})`,
            name: "adminClientLegacyInvoice",
            beforeEnter: async (
              to: Route,
              from: Route,
              next: (options: boolean | Location | Location["path"]) => void
            ) => {
              const { invoiceId } = to.params;
              if (invoiceId) {
                store.dispatch("ui/open/slideModal", {
                  config: {
                    component: () =>
                      import(
                        "@/components/app/global/legacyInvoices/legacyInvoiceModal.vue"
                      ),
                    canCancel: ["button"],
                    props: to.params
                  }
                });
              }
              store.dispatch("ui/endRouting");
              return next(!from.matched.length); // Important to maintain pagination/filters etc on parent list view
            }
          }
        ]
      },
      {
        path: "credit-notes",
        component: {
          name: "AdminClientCreditNotes",
          template: `<router-view v-bind="$attrs" />`,
          inheritattrs: false
        },
        children: [
          {
            path: "",
            name: "adminClientCreditNotes",
            component: () =>
              import(
                "@/views/admin/clients/client/billing/creditNotes/index.vue"
              ),
            meta: {
              title: "_.credit_notes"
            }
          },
          {
            path: `:invoiceId(${RegexMatch.UUID})`,
            name: "adminCreditNote",
            component: () =>
              import(
                "@/views/admin/clients/client/billing/creditNotes/creditNote/index.vue"
              ),
            meta: {
              title: "_.invoice_#"
            },
            props: true
          }
        ]
      },
      {
        path: "transactions",
        name: "adminClientTransactions",
        redirect: { name: "adminClientSuccessfulPayments" },
        component: () =>
          import("@/views/admin/clients/client/billing/transactions/index.vue"),
        props: {
          routes: require("./menu").transactionsTabigation
        },
        meta: {
          title: "_.transactions"
        },
        children: [
          {
            path: "successful-payments",
            name: "adminClientSuccessfulPayments",
            component: () =>
              import(
                "@/views/admin/clients/client/billing/transactions/successfulPayments/index.vue"
              ),
            meta: {
              title: "_.successful_payments"
            }
          },
          {
            path: "failed-payments",
            name: "adminClientFailedPayments",
            component: () =>
              import(
                "@/views/admin/clients/client/billing/transactions/failedPayments/index.vue"
              ),
            meta: {
              title: "_.failed_payments"
            }
          },
          {
            path: "refunds",
            name: "adminClientRefunds",
            component: () =>
              import(
                "@/views/admin/clients/client/billing/transactions/refunds/index.vue"
              ),
            meta: {
              title: "_.refunds"
            }
          },
          {
            path: "all",
            name: "adminClientAllTransactions",
            component: () =>
              import(
                "@/views/admin/clients/client/billing/transactions/all/index.vue"
              ),
            meta: {
              title: "_.all"
            }
          }
        ]
      }
    ]
  }
];
