<script lang="ts">
import { defineComponent } from "vue";
import Vue from "vue";
import SlideModal from "./SlideModal.vue";
import objectHash from "object-hash";
import type { PropType } from "vue";

export default defineComponent({
  name: "SlideModalUnique",
  components: {
    SlideModal
  },
  props: {
    modalProps: { type: Object as PropType<any>, required: true }
  },
  data: () => ({
    modal: {} as typeof SlideModal
  }),
  async created() {
    if (this.modalProps.allowSameHash !== true) {
      let name = "";
      if (this.$_.isFunction(this.modalProps.component)) {
        const component = await this.modalProps.component();
        name = component.default.name;
      } else {
        name = this.modalProps.component.name;
      }
      // TODO
      // eslint-disable-next-line
      this.modalProps.modalHash = objectHash.sha1({
        name: name,
        props: this.modalProps.props
      });
      const currentlyActive = this.$_.last(this.$store.state.ui.sliders);
      if (this.$_.get(currentlyActive, "hash") === this.modalProps.modalHash)
        return this.$destroy();
    }

    return this.openSlideModal();
  },
  methods: {
    openSlideModal() {
      const SlideModalClass = Vue.extend(SlideModal);
      this.modal = new SlideModalClass({
        parent: this,
        propsData: this.modalProps,
        el: document.createElement("div")
      });

      this.modal.$on("close", () => {
        this.$destroy();
      });

      return this.modal;
    },
    close() {
      if (this.$_.isEmpty(this.modal)) {
        this.$destroy();
      } else {
        // This will trigger this.modal.$on("close") above and destroy SlideModalUnique component too
        this.modal.close();
      }
    }
  }
});
</script>
