import _ from "@/boot/lodash";
import type { HttpMethods } from "@/data/enums/forms";
import { Targets } from "@/data/enums/forms";
import store from "@/store";

export function getNewValues(form, formClone) {
  return _.omitBy(form, (v, k) => {
    return formClone[k] === v;
  });
}

/**
 * @name submitProgrammaticForm
 * @desc This exported function lets you programmatically create, insert and
 * submit a new form element so we can reliably hand-off to third party origins
 * without encountering any cross-origin (CORS) issues. */

export function submitProgrammaticForm({
  fields,
  method,
  target = Targets.SELF,
  url
}: {
  fields: Record<string, any>;
  method: HttpMethods;
  target?: Targets;
  url: string;
}) {
  try {
    const form = document.createElement("form");
    form.target = target;
    form.method = method;
    form.action = url;
    form.style.display = "none";

    for (const key in fields || {}) {
      const input = document.createElement("input");
      input.type = "hidden";
      input.name = key;
      input.value = fields[key];
      form.appendChild(input);
    }

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  } catch (error) {
    store.dispatch("api/handleError", error);
  }
}
