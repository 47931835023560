import { GuestRoutes } from "@/data/enums/router";
import store from "@/store";

export default [
  {
    path: "/invoice",
    component: {
      name: "ShareView",
      template: `<router-view />`
    },
    beforeEnter: async (to: any, from: any, next: any) => {
      store.commit("user", {});
      next();
    },
    meta: {
      allowGuestContext: true
    },
    children: [
      {
        path: ":token([0-9a-fA-F]{8}[0-9a-fA-F]{4}[0-9a-fA-F]{4}[0-9a-fA-F]{4}[0-9a-fA-F]{12})",
        components: {
          default: {
            template: `<router-view />`
          }
        },
        children: [
          {
            path: "/",
            name: GuestRoutes.SHARED_INVOICE,
            component: () => import("@/views/share/index.vue"),
            props: route => {
              return { token: route.params.token };
            },
            meta: {
              title: "_.invoice"
            }
          }
        ]
      }
    ]
  }
];
