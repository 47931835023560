import type { IMenuRoute } from "@/models/routes";
import i18n from "@/i18n";

import { ClientRoutes } from "@/data/enums/router";

export function ticketsTabigation(): (IMenuRoute & { exact?: boolean })[] {
  return [
    {
      label: i18n.t("_.active_tickets"),
      to: { name: "client__activeSupportTickets" }
    },
    {
      label: i18n.t("_.closed_tickets"),
      to: {
        name: "client__closedSupportTickets"
      }
    }
  ];
}

export const supportMenu = [
  {
    children: [
      {
        label: "_.my_tickets",
        to: {
          name: ClientRoutes.SUPPORT_TICKETS
        },
        children: [
          {
            label: "_.add",
            to: {
              name: ClientRoutes.SUPPORT_TICKETS_ADD
            }
          }
        ]
      }
    ]
  }
];
