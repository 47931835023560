<template>
  <ValidationObserver ref="observer" slim>
    <modal-card
      :is-processing="isProcessing"
      :show-footer="false"
      :block-cancellation="true"
    >
      <div class="has-padding-100 has-text-centered">
        <p class="subtitle has-text-weight-semibold has-margin-bottom-50">
          <u-i18n path="_.completing_payment" />
        </p>
        <u-i18n
          tag="p"
          path="_sentence.completing_invoice_payment"
          class="has-text-grey"
        >
          <strong slot="invoice">{{ invoice.number }}</strong>
        </u-i18n>
        <b-progress class="has-margin-top-100" />
      </div>
    </modal-card>
  </ValidationObserver>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import InvoicePaymentModal from "@/components/app/global/payments/invoicePaymentModal.vue";
import { PaymentMethodType } from "@/models/selectPaymentMethod";
import type { StoredCardData } from "@/models/selectPaymentMethod";

export default defineComponent({
  name: "InvoiceAutoPayModal",
  mixins: [InvoicePaymentModal],
  props: {
    amount: { type: Number, default: undefined },
    walletAmount: { type: Number, default: undefined }
  },
  data: () => ({
    processing: {},
    isLoadingInvoice: true
  }),
  async created() {
    try {
      this.$set(this.processing, "payment", true);
      await Promise.all([this.waitMinimum(), this.pay()]);
    } finally {
      this.$delete(this.processing, "payment");
    }
  },
  methods: {
    waitMinimum() {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve(true);
        }, 2 * 1000);
      });
    },
    async pay() {
      if (!this.preselectedStoredPaymentMethodId) {
        this.$delete(this.processing, "payment");
        this.$emit("close");
        return;
      }
      try {
        this.paymentMethod = {
          amount: this.amount || 0,
          walletAmount: this.walletAmount || 0,
          type: PaymentMethodType.STORED_CARD,
          data: {
            payment_details_id: this.preselectedStoredPaymentMethodId
          } as StoredCardData
        };
        await this.preparePaymentData(this.invoice.client_id);
        // Execute payment
        await this.executePayment();
        // Emit 'success'
        this.$emit("success", { invoiceId: this.invoice.id });
      } catch (error) {
        this.$emit("error", error);
        this.$store.dispatch("api/handleValidationError", {
          error,
          vm: this
        });
      } finally {
        this.$emit("close");
      }
    }
  }
});
</script>
