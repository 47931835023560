import { OrgFeatureKeys } from "@/data/constants";
import { RegexMatch } from "@/data/enums/router";
import type { Route } from "vue-router";

export default [
  {
    path: "clients/bulk-actions",
    component: {
      name: "AdminClientBulkActions",
      template: `<router-view />`
    },
    meta: {
      requiredFeatureKeys: [OrgFeatureKeys.BULK_NOTIFICATIONS_ENABLED]
    },
    children: [
      {
        path: "",
        name: "adminClientBulkNotifications",
        component: () => import("@/views/admin/clients/bulkActions/index.vue"),
        meta: {
          auth: ["list_notification_channels"]
        }
      },
      {
        path: `:bulkActionId(${RegexMatch.UUID})`,
        name: "adminClientBulkActionsEdit",
        redirect: { name: "adminClientsBulkActionOverview" },
        component: () =>
          import("@/views/admin/clients/bulkActions/bulkAction/index.vue"),
        props: (to: Route) => {
          return {
            bulkActionId: to.params.bulkActionId,
            routes: require("./menu").bulkActionTabigation
          };
        },
        children: [
          {
            path: "overview",
            name: "adminClientsBulkActionOverview",
            component: () =>
              import(
                "@/views/admin/clients/bulkActions/bulkAction/overview/index.vue"
              )
          },
          {
            path: "logs",
            name: "adminClientsBulkActionLogs",
            component: () =>
              import(
                "@/views/admin/clients/bulkActions/bulkAction/logs/index.vue"
              )
          },
          {
            path: "activity-log",
            name: "adminClientsBulkActionActivityLog",
            component: () =>
              import(
                "@/views/admin/clients/bulkActions/bulkAction/activityLog/index.vue"
              )
          }
        ]
      }
    ]
  }
];
