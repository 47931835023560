import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import {
  FraudDetectionTypes,
  FraudDetectionEntities,
  ConfirmModalModes
} from "@/data/enums";
import type { IFraudEvent, IFraudSetting } from "@/models/fraud";
import { Methods } from "@/models/methods";
import _ from "@/boot/lodash";
import type { ApiPathGetter } from "@/models/api";
import i18n from "@/i18n";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath: (): ApiPathGetter => () => {
    const admin = "api/admin/fraud_check/settings";
    return { admin };
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload.entity}/${payload.type}`,
        storeModule: DataModules.FRAUD_DETECTION
      },
      { root: true }
    );
  },
  create: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/create",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload.data.entity}/${
          payload.data.type
        }/single`,
        storeModule: DataModules.FRAUD_DETECTION
      },
      { root: true }
    );
  },
  update: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/update",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload.data.entity}/${
          payload.data.type
        }/${payload.id}`,
        storeModule: DataModules.FRAUD_DETECTION
      },
      { root: true }
    );
  },
  confirmDelete: async ({ dispatch }, { id, entity, type, onSuccess }) => {
    const confirm = await dispatch(
      "ui/open/confirmModal",
      {
        config: {
          props: {
            autoClose: false,
            confirmButtonText: i18n.t("_action.delete"),
            message:
              entity === FraudDetectionEntities.BRAND
                ? i18n.t("_sentence.delete_fraud_rule")
                : i18n.t("_sentence.delete_org_level_rule"),
            mode:
              entity === FraudDetectionEntities.BRAND
                ? ConfirmModalModes.BUTTON
                : ConfirmModalModes.PIN
          },
          canCancel: [],
          events: {
            confirmed: async () => {
              try {
                await dispatch("delete", { id, entity, type });

                dispatch(
                  "toast/show",
                  {
                    message: i18n.t("_sentence.fraud_rule_successfully_deleted")
                  },
                  { root: true }
                );
                onSuccess();
              } catch (error) {
                dispatch("api/handleError", error, { root: true });
              } finally {
                confirm.close();
              }
            }
          }
        }
      },
      { root: true }
    );
  },
  delete: async ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/remove",
      {
        ...payload,
        keys: [payload.id],
        path: `${getters.apiPath().admin}/${payload.entity}/${payload.type}/${
          payload.id
        }`,
        storeModule: DataModules.FRAUD_DETECTION
      },
      { root: true }
    );
  },
  getFraudRule: async ({ dispatch, rootState }, { ip }) => {
    return _.head(
      await dispatch(`list`, {
        storeData: false,
        type: ip ? FraudDetectionTypes.IP : FraudDetectionTypes.COUNTRY,
        entity: rootState.auth.admin.brandId
          ? FraudDetectionEntities.BRAND
          : FraudDetectionEntities.ORGANISATION,
        params: {
          ...(ip ? { "filter[value]": ip } : {}),
          limit: 1
        }
      })
    ) as IFraudSetting;
  },
  addFraudDetectionRule: async ({ dispatch }, rule: IFraudSetting) => {
    return dispatch(`openAddEditModal`, {
      props: {
        rule
      }
    });
  },
  addIPRule: async ({ dispatch }, event: IFraudEvent) => {
    const rule = await dispatch("getFraudRule", {
      ip: _.get(event, "invoice.ip")
    });
    return dispatch(
      "addFraudDetectionRule",
      rule ||
        ({
          type: FraudDetectionTypes.IP,
          value: _.get(event, "invoice.ip")
        } as IFraudSetting)
    );
  },
  getInvoiceFraudCheckData: async ({ dispatch, rootGetters }, payload) => {
    if (!rootGetters["user/can"]("get_invoice_fraud_check_data")) return;
    return dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.GET,
        path: `api/admin/invoices/${payload.invoiceId}/fraud_check_data`,
        storeModule: DataModules.FRAUD_DETECTION,
        requestConfig: { data: payload.data }
      },
      { root: true }
    );
  },
  openAddEditModal: ({ dispatch }, payload) => {
    dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/admin/settings/fraud/addEditFraudDetectionRuleModal.vue"
            ),
          parent: window.$rootVue,
          width: 540,
          hasModalCard: true,
          ...payload
        }
      },
      { root: true }
    );
  },
  openAnalysisModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import("@/components/app/global/fraud/fraudAnalysisModal.vue"),
          parent: payload.parent || window.$rootVue,
          width: 540,
          hasModalCard: true,
          ...payload
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  getters,
  modules: {
    events: require("./events").default,
    policies: require("./policies").default
  }
};
