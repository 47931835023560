import type { ApiPathGetter } from "@/models/api";
import type { IState } from "@/store";
import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext, isMockClientContext }): ApiPathGetter =>
    hasToken => {
      const admin = `api/admin/notifications/opt-outs`;
      const client = `api/notifications/opt-outs`;
      const contextual =
        isAdminContext && !isMockClientContext() && !hasToken ? admin : client;
      return { client, admin, contextual };
    },
  scope: () => {
    return "$notification_opt_outs";
  }
};

const actions: ActionTree<IDataState, IState> = {
  get: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath(!!payload.params?.token).contextual}${
          payload.clientId
            ? `/client/${payload.clientId}`
            : payload.userId
            ? `/user/${payload.userId}`
            : ``
        }`,
        handle401Error: !payload.params?.token,
        storeModule: DataModules.NOTIFICATIONS_OPT_OUTS
      },
      { root: true }
    );
  },
  update: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/update",
      {
        path: `${getters.apiPath(!!payload.data.token).contextual}${
          payload.clientId
            ? `/client/${payload.clientId}`
            : payload.userId
            ? `/user/${payload.userId}`
            : ``
        }`,
        storeModule: DataModules.NOTIFICATIONS_OPT_OUTS,
        data: payload.data
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
