<script lang="ts">
import { defineComponent } from "vue";
import FilteringSortingTableStore from "@/mixins/tableWithFilteringAndSortingStore";
import CurrenciesStore from "@/mixins/constants/currenciesStore";
import { DataModules } from "@/store/modules/data/modules";
import { Sorters } from "@/data/table";
import type { PropType, CreateElement } from "vue";
import type { DebouncedFunc } from "lodash";
import type {
  IFilter,
  IFilteringSortingTable,
  ISorting,
  ITableConfig
} from "@/models/tables";
import type { IPromotion } from "@/models/promotions";
import _ from "@/boot/lodash";

interface IPromotionsProviderData {
  activeFilters: IFilter[];
  activeSort: ISorting;
  enableFiltering: boolean;
  enableSorting: boolean;
  canShowMore: boolean;
  defaults: ITableConfig;
  debounceSearch: DebouncedFunc<IPromotionsProviderMethods["doQuickSearch"]>;
  hasPromotions: boolean;
  isLoading: boolean;
  limit: number;
  page: number;
  promotions: IPromotion[];
  searchQuery: string;
  table: IFilteringSortingTable;
  total: number | null;
}

interface IPromotionsProviderMethods {
  doQuickSearch: (value?: string) => Promise<void>;
  onFiltersChange: (filters: IFilter[]) => void;
  onLimitChange: (limit: number) => void;
  onPageChange: (page: number) => void;
  onSort: (field: string, direction: Sorters) => void;
  reloadData: () => Promise<void>;
  deletePromotion: (promotionId: IPromotion["id"]) => Promise<void>;
  restorePromotion: (promotionId: IPromotion["id"]) => Promise<void>;
}

export default defineComponent({
  name: "PromotionsProvider",
  mixins: [FilteringSortingTableStore, CurrenciesStore],
  provide() {
    return {
      promotionsProviderData: this.promotionsProviderData,
      promotionsProviderMethods: this.promotionsProviderMethods
    };
  },
  props: {
    id: { type: String, default: "PL" },
    limit: { type: Number, default: 10 },
    handleLoading: { type: Boolean, default: true },
    handleRequestError: { type: Boolean, default: true },
    manualFilters: {
      type: Object as PropType<Record<string, string | number | boolean>>,
      default: () => ({})
    }
  },
  data: () => ({
    dataModule: DataModules.CATALOGUE_PROMOTIONS,
    debounceSearch: null as any,
    defaults: {} as ITableConfig,
    isProcessing: false,
    loading: true,
    isReloading: false,
    searchQuery: ""
  }),
  computed: {
    queryFilter() {
      return this.searchQuery
        ? { "filter[name|like]": `%${this.searchQuery}%` }
        : {};
    },
    hasPromotions(): boolean {
      return !!this.table.data.length;
    },
    canShowMore(): boolean {
      return _.isNull(this.table.total)
        ? false
        : this.table.total > this.table.data.length;
    },
    promotionsProviderMethods(): IPromotionsProviderMethods {
      return {
        doQuickSearch: this.doQuickSearch,
        onFiltersChange: this.onFiltersChange,
        onLimitChange: this.onLimitChange,
        onPageChange: this.onPageChange,
        onSort: this.onSort,
        reloadData: this.reloadData,
        deletePromotion: this.deletePromotion,
        restorePromotion: this.restorePromotion
      };
    }
  },
  async created() {
    this.debounceSearch = _.debounce(this.doQuickSearch, 250);
    this.defaults = {
      ...this.defaults,
      page: 1,
      limit: this.limit,
      sort: {
        field: "created_at",
        direction: Sorters.DESCENDING
      },
      filters: []
    };

    this.loadData();
  },
  methods: {
    async loadData() {
      try {
        await Promise.all([
          this.getCurrencies(),
          this.getData({ ignoreStored: true })
        ]);
        this.requestError = false;
      } catch (error) {
        this.requestError = true;
        this.$store.dispatch("api/handleError", error);
      } finally {
        this.loading = false;
        this.isReloading = false;
      }
    },
    getStoreParams(params: any): Promise<any> {
      return _.merge(
        {
          params: {
            with: "products",
            ...this.queryFilter,
            ...this.manualFilters
          }
        },
        params // Important – must merge last
      );
    },
    async reloadData() {
      await this.cancelRequests();
      this.loadData();
    },
    doQuickSearch(value = "") {
      if (value === this.searchQuery) return Promise.resolve();
      this.searchQuery = value;
      return this.reloadData();
    },
    async deletePromotion(promotionId: IPromotion["id"]) {
      const confirm = await this.$store.dispatch("ui/open/confirmModal", {
        config: {
          props: {
            autoClose: false,
            confirmButtonText: this.$t("_action.delete"),
            message: this.$t("_sentence.delete_confirmation", {
              object: this.$t("_.promotion").toString().toLowerCase()
            })
          },
          events: {
            confirmed: async () => {
              try {
                await this.removePromotion(promotionId);
                this.reloadData();
              } catch (error) {
                this.$store.dispatch("api/handleError", error);
              } finally {
                confirm.close();
              }
            }
          }
        }
      });
    },
    removePromotion(promotionId: IPromotion["id"]) {
      return this.$store.dispatch(
        `data/${DataModules.CATALOGUE_PROMOTIONS}/remove`,
        { id: promotionId }
      );
    },
    async restorePromotion(promotionId: IPromotion["id"]) {
      try {
        await this.$store.dispatch(
          `data/${DataModules.CATALOGUE_PROMOTIONS}/restore`,
          { id: promotionId }
        );
        this.reloadData();
      } catch (error) {
        this.$store.dispatch("api/handleError", error);
      }
    },
    promotionsProviderData(): IPromotionsProviderData {
      return {
        activeFilters: this.activeFilters,
        activeSort: this.table.sort,
        canShowMore: this.canShowMore,
        defaults: this.defaults,
        debounceSearch: this.debounceSearch,
        enableFiltering: this.enableFiltering,
        enableSorting: this.enableSorting,
        hasPromotions: this.hasPromotions,
        isLoading: this.table.loading,
        limit: this.table.limit,
        page: this.table.page,
        promotions: this.table.data,
        searchQuery: this.searchQuery,
        table: this.table,
        total: this.table.total
      };
    }
  },
  render(h: CreateElement) {
    // Handle loading
    if (this.handleLoading && this.loading)
      return h("is-loading", { props: { if: true } });

    // Handle request error
    if (this.handleRequestError && this.requestError)
      return h("request-error", {
        props: { isLoading: this.table.loading && this.requestError },
        on: { click: this.reloadData }
      });

    return (
      this.$scopedSlots?.default &&
      this.$scopedSlots.default({
        $promotionsData: this.promotionsProviderData(),
        $promotionsMethods: this.promotionsProviderMethods
      })
    );
  }
});
</script>
