import i18n from "@/i18n";
import type { IMenuRoute } from "@/models/routes";

export function affiliatesTabigation(): IMenuRoute[] {
  return [
    {
      label: i18n.t("_.overview"),
      to: { name: "adminAffiliatesOverview" }
    },
    {
      label: i18n.t("_.affiliates_list"),
      to: { name: "adminAffiliatesList" }
    }
  ];
}
