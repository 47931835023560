import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import _ from "@/boot/lodash";
import type { ApiPathGetter } from "@/models/api";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext }): ApiPathGetter =>
    () => {
      const admin = "api/admin/config";
      const client = "api/config";
      const contextual = isAdminContext ? admin : client;
      return { client, admin, contextual };
    },
  scope: () => entity => {
    return `$${entity}`;
  },
  categoryScope: () => (entity, code) => {
    return `$${entity}_${code}`;
  },
  categoryGroupsScope: () => (entity, code) => {
    return `$${entity}_${code}_groups`;
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `${getters.apiPath().contextual}/${payload?.entity}/categories`,
        storeModule: DataModules.SETTINGS_CATEGORIES,
        params: {
          limit: 0,
          order: "order"
        }
      },
      { root: true }
    );
  },
  getCategory: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath().contextual}/${
          payload?.entity
        }/categories/${_.get(payload, "code")}`,
        storeModule: DataModules.SETTINGS_CATEGORIES
      },
      { root: true }
    );
  },
  getCategoryGroups: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `${getters.apiPath().contextual}/${
          payload?.entity
        }/categories/${_.get(payload, "code")}/groups`,
        storeModule: DataModules.SETTINGS_CATEGORIES,
        params: {
          with: "fields.value"
        }
      },
      { root: true }
    );
  },
  updateCategoryFields: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/update",
      {
        data: payload.data,
        path: `${getters.apiPath().contextual}/${payload?.entity}/${_.get(
          payload,
          "code"
        )}`,
        storeModule: DataModules.SETTINGS_CATEGORIES
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
