import type { IFilter } from "@/models/tables";
import { FilterTypes, FilterOperators } from "@/data/table";
import i18n from "@/i18n";

export const IsActiveParam = () => ({
  "filter[active]": 1
});

export const IsInactiveParam = () => ({
  "filter[active]": 0
});

export const IsDeletedPromotionParam = () => ({
  "filter[hidden]": 1,
  with_hidden: 1
});

export const ActiveFilter: () => IFilter = () => ({
  key: "active",
  label: i18n.t("_.status"),
  options: [
    { value: "1", label: i18n.t("_.active").toString() },
    { value: "0", label: i18n.t("_.inactive").toString() }
  ],
  value: "1",
  type: FilterTypes.SELECT_RADIO,
  operator: FilterOperators.EQUAL
});

export const AutoApplyFilter: () => IFilter = () => ({
  key: "autoapply",
  label: i18n.t("_.autoapply"),
  options: [
    { value: true, label: i18n.t("_.yes").toString() },
    { value: false, label: i18n.t("_.no").toString() }
  ],
  value: true,
  type: FilterTypes.SELECT_RADIO,
  operator: FilterOperators.EQUAL
});

export const CodeFilter: () => IFilter = () => ({
  key: "code",
  label: i18n.t("_.code"),
  type: FilterTypes.STRING,
  operator: FilterOperators.CONTAINS
});

export const FromDateFilter: () => IFilter = () => ({
  key: "from_date",
  label: i18n.t("_.starts"),
  type: FilterTypes.DATE,
  operator: FilterOperators.EQUAL
});

export const ToDateFilter: () => IFilter = () => ({
  key: "to_date",
  label: i18n.t("_.ends"),
  type: FilterTypes.DATE,
  operator: FilterOperators.EQUAL
});

export const DateCreatedFilter: () => IFilter = () => ({
  key: "created_at",
  label: i18n.t("_.date_added"),
  type: FilterTypes.DATE,
  operator: FilterOperators.EQUAL
});

export const DefaultPromotionsFilters: () => IFilter[] = () => [
  CodeFilter(),
  AutoApplyFilter(),
  FromDateFilter(),
  ToDateFilter(),
  DateCreatedFilter()
];
