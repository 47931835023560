<script lang="ts">
import { defineComponent } from "vue";
import Vue from "vue";
import Context from "@/mixins/context";
import ExternalAuthMixin from "@/components/app/global/payments/externalAuthMixin.vue";
import { DataModules } from "@/store/modules/data/modules";
import { PaymentTarget } from "@/models/externalAuth";
import CProdProvider from "@/components/app/global/contractProducts/cProdProvider.vue";

export default defineComponent({
  name: "OpenInitPayModal",
  mixins: [ExternalAuthMixin, Context],
  computed: {
    clientId() {
      return this.initPayQueryParam?.clientId;
    },
    invoiceId() {
      return this.initPayQueryParam?.invoiceId;
    },
    isInvoicePayment() {
      return !!this.invoiceId;
    },
    isInvoiceChangePaymentModal() {
      return (
        !!this.invoiceId &&
        this.initPayQueryParam?.target ===
          PaymentTarget.INVOICE_CHANGE_PAYMENT_METHOD
      );
    },
    isWalletTopUp() {
      return !!(!this.invoiceId && this.clientId);
    },
    isChangePaymentMethodModal() {
      return !!(
        this.initPayQueryParam?.target ===
          PaymentTarget.CONTRACT_PRODUCT_CHANGE_PAYMENT_METHOD &&
        this.initPayQueryParam.clientId &&
        this.initPayQueryParam.contractId &&
        this.initPayQueryParam.productId
      );
    }
  },
  mounted() {
    this.checkExternalAuthQueryParams();
    if (
      !this.paymentDetailIdFromExternalAuth ||
      this.$_.isEmpty(this.initPayQueryParam) ||
      !this.isSuccess
    )
      return this.close();

    if (this.isInvoiceChangePaymentModal) this.openChangeInvoicePaymentModal();
    else if (this.isInvoicePayment) this.openPayInvoiceModal();
    else if (this.isChangePaymentMethodModal)
      this.openChangePaymentMethodModal();
    else if (this.isWalletTopUp) this.openTopUpModal();
    else this.close();
  },
  methods: {
    async openChangePaymentMethodModal() {
      const CProdProviderClass = Vue.extend(CProdProvider);
      const cpProvider = new CProdProviderClass({
        parent: window.$rootVue,
        propsData: {
          contractId: this.initPayQueryParam?.contractId,
          productId: this.initPayQueryParam?.productId
        }
      }) as CProdProvider;

      cpProvider.$on("loaded", async () => {
        const modal = await cpProvider.openChangePaymentMethodModal({
          props: {
            preselectedStoredPaymentMethodId:
              this.paymentDetailIdFromExternalAuth
          },
          events: {
            "payment-method-changed": async () => {
              await cpProvider.reloadData();
              cpProvider.$destroy();
              this.close();
            }
          }
        });
        modal.$on("close", () => {
          cpProvider.$destroy();
          this.close();
        });
      });
    },
    async openChangeInvoicePaymentModal() {
      const invoice = await this.$store.dispatch(
        `data/${DataModules.INVOICES}/getWithParams`,
        {
          id: this.invoiceId,
          storeData: false,
          isClient: this.isClient
        }
      );
      await this.$store.dispatch(
        `data/${DataModules.INVOICES}/openChangePaymentMethodModal`,
        {
          props: {
            invoice,
            preselectedStoredPaymentMethodId:
              this.paymentDetailIdFromExternalAuth
          },
          events: {
            "payment-method-changed": () => {
              this.$bus.$emit("init-payment-invoice-payment-method-changed", {
                invoiceId: this.invoiceId
              });
            }
          }
        }
      );
      this.close();
    },
    async openPayInvoiceModal() {
      const invoice = await this.$store.dispatch(
        `data/${DataModules.INVOICES}/getWithParams`,
        {
          id: this.invoiceId,
          storeData: false,
          isClient: this.isClient
        }
      );
      const modal = await this.$store.dispatch(
        `data/${DataModules.INVOICES}/openPayInvoiceModal`,
        {
          props: {
            invoice,
            preselectedStoredPaymentMethodId:
              this.paymentDetailIdFromExternalAuth,
            preselectedShowPendingPayment: this.showPendingPaymentQueryParam
          },
          events: {
            success: () => {
              modal.close();
              this.$bus.$emit("init-payment-invoice-paid", {
                invoiceId: invoice.id
              });
            }
          }
        }
      );
      this.close();
    },
    async openTopUpModal() {
      const client = await this.$store.dispatch(
        `data/${DataModules.CLIENTS}/getClientWithRelatedData`,
        {
          id: this.initPayQueryParam?.clientId
        }
      );

      this.$store.dispatch(`data/${DataModules.WALLETS}/openTopUpModal`, {
        props: {
          client,
          preselectedStoredPaymentMethodId: this.paymentDetailIdFromExternalAuth
        }
      });
      this.close();
    },
    close() {
      this.$emit("close");
    },
    render() {
      return this.$scopedSlots?.default && this.$scopedSlots.default({});
    }
  }
});
</script>
