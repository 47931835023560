import { DocCategories } from "@/data/enums/docs";

export default [
  {
    path: "affiliates",
    name: "adminAffiliates",
    redirect: { name: "adminAffiliatesOverview" },
    component: () => import("@/views/admin/affiliates/index.vue"),
    props: {
      routes: require("./menu").affiliatesTabigation
    },
    meta: {
      auth: ["list_affiliate_accounts"],
      backTo: "_.affiliates",
      title: "_.affiliates"
    },
    children: [
      {
        path: "overview",
        name: "adminAffiliatesOverview",
        component: () => import("@/views/admin/affiliates/overview/index.vue"),
        meta: {
          categorySlug: DocCategories.USING_UPMIND,
          docsSlugs: ["affiliates"]
        }
      },
      {
        path: "list",
        name: "adminAffiliatesList",
        component: () => import("@/views/admin/affiliates/list/index.vue"),
        meta: {
          categorySlug: DocCategories.USING_UPMIND,
          docsSlugs: ["affiliates"]
        }
      }
    ]
  }
];
