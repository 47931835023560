import { RegexMatch } from "@/data/enums/router";
import { SegmentTypes } from "@/data/enums/segments";
import type { Route } from "vue-router";

import CProdsListing from "@/components/app/global/contractProducts/cProdsListing.vue";
import SegmentResultsListing from "@/components/app/admin/segments/segmentResultsListing.vue";
import { ContractStatusCodes } from "@/models/contracts";

import {
  DefaultCProdFilters,
  statusParam,
  isFromLast30DaysParam,
  hasUnresolvedRequestsParam,
  IsCategoryParam,
  HasProvisionIssuesFilter
} from "@/data/filters/contractProducts";
import {
  DefaultCProdSorters,
  StatusSorter,
  DatePurchasedSorter,
  DateCancelledSorter,
  DateLapsedSorter
} from "@/data/sorters/contractProducts";

import { ListDisplayMode } from "@/data/constants";
import { UserMetaKeys } from "@/data/enums/users";

export default [
  {
    path: "list",
    name: "adminCProds",
    redirect: { name: "adminCProdsList" },
    component: () => import("@/views/admin/contractProducts/all/index.vue"),
    props: {
      usedFilters: () => DefaultCProdFilters()
    },
    meta: {
      backTo: "_.all"
    },
    children: [
      {
        path: "",
        name: "adminCProdsList",
        component: {
          name: "adminCProdsList",
          template: "<router-view />"
        },
        redirect: { name: "admin__allCProds" },
        children: [
          {
            path: "all",
            name: "admin__allCProds",
            component: CProdsListing,
            props: {
              availableSorters: () => [StatusSorter(), DatePurchasedSorter()],
              availableFilters: () => DefaultCProdFilters(),
              routeFilters: () => ({})
            }
          },
          {
            path: "active",
            name: "admin__activeCProds",
            component: CProdsListing,
            props: {
              availableSorters: () => [StatusSorter(), DatePurchasedSorter()],
              availableFilters: () => DefaultCProdFilters(["status.code"]),
              routeFilters: () => ({
                ...statusParam([ContractStatusCodes.ACTIVE])
              })
            }
          },
          {
            path: "awaiting-activation",
            name: "admin__awaitingActivationCProds",
            component: CProdsListing,
            props: {
              availableSorters: () => [StatusSorter(), DatePurchasedSorter()],
              availableFilters: () => DefaultCProdFilters(["status.code"]),
              routeFilters: () => ({
                ...statusParam([ContractStatusCodes.AWAITING_ACTIVATION])
              })
            }
          },
          {
            path: "awaiting-payment",
            name: "admin__awaitingPaymentCProds",
            component: CProdsListing,
            props: {
              availableSorters: () => [StatusSorter(), DatePurchasedSorter()],
              availableFilters: () => DefaultCProdFilters(["status.code"]),
              routeFilters: () => ({
                ...statusParam([ContractStatusCodes.PENDING])
              })
            }
          },
          {
            path: "suspended",
            name: "admin__suspendedCProds",
            component: CProdsListing,
            props: {
              availableSorters: () => [StatusSorter(), DatePurchasedSorter()],
              availableFilters: () => DefaultCProdFilters(["status.code"]),
              routeFilters: () => ({
                ...statusParam([ContractStatusCodes.SUSPENDED])
              })
            }
          },
          {
            path: "cancelled",
            name: "admin__cancelledCProds",
            component: CProdsListing,
            props: {
              availableSorters: () => [
                StatusSorter(),
                DateCancelledSorter(),
                DatePurchasedSorter()
              ],
              availableFilters: () => DefaultCProdFilters(["status.code"]),
              routeFilters: () => ({
                ...statusParam([ContractStatusCodes.CANCELLED])
              })
            }
          },
          {
            path: "lapsed",
            name: "admin__lapsedCProds",
            component: CProdsListing,
            props: {
              availableSorters: () => [
                StatusSorter(),
                DateLapsedSorter(),
                DatePurchasedSorter()
              ],
              availableFilters: () => DefaultCProdFilters(["status.code"]),
              routeFilters: () => ({
                ...statusParam([ContractStatusCodes.CLOSED])
              })
            }
          },
          {
            path: "last-30-days",
            name: "admin__fromLast30DaysCProds",
            component: CProdsListing,
            props: {
              availableSorters: () => [StatusSorter(), DatePurchasedSorter()],
              availableFilters: () => DefaultCProdFilters(["created_at"]),
              routeFilters: () => ({
                ...isFromLast30DaysParam()
              })
            }
          },
          {
            path: "has-provision-issues",
            name: "admin__hasProvisionIssuesCProds",
            component: CProdsListing,
            props: {
              availableSorters: () => [StatusSorter(), DatePurchasedSorter()],
              availableFilters: () => [
                ...DefaultCProdFilters(),
                HasProvisionIssuesFilter()
              ],
              routeFilters: () => ({
                ...hasUnresolvedRequestsParam()
              })
            }
          }
        ]
      },
      {
        path: `category/:categoryId(${RegexMatch.UUID})`,
        name: "admin__allCategoryProducts",
        component: CProdsListing,
        props: {
          availableSorters: () => [StatusSorter(), DatePurchasedSorter()],
          availableFilters: () => DefaultCProdFilters(["product.category.id"]),
          routeFilters: (vm: any) => ({
            ...IsCategoryParam(vm)
          })
        }
      },
      {
        path: `segment/:segmentId(${RegexMatch.UUID})`,
        name: "adminCProdsSegmentList",
        component: {
          name: "AdminCProdsSegmentList",
          template: "<router-view />"
        },
        redirect: { name: "admin__CProdsSegment" },
        children: [
          {
            path: "",
            name: "admin__CProdsSegment",
            component: SegmentResultsListing,
            props: (route: Route) => ({
              defaultDisplayMode: ListDisplayMode.GRID,
              displayModeStoragePath: UserMetaKeys.UI_CPRODS_VIEW,
              segmentTypeHint: SegmentTypes.CONTRACTS_PRODUCTS,
              segmentId: route.params.segmentId,
              newSegmentRouteName: "adminCProdsSegmentList",
              availableSorters: DefaultCProdSorters,
              availableFilters: () => DefaultCProdFilters()
            })
          }
        ]
      }
    ]
  }
];
