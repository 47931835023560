import i18n from "@/i18n";
import type { ActionTree, GetterTree } from "vuex";
import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ApiPathGetter } from "@/models/api";
import type { IState } from "@/store";
import { Methods } from "@/models/methods";
import { ToastProgrammatic as $toast } from "buefy";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext, isMockClientContext }): ApiPathGetter =>
    () => {
      const admin = "api/admin/shared";
      const client = "api/shared";
      const contextual =
        isAdminContext && !isMockClientContext() ? admin : client;
      return { client, admin, contextual };
    }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath().contextual,
        storeModule: DataModules.SHARES
      },
      { root: true }
    );
  },
  get: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath().contextual}/${payload?.token}`,
        storeModule: DataModules.SHARES
      },
      { root: true }
    );
  },
  create: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/create",
      {
        ...payload,
        path: getters.apiPath().contextual,
        storeModule: DataModules.SHARES
      },
      { root: true }
    );
  },
  update: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/update",
      {
        ...payload,
        path: `${getters.apiPath().contextual}/${payload?.token}`,
        storeModule: DataModules.SHARES
      },
      { root: true }
    );
  },
  delete: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/remove",
      {
        ...payload,
        path: `${getters.apiPath().contextual}/${payload?.token}`,
        storeModule: DataModules.SHARES
      },
      { root: true }
    );
  },
  getSharedObject: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.GET,
        storeModule: DataModules.SHARES,
        path: `${getters.apiPath().contextual}/${payload.token}/${
          payload.action
        }`,
        requestConfig: {
          params: payload.params,
          responseType: payload.responseType
        }
      },
      { root: true }
    );
  },
  postToAction: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.POST,
        path: `${getters.apiPath().contextual}/${payload.token}/${
          payload.action
        }`,
        storeModule: DataModules.SHARES,
        requestConfig: { data: payload.data }
      },
      { root: true }
    );
  },
  openInvoiceShareModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/windowModal",
      {
        config: {
          component: () =>
            import("@/components/app/global/share/invoiceShareModal.vue"),
          width: 540,
          canCancel: ["escape", "outside"],
          ...payload
        }
      },
      { root: true }
    );
  },
  openDeleteWithConfirmModal: ({ dispatch }, payload) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const confirm = await dispatch(
        "ui/open/confirmModal",
        {
          config: {
            props: {
              autoClose: false,
              action: i18n.t("_action.delete"),
              message: i18n.t("_sentence.delete_confirmation", {
                object: (i18n.t("_.shared_link") as string).toLowerCase()
              })
            },
            events: {
              confirmed: async () => {
                try {
                  const deleteResult = await dispatch(
                    `data/${DataModules.SHARES}/delete`,
                    payload,
                    {
                      root: true
                    }
                  );
                  resolve(deleteResult);

                  $toast.open({
                    message: i18n.t("_.object_successfully_removed", {
                      object: i18n.t("_.shared_link")
                    }) as string
                  });
                } catch (error) {
                  reject(error);
                }
                confirm.close();
              }
            }
          }
        },
        { root: true }
      );
    });
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
