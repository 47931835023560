export enum DocCategories {
  CLIENT_SELF_SERVICE = "client-self-service",
  CURRENCIES = "currencies",
  FOR_WEB_HOSTS = "for-web-hosts",
  GETTING_STARTED = "getting-started",
  LEGAL_AND_INFORMATION = "legal-and-information",
  MANAGING_YOUR_ACCOUNT = "managing-your-account",
  SETUP_CONFIGURATION = "setup-configuration",
  USING_UPMIND = "using-upmind"
}

export enum CategorySlug {
  AFFILIATE_SYSTEMS = DocCategories.USING_UPMIND,
  ANALYTICS = DocCategories.USING_UPMIND,
  BILLING = DocCategories.USING_UPMIND,
  CURRENCIES = DocCategories.USING_UPMIND,
  EXTERNAL_AFFILIATE_SYSTEMS = DocCategories.USING_UPMIND,
  FRAUD = DocCategories.SETUP_CONFIGURATION,
  INVOICES = DocCategories.USING_UPMIND,
  NOTIFICATION_CHANNELS = DocCategories.SETUP_CONFIGURATION,
  PRICE_TAX = DocCategories.USING_UPMIND,
  SECURITY = DocCategories.SETUP_CONFIGURATION,
  SUBSCRIPTIONS = DocCategories.USING_UPMIND,
  TICKETS = DocCategories.USING_UPMIND,
  UI = DocCategories.SETUP_CONFIGURATION
}

export enum DocsSlugs {
  AFFILIATE_SYSTEMS = "affiliates",
  ANALYTICS = "reporting",
  BILLING = "invoicing-and-payments",
  CURRENCIES = "invoicing-and-payments",
  EXTERNAL_AFFILIATE_SYSTEMS = "affiliates",
  FRAUD = "orders-services",
  INVOICES = "invoicing-and-payments",
  NOTIFICATION_CHANNELS = "notifications",
  PRICE_TAX = "invoicing-and-payments",
  SECURITY = "orders-services",
  SUBSCRIPTIONS = "product-management",
  TICKETS = "service-desk",
  UI = "branding"
}
