import { ClientRoutes } from "@/data/enums/router";
import store from "@/store";
import type { Route } from "vue-router";

export default [
  {
    path: "delegate_access/accept/:hash",
    beforeEnter: async (to: Route, from: Route, next: any) => {
      const { hash } = to.params;
      if (hash) {
        store.dispatch("ui/open/windowModal", {
          config: {
            component: () =>
              import("@/components/app/global/delegates/acceptInviteModal.vue"),
            width: 480,
            canCancel: ["button"],
            props: {
              hash
            }
          }
        });
      }
      // Always redirect to client dashboard
      next({ name: ClientRoutes.DASHBOARD }, { replace: true });
    }
  }
];
