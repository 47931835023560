<script lang="ts">
import { defineComponent } from "vue";
import RequestPromises from "@/mixins/requestPromises";
import { DataModules } from "@/store/modules/data/modules";
import type { PropType, CreateElement } from "vue";
import type {
  IObjectNotificationProvider,
  IObjectNotificationProviderMethods
} from "@/models/providers/objectNotifications";
import type { ITemplate, ITemplateCategory } from "@/models/templates";
import type { TemplateObjectTypes } from "@/data/enums/templates";

export default defineComponent({
  name: "ObjectNotificationProvider",
  mixins: [RequestPromises],
  provide() {
    return {
      objectNotificationProvider: this
        .objectNotificationProvider as IObjectNotificationProvider,
      objectNotificationMethods: this
        .objectNotificationMethods as IObjectNotificationProviderMethods
    };
  },
  props: {
    mappingId: { type: String, default: null },
    objectType: {
      type: String as PropType<TemplateObjectTypes>,
      required: true
    },
    objectId: { type: String, required: true },
    handleLoading: { type: Boolean, default: false },
    loaderAttrs: {
      type: Object as PropType<Record<string, any>>,
      default: () => ({})
    },
    handleRequestError: { type: Boolean, default: true }
  },
  data: () => ({
    isLoading: true,
    hasRequestError: false,
    isProcessing: false,
    isReloading: false,
    templateCategories: [] as ITemplateCategory[],
    templates: [] as ITemplate[]
  }),
  computed: {
    objectNotificationMethods(): IObjectNotificationProviderMethods {
      return {
        reloadData: this.reloadData,
        create: this.create,
        update: this.update,
        setProcessing: this.setProcessing,
        setTemplates: this.setTemplates
      };
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    setProcessing(value) {
      this.isProcessing = value;
    },
    reloadData() {
      try {
        this.isReloading = true;
        return this.loadData();
      } finally {
        this.isReloading = false;
      }
    },
    async loadData() {
      try {
        this.templateCategories = await this.getTemplateCategories();
        await this.setTemplates();
      } finally {
        this.isLoading = false;
      }
    },
    async setTemplates() {
      this.templates = await this.getTemplates();
    },
    create(form) {
      return this.$store.dispatch(
        `data/${DataModules.OBJECT_NOTIFICATIONS}/create`,
        {
          objectType: this.objectType,
          objectId: this.objectId,
          data: form
        }
      );
    },
    update(form) {
      return this.$store.dispatch(
        `data/${DataModules.OBJECT_NOTIFICATIONS}/update`,
        {
          id: this.mappingId,
          objectType: this.objectType,
          objectId: this.objectId,
          data: form
        }
      );
    },
    getTemplateCategories() {
      return this.$store.dispatch(
        `data/${DataModules.OBJECT_NOTIFICATIONS}/templateCategories`,
        {
          vm: this,
          storeData: false,
          objectType: this.objectType,
          params: {
            limit: 0,
            order: "name",
            with: "hooks"
          }
        }
      );
    },
    getTemplates() {
      return this.$store.dispatch(
        `data/${DataModules.OBJECT_NOTIFICATIONS}/templates`,
        {
          vm: this,
          storeData: false,
          objectType: this.objectType,
          params: {
            limit: 0,
            order: "name",
            with: "category"
          }
        }
      );
    },
    objectNotificationProvider(): IObjectNotificationProvider {
      return {
        objectType: this.objectType,
        isLoading: this.isLoading,
        isReloading: this.isReloading,
        hasRequestError: this.hasRequestError,
        isProcessing: this.isProcessing,
        templateCategories: this.templateCategories,
        templates: this.templates
      };
    }
  },
  render(h: CreateElement) {
    // Handle loading
    if (this.handleLoading && this.isLoading)
      return h("is-loading", {
        props: { if: true },
        attrs: this.loaderAttrs
      });
    // Handle request error
    if (this.handleRequestError && this.hasRequestError)
      return h("request-error", {
        props: { isLoading: this.isReloading },
        on: { click: () => this.reloadData() }
      });
    return (
      this.$scopedSlots?.default &&
      this.$scopedSlots.default({
        $objNotificationData: this.objectNotificationProvider(),
        $objNotificationMethods: this.objectNotificationMethods
      })
    );
  }
});
</script>
