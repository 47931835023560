import store from "@/store";
import _ from "@/boot/lodash";
import { BrandConfigKeys } from "@/data/constants";
import { ClientRoutes } from "@/data/enums/router";
import type { IClient } from "@upmind-automation/types";

export const clientAccountMenu = (id: IClient["id"]) => {
  return [
    {
      children: [
        {
          label: "_.profile",
          to: {
            name: ClientRoutes.ACCOUNT_PROFILE
          }
        },
        {
          label: "_.notes_and_secrets",
          to: {
            name: ClientRoutes.ACCOUNT_NOTES
          },
          if: () => {
            const config = store.getters["brand/config"];
            return _.get(
              config,
              BrandConfigKeys.CLIENT_NOTES_AND_SECRETS_ENABLED
            );
          }
        },
        {
          label: "_.security",
          to: {
            name: ClientRoutes.ACCOUNT_SECURITY
          }
        },
        {
          label: "_.notifications",
          to: {
            name: ClientRoutes.NOTIFICATIONS
          }
        },
        {
          label: "_.email_history",
          to: {
            name: ClientRoutes.EMAIL_HISTORY
          }
        },
        {
          label: "_.affiliate_program",
          if: () => {
            const config = store.getters["brand/config"];
            return (
              _.get(
                config,
                BrandConfigKeys.UPMIND_AFFILIATES_CUSTOMER_CONTROLS_ENABLED
              ) && _.get(config, BrandConfigKeys.UPMIND_AFFILIATES_ENABLED)
            );
          },
          to: {
            name: ClientRoutes.AFFILIATE
          },
          children: [
            {
              label: "_.overview",
              to: {
                name: ClientRoutes.AFFILIATE_OVERVIEW
              }
            },
            {
              label: "_.commissions",
              to: {
                name: ClientRoutes.AFFILIATE_COMMISSIONS
              }
            },
            {
              label: "_.payouts",
              to: {
                name: ClientRoutes.AFFILIATE_PAYOUTS
              }
            }
          ]
        },
        {
          label: "_.child_accounts",
          to: {
            name: ClientRoutes.CHILD_ACCOUNTS
          },
          if: () => store.getters["data/clients/showChildAccounts"](id)
        }
      ]
    }
  ];
};
