import i18n from "@/i18n";
import { FraudStatus } from "@/data/enums";
import { FilterOperators } from "@/data/table";
import { serialiseConfig } from "@/helpers/table";
import { InvoiceStatus } from "@/data/enums/invoice";

export function ordersTabigation() {
  return [
    {
      label: i18n.t("_.overview"),
      to: {
        name: `adminOrdersOverview`
      }
    },
    {
      label: i18n.t("_.orders_list"),
      to: {
        name: "adminOrdersList"
      }
    }
  ];
}

export const ordersQuickFilters = [
  {
    label: "_.all",
    to: {
      name: "adminOrdersList"
    }
  },
  {
    label: "_.paid",
    to: {
      name: "adminOrdersList",
      query: {
        OT1: serialiseConfig({
          constFilters: [
            {
              key: "status.code",
              operator: FilterOperators.EQUAL,
              value: [InvoiceStatus.PAID]
            }
          ]
        })
      }
    }
  },
  {
    label: "_.unpaid",
    to: {
      name: "adminOrdersList",
      query: {
        OT1: serialiseConfig({
          constFilters: [
            {
              key: "status.code",
              operator: FilterOperators.EQUAL,
              value: [
                InvoiceStatus.UNPAID,
                InvoiceStatus.ADJUSTED,
                InvoiceStatus.OVERDUE
              ]
            }
          ]
        })
      }
    }
  },
  {
    label: "_.cancelled",
    to: {
      name: "adminOrdersList",
      query: {
        OT1: serialiseConfig({
          constFilters: [
            {
              key: "status.code",
              operator: FilterOperators.EQUAL,
              value: [InvoiceStatus.CANCELLED]
            }
          ]
        })
      }
    }
  },
  {
    label: "_.from_last_24_hours",
    to: {
      name: "adminOrdersList",
      query: {
        OT1: serialiseConfig({
          constFilters: [
            {
              key: "created_at",
              operator: FilterOperators.AFTER_RELATIVE,
              value: "-24_hours"
            }
          ]
        })
      }
    }
  },
  {
    label: "_.from_last_7_days",
    to: {
      name: "adminOrdersList",
      query: {
        OT1: serialiseConfig({
          constFilters: [
            {
              key: "created_at",
              operator: FilterOperators.AFTER_RELATIVE,
              value: "-7_days"
            }
          ]
        })
      }
    }
  },
  {
    label: "_.from_last_30_days",
    to: {
      name: "adminOrdersList",
      query: {
        OT1: serialiseConfig({
          constFilters: [
            {
              key: "created_at",
              operator: FilterOperators.AFTER_RELATIVE,
              value: "-1_months"
            }
          ]
        })
      }
    }
  },
  {
    label: "_.pending_review",
    to: {
      name: "adminOrdersList",
      query: {
        OT1: serialiseConfig({
          constFilters: [
            {
              key: "fraud_status",
              operator: FilterOperators.EQUAL,
              value: `${FraudStatus.REVIEW}`
            }
          ]
        })
      }
    }
  },
  {
    label: "_.blocked",
    to: {
      name: "adminOrdersList",
      query: {
        OT1: serialiseConfig({
          constFilters: [
            {
              key: "fraud_status",
              operator: FilterOperators.EQUAL,
              value: `${FraudStatus.FRAUD}`
            }
          ]
        })
      }
    }
  }
];
