import _ from "@/boot/lodash";
import type { ActionTree, GetterTree } from "vuex";
import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ApiPathGetter } from "@/models/api";
import type { IOrder } from "@/models/orders";
import type { IState } from "@/store";
import { InvoiceCategoryCode } from "@/models/invoices";
import { Methods } from "@/models/methods";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext, isMockClientContext }): ApiPathGetter =>
    () => {
      const admin = `api/admin/invoices`;
      const client = `api/invoices`;
      const contextual =
        isAdminContext && !isMockClientContext() ? admin : client;
      return { client, admin, contextual };
    },
  listFunctionalities: () => {
    return ["list_client_fields", "list_basket_fields"];
  },
  withParam: () => () => {
    return _.compact([
      "tags",
      "client",
      "client.image",
      "status",
      "products"
    ]).join();
  },
  scope: () => id => {
    return `$order_${id}`;
  },
  isDue: (s, g, rS, rootGetters) => (order: IOrder) => {
    // Map to identical INVOICES getter
    return rootGetters[`data/${DataModules.INVOICES}/isDue`](order);
  },
  isOverdue: (s, g, rS, rootGetters) => (order: IOrder) => {
    // Map to identical INVOICES getter
    return rootGetters[`data/${DataModules.INVOICES}/isOverdue`](order);
  },
  isPaid: (s, g, rS, rootGetters) => (order: IOrder) => {
    // Map to identical INVOICES getter
    return rootGetters[`data/${DataModules.INVOICES}/isPaid`](order);
  },
  isPayable: (s, g, rS, rootGetters) => (order: IOrder) => {
    // Map to identical INVOICES getter
    return rootGetters[`data/${DataModules.INVOICES}/isPayable`](order);
  },
  isCancellable: (s, g, rS, rootGetters) => (order: IOrder) => {
    // Map to identical INVOICES getter
    return rootGetters[`data/${DataModules.INVOICES}/isCancellable`](order);
  },
  isPartiallyPaid: (s, g, rS, rootGetters) => (order: IOrder) => {
    // Map to identical INVOICES getter
    return rootGetters[`data/${DataModules.INVOICES}/isPartiallyPaid`](order);
  },
  isCancelled: (s, g, rS, rootGetters) => (order: IOrder) => {
    // Map to identical INVOICES getter
    return rootGetters[`data/${DataModules.INVOICES}/isCancelled`](order);
  },
  belongsToDelegate: (s, g, rS, rootGetters) => (order: IOrder) => {
    // Map to identical INVOICES getter
    return rootGetters[`data/${DataModules.INVOICES}/belongsToDelegate`](order);
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters, rootGetters }, payload = {}) => {
    payload.params = _.merge(
      {
        [`filter[category.slug]`]: [InvoiceCategoryCode.NEW_CONTRACT].join(","),
        with: _.compact([
          "client",
          "client.image",
          "status",
          "products",
          rootGetters["brand/isMultibrand"] ? "brand" : null
        ]).join(",")
      },
      payload.params
    );
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath().contextual,
        splitCount: true,
        storeModule: DataModules.ORDERS
      },
      { root: true }
    );
  },
  get: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath().contextual}/${payload.id}`,
        storeModule: DataModules.ORDERS,
        params: {
          ...payload.params,
          with_staged_imports: 1
        }
      },
      { root: true }
    );
  },
  syncProductTags: ({ dispatch }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PUT,
        path: `api/admin/invoice_products/${payload.id}/tags`,
        storeModule: DataModules.ORDERS,
        requestConfig: { data: payload.data }
      },
      { root: true }
    );
  },
  openPreviewModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import("@/components/app/global/orders/orderModal.vue"),
          ...payload
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  modules: {
    templates: require("./templates").default
  }
};
