import _ from "@/boot/lodash";

export function downloadBlob(blob, filename) {
  const link = document.createElement("a");
  if (link.download !== undefined) {
    // feature detection
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

export function bytesToSize(bytes, decimals = 1) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i];
}

// Convert ArrayBuffer to string
export function ab2Str(ab: ArrayBuffer): string {
  return String.fromCharCode.apply(null, new Uint8Array(ab) as any);
}

// Convert ArrayBuffer to JSON
export function ab2Json(ab: ArrayBuffer): JSON {
  return JSON.parse(ab2Str(ab));
}

export function tryArrayBufferToJson(ab: ArrayBuffer): Record<string, any> {
  try {
    return ab2Json(ab);
  } catch {
    return ab;
  }
}

// Convert object ArrayBuffer to Json
export function convertObjAb2Json(object: object, key: string) {
  try {
    const ab = _.get(object, key) as ArrayBuffer;
    if (ab) _.set(object, key, ab2Json(ab));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}
