import _ from "lodash";
import store from "@/store";
import type { NavigationGuardNext, Route } from "vue-router";
import type { RouteConfig } from "vue-router/types/router";

export default [
  {
    path: "upmind/updates/changelog",
    component: () => import("@/views/admin/upmind/updates/index.vue"),
    props: { initialPage: "Changelog" },
    meta: {
      title: "_.changelog"
    }
  },
  {
    path: "upmind/updates/roadmap",
    component: () => import("@/views/admin/upmind/updates/index.vue"),
    props: { initialPage: "Roadmap" },
    meta: {
      title: "_.upmind_roadmap"
    }
  },
  {
    path: "upmind/updates:catchall(.*)",
    component: () => import("@/views/admin/upmind/updates/index.vue"),
    props: { initialPage: "Board" },
    meta: {
      title: "_.feature_requests"
    }
  },
  {
    path: "upmind",
    component: () => import("@/views/admin/upmind/index.vue"),
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext) => {
      if (store.getters["user/isUpmindClient"]) return next();
      next(from.matched.length ? false : { name: "admin__account" });
      return store.dispatch("ui/endRouting");
    },
    props: {
      routes: require("./menu").UpmindRoutes
    },
    meta: {
      title: "_.manage_my_upmind",
      mockClientContext: true
    },
    children: _.union(
      [
        {
          path: "",
          name: "admin__upmindAccount",
          redirect: { name: "admin__upmindOverview" }
        },
        {
          path: "overview",
          name: "admin__upmindOverview",
          component: () => import("@/views/admin/upmind/overview/index.vue"),
          meta: {
            title: "_.overview",
            backTo: "_.overview"
          }
        }
      ] as RouteConfig[],
      require("./products").default as RouteConfig[],
      require("./billing").default as RouteConfig[],
      require("./tickets").default as RouteConfig[],
      require("./settings").default as RouteConfig[],
      [
        {
          path: "*",
          component: () => import("@/views/error/index.vue"),
          meta: { error: 404 }
        }
      ] as RouteConfig[]
    )
  }
] as RouteConfig[];
