import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import { Methods } from "@/models/methods";
import type { ApiPathGetter } from "@/models/api";
import _ from "@/boot/lodash";
import i18n from "@/i18n";
import { ConfirmModalModes } from "@/data/enums";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext }): ApiPathGetter =>
    () => {
      const admin = "api/admin/import/files";
      const client = "";
      const contextual = isAdminContext ? admin : client;
      return { client, admin, contextual };
    }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `${getters.apiPath().admin}/files`,
        storeModule: DataModules.IMPORTS_UPLOAD_FILES
      },
      { root: true }
    );
  },
  openUploadFilesModal: ({ dispatch }, modalConfig) => {
    return dispatch(
      `ui/open/slideModal`,
      {
        config: {
          component: () =>
            import("@/components/app/admin/imports/uploadFilesModal.vue"),
          width: 720,
          canCancel: ["x", "button", "outside"],
          ...modalConfig
        }
      },
      { root: true }
    );
  },
  download: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.GET,
        returnData: false,
        path: `${getters.apiPath().contextual}/${payload?.fileId}/download`,
        storeModule: DataModules.IMPORTS_UPLOAD_FILES,
        requestConfig: { params: payload.params, responseType: "arraybuffer" }
      },
      { root: true }
    );
  },
  uploadFile: async ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.POST,
        path: getters.apiPath().contextual,
        storeModule: DataModules.IMPORTS_UPLOAD_FILES,
        requestConfig: _.merge(
          { data: payload.data },
          _.get(payload, "onUploadProgress")
            ? { onUploadProgress: payload.onUploadProgress }
            : {},
          _.has(payload, "handle401Error")
            ? { handle401Error: payload.handle401Error }
            : {}
        )
      },
      { root: true }
    );
  },
  confirmDelete: async ({ dispatch }, { fileId, message, onSuccess }) => {
    const confirm = await dispatch(
      "ui/open/confirmModal",
      {
        config: {
          props: {
            autoClose: false,
            confirmButtonText: i18n.t("_action.confirm"),
            mode: ConfirmModalModes.BUTTON,
            message
          },
          canCancel: [],
          events: {
            confirmed: async () => {
              try {
                await dispatch("remove", { fileId });

                dispatch(
                  "toast/show",
                  {
                    message: i18n.t(
                      "_sentence.imports.import_file_successfully_deleted"
                    )
                  },
                  { root: true }
                );

                onSuccess();
              } catch (error) {
                dispatch("api/handleError", error, {
                  root: true
                });
              } finally {
                confirm.close();
              }
            }
          }
        }
      },
      { root: true }
    );
  },
  remove: ({ dispatch, getters }, { fileId }) => {
    return dispatch(
      "data/remove",
      {
        path: `${getters.apiPath().contextual}`,
        storeModule: DataModules.IMPORTS_UPLOAD_FILES,
        data: {
          file_id: fileId
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
