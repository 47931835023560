import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import _ from "@/boot/lodash";
import type { ApiPathGetter } from "@/models/api";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath: (): ApiPathGetter => () => {
    const admin = "api/admin/provision/categories";
    return { admin };
  },
  categoriesScope: () => {
    return `$provision_categories`;
  },
  attributesScope: () => categoryId => {
    return `$provision_category_${categoryId}_attributes`;
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath().admin,
        params: _.merge({ limit: 0 }, _.get(payload, "params", {})),
        storeModule: DataModules.PROVISIONING_CATEGORIES
      },
      { root: true }
    );
  },
  getAttributes: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${
          payload?.id
        }/configuration_attributes`,
        storeModule: DataModules.PROVISIONING_CATEGORIES
      },
      { root: true }
    );
  },

  getAttributeValueSuggestions: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${
          payload?.id
        }/configuration_attributes/${payload?.attributeId}/values`,
        storeModule: DataModules.PROVISIONING_CATEGORIES
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
