import type { IDataState } from "@/store/modules/data";
import { DEFAULT_LIST_SCOPE } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import _ from "@/boot/lodash";
import i18n from "@/i18n";
import type { ActionTree, GetterTree } from "vuex";
import type { ApiPathGetter } from "@/models/api";
import type { IState } from "@/store";
import { Methods } from "@/models/methods";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath: (): ApiPathGetter => () => {
    const admin = `api/admin/leads`;
    return { admin };
  },
  scope: () => leadId => {
    return `$lead_${leadId}`;
  },
  listParams: (state: IDataState) => () => {
    return _.get(state, `${DEFAULT_LIST_SCOPE}.params`, {});
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath().admin,
        storeModule: DataModules.LEADS
      },
      { root: true }
    );
  },
  get: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload.id}`,
        storeModule: DataModules.LEADS
      },
      { root: true }
    );
  },
  create: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/create",
      {
        ...payload,
        path: getters.apiPath().admin,
        storeModule: DataModules.LEADS
      },
      { root: true }
    );
  },
  update: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/update",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload.id}`,
        storeModule: DataModules.LEADS
      },
      { root: true }
    );
  },
  remove: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/remove",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload.id}`,
        storeModule: DataModules.LEADS
      },
      { root: true }
    );
  },
  search: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.GET,
        path: `${getters.apiPath().admin}/search`,
        ...payload,
        requestConfig: {
          params: payload.params
        },
        storeModule: DataModules.LEADS
      },
      { root: true }
    );
  },
  openSelectLeadModal: ({ rootGetters, dispatch }, payload) => {
    if (!rootGetters["user/can"]("list_leads")) {
      return dispatch("ui/showErrorMessage", i18n.t("_.invalid_permissions"), {
        root: true
      });
    }
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import("@/components/app/admin/leads/selectLeadModal.vue"),
          ...payload
        }
      },
      { root: true }
    );
  },
  createClient: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.POST,
        path: `${getters.apiPath().admin}/${payload.id}`,
        requestConfig: {
          data: payload.data
        }
      },
      { root: true }
    );
  },
  attach: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.POST,
        path: `${getters.apiPath().admin}/${payload.id}/attach/${
          payload.relatedId
        }`,
        requestConfig: {
          data: payload.data
        }
      },
      { root: true }
    );
  },
  attachMain: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.POST,
        path: `${getters.apiPath().admin}/${payload.id}/attach/main/${
          payload.relatedId
        }`,
        requestConfig: {
          data: payload.data
        }
      },
      { root: true }
    );
  },
  changeMain: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.POST,
        path: `${getters.apiPath().admin}/assign/main/${payload.id}`,
        requestConfig: {
          data: payload.data
        }
      },
      { root: true }
    );
  },
  detach: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.DELETE,
        path: `${getters.apiPath().admin}/${payload.id}/detach/${
          payload.relatedId
        }`,
        requestConfig: {
          data: payload.data
        }
      },
      { root: true }
    );
  },
  openAddLeadModal: ({ rootGetters, dispatch }) => {
    if (!rootGetters["user/can"]("create_lead")) {
      return;
    }
    dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import("@/components/app/admin/leads/addLeadModal.vue")
        }
      },
      { root: true }
    );
  },
  assignTags: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PUT,
        path: `${getters.apiPath().admin}/${payload?.id}/tags`,
        storeModule: DataModules.LEADS,
        requestConfig: {
          data: {
            tag_ids: payload?.tagIds
          }
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  modules: {
    vaultAssets: require("./vaultAssets").default
  }
};
