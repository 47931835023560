import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import type { ApiPathGetter } from "@/models/api";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath: (): ApiPathGetter => () => {
    const admin = `api/admin/brands`;
    return { admin };
  },
  brandFieldsScope: () => (brandId, blueprintId) => {
    return `$brand_${brandId}_blueprint_${blueprintId}_fields`;
  },
  brandFieldsValuesScope: () => (brandId, blueprintId) => {
    return `$brand_${brandId}_blueprint_${blueprintId}_fields_values`;
  }
};

const actions: ActionTree<IDataState, IState> = {
  listBrandFields: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${
          payload?.brandId
        }/provision_fields/${payload?.blueprintId}`,
        params: {
          limit: 0
        },
        storeModule: DataModules.PROVISIONING_BRAND
      },
      { root: true }
    );
  },

  listBrandFieldsValues: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${
          payload?.brandId
        }/provision_fields/${payload?.blueprintId}/values`,
        params: {
          limit: 0
        },
        storeModule: DataModules.PROVISIONING_BRAND
      },
      { root: true }
    );
  },

  updateBrandFieldsValues: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/update",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${
          payload?.brandId
        }/provision_fields/${payload?.blueprintId}/values`,
        storeModule: DataModules.PROVISIONING_BRAND
      },
      { root: true }
    );
  },

  checkBrandFields: ({ dispatch }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `api/admin/provision/blueprints/${payload?.blueprintId}/fields`,
        params: {
          limit: "count",
          "filter[type]": "brand"
        },
        storeModule: DataModules.PROVISIONING_BRAND
      },
      { root: true }
    );
  },

  openManageBrandFieldsModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/admin/settings/provisioning/manageBrandFieldsModal.vue"
            ),
          props: payload,
          width: 640
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
