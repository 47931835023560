import UNotification from "./UNotification.vue";

export const UNotificationProgrammatic = {
  open(params) {
    return new UNotification({
      parent: window.$rootVue,
      el: document.createElement("div"),
      propsData: params.props
    });
  }
};
