<template>
  <div>
    <!-- Billboard Component (Optional) -->
    <u-transition-fade-in>
      <template v-if="hasBillboard">
        <ribbon class="has-background-white has-border-top">
          <slot name="billboard" />
        </ribbon>
      </template>
    </u-transition-fade-in>
    <!-- Default Component Ribbon -->
    <ribbon class="has-inset-shadow-top">
      <u-padding>
        <columns class="content-pane-columns is-marginless is-centered">
          <column v-if="hasMenu" class="is-4 is-3-fullhd">
            <slot name="menu" />
          </column>
          <column :class="hasMenu ? 'is-8 is-9-fullhd' : 'is-12'">
            <router-view />
          </column>
        </columns>
      </u-padding>
    </ribbon>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ClientDefaultLayout",
  computed: {
    hasBillboard() {
      return !!this.$scopedSlots.billboard;
    },
    hasMenu() {
      return !!this.$scopedSlots.menu;
    }
  }
});
</script>

<style lang="scss" scoped>
.content-pane-columns {
  padding-bottom: 3em;
  @include desktop {
    padding-top: 1em;
  }
}
</style>
