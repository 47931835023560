import _ from "@/boot/lodash";

export function getSessionItem(key: string, defaultValue: any = "") {
  const sessionItem: string | null = sessionStorage.getItem(key);
  if (_.isNull(sessionItem)) return defaultValue;
  try {
    return JSON.parse(sessionItem);
  } catch {
    return sessionItem;
  }
}

export function setSessionItem(key: string, data: any, stringify = false) {
  const stringifiedSessionItem = stringify ? JSON.stringify(data) : data;
  sessionStorage.setItem(key, stringifiedSessionItem);
}

export function removeSessionItem(key: string) {
  sessionStorage.removeItem(key);
}
