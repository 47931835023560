import _ from "@/boot/lodash";
import i18n from "@/i18n";
import { DataModules } from "@/store/modules/data/modules";
import { InvoiceCategoryCode as ICC } from "@/models/invoices";
import { InvoiceStatus } from "@/data/enums/invoice";
import { Methods } from "@/models/methods";
import { InvoiceCategoryCodesMap } from "@/data/enums/invoice";
import { GatewayTypes } from "@/data/enums";
import {
  AdminRoutes,
  ClientRoutes,
  UpmindClientRoutes
} from "@/data/enums/router";
import type { ActionTree, GetterTree } from "vuex";
import type { IAddress, ICompany, IClient } from "@upmind-automation/types";
import type { IDataState } from "@/store/modules/data";
import type { IInvoice } from "@/models/invoices";
import type { IState } from "@/store";
import type { ApiPathGetter } from "@/models/api";
import type { Location as VueLocation } from "vue-router";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext, isMockClientContext }): ApiPathGetter =>
    () => {
      const admin = `api/admin/invoices`;
      const client = `api/invoices`;
      const contextual =
        isAdminContext && !isMockClientContext() ? admin : client;
      return { client, admin, contextual };
    },
  scope: () => id => {
    return `$invoice_${id}`;
  },
  unifiableCountScope: () => clientId => {
    return `$unifiable_count::${clientId}`;
  },
  unifiableCount: (state, getters) => (clientId: IClient) => {
    const scope = getters["unifiableCountScope"](clientId);
    return state[scope]?.total || 0;
  },
  listFunctionalities: () => {
    return ["staff_list_invoices"];
  },
  withParam:
    (s, g, rS, rootGetters) =>
    (
      config: {
        clientId?: IClient["id"];
        withParams?: "";
      } = {}
    ) => {
      return _.compact([
        "client,client.image",
        rootGetters["brand/isMultibrand"] && "brand",
        !_.isEmpty(config.withParams) && config.withParams,
        "status",
        "products"
      ]).join();
    },
  selectorScope: () => id => {
    return `$invoice_${id}_selector`;
  },
  unpaidStatuses: () => {
    return [
      InvoiceStatus.UNPAID,
      InvoiceStatus.ADJUSTED,
      InvoiceStatus.OVERDUE
    ];
  },
  isDue: () => (invoice: IInvoice) => {
    return [
      InvoiceStatus.UNPAID,
      InvoiceStatus.ADJUSTED,
      InvoiceStatus.OVERDUE
    ].includes(_.get(invoice, "status.code"));
  },
  isOverdue: () => (invoice: IInvoice) => {
    return [InvoiceStatus.OVERDUE].includes(_.get(invoice, "status.code"));
  },
  isPaid: () => (invoice: IInvoice) => {
    return [InvoiceStatus.PAID].includes(_.get(invoice, "status.code"));
  },
  hasPendingPayments: () => (invoice: IInvoice) => {
    return !!invoice?.payments?.find(i => i.pending);
  },
  hasPendingPaymentInstructions: () => (invoice: IInvoice) => {
    return !!invoice?.payments?.find(payment => {
      return (
        payment.pending &&
        payment.gateway?.type === GatewayTypes.AWAITING_CLIENT
      );
    });
  },
  isPayable: () => (invoice: IInvoice) => {
    return [
      InvoiceStatus.UNPAID,
      InvoiceStatus.ADJUSTED,
      InvoiceStatus.OVERDUE
    ].includes(_.get(invoice, "status.code"));
  },
  isRefunded: () => (invoice: IInvoice) => {
    return [InvoiceStatus.REFUNDED].includes(_.get(invoice, "status.code"));
  },
  isCreditNote: () => (invoice: IInvoice) => {
    return [ICC.CREDIT_NOTE, ICC.CREDIT_NOTE_FOR_REFUND].includes(
      _.get(invoice, "category.slug")
    );
  },
  isPartiallyPaid: (state, getters) => (invoice: IInvoice) => {
    return (
      getters["isDue"](invoice) &&
      invoice.unpaid_amount_converted > 0 &&
      invoice.paid_amount > 0
    );
  },
  isCancellable: () => (invoice: IInvoice) => {
    return [InvoiceStatus.UNPAID, InvoiceStatus.OVERDUE].includes(
      _.get(invoice, "status.code")
    );
  },
  isCancelled: () => (invoice: IInvoice) => {
    return [
      InvoiceStatus.CANCELLED,
      InvoiceStatus.CANCELLATION_REQUEST
    ].includes(_.get(invoice, "status.code"));
  },
  isLocked: () => (invoice?: IInvoice) => {
    return !isNaN(Date.parse(invoice?.locked as string));
  },
  belongsToChildOfClient:
    (s, g, rootState) => (invoice: IInvoice, clientId?: IClient["id"]) => {
      clientId = clientId || rootState?.user?.id;
      const parentId = invoice?.client?.parent_client_config?.parent_client_id;
      return clientId === parentId;
    },
  belongsToDelegate: () => (invoice: IInvoice) => {
    if (invoice?.client?.parent_client_config?.parent_client_id) return false;
    return !!invoice?.delegate_related;
  },
  hasCreditRefundOptions: (state, getters) => (invoice: IInvoice) => {
    return !getters["isCancelled"](invoice) && !getters["isRefunded"](invoice);
  },
  mappedPromotions: () => (invoice: IInvoice) => {
    return _.map(invoice?.promotions, ({ promotion }) => {
      const {
        code,
        name_translated,
        name,
        short_description_translated,
        short_description
      } = promotion;
      // Adjustment promotions
      if (code.startsWith("adjust_"))
        return {
          code: "",
          name: i18n.t("_.custom_discount"),
          desc: i18n.t("_sentence.invoice.manually_adjusted_tooltip")
        };
      // Normal promotions
      return {
        code: i18n.t("_.code_code", { code }),
        name: _.trim(name_translated || name),
        desc: _.trim(short_description_translated || short_description)
      };
    });
  },
  getInvoiceCategoryName: () => (invoice: IInvoice) => {
    const map = InvoiceCategoryCodesMap();
    const code = invoice?.category?.slug;
    if (invoice.is_consolidation) return map[ICC.CONSOLIDATION].label;
    return map[code]?.label || "";
  },
  getRoute:
    (s, rS, g, { isAdminContext, isMockClientContext }) =>
    (
      invoiceId: IInvoice["id"],
      clientId?: IInvoice["client_id"]
    ): VueLocation => {
      if (isMockClientContext())
        return {
          name: UpmindClientRoutes.INVOICE,
          params: { invoiceId }
        };
      if (!isAdminContext)
        return {
          name: ClientRoutes.INVOICE,
          params: { invoiceId }
        };
      if (clientId)
        return {
          name: AdminRoutes.INVOICE,
          params: { invoiceId, clientId }
        };
      return {
        name: AdminRoutes.INVOICE_PREVIEW,
        params: { invoiceId }
      };
    },
  getCProdBillingRoute:
    (s, rS, g, { isAdminContext, isMockClientContext }) =>
    (params): VueLocation => {
      if (isMockClientContext())
        return {
          name: UpmindClientRoutes.CONTRACT_PRODUCT_BILLING
        };
      if (!isAdminContext)
        return {
          name: ClientRoutes.CONTRACT_PRODUCT_BILLING
        };

      return {
        name: AdminRoutes.CONTRACT_PRODUCT_INVOICES,
        params
      };
    }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath().contextual,
        splitCount: true,
        storeModule: DataModules.INVOICES
      },
      { root: true }
    );
  },
  get: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath().contextual}/${payload.id}`,
        storeModule: DataModules.INVOICES
      },
      { root: true }
    );
  },
  getWithParams: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        params: {
          with: [
            "data",
            "account.user",
            "status",
            "client",
            "promotions",
            "gateway",
            "payment_details",
            "payments.gateway",
            "payments.payment_details",
            "payments.payment_type",
            "original_invoice", // Required if duplicated
            "duplicate_invoice" // Required if duplicate
          ].join(",")
        },
        ...payload,
        path: `${getters.apiPath().contextual}/${payload.id}`,
        storeModule: DataModules.INVOICES
      },
      { root: true }
    );
  },
  update: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/update",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload.id}`,
        storeModule: DataModules.INVOICES
      },
      { root: true }
    );
  },
  updatePaymentDetails: ({ dispatch, getters }, payload) => {
    if (!_.has(payload, "id"))
      throw new Error("id not provided to fetch results");
    return dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.PATCH,
        path: `${getters.apiPath().contextual}/${payload.id}/payment_details`,
        requestConfig: { data: payload.data }
      },
      { root: true }
    );
  },
  create: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/create",
      {
        ...payload,
        path: getters.apiPath().admin,
        storeModule: DataModules.INVOICES
      },
      { root: true }
    );
  },
  cancel: ({ dispatch }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PATCH,
        path: `api/admin/orders/${payload.invoiceId}/cancel`,
        storeModule: DataModules.INVOICES,
        requestConfig: { data: payload.data }
      },
      { root: true }
    );
  },
  creditProducts: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.POST,
        path: `${getters.apiPath().admin}/${payload.invoiceId}/credit_product`,
        storeModule: DataModules.INVOICES,
        requestConfig: { data: payload.data }
      },
      { root: true }
    );
  },
  creditAmount: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.POST,
        path: `${getters.apiPath().admin}/${payload.invoiceId}/credit_amount`,
        storeModule: DataModules.INVOICES,
        requestConfig: { data: payload.data }
      },
      { root: true }
    );
  },
  openPreviewModal: ({ dispatch }, { invoiceId, creditNote = false }) => {
    return dispatch(
      "ui/open/modal",
      {
        config: {
          component: () =>
            import("@/components/app/global/invoices/invoiceModal.vue"),
          width: 780,
          props: {
            invoiceId,
            creditNote
          }
        }
      },
      { root: true }
    );
  },
  openCreditNotePreviewModal: ({ dispatch }, { creditNoteId }) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import("@/components/app/global/creditNotes/creditNoteModal.vue"),
          width: 840,
          props: {
            creditNoteId: creditNoteId
          }
        }
      },
      { root: true }
    );
  },
  openCancelInvoiceModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/windowModal",
      {
        config: {
          component: () =>
            import("@/components/app/global/invoices/invoiceCancelModal.vue"),
          width: 540,
          ...payload
        }
      },
      { root: true }
    );
  },
  openRegenerateInvoiceModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/global/invoices/regenerateInvoiceModal.vue"
            ),
          width: 540,
          ...payload
        }
      },
      { root: true }
    );
  },
  openPayInvoiceModal: ({ dispatch }, config) => {
    return dispatch(
      "ui/open/windowModal",
      {
        config: _.merge(
          {
            parent: window.$rootVue,
            component: () =>
              import(
                "@/components/app/global/payments/invoicePaymentModal.vue"
              ),
            width: 740,
            canCancel: ["button"]
          },
          config
        )
      },
      { root: true }
    );
  },
  openChangePaymentMethodModal: ({ dispatch }, config) => {
    return dispatch(
      "ui/open/windowModal",
      {
        config: _.merge(
          {
            component: () =>
              import(
                "@/components/app/global/invoices/invoiceChangePaymentMethodModal.vue"
              )
          },
          config
        )
      },
      { root: true }
    );
  },
  openSelectInvoiceModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/modal",
      {
        config: {
          canCancel: ["escape", "button", "x", "outside"],
          component: () =>
            import("@/components/app/global/invoices/selectInvoiceModal.vue"),
          ...payload,
          width: 940
        }
      },
      { root: true }
    );
  },
  regenerateInvoice: (
    { dispatch, getters },
    {
      invoiceId,
      data
    }: {
      invoiceId: IInvoice["id"];
      data: {
        company_id?: ICompany["id"];
        address_id?: IAddress["id"];
      };
    }
  ) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.POST,
        path: `${getters.apiPath().admin}/${invoiceId}/refresh`,
        storeModule: DataModules.INVOICES,
        requestConfig: { data }
      },
      { root: true }
    );
  },
  recalculateInvoice: (
    { dispatch, getters },
    {
      invoiceId,
      data
    }: {
      invoiceId: IInvoice["id"];
      data: {
        company_id?: ICompany["id"];
        address_id?: IAddress["id"];
        apply_contract_changes: boolean;
      };
    }
  ) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.POST,
        path: `${getters.apiPath().admin}/${invoiceId}/duplicate`,
        storeModule: DataModules.INVOICES,
        requestConfig: { data }
      },
      { root: true }
    );
  },
  listProducts: ({ dispatch }, payload) => {
    return dispatch(
      "data/fetch",
      {
        ...payload,
        path: `api/admin/invoice_products`,
        scope: _.get(payload, "scope") || `$list_products`,
        storeModule: DataModules.INVOICES
      },
      { root: true }
    );
  },
  hasUnpaid: async ({ dispatch, getters }, payload) => {
    const response = await dispatch(
      "data/list",
      {
        storeData: false,
        params: {
          limit: "count",
          "filter[status.code]": getters["unpaidStatuses"].join(),
          ...(payload.clientId
            ? { [`filter[client_id]`]: payload.clientId }
            : {})
        },
        path: getters.apiPath().contextual,
        storeModule: DataModules.INVOICES,
        returnData: false
      },
      { root: true }
    );

    return response.total > 0;
  },
  getConsolidatableTotal: async ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath().contextual,
        params: {
          limit: "count",
          "filter[status.code]": getters["unpaidStatuses"].join(),
          "filter[is_consolidation]": false,
          "filter[category.slug]": ICC.RECURRENT,
          "filter[client_id]": payload.clientId,
          "filter[paid_amount]": 0
        },
        storeModule: DataModules.INVOICES
      },
      { root: true }
    );
  },
  openConsolidateInvoicesModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/modal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/global/invoices/manualConsolidationModal.vue"
            ),
          width: 940,
          ...payload
        }
      },
      { root: true }
    );
  },
  consolidateInvoices: ({ dispatch, getters }, { data }) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.POST,
        path: `${getters.apiPath().contextual}/consolidate`,
        requestConfig: { data },
        storeModule: DataModules.INVOICES
      },
      { root: true }
    );
  },
  getUnpaidConvertedAmount: ({ dispatch, getters }, { invoiceId, params }) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.GET,
        path: `${getters.apiPath().contextual}/unpaid_amount/${invoiceId}`,
        requestConfig: { params },
        storeModule: DataModules.INVOICES
      },
      { root: true }
    );
  },
  updateMetaData: ({ dispatch }, payload) => {
    return dispatch(
      "data/update",
      {
        ...payload,
        path: `api/admin/invoice_meta/${payload.id}`,
        storeModule: DataModules.INVOICES
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  modules: {
    baskets: require("./baskets").default,
    pdfs: require("./pdfs").default
  }
};
