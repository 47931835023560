import i18n from "@/i18n";

export function fraudmindTabigation() {
  return [
    {
      label: i18n.t("_.attention_required"),
      to: {
        name: "adminFraudmindAttentionRequired"
      }
    },
    {
      label: i18n.t("_.all_events"),
      to: {
        name: "adminFraudmindEvents"
      }
    }
  ];
}

export const clientsQuickFilters = [
  {
    get label() {
      return i18n.t("_.pending_review");
    },
    to: {
      name: "adminFraudmindClients_pending_review"
    }
  },
  {
    get label() {
      return i18n.t("_.blocked");
    },
    to: {
      name: "adminFraudmindClients_blocked"
    }
  }
];

export const ordersQuickFilters = [
  {
    get label() {
      return i18n.t("_.pending_review").toString();
    },
    to: {
      name: "adminFraudmindOrders_pending_review"
    }
  },
  {
    get label() {
      return i18n.t("_.blocked").toString();
    },
    to: {
      name: "adminFraudmindOrders_blocked"
    }
  }
];

export const invoicesQuickFilters = [
  {
    get label() {
      return i18n.t("_.pending_review");
    },
    to: {
      name: "adminFraudmindInvoices_pending_review"
    }
  },
  {
    get label() {
      return i18n.t("_.blocked");
    },
    to: {
      name: "adminFraudmindInvoices_blocked"
    }
  }
];
