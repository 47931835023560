import i18n from "@/i18n";
import type { ISorter } from "@/models/tables";
import _ from "@/boot/lodash";

export const InvoiceNumberSorter: () => ISorter = () => ({
  field: "id",
  label: i18n.t("_.invoice_number")
});

export const TotalAmountSorter: () => ISorter = () => ({
  field: "total_amount",
  label: i18n.t("_.total")
});

export const StatusSorter: () => ISorter = () => ({
  field: "status",
  label: i18n.t("_.status")
});

export const DateCreatedSorter: () => ISorter = () => ({
  field: "create_datetime",
  label: i18n.t("_.date_created")
});

export const DatePaidSorter: () => ISorter = () => ({
  field: "paid_datetime",
  label: i18n.t("_.date_paid")
});

export const DueDateSorter: () => ISorter = () => ({
  field: "due_date",
  label: i18n.t("_.due_date")
});

export const DateCancelledSorter: () => ISorter = () => ({
  field: "cancellation_datetime",
  label: i18n.t("_.date_cancelled")
});

export const DefaultInvoicesSorters: () => ISorter[] = () =>
  _.orderBy(
    [
      InvoiceNumberSorter(),
      TotalAmountSorter(),
      StatusSorter(),
      DateCreatedSorter(),
      DatePaidSorter(),
      DueDateSorter()
    ],
    ["label"],
    ["asc"]
  );

export const LegacyInvoicesSorters = () =>
  _.filter(DefaultInvoicesSorters(), ({ field }) =>
    ["id", "total_amount", "create_datetime"].includes(field as string)
  );
