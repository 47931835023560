import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import type { ApiPathGetter } from "@/models/api";
import { Methods } from "@/models/methods";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath: (): ApiPathGetter => () => {
    const admin = "api/admin/revenue_report";
    return { admin };
  },
  regenerateApiPath: (): ApiPathGetter => () => {
    const admin = "api/admin/revenue_report_current_month";
    return { admin };
  },
  scope: () => {
    return `$revenue_report`;
  }
};

const actions: ActionTree<IDataState, IState> = {
  get: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath().admin}`,
        storeModule: DataModules.REPORTS_REVENUE
      },
      { root: true }
    );
  },
  regenerateReport: ({ dispatch, getters }) => {
    return dispatch(
      "api/call",
      {
        method: Methods.POST,
        path: `${getters.regenerateApiPath().admin}`
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
