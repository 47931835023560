import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import { Methods } from "@/models/methods";
import type {
  IAffiliate,
  IAffiliatePayoutForm
} from "@upmind-automation/types";
import type { ApiPathGetter } from "@/models/api";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext }): ApiPathGetter =>
    ({ accountId }) => {
      const admin = `api/admin/accounts/${accountId}/affiliate`;
      const client = `api/accounts/${accountId}/affiliate`;
      const contextual = isAdminContext ? admin : client;
      return { client, admin, contextual };
    },
  scope: () => accountId => {
    return `$payouts_${accountId}`;
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `${getters.apiPath(payload).contextual}/payouts`,
        storeModule: DataModules.AFFILIATES_PAYOUTS,
        params: {
          with_staged_imports: 1,
          ...payload.params
        }
      },
      { root: true }
    );
  },
  openPayoutModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/global/affiliate/commissionPayoutModal.vue"
            ),
          ...payload
        }
      },
      { root: true }
    );
  },
  payout: (
    { dispatch },
    payload: {
      affiliate_account_id: IAffiliate["account_id"];
      data: IAffiliatePayoutForm;
    }
  ) => {
    const { affiliate_account_id, data } = payload;
    return dispatch(
      "data/callApi",
      {
        method: Methods.POST,
        path: `api/admin/affiliate_accounts/${affiliate_account_id}/payout`,
        storeModule: DataModules.AFFILIATES_PAYOUTS,
        requestConfig: { data }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
