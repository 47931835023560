import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import { Methods } from "@/models/methods";
import type { ApiPathGetter } from "@/models/api";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath: (): ApiPathGetter => () => {
    const admin = `api/admin/affiliate_tier`;
    return { admin };
  },
  scope: () => (tierId, conditionId) => {
    return `$affiliate_tier_${tierId}_condition_${conditionId}`;
  },
  listScope: () => tierId => {
    return `$affiliate_tier_${tierId}_conditions`;
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload?.id}/conditions`,
        storeModule: DataModules.AFFILIATES_TIERS_CONDITIONS
      },
      { root: true }
    );
  },
  get: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload?.tierId}/conditions/${
          payload?.conditionId
        }`,
        params: {
          with: "products"
        },
        storeModule: DataModules.AFFILIATES_TIERS_CONDITIONS
      },
      { root: true }
    );
  },
  create: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/create",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload?.id}/conditions`,
        storeModule: DataModules.AFFILIATES_TIERS_CONDITIONS
      },
      { root: true }
    );
  },
  update: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/update",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload?.tierId}/conditions/${
          payload?.id
        }`,
        storeModule: DataModules.AFFILIATES_TIERS_CONDITIONS
      },
      { root: true }
    );
  },
  remove: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/remove",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload?.tierId}/conditions/${
          payload?.id
        }`,
        storeModule: DataModules.AFFILIATES_TIERS_CONDITIONS
      },
      { root: true }
    );
  },
  openAddEditModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          canCancel: ["button", "x"],
          component: () =>
            import(
              "@/components/app/admin/settings/affiliates/conditions/addEditAffiliateTierConditionModal.vue"
            ),
          ...payload
        }
      },
      { root: true }
    );
  },
  attachSelectedProducts: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PATCH,
        path: `${getters.apiPath().admin}/${payload?.tierId}/conditions/${
          payload?.conditionId
        }/products`,
        storeModule: DataModules.AFFILIATES_TIERS_CONDITIONS,
        requestConfig: { data: payload?.data }
      },
      { root: true }
    );
  },
  detachSelectedProducts: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.DELETE,
        path: `${getters.apiPath().admin}/${payload?.tierId}/conditions/${
          payload?.conditionId
        }/products`,
        requestConfig: { data: payload?.data },
        storeModule: DataModules.AFFILIATES_TIERS_CONDITIONS
      },
      { root: true }
    );
  },
  reorderTierConditions: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PATCH,
        path: `${getters.apiPath().admin}/${
          payload?.tierId
        }/conditions_reorder`,
        requestConfig: { data: payload?.data },
        storeModule: DataModules.AFFILIATES_TIERS_CONDITIONS
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  modules: {
    products: require("./conditionProducts").default
  }
};
