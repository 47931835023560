import _ from "@/boot/lodash";
import store from "@/store";
import router from "@/router";
import { ToastProgrammatic as $toast } from "buefy";
import { Contexts } from "@upmind-automation/types";
import { AdminRoutes } from "@/data/enums/router";
import type { NavigationGuardNext, Route } from "vue-router";

const guardAdminAuthRoutes = async (
  to: Route,
  from: Route,
  next: NavigationGuardNext
) => {
  const allowAuthenticated = _.get(to.meta, "allowAuthenticated", false);
  if (store.getters["auth/admin/isAuthenticated"] && !allowAuthenticated) {
    store.dispatch("ui/endRouting");
    return next(
      to.query.redirect
        ? {
            // Replace '\\' from start to prevent attack vector
            path: (to.query.redirect as string).replace(/^\\+/, ""),
            replace: true
          }
        : !from.matched.length
        ? { name: "adminDashboard", replace: true }
        : false
    );
  }
  store.commit("user", {});
  next();
};

export default [
  {
    path: "/admin/auth/preferences",
    component: () => import("@/skeleton/admin/loggedOut/minimalLayout.vue"),
    beforeEnter: guardAdminAuthRoutes,
    children: [
      {
        path: "",
        alias: "/admin/preferences",
        component: () => import("@/views/admin/notifications/index.vue"),
        meta: {
          title: "_.notification_preferences",
          allowAuthenticated: false
        }
      }
    ]
  },
  {
    path: "/admin/auth",
    redirect: "/admin/login",
    component: () => import("@/skeleton/admin/loggedOut/index.vue"),
    beforeEnter: guardAdminAuthRoutes,
    children: [
      {
        path: "login",
        alias: "/admin/login",
        name: "adminLogin",
        component: () => import("@/views/admin/auth/login/index.vue"),
        meta: {
          title: "_.login",
          allowAuthenticated: false
        }
      },
      {
        path: "forgotten-password",
        alias: "/admin/forgotten-password",
        name: "adminForgottenPassword",
        component: () =>
          import("@/views/admin/auth/forgottenPassword/index.vue"),
        meta: {
          title: "_.recover_account",
          allowAuthenticated: false
        }
      },
      {
        path: "reset-password",
        name: "resetPassword",
        alias: "/admin/reset-password",
        component: () => import("@/views/admin/auth/resetPassword/index.vue"),
        meta: {
          title: "_.reset_password",
          allowAuthenticated: true
        }
      },
      {
        path: "verify",
        alias: "/admin/verify",
        component: () => import("@/views/admin/auth/verify/index.vue"),
        meta: {
          title: "_.verify_email",
          allowAuthenticated: false
        }
      }
    ]
  },
  {
    path: "/admin/logout",
    name: AdminRoutes.LOGOUT,
    beforeEnter(to: Route, from: Route, next: NavigationGuardNext) {
      const query = {};
      const fullPath = window.location.pathname + window.location.search;
      if (!fullPath.startsWith("/admin/logout")) query["redirect"] = fullPath;
      if (from.matched.length) {
        const message = _.get(to.params, "toast");
        if (message) $toast.open({ message });
      }
      next();
      router.replace({ query }).catch(err => err);
    },
    component: {
      name: "AdminLogout",
      async created() {
        await store.dispatch("auth/logout", Contexts.ADMIN);
        /** Here we call 'brand/get' again in case the staff user was operating
        in a brand different to that of the current oauth client (possible with
        multi-brand orgs). */
        await store.dispatch("brand/get", { ignoreStored: true });
        const query = router.currentRoute.query;
        router.replace({ name: "adminLogin", query }).catch(err => err);
      },
      render(h) {
        return h("loading", {
          props: {
            centered: true
          }
        });
      }
    }
  }
];
