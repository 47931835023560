// Fix the plugin showing for every error: {field} is not valid (https://github.com/logaretm/vee-validate/issues/2191)
import {
  extend,
  ValidationObserver,
  ValidationProvider
} from "vee-validate/dist/vee-validate.full.esm";
import {
  email,
  length,
  max,
  max_value,
  min,
  min_value,
  regex,
  required
} from "vee-validate/dist/rules";
import _ from "@/boot/lodash";
import isCreditCard from "validator/lib/isCreditCard";
import i18n from "@/i18n";
import moment from "moment";
import store from "@/store";
import type { CountryCode } from "libphonenumber-js/max";
import { isValidPhoneNumber } from "libphonenumber-js/max";

export default {
  install: (Vue: any) => {
    Vue.component("ValidationProvider", ValidationProvider);
    Vue.component("ValidationObserver", ValidationObserver);

    // import some existing validation methods
    extend("email", email);
    extend("required", required);
    extend("regex", regex);
    extend("length", length);
    extend("max_value", max_value);
    extend("min_value", min_value);
    extend("min", min);
    extend("max", max);

    // create new validation methods
    extend("credit_card", {
      validate: (value: any) => isCreditCard(String(value)),
      message: (value: any, args: Record<string, any>) => {
        return i18n.t("_validation.credit_card", { ...args }) as string;
      }
    });

    extend("is_ip", {
      validate: (value: any) => {
        // https://github.com/sindresorhus/ip-regex
        const regex =
          /(?:^(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}$)|(?:^((?:[a-fA-F\d]{1,4}:){7}(?:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){6}(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){5}(?::(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(:[a-fA-F\d]{1,4}){1,2}|:)|(?:[a-fA-F\d]{1,4}:){4}(?:(:[a-fA-F\d]{1,4}){0,1}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(:[a-fA-F\d]{1,4}){1,3}|:)|(?:[a-fA-F\d]{1,4}:){3}(?:(:[a-fA-F\d]{1,4}){0,2}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(:[a-fA-F\d]{1,4}){1,4}|:)|(?:[a-fA-F\d]{1,4}:){2}(?:(:[a-fA-F\d]{1,4}){0,3}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(:[a-fA-F\d]{1,4}){1,5}|:)|(?:[a-fA-F\d]{1,4}:){1}(?:(:[a-fA-F\d]{1,4}){0,4}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(:[a-fA-F\d]{1,4}){1,6}|:)|(?::((?::[a-fA-F\d]{1,4}){0,5}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,7}|:)))(%[0-9a-zA-Z]{1,})?$)/;
        return regex.test(value);
      },
      message: (value: any, args: Record<string, any>) => {
        return i18n.t("_validation.ip_address", { ...args }) as string;
      }
    });

    extend("min_password_reqs", {
      validate: (value: any) => value.match(/^(?=.*[\d])(?=.*[a-z]).{8,}$/),
      message: (value: any, args: Record<string, any>) => {
        return i18n.t("_validation.min_password_reqs", { ...args }) as string;
      }
    });

    extend("number", {
      validate: (value: any) => _.isNumber(value),
      message: (value: any, args: Record<string, any>) => {
        return i18n.t("_validation.number", { ...args }) as string;
      }
    });

    extend("url", {
      validate: (value: string) => {
        try {
          const url = new URL(value);
          if (!["http:", "https:"].includes(url.protocol)) return false;
          const rx = new RegExp(
            /^(?!^[\\.-])([A-Z0-9-]+)(\.[A-Z0-9\\.]{2,})/,
            "gim"
          );
          return !!rx.exec(url.hostname)?.length;
        } catch {
          return false;
        }
      },
      message: (value: any, args: Record<string, any>) => {
        return i18n.t("_validation.url", { ...args }) as string;
      }
    });

    extend("required_number", {
      ...required,
      validate: (value: any) => required.validate(value) && _.isNumber(value),
      message: (value: any, args: Record<string, any>) => {
        return i18n.t("_validation.required_number", { ...args }) as string;
      }
    });

    extend("date_format", {
      validate: (value: any, args: any[]) =>
        moment(value, args[0], true).isValid(),
      message: (field: any, args: Record<string, any>) => {
        return i18n.t("_validation.date_format", {
          format: args[0],
          ...args
        }) as string;
      }
    });

    extend("before", {
      validate: (value: any, args: any[]) => {
        const format = args[1];
        const before = moment(args[0], format, true);
        const date = moment(value, format, true);
        return before.isValid() && date.isValid() && date.isBefore(before);
      },
      message: (field: any, args: Record<string, any>) => {
        const format = args[1] as string;

        return i18n.t("_validation.before", {
          date: moment(args[0] as string, format, true).format(format),
          ...args
        }) as string;
      }
    });

    extend("after", {
      validate: (value: any, args: any[]) => {
        const format = args[1];
        const after = moment(args[0], format, true);
        const date = moment(value, format, true);
        return after.isValid() && date.isValid() && date.isAfter(after);
      },
      message: (field: any, args: Record<string, any>) => {
        const format = args[1] as string;

        return i18n.t("_validation.after", {
          date: moment(args[0] as string, format, true).format(format),
          ...args
        }) as string;
      }
    });
    extend("divisible_by", {
      validate: (value: any, args: any[]) => {
        return value % args[0] === 0;
      },
      message: (field: any, args: Record<string, any>) => {
        return i18n.t("_validation.divisible_by", {
          divisible_by: args[0],
          ...args
        }) as string;
      }
    });

    extend("phoneCountryCode", {
      validate: (value: any) => {
        return (
          value === "" ||
          !!_.find(store.state.constants.countries, country => {
            return (
              `${_.get(country, "name")} (${_.get(
                country,
                "phone_code"
              )})`.toLowerCase() === value.toLowerCase()
            );
          })
        );
      },
      message: (field: any, args: Record<string, any>) => {
        return i18n.t("_validation.phone_country_code", { ...args }) as string;
      }
    });

    extend("required_phone", {
      validate: (value: any, { countryCode }: { countryCode: CountryCode }) => {
        if (_.isEmpty(value)) return false;
        const valid = isValidPhoneNumber(value, countryCode);
        return valid;
      },
      message: (field: any, args: Record<string, any>) => {
        return i18n.t("_validation.required_phone", { ...args }) as string;
      },
      params: ["countryCode"]
    });

    extend("is_not_multiple", {
      validate: (value: any, args: any[]) => {
        return !args.includes(value);
      },
      message: (value: any, args: Record<string, any>) => {
        return i18n.t("_validation.unique", { ...args }) as string;
      }
    });

    extend("is_valid_json", {
      validate: (value: any) => {
        try {
          if (typeof value !== "object") return false;
          JSON.stringify(value);
          return true;
        } catch (error) {
          return false;
        }
      },
      message: (value: any, args: Record<string, any>) => {
        return i18n.t("_validation.is_valid_json", { ...args }) as string;
      }
    });
  }
};
