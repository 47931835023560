import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree } from "vuex";
import type { IState } from "@/store";
import { Methods } from "@/models/methods";

const initialState = {} as IDataState;

export const actions: ActionTree<IDataState, IState> = {
  update: ({ dispatch }, { contractId, cProdId, data }) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PATCH,
        path: `api/admin/contracts/${contractId}/products/${cProdId}/retention_status`,
        storeModule: DataModules.RETENTIONS,
        requestConfig: {
          data
        }
      },
      { root: true }
    );
  },

  changeStatus: ({ dispatch }, { contractId, cProdId, retentionStatusId }) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PATCH,
        path: `api/admin/contracts/${contractId}/products/${cProdId}/retention_status`,
        storeModule: DataModules.RETENTIONS,
        requestConfig: { data: { retention_status_id: retentionStatusId } }
      },
      { root: true }
    );
  },

  changeReason: ({ dispatch }, { contractId, cProdId, retentionReasonId }) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PATCH,
        path: `api/admin/contracts/${contractId}/products/${cProdId}/retention_status`,
        storeModule: DataModules.RETENTIONS,
        requestConfig: { data: { retention_reason_id: retentionReasonId } }
      },
      { root: true }
    );
  },

  openUpdateRetentionModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/admin/retentions/updateRetentionModal.vue"
            ),
          width: 480,
          ...payload
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  modules: {
    logs: require("./logs").default,
    reasons: require("./reasons").default
  }
};
