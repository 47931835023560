<template>
  <guard :if-client="true" :if-admin="guardFunctionalities">
    <div class="is-relative">
      <b-loading :active="$emailHistoryData.isSending" :is-full-page="false" />

      <div class="has-margin-bottom-100">
        <u-message type="is-info" icon="info-circle">
          <u-i18n path="_sentence.email_history_log_delay_warning_msg" />
        </u-message>
      </div>

      <filter-sort-controls
        v-if="
          $emailHistoryData.enableFiltering || $emailHistoryData.enableSorting
        "
        :active-filters="$emailHistoryData.activeFilters"
        :enable-filtering="$emailHistoryData.enableFiltering"
        :enable-sorting="$emailHistoryData.enableSorting"
        :filters="$emailHistoryData.filters"
        :sorters="$emailHistoryData.sorters"
        :table="table"
        class="has-margin-bottom-100"
        @onRefresh="$emailMethods.reloadData"
        @onFiltersChange="$emailMethods.onFiltersChange"
        @onSort="$emailMethods.onSort"
      />

      <u-ripple-table
        :data="table.data.length ? table.data : Array(5)"
        :is-loading="table.loading"
        :is-paginated="table.paginated"
        :is-hoverable="!!table.data.length"
        :limit="table.limit"
        :total="table.total"
        :current-page="table.page"
        @page-change="$emailMethods.onPageChange"
        @limit-change="$emailMethods.onLimitChange"
      >
        <template #emptyMessage>
          <u-i18n path="_sentence.no_email_history_msg" />
        </template>

        <template slot="thead">
          <tr>
            <th>{{ $t("_.subject") }}</th>
            <th class="has-text-right">{{ $t("_.status") }}</th>
            <th v-if="showManageControls" />
          </tr>
        </template>

        <template v-if="table.loading || !table.data.length" v-slot="{ index }">
          <tr :key="`skeleton-row-${index}`">
            <td>
              <span v-if="$emailHistoryData.withRecipient" class="is-flex">
                <span style="width: 2.5rem" class="has-margin-right-50">
                  <b-skeleton
                    :animated="table.loading"
                    width="2.5rem"
                    height="2.5rem"
                    circle
                  />
                </span>
                <span>
                  <b-skeleton :animated="table.loading" width="8rem" />
                  <b-skeleton :animated="table.loading" width="12rem" />
                </span>
              </span>
              <b-skeleton v-else :animated="table.loading" width="8rem" />
            </td>
            <td class="is-compact has-text-right">
              <b-skeleton :animated="table.loading" width="6rem" />
              <b-skeleton :animated="table.loading" width="8rem" />
            </td>
            <template v-if="showManageControls">
              <td class="is-compact">
                <b-skeleton :animated="table.loading" width="1rem" circle />
              </td>
            </template>
          </tr>
        </template>

        <template v-else v-slot="{ row: email }">
          <component
            :is="rowTag"
            :key="`email-${email.id}`"
            v-bind="rowProps(email)"
            class="is-size-7"
            v-on="rowEvents(email)"
          >
            <td custom-key="subject">
              <template v-if="$emailHistoryData.withRecipient">
                <p class="is-flex has-items-aligned-center">
                  <with :data="recipientData(email)" slot-prop="recipient">
                    <template v-slot:default="{ recipient }">
                      <avatar
                        :size="40"
                        :name="$_.get(recipient, 'name', '')"
                        :email="$_.get(recipient, 'email', '')"
                        :src="$_.get(recipient, 'imageUrl')"
                        class="has-margin-right-50"
                      >
                        <email-history-status
                          slot="bottom-left"
                          :email="email"
                        />
                      </avatar>
                      <span>
                        <p>
                          <strong>{{ email.subject }}</strong>
                        </p>

                        <u-i18n tag="p" path="_.to_recipient">
                          <template #recipient>
                            <template v-if="isAdmin && $_.get(recipient, 'to')">
                              <u-link
                                :to="recipient.to"
                                @click.native.stop.prevent
                                >{{ email.to }}</u-link
                              >
                            </template>
                            <template v-else>{{ email.to }}</template>
                          </template>
                        </u-i18n>
                      </span>
                    </template>
                  </with>
                </p>
              </template>
              <p v-else>
                <strong>{{ email.subject }}</strong>
              </p>
            </td>

            <td class="is-compact has-text-right">
              <!-- Error -->
              <template v-if="isError(email)">
                <u-i18n
                  tag="strong"
                  path="_.send_failed"
                  class="has-text-danger"
                />
                <p>
                  <datetime
                    :key="email.updated_at"
                    :datetime="email.updated_at"
                    format-function="calendar"
                    :format-function-params="
                      $store.getters['utils/time/formatFunctionCalendar'](
                        email.updated_at,
                        {
                          sameElse: () => $t('_datetime.date.default')
                        }
                      )
                    "
                  />
                </p>
              </template>

              <!-- Bounced -->
              <template v-else-if="isBounced(email)">
                <u-i18n
                  tag="strong"
                  path="_.email_bounced"
                  class="has-text-warning"
                />
                <p>
                  <datetime
                    :key="email.bounced_at"
                    :datetime="email.bounced_at"
                    format-function="calendar"
                    :format-function-params="
                      $store.getters['utils/time/formatFunctionCalendar'](
                        email.bounced_at,
                        {
                          sameElse: () => $t('_datetime.date.default')
                        }
                      )
                    "
                  />
                </p>
              </template>

              <!-- Sent -->
              <template v-else-if="isSent(email)">
                <u-i18n
                  tag="strong"
                  path="_.email_sent"
                  class="has-text-success"
                />
                <p>
                  <datetime
                    :key="email.sent_at"
                    format-function="calendar"
                    :format-function-params="
                      $store.getters['utils/time/formatFunctionCalendar'](
                        email.sent_at,
                        {
                          sameElse: () => $t('_datetime.date.default')
                        }
                      )
                    "
                    :datetime="email.sent_at"
                  />
                </p>
              </template>

              <!-- Sending -->
              <span v-else class="has-text-grey">
                <i class="fas fa-spinner fa-spin has-margin-right-25" />
                <u-i18n path="_.sending" />&hellip;
              </span>
            </td>

            <template v-if="showManageControls">
              <td class="is-compact has-padding-x-0" @click.prevent.stop>
                <b-icon v-if="!isAdmin" icon="chevron-right" size="is-small" />

                <b-dropdown
                  v-else
                  :mobile-modal="false"
                  position="is-bottom-left"
                >
                  <template #trigger>
                    <b-button icon-right="cog" type="is-white" />
                  </template>

                  <!-- View email -->
                  <template v-if="showViewControl">
                    <b-dropdown-item @click="$emailMethods.viewEmail(email.id)">
                      <u-i18n path="_action.view" />
                    </b-dropdown-item>
                  </template>

                  <template v-if="isAdmin">
                    <!-- Re-try failed email -->
                    <u-i18n
                      v-if="
                        email.error_message && $emailHistoryData.canSendEmail
                      "
                      tag="b-dropdown-item"
                      path="_action.retry_send"
                      @click="$emailMethods.sendEmail(email.id)"
                    />

                    <!-- Resend sent email -->
                    <u-i18n
                      v-else-if="isAdmin && $emailHistoryData.canResendEmail"
                      tag="b-dropdown-item"
                      path="_action.resend"
                      @click="$emailMethods.resendEmail(email.id)"
                    />
                  </template>
                </b-dropdown>
              </td>
            </template>
          </component>
        </template>
      </u-ripple-table>
    </div>
  </guard>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Context from "@/mixins/context";
import { DataModules } from "@/store/modules/data/modules";
import { SentEmailStatus, RecipientTypeCodes } from "@/data/enums/emails";
import type { PropType } from "vue";
import type {
  IEmailsProviderData
  // IEmailsProviderMethods
} from "@/models/providers/emails";
import type { ISentEmail } from "@/models/emails";
import type { IClient } from "@upmind-automation/types";
import type { ILead } from "@/models/leads";
import type { IUser } from "@/models/users";

type IRecipientWithImage = {
  image: {
    full_url;
  };
};

export default defineComponent({
  name: "EmailHistoryTable",
  components: {
    "email-history-status": () =>
      import("@/components/app/global/emailHistory/emailHistoryStatus.vue")
  },
  mixins: [Context],
  inject: {
    emailHistoryProvider: { from: "emailHistoryProvider" },
    $emailMethods: { from: "emailHistoryMethods" }
  },
  props: {
    rowTag: { type: String, default: "router-link" },
    rowProps: {
      type: Function as PropType<
        (email: ISentEmail) => { [key: string]: string }
      >,
      default: (email: ISentEmail) => ({
        to: {
          name: "adminEmailLog",
          params: { emailId: email.id }
        }
      })
    },
    rowEvents: {
      type: Function as PropType<
        (email: ISentEmail) => { [key: string]: Function }
      >,
      default: () => {}
    }
  },
  computed: {
    $emailHistoryData(): IEmailsProviderData {
      return this.emailHistoryProvider();
    },
    emailType() {
      return this.$emailHistoryData.emailType;
    },
    table() {
      return this.$emailHistoryData.table;
    },
    guardFunctionality() {
      return this.$emailHistoryData.guardFunctionality;
    },
    guardFunctionalities() {
      return [this.guardFunctionality];
    },
    showViewControl() {
      return this.$userCan("get_email_message");
    },
    showManageControls() {
      return this.$userCan("resend_email_message") || this.showViewControl;
    }
  },
  methods: {
    isError(email: ISentEmail) {
      return (
        this.$store.getters[`data/${DataModules.EMAILS}/status`](email) ===
        SentEmailStatus.ERROR
      );
    },
    isBounced(email: ISentEmail) {
      return (
        this.$store.getters[`data/${DataModules.EMAILS}/status`](email) ===
        SentEmailStatus.BOUNCED
      );
    },
    isSent(email: ISentEmail) {
      return (
        this.$store.getters[`data/${DataModules.EMAILS}/status`](email) ===
        SentEmailStatus.SENT
      );
    },
    recipientIsClient(email: ISentEmail) {
      return [
        RecipientTypeCodes.CLIENT,
        RecipientTypeCodes.CLIENT_WITH_LOGIN,
        RecipientTypeCodes.CLIENT_WITHOUT_LOGIN
      ].includes(email.recipient_type?.code as RecipientTypeCodes);
    },
    recipientIsUser(email: ISentEmail) {
      return [RecipientTypeCodes.USER].includes(
        email.recipient_type?.code as RecipientTypeCodes
      );
    },
    recipientIsLead(email: ISentEmail) {
      return [RecipientTypeCodes.LEAD].includes(
        email.recipient_type?.code as RecipientTypeCodes
      );
    },
    recipientData(email: ISentEmail) {
      const client = email.recipient as IClient & IRecipientWithImage;
      const user = email.recipient as IUser & IRecipientWithImage;
      const lead = email.recipient as ILead & IRecipientWithImage;

      if (this.recipientIsClient(email))
        return {
          name: client?.fullname,
          email: client?.email,
          imageUrl: client?.image?.full_url,
          to: {
            name: "adminClientEmailHistory",
            params: { clientId: email.recipient_id }
          }
        };
      else if (this.recipientIsUser(email))
        return {
          name: user?.fullname,
          email: user?.email,
          imageUrl: user?.image?.full_url,
          to: {
            name: "adminUserEmailHistory",
            params: { userId: email.recipient_id }
          }
        };
      else if (this.recipientIsLead(email))
        return {
          name: lead?.name,
          email: lead?.email,
          imageUrl: lead?.image?.full_url,
          to: { name: "adminLead", params: { leadId: email.recipient_id } }
        };
    }
  }
});
</script>

<style lang="scss" scoped>
.table-wrapper tr:last-of-type .email-status > div:after {
  display: none;
}
</style>
