<template>
  <columns>
    <column>
      <card :is-paddingless="true" :title="title()">
        <div class="has-padding-50">
          <menu-component :routes="routes" background="light" />
        </div>
      </card>
    </column>
    <template v-if="showSupportPin">
      <column>
        <box>
          <support-pin-view />
        </box>
      </column>
    </template>
  </columns>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapState } from "vuex";
import i18n from "@/i18n";
import { BrandConfigKeys } from "@/data/constants";
import type { PropType } from "vue";
import type { IState } from "@/store";

export default defineComponent({
  name: "ClientSupportMenu",
  components: {
    "support-pin-view": () =>
      import("@/components/app/client/clientSupportPinView.vue")
  },
  props: {
    title: {
      type: Function as PropType<() => string>,
      default: () => i18n.t("_.menu")
    },
    routes: { type: Array as PropType<any[]>, required: true }
  },
  computed: {
    ...mapState({
      showSupportPin(state: IState): boolean {
        return state.brand.config[BrandConfigKeys.SUPPORT_PIN_ENABLED];
      }
    })
  }
});
</script>
