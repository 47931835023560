export enum EmailTypes {
  ACCOUNT = "account",
  BRAND = "brand",
  CLIENT = "client",
  LEAD = "lead",
  MYSELF = "myself",
  USER = "user"
}

export enum SentEmailStatus {
  SENT = "sent",
  BOUNCED = "bounced",
  ERROR = "error",
  SENDING = "sending"
}

export enum RecipientTypeCodes {
  BRAND = "brand",
  CLIENT = "client",
  CLIENT_WITH_LOGIN = "client_with_login",
  CLIENT_WITHOUT_LOGIN = "client_without_login",
  LEAD = "lead",
  USER = "user"
}
