import { AdminRoutes, RegexMatch } from "@/data/enums/router";
import { DocCategories } from "@/data/enums/docs";
import store from "@/store";
import type { CreateElement } from "vue";
import type { Route } from "vue-router";

export default [
  {
    path: "support",
    components: {
      default: {
        render(h: CreateElement) {
          return h("router-view", {
            key: store.state.ui.displayMode
          });
        }
      }
    },
    children: [
      {
        path: "",
        name: "adminSupport",
        meta: {
          title: "_.tickets"
        },
        redirect: "inbox"
      },
      {
        path: "inbox",
        name: "adminSupportInbox",
        component: () => import("@/views/admin/support/inbox/index.vue"),
        meta: {
          title: "_.inbox",
          categorySlug: DocCategories.USING_UPMIND,
          docsSlugs: ["service-desk"]
        },
        props: {
          saveInStorage: true,
          ignoreHistory: true
        },
        children: [
          {
            name: AdminRoutes.SUPPORT_TICKET,
            path: `:ticketId(${RegexMatch.UUID})`,
            component: () =>
              import("@/views/admin/support/inbox/ticket/index.vue"),
            meta: {
              title: "_.ticket_ref"
            },
            props: true
          },
          {
            path: `:ticketId(${RegexMatch.UUID})`,
            name: "admin__supportTicketModal",
            beforeEnter: async (to: Route, from: Route, next: any) => {
              const { ticketId } = to.params;
              if (ticketId) {
                store.dispatch("ui/open/slideModal", {
                  config: {
                    component: () =>
                      import("@/components/app/global/tickets/ticketModal.vue"),
                    width: 480,
                    canCancel: ["button"],
                    props: to.params
                  }
                });
              }
              store.dispatch("ui/endRouting");
              return next(false);
            }
          }
        ]
      },
      {
        path: "*",
        component: () => import("@/views/error/index.vue"),
        props: { code: 404 }
      }
    ]
  }
];
