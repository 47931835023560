<template>
  <cprods-provider
    :handle-loading="false"
    :limit="10"
    v-bind="{
      accountId,
      availableFilters,
      availableSorters,
      clientId,
      dataScope,
      id,
      ignoreHistory,
      isStaged,
      manualFilters,
      query,
      useOnlyAvailableFilters
    }"
  >
    <template v-slot:default="{ $cProdsData, $cProdsMethods }">
      <div class="has-margin-bottom-50">
        <template v-if="showQuickSearch">
          <div class="field is-grouped">
            <div class="control is-expanded">
              <b-input
                v-focus="$store.getters['ui/isTabletPlus']"
                :value="$cProdsData.searchQuery"
                :placeholder="$t('_placeholder.product_quick_search')"
                :icon-right="$cProdsData.searchQuery ? 'close-circle' : null"
                :icon-right-clickable="true"
                @icon-right-click="$cProdsMethods.doQuickSearch()"
                @input="$cProdsData.debounceSearch"
              />
            </div>
          </div>
        </template>
        <template
          v-if="$cProdsData.enableFiltering || $cProdsData.enableSorting"
        >
          <filter-sort-controls
            :active-filters="$cProdsData.activeFilters"
            :enable-filtering="$cProdsData.enableFiltering"
            :enable-sorting="$cProdsData.enableSorting"
            :filters="$cProdsData.availableFilters"
            :sorters="$cProdsData.availableSorters"
            :pre-defined-filters="$cProdsData.predefinedFilters"
            :table="$cProdsData.table"
            class="has-margin-bottom-100"
            @onRefresh="$cProdsMethods.refreshListing"
            @onFiltersChange="$cProdsMethods.onFiltersChange"
            @onSort="$cProdsMethods.onSort"
          >
            <!-- Display Mode -->
            <template v-if="!forceDisplayMode" #control>
              <u-list-display-modes
                v-model="displayMode"
                :storage-path="displayModeStoragePath"
              />
            </template>
            <!-- Refresh Control -->
            <template
              v-if="isAdmin && (showBulkUpdateAction || showCreateSegment)"
              #controlRefresh
            >
              <b-dropdown
                :mobile-modal="false"
                position="is-bottom-left"
                @click.native.stop
                @click.prevent
              >
                <b-button slot="trigger" icon-left="ellipsis-v" />
                <!-- Add segment -->
                <template v-if="showCreateSegment">
                  <add-segment-message
                    :active-filters="$cProdsData.activeFilters"
                    :pre-defined-filters="$cProdsData.predefinedFilters"
                    :api-props="{ with: $cProdsData.withParam }"
                    :filters="$cProdsData.availableFilters"
                    :segment-type="segmentType"
                  />
                </template>
                <!-- Schedule action -->
                <template
                  v-if="
                    showBulkUpdateAction &&
                    !isMultibrand &&
                    !isOneTimeType($cProdsData.activeFilters)
                  "
                >
                  <cprod-schedule-action-action
                    :active-filters="$cProdsData.activeFilters"
                    :pre-defined-filters="$cProdsData.predefinedFilters"
                    :filters="$cProdsData.availableFilters"
                    :query="$cProdsData.searchQuery"
                    :total="$cProdsData.table.total"
                    @refresh="$cProdsMethods.refreshListing"
                  />
                </template>
              </b-dropdown>
            </template>
          </filter-sort-controls>
        </template>
        <cprods-view-switcher
          :display-mode="displayModeComputed"
          :is-selectable="isSelectable"
          :is-multiselect="isMultiselect"
          :selected-ids="newSelectedIds"
          :action="action"
          :row-events="rowEvents"
          :show-table-head="showTableHead"
          :show-when-purchased="showWhenPurchased"
          @selectProduct="selectProduct"
        >
          <template v-if="$slots['dropdown-items']" #dropdown-items>
            <slot name="dropdown-items" />
          </template>
        </cprods-view-switcher>
        <u-pagination
          :current.sync="$cProdsData.page"
          :per-page="$cProdsData.limit"
          :total="$cProdsData.total"
          :default-limit="$cProdsData.defaults.limit"
          :visible-total="$cProdsData.table.data.length"
          @change-page="$cProdsMethods.onPageChange"
          @change-per-page="$cProdsMethods.onLimitChange"
        />
      </div>
    </template>
  </cprods-provider>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
import { UserMetaKeys } from "@/data/enums/users";
import { ListDisplayMode } from "@/data/constants";
import _ from "@/boot/lodash";
import Context from "@/mixins/context";
import { DEFAULT_LIST_SCOPE } from "@/store/modules/data";
import { mapGetters } from "vuex";
import { DefaultCProdFilters } from "@/data/filters/contractProducts";
import { DefaultCProdSorters } from "@/data/sorters/contractProducts";
import { SegmentTypes } from "@/data/enums/segments";
import type { TranslateResult } from "vue-i18n";
import type { IClient } from "@upmind-automation/types";
import type { IFilter, ISorter } from "@/models/tables";
import type { IContractProduct } from "@/models/contracts";

const CProdsListing = defineComponent({
  name: "CProdsListing",
  components: {
    "cprods-provider": () =>
      import("@/components/app/global/contractProducts/cProdsProvider.vue"),
    "add-segment-message": () =>
      import("@/components/app/admin/segments/addSegmentMessage.vue"),
    "cprod-schedule-action-action": () =>
      import(
        "@/components/app/global/contractProducts/cProdScheduleActionAction.vue"
      ),
    "cprods-view-switcher": () =>
      import("@/components/app/global/contractProducts/cProdsViewSwitcher.vue")
  },
  mixins: [Context],
  props: {
    id: { type: String, default: undefined },
    dataScope: {
      type: String,
      default: DEFAULT_LIST_SCOPE
    },
    clientId: { type: String as PropType<IClient["id"]>, default: null },
    client: { type: Object as PropType<IClient>, default: undefined },
    accountId: { type: String as PropType<IClient["id"]>, default: null },
    action: { type: String as PropType<string | TranslateResult>, default: "" },
    ignoreHistory: { type: Boolean, default: false },
    routeFilters: {
      type: Function as PropType<
        (vm: any) => Record<string, string | number | boolean>
      >,
      default: () => {}
    },
    parentFilters: {
      type: Object as PropType<Record<string, string | number | boolean>>,
      default: () => ({})
    },
    availableFilters: {
      type: Function as PropType<(vm?: typeof CProdsListing) => IFilter[]>,
      default: () => DefaultCProdFilters()
    },
    availableSorters: {
      type: Function as PropType<(vm?: typeof CProdsListing) => ISorter[]>,
      default: () => DefaultCProdSorters()
    },
    rowEvents: {
      type: Function as PropType<
        (contractProduct: IContractProduct) => {
          [key: string]: Function;
        }
      >,
      default: () => ({})
    },
    showTableHead: { type: Boolean, default: true },
    showWhenPurchased: { type: Boolean, default: true },
    forceDisplayMode: {
      type: String as PropType<ListDisplayMode>,
      default: undefined
    },
    isSelectable: { type: Boolean, default: false },
    isMultiselect: { type: Boolean, default: true },
    selectedIds: {
      type: Array as PropType<IContractProduct["id"][]>,
      default: () => []
    },
    query: { type: String, default: "" },
    showQuickSearch: { type: Boolean, default: true },
    showBulkUpdateAction: { type: Boolean, default: true },
    showCreateSegmentAction: { type: Boolean, default: true },
    segmentType: {
      type: String as PropType<SegmentTypes>,
      default: SegmentTypes.CONTRACTS_PRODUCTS
    },
    useOnlyAvailableFilters: { type: Boolean, default: false }
  },
  data: () => ({
    displayModeStoragePath: UserMetaKeys.UI_CPRODS_VIEW,
    displayMode: ListDisplayMode.GRID,
    newSelectedIds: [] as IContractProduct["id"][]
  }),
  computed: {
    ...mapGetters({
      isMultibrand: "brand/isMultibrand"
    }),
    displayModeComputed() {
      return this.forceDisplayMode || this.displayMode;
    },
    manualFilters() {
      return {
        ...this.parentFilters,
        ...this.routeFilters(this)
      };
    },
    isStaged() {
      return this.client?.staged_import ?? false;
    },
    showCreateSegment() {
      return this.showCreateSegmentAction && !this.clientId;
    }
  },
  created() {
    this.newSelectedIds = _.cloneDeep(this.selectedIds);
  },
  methods: {
    selectProduct(cProd, isSelected) {
      if (!isSelected) {
        this.newSelectedIds = _.filter(
          this.newSelectedIds,
          id => id !== cProd.id
        );
      } else if (!_.some(this.newSelectedIds, id => id === cProd.id)) {
        if (!this.isMultiselect) this.newSelectedIds = [];
        this.newSelectedIds.push(cProd.id);
      }

      this.$emit("updateSelectedIds", this.newSelectedIds);
    },
    isOneTimeType(activeFilters: IFilter[]) {
      const hasActiveOneTime = _.some(
        activeFilters,
        filter => filter.key === "billing_cycle_months" && filter.value === "0"
      );
      const hasRouterOneTime = _.some(
        this.routeFilters(this),
        (filterValue, filterKey) =>
          filterKey === "filter[billing_cycle_days]" && filterValue === 0
      );

      return hasActiveOneTime || hasRouterOneTime;
    }
  }
});

export default CProdsListing;
</script>
