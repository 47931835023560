import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import { Methods } from "@/models/methods";
import type { ApiPathGetter } from "@/models/api";

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (): ApiPathGetter =>
    ({ importId, stepId }) => {
      const admin = `api/admin/imports/${importId}/steps/${stepId}/mappings`;
      return { admin };
    }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, { importId, stepId, payload }) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath({ importId, stepId }).admin,
        storeModule: DataModules.IMPORT_MAPPINGS,
        params: {
          with: ["object"].join(),
          limit: 0,
          with_staged_imports: 1,
          ...payload.params
        }
      },
      { root: true }
    );
  },
  skip: ({ dispatch, getters }, { importId, stepId, payload }) => {
    return dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.PATCH,
        path: `${getters.apiPath({ importId, stepId }).admin}/skip`
      },
      { root: true }
    );
  },
  update: ({ dispatch, getters }, { importId, stepId, mappingId, payload }) => {
    return dispatch(
      "data/update",
      {
        ...payload,
        path: `${getters.apiPath({ importId, stepId }).admin}/${mappingId}`,
        storeModule: DataModules.IMPORT_MAPPINGS
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: {},
  getters,
  actions
};
