import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import { Methods } from "@/models/methods";
import type { ApiPathGetter } from "@/models/api";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext }): ApiPathGetter =>
    () => {
      const admin = "api/admin/import";
      const client = "";
      const contextual = isAdminContext ? admin : client;
      return { client, admin, contextual };
    }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `${getters.apiPath().admin}/dynamic_files`,
        // path: `${getters.apiPath().admin}/files`,
        storeModule: DataModules.IMPORTS_FILES
      },
      { root: true }
    );
  },
  syncImportStepImages: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PUT,
        path: `${getters.apiPath().admin}/dynamic_files/import_step/${
          payload.stepId
        }`,
        storeModule: DataModules.IMPORTS_FILES,
        requestConfig: { data: payload.data }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
