import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import { Methods } from "@/models/methods";
import type { IState } from "@/store";
import OpenAutoPayModal from "@/components/app/global/payments/openAutoPayModal.vue";
import OpenInitPayModal from "@/components/app/global/payments/openInitPayModal.vue";
import Vue from "vue";

import type { ApiPathGetter } from "@/models/api";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext, isMockClientContext }): ApiPathGetter =>
    (slug = "payments") => {
      const admin = `api/admin/${slug}`;
      const client = `api/${slug}`;
      const contextual =
        isAdminContext && !isMockClientContext() ? admin : client;
      return { client, admin, contextual };
    },
  scope: () => id => {
    return `$payment${id}`;
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath("payment_logs").admin,
        storeModule: DataModules.PAYMENTS
      },
      { root: true }
    );
  },
  create: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/create",
      {
        ...payload,
        path: getters.apiPath().contextual,
        storeModule: DataModules.PAYMENTS
      },
      { root: true }
    );
  },
  manualPayment: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.POST,
        path: `${getters.apiPath().admin}/manual`,
        requestConfig: { data: payload.data },
        storeModule: DataModules.PAYMENTS
      },
      { root: true }
    );
  },
  fetchPaymentInstructions: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: getters.apiPath(
          `gateway/frontend/instructions/${payload.transactionId}`
        ).contextual,
        storeModule: DataModules.PAYMENTS
      },
      { root: true }
    );
  },
  openMercadoPagoSCAChallengeModal: ({ dispatch }, props) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(
        "ui/open/windowModal",
        {
          config: {
            canCancel: ["button"],
            component: () =>
              import(
                "@/components/app/global/payments/brandGateways/mercadoPago/scaChallengeModal.vue"
              ),
            width: 520,
            props,
            onCancel: () => reject(),
            events: {
              success: () => resolve()
            }
          }
        },
        { root: true }
      );
    });
  },
  openPaymentInstructionsModal: ({ dispatch }, transactionId) => {
    return dispatch(
      "ui/open/windowModal",
      {
        config: {
          canCancel: ["button"],
          component: () =>
            import(
              "@/components/app/global/payments/paymentInstructionsModal.vue"
            ),
          width: 480,
          props: {
            transactionId
          }
        }
      },
      { root: true }
    );
  },
  refundTransactions: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.POST,
        path: `${getters.apiPath().admin}/refund_transactions`,
        requestConfig: { data: payload.data },
        storeModule: DataModules.PAYMENTS
      },
      { root: true }
    );
  },
  openAutoPayModal: (ctx, payload) => {
    const OpenAutoPayModalClass = Vue.extend(OpenAutoPayModal);
    const openAutoPayModal = new OpenAutoPayModalClass({
      parent: window.$rootVue,
      propsData: {
        ...payload.props
      }
    });
    openAutoPayModal.$on("close", () => {
      openAutoPayModal.$destroy();
    });
    openAutoPayModal.$mount();
  },
  openInitPayModal: (ctx, payload) => {
    const OpenInitPayModalClass = Vue.extend(OpenInitPayModal);
    const component = new OpenInitPayModalClass({
      parent: window.$rootVue,
      propsData: {
        ...payload.props
      }
    });
    component.$on("close", () => {
      component.$destroy();
    });
    component.$mount();
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
