import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  scope: () => (tierId, conditionId) => {
    return `$affiliate_tier_${tierId}_condition_${conditionId}_products`;
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: "api/admin/products",
        storeModule: DataModules.AFFILIATES_TIERS_CONDITIONS_PRODUCTS
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
