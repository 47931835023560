import type { IDataState } from "@/store/modules/data";
import { DEFAULT_LIST_SCOPE } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import _ from "@/boot/lodash";
import i18n from "@/i18n";
import type { ActionTree, GetterTree } from "vuex";
import { ConfirmModalModes } from "@/data/enums";
import type { ApiPathGetter } from "@/models/api";
import type { IState } from "@/store";
import { OrgPackageLimits, OrgFeatureKeys } from "@/data/constants";
import { ActionTypes } from "@/data/enums";
import { Methods } from "@/models/methods";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath: (): ApiPathGetter => () => {
    const admin = "api/admin/provision/configurations";
    return { admin };
  },
  scope: () => {
    return `$provision_configurations`;
  },
  configurationScope: () => configurationId => {
    return `$provision_configuration_${configurationId}`;
  },
  params: (state: IDataState) => () => {
    return _.get(state, `${DEFAULT_LIST_SCOPE}.params`, {});
  },
  getTotal: (state: IDataState) => () => {
    return _.get(state, `${DEFAULT_LIST_SCOPE}.total`, 0);
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `${getters.apiPath().admin}`,
        storeModule: DataModules.PROVISIONING_CONFIGURATIONS
      },
      { root: true }
    );
  },

  get: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${_.get(payload, "id")}`,
        storeModule: DataModules.PROVISIONING_CONFIGURATIONS,
        params: {
          with: "category,provider,attribute_values"
        }
      },
      { root: true }
    );
  },

  create: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/create",
      {
        ...payload,
        path: getters.apiPath().admin,
        storeModule: DataModules.PROVISIONING_CONFIGURATIONS
      },
      { root: true }
    );
  },

  update: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/update",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${_.get(payload, "id")}`,
        storeModule: DataModules.PROVISIONING_CONFIGURATIONS
      },
      { root: true }
    );
  },

  openConfirmDeleteModal: async (
    { dispatch, getters },
    {
      configurationId,
      message = i18n.t("_sentence.delete_provision_configuration_confirmation"),
      successMessage = i18n.t(
        "_sentence.provision_configuration_successfully_deleted"
      ),
      onSuccess
    }
  ) => {
    const confirm = await dispatch(
      "ui/open/confirmModal",
      {
        config: {
          props: {
            mode: ConfirmModalModes.BUTTON,
            autoClose: false,
            title: i18n.t("_.delete_confirmation"),
            confirmButtonText: i18n.t("_action.delete"),
            message
          },
          canCancel: [],
          events: {
            confirmed: async () => {
              try {
                await dispatch(
                  "data/remove",
                  {
                    path: `${getters.apiPath().admin}/${configurationId}`,
                    storeModule: DataModules.PROVISIONING_CONFIGURATIONS
                  },
                  { root: true }
                );

                dispatch(
                  "toast/show",
                  { message: successMessage },
                  { root: true }
                );
                onSuccess();
              } catch (error) {
                dispatch("api/handleError", error, { root: true });
              } finally {
                confirm.close();
              }
            }
          }
        }
      },
      { root: true }
    );
  },

  openManageProviderConfigurationModal: (
    { dispatch, rootGetters },
    payload
  ) => {
    if (!payload.actionType || payload.actionType === ActionTypes.CREATE) {
      const hasReachedLimit = rootGetters["org/hasReachedLimit"](
        OrgPackageLimits.PROVISION_CONFIGURATIONS,
        payload.total
      );

      if (hasReachedLimit)
        return dispatch(
          "org/openUpgradeModal",
          {
            props: {
              missingKeys: [OrgFeatureKeys.UNLIMITED_PROVISION_CONFIGURATIONS]
            }
          },
          { root: true }
        );
    }

    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/admin/settings/provisioning/manageProviderConfigurationModal.vue"
            ),
          ...payload
        }
      },
      { root: true }
    );
  },

  openMergeProviderConfigurationModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/admin/settings/provisioning/mergeProviderConfigurationsModal.vue"
            ),
          ...payload
        }
      },
      { root: true }
    );
  },

  mergeConfigurations: async (
    { dispatch, getters },
    {
      configurationId,
      oldConfigurationId,
      message,
      successMessage = i18n.t(
        "_sentence.provision_configurations_successfully_merged"
      ),
      onSuccess
    }
  ) => {
    const confirm = await dispatch(
      "ui/open/confirmModal",
      {
        config: {
          props: {
            mode: ConfirmModalModes.PIN,
            autoClose: false,
            title: i18n.t("_.merge_confirmation"),
            confirmButtonText: i18n.t("_action.merge"),
            message
          },
          canCancel: [],
          events: {
            confirmed: async () => {
              try {
                await dispatch(
                  "data/callApi",
                  {
                    method: Methods.PATCH,
                    path: `${getters.apiPath().admin}/${configurationId}/merge`,
                    requestConfig: {
                      data: {
                        old_configuration_id: oldConfigurationId,
                        delete_old: true
                      }
                    }
                  },
                  { root: true }
                );

                dispatch(
                  "toast/show",
                  { message: successMessage },
                  { root: true }
                );
                onSuccess();
              } catch (error) {
                dispatch("api/handleError", error, { root: true });
              } finally {
                confirm.close();
              }
            }
          }
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
