import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import type { IImportSource, IImportSourceCredentials } from "@/models/imports";
import { Methods } from "@/models/methods";
import { ConfirmModalModes } from "@/data/enums";
import type { IModalComponent } from "@/models/modal";
import { ToastProgrammatic as $toast } from "buefy";
import i18n from "@/i18n";
import type { ApiPathGetter } from "@/models/api";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath: (): ApiPathGetter => () => {
    const admin = `api/admin/imports`;
    return { admin };
  }
};

export const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `${getters.apiPath().admin}`,
        storeModule: DataModules.IMPORTS
      },
      { root: true }
    );
  },
  get: ({ dispatch, getters }, { importId, payload } = { payload: {} }) => {
    // Set default params
    payload.params = {
      with: [
        "credentials",
        "status",
        "source",
        "steps.status",
        "steps.files"
      ].join(),
      ...payload.params
    };
    return dispatch(
      "data/get",
      {
        ...payload,
        method: Methods.GET,
        path: `${getters.apiPath().admin}/${importId}`,
        storeModule: DataModules.IMPORTS
      },
      { root: true }
    );
  },
  confirmCommit: async ({ dispatch }, { importId }) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<boolean>(async resolve => {
      const confirm: IModalComponent = await dispatch(
        "ui/open/confirmModal",
        {
          config: {
            props: {
              autoClose: false,
              mode: ConfirmModalModes.PIN,
              confirmButtonText: i18n.t("_action.commit_import"),
              message: i18n.t("_sentence.imports.confirm_commit"),
              type: "is-success"
            },
            events: {
              close: () => resolve(false),
              confirmed: async () => {
                try {
                  await dispatch("commit", { importId });
                  resolve(true);
                  $toast.open({
                    message: i18n.t("_.import_committed") as string
                  });
                } catch (error) {
                  dispatch("api/handleError", error, { root: true });
                  resolve(false);
                } finally {
                  confirm.close();
                }
              }
            }
          }
        },
        { root: true }
      );
    });
  },
  commit: ({ dispatch, getters }, { importId }) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PATCH,
        path: `${getters.apiPath().admin}/${importId}/commit`
      },
      { root: true }
    );
  },
  confirmDelete: async ({ dispatch }, { importId }) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<boolean>(async resolve => {
      const confirm: IModalComponent = await dispatch(
        "ui/open/confirmModal",
        {
          config: {
            props: {
              autoClose: false,
              mode: ConfirmModalModes.BUTTON,
              confirmButtonText: i18n.t("_action.delete"),
              message: i18n.t("_sentence.imports.confirm_import_delete")
            },
            events: {
              close: () => resolve(false),
              confirmed: async () => {
                try {
                  await dispatch("remove", { importId });
                  resolve(true);
                  $toast.open({
                    message: i18n.t("_.import_deleted") as string
                  });
                } catch (error) {
                  dispatch("api/handleError", error, { root: true });
                  resolve(false);
                } finally {
                  confirm.close();
                }
              }
            }
          }
        },
        { root: true }
      );
    });
  },
  remove: ({ dispatch, getters }, { importId }) => {
    return dispatch(
      "data/remove",
      {
        path: `${getters.apiPath().admin}/${importId}`,
        storeModule: DataModules.IMPORTS
      },
      { root: true }
    );
  },
  createImport: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/create",
      {
        ...payload,
        path: `${getters.apiPath().admin}`,
        storeModule: DataModules.IMPORTS
      },
      { root: true }
    );
  },
  start: ({ dispatch, getters }, { importId, payload }) => {
    return dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.PATCH,
        path: `${getters.apiPath().admin}/${importId}/start`
      },
      { root: true }
    );
  },
  retryStep: ({ dispatch, getters }, { importId, stepId, payload }) => {
    return dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.PATCH,
        path: `${getters.apiPath().admin}/${importId}/steps/${stepId}/retry`
      },
      { root: true }
    );
  },
  skipStep: ({ dispatch, getters }, { importId, stepId, payload }) => {
    return dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.PATCH,
        path: `${getters.apiPath().admin}/${importId}/steps/${stepId}/skip`
      },
      { root: true }
    );
  },
  confirmCancel: async ({ dispatch }, { importId }) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<boolean>(async resolve => {
      const confirm: IModalComponent = await dispatch(
        "ui/open/confirmModal",
        {
          config: {
            props: {
              autoClose: false,
              mode: ConfirmModalModes.BUTTON,
              closeButtonText: i18n.t("_action.no_dont_cancel"),
              confirmButtonText: i18n.t("_action.cancel_import"),
              message: i18n.t("_sentence.imports.confirm_cancel")
            },
            events: {
              close: () => resolve(false),
              confirmed: async () => {
                try {
                  await dispatch("cancel", { importId });
                  resolve(true);
                  $toast.open({
                    message: i18n.t("_.import_cancelled") as string
                  });
                } catch (error) {
                  dispatch("api/handleError", error, { root: true });
                  resolve(false);
                } finally {
                  confirm.close();
                }
              }
            }
          }
        },
        { root: true }
      );
    });
  },
  cancel: ({ dispatch, getters }, { importId }) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PATCH,
        path: `${getters.apiPath().admin}/${importId}/cancel`
      },
      { root: true }
    );
  },
  listSources: ({ dispatch, getters }, payload = {}) => {
    // Set default params
    payload.params = {
      with: ["logos"].join(),
      ...payload.params
    };
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `${getters.apiPath().admin}/sources`,
        scope: "$sources",
        storeModule: DataModules.IMPORTS
      },
      { root: true }
    );
  },
  listSourceCredentials: (
    { dispatch, getters },
    { sourceId, payload = {} }: { sourceId: IImportSource["id"]; payload: any }
  ) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `${getters.apiPath().admin}/sources/${sourceId}/credentials`,
        storeModule: DataModules.IMPORTS
      },
      { root: true }
    );
  },
  addSourceCredentials: (
    { dispatch, getters },
    { sourceId, payload = {} }: { sourceId: IImportSource["id"]; payload: any }
  ) => {
    return dispatch(
      "data/create",
      {
        ...payload,
        path: `${getters.apiPath().admin}/sources/${sourceId}/credentials`,
        storeModule: DataModules.IMPORTS
      },
      { root: true }
    );
  },
  updateSourceCredentials: (
    { dispatch, getters },
    {
      sourceId,
      credentialsId,
      payload = {}
    }: {
      sourceId: IImportSource["id"];
      credentialsId: IImportSourceCredentials["id"];
      payload: any;
    }
  ) => {
    return dispatch(
      "data/update",
      {
        ...payload,
        path: `${
          getters.apiPath().admin
        }/sources/${sourceId}/credentials/${credentialsId}`,
        storeModule: DataModules.IMPORTS
      },
      { root: true }
    );
  },
  deleteSourceCredentials: (
    { dispatch, getters },
    {
      sourceId,
      credentialsId
    }: {
      sourceId: IImportSource["id"];
      credentialsId: IImportSourceCredentials["id"];
    }
  ) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.DELETE,
        path: `${
          getters.apiPath().admin
        }/sources/${sourceId}/credentials/${credentialsId}`
      },
      { root: true }
    );
  },
  openAttachFileModal: ({ dispatch }, { modalConfig }) => {
    return dispatch(
      `ui/open/slideModal`,
      {
        config: {
          component: () =>
            import("@/components/app/admin/imports/attachFilesModal.vue"),
          width: 720,
          canCancel: ["x", "button", "outside"],
          ...modalConfig
        }
      },
      { root: true }
    );
  },
  openCreateImportModal: ({ dispatch }, { modalConfig }) => {
    return dispatch(
      `ui/open/slideModal`,
      {
        config: {
          component: () =>
            import("@/components/app/admin/imports/newImportModal.vue"),
          width: 720,
          canCancel: ["x", "button", "outside"],
          ...modalConfig
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  modules: {
    files: require("./files").default,
    logs: require("./logs").default,
    mappings: require("./mappings").default,
    steps: require("./steps").default,
    uploadFiles: require("./uploadFiles").default
  }
};
