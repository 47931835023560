import type { GetterTree, ActionTree, MutationTree } from "vuex";
import type { IState } from "@/store";
import type { CalendarSpec } from "moment";
import moment from "moment";
import i18n from "@/i18n";
import _ from "@/boot/lodash";

export interface IUtilsTimeState {
  now: Date;
}

const initialState: IUtilsTimeState = {
  now: new Date()
};

const getters: GetterTree<IUtilsTimeState, IState> = {
  hasTime:
    () =>
    (dateTime: string): boolean => {
      return typeof dateTime === "string"
        ? !!dateTime.match(/\s\d{2}:\d{2}:\d{2}?$/)
        : true;
    },
  formatFunctionCalendar:
    (state, getters) =>
    (dateTime, spec: CalendarSpec = {}): CalendarSpec => {
      const hasTime = getters["hasTime"](dateTime);
      const mDate = moment.utc(dateTime).local();

      const formatFunction: CalendarSpec = _.merge(
        {
          sameDay: now => {
            return hasTime
              ? `[${mDate.from(now)}]`
              : i18n.t("_datetime.calendar.sameDay").toString();
          },
          nextDay: i18n.t("_datetime.calendar.nextDay").toString(),
          nextWeek: i18n.t("_datetime.calendar.nextWeek").toString(),
          lastDay: now => {
            return hasTime
              ? `[${mDate.from(now)}]`
              : i18n.t("_datetime.calendar.lastDay").toString();
          },
          lastWeek: i18n.t("_datetime.calendar.lastWeek").toString(),
          sameElse: () => {
            return hasTime
              ? (i18n.t("_datetime.datetime.with_seconds") as string)
              : (i18n.t("_datetime.date.default") as string);
          }
        },
        spec
      );
      return formatFunction;
    },
  formatCalendar:
    (state, getters) =>
    (dateTime: string, spec: CalendarSpec = {}): string => {
      const formatFunction = getters["formatFunctionCalendar"](dateTime, spec);
      const momentDate = moment.utc(dateTime).local();
      return momentDate.calendar(formatFunction);
    }
};
const actions: ActionTree<IUtilsTimeState, IState> = {
  start: ({ commit }) => {
    setInterval(() => {
      commit("update");
    }, 1000 * 60);
  }
};

const mutations: MutationTree<IUtilsTimeState> = {
  update: state => {
    state.now = new Date();
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
};
