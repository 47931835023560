import type { ClientTemplateSlotCodes } from "@/data/enums/templates";
import type { ApiPathGetter } from "@/models/api";
import { Methods } from "@/models/methods";
import type { IState } from "@/store";
import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { GetterTree } from "vuex";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (
      s,
      g,
      rS,
      { isAuthenticatedAdminContext, isMockClientContext }
    ): ApiPathGetter =>
    () => {
      const admin = "api/admin/templates/client_area";
      const client = "api/templates/client_area";
      const contextual =
        isAuthenticatedAdminContext && !isMockClientContext() ? admin : client;
      return { client, admin, contextual };
    },
  scope: () => (code: ClientTemplateSlotCodes) => {
    return `$client_template_${code}`;
  }
};

const actions = {
  get: (
    { dispatch, getters },
    { code, payload }: { code: ClientTemplateSlotCodes; payload: any }
  ) => {
    return dispatch(
      "data/fetch",
      {
        method: Methods.PATCH,
        path: `${getters.apiPath().contextual}/slots/${code}/render`,
        storeModule: DataModules.TEMPLATES_CLIENT_RENDER,
        ...payload
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
