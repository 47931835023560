import i18n from "@/i18n";
import { ClientRoutes } from "@/data/enums/router";
import type { ICProdProvider } from "@/models/providers/contractProduct";
import type { IMenuRoute } from "@/models/routes";

const isActive: IMenuRoute["isActive"] = (currentRoute, isActive) => {
  if ((currentRoute.name || "").match(/__(cancelled|active)/g)) return false;
  return isActive;
};

export function ClientProductsTabigation() {
  return [
    {
      label: i18n.t("_.active"),
      to: {
        name: ClientRoutes.PRODUCTS_ACTIVE
      }
    },
    {
      label: i18n.t("_.cancelled"),
      to: {
        name: ClientRoutes.PRODUCTS_CANCELLED
      }
    },
    {
      isActive,
      label: i18n.t("_.all"),
      to: {
        name: ClientRoutes.PRODUCTS_ALL
      }
    }
  ];
}

export function ClientSubscriptionsTabigation() {
  return [
    {
      label: i18n.t("_.active"),
      to: {
        name: ClientRoutes.SUBSCRIPTIONS_ACTIVE
      }
    },
    {
      label: i18n.t("_.cancelled"),
      to: {
        name: ClientRoutes.SUBSCRIPTIONS_CANCELLED
      }
    },
    {
      isActive,
      label: i18n.t("_.all"),
      to: {
        name: ClientRoutes.SUBSCRIPTIONS_ALL
      }
    }
  ];
}

export function ClientOneTimePurchasesTabigation() {
  return [
    {
      label: i18n.t("_.active"),
      to: {
        name: ClientRoutes.ONE_TIME_PURCHASES_ACTIVE
      }
    },
    {
      label: i18n.t("_.cancelled"),
      to: {
        name: ClientRoutes.ONE_TIME_PURCHASES_CANCELLED
      }
    },
    {
      isActive,
      label: i18n.t("_.all"),
      to: {
        name: ClientRoutes.ONE_TIME_PURCHASES_ALL
      }
    }
  ];
}

export function ClientCategoryProductsTabigation() {
  return [
    {
      label: i18n.t("_.active"),
      to: {
        name: ClientRoutes.CATEGORY_PRODUCTS_ACTIVE
      }
    },
    {
      label: i18n.t("_.cancelled"),
      to: {
        name: ClientRoutes.CATEGORY_PRODUCTS_CANCELLED
      }
    },
    {
      isActive,
      label: i18n.t("_.all"),
      to: {
        name: ClientRoutes.CATEGORY_PRODUCTS_ALL
      }
    }
  ];
}

export function cProdTabigation({ cProdData }: { cProdData: ICProdProvider }) {
  return [
    {
      label: i18n.t("_.setup"),
      to: {
        name: ClientRoutes.CONTRACT_PRODUCT_SETUP
      },
      if: () => cProdData.isAwaitingSetup
    },
    {
      label: i18n.t("_.overview"),
      to: {
        name: ClientRoutes.CONTRACT_PRODUCT_OVERVIEW
      }
    },
    {
      label: i18n.t("_.billing"),
      to: () => ({
        name: ClientRoutes.CONTRACT_PRODUCT_BILLING
      })
    },
    {
      label: i18n.t("_.settings"),
      to: {
        name: ClientRoutes.CONTRACT_PRODUCT_SETTINGS
      },
      if: () => {
        return cProdData.canModifySettings;
      }
    }
  ];
}
