import { Methods } from "@/models/methods";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import type { IDataState } from "@/store/modules/data";
import type { ApiPathGetter } from "@/models/api";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext, isMockClientContext }): ApiPathGetter =>
    ({ clientId }) => {
      const admin = `api/admin/clients/${clientId}/upmind_usage`;
      const client = `api/clients/upmind_usage`;
      const contextual =
        isAdminContext && !isMockClientContext() ? admin : client;
      return { client, admin, contextual };
    }
};

const actions: ActionTree<IDataState, IState> = {
  getUpmindUsage: async ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.GET,
        path: getters.apiPath(payload).contextual
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
