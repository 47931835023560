import { DEFAULT_LIST_SCOPE, type IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import type { ApiPathGetter } from "@/models/api";
import { ConfirmModalModes } from "@/data/enums";
import i18n from "@/i18n";
import { Methods } from "@/models/methods";
import type { ICustomField } from "@upmind-automation/types";
import type { CustomFieldsMajorTypes } from "@/data/enums/customFields";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext }): ApiPathGetter =>
    () => {
      const admin = `api/admin/custom_fields`;
      const client = `api/custom_fields`;
      const contextual = isAdminContext ? admin : client;
      return { client, admin, contextual };
    },
  scope: () => (id: ICustomField) => {
    return `$custom_field_${id}`;
  },
  listScope: () => (customFieldsType: CustomFieldsMajorTypes, id) => {
    return `$custom_fields_${customFieldsType}_${id || DEFAULT_LIST_SCOPE}`;
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `${getters.apiPath().contextual}`,
        params: {
          "filter[object_type]": payload.customFieldsType,
          ...(payload.params || {})
        },
        storeModule: DataModules.CUSTOM_FIELDS
      },
      { root: true }
    );
  },
  get: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload?.id}`,
        storeModule: DataModules.CUSTOM_FIELDS
      },
      { root: true }
    );
  },
  create: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/create",
      {
        ...payload,
        path: getters.apiPath().admin,
        storeModule: DataModules.CUSTOM_FIELDS
      },
      { root: true }
    );
  },
  update: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/update",
      {
        data: payload.data,
        path: `${getters.apiPath().admin}/${payload?.id}`,
        storeModule: DataModules.CUSTOM_FIELDS
      },
      { root: true }
    );
  },
  deleteCustomField: async ({ dispatch }, { customFieldId, onSuccess }) => {
    const confirm = await dispatch(
      "ui/open/confirmModal",
      {
        config: {
          props: {
            autoClose: false,
            confirmButtonText: i18n.t("_action.delete"),
            message: i18n.t("_sentence.custom_fields.confirm_delete_msg"),
            mode: ConfirmModalModes.BUTTON
          },
          canCancel: [],
          events: {
            confirmed: async () => {
              try {
                await dispatch("remove", { id: customFieldId });
                dispatch(
                  "toast/show",
                  {
                    message: i18n.t("_.custom_field_successfully_removed")
                  },
                  { root: true }
                );
                await onSuccess();
              } catch (error) {
                dispatch("api/handleError", error, {
                  root: true
                });
              } finally {
                confirm.close();
              }
            }
          }
        }
      },
      { root: true }
    );
  },
  remove: async ({ dispatch, getters }, payload) => {
    await dispatch(
      "data/remove",
      {
        ...payload,
        keys: [payload.id],
        path: `${getters.apiPath().admin}/${payload.id}`,
        storeModule: DataModules.CUSTOM_FIELDS
      },
      { root: true }
    );
  },
  openManageCustomFieldModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/admin/customFields/manageCustomFieldModal.vue"
            ),
          ...payload
        }
      },
      { root: true }
    );
  },
  reorder: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PUT,
        path: `${getters.apiPath().admin}/order`,
        storeModule: DataModules.CUSTOM_FIELDS,
        requestConfig: { data: payload.data }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  getters
};
