import { RegexMatch } from "@/data/enums/router";
import type { Route } from "vue-router";
import TicketsListing from "@/components/app/global/tickets/ticketsListing.vue";
import { IsActiveParam, IsClosedParam } from "@/data/filters/tickets";
import type { ITicket } from "@upmind-automation/types";

export default [
  {
    path: `leads/:leadId(${RegexMatch.UUID})`,
    component: {
      name: "AdminLead",
      template: "<router-view :key='$route.params.leadId' />"
    },
    meta: {
      title: "_.leads"
    },
    children: [
      {
        path: "",
        name: "adminLead",
        redirect: { name: "adminLeadOverview" },
        component: () => import("@/views/admin/leads/lead/index.vue"),
        props: {
          routes: require("./menu").leadOverview
        },
        meta: {
          title: ({ leadName }) => leadName
        },
        children: [
          {
            path: "overview",
            name: "adminLeadOverview",
            component: () =>
              import("@/views/admin/leads/lead/overview/index.vue"),
            meta: {
              auth: ["get_lead"]
            }
          },
          {
            path: "tickets",
            component: {
              name: "AdminLeadTicketsWrapper",
              template: `<router-view v-bind="$attrs" />`
            },
            children: [
              {
                path: "",
                name: "adminLeadSupportTickets",
                component: () =>
                  import("@/views/admin/leads/lead/tickets/index.vue"),
                meta: {
                  title: "_.tickets",
                  breadcrumb: {
                    label: "_action.edit"
                  }
                },
                redirect: { name: "admin__leadActiveSupportTickets" },
                props: (route: Route) => ({
                  routes: require("./menu").ticketsTabigation,
                  leadId: route.params.leadId
                }),
                children: [
                  {
                    path: "active",
                    name: "admin__leadActiveSupportTickets",
                    component: TicketsListing,
                    props: (route: Route) => ({
                      leadId: route.params?.leadId,
                      routeFilters: () => ({
                        ...IsActiveParam()
                      }),
                      ticketRoute: (ticket: ITicket) => ({
                        name: "adminLeadSupportTicket",
                        params: {
                          ticketId: ticket.id,
                          leadId: ticket.lead_id
                        }
                      }),
                      showStaged: true
                    }),
                    meta: {
                      backTo: "_.lead_active_support_tickets",
                      title: "_.lead_active_support_tickets"
                    }
                  },
                  {
                    path: "closed",
                    name: "admin__leadClosedSupportTickets",
                    component: TicketsListing,
                    props: (route: Route) => ({
                      leadId: route.params?.leadId,
                      routeFilters: () => ({
                        ...IsClosedParam()
                      }),
                      ticketRoute: (ticket: ITicket) => ({
                        name: "adminLeadSupportTicket",
                        params: {
                          ticketId: ticket.id,
                          leadId: ticket.lead_id
                        }
                      }),
                      showStaged: true
                    }),
                    meta: {
                      backTo: "_.lead_closed_support_tickets",
                      title: "_.lead_closed_support_tickets"
                    }
                  }
                ]
              },
              {
                path: `:ticketId(${RegexMatch.UUID})`,
                name: "adminLeadSupportTicket",
                component: () =>
                  import("@/views/admin/leads/lead/tickets/ticket/index.vue"),
                props: true
              }
            ]
          },
          {
            path: "email-history",
            name: "adminLeadEmailHistory",
            component: () =>
              import("@/views/admin/leads/lead/emailHistory/index.vue"),
            meta: {
              auth: ["get_lead"]
            }
          }
        ]
      }
    ]
  },
  {
    path: "leads",
    name: "adminLeads",
    redirect: { name: "adminLeadsList" },
    component: () => import("@/views/admin/leads/index.vue"),
    meta: {
      title: "_.leads",
      auth: ["list_leads"],
      breadcrumb: {
        label: "_.leads"
      }
    },
    children: [
      {
        path: "list",
        component: () => import("@/views/admin/leads/list/index.vue"),
        props: {
          routes: require("./menu").leadsQuickFilters
        },
        children: [
          {
            path: "",
            name: "adminLeadsList",
            component: () =>
              import("@/components/app/admin/leads/leadsTable.vue"),
            props: {
              id: "LT1"
            },
            meta: {
              backTo: "_.leads"
            }
          }
        ]
      }
    ]
  }
];
