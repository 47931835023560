import _ from "@/boot/lodash";
import type { MutationTree } from "vuex";

export interface IBreadcrumbValue {
  key: string;
  value: any;
}

export interface IBreadcrumbState {
  breadcrumbs: object;
}

const initialState = (): IBreadcrumbState => {
  return {
    breadcrumbs: {}
  };
};

const mutations: MutationTree<IBreadcrumbState> = {
  add: (state: IBreadcrumbState, breadcrumb: IBreadcrumbValue) => {
    _.set(state.breadcrumbs, breadcrumb.key, breadcrumb.value);
    window.$rootVue.$emit("breadcrumbUpdated", breadcrumb.key);
  },
  reset: (state: IBreadcrumbState) => {
    Object.assign(state, initialState());
  }
};

export default {
  namespaced: true,
  state: initialState(),
  mutations
};
