<template>
  <columns>
    <column class="is-12">
      <u-header :title="$t('_.products_and_services')">
        <!-- Crumbs -->
        <template slot="crumbs">
          <u-breadcrumbs :crumbs="breadcrumbs" />
        </template>
        <!-- Tabigation -->
        <template #tabs>
          <tabigation :routes="routes()" />
        </template>
      </u-header>
      <router-view :key="$route.path" />
    </column>
  </columns>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
import type { IMenuRoute } from "@/models/routes";
import type { ICrumb } from "@/models/breadcrumbs";

export default defineComponent({
  name: "AdminUpmindClientProducts",
  props: {
    routes: {
      type: Function as PropType<() => IMenuRoute[]>,
      default: () => {}
    }
  },
  data: () => ({
    breadcrumbs: [
      { label: "", route: { to: { name: "admin__upmindAccount" } } }
    ] as ICrumb[]
  }),
  created() {
    this.breadcrumbs[0].label = this.$t("_.my_upmind");
  }
});
</script>
