import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree } from "vuex";
import type { IState } from "@/store";

const initialState = {} as IDataState;

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: "api/admin/payment_details",
        storeModule: DataModules.PAYMENT_DETAILS,
        params: {
          ...payload.params,
          with_staged_imports: 1
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  actions
};
