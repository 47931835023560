import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import { Methods } from "@/models/methods";
import _ from "@/boot/lodash";
import { EmailTypes } from "@/data/enums/emails";
import type { ISentEmail } from "@/models/emails";
import { SentEmailStatus } from "@/data/enums/emails";
import type { ApiPathGetter } from "@/models/api";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext }): ApiPathGetter =>
    () => {
      const admin = "api/admin/emails";
      const client = "api/emails";
      const contextual = isAdminContext ? admin : client;
      return { client, admin, contextual };
    },
  apiEmailTypePath:
    (s, g, rS, { isAdminContext }) =>
    (emailType: EmailTypes, objectId: string) => {
      const contextual = isAdminContext ? "api/admin" : "api";
      switch (emailType) {
        case EmailTypes.ACCOUNT:
          return `api/admin/accounts/${objectId}/email_history`;
        case EmailTypes.CLIENT:
          return `${contextual}/clients/${objectId}/email_history`;
        case EmailTypes.LEAD:
          return `${contextual}/leads/${objectId}/email_history`;
        case EmailTypes.MYSELF:
          return `${contextual}/self/email_history`;
        case EmailTypes.USER:
          return `api/admin/users/${objectId}/email_history`;
        case EmailTypes.BRAND:
        default:
          return `api/admin/brand/email_history`;
      }
    },
  scope: () => id => {
    return `$email_${id}`;
  },
  listScope:
    () =>
    (emailType: string, objectId = "") => {
      return `$${emailType}${objectId ? `_${objectId}` : ""}`;
    },
  params:
    (state: IDataState, getters) =>
    (emailType: EmailTypes, objectId: string) => {
      return _.get(
        state,
        `${getters.listScope(emailType, objectId)}.params`,
        {}
      );
    },
  status:
    () =>
    (email: ISentEmail): SentEmailStatus => {
      if (email.error_id) return SentEmailStatus.ERROR;
      else if (email.bounced) return SentEmailStatus.BOUNCED;
      else if (email.sent) return SentEmailStatus.SENT;
      else return SentEmailStatus.SENDING;
    }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    const { emailType, emailTypeId } = payload?.params;
    delete payload?.params?.emailType;
    delete payload?.params?.emailTypeId;
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiEmailTypePath(emailType, emailTypeId),
        splitCount: true,
        storeModule: DataModules.EMAILS
      },
      { root: true }
    );
  },
  get: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath().contextual}/${_.get(payload, "id")}`,
        storeModule: DataModules.EMAILS
      },
      { root: true }
    );
  },
  send: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/create",
      {
        path: `${getters.apiPath().admin}/${_.get(payload, "id")}/send`,
        storeModule: DataModules.EMAILS,
        data: {
          dispatch_now: true
        }
      },
      { root: true }
    );
  },
  resend: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.POST,
        path: `${getters.apiPath().admin}/${_.get(payload, "id")}/resend`,
        storeModule: DataModules.EMAILS
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
