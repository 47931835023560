export default {
  namespaced: true,
  modules: {
    categories: require("./categories").default,
    channels: require("./channels").default,
    hooks: require("./hooks").default,
    optOuts: require("./optOuts").default,
    recipientTypes: require("./recipientTypes").default,
    topics: require("./topics").default
  }
};
