import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import { ProductTypes } from "@/data/enums/products";
import type { IState } from "@/store";
import _ from "@/boot/lodash";
import i18n from "@/i18n";
import { Methods } from "@/models/methods";
import type { IBrand } from "@upmind-automation/types";
import { UUID, ConfirmModalModes } from "@/data/enums";
import type { IProductCategory } from "@/models/products";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext, isMockClientContext }) =>
    ({ productTypes = [] as ProductTypes[], isBasketContext = false }) => {
      const slug = productTypes.includes(ProductTypes.PRODUCT_OPTION)
        ? `products_options_categories`
        : productTypes.includes(ProductTypes.PRODUCT_ATTRIBUTE)
        ? `products_attributes_categories`
        : isBasketContext
        ? "basket/products_categories"
        : "products_categories";

      const admin = `api/admin/${slug}`;
      const client = `api/${slug}`;
      const contextual =
        isAdminContext && !isMockClientContext() ? admin : client;
      return { client, admin, contextual };
    },
  scope: () => id => {
    return `$category_${id}`;
  },
  categoriesScope: () => (brand_id: IBrand["id"], productTypes: number[]) => {
    const productType: string = productTypes.includes(
      ProductTypes.PRODUCT_OPTION
    )
      ? "options"
      : productTypes.includes(ProductTypes.PRODUCT_ATTRIBUTE)
      ? "attributes"
      : "products";

    return `$categories_${productType}_${brand_id}`;
  },
  listParams:
    (state: IDataState, getters) =>
    (brand_id: IBrand["id"], productTypes: number[]) => {
      return _.get(
        state,
        `${getters.categoriesScope(brand_id, productTypes)}.params`,
        {}
      );
    },
  rootCategoriesScope:
    () => (brand_id: IBrand["id"], productTypes: number[]) => {
      const productType: string = productTypes.includes(
        ProductTypes.PRODUCT_OPTION
      )
        ? "options"
        : productTypes.includes(ProductTypes.PRODUCT_ATTRIBUTE)
        ? "attributes"
        : "products";

      return `$root_categories_${productType}_${brand_id}`;
    },

  multilevelCategoriesScope:
    () => (brand_id: IBrand["id"], productTypes: number[]) => {
      const productType: string = productTypes.includes(
        ProductTypes.PRODUCT_OPTION
      )
        ? "options"
        : productTypes.includes(ProductTypes.PRODUCT_ATTRIBUTE)
        ? "attributes"
        : "products";

      return `$multilevel_categories_${productType}_${brand_id}`;
    },
  getFullCategoryPath:
    () =>
    ({
      categories,
      categoryId,
      showTargetCategory = true
    }: {
      categories: IProductCategory[];
      categoryId: IProductCategory["id"];
      showTargetCategory: boolean;
    }) => {
      let path: {
        id: string;
        name: string;
        order: number;
      }[] = [];

      function dive(_data, _path, level) {
        for (let i = 0; i < _data.length; i++) {
          if (_data[i].id === categoryId) {
            path = _.concat(_path, [
              { id: _data[i].id, name: _data[i].name, order: level }
            ]);
          } else if (_.has(_data[i], "subcategories")) {
            dive(
              _data[i].subcategories,
              _.concat(_path, [
                { id: _data[i].id, name: _data[i].name, order: level }
              ]),
              level + 1
            );
          }
        }
      }

      dive(categories, [], 0);

      return showTargetCategory
        ? path
        : _.filter(path, pathObj => pathObj.id !== categoryId);
    }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath(payload).contextual,
        storeModule: DataModules.CATALOGUE_CATEGORIES
      },
      { root: true }
    );
  },

  get: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath(payload).contextual}/${payload?.id}`,
        storeModule: DataModules.CATALOGUE_CATEGORIES,
        params: {
          with_staged_imports: 1
        }
      },
      { root: true }
    );
  },

  getMultilevelCategories: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        path: getters.apiPath(payload).contextual,
        scope: _.get(payload, "scope", ""),
        params: {
          brandId: _.get(payload, "brandId", UUID.ORG),
          order: "order",
          limit: 0,
          with: "subcategories,subcategories.subcategories,subcategories.subcategories.subcategories,subcategories.subcategories.subcategories.subcategories",
          with_staged_imports: 1
        },
        storeModule: DataModules.CATALOGUE_CATEGORIES
      },
      { root: true }
    );
  },

  create: async ({ dispatch, commit, getters }, payload) => {
    const category = await dispatch(
      "data/create",
      {
        ...payload,
        path: getters.apiPath(payload).admin,
        storeModule: DataModules.CATALOGUE_CATEGORIES
      },
      { root: true }
    );

    await commit(
      "data/addSingleItemList",
      {
        scope: payload.scope,
        path: `data.${category.id}`,
        storeModule: DataModules.CATALOGUE_CATEGORIES,
        data: category
      },
      { root: true }
    );

    return category;
  },

  update: async ({ dispatch, commit, getters }, payload) => {
    const category = await dispatch(
      "data/update",
      {
        data: payload.data,
        path: `${getters.apiPath(payload).admin}/${payload?.id}`,
        storeModule: DataModules.CATALOGUE_CATEGORIES
      },
      { root: true }
    );

    await commit(
      `data/updateSingleItemList`,
      {
        scope: payload.scope,
        path: `data.${_.get(payload, "id")}`,
        storeModule: DataModules.CATALOGUE_CATEGORIES,
        data: category
      },
      { root: true }
    );

    return category;
  },

  remove: async ({ dispatch, commit, getters }, payload) => {
    const result = await dispatch(
      "data/remove",
      {
        path: `${getters.apiPath(payload).admin}/${payload?.id}`,
        storeModule: DataModules.CATALOGUE_CATEGORIES
      },
      { root: true }
    );

    await commit(
      "data/binSingleItemList",
      {
        scope: payload.scope,
        path: `data.${payload.id}`,
        storeModule: DataModules.CATALOGUE_CATEGORIES
      },
      { root: true }
    );

    return result;
  },

  reorder: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PATCH,
        path: `${getters.apiPath(payload).admin}/reorder`,
        storeModule: DataModules.CATALOGUE_CATEGORIES,
        requestConfig: { data: payload.data }
      },
      { root: true }
    );
  },

  openAddEditCategoryModal: ({ dispatch }, payload) => {
    return new Promise(resolve => {
      dispatch(
        "ui/open/slideModal",
        {
          config: {
            component: () =>
              import(
                "@/components/app/admin/catalogue/products/addEditProductCategoryModal.vue"
              ),
            props: payload,
            onCancel: () => {
              resolve({
                cancelled: true
              });
            },
            events: {
              success: (results: any) => {
                resolve({
                  cancelled: false,
                  results
                });
              }
            }
          }
        },
        { root: true }
      );
    });
  },

  openSelectProductCurrencyModal: ({ dispatch }, modalConfig) => {
    return dispatch(
      "ui/open/windowModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/admin/catalogue/products/billing/selectCurrencyModal.vue"
            ),
          ...modalConfig
        }
      },
      { root: true }
    );
  },

  deleteCategory: async (
    { dispatch },
    { categoryId, productTypes, onSuccess }
  ) => {
    const confirm = await dispatch(
      "ui/open/confirmModal",
      {
        config: {
          props: {
            autoClose: false,
            mode: ConfirmModalModes.PIN,
            message: i18n.t("_sentence.delete_category")
          },
          canCancel: [],
          events: {
            confirmed: async () => {
              try {
                await dispatch("removeCategory", { categoryId, productTypes });

                dispatch(
                  "toast/show",
                  { message: i18n.t("_.category_successfully_removed") },
                  { root: true }
                );

                onSuccess();
                confirm.close();
              } catch (error) {
                dispatch("api/handleError", error, { root: true });
              }
            }
          }
        }
      },
      { root: true }
    );
  },

  removeCategory: async (
    { dispatch, getters },
    { categoryId, productTypes }
  ) => {
    return dispatch(
      "data/remove",
      {
        path: `${getters.apiPath({ productTypes }).admin}/${categoryId}`,
        storeModule: DataModules.CATALOGUE_CATEGORIES
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
