import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree } from "vuex";
import type { IState } from "@/store";
import { Methods } from "@/models/methods";
import type { ApiPathGetter } from "@/models/api";
import { ToastProgrammatic as $toast } from "buefy";
import _ from "@/boot/lodash";
import i18n from "@/i18n";
import type { BModalComponent, BModalConfig } from "buefy/types/components";
import { ConfirmModalModes } from "@/data/enums";

const initialState = {} as IDataState;

const getters = {
  scope: () => id => {
    return `$taxTemplate${id}`;
  },
  apiPath: (): ApiPathGetter => () => {
    const admin = `api/admin/tax_templates`;
    return { admin };
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      _.merge(
        {
          params: {
            with: ["business_types", "tax_tags"].join()
          }
        },
        payload,
        {
          path: getters.apiPath().admin,
          storeModule: DataModules.TAX_TEMPLATES
        }
      ),
      { root: true }
    );
  },
  get: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload.id}`,
        storeModule: DataModules.TAX_TEMPLATES
      },
      { root: true }
    );
  },
  create: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/create",
      {
        ...payload,
        path: getters.apiPath().admin,
        storeModule: DataModules.TAX_TEMPLATES
      },
      { root: true }
    );
  },
  update: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.PUT,
        path: `${getters.apiPath().admin}/${payload.id}`,
        storeModule: DataModules.TAX_TEMPLATES,
        requestConfig: { data: payload.data }
      },
      { root: true }
    );
  },
  remove: async ({ dispatch, getters }, payload) => {
    await dispatch(
      "data/remove",
      {
        ...payload,
        keys: [payload.id],
        path: `${getters.apiPath().admin}/${payload.id}`,
        storeModule: DataModules.TAX_TEMPLATES
      },
      { root: true }
    );
  },
  sync: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PUT,
        path: `${getters.apiPath().admin}/${payload.taxTemplateId}/sync_tags`,
        storeModule: DataModules.TAX_TEMPLATES,
        requestConfig: { data: payload.data }
      },
      { root: true }
    );
  },
  openAddEditModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/global/tax/templates/addEditTaxTemplateModal.vue"
            ),

          width: 540,
          ...payload
        }
      },
      { root: true }
    );
  },
  resetDefaults: async (
    { dispatch, getters },
    {
      config = {},
      onSuccess = () => Promise.resolve()
    }: {
      config: Partial<BModalConfig>;
      onSuccess: () => Promise<any>;
    }
  ) => {
    const confirm: BModalComponent = await dispatch(
      "ui/open/confirmModal",
      {
        config: {
          ...config,
          props: {
            autoClose: false,
            mode: ConfirmModalModes.PIN,
            title: i18n.t("_action.use_system_defaults"),
            confirmButtonText: i18n.t("_action.confirm"),
            message: i18n.t("_sentence.tax.confirm_reset_templates_msg")
          },
          events: {
            confirmed: async () => {
              await dispatch(
                "data/callApi",
                {
                  method: Methods.POST,
                  path: `${getters.apiPath().admin}/reset_default_vat`
                },
                { root: true }
              );
              await onSuccess();
              $toast.open({
                message: i18n.t("_sentence.tax.templates_reset_msg") as string
              });
              confirm.close();
            }
          }
        }
      },
      { root: true }
    );
    return confirm;
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
