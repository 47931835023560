import type { IState } from "@/store";
import type { ActionTree, MutationTree } from "vuex";
import _ from "@/boot/lodash";

export interface ISocketState {
  token: string;
}

const initialState: ISocketState = {
  token: ""
};

const actions: ActionTree<ISocketState, IState> = {
  register: ({ state, commit, rootState }) => {
    const token = _.get(
      rootState,
      `auth.${rootState.context}.token.access_token`,
      ""
    );
    if (!window.$rootVue || state.token === token) {
      return;
    }
    commit("setToken", token);
    // window.$rootVue.$socket.disconnect();
    if (!token) return;
    // window.$rootVue.$socket.connect();
    // window.$rootVue.$socket.emit("register", { token });
  },
  unsetToken: ({ commit }) => {
    if (window.$rootVue) {
      // window.$rootVue.$socket.disconnect();
    }
    commit("setToken", "");
  }
};

const mutations: MutationTree<ISocketState> = {
  setToken: (state: ISocketState, token: string) => {
    state.token = token;
  }
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations
};
