<template>
  <ticket-provider v-if="!!ticketId" :ticket-id="ticketId">
    <div class="u-client-ticket-layout">
      <!-- Billboard -->
      <client-ticket-billboard />

      <!-- Status -->
      <client-ticket-status />

      <!-- Secondary Content -->
      <template v-if="$store.getters['ui/isDesktopPlus']">
        <div class="u-client-ticket-secondary is-hidden-touch">
          <div>
            <client-ticket-details-component tag="u-aside" />
            <related-product-component tag="u-aside" />
          </div>
        </div>
      </template>

      <!-- Primary Content -->
      <div class="u-client-ticket-primary">
        <template v-if="$store.getters['ui/isTouch']">
          <div class="is-hidden-desktop has-margin-bottom-100">
            <client-ticket-details-component tag="u-section-box" />
            <related-product-component tag="u-section-box" />
          </div>
        </template>
        <client-ticket-feed />
      </div>
    </div>
  </ticket-provider>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { ITicket } from "@upmind-automation/types";

export default defineComponent({
  components: {
    TicketProvider: () =>
      import("@/components/app/global/tickets/ticketProvider"),
    ClientTicketBillboard: () =>
      import("@/components/app/client/support/clientTicketBillboard.vue"),
    ClientTicketDetailsComponent: () =>
      import(
        "@/components/app/client/support/clientTicketDetailsComponent.vue"
      ),
    RelatedProductComponent: () =>
      import("@/components/app/global/ticket/relatedProductComp.vue"),
    ClientTicketFeed: () =>
      import("@/components/app/client/support/clientTicketFeed.vue"),
    ClientTicketStatus: () =>
      import("@/components/app/client/support/clientTicketStatus.vue")
  },
  data: () => ({
    ticketId: "" as ITicket["id"]
  }),
  created() {
    this.ticketId = this.$route.params.ticketId;
  }
});
</script>

<style lang="scss" scoped>
$gap: 32px;
$secondaryWidth: 400px;

.u-client-ticket-layout {
  display: grid;
  grid-template-columns: 100%;
  column-gap: $gap;
  margin-bottom: $gap;

  @include desktop {
    $primaryWidth: $desktop - (3 * $gap) - $secondaryWidth - 32px;
    grid-template-columns: 1fr $secondaryWidth minmax(auto, $primaryWidth) 1fr;
    grid-template-rows: auto auto 1fr;
  }

  @include widescreen {
    $primaryWidth: $widescreen - (3 * $gap) - $secondaryWidth - 32px;
    grid-template-columns: 1fr $secondaryWidth minmax(auto, $primaryWidth) 1fr;
  }

  @include fullhd {
    $primaryWidth: $fullhd - (3 * $gap) - $secondaryWidth - 32px;
    grid-template-columns: 1fr $secondaryWidth minmax(auto, $primaryWidth) 1fr;
  }

  .u-client-ticket-billboard::v-deep {
    display: contents;

    &::before,
    .u-client-ticket-billboard-content {
      grid-row: 1;
      grid-column: 1;
    }

    &::before {
      content: "";
      background: $white;
      border-bottom: 1px solid $border;

      @include tablet {
        border-top: 1px solid $border;
      }

      @include desktop {
        grid-row: 1;
        grid-column: 1 / span 4;
      }
    }

    .u-client-ticket-billboard-content {
      @include desktop {
        grid-row: 1;
        grid-column: 3;
      }
    }
  }

  & > .u-st-wrapper::v-deep {
    display: contents;

    &::before {
      content: "";
      grid-row: 2;
      grid-column: 1;
      background: $white;
      border-bottom: 3px solid $border;
      @include desktop {
        grid-row: 2;
        grid-column: 1 / span 4;
      }
    }

    .u-st-content {
      grid-row: 2;
      grid-column: 1;
      padding: 1.125rem 1.5rem;

      @include desktop {
        grid-row: 2;
        grid-column: 3;
        padding: 1.125rem 0;
      }
    }
  }

  .u-client-ticket-primary {
    grid-row: 4;
    grid-column: 1;

    @include touch {
      padding: 1rem;
    }

    @include desktop {
      grid-row: 3;
      grid-column: 3;
      padding-top: $gap;
    }

    &::v-deep > div section.box:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
  .u-client-ticket-secondary {
    grid-row: 3;
    grid-column: 1;
    padding: 3rem 1rem;
    background: $white;
    border: 1px solid mix($border, $white, 50%);
    border-bottom-left-radius: $radius-large;
    border-bottom-right-radius: $radius-large;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);

    & > div {
      position: sticky;
      top: 6.25rem;
    }

    @include touch {
      margin: 1.5rem;
    }

    @include desktop {
      grid-row: 1 / span 3;
      grid-column: 2;
      z-index: 5;
    }
  }
}
</style>
