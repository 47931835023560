import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import { Methods } from "@/models/methods";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import type { ApiPathGetter } from "@/models/api";
import type { IUser } from "@/models/users";
import type { IUserTicketDeptPayload } from "@/models/userTicketDepartments";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath: (): ApiPathGetter => () => {
    const admin = `api/admin/users`;
    return { admin };
  },
  scope: () => id => {
    return `$user_${id}`;
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath().admin,
        storeModule: DataModules.USERS
      },
      { root: true }
    );
  },
  get: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload.id}`,
        storeModule: DataModules.USERS,
        params: {
          with_staged_imports: 1,
          ...payload.params
        }
      },
      { root: true }
    );
  },
  create: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/create",
      {
        ...payload,
        path: getters.apiPath().admin,
        storeModule: DataModules.USERS
      },
      { root: true }
    );
  },
  update: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/update",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload.id}`,
        storeModule: DataModules.USERS
      },
      { root: true }
    );
  },
  remove: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/remove",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload.id}`,
        storeModule: DataModules.USERS
      },
      { root: true }
    );
  },
  resendVerification: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.POST,
        path: `${getters.apiPath().admin}/resend_verification`,
        requestConfig: { data: payload.data }
      },
      { root: true }
    );
  },
  sendResetPasswordEmail: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.POST,
        path: `${getters.apiPath().admin}/${payload.user_id}/password_reset`
      },
      { root: true }
    );
  },
  search: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/fetch",
      {
        ...payload,
        method: Methods.GET,
        path: `${getters.apiPath().admin}/search`,
        requestConfig: { params: payload.params }
      },
      { root: true }
    );
  },
  openCreateModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import("@/components/app/admin/users/addEditUserModal.vue"),
          width: 840,
          ...payload
        }
      },
      { root: true }
    );
  },
  disable2FA: ({ dispatch, getters }, { user_id }) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.DELETE,
        path: `${getters.apiPath().admin}/${user_id}/twofa`,
        storeModule: DataModules.USERS
      },
      { root: true }
    );
  },
  openActivityLogModal: ({ dispatch }, user: IUser) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import("@/components/app/admin/users/activityLogModal.vue"),
          props: { user }
        }
      },
      { root: true }
    );
  },
  syncDepartments: (
    { dispatch, getters },
    {
      userId,
      data
    }: {
      userId: IUser["id"];
      data: {
        ticket_departments: IUserTicketDeptPayload[];
      };
    }
  ) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.POST,
        path: `${getters.apiPath().admin}/${userId}/ticket_departments/sync`,
        storeModule: DataModules.USERS,
        requestConfig: { data }
      },
      { root: true }
    );
  },
  listDepartments: (
    { dispatch, getters },
    {
      userId,
      payload
    }: {
      userId: IUser["id"];
      payload: IUserTicketDeptPayload[];
    }
  ) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.POST,
        path: `${getters.apiPath().admin}/${userId}/ticket_departments/sync`,
        storeModule: DataModules.USERS,
        requestConfig: {
          data: {
            ticket_departments: payload
          }
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  modules: {
    apiTokens: require("./apiTokens").default,
    ticketDepartments: require("./ticketDepartments").default
  }
};
