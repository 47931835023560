import type { IFilter } from "@/models/tables";
import { FilterOperators, FilterTypes } from "@/data/table";
import i18n from "@/i18n";
import { CreatedAtFilter } from "@/data/filters/object";

export const NameFilter: () => IFilter = () => ({
  key: "filename",
  label: i18n.t("_.filename"),
  type: FilterTypes.STRING,
  operator: FilterOperators.EQUAL
});

export const StartOfPeriodFilter = () => ({
  key: "start_of_period",
  label: i18n.t("_.reports.start_month"),
  type: FilterTypes.MONTH,
  operator: FilterOperators.AFTER
});

export const EndOfPeriodFilter = () => ({
  key: "end_of_period",
  label: i18n.t("_.reports.end_month"),
  type: FilterTypes.MONTH,
  operator: FilterOperators.AFTER
});

export const codeParam = (code: string) => ({
  "filter[report_code]": code
});

export const DefaultReportsFilters: () => IFilter[] = () => [
  NameFilter(),
  CreatedAtFilter()
];

export const DefaultRevenueReportFilters: () => IFilter[] = () => [
  StartOfPeriodFilter(),
  EndOfPeriodFilter()
];
