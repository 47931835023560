export default {
  install: (Vue: any) => {
    Object.defineProperty(Vue.prototype, "$uuid", {
      value: () => {
        return (
          Math.random().toString(36).slice(2) +
          Math.random().toString(36).slice(2)
        );
      }
    });
  }
};
