import { RegexMatch } from "@/data/enums/router";
import i18n from "@/i18n";

export function routes() {
  return [
    {
      label: "_.insights",
      children: [
        {
          label: "_.clients",
          to: { path: "/admin/insights/clients" },
          children: [
            {
              label: "_.new_clients",
              to: {
                path: `/admin/insights/clients/${RegexMatch.NEW}`
              }
            },
            {
              label: "_.client_sources",
              to: {
                path: "/admin/insights/clients/sources"
              }
            },
            {
              label: "_.client_by_country",
              to: {
                path: "/admin/insights/clients/country"
              }
            }
          ]
        },
        {
          label: "_.income",
          to: {
            path: "/admin/insights/income"
          },
          children: [
            {
              label: "_.annual_income",
              to: {
                path: "/admin/insights/income/annual"
              }
            },
            {
              label: "_.forecast",
              to: {
                path: "/admin/insights/income/forecast"
              }
            },
            {
              label: "_.income_by_product",
              to: {
                path: "/admin/insights/income/country"
              }
            },
            {
              label: "_.monthly_transactions",
              to: {
                path: "/admin/insights/income/monthly"
              }
            }
          ]
        }
      ]
    }
  ];
}

export function upmTrackingTabigation() {
  return [
    {
      label: i18n.t("_.clients"),
      to: {
        name: `upmTrackedReferralsClients`
      }
    },
    {
      label: i18n.t("_.orders"),
      to: {
        name: "upmTrackedReferralsOrders"
      }
    }
  ];
}
