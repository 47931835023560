import i18n from "@/i18n";
import { DataModules } from "@/store/modules/data/modules";
import { Methods } from "@/models/methods";
import { ConfirmModalModes } from "@/data/enums";
import type { IDataState } from "@/store/modules/data";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import type { IUser } from "@/models/users";
import type { ITicket } from "@upmind-automation/types";
import type { ApiPathGetter } from "@/models/api";
import type { IClient } from "@upmind-automation/types";
import type { ILead } from "@/models/leads";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext, isMockClientContext }): ApiPathGetter =>
    (path = "tickets") => {
      const admin = `api/admin/${path}`;
      const client = `api/${path}`;
      const contextual =
        isAdminContext && !isMockClientContext() ? admin : client;
      return { client, admin, contextual };
    },
  scope: () => (ticketId: ITicket["id"]) => {
    return `$ticket_${ticketId}`;
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters, rootGetters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath(
          /**
           * If user can 'list_tickets', we'll use endpoint to list all tickets,
           * otherwise we'll only list assigned tickets. For clients, 'user/can' will
           * always return true.
           */
          rootGetters["user/can"]("list_tickets")
            ? "tickets"
            : "tickets/assigned"
        ).contextual,
        splitCount: true,
        storeModule: DataModules.TICKETS
      },
      { root: true }
    );
  },
  listForGivenClient: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath(`clients/${payload.clientId}/tickets`).admin,
        splitCount: true,
        storeModule: DataModules.TICKETS
      },
      { root: true }
    );
  },
  listForGivenLead: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath(`leads/${payload.leadId}/tickets`).admin,
        splitCount: true,
        storeModule: DataModules.TICKETS
      },
      { root: true }
    );
  },
  get: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath().contextual}/${payload.id}`,
        storeModule: DataModules.TICKETS
      },
      { root: true }
    );
  },
  create: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/create",
      {
        ...payload,
        path: getters.apiPath().contextual,
        storeModule: DataModules.TICKETS
      },
      { root: true }
    );
  },
  update: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/update",
      {
        ...payload,
        path: `${getters.apiPath().contextual}/${payload.id}`,
        storeModule: DataModules.TICKETS
      },
      { root: true }
    );
  },
  reply: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.POST,
        path: `${getters.apiPath().contextual}/${payload.id}/replies`,
        requestConfig: { data: payload.data }
      },
      { root: true }
    );
  },
  replyUpdate: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.PUT,
        path: `${getters.apiPath().contextual}/${payload.id}/replies/${
          payload.replyId
        }`,
        requestConfig: { data: payload.data }
      },
      { root: true }
    );
  },
  assignUsers: (
    { dispatch, getters },
    {
      ticketId,
      users
    }: {
      ticketId: ITicket["id"];
      users: { id: IUser["id"]; default?: boolean }[];
    }
  ) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PUT,
        path: `${getters.apiPath().admin}/${ticketId}/users`,
        requestConfig: { data: { users } }
      },
      { root: true }
    );
  },
  unassignUser: (
    { dispatch, getters },
    { ticketId, userId }: { ticketId: ITicket["id"]; userId: IUser["id"] }
  ) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PATCH,
        path: `${getters.apiPath().admin}/${ticketId}/unassign`,
        requestConfig: { data: { user_id: userId } }
      },
      { root: true }
    );
  },
  changeStatus: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.PUT,
        path: `${getters.apiPath().contextual}/${payload.id}/status`,
        requestConfig: { data: { status_code: payload.statusCode } }
      },
      { root: true }
    );
  },
  downloadEmailFile: ({ dispatch, getters }, payload) => {
    const { ticketId } = payload;
    return dispatch(
      "data/callApi",
      {
        method: Methods.GET,
        path: `${getters.apiPath().admin}/${ticketId}/download`,
        returnData: false,
        requestConfig: { responseType: "arraybuffer" }
      },
      { root: true }
    );
  },
  openNewTicketModal: async (
    { dispatch },
    { props = {}, events = {} } = {}
  ) => {
    const openAddTicketModal = (props = {}, events = {}) => {
      return dispatch(
        "ui/open/modal",
        {
          config: {
            component: () =>
              import("@/components/app/global/tickets/addTicketModal.vue"),
            props,
            events
          }
        },
        { root: true }
      );
    };

    // If NO client, lead or account details passed, open select client modal
    if (!props?.clientId && !props?.accountId && !props?.leadId) {
      const modal = await dispatch(
        "data/clients/openSelectClientModal",
        {
          events: {
            onSelect: (client: IClient) => {
              props.clientId = client.id;
              props.accountId = client.accounts?.[0]?.id;
              props.brandId = client.brand_id;
              openAddTicketModal(props, events);
              modal.close();
            }
          }
        },
        {
          root: true
        }
      );
      return;
    }
    return openAddTicketModal(props, events);
  },
  openPreferencesModal: ({ dispatch }, payload) => {
    dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import("@/components/app/global/tickets/ticketSettingModal.vue"),
          ...payload
        }
      },
      { root: true }
    );
  },
  closeAllClientTickets: async (
    { dispatch },
    {
      client,
      lead,
      onSuccess
    }: { client?: IClient; lead?: ILead; onSuccess: Function }
  ) => {
    const confirm = await dispatch(
      "ui/open/confirmModal",
      {
        config: {
          props: {
            autoClose: false,
            mode: ConfirmModalModes.BUTTON,
            confirmButtonText: i18n.t("_action.yes_close_now"),
            message: i18n.t(
              client
                ? "_sentence.ticket.confirm_close_client_tickets"
                : "_sentence.ticket.confirm_close_lead_tickets",
              {
                context: client
                  ? client.fullname || client.public_name || client.email
                  : lead?.name || lead?.email
              }
            ),
            title: i18n.t("_action.close_all_tickets")
          },
          events: {
            confirmed: async () => {
              try {
                await dispatch(
                  "data/callApi",
                  {
                    method: Methods.POST,
                    path: `api/admin/bulk/tickets/close`,
                    requestConfig: {
                      data: {
                        ...(client ? { client_id: client.id } : {}),
                        ...(lead ? { lead_id: lead.id } : {})
                      }
                    }
                  },
                  { root: true }
                );
                dispatch(
                  "toast/show",
                  {
                    message: i18n.t("_sentence.tickets_successfully_closed")
                  },
                  { root: true }
                );
                onSuccess();
              } catch (error) {
                dispatch("api/handleError", error, { root: true });
              } finally {
                confirm.close();
              }
            }
          }
        }
      },
      { root: true }
    );
  },
  takeTicket: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/create",
      {
        ...payload,
        path: getters.apiPath("bulk/tickets/take").admin,
        storeModule: DataModules.TICKETS
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  modules: {
    departments: require("./departments").default,
    files: require("./files").default,
    messages: require("./messages").default
  }
};
