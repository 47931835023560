import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import type { ApiPathGetter } from "@/models/api";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  scope: () => id => {
    return `$webhook_${id}`;
  },
  webhooksScope: () => {
    return `$webhooks`;
  },
  apiPath: (): ApiPathGetter => () => {
    const admin = `api/admin/webhooks`;
    return { admin };
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath().admin,
        storeModule: DataModules.WEBHOOKS
      },
      { root: true }
    );
  },
  get: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload?.id}`,
        storeModule: DataModules.WEBHOOKS
      },
      { root: true }
    );
  },
  create: ({ dispatch, getters }, { data }) => {
    return dispatch(
      "data/create",
      {
        path: getters.apiPath().admin,
        storeModule: DataModules.WEBHOOKS,
        data
      },
      { root: true }
    );
  },
  update: ({ dispatch, getters }, { id, data }) => {
    return dispatch(
      "data/update",
      {
        path: `${getters.apiPath().admin}/${id}`,
        storeModule: DataModules.WEBHOOKS,
        data
      },
      { root: true }
    );
  },
  remove: ({ dispatch, getters }, { id }) => {
    return dispatch(
      "data/remove",
      {
        path: `${getters.apiPath().admin}/${id}`,
        storeModule: DataModules.WEBHOOKS
      },
      { root: true }
    );
  },
  openManageModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/modal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/admin/settings/webhooks/createWebhookModal.vue"
            ),
          ...payload,
          width: 640
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  modules: {
    events: require("./events").default,
    triggers: require("./triggers").default
  }
};
