import type { ActionTree } from "vuex";
import type { IState } from "@/store";
import type { QUERY_PARAMS } from "@/data/constants";
import $router from "@/router";
import _ from "@/boot/lodash";

const actions: ActionTree<IState, IState> = {
  removeQueryParams: (context, params: QUERY_PARAMS[] = []) => {
    const query = _.omit($router.currentRoute.query, params);
    return $router.replace({ query }).catch(err => err);
  }
};

export default {
  namespaced: true,
  actions
};
