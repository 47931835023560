export default {
  componentUpdated(el, binding, vnode) {
    const table = el.querySelector("table");
    const thead = table.querySelector("thead");
    const ths = thead.querySelectorAll("th");
    const barHeight = table.offsetHeight;

    const resizeContainer = vnode.context.$el;
    resizeContainer.style.width = table.offsetWidth + "px";

    const elements = el.getElementsByClassName("columns-resize-bar");
    while (elements.length > 0) elements[0].remove();
    let moving = false;
    let movingIndex = 0;
    let forwardStart: number[] = [];
    let backwardStart: number[] = [];
    let bars: any[] = [];
    const handleResize = e => {
      if (
        !moving ||
        forwardStart.includes(e.x) ||
        backwardStart.includes(e.x) ||
        !e.movementX
      ) {
        return;
      }
      if (e.movementX > 0) {
        forwardStart.push(e.x);
        backwardStart = [];
      } else {
        forwardStart = [];
        backwardStart.push(e.x);
      }
      const th = ths[movingIndex];
      th.style.width = th.offsetWidth + e.movementX + "px";
    };

    const handleMouseUp = () => {
      if (!moving) return;
      forwardStart = [];
      backwardStart = [];
      moving = false;
      document.body.style.cursor = "";
      document.body.style.userSelect = "";

      bars.forEach((bar: HTMLElement, index) => {
        const th = ths[index];
        th.style.width = th.offsetWidth + "px";
      });
      const all: { index: number; width: string }[] = [];
      ths.forEach((th, index) => {
        all.push({
          index,
          width: th.style.width
        });
      });
      vnode.context.$emit("columns-resizing", {
        ...all
      });
    };
    resizeContainer.removeEventListener("mousemove", handleResize);
    table.removeEventListener("mousemove", handleResize);
    document.removeEventListener("mouseup", handleMouseUp);

    ths.forEach((th, index) => {
      th.style.width = th.offsetWidth + "px";

      if (index >= ths.length) return;

      const bar = document.createElement("div");

      bar.style.position = "absolute";
      bar.style.right = "-4px";
      bar.style.top = "0";
      bar.style.height = barHeight + "px";
      bar.style.width = "8px";
      bar.style.cursor = "col-resize";
      bar.style.zIndex = "10";
      bar.className = "columns-resize-bar";

      bar.addEventListener("mousedown", () => {
        moving = true;
        movingIndex = index;
        document.body.style.cursor = "col-resize";
        document.body.style.userSelect = "none";
      });

      th.appendChild(bar);
    });

    bars = resizeContainer.querySelectorAll(".columns-resize-bar");
    document.addEventListener("mouseup", handleMouseUp);

    resizeContainer.addEventListener("mousemove", handleResize);
    table.addEventListener("mousemove", handleResize);
  }
};
