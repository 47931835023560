import i18n from "@/i18n";
import type { IMenuRoute } from "@/models/routes";

export const UpmindRoutes: () => IMenuRoute[] = () => [
  {
    label: i18n.t("_.overview"),
    to: {
      name: "admin__upmindOverview"
    }
  },
  {
    label: "_.products_and_services",
    to: (vm: any) => {
      return {
        name: "admin__upmindProducts",
        params: vm.$route.params
      };
    },
    children: [
      {
        label: "_.subscriptions",
        to: (vm: any) => {
          return {
            name: "admin__upmindSubscriptions",
            params: vm.$route.params
          };
        }
      },
      {
        label: "_.one_time_purchases",
        to: (vm: any) => {
          return {
            name: "admin__upmindOneTimePurchases",
            params: vm.$route.params
          };
        }
      }
    ]
  },
  {
    label: i18n.t("_.billing"),
    to: {
      name: "admin__upmindBilling"
    },
    children: [
      {
        label: i18n.t("_.orders"),
        to: {
          name: "admin__upmindOrders"
        }
      },
      {
        label: i18n.t("_.invoices"),
        to: {
          name: "admin__upmindInvoices"
        }
      },
      {
        label: i18n.t("_.credit_notes"),
        to: {
          name: "admin__upmindCreditNotes"
        }
      },
      {
        label: i18n.t("_.payment_methods"),
        to: {
          name: "admin__upmindPaymentMethods"
        }
      }
    ]
  },
  {
    label: i18n.t("_.support"),
    to: {
      name: "admin__upmindSupport"
    }
  },
  {
    label: i18n.t("_.settings"),
    to: {
      name: "admin__upmindSettings"
    },
    children: [
      {
        label: i18n.t("_.profile"),
        to: {
          name: "admin__upmindProfile"
        }
      },
      {
        label: i18n.t("_.notifications"),
        to: {
          name: "admin__upmindNotifications"
        }
      }
    ]
  }
];
