import VueScrollTo from "vue-scrollto";

export default {
  install: (Vue: any) => {
    Vue.use(VueScrollTo, {
      container: "body",
      duration: 400,
      easing: "ease",
      offset: 0,
      force: true,
      cancelable: true,
      onStart: false,
      onDone: false,
      onCancel: false,
      x: false,
      y: true
    });

    Object.defineProperty(Vue.prototype, "$scrollTo", {
      value: VueScrollTo.scrollTo
    });
  }
};
