import type { ActionTree, GetterTree } from "vuex";
import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { IState } from "@/store";
import type { ApiPathGetter } from "@/models/api";
import { Methods, type IContractProduct } from "@upmind-automation/types";
import type { IScheduledAction } from "@upmind-automation/types";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (): ApiPathGetter =>
    ({ contractId, productId }) => {
      const admin = `api/admin/contracts/${
        !!contractId && !!productId
          ? `${contractId}/products/${productId}/scheduled_actions`
          : `scheduled_actions/bulk`
      }`;
      return { admin };
    },
  bulkApiPath: (): ApiPathGetter => () => {
    const admin = `api/admin/contracts/scheduled_actions/bulk`;
    return { admin };
  },
  scope: () => (actionId: IScheduledAction["id"]) => {
    return `$scheduled_action_${actionId}`;
  },
  listScope: () => (productId: IContractProduct["id"]) => {
    return `$contract_products_${productId}_scheduled_actions`;
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath({
          contractId: payload.contractId,
          productId: payload.productId
        }).admin,
        storeModule: DataModules.CONTRACTS_PRODUCTS_SCHEDULED_ACTIONS
      },
      { root: true }
    );
  },
  create: ({ dispatch, getters }, { contractId, productId, data }) => {
    return dispatch(
      "data/create",
      {
        path: getters.apiPath({ contractId, productId }).admin,
        storeModule: DataModules.CONTRACTS_PRODUCTS_SCHEDULED_ACTIONS,
        data
      },
      { root: true }
    );
  },
  update: (
    { dispatch, getters },
    { contractId, productId, data, actionId }
  ) => {
    return dispatch(
      "data/update",
      {
        path: `${getters.apiPath({ contractId, productId }).admin}/${actionId}`,
        storeModule: DataModules.CONTRACTS_PRODUCTS_SCHEDULED_ACTIONS,
        data
      },
      { root: true }
    );
  },
  bulkUpdate: ({ dispatch, getters }, { data, filters }) => {
    return dispatch(
      "data/create",
      {
        path: getters.bulkApiPath().admin,
        storeModule: DataModules.CONTRACTS_PRODUCTS_SCHEDULED_ACTIONS,
        ...filters,
        data
      },
      { root: true }
    );
  },
  delete: (
    { dispatch, getters },
    { contractId, productId, scheduledActionId }
  ) => {
    return dispatch(
      "data/remove",
      {
        path: `${
          getters.apiPath({ contractId, productId }).admin
        }/${scheduledActionId}`,
        storeModule: DataModules.CONTRACTS_PRODUCTS_SCHEDULED_ACTIONS
      },
      { root: true }
    );
  },
  restore: (
    { dispatch, getters },
    { contractId, productId, scheduledActionId }
  ) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PATCH,
        path: `${
          getters.apiPath({ contractId, productId }).admin
        }/${scheduledActionId}/restore`,
        storeModule: DataModules.CONTRACTS_PRODUCTS_SCHEDULED_ACTIONS
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
