import store from "@/store";
import { RegexMatch, AdminRoutes } from "@/data/enums/router";
import { DocCategories } from "@/data/enums/docs";
import type { Location, Route } from "vue-router";
import EmailsHistoryProvider from "@/components/app/global/emailHistory/emailsHistoryProvider.vue";
import EmailHistoryTable from "@/components/app/global/emailHistory/emailHistoryTable.vue";
import { EmailTypes } from "@/data/enums/emails";
import { OrgFeatureKeys } from "@/data/constants";
import {
  IsSentParam,
  IsBouncedParam,
  IsFailedParam
} from "@/data/filters/emails";

export default [
  {
    path: "logs",
    components: {
      default: {
        name: "AdminLogs",
        template: `<router-view />`
      }
    },
    meta: {
      breadcrumb: {
        label: "_.logs"
      }
    },
    children: [
      {
        path: "",
        name: "adminLogs",
        redirect: { name: "adminActivityLogs" }
      },
      {
        path: "activity-logs",
        name: "adminActivityLogs",
        component: () => import("@/views/admin/logs/activityLogs/index.vue")
      },
      {
        path: "email-history",
        name: "adminEmailLogs",
        component: () => import("@/views/admin/logs/emailLogs/index.vue"),
        redirect: { name: "adminEmailLogsAll" },
        children: [
          {
            path: "failed",
            name: "adminEmailLogsFailed",
            component: {
              render(h) {
                return h(EmailsHistoryProvider, {
                  props: {
                    emailType: EmailTypes.BRAND,
                    manualFilters: {
                      ...IsFailedParam()
                    }
                  },
                  scopedSlots: { default: () => h(EmailHistoryTable) }
                });
              }
            },
            meta: {
              categorySlug: DocCategories.SETUP_CONFIGURATION,
              docsSlugs: ["email-messages.how-to-view-email-logs"]
            }
          },
          {
            path: "bounced",
            name: "adminEmailLogsBounced",
            component: {
              render(h) {
                return h(EmailsHistoryProvider, {
                  props: {
                    emailType: EmailTypes.BRAND,
                    manualFilters: {
                      ...IsBouncedParam()
                    }
                  },
                  scopedSlots: { default: () => h(EmailHistoryTable) }
                });
              }
            },
            meta: {
              categorySlug: DocCategories.SETUP_CONFIGURATION,
              docsSlugs: ["email-messages.how-to-view-email-logs"]
            }
          },
          {
            path: "sent",
            name: "adminEmailLogsSent",
            component: {
              render(h) {
                return h(EmailsHistoryProvider, {
                  props: {
                    emailType: EmailTypes.BRAND,
                    manualFilters: {
                      ...IsSentParam()
                    }
                  },
                  scopedSlots: { default: () => h(EmailHistoryTable) }
                });
              }
            },
            meta: {
              categorySlug: DocCategories.SETUP_CONFIGURATION,
              docsSlugs: ["email-messages.how-to-view-email-logs"]
            }
          },
          {
            path: "all",
            name: "adminEmailLogsAll",
            component: {
              render(h) {
                return h(EmailsHistoryProvider, {
                  props: {
                    emailType: EmailTypes.BRAND
                  },
                  scopedSlots: { default: () => h(EmailHistoryTable) }
                });
              }
            },
            children: [
              {
                path: `:emailId(${RegexMatch.UUID})`,
                name: "adminEmailLog",
                beforeEnter: async (
                  to: Route,
                  from: Route,
                  next: (options: boolean | Location | Location["path"]) => void
                ) => {
                  const { emailId } = to.params;
                  if (emailId) {
                    store.dispatch("ui/open/slideModal", {
                      config: {
                        component: () =>
                          import(
                            "@/components/app/global/emailHistory/viewEmailModal.vue"
                          ),
                        canCancel: ["button"],
                        props: {
                          viewRoute: () => ({
                            name: "adminEmailLog",
                            params: to.params
                          }),
                          emailId
                        }
                      }
                    });
                  }
                  store.dispatch("ui/endRouting");
                  return next(!from.matched.length); // Important to maintain pagination/filters etc on parent list view
                }
              }
            ],
            meta: {
              categorySlug: DocCategories.SETUP_CONFIGURATION,
              docsSlugs: ["email-messages.how-to-view-email-logs"]
            }
          }
        ]
      },
      {
        path: "provision-requests",
        name: AdminRoutes.LOGS_PROVISION_REQUESTS,
        beforeEnter: async (to, from, next) => {
          if (!store.getters[`org/hasProvisioningEnabled`])
            return next({ name: "adminLogs" });
          next();
        },
        component: () =>
          import("@/views/admin/logs/provisionRequests/index.vue"),
        meta: {
          backTo: "_.provision_requests_log",
          title: "_.provision_requests_log"
        },
        props: {
          routes: require("./menu").provisionRequestsLogQuickFilters
        }
      },
      {
        path: "webhook-events",
        name: AdminRoutes.ADMIN_LOGS_WEBHOOKS_EVENTS,
        component: () => import("@/views/admin/logs/webhookEvents/index.vue"),
        meta: {
          backTo: "_.webhook_events_log",
          title: "_.webhook_events_log",
          requiredFeatureKeys: [OrgFeatureKeys.WEBHOOKS]
        }
      }
    ]
  }
];
