import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import _ from "@/boot/lodash";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import type { ApiPathGetter } from "@/models/api";
import type {
  IBrandTicketDepartment,
  ITicketDepartment
} from "@upmind-automation/types";
import type { IBrand } from "@upmind-automation/types";
import i18n from "@/i18n";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext, isMockClientContext }): ApiPathGetter =>
    () => {
      const admin = `api/admin/tickets/departments`;
      const client = `api/tickets/departments`; // check
      const contextual =
        isAdminContext && !isMockClientContext() ? admin : client;
      return { client, admin, contextual };
    },
  scope: () => (departmentId: string) => {
    return `$ticket_department_${departmentId}`;
  },
  getDepartmentName:
    () => (department: ITicketDepartment, brandId: IBrand["id"]) => {
      const brandDepartments = (department?.brand_ticket_departments ||
        []) as IBrandTicketDepartment[];
      const brandDepartment = _.find(brandDepartments, ["brand_id", brandId]);
      return (
        brandDepartment?.name_translated ||
        brandDepartment?.name ||
        department?.name_translated ||
        department?.name ||
        i18n.t("_.no_department")
      );
    }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath().contextual,
        params: {
          limit: 0,
          with: "brand_ticket_departments",
          ...(payload.params || {})
        },
        storeModule: DataModules.SUPPORT_DEPARTMENTS
      },
      { root: true }
    );
  },
  get: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath().contextual}/${payload.id}`,
        params: {
          limit: 0,
          with: "brand_ticket_departments",
          ...(payload.params || {})
        },
        storeModule: DataModules.SUPPORT_DEPARTMENTS
      },
      { root: true }
    );
  },
  create: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/create",
      {
        ...payload,
        path: getters.apiPath().admin,
        storeModule: DataModules.SUPPORT_DEPARTMENTS
      },
      { root: true }
    );
  },
  update: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/update",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload.id}`,
        storeModule: DataModules.SUPPORT_DEPARTMENTS
      },
      { root: true }
    );
  },
  remove: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/remove",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload.id}`,
        storeModule: DataModules.SUPPORT_DEPARTMENTS
      },
      { root: true }
    );
  },
  openManageSupportDepartmentModal: ({ dispatch }, payload) => {
    dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/admin/settings/supportDepartments/manageSupportDepartmentModal.vue"
            ),
          width: 480,
          ...payload
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  modules: {
    brands: require("./brands").default
  }
};
