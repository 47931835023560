import Vue from "vue";
import _ from "@/boot/lodash";
import i18n from "@/i18n";
import router from "@/router";
import type { IState } from "@/store";
import store from "@/store";
import type { ActionTree, MutationTree, GetterTree } from "vuex";
import type { ApiPathGetter } from "@/models/api";
import type { IOrg } from "@/models/organisation";
import type { IUpgradePackagePayload } from "@/models/providers/upmind";
import type { IUpmindModule } from "@/models/upmindModules";
import { Methods } from "@/models/methods";
import { ModalCancelBy, ModalCancelByAll } from "@/data/enums";
import {
  OrgConfigKeys,
  OrgFeatureKeys,
  OrgPackageLimits
} from "@/data/constants";
import type { UpmindModuleCodes } from "@/data/enums/upmind";
import { UpmindProductCodes } from "@/data/enums/upmind";
import type { BModalConfig } from "buefy/types/components";

export interface IOrgState {
  config: {
    [Property in keyof typeof OrgConfigKeys]?: string | boolean | number;
  };
  enabledModules: IUpmindModule[];
  injectedModules: UpmindModuleCodes[];
  data: Pick<
    IOrg,
    | "upmind_contract_product"
    | "upmind_impersonation_enabled"
    | "upmind_impersonation_expiry"
    | "upmind_package_limits"
  >;
}

const initialState = (): IOrgState => {
  return {
    config: {},
    enabledModules: [],
    injectedModules: [],
    data: {
      upmind_contract_product: undefined,
      upmind_impersonation_enabled: false,
      upmind_impersonation_expiry: null,
      upmind_package_limits: undefined
    }
  };
};

const getters: GetterTree<IOrgState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext }): ApiPathGetter =>
    () => {
      const admin = `api/admin/org`;
      const client = `api/org`;
      const contextual = isAdminContext ? admin : client;
      return { client, admin, contextual };
    },
  availableModules: state => state.enabledModules.map(module => module.code),
  canRegisterModule: (state, getters) => (code: UpmindModuleCodes) => {
    const isAvailable = getters.availableModules.includes(code);
    const isInjected = state.injectedModules.includes(code);
    return isAvailable && !isInjected;
  },
  checkOrgFeatureKeys:
    ({ config }) =>
    (keys: OrgFeatureKeys[]) => {
      const missingKeys = keys.filter(key => config[key] !== true);
      return { missingKeys };
    },
  currentLicense: ({ data }) => {
    return data.upmind_contract_product;
  },
  getPackageLimit:
    ({ data }) =>
    (featureKey: OrgFeatureKeys) => {
      switch (featureKey) {
        case OrgFeatureKeys.UNLIMITED_PAYMENT_GATEWAYS:
          return data.upmind_package_limits?.[OrgPackageLimits.PAYMENT_GATEWAYS]
            ?.quota;
        case OrgFeatureKeys.UNLIMITED_PROVISION_CONFIGURATIONS:
          return data.upmind_package_limits?.[
            OrgPackageLimits.PROVISION_CONFIGURATIONS
          ]?.quota;
        default:
          return;
      }
    },
  hasFreeLicense: (s, { currentLicense }) => {
    const productCode = currentLicense?.product?.code;
    return productCode === UpmindProductCodes.FREE_SINGLE_BRAND_PRODUCT;
  },
  hasModuleEnabled: (state, getters) => (code: UpmindModuleCodes) => {
    const isAvailable = getters.availableModules.includes(code);
    const isInjected = state.injectedModules.includes(code);
    return isAvailable && isInjected;
  },
  hasPendingUpgrade: ({ data }) => {
    return !!data.upmind_contract_product?.pro_rata_pending;
  },
  hasProvisioningEnabled: ({ config }) =>
    // By default, assume product provisioning is NOT enabled
    (config[OrgConfigKeys.PRODUCT_PROVISIONING_ENABLED] ?? false) === true,
  hasReachedLimit:
    ({ data }) =>
    (key: OrgPackageLimits, total: number) => {
      const limit = data.upmind_package_limits?.[key];
      return typeof limit === "number" && total >= limit;
    },
  hasUpmindBrandingEnabled: ({ config }) =>
    // By default, assume Upmind branding it NOT to be shown
    (config[OrgConfigKeys.REMOVE_UPMIND_BRANDING_ENABLED] ?? true) === false,
  hasMultibrandEnabled: ({ config }) => {
    return config[OrgConfigKeys.MULTI_BRAND_ENABLED] || false;
  }
};

const actions: ActionTree<IOrgState, IState> = {
  getEnabledModules: async ({ commit, dispatch, getters }) => {
    try {
      const { data } = await dispatch(
        "api/call",
        {
          method: Methods.GET,
          path: [getters.apiPath().contextual, "modules"].join("/"),
          callConfig: {
            withAccessToken: false
          }
        },
        { root: true }
      );
      commit("enabledModules", data);
    } catch (e) {
      // Silent (non-blocking) catch
      // eslint-disable-next-line no-console
      console.error(e);
    }
  },
  getAndRegisterModules: async ({ getters, dispatch }) => {
    await dispatch("getEnabledModules");
    for (const code of getters.availableModules as UpmindModuleCodes[]) {
      await dispatch("registerModule", code);
    }
  },
  registerModule: async (
    { getters, rootState, commit },
    code: UpmindModuleCodes
  ) => {
    if (!getters.canRegisterModule(code)) return;
    try {
      // Import routes
      const importModulesRouterHelper = import.meta.glob(
        "../../../modules/*/router/index.ts"
      );
      const moduleRouter = await importModulesRouterHelper[
        `../../../modules/${code}/router/index.ts`
      ]();
      const routes = moduleRouter.default;
      // Add imported routes
      _.forEach(routes, route => router.addRoute("adminRoute", route));

      // Register store module
      const importModulesStoreHelper = import.meta.glob(
        "../../../modules/*/store/index.ts"
      );
      const moduleStore = await importModulesStoreHelper[
        `../../../modules/${code}/store/index.ts`
      ]();
      store.registerModule(`data/${code}`, moduleStore.default);

      // Load language packs
      await store.dispatch("i18n/loadLanguagePacks", {
        localeCode: rootState.i18n.activeLocaleCode,
        packs: [`_modules.${code}`]
      });
      // Commit injection
      commit("injectModule", code);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(i18n.t("_error.module_not_found", { module: code }), error);
    }
  },
  getConfig: async (
    { commit, dispatch, state, rootGetters },
    {
      keys,
      ignoreStored = false
    }: { keys: Array<keyof typeof OrgConfigKeys>; ignoreStored: boolean }
  ) => {
    if (!ignoreStored) {
      let i = keys.length;
      while (i--) {
        const key = keys[i];
        if (_.has(state.config, key)) {
          keys.splice(i, 1);
        }
      }
    }
    if (!keys.length) return state.config;

    const { data } = await dispatch(
      "api/call",
      {
        method: Methods.GET,
        path: rootGetters.isAuthenticatedAdminContext
          ? "api/admin/config/organisation/values"
          : "api/config/organisation/values",
        callConfig: {
          /** Here we make sure to only pass an access token when in an
           * authenticated admin context, otherwise the app's boot sequence
           * can break for users with an expired access token */
          withAccessToken: rootGetters.isAuthenticatedAdminContext
        },
        requestConfig: {
          params: {
            keys: keys.join(",")
          }
        }
      },
      { root: true }
    );
    commit("config", data);
    return data;
  },
  openResendVerificationModal: async ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/windowModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/global/auth/resendOrgVerificationModal.vue"
            ),
          width: 540,
          ...payload
        }
      },
      { root: true }
    );
  },
  resendVerification: async ({ dispatch, rootGetters }, data) => {
    return dispatch(
      "api/call",
      {
        method: Methods.POST,
        path: rootGetters.isAuthenticatedAdminContext
          ? "api/admin/organisations/resend_verification"
          : "api/organisations/resend_verification",
        requestConfig: { data },
        callConfig: {
          withAccessToken: rootGetters.isAuthenticatedAdminContext
        }
      },
      { root: true }
    );
  },
  guardMissingFeatureKeys: async (
    { dispatch, getters },
    {
      featureKeys = [],
      onCancelUpgrade = undefined
    }: { featureKeys: OrgFeatureKeys[]; onCancelUpgrade?: Function }
  ) => {
    // Check for missing feature keys
    const { missingKeys } = getters["checkOrgFeatureKeys"](featureKeys);
    // If missing feature keys
    if (missingKeys.length)
      await dispatch("openUpgradeModal", {
        props: {
          missingKeys,
          cancelText: _.isFunction(onCancelUpgrade)
            ? i18n.t("_action.go_back")
            : undefined
        },
        onCancel: onCancelUpgrade
      } as Partial<BModalConfig>);
    // Resolve action
    return Promise.resolve({
      missingKeys,
      hasMissingKeys: !!missingKeys.length
    });
  },
  openUpgradeModal: async (
    { dispatch, rootGetters },
    config: Partial<BModalConfig>
  ) => {
    return dispatch(
      "ui/open/windowModal",
      {
        config: {
          component: () =>
            import("@/components/app/admin/upmind/upgradeModal.vue"),
          ...(rootGetters["org/hasPendingUpgrade"]
            ? { width: 540, canCancel: ModalCancelByAll }
            : { width: 1200, canCancel: [ModalCancelBy.BUTTON] }),
          ...config
        }
      },
      { root: true }
    );
  },
  getPackageUpgrades: async ({ dispatch, getters }) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.GET,
        path: [getters.apiPath().client, "packages/upgradable"].join("/"),
        requestConfig: {
          params: {
            with: ["prices", "products_options.prices"].join()
          }
        }
      },
      { root: true }
    );
  },
  getPackagesByFeature: async ({ dispatch, getters }) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.GET,
        path: [getters.apiPath().client, "packages"].join("/")
      },
      { root: true }
    );
  },
  upgradePackage: async (
    { dispatch, getters },
    payload: IUpgradePackagePayload
  ) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PUT,
        path: [getters.apiPath().client, "packages/upgrade"].join("/"),
        requestConfig: {
          data: payload
        }
      },
      { root: true }
    );
  },
  update: (
    { commit, dispatch, getters },
    { orgId, data }: { orgId: IOrg["id"]; data: Partial<IOrgState["data"]> }
  ) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PUT,
        path: [getters.apiPath().admin, orgId].join("/"),
        requestConfig: {
          data
        }
      },
      { root: true }
    ).then(() => commit("data", data));
  }
};

const mutations: MutationTree<IOrgState> = {
  config: (state, config: Partial<IOrgState["config"]>) => {
    Vue.set(state, "config", { ...state.config, ...config });
  },
  data: (state, data: Partial<IOrgState["data"]>) => {
    Vue.set(state, "data", { ...state.data, ...data });
  },
  enabledModules: (state, modules) => {
    state.enabledModules = _.uniqBy(
      [...modules, ...state.enabledModules],
      i => i.code
    );
  },
  injectModule: ({ injectedModules }, code: UpmindModuleCodes) => {
    if (!injectedModules?.includes(code)) injectedModules.push(code);
  }
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations
};
