export enum DeviceTypes {
  MOBILE = "mobile",
  DESKTOP = "desktop"
}

export enum ContrastColor {
  WHITE = "white",
  BLACK = "black"
}

export enum ContrastMode {
  LIGHT = "light",
  DARK = "dark"
}
