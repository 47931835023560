import type { IDataModuleState, IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { GetterTree, MutationTree } from "vuex";
import type { IUserNotification } from "@/models/userNotifications";
import _ from "@/boot/lodash";
import {
  NotificationStatuses,
  NotificationTypes
} from "@/data/enums/notifications";
import Vue from "vue";
import { Methods } from "@/models/methods";
import { UNotificationProgrammatic } from "@/plugins/UNotification";
import type { ActionTree } from "vuex";
import type { IState } from "@/store";
import type { ApiPathGetter } from "@/models/api";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext }): ApiPathGetter =>
    () => {
      const admin = "api/admin/notifications";
      const client = "api/notifications";
      const contextual = isAdminContext ? admin : client;
      return { client, admin, contextual };
    },
  loggedUserScope: (s, g, rootState) => () => {
    return `$user_${rootState.user?.id}`;
  },
  loggedUserUnreadScope: (s, g, rootState) => () => {
    return `$user_${rootState.user?.id}_unread`;
  },
  hasUnread: (state, getters) => (scope?: string) => {
    scope = scope || getters["loggedUserUnreadScope"]();
    return (_.get(state, `${scope}.total`, 0) as number) > 0;
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ getters, dispatch }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath().contextual,
        storeModule: DataModules.USER_NOTIFICATIONS
      },
      { root: true }
    );
  },
  get: ({ getters, dispatch }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath().contextual}/${payload.id}`,
        storeModule: DataModules.USER_NOTIFICATIONS
      },
      { root: true }
    );
  },
  dismissAll: async ({ dispatch, getters }) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PUT,
        path: getters.apiPath().contextual
      },
      { root: true }
    );
  },
  updateStatus: ({ dispatch, getters }, { notificationId, status }) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PUT,
        path: `${getters.apiPath().contextual}/${notificationId}/status`,
        storeModule: DataModules.USER_NOTIFICATIONS,
        requestConfig: {
          data: {
            status
          }
        }
      },
      { root: true }
    );
  },
  refreshHasUnread: async ({ dispatch, getters }, payload) => {
    return dispatch("list", {
      ignoreStored: true,
      returnData: false,
      scope: _.get(payload, "scope", getters["loggedUserUnreadScope"]()),
      params: {
        "filter[message_type]": NotificationTypes.PRIVATE,
        "filter[status]": NotificationStatuses.UNREAD,
        limit: "count"
      }
    });
  },
  addNewUnread: async (
    { dispatch, commit, getters },
    notification: IUserNotification
  ) => {
    const notificationWithRelations = await dispatch("get", {
      id: notification.id,
      storeData: false,
      params: {
        with: "object"
      }
    });
    commit("push", {
      notification: notificationWithRelations,
      scope: getters["loggedUserScope"]()
    });
    commit("incrementTotal", {
      scope: getters["loggedUserUnreadScope"]()
    });
    return dispatch("showBrowserNotification", {
      notification: notificationWithRelations
    });
  },
  markRead: async (
    { dispatch, getters, commit },
    {
      notification,
      scope,
      remove = true
    }: { notification: IUserNotification; scope: string; remove: boolean }
  ) => {
    scope = scope || getters["loggedUserScope"]();

    try {
      await dispatch("updateStatus", {
        notificationId: notification.id,
        status: NotificationStatuses.READ
      });
      commit("markRead", {
        notification,
        scope
      });

      if (remove) {
        commit(
          `data/binData`,
          {
            scope,
            keys: [notification.id],
            storeModule: DataModules.USER_NOTIFICATIONS
          },
          { root: true }
        );
      }
    } catch (error) {
      dispatch("api/handleError", error, { root: true });
    }
    return dispatch("refreshHasUnread");
  },
  showBrowserNotification: (
    { rootState, dispatch },
    { notification, duration = 5000 }
  ) => {
    if (!("Notification" in window)) {
      return UNotificationProgrammatic.open({
        props: {
          userNotification: notification
        }
      });
    }
    if (Notification.permission !== "granted") {
      Notification.requestPermission();
    }
    if (Notification.permission !== "granted") {
      return UNotificationProgrammatic.open({
        props: {
          duration,
          userNotification: notification
        }
      });
    }

    const browserNotification = new Notification(rootState.brand.name, {
      body: notification.message,
      vibrate: [200, 100, 200],
      requireInteraction: true
    });
    browserNotification.onclick = () =>
      dispatch("markRead", {
        notification
      });

    setTimeout(() => {
      browserNotification.close();
    }, duration);
  }
};

const mutations: MutationTree<IDataModuleState> = {
  markRead: (
    state,
    { scope, notification }: { scope: string; notification: IUserNotification }
  ) => {
    if (!_.has(state, `${scope}.data.${notification.id}`)) {
      return;
    }

    state[scope].data[notification.id].status = NotificationStatuses.READ;
  },
  push: (
    state,
    { scope, notification }: { scope: string; notification: IUserNotification }
  ) => {
    if (!state[scope] || _.has(state, `${scope}.data.${notification.id}`)) {
      return;
    }

    if (!_.has(state, `${scope}.data`)) Vue.set(state[scope], "data", {});

    Vue.set(state[scope].data, notification.id, notification);
    // @ts-ignore
    Vue.set(state[scope], "total", state[scope].total + 1);
  },
  incrementTotal: (state, { scope }: { scope: string }) => {
    if (!_.has(state, scope)) {
      return;
    }
    // @ts-ignore
    Vue.set(state[scope], "total", state[scope].total + 1);
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
};
