import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import { Methods } from "@/models/methods";
import type { ApiPathGetter } from "@/models/api";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  scope:
    () =>
    ({ id }) => {
      return `$affiliate_payout_destination_${id}`;
    },
  apiPath:
    (s, g, rS, { isAdminContext }): ApiPathGetter =>
    () => {
      const admin = "api/admin/brands";
      const client = "api/brands";
      const contextual = isAdminContext ? admin : client;
      return { client, admin, contextual };
    }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `${getters.apiPath().contextual}/${
          payload.brandId
        }/affiliate_payout_destination`,
        storeModule: DataModules.AFFILIATES_PAYOUT_DESTINATIONS
      },
      { root: true }
    );
  },
  get: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath().contextual}/${
          payload.brandId
        }/affiliate_payout_destination/${payload.id}`,
        storeModule: DataModules.AFFILIATES_PAYOUT_DESTINATIONS
      },
      { root: true }
    );
  },
  create: ({ dispatch }, payload) => {
    return dispatch(
      "data/create",
      {
        ...payload,
        path: `api/admin/brands/${payload.brandId}/affiliate_payout_destination`,
        storeModule: DataModules.AFFILIATES_PAYOUT_DESTINATIONS
      },
      { root: true }
    );
  },
  update: ({ dispatch }, payload) => {
    return dispatch(
      "data/update",
      {
        ...payload,
        path: `api/admin/brands/${payload.brandId}/affiliate_payout_destination/${payload.id}`,
        storeModule: DataModules.AFFILIATES_PAYOUT_DESTINATIONS
      },
      { root: true }
    );
  },
  remove: ({ dispatch }, payload) => {
    return dispatch(
      "data/remove",
      {
        ...payload,
        path: `api/admin/brands/${payload.brandId}/affiliate_payout_destination/${payload.id}`,
        storeModule: DataModules.AFFILIATES_PAYOUT_DESTINATIONS
      },
      { root: true }
    );
  },
  availablePayoutDestinations: ({ dispatch }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.GET,
        path: `api/admin/available_payout_destinations`,
        storeModule: DataModules.AFFILIATES_PAYOUT_DESTINATIONS,
        ...payload
      },
      { root: true }
    );
  },
  openAddEditModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          width: 540,
          canCancel: ["button", "x"],
          component: () =>
            import(
              "@/components/app/admin/settings/affiliates/payoutDestinations/addEditAffiliatePayoutDestinationModal.vue"
            ),
          ...payload
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
