import type { ActionTree } from "vuex";
import type { IState } from "@/store";
import type { IDataState } from "@/store/modules/data";

const initialState = {} as IDataState;

const actions: ActionTree<IDataState, IState> = {
  openManageBusinessTypesModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/global/tax/manageTaxBusinessTypeModal.vue"
            ),
          width: 540,
          ...payload
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  actions,
  state: initialState,
  modules: {
    tags: require("./tags").default,
    templates: require("./templates").default,
    locations: require("./locations").default
  }
};
