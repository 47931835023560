<script lang="ts">
import { defineComponent } from "vue";
import { mapState } from "vuex";
import _ from "@/boot/lodash";
import Context from "@/mixins/context";
import BrandsStore from "@/mixins/brandsStore";
import RequestPromises from "@/mixins/requestPromises";
import CProdMixin from "@/mixins/cProdMixin";
import { DataModules } from "@/store/modules/data/modules";
import { QUERY_PARAMS } from "@/data/constants";
import {
  CancellationRequestStatusCodes as CRSC,
  ScheduledActionTypes
} from "@upmind-automation/types";
import type {
  IContractProduct,
  IScheduledAction
} from "@upmind-automation/types";
import { BrandConfigKeys } from "@/data/constants";
import {
  ContractStatusCodes as CSC,
  ContractStatusMapped
} from "@/models/contracts";
import { InvoiceCategoryCode } from "@/models/invoices";
import { ConfirmModalModes, FraudStatus, ModalTypes } from "@/data/enums";
import { InvoiceStatus, InvoiceConsolidationTypes } from "@/data/enums/invoice";
import { ClientRoutes, UpmindClientRoutes } from "@/data/enums/router";
import {
  ProductCategoryTypes,
  ProductTypes,
  TrialEndActionTypes
} from "@/data/enums/products";
import type { PropType, CreateElement } from "vue";
import type { IState } from "@/store";
import type {
  IContract,
  IContractCancellationRequest
} from "@/models/contracts";
import type { IProduct } from "@/models/products";
import type {
  ICProdAction,
  ICProdProvider,
  ICProdProviderMethods
} from "@/models/providers/contractProduct";
import type { IInvoice } from "@/models/invoices";
import type { IModalComponent } from "@/models/modal";
import type { IStatus } from "@/models/statuses";
import type { IProvider } from "@/models/providers";
import type { IShopListItem } from "@/models/shop";

const CProdProvider = defineComponent({
  name: "CProdProvider",
  mixins: [Context, BrandsStore, RequestPromises, CProdMixin],
  provide() {
    return {
      contractProductProvider: this.contractProductProvider,
      contractProductProviderMethods: this.contractProductProviderMethods
    };
  },
  props: {
    productId: {
      type: String as PropType<IContractProduct["id"]>,
      default: null
    },
    productOptionId: {
      type: String as PropType<IContractProduct["id"]>,
      default: null
    },
    productAttributeId: {
      type: String as PropType<IContractProduct["id"]>,
      default: null
    },
    contractId: { type: String as PropType<IContract["id"]>, required: true },
    handleLoading: { type: Boolean, default: true },
    loaderAttrs: {
      type: Object as PropType<Record<string, any>>,
      default: () => ({})
    },
    handleRequestError: { type: Boolean, default: true },
    onNotFound: {
      type: Function as PropType<IProvider["onNotFound"]>,
      default: (vm: typeof CProdProvider) => {
        const name = vm.isAdmin
          ? "adminClientCProds"
          : vm.isMockClientContext
          ? "admin__upmindAllProducts"
          : ClientRoutes.PRODUCTS_ALL;
        return vm.$router.replace({ name }).catch(err => err);
      }
    }
  },
  data: () => ({
    hasRequestError: false,
    isLoading: true,
    isProcessing: false,
    isReloading: false,
    nextDueOrderInvoice: null as IInvoice | null,
    nextDueRenewalInvoice: null as IInvoice | null,
    unpaidInvoices: [] as IInvoice[],
    migrationsCount: 0
  }),
  computed: {
    ...mapState({
      contractScope(state: IState, getters): string {
        return getters[`data/${DataModules.CONTRACTS}/scope`](this.contractId);
      },
      contract(state: IState, getters): IContract {
        return getters[`data/single`]({
          storeModule: DataModules.CONTRACTS,
          scope: this.contractScope
        });
      }
    }),
    contractProductOption() {
      return this.$_.flatMap(
        this.contract?.products,
        product => product.options
      )?.find(i => i.id === this.productOptionId);
    },
    contractProductAttribute() {
      return this.$_.flatMap(
        this.contract?.products,
        product => product.attributes
      )?.find(i => i.id === this.productAttributeId);
    },
    contractProduct() {
      const product =
        (_.find(this.contract?.products || [], [
          "id",
          this.productId
        ]) as IContractProduct) ||
        this.contractProductOption ||
        this.contractProductAttribute;
      return (
        product && {
          ...product,
          client_id: this.contract?.client_id,
          client: this.contract?.client
        }
      );
    },
    product() {
      return _.get(this.contractProduct, "product") as IProduct;
    },
    contractProductId() {
      return this.productId || this.productOptionId || this.productAttributeId;
    },
    status() {
      const status = _.get(this.contractProduct, "status") as IStatus;
      return status ? ContractStatusMapped(status) : status;
    },
    enabledCV(): InvoiceConsolidationTypes {
      const clientValue = _.get(
        this.contract,
        "client.invoice_consolidation_enabled"
      );
      const brandValue = this.$store.getters["brand/config"][
        BrandConfigKeys.INVOICE_CONSOLIDATION_ENABLED
      ]
        ? InvoiceConsolidationTypes.ENABLED
        : InvoiceConsolidationTypes.DISABLED;

      return clientValue === InvoiceConsolidationTypes.INHERIT
        ? brandValue
        : clientValue;
    },
    cProdBillingRoute() {
      return this.$store.getters[
        `data/${DataModules.INVOICES}/getCProdBillingRoute`
      ]({
        clientId: this.contract?.client_id,
        contractId: this.contract?.id,
        productId: this.contractProduct?.id
      });
    },
    cProdStatusCode() {
      return _.get(this.contractProduct, "status.code") as CSC;
    },
    isDelegatedAccess() {
      return !!_.get(this.contractProduct, "is_delegated_object");
    },
    hasMoved() {
      return this.$store.getters[
        `data/${DataModules.CONTRACTS_PRODUCTS}/hasMoved`
      ](this.contractProduct);
    },
    isStaged() {
      return this.$store.getters[
        `data/${DataModules.CONTRACTS_PRODUCTS}/isStaged`
      ](this.contractProduct);
    },
    isImported() {
      return this.$store.getters[
        `data/${DataModules.CONTRACTS_PRODUCTS}/isImported`
      ](this.contractProduct);
    },
    isSubscription() {
      return this.$store.getters[
        `data/${DataModules.CONTRACTS_PRODUCTS}/isSubscription`
      ](this.contractProduct);
    },
    isActive() {
      return this.$store.getters[
        `data/${DataModules.CONTRACTS_PRODUCTS}/isActive`
      ](this.contractProduct);
    },
    isAwaitingActivation() {
      return this.$store.getters[
        `data/${DataModules.CONTRACTS_PRODUCTS}/isAwaitingActivation`
      ](this.contractProduct);
    },
    isAwaitingSetup() {
      return this.$store.getters[
        `data/${DataModules.CONTRACTS_PRODUCTS}/isAwaitingSetup`
      ](this.contractProduct);
    },
    isCancelled() {
      return this.$store.getters[
        `data/${DataModules.CONTRACTS_PRODUCTS}/isCancelled`
      ](this.contractProduct);
    },
    isSuspended() {
      return this.$store.getters[
        `data/${DataModules.CONTRACTS_PRODUCTS}/isSuspended`
      ](this.contractProduct);
    },
    isLapsed() {
      return this.$store.getters[
        `data/${DataModules.CONTRACTS_PRODUCTS}/isLapsed`
      ](this.contractProduct);
    },
    isPending() {
      return this.$store.getters[
        `data/${DataModules.CONTRACTS_PRODUCTS}/isPending`
      ](this.contractProduct);
    },
    isOnTrial() {
      return this.$store.getters[
        `data/${DataModules.CONTRACTS_PRODUCTS}/isOnTrial`
      ](this.contractProduct);
    },
    isCancellable() {
      if (this.hasHardCancellationRequest) return false;
      if (this.isClient && this.hasAcceptedCancellationRequest) return false;
      if (this.isClient && this.hasAutoExpireEnabled) return false;
      if (this.isSubscription)
        return [
          CSC.AWAITING_ACTIVATION,
          CSC.ACTIVE,
          CSC.SUSPENDED,
          CSC.PENDING
        ].includes(this.cProdStatusCode);
      return [CSC.PENDING].includes(this.cProdStatusCode);
    },
    isProvisionable() {
      return (
        this.$store.getters["org/hasProvisioningEnabled"] &&
        !_.isNull(this.product?.provision_blueprint_id)
      );
    },
    hasAutoExpireEnabled() {
      return this.$store.getters[
        `data/${DataModules.CONTRACTS_PRODUCTS}/hasAutoExpireEnabled`
      ](this.contractProduct);
    },
    hasAutoRenewDisabled() {
      return this.$store.getters[
        `data/${DataModules.CONTRACTS_PRODUCTS}/hasAutoRenewDisabled`
      ](this.contractProduct);
    },
    canModifyBilling() {
      if (this.isStaged) return false;
      if (!this.isAdmin) return false;
      if (!this.isSubscription) return false;
      if (!this.contractProduct?.can_modify) return false;
      return this.$userCan(
        "modify_contract_product_selling_price",
        "modify_contract_product_billing_terms"
      );
    },
    canModifySettings() {
      if (!this.isSubscription) return false;
      if (this.isDelegatedAccess) return false;
      return true;
    },
    canCreateNextInvoice() {
      if (!this.isSubscription) return false;
      if (this.isStaged) return false;
      return !!this.contractProduct?.can_create_next_invoice;
    },
    canUpgradeDowngrade() {
      if (!this.isAdmin) return false;
      if (this.isStaged) return false;
      if (!this.isSubscription) return false;
      return this.canModifyBilling;
    },
    isSingleProduct() {
      return this.product?.product_type === ProductTypes.SINGLE_PRODUCT;
    },
    canUpgradeDowngradeAsClient() {
      if (this.isAdmin) return false;
      if (!this.contractProduct?.can_modify) return false;
      if (this.hasHardCancellationRequest) return false;
      if (this.hasAcceptedCancellationRequest) return false;
      if (this.hasAutoExpireEnabled) return false;
      if (!this.isSingleProduct) return false;
      return !!this.allowedMigrations?.length;
    },
    canSchedulePriceChange() {
      if (!this.isAdmin) return false;
      if (this.isStaged) return false;
      if (!this.isSubscription) return false;
      if (this.isCancelled) return false;
      if (this.isLapsed) return false;
      return this.$userCan("create_contract_product_scheduled_actions");
    },
    canChangeBillingEntity() {
      if (this.isStaged) return false;
      if (!this.isSubscription) return false;
      if ([CSC.CLOSED].includes(this.status?.code as CSC)) return false;
      return this.$userCan("change_contract_address_company_vat");
    },
    canChangeCurrency() {
      if (!this.isAdmin) return false;
      if (this.isStaged) return false;
      if (![CSC.ACTIVE, CSC.SUSPENDED].includes(this.status?.code as CSC))
        return false;
      return this.$userCan("switch_currency");
    },
    canTransferOwnership() {
      if (!this.isAdmin) return false;
      if (this.isStaged) return false;
      if (this.hasMoved) return false;
      if (![CSC.ACTIVE, CSC.SUSPENDED].includes(this.status?.code as CSC))
        return false;
      return this.$userCan("switch_ownership");
    },
    canActivate() {
      if (!this.isAdmin) return false;
      if (this.isStaged) return false;
      if (this.hasMoved) return false;
      if (this.status?.code !== CSC.AWAITING_ACTIVATION) return false;
      return this.$userCan("activate_contract_product");
    },
    canReactivate() {
      if (!this.isAdmin) return false;
      if (this.isStaged) return false;
      if (this.hasMoved) return false;
      if (this.contractProduct?.pro_rata_pending) return false;
      if (![CSC.CANCELLED, CSC.CLOSED].includes(this.status?.code as CSC))
        return false;
      return this.$userCan("reactivate_contract_product");
    },
    canModifyStatus() {
      if (!this.isAdmin) return false;
      if (this.isStaged) return false;
      if (this.hasMoved) return false;
      return [CSC.ACTIVE, CSC.SUSPENDED, CSC.CANCELLED, CSC.CLOSED].includes(
        this.status?.code as CSC
      );
    },
    canDisableAutoRenew() {
      if (this.isAdmin) return true;
      return this.product?.can_disable_auto_create_renew_invoice ?? true;
    },
    canUpdateContractProduct() {
      if (this.isStaged) return false;
      return this.$userCan("update_contract_product");
    },
    hasManualStatus() {
      if (this.hasMoved) return false;
      return this.contractProduct?.manual_status;
    },
    currency() {
      return this.$store.getters["constants/getCurrency"](
        _.get(this.contract, "currency_id")
      );
    },
    unpaidOverdueInvoices() {
      return _.filter(this.unpaidInvoices, invoice => {
        return [InvoiceStatus.OVERDUE].includes(
          invoice.status.code as InvoiceStatus
        );
      });
    },
    entity() {
      return this.$store.getters[
        `data/${DataModules.CONTRACTS_PRODUCTS}/entity`
      ](this.contractProduct);
    },
    productName() {
      return this.getProductName(this.contractProduct);
    },
    productCategory() {
      return this.getCategoryName(this.contractProduct);
    },
    clientLabel() {
      return this.getClientLabel(this.contractProduct);
    },
    condition() {
      return this.$store.getters[
        `data/${DataModules.CONTRACTS_PRODUCTS}/conditionSummary`
      ](this.contractProduct, this.$route);
    },
    productDescription() {
      return (
        _.get(this.product, "description_translated") ||
        _.get(this.product, "description", "")
      );
    },
    productImages() {
      return _.get(this.contractProduct, "product.images", []);
    },
    allowedMigrations() {
      return this.$_.get(this.contractProduct, "allowed_migrations", []);
    },
    siblingSubscriptions() {
      return _.get(this.contract, "products", []).filter(
        (product: IContractProduct) => {
          if (product.id === this.productId) return false;
          return !!product.billing_cycle_months;
        }
      );
    },
    cancellationRequest() {
      return this.contractProduct?.contract_request;
    },
    hasHardCancellationRequest() {
      return this.$store.getters[
        `data/${DataModules.CONTRACTS_PRODUCTS}/hasHardCancellationRequest`
      ](this.contractProduct);
    },
    hasSoftCancellationRequest() {
      return this.$store.getters[
        `data/${DataModules.CONTRACTS_PRODUCTS}/hasSoftCancellationRequest`
      ](this.contractProduct);
    },
    hasCancellationFields() {
      return !!(
        this.cancellationRequest?.reason ||
        this.cancellationRequest?.custom_fields?.length
      );
    },
    hasAcceptedCancellationRequest() {
      return (
        [CSC.CANCELLED, CSC.CLOSED].includes(this.status?.code as CSC) &&
        this.cancellationRequest?.status?.code === CRSC.REQUEST_ACCEPTED
      );
    },
    scheduledPriceChange(): IScheduledAction | null {
      return (
        _.first(
          _.get(this.contractProduct, "scheduled_actions", []).filter(
            ({ action, executed_at }) => {
              return (
                action === ScheduledActionTypes.PRICE_CHANGE &&
                executed_at === null
              );
            }
          )
        ) || null
      );
    },
    hasScheduledChanges() {
      return !!(this.contractProduct?.scheduled_actions_all_count ?? 0);
    },
    billingActions() {
      return this.filterActions([
        {
          label: this.$t("_action.end_trial_early"),
          method: this.endTrialEarly,
          if: () => this.isOnTrial,
          isDisabled: this.isStaged || this.isAwaitingActivation
        },
        {
          label: this.$t("_action.modify_object", {
            object: (this.$t("_.product") as string).toLowerCase()
          }),
          method: this.openModifyProductModal,
          if: () => this.canUpgradeDowngrade
        },
        {
          label: this.$t("_action.schedule_price_change"),
          method: this.openScheduleActionModal,
          if: () => this.canSchedulePriceChange
        },
        {
          label: this.$t("_action.upgrade_downgrade"),
          method: this.openAvailableMigrationsModal,
          if: () => this.canUpgradeDowngradeAsClient,
          isDisabled:
            ![CSC.ACTIVE, CSC.SUSPENDED].includes(
              this.contractProduct?.status?.code as CSC
            ) ||
            this.contractProduct?.pro_rata_pending ||
            this.isStaged,
          isHighlighted: true
        },
        {
          label: this.nextInvoiceDateInFuture
            ? this.$t("_action.issue_next_invoice")
            : this.$t("_action.create_late_renewal_invoice"),
          method: this.createNextInvoice,
          if: () => this.canCreateNextInvoice
        },
        {
          label: this.$t("_.cancellation_options"),
          method: this.isAdmin
            ? this.openAdminCancellationModal
            : this.openClientCancellationModal,

          if: () => this.isCancellable,
          isDisabled: this.contractProduct?.pro_rata_pending
        },
        {
          label: this.$t("_action.manage_cancellation_request"),
          method: this.openAdminCancellationModal,
          if: () => this.isAdmin && this.hasHardCancellationRequest
        },
        {
          label: this.hasManualStatus
            ? this.$t("_action.change_manual_status")
            : this.$t("_action.set_manual_status"),
          method: this.openManualStatusModal,
          if: () => this.canModifyStatus
        },
        {
          label: this.$t("_action.unset_manual_status"),
          method: this.unsetManualStatus,
          if: () => this.canModifyStatus && this.hasManualStatus
        }
      ]);
    },
    productActions() {
      return this.filterActions([
        {
          label: this.$t("_action.activate"),
          method: this.activateProduct,
          if: () => this.canActivate,
          isDisabled: [FraudStatus.REVIEW, FraudStatus.FRAUD].includes(
            this.contract?.fraud_status as FraudStatus
          )
        },
        {
          label: this.$t("_.reactivate_product"),
          method: this.openReactivationModal,
          if: () => this.canReactivate
        },
        {
          label: this.$t("_action.send_email"),
          method: this.openSendEmailModal,
          if: () => this.isAdmin
        },
        {
          label: this.$t("_action.create_ticket"),
          method: this.openCreateTicketModal,
          if: () => true
        },
        {
          label: this.$t("_action.manage_catalogue_product"),
          method: () => {
            this.$router.push({
              name: "adminCatalogueProduct",
              params: { productId: this.product.id }
            });
          },
          if: () => true
        }
      ]);
    },
    advancedActions() {
      return this.filterActions([
        {
          label: this.$t("_action.change_currency"),
          method: this.openChangeCurrencyModal,
          if: () => this.canChangeCurrency
        },
        {
          label: this.$t("_action.transfer_ownership"),
          method: this.openTransferOwnershipModal,
          if: () => this.canTransferOwnership
        }
      ]);
    },
    highlightedActions() {
      return _.filter(
        [
          ...this.billingActions,
          ...this.productActions,
          ...this.advancedActions
        ],
        "isHighlighted"
      );
    },
    nextInvoiceDateInFuture() {
      const nextDate = _.get(this.contractProduct, "next_invoice_date");
      const nextMoment = this.$moment.utc(nextDate).endOf("day");
      return nextDate && nextMoment > this.$moment();
    },
    mainProductId() {
      if (
        [
          ProductCategoryTypes.PRODUCT_OPTION,
          ProductCategoryTypes.PRODUCT_ATTRIBUTE
        ].includes(
          this.contractProduct?.product?.category
            ?.category_type as ProductCategoryTypes
        )
      )
        return this.contractProduct
          ?.main_contracts_product_id as IContractProduct["id"];
      else return this.contractProduct?.id as IContractProduct["id"];
    },
    trialActionConfirmationMsg() {
      const { trial_end_action } = this.contractProduct;
      // Cancel
      if (trial_end_action === TrialEndActionTypes.CANCEL)
        return "_sentence.cprod.confirm_early_trial_end";
      // Migrate
      if (trial_end_action === TrialEndActionTypes.MIGRATE)
        return "_sentence.cprod.confirm_early_trial_migration";
      // Continue
      return "_sentence.cprod.confirm_early_trial_continuation";
    },
    contractProductProviderMethods(): ICProdProviderMethods {
      return {
        activateProduct: this.activateProduct,
        changeAddressOrCompany: this.changeAddressOrCompany,
        changeDates: this.changeDates,
        createNextInvoice: this.createNextInvoice,
        deleteCancellationRequest: this.deleteCancellationRequest,
        endTrialEarly: this.endTrialEarly,
        getBillingCycle: this.getBillingCycle,
        getMigrationProducts: this.getMigrationProducts,
        loadContractData: this.loadContractData,
        openAdminCancellationModal: this.openAdminCancellationModal,
        openAvailableMigrationsModal: this.openAvailableMigrationsModal,
        openChangePaymentMethodModal: this.openChangePaymentMethodModal,
        openChangeProductNameModal: this.openChangeProductNameModal,
        openClientCancellationModal: this.openClientCancellationModal,
        openCreateTicketModal: this.openCreateTicketModal,
        openDueDateModal: this.openDueDateModal,
        openManualStatusModal: this.openManualStatusModal,
        openMigrationProductDetailModal: this.openMigrationProductDetailModal,
        openModifyProductModal: this.openModifyProductModal,
        openReactivationModal: this.openReactivationModal,
        openSendEmailModal: this.openSendEmailModal,
        openUpgradeDowngradeModal: this.openUpgradeDowngradeModal,
        reloadData: this.reloadData,
        toggleAutoExpire: this.toggleAutoExpire,
        toggleAutoRenew: this.toggleAutoRenew,
        unsetManualStatus: this.unsetManualStatus,
        update: this.update,
        updateInvoiceConsolidation: this.updateInvoiceConsolidation
      };
    }
  },
  async created() {
    this.loadData();
  },
  mounted() {
    // Register $bus listener(s)
    this.$bus.$on("cProdProvider::reloadData", this.reloadData);
  },
  beforeDestroy() {
    // Unregister $bus listener(s)
    this.$bus.$off("cProdProvider::reloadData");
    // Bin contract
    this.$store.dispatch("data/binSingle", {
      storeModule: DataModules.CONTRACTS,
      scope: this.contractScope,
      vm: this
    });
  },
  methods: {
    async loadData() {
      try {
        await Promise.all([
          this.$store.dispatch("constants/getBillingCycles"),
          this.$store.dispatch("constants/getCurrencies"),
          this.loadContractData(this.isReloading)
        ]);

        this.getBrandConfig(this.isReloading); // We don't await here as the result is non-critical

        if (!this.contractProduct)
          throw {
            message: this.$t("_.object_not_found", {
              object: this.$t("_.product")
            }),
            response: {
              status: 404
            }
          };

        if (this.isClient && this.allowedMigrations.length) {
          this.migrationsCount = this.$_.get(
            await this.getAvailableMigrations(),
            "total",
            0
          );
        }

        await this.getUnpaidInvoices();

        this.runQueryAutomations();
        this.hasRequestError = false;
      } catch (error) {
        const is403 = this.$store.getters["api/is403"](error);
        const is404 = this.$store.getters["api/is404"](error);
        if ((is403 || is404) && this.onNotFound) this.onNotFound(this);
        this.hasRequestError = true;
        this.$store.dispatch("api/handleError", error);
      } finally {
        this.isLoading = false;
        this.isReloading = false;
        this.$emit("loaded");
      }
    },
    reloadData() {
      this.isReloading = true;
      return this.loadData();
    },
    async loadContractData(ignoreStored = false) {
      return this.$store.dispatch(`data/${DataModules.CONTRACTS}/get`, {
        id: this.contractId,
        scope: this.contractScope,
        params: {
          with_count: [
            ...(this.isAdmin ? ["products.scheduled_actions_all"] : [])
          ].join(","),
          with: _.compact([
            ...(this.isAdmin
              ? [
                  "products.paid_initial_invoice",
                  "products.product.prices",
                  "products.provision_configuration",
                  "products.provision_provider.logos",
                  "products.unresolved_provision_requests",
                  "user"
                ]
              : []),
            "account",
            "address",
            "brand",
            "brand.currency",
            "cancellation_request.status",
            "cancellation_request.custom_fields.field",
            "client",
            "client.tags",
            "client.image",
            "gateway",
            "import.credentials",
            "import.source",
            "moved_to_contract",
            "moved_to_contract.products",
            "payment_details",
            "payment_details.gateway",
            "products.allowed_migrations", // Required to determine possible migration paths
            "products.brand", // Required for `can_create_next_invoice` flag to work
            "products.contract_request", // Required for `can_create_next_invoice` flag to work
            "products.contract_request.custom_fields.field", // Required for `can_create_next_invoice` flag to work
            "products.options.product.image",
            "products.product",
            "products.product.brand.currency",
            "products.product.image",
            "products.product.images",
            "products.product.provision_blueprint",
            "products.scheduled_actions",
            "products.status",
            "products.unpaid_recurring_invoices", // Required for `can_create_next_invoice` flag to work
            "promotions",
            "status"
          ]).join(",")
        },
        vm: this,
        ignoreStored
      });
    },
    /**
     * Here we load the brand setting for invoice consolidation. When the
     * function is called we don't await it as there is no need for it to be
     * blocking. We also load this brand value within the clientProvider, so in
     * theory the value will already be in memory.
     */
    getBrandConfig(ignoreStored = false) {
      return this.$store.dispatch("brand/getConfig", {
        keys: [
          BrandConfigKeys.CLIENT_NOTES_AND_SECRETS_ENABLED,
          BrandConfigKeys.INVOICE_CONSOLIDATION_ENABLED,
          BrandConfigKeys.INVOICE_CONSOLIDATION_RESTRICT_TO_STAFF
        ],
        brandId: this.contract?.brand_id,
        ignoreStored
      });
    },
    getBillingCycle(product: IContractProduct = this.contractProduct) {
      return this.$store.getters["constants/getBillingCycleName"](
        product.billing_cycle_months
      );
    },
    async getUnpaidInvoices() {
      this.unpaidInvoices = await this.$store.dispatch(
        `data/${DataModules.INVOICES}/list`,
        {
          vm: this,
          storeData: false,
          ignoreStored: true,
          params: {
            limit: 0,
            [`filter[contract_id]`]: this.contract.id,
            [`filter[products.contracts_product_id]`]: this.contractProduct.id,
            [`filter[status.code]`]: [
              InvoiceStatus.UNPAID,
              InvoiceStatus.ADJUSTED,
              InvoiceStatus.OVERDUE
            ].join(","),
            with: ["status"].join(","),
            order: "-id"
          }
        }
      );
      this.nextDueOrderInvoice = _.last(
        _.filter(this.unpaidInvoices, invoice => {
          return [
            InvoiceCategoryCode.NEW_CONTRACT,
            InvoiceCategoryCode.ONE_TIME_SERVICE
          ].includes(invoice.category.slug);
        })
      );
      this.nextDueRenewalInvoice = _.last(
        _.filter(this.unpaidInvoices, invoice =>
          [InvoiceCategoryCode.RECURRENT].includes(invoice.category.slug)
        )
      );
    },
    runQueryAutomations() {
      // IF init=upgrade: Open upgrade modal
      if (this.$route.query?.[QUERY_PARAMS.INIT] === "upgrade") {
        if (this.canUpgradeDowngradeAsClient)
          this.openAvailableMigrationsModal();
      }
      // Remove init param
      this.$store.dispatch("ui/router/removeQueryParams", [QUERY_PARAMS.INIT]);
    },
    filterActions(actions: (ICProdAction & { if: () => boolean })[]) {
      return _.map(
        _.filter(actions, action => action.if() as boolean),
        action => _.omit(action, ["if"])
      );
    },
    openReactivationModal() {
      return this.$store.dispatch(
        `data/${DataModules.CONTRACTS_PRODUCTS}/openReactivationModal`,
        {
          props: {
            contractProductId: this.contractProduct.id,
            contractId: this.contractId,
            title: this.$t(`_.reactivate_object`, {
              object: this.productName
            })
          },
          events: {
            "contract-product-updated": this.reloadData
          }
        }
      );
    },
    getAvailableMigrations() {
      return this.$store.dispatch(`data/${DataModules.CATALOGUE}/list`, {
        returnData: false,
        storeData: false,
        clientApi: true,
        params: {
          limit: "count",
          offset: 0,
          account_id: this.contract?.account_id,
          currency_id: this.contract.currency_id,
          "filter[billing_cycle_months|neq]": 0,
          "filter[available_for_sales]": 1,
          "filter[clients_can_order]": 1,
          "filter[id|like]": this.$_.map(this.allowedMigrations, migration =>
            this.$_.get(migration, "migration_product_id", "")
          ).join(","),
          order: "order",
          with: ["image"].join()
        },
        vm: this
      });
    },
    openSendEmailModal() {
      return this.$store.dispatch(
        `data/${DataModules.TEMPLATES}/openSendEmailModal`,
        {
          props: {
            type: "contracts_product",
            objectId: _.get(this.contractProduct, "id"),
            title: this.$t("_action.send_object", {
              object: this.$t("_.object_email", {
                object: this.$t("_.product").toString().toLowerCase()
              })
                .toString()
                .toLowerCase()
            })
          }
        }
      );
    },
    openCreateTicketModal() {
      return this.$store.dispatch(
        `data/${DataModules.TICKETS}/openNewTicketModal`,
        {
          props: {
            clientId: this.$_.get(this.contract, "client_id"),
            accountId: this.$_.get(this.contract, "account_id"),
            brandId: this.$_.get(this.contract, "brand_id"),
            contractProduct: this.contractProduct
          }
        }
      );
    },
    openScheduleActionModal() {
      return this.$store.dispatch(
        `data/${DataModules.CONTRACTS_PRODUCTS}/openScheduleActionModal`,
        {
          props: {
            contractProduct: {
              ...this.contractProduct,
              pricelist_id: this.contract.pricelist_id
            }
          },
          events: {
            success: this.reloadData
          }
        }
      );
    },
    updateInvoiceConsolidation(
      data: Pick<IContractProduct, "invoice_consolidation_enabled">
    ) {
      return this.$store.dispatch(
        `data/${DataModules.CONTRACTS_PRODUCTS}/updateProperties`,
        {
          contractId: this.contractId,
          contractProductId: this.contractProduct.id,
          data
        }
      );
    },
    openModifyProductModal() {
      return this.$store.dispatch(
        `data/${DataModules.CONTRACTS_PRODUCTS}/openModifyProductModal`,
        {
          props: {
            contract: this.contract,
            oldContractProduct: this.contractProduct
          },
          events: {
            success: () => {
              this.reloadData();
            }
          }
        }
      );
    },
    async openAvailableMigrationsModal() {
      const modal = await this.$store.dispatch("ui/open/windowModal", {
        config: {
          component: () =>
            import(
              "@/components/app/global/contractProducts/migrationsListModal.vue"
            ),
          canCancel: ["outside", "x"],
          width: 1080,
          parent: this,
          events: {
            close: () => modal.close(),
            migrateProduct: (migrationProductId: IProduct["id"]) => {
              this.openUpgradeDowngradeModal(migrationProductId);
              modal.close();
            }
          }
        }
      });
      return modal;
    },
    async openMigrationProductDetailModal(item: IShopListItem) {
      return this.$store.dispatch("ui/open/windowModal", {
        config: {
          component: () =>
            import(
              "@/components/app/global/contractProducts/migrationProductDetailModal.vue"
            ),
          canCancel: ["escape", "outside", "x"],
          props: {
            item
          },
          width: 720,
          parent: this
        }
      });
    },
    getMigrationProducts(limit = 4, offset = 0) {
      return this.$store.dispatch(`data/${DataModules.CATALOGUE}/list`, {
        returnData: false,
        storeData: false,
        clientApi: true,
        params: {
          limit,
          offset,
          account_id: this.contract.account_id,
          currency_id: this.contract.currency_id,
          "filter[prices.billing_cycle_months]":
            this.contractProduct?.billing_cycle_months, // Only get products with matching billing term
          "filter[available_for_sales]": 1,
          "filter[clients_can_order]": 1,
          "filter[id|like]": _.map(this.allowedMigrations, migration =>
            _.get(migration, "migration_product_id", "")
          ).join(","),
          order: "order",
          with: ["image,prices"].join()
        },
        vm: this
      });
    },
    async openUpgradeDowngradeModal(migrationProductId: IProduct["id"]) {
      return this.$store.dispatch(
        `data/${DataModules.CONTRACTS_PRODUCTS}/openUpgradeDowngradeModal`,
        {
          props: {
            contract: this.contract,
            contractProduct: this.contractProduct,
            migrationProductId
          },
          events: {
            success: ({ id, unpaid_amount }: IInvoice) => {
              const query = unpaid_amount ? { [QUERY_PARAMS.INIT]: "pay" } : {};
              this.$router.push({
                name: this.isMockClientContext
                  ? UpmindClientRoutes.INVOICE
                  : ClientRoutes.INVOICE,
                query,
                params: {
                  invoiceId: id
                }
              });
            }
          }
        }
      );
    },
    openManualStatusModal() {
      return this.$store.dispatch(
        `data/${DataModules.CONTRACTS_PRODUCTS}/openManualStatusModal`,
        {
          parent: this,
          props: {
            hasUnpaidInvoices: !!this.unpaidInvoices.length,
            isProvisionable: this.isProvisionable,
            contractId: this.contractId,
            contractProductId: this.contractProduct.id,
            statusCode: this.hasManualStatus ? this.status.code : null,
            reason: this.hasManualStatus
              ? this.contractProduct?.manual_status_reason
              : null
          },
          events: {
            success: () => this.reloadData()
          }
        }
      );
    },
    async unsetManualStatus() {
      if (this.isProvisionable) {
        return this.$store.dispatch(
          `data/${DataModules.CONTRACTS_PRODUCTS}/openRestoreStatusModal`,
          {
            parent: this,
            props: {
              contractId: this.contractId,
              contractProductId: this.contractProduct.id
            },
            events: {
              success: this.reloadData
            }
          }
        );
      } else {
        const confirm = await this.$store.dispatch("ui/open/confirmModal", {
          config: {
            props: {
              autoClose: false,
              mode: ConfirmModalModes.BUTTON,
              confirmButtonText: this.$t("_action.unset_manual_status"),
              message: this.$t("_sentence.cprod.confirm_unset_manual_status", {
                status: this.status.name
              })
            },
            events: {
              confirmed: async () => {
                try {
                  await this.$store.dispatch(
                    `data/${DataModules.CONTRACTS_PRODUCTS}/unsetManualStatus`,
                    {
                      contractId: this.contractId,
                      contractProductId: this.contractProduct.id
                    }
                  );
                  return this.reloadData();
                } catch (error) {
                  this.$store.dispatch("api/handleError", error);
                } finally {
                  confirm.close();
                }
              }
            }
          }
        });
      }
    },
    update(data: Record<string, any>) {
      return this.$store.dispatch(
        `data/${DataModules.CONTRACTS_PRODUCTS}/update`,
        {
          contractProductId: this.contractProduct.id,
          data
        }
      ) as Promise<IContractProduct>;
    },
    openAdminCancellationModal() {
      return this.$store.dispatch(
        `data/${DataModules.CONTRACTS}/openAdminCancellationModal`,
        {
          parent: this,
          props: {
            contractId: this.contractId,
            contractProductId: this.contractProduct.id
          },
          events: {
            "contract-updated": this.reloadData
          }
        }
      );
    },
    async deleteCancellationRequest(
      requestId: IContractCancellationRequest["id"]
    ) {
      const confirm = await this.$store.dispatch("ui/open/confirmModal", {
        config: {
          props: {
            autoClose: false,
            mode: ConfirmModalModes.BUTTON,
            confirmButtonText: this.$t("_action.delete"),
            message: this.$t("_sentence.delete_confirmation", {
              object: (
                this.$t("_.cancellation_request") as string
              ).toLowerCase()
            })
          },
          events: {
            confirmed: async () => {
              try {
                await this.$store.dispatch(
                  `data/${DataModules.CONTRACTS}/deleteCancellation`,
                  {
                    id: this.contractId,
                    data: {
                      contract_request_id: requestId
                    }
                  }
                );
                return this.reloadData();
              } catch (error) {
                this.$store.dispatch("api/handleError", error);
              } finally {
                confirm.close();
              }
            }
          }
        }
      });
    },
    openClientCancellationModal() {
      return this.$store.dispatch(
        `data/${DataModules.CONTRACTS}/openClientCancellationModal`,
        {
          parent: this,
          props: {
            contractId: this.contractId,
            contractProductId: this.contractProduct.id
          },
          events: {
            "contract-updated": this.reloadData
          }
        }
      );
    },
    openDueDateModal(): Promise<IModalComponent> {
      return this.$store.dispatch("ui/openModal", {
        type: ModalTypes.WINDOW,
        config: {
          parent: this, // Parent required for Inject() to work
          component: () =>
            import(
              "@/components/app/global/client/contractProducts/contractProductDueDateModal.vue"
            ),
          width: 440,
          props: {
            nextDueDate: this.$moment(
              this.contractProduct.next_due_date
            ).toDate(),
            contractId: this.contract.id,
            productId: this.contractProduct.id
          },
          events: {
            changed: () => {
              this.reloadData();
            }
          }
        }
      });
    },
    async changeDates(
      data,
      onSuccess = () => {
        this.$buefy.toast.open({
          message: this.$t("_.object_successfully_updated", {
            object: this.$t("_.date")
          }) as string
        });
      }
    ) {
      try {
        this.isProcessing = true;
        await this.$store.dispatch(
          `data/${DataModules.CONTRACTS_PRODUCTS}/changeDates`,
          {
            contractId: this.contractId,
            contractProductId: this.contractProduct.id,
            data
          }
        );
        await this.loadContractData(true);
        onSuccess();
      } catch (error) {
        this.$store.dispatch("api/handleError", error);
        throw error;
      } finally {
        this.isProcessing = false;
      }
    },
    async changeAddressOrCompany(data) {
      try {
        this.isProcessing = true;
        await this.$store.dispatch(
          `data/${DataModules.CONTRACTS_PRODUCTS}/changeAddressOrCompany`,
          {
            contractId: this.contractId,
            data
          }
        );
        await this.loadContractData(true);
      } catch (error) {
        this.$store.dispatch("api/handleError", error);
        throw error;
      } finally {
        this.isProcessing = false;
      }
    },
    openChangePaymentMethodModal(config: any = {}) {
      return this.$store.dispatch(
        `data/${DataModules.CONTRACTS}/openChangePaymentMethodModal`,
        this.$_.merge(
          {
            parent: this, // Parent required for Inject() to work
            events: {
              "payment-method-changed": () => {
                this.reloadData();
              }
            }
          },
          config
        )
      );
    },
    openChangeProductNameModal({
      contractProductId = this.productId,
      modalTitle = undefined,
      name = this.contractProduct?.name
    } = {}) {
      return this.$store.dispatch(
        `data/${DataModules.CONTRACTS}/openChangeProductNameModal`,
        {
          parent: this, // Parent required for Inject() to work
          props: {
            contractProductId,
            modalTitle,
            name
          },
          events: {
            success: () => this.reloadData()
          }
        }
      );
    },
    async toggleAutoExpire(expire: boolean) {
      this.isProcessing = true;
      try {
        await this.$store.dispatch(
          `data/${DataModules.CONTRACTS_PRODUCTS}/modify_renew`,
          {
            contractId: this.contractId,
            contractProductId: this.contractProduct.id,
            payload: {
              renew: !expire
            }
          }
        );
        await this.reloadData();
      } catch (error) {
        this.$store.dispatch("api/handleError", error);
      } finally {
        this.isProcessing = false;
      }
    },
    async toggleAutoRenew(renew: boolean) {
      if (this.isProcessing) return;
      try {
        this.isProcessing = true;
        await this.$store.dispatch(
          `data/${DataModules.CONTRACTS_PRODUCTS}/toggleAutoRenew`,
          {
            contractId: this.contractId,
            contractProductId: this.contractProduct.id,
            payload: {
              invoicing: renew
            }
          }
        );
        await this.reloadData();
        this.$buefy.toast.open({
          message: this.$t(
            renew ? "_.auto_renew_enabled" : "_.auto_renew_disabled"
          ) as string
        });
      } catch (error) {
        this.$store.dispatch("api/handleError", error);
      } finally {
        this.isProcessing = false;
      }
    },
    async createNextInvoice() {
      if (this.isProcessing) return;
      const confirm = await this.$store.dispatch("ui/open/confirmModal", {
        config: {
          props: {
            autoClose: false,
            confirmButtonText: this.$t("_action.issue_next_invoice"),
            message: this.$t("_sentence.cprod.confirm_next_invoice_creation"),
            mode: ConfirmModalModes.BUTTON,
            type: "is-success"
          },
          events: {
            confirmed: async () => {
              try {
                await this.createNextInvoiceConfirmed();
              } finally {
                confirm.close();
              }
            }
          }
        }
      });
    },
    async createNextInvoiceConfirmed() {
      if (this.isProcessing) return;
      try {
        this.isProcessing = true;
        const invoice: IInvoice = await this.$store.dispatch(
          `data/${DataModules.CONTRACTS_PRODUCTS}/createNextInvoice`,
          {
            contractId: this.contractId,
            contractProductId: this.contractProduct.id
          }
        );
        // Redirect to invoice
        this.$router
          .push(
            this.$store.getters[`data/${DataModules.INVOICES}/getRoute`](
              invoice.id,
              invoice.client_id
            )
          )
          .catch(err => err);
        // Open toast
        this.$buefy.toast.open({
          message: this.$t("_.object_successfully_created", {
            object: this.$t("_.invoice")
          }) as string
        });
      } catch (error) {
        this.$store.dispatch("api/handleError", error);
      } finally {
        this.isProcessing = false;
      }
    },
    async endTrialEarly() {
      if (this.isProcessing) return;
      const confirm = await this.$store.dispatch("ui/open/confirmModal", {
        config: {
          props: {
            autoClose: false,
            confirmButtonText: this.$t("_action.end_trial_early"),
            message: this.$t(this.trialActionConfirmationMsg, {
              entity: this.entity
            }),
            mode: ConfirmModalModes.BUTTON,
            type: "is-success"
          },
          events: {
            confirmed: async () => {
              try {
                await this.endTrialEarlyConfirmed();
              } finally {
                confirm.close();
              }
            }
          }
        }
      });
    },
    async endTrialEarlyConfirmed() {
      if (this.isProcessing) return;
      try {
        this.isProcessing = true;
        const invoice: IInvoice | null = await this.$store.dispatch(
          `data/${DataModules.CONTRACTS_PRODUCTS}/endTrialEarly`,
          {
            contractId: this.contractId,
            contractProductId: this.contractProduct.id
          }
        );

        await Promise.all([
          // Redirect to invoice (if migration/continuation) OR reload cProdProvider data
          invoice?.id &&
          [
            InvoiceCategoryCode.MIGRATION,
            InvoiceCategoryCode.RECURRENT
          ].includes(invoice?.category?.slug)
            ? this.$router
                .push(
                  this.$store.getters[`data/${DataModules.INVOICES}/getRoute`](
                    invoice.id,
                    invoice.client_id
                  )
                )
                .catch(err => err)
            : this.reloadData(),
          // Open toast
          this.$buefy.toast.open({
            message: this.$t("_sentence.cprod.trial_now_ended_msg") as string
          })
        ]);
      } catch (error) {
        this.$store.dispatch("api/handleError", error);
      } finally {
        this.isProcessing = false;
      }
    },
    async activateProduct() {
      const confirm = await this.$store.dispatch("ui/open/confirmModal", {
        config: {
          props: {
            autoClose: false,
            confirmButtonText: this.$t("_action.activate"),
            message: this.$t("_sentence.cprod.confirm_manual_activation", {
              entity: this.entity
            }),
            mode: ConfirmModalModes.BUTTON,
            type: "is-danger"
          },
          events: {
            confirmed: async () => {
              try {
                this.isProcessing = true;
                await this.$store.dispatch(
                  `data/${DataModules.CONTRACTS_PRODUCTS}/activateProduct`,
                  {
                    contractId: this.contractId,
                    contractProductId: this.contractProduct.id
                  }
                );
                await this.reloadData();

                this.$store.dispatch("toast/show", {
                  message: this.$t("_.product_activated")
                });
              } catch (error) {
                this.$store.dispatch("api/handleError", error);
              } finally {
                this.isProcessing = false;
                confirm.close();
              }
            }
          }
        }
      });
    },
    openChangeCurrencyModal() {
      if (!this.canChangeCurrency) return;
      return this.$store.dispatch(
        `data/${DataModules.CONTRACTS_PRODUCTS}/openChangeCurrencyModal`,
        {
          props: {
            contract: this.contract,
            contractProductId: this.contractProduct.id
          }
        }
      );
    },
    async openTransferOwnershipModal() {
      if (!this.canTransferOwnership) return;
      try {
        const modal = await this.$store.dispatch(
          `data/${DataModules.CLIENTS}/openSelectClientModal`,
          {
            props: {
              brandId: this.contract.brand_id,
              manualFilters: {
                "filter[id|neq]": this.contract.client_id
              }
            },
            events: {
              onSelect: async client => {
                modal.close();
                const confirm = await this.$store.dispatch(
                  "ui/open/confirmModal",
                  {
                    config: {
                      props: {
                        autoClose: false,
                        mode: ConfirmModalModes.PIN,
                        confirmButtonText: this.$t(
                          "_action.transfer_ownership"
                        ),
                        message: this.$t(
                          "_sentence.cprod.confirm_ownership_change",
                          {
                            productName: this.productName,
                            clientName:
                              client.fullname || client.default_email.email
                          }
                        )
                      },
                      events: {
                        confirmed: async () => {
                          this.isProcessing = true;
                          try {
                            const newContract = await this.transferOwnership(
                              client
                            );

                            this.$router.push({
                              name: "admin__clientCProd",
                              params: {
                                clientId: client.id,
                                contractId: newContract.contract_id,
                                productId: newContract.id
                              }
                            });
                          } catch (error) {
                            this.$store.dispatch("api/handleError", error);
                          } finally {
                            this.isProcessing = false;
                            confirm.close();
                          }
                        }
                      }
                    }
                  }
                );
              }
            }
          }
        );
      } catch (error) {
        this.$store.dispatch("api/handleError", error);
      }
    },
    transferOwnership(client) {
      return this.$store.dispatch(
        `data/${DataModules.CONTRACTS_PRODUCTS}/transferOwnership`,
        {
          contractId: this.contract.id,
          contractProductId: this.contractProduct.id,
          data: {
            client_id: client.id
          }
        }
      );
    },
    contractProductProvider(): ICProdProvider {
      return {
        advancedActions: this.advancedActions,
        allowedMigrations: this.allowedMigrations,
        billingActions: this.billingActions,
        cProdBillingRoute: this.cProdBillingRoute,
        canActivate: this.canActivate,
        canChangeBillingEntity: this.canChangeBillingEntity,
        canChangeCurrency: this.canChangeCurrency,
        canCreateNextInvoice: this.canCreateNextInvoice,
        canDisableAutoRenew: this.canDisableAutoRenew,
        canModifyBilling: this.canModifyBilling,
        canModifySettings: this.canModifySettings,
        canModifyStatus: this.canModifyStatus,
        canReactivate: this.canReactivate,
        canSchedulePriceChange: this.canSchedulePriceChange,
        canTransferOwnership: this.canTransferOwnership,
        canUpdateContractProduct: this.canUpdateContractProduct,
        canUpgradeDowngrade: this.canUpgradeDowngrade,
        canUpgradeDowngradeAsClient: this.canUpgradeDowngradeAsClient,
        cancellationRequest: this.cancellationRequest,
        category: this.productCategory,
        clientLabel: this.clientLabel,
        condition: this.condition,
        contract: this.contract,
        contractId: this.contractId,
        contractProduct: this.contractProduct,
        contractProductId: this.contractProductId,
        currency: this.currency,
        description: this.productDescription,
        enabledCV: this.enabledCV,
        entity: this.entity,
        hasAcceptedCancellationRequest: this.hasAcceptedCancellationRequest,
        hasAutoExpireEnabled: this.hasAutoExpireEnabled,
        hasAutoRenewDisabled: this.hasAutoRenewDisabled,
        hasCancellationFields: this.hasCancellationFields,
        hasHardCancellationRequest: this.hasHardCancellationRequest,
        hasManualStatus: this.hasManualStatus,
        hasMoved: this.hasMoved,
        hasRequestError: this.hasRequestError,
        hasScheduledChanges: this.hasScheduledChanges,
        hasSoftCancellationRequest: this.hasSoftCancellationRequest,
        highlightedActions: this.highlightedActions,
        images: this.productImages,
        isActive: this.isActive,
        isAwaitingActivation: this.isAwaitingActivation,
        isAwaitingSetup: this.isAwaitingSetup,
        isCancellable: this.isCancellable,
        isCancelled: this.isCancelled,
        isDelegatedAccess: this.isDelegatedAccess,
        isImported: this.isImported,
        isLapsed: this.isLapsed,
        isLoading: this.isLoading,
        isOnTerminatingTrial: this.isOnTerminatingTrial(this.contractProduct),
        isOnTrial: this.isOnTrial,
        isPending: this.isPending,
        isProcessing: this.isProcessing,
        isProvisionable: this.isProvisionable,
        isReloading: this.isReloading,
        isStaged: this.isStaged,
        isSubscription: this.isSubscription,
        isSuspended: this.isSuspended,
        mainProductId: this.mainProductId,
        migrationsCount: this.migrationsCount,
        name: this.productName,
        nextDueOrderInvoice: this.nextDueOrderInvoice,
        nextDueRenewalInvoice: this.nextDueRenewalInvoice,
        product: this.product,
        productActions: this.productActions,
        scheduledPriceChange: this.scheduledPriceChange,
        siblingSubscriptions: this.siblingSubscriptions,
        status: this.status,
        unpaidInvoices: this.unpaidInvoices,
        unpaidOverdueInvoices: this.unpaidOverdueInvoices
      };
    }
  },
  render(h: CreateElement) {
    // Handle loading
    if (this.handleLoading && this.isLoading)
      return h("is-loading", {
        props: { if: true },
        attrs: this.loaderAttrs
      });
    // Handle request error
    if (this.handleRequestError && this.hasRequestError)
      return h("request-error", {
        props: { isLoading: this.isReloading },
        on: { click: () => this.reloadData() }
      });
    return (
      this.$scopedSlots?.default &&
      this.$scopedSlots.default({
        $cProdData: this.contractProductProvider(),
        $cProdMethods: this.contractProductProviderMethods
      })
    );
  }
});

export default CProdProvider;
</script>
