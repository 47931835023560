import { RegexMatch } from "@/data/enums/router";
import TicketsListing from "@/components/app/global/tickets/ticketsListing.vue";
import { IsActiveParam, IsClosedParam } from "@/data/filters/tickets";
import type { Route } from "vue-router";
import type { ITicket } from "@upmind-automation/types";

export default [
  {
    path: "support",
    alias: "tickets",
    component: {
      name: "AdminAccountTickets",
      template: `<router-view />`
    },
    meta: {
      title: "_.tickets"
    },
    children: [
      {
        path: "",
        name: "admin__upmindSupport",
        component: () => import("@/views/admin/upmind/tickets/index.vue"),
        meta: {
          title: "_.support",
          backTo: "_.support"
        },
        redirect: { name: "admin__upmindActiveSupportTickets" },
        props: (route: Route) => ({
          routes: require("./menu").ticketsTabigation,
          userId: route.params.userId
        }),
        children: [
          {
            path: "active",
            name: "admin__upmindActiveSupportTickets",
            component: TicketsListing,
            props: () => ({
              routeFilters: () => ({
                ...IsActiveParam()
              }),
              ticketRoute: (ticket: ITicket) => ({
                name: "admin__upmindTicket",
                params: {
                  ticketId: ticket.id
                }
              }),
              showStaged: true
            }),
            meta: {
              backTo: "_.upmind_user_active_support_tickets",
              title: "_.upmind_user_active_support_tickets"
            }
          },
          {
            path: "closed",
            name: "admin__upmindClosedSupportTickets",
            component: TicketsListing,
            props: () => ({
              routeFilters: () => ({
                ...IsClosedParam()
              }),
              ticketRoute: (ticket: ITicket) => ({
                name: "admin__upmindTicket",
                params: {
                  ticketId: ticket.id
                }
              }),
              showStaged: true
            }),
            meta: {
              backTo: "_.upmind_user_closed_support_tickets",
              title: "_.upmind_user_closed_support_tickets"
            }
          }
        ]
      },
      {
        path: "add",
        name: "admin__addUpmindTicket",
        component: () =>
          import("@/views/admin/upmind/tickets/ticket/index.vue"),
        meta: {
          title: "_action.open_new_ticket",
          backTo: "_action.open_new_ticket"
        }
      },
      {
        path: `:ticketId(${RegexMatch.UUID})`,
        name: "admin__upmindTicket",
        props: true,
        component: () =>
          import("@/views/admin/upmind/tickets/ticket/details.vue"),
        meta: {
          title: ({ ticketRef }: Record<string, string>) => ticketRef
        }
      }
    ]
  }
];
