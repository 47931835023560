import type { Route } from "vue-router";
import { RegexMatch, UpmindClientRoutes } from "@/data/enums/router";
import CProdsListing from "@/components/app/global/contractProducts/cProdsListing.vue";
import UpmindClientProductsView from "@/views/admin/upmind/products/index.vue";
import {
  UpmindClientProductsTabigation,
  UpmindClientOneTimePurchasesTabigation,
  UpmindClientSubscriptionsTabigation,
  UpmindClientCProdTabigation
} from "@/router/admin/upmind/products/menu";

import {
  IsActiveParam,
  IsInactiveParam,
  IsSubscriptionParam,
  IsOneTimePurchaseParam,
  ProductNameFilter,
  ProductCategoryNameFilter,
  StatusCodesFilter,
  ActiveStatusCodesFilter,
  CancelledStatusCodesFilter,
  PriceFilter,
  DatePurchasedFilter,
  NextDueDateFilter
} from "@/data/filters/contractProducts";
import {
  DateCancelledSorter,
  DatePurchasedSorter,
  DefaultCProdSorters,
  StatusSorter
} from "@/data/sorters/contractProducts";
import { $bus } from "@/boot/event-bus";

export default [
  {
    path: "products",
    name: "admin__upmindProducts",
    redirect: { name: "admin__upmindActiveProducts" },
    component: {
      name: "AdminUpmindProductsWrapper",
      template: "<router-view />"
    },
    meta: {},
    children: [
      /** ALL products */
      {
        path: "all",
        redirect: { name: "admin__upmindActiveProducts" },
        component: UpmindClientProductsView,
        props: {
          routes: UpmindClientProductsTabigation
        },
        meta: {
          title: "_.products_and_services"
        },
        children: [
          {
            path: "",
            name: "admin__upmindAllProducts",
            component: CProdsListing,
            props: {
              availableSorters: DefaultCProdSorters,
              availableFilters: () => [
                DatePurchasedFilter(),
                NextDueDateFilter(),
                PriceFilter(),
                ProductNameFilter(),
                ProductCategoryNameFilter(),
                StatusCodesFilter()
              ]
            }
          },
          {
            path: "active",
            name: "admin__upmindActiveProducts",
            component: CProdsListing,
            props: {
              availableSorters: DefaultCProdSorters,
              availableFilters: () => [
                DatePurchasedFilter(),
                NextDueDateFilter(),
                PriceFilter(),
                ProductNameFilter(),
                ProductCategoryNameFilter(),
                ActiveStatusCodesFilter()
              ],
              routeFilters: () => ({
                ...IsActiveParam()
              })
            }
          },
          {
            path: "cancelled",
            name: "admin__upmindCancelledProducts",
            component: CProdsListing,
            props: {
              availableSorters: () => [
                DatePurchasedSorter(),
                DateCancelledSorter(),
                StatusSorter()
              ],
              availableFilters: () => [
                DatePurchasedFilter(),
                PriceFilter(),
                ProductNameFilter(),
                ProductCategoryNameFilter(),
                CancelledStatusCodesFilter()
              ],
              routeFilters: () => ({
                ...IsInactiveParam()
              })
            }
          }
        ]
      },
      /** SUBSCRIPTION products */
      {
        path: "subscriptions",
        name: "admin__upmindSubscriptions",
        redirect: { name: "admin__upmindActiveSubscriptions" },
        component: UpmindClientProductsView,
        props: {
          routes: UpmindClientSubscriptionsTabigation
        },
        meta: {
          title: "_.subscriptions"
        },
        children: [
          {
            path: "",
            name: "admin__upmindAllSubscriptions",
            component: CProdsListing,
            props: {
              availableSorters: DefaultCProdSorters,
              availableFilters: () => [
                DatePurchasedFilter(),
                NextDueDateFilter(),
                PriceFilter(),
                ProductNameFilter(),
                ProductCategoryNameFilter(),
                StatusCodesFilter()
              ],
              routeFilters: () => ({
                ...IsSubscriptionParam()
              })
            }
          },
          {
            path: "active",
            name: "admin__upmindActiveSubscriptions",
            component: CProdsListing,
            props: {
              availableSorters: DefaultCProdSorters,
              availableFilters: () => [
                DatePurchasedFilter(),
                NextDueDateFilter(),
                PriceFilter(),
                ProductNameFilter(),
                ProductCategoryNameFilter(),
                ActiveStatusCodesFilter()
              ],
              routeFilters: () => ({
                ...IsSubscriptionParam(),
                ...IsActiveParam()
              })
            }
          },
          {
            path: "cancelled",
            name: "admin__upmindCancelledSubscriptions",
            component: CProdsListing,
            props: {
              availableSorters: () => [
                DatePurchasedSorter(),
                DateCancelledSorter(),
                StatusSorter()
              ],
              availableFilters: () => [
                DatePurchasedFilter(),
                PriceFilter(),
                ProductNameFilter(),
                ProductCategoryNameFilter(),
                CancelledStatusCodesFilter()
              ],
              routeFilters: () => ({
                ...IsSubscriptionParam(),
                ...IsInactiveParam()
              })
            }
          }
        ]
      },
      /** ONE-TIME PURCHASE products */
      {
        path: "one-time-purchases",
        name: "admin__upmindOneTimePurchases",
        redirect: { name: "admin__upmindActiveOneTimePurchases" },
        component: UpmindClientProductsView,
        props: {
          routes: UpmindClientOneTimePurchasesTabigation
        },
        meta: {
          title: "_.one_time_purchases"
        },
        children: [
          {
            path: "",
            name: "admin__upmindAllOneTimePurchases",
            component: CProdsListing,
            props: {
              availableSorters: () => [DatePurchasedSorter(), StatusSorter()],
              availableFilters: () => [
                DatePurchasedFilter(),
                PriceFilter(),
                ProductNameFilter(),
                ProductCategoryNameFilter(),
                StatusCodesFilter()
              ],
              routeFilters: () => ({
                ...IsOneTimePurchaseParam()
              })
            }
          },
          {
            path: "active",
            name: "admin__upmindActiveOneTimePurchases",
            component: CProdsListing,
            props: {
              availableSorters: () => [DatePurchasedSorter(), StatusSorter()],
              availableFilters: () => [
                DatePurchasedFilter(),
                PriceFilter(),
                ProductNameFilter(),
                ProductCategoryNameFilter(),
                ActiveStatusCodesFilter()
              ],
              routeFilters: () => ({
                ...IsOneTimePurchaseParam(),
                ...IsActiveParam()
              })
            }
          },
          {
            path: "cancelled",
            name: "admin__upmindCancelledOneTimePurchases",
            component: CProdsListing,
            props: {
              availableSorters: () => [
                DatePurchasedSorter(),
                DateCancelledSorter(),
                StatusSorter()
              ],
              availableFilters: () => [
                DatePurchasedFilter(),
                PriceFilter(),
                ProductNameFilter(),
                ProductCategoryNameFilter(),
                CancelledStatusCodesFilter()
              ],
              routeFilters: () => ({
                ...IsOneTimePurchaseParam(),
                ...IsInactiveParam()
              })
            }
          }
        ]
      }
    ]
  },
  {
    path: `products/:contractId(${RegexMatch.UUID})/:productId(${RegexMatch.UUID})`,
    component: {
      name: "UpmindClientContractProductWrapper",
      template: "<router-view :key='$route.params.productId' />"
    },
    children: [
      {
        path: "",
        name: "admin__upmindClientCProd",
        redirect: "overview",
        component: () =>
          import("@/views/admin/upmind/products/product/index.vue"),
        props: (route: Route) => ({
          contractId: route.params.contractId,
          productId: route.params.productId,
          routes: UpmindClientCProdTabigation
        }),
        meta: {
          title: "_.product_and_service"
        },
        children: [
          {
            path: "overview",
            name: UpmindClientRoutes.CONTRACT_PRODUCT_OVERVIEW,
            component: () =>
              import(
                "@/views/admin/upmind/products/product/overview/index.vue"
              ),
            beforeEnter: (to, from, next) => {
              $bus.$emit("cProdProvider::reloadData");
              next();
            }
          },
          {
            path: "billing",
            name: UpmindClientRoutes.CONTRACT_PRODUCT_BILLING,
            component: () =>
              import("@/views/admin/upmind/products/product/billing/index.vue")
          },
          {
            path: "settings",
            name: UpmindClientRoutes.CONTRACT_PRODUCT_SETTINGS,
            component: () =>
              import("@/views/admin/upmind/products/product/settings/index.vue")
          }
        ]
      }
    ]
  }
];
