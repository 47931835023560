import _ from "@/boot/lodash";
import { DataModules } from "@/store/modules/data/modules";
import type { IDataState } from "@/store/modules/data";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import type { ApiPathGetter } from "@/models/api";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext }): ApiPathGetter =>
    () => {
      const admin = "api/admin/contract_product_categories";
      const client = "api/contract_product_categories";
      const contextual = isAdminContext ? admin : client;
      return { client, admin, contextual };
    }
};

const actions: ActionTree<IDataState, IState> = {
  /**
   * @name data/contracts/categories/list
   * @desc Return all product categories from which products have been purchased
   * */

  list: ({ dispatch, rootGetters, getters }, payload) => {
    const isClientContext = rootGetters["isClientContext"];
    const hasDelegatedProducts =
      rootGetters["auth/client/hasDelegatedProducts"];
    if (isClientContext && !hasDelegatedProducts) {
      _.set(payload, "params.exclude_delegated", 1);
    }
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath().contextual,
        storeModule: DataModules.CONTRACTS_CATEGORIES
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
