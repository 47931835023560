import Vue from "vue";
import SlideModalUnique from "./SlideModalUnique.vue";
import type { IModalComponent } from "@/models/modal";

const SlideProgrammatic = {
  slide(params): IModalComponent {
    let content;
    let parent;
    if (typeof params === "string") {
      content = params;
    }

    const defaultParam = {
      programmatic: true,
      canCancel: ["escape", "outside", "button", "x"],
      hasModalCard: true,
      content
    };
    if (params.parent) {
      parent = params.parent;
      delete params.parent;
    }

    const SlideModalUniqueClass = Vue.extend(SlideModalUnique);
    return new SlideModalUniqueClass({
      parent,
      propsData: {
        modalProps: { ...defaultParam, ...params }
      },
      el: document.createElement("div")
    });
  }
};

export default {
  install: (Vue: any) => {
    Vue.prototype.$buefy.modal = {
      ...Vue.prototype.$buefy.modal,
      ...SlideProgrammatic
    };
  }
};

export { SlideProgrammatic };
