<template>
  <ValidationProvider
    v-slot="props"
    :name="vname"
    :vid="vid"
    :rules="rules"
    :mode="mode"
    slim
  >
    <b-field
      :label="label"
      :message="showErrorMessage ? props.errors : []"
      :type="{
        'is-danger': props.errors[0],
        'is-success': showSuccess && props.valid
      }"
      :expanded="expanded"
      :grouped="grouped"
    >
      <slot v-bind="props" />
    </b-field>
  </ValidationProvider>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "UField",
  props: {
    vname: { type: String, default: "" },
    mode: { type: String, default: "aggressive" },
    rules: { type: [String, Object], default: "" },
    vid: { type: String, default: "" },
    label: { type: String, default: "" },
    expanded: { type: Boolean, default: false },
    grouped: { type: Boolean, default: false },
    showSuccess: { type: Boolean, default: false },
    showErrorMessage: { type: Boolean, default: true },
    slim: { type: Boolean, default: true }
  }
});
</script>

<style lang="scss" scoped>
::v-deep .help:empty {
  margin: 0;
}
</style>
