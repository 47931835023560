import type { ActionTree, MutationTree } from "vuex";
import type { IState } from "@/store";
import { Methods } from "@/models/methods";

interface IFeatureBaseState {
  jwt: string | null;
}

const initialState = (): IFeatureBaseState => ({
  jwt: null
});

const actions: ActionTree<IFeatureBaseState, IState> = {
  getJWT: async ({ state, dispatch, commit, rootState }) => {
    try {
      if (state.jwt) return Promise.resolve(state.jwt);
      const { token: jwt } = await dispatch(
        "data/callApi",
        {
          method: Methods.POST,
          path: `api/admin/users/${rootState?.user?.id}/featurebasetoken`
        },
        { root: true }
      );
      commit("jwt", jwt);
      return jwt;
    } catch (error) {
      return "";
    }
  }
};

const mutations: MutationTree<IFeatureBaseState> = {
  jwt: (state, val) => {
    state.jwt = val;
  }
};

export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations
};
