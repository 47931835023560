<template>
  <component
    :is="tag"
    :class="{ 'has-cursor-wait': isProcessing }"
    :disabled="disabled || isProcessing"
    :loading="isProcessing"
    @click="processAction"
  >
    <slot />
  </component>
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";
import type {
  ICProdProviderMethods,
  ICProdProvProviderMethods
} from "@/models/providers/contractProduct";

export default defineComponent({
  name: "CProdActionControl",
  inject: {
    $cProdMethods: {
      from: "contractProductProviderMethods",
      type: Object as PropType<ICProdProviderMethods>
    },
    $cProdProvMethods: {
      from: "cProdProvProviderMethods",
      type: Object as PropType<ICProdProvProviderMethods>
    }
  },
  props: {
    tag: { type: String, default: "u-link" },
    action: { type: Function, default: () => {} },
    disabled: { type: Boolean, default: false }
  },
  data: () => ({
    isProcessing: false
  }),
  methods: {
    async processAction() {
      try {
        this.isProcessing = true;
        await this.action({
          $cProdMethods: this.$cProdMethods,
          $cProdProvMethods: this.$cProdProvMethods
        });
      } finally {
        this.isProcessing = false;
      }
    }
  }
});
</script>
