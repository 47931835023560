import { UpmindObjectTypes } from "./objects";

export enum TemplateTypes {
  NOTIFICATION = 1, // Notification
  DOCUMENT = 2, // Document
  TICKET = 3, // Ticket/Message
  CLIENT_TEMPLATE = 4 // Client Area
}

export enum ClientAreaTemplateTypes {
  TEMPLATE = "template", // Template
  CLIENT_AREA_IFRAME = "client_area_iframe_template" // Iframe
}

export enum TemplateObjectTypes {
  PRODUCT = UpmindObjectTypes.PRODUCT
}

export enum ClientTemplateSlotCodes {
  AFFILIATES_OVERVIEW = "affiliates_overview",
  BASKET_SUMMARY_FOOTER = "basket_summary_footer",
  CONTRACT_PRODUCT_OVERVIEW = "contract_product_overview",
  DASHBOARD_OVERVIEW = "dashboard_overview",
  FOOTER = "footer",
  INVOICES_OVERVIEW = "invoices_overview",
  LEGACY_INVOICES_OVERVIEW = "legacy_invoices_overview",
  LOGIN_PAGE = "login_page",
  REGISTER_PAGE = "register_page",
  SUPPORT_OVERVIEW = "support_overview"
}

export enum TemplateCodes {
  INVOICE_PDF = "invoice_pdf_template"
}
