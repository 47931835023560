import type { IDataState } from "@/store/modules/data/index";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import { Methods } from "@/models/methods";
import type { ApiPathGetter } from "@/models/api";
import i18n from "@/i18n";
import { ConfirmModalModes } from "@/data/enums";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext }): ApiPathGetter =>
    ({ importId, stepId }) => {
      const admin = `api/admin/imports/${importId}/steps/${stepId}`;
      const client = "";
      const contextual = isAdminContext ? admin : client;
      return { client, admin, contextual };
    }
};

const actions: ActionTree<IDataState, IState> = {
  start: ({ dispatch, getters }, { importId, stepId }) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PATCH,
        path: `${getters.apiPath({ importId, stepId }).admin}/start`
      },
      { root: true }
    );
  },
  reset: ({ dispatch, getters }, { importId, stepId }) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PATCH,
        path: `${getters.apiPath({ importId, stepId }).admin}/reset`,
        requestConfig: {
          data: {
            clear_collected_data: true,
            clear_mappings: true
          }
        }
      },
      { root: true }
    );
  },
  confirmReset: async ({ dispatch }, { importId, stepId, onSuccess }) => {
    const confirm = await dispatch(
      "ui/open/confirmModal",
      {
        config: {
          props: {
            autoClose: false,
            confirmButtonText: i18n.t("_action.reset"),
            mode: ConfirmModalModes.BUTTON,
            message: i18n.t("_sentence.imports.reset_step_message")
          },
          canCancel: [],
          events: {
            confirmed: async () => {
              try {
                await dispatch("reset", { importId, stepId });

                dispatch(
                  "toast/show",
                  {
                    message: i18n.t(
                      "_sentence.imports.import_step_successfully_reset"
                    )
                  },
                  { root: true }
                );

                await onSuccess();
              } catch (error) {
                dispatch("api/handleError", error, {
                  root: true
                });
              } finally {
                confirm.close();
              }
            }
          }
        }
      },
      { root: true }
    );
  },

  openStagedRecordsModal: ({ dispatch }, { modalConfig }) => {
    return dispatch(
      `ui/open/slideModal`,
      {
        config: {
          component: () =>
            import("@/components/app/admin/imports/stagedRecordsModal.vue"),
          width: 720,
          canCancel: ["x", "button", "outside"],
          ...modalConfig
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
