import { RegexMatch } from "@/data/enums/router";
import { DocCategories } from "@/data/enums/docs";
import { SegmentTypes } from "@/data/enums/segments";
import type { Route } from "vue-router";

import CProdsListing from "@/components/app/global/contractProducts/cProdsListing.vue";
import SegmentResultsListing from "@/components/app/admin/segments/segmentResultsListing.vue";
import { ContractStatusCodes } from "@/models/contracts";

import {
  DefaultCProdFilters,
  statusParam,
  isFromLast30DaysParam,
  IsCategoryParam,
  IsOneTimePurchaseParam,
  IsOneTimeFilter
} from "@/data/filters/contractProducts";
import {
  DefaultCProdSorters,
  StatusSorter,
  DatePurchasedSorter,
  DateCancelledSorter
} from "@/data/sorters/contractProducts";

import { ListDisplayMode } from "@/data/constants";
import { UserMetaKeys } from "@/data/enums/users";

export default [
  {
    path: "otps",
    name: "adminOneTimePurchases",
    redirect: { name: "adminOneTimePurchasesList" },
    component: () => import("@/views/admin/contractProducts/otps/index.vue"),
    props: {
      usedFilters: () => [...DefaultCProdFilters(), IsOneTimeFilter()]
    },
    meta: {
      backTo: "_.one_time_purchases",
      categorySlug: DocCategories.USING_UPMIND,
      docsSlugs: ["product-management"]
    },
    children: [
      {
        path: "list",
        name: "adminOneTimePurchasesList",
        component: {
          name: "AdminOneTimePurchasesList",
          template: "<router-view />"
        },
        redirect: { name: "admin__allOneTimePurchases" },
        children: [
          {
            path: "all",
            name: "admin__allOneTimePurchases",
            component: CProdsListing,
            props: {
              availableSorters: () => [StatusSorter(), DatePurchasedSorter()],
              availableFilters: () => [
                ...DefaultCProdFilters(),
                IsOneTimeFilter()
              ],
              routeFilters: () => ({
                ...IsOneTimePurchaseParam()
              }),
              segmentType: SegmentTypes.CONTRACTS_PRODUCTS_SALE
            }
          },
          {
            path: "fulfilled",
            name: "admin__fulfilledOneTimePurchases",
            component: CProdsListing,
            props: {
              availableSorters: () => [StatusSorter(), DatePurchasedSorter()],
              availableFilters: () => [
                ...DefaultCProdFilters(["status.code"]),
                IsOneTimeFilter()
              ],
              routeFilters: () => ({
                ...IsOneTimePurchaseParam(),
                ...statusParam([ContractStatusCodes.ACTIVE])
              }),
              segmentType: SegmentTypes.CONTRACTS_PRODUCTS_SALE
            }
          },
          {
            path: "awaiting-activation",
            name: "admin__awaitingActivationOneTimePurchases",
            component: CProdsListing,
            props: {
              availableSorters: () => [StatusSorter(), DatePurchasedSorter()],
              availableFilters: () => [
                ...DefaultCProdFilters(["status.code"]),
                IsOneTimeFilter()
              ],
              routeFilters: () => ({
                ...IsOneTimePurchaseParam(),
                ...statusParam([ContractStatusCodes.AWAITING_ACTIVATION])
              }),
              segmentType: SegmentTypes.CONTRACTS_PRODUCTS_SALE
            }
          },
          {
            path: "awaiting-payment",
            name: "admin__awaitingPaymentOneTimePurchases",
            component: CProdsListing,
            props: {
              availableSorters: () => [StatusSorter(), DatePurchasedSorter()],
              availableFilters: () => [
                ...DefaultCProdFilters(["status.code"]),
                IsOneTimeFilter()
              ],
              routeFilters: () => ({
                ...IsOneTimePurchaseParam(),
                ...statusParam([ContractStatusCodes.PENDING])
              }),
              segmentType: SegmentTypes.CONTRACTS_PRODUCTS_SALE
            }
          },
          {
            path: "suspended",
            name: "admin__suspendedOneTimePurchases",
            component: CProdsListing,
            props: {
              availableSorters: () => [StatusSorter(), DatePurchasedSorter()],
              availableFilters: () => [
                ...DefaultCProdFilters(["status.code"]),
                IsOneTimeFilter()
              ],
              routeFilters: () => ({
                ...IsOneTimePurchaseParam(),
                ...statusParam([ContractStatusCodes.SUSPENDED])
              }),
              segmentType: SegmentTypes.CONTRACTS_PRODUCTS_SALE
            }
          },
          {
            path: "cancelled",
            name: "admin__cancelledOneTimePurchases",
            component: CProdsListing,
            props: {
              availableSorters: () => [
                StatusSorter(),
                DateCancelledSorter(),
                DatePurchasedSorter()
              ],
              availableFilters: () => [
                ...DefaultCProdFilters(["status.code"]),
                IsOneTimeFilter()
              ],
              routeFilters: () => ({
                ...IsOneTimePurchaseParam(),
                ...statusParam([ContractStatusCodes.CANCELLED])
              }),
              segmentType: SegmentTypes.CONTRACTS_PRODUCTS_SALE
            }
          },
          {
            path: "lapsed",
            name: "admin__lapsedOneTimePurchases",
            component: CProdsListing,
            props: {
              availableSorters: () => [StatusSorter(), DatePurchasedSorter()],
              availableFilters: () => [
                ...DefaultCProdFilters(["status.code"]),
                IsOneTimeFilter()
              ],
              routeFilters: () => ({
                ...IsOneTimePurchaseParam(),
                ...statusParam([ContractStatusCodes.CLOSED])
              }),
              segmentType: SegmentTypes.CONTRACTS_PRODUCTS_SALE
            }
          },
          {
            path: "last-30-days",
            name: "admin__fromLast30DaysOneTimePurchases",
            component: CProdsListing,
            props: {
              availableSorters: () => [StatusSorter(), DatePurchasedSorter()],
              availableFilters: () => [
                ...DefaultCProdFilters(["created_at"]),
                IsOneTimeFilter()
              ],
              routeFilters: () => ({
                ...IsOneTimePurchaseParam(),
                ...isFromLast30DaysParam()
              }),
              segmentType: SegmentTypes.CONTRACTS_PRODUCTS_SALE
            }
          }
        ]
      },
      {
        path: `category/:categoryId(${RegexMatch.UUID})`,
        name: "admin__allOTPsCategoryProducts",
        component: CProdsListing,
        props: {
          availableSorters: () => [StatusSorter(), DatePurchasedSorter()],
          availableFilters: () => [
            ...DefaultCProdFilters(["product.category.id"]),
            IsOneTimeFilter()
          ],
          routeFilters: vm => ({
            ...IsOneTimePurchaseParam(),
            ...IsCategoryParam(vm)
          }),
          segmentType: SegmentTypes.CONTRACTS_PRODUCTS_SALE
        }
      },
      {
        path: `segment/:segmentId(${RegexMatch.UUID})`,
        name: "adminOneTimePurchasesSegmentList",
        component: {
          name: "AdminOneTimePurchasesSegmentList",
          template: "<router-view />"
        },
        redirect: { name: "admin__oneTimePurchasesSegment" },
        children: [
          {
            path: "",
            name: "admin__oneTimePurchasesSegment",
            component: SegmentResultsListing,
            props: (route: Route) => ({
              defaultDisplayMode: ListDisplayMode.GRID,
              displayModeStoragePath: UserMetaKeys.UI_CPRODS_VIEW,
              segmentTypeHint: SegmentTypes.CONTRACTS_PRODUCTS_SALE,
              segmentId: route.params.segmentId,
              newSegmentRouteName: "adminOneTimePurchasesSegmentList",
              availableSorters: DefaultCProdSorters,
              availableFilters: () => [
                ...DefaultCProdFilters(),
                IsOneTimeFilter()
              ]
            })
          }
        ]
      }
    ]
  }
];
