export enum NotificationStatuses {
  UNREAD = 0,
  READ = 1
}

export enum NotificationTypes {
  PRIVATE = 1,
  GROUP = 2,
  ERROR = 3,
  WARNING = 4
}

export enum NotificationCategoryCodes {
  ACCOUNT = "account",
  BRAND = "brand",
  BRAND_HOOK_TEMPLATE = "brand_hook_template",
  BULK_ACTION = "bulk_action",
  CLIENT = "client",
  CLIENTS_ADDRESS = "clients_address",
  CLIENTS_COMPANY = "clients_company",
  CLIENTS_EMAIL = "clients_email",
  CLIENTS_FIELDS_VALUE = "clients_fields_value",
  CLIENTS_PAYMENT_DETAIL = "clients_payment_detail",
  CLIENTS_PHONE = "clients_phone",
  CONTRACT = "contract",
  CONTRACTS_PRODUCT = "contracts_product",
  CONTRACT_PRODUCT = "contract_product",
  COST = "cost",
  CREDIT_DEBIT_TRANSACTION = "credit_debit_transaction",
  CSV_EXPORT = "csv_export",
  CUSTOM_FIELD_VALUE = "custom_field_value",
  GATEWAY = "gateway",
  INVOICE = "invoice",
  INVOICE_PAYMENT = "invoice_payment",
  LEAD = "lead",
  OAUTH_CLIENT = "oauth_client",
  ORGANISATION = "organisation",
  PAYMENT_RECONCILIATION_LOG = "payment_reconciliation_log",
  PRICELIST = "pricelist",
  PRODUCT = "product",
  PRODUCTS_GROUP = "products_group",
  PROMOTION = "promotion",
  PROVISION_RESULT = "provision_result",
  TEMPLATE = "template",
  TEMPLATES_CONTENT = "templates_content",
  TICKET = "ticket",
  TICKET_MESSAGE = "ticket_message",
  TICKET_USER = "ticket_user",
  UPMIND_PAY_ACCOUNT = "upmind_pay_account",
  USER = "user",
  VOUCHER = "voucher",
  WALLET_REFUND = "wallet_refund",
  WALLET_REFUND_REQUEST = "wallet_refund_request"
}

export enum NotificationChannelCodes {
  EMAIL = "template_mail",
  IN_APP = "template_websocket",
  SLACK = "slack"
}
