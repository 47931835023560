export enum ScheduledActionTypes {
  PRICE_CHANGE = "price_change",
  INTERVAL_CHANGE = "interval_change",
}

export enum ScheduledActionPriceChangeType {
  FIXED = "fixed",
  PERCENTAGE = "percentage",
}

export enum ScheduledActionPriceChangeDirection {
  UP = "up",
  DOWN = "down",
}

export enum ScheduledActionStatusTypes {
  STATUS_CANCELLED = "contract_product_scheduled_action_cancelled",
  STATUS_EXECUTED = "contract_product_scheduled_action_executed",
  STATUS_OVERRIDDEN = "contract_product_scheduled_action_overridden",
  STATUS_REVERTED = "contract_product_scheduled_action_reverted",
  STATUS_SCHEDULED = "contract_product_scheduled_action_scheduled",
}
