import { DataModules } from "@/store/modules/data/modules";
import type { MutationTree, GetterTree, ActionTree } from "vuex";
import type { IState } from "@/store";
import { ImportStatusCodes as ISC } from "@/data/enums/imports";
import type { IImport } from "@/models/imports";
import i18n from "@/i18n";
import { ToastProgrammatic as $toast } from "buefy";

export interface IDemoDataState {
  status: IImport["status"] | null;
  timeout: ReturnType<typeof setTimeout> | null;
}

const initialState = (): IDemoDataState => ({
  status: null,
  timeout: null
});

const getters: GetterTree<IDemoDataState, IState> = {
  hasDemoData: (s, getters) => {
    return !!getters.importId;
  },
  importId: (s, g, rootState) => {
    return rootState?.brand?.demo_data_import_id;
  },
  importIsComplete: ({ status }) => {
    return status?.code === ISC.COMPLETE;
  },
  importIsProcessing: ({ status }) => {
    return [
      ISC.EXPORTING,
      ISC.IN_PROGRESS,
      ISC.MAPPINGS_REQUIRED,
      ISC.PENDING,
      ISC.STAGED,
      ISC.STEP_SKIPPED
    ].includes(status?.code as ISC);
  }
};

const actions: ActionTree<IDemoDataState, IState> = {
  checkImportStatus: async ({ dispatch, commit, getters, rootGetters }) => {
    // Abort if user is not an authenticated admin
    if (!rootGetters["auth/admin/isAuthenticated"]) return;
    if (!getters.hasDemoData) return commit("importStatus", null);
    await dispatch("getImportStatus");
    if (!getters.importIsComplete) dispatch("monitorImportStatus");
  },

  getImportStatus: async ({ dispatch, commit, getters }) => {
    const { status } = await dispatch(
      `data/${DataModules.IMPORTS}/get`,
      {
        importId: getters.importId,
        payload: {
          storeData: false,
          returnData: true
        }
      },
      { root: true }
    );
    commit("importStatus", status);
  },

  monitorImportStatus: async ({ state, commit, dispatch, getters }) => {
    if (state.timeout) return; // Abort – monitoring in progress
    commit(
      "monitorTimeout",
      setTimeout(async () => {
        commit("monitorTimeout", null);
        await dispatch("getImportStatus");
        if (getters.importIsComplete)
          $toast.open({
            message: i18n.t(
              "_sentence.settings.demo_data_successfully_seeded"
            ) as string
          });
        else dispatch("monitorImportStatus");
      }, 1000)
    );
  }
};

const mutations: MutationTree<IDemoDataState> = {
  importStatus: (state: IDemoDataState, status: IImport["status"]) => {
    state.status = status || null;
  },
  monitorTimeout: (state: IDemoDataState, timeout: NodeJS.Timeout | null) => {
    state.timeout = timeout;
  }
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations
};
