import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import type { ApiPathGetter } from "@/models/api";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext }): ApiPathGetter =>
    () => {
      const admin = "api/admin/terms_and_conditions";
      const client = "api/terms_and_conditions";
      const contextual = isAdminContext ? admin : client;
      return { client, admin, contextual };
    },
  translationApiPath: (): ApiPathGetter => () => {
    const admin = `api/admin/translations/terms_and_conditions`;
    return { admin };
  },
  termsAndConditionsScope: () => id => {
    return `$termsAndConditions_${id}`;
  },
  termsAndConditionsTranslationsScope: () => id => {
    return `$termsAndConditions_${id}_translations`;
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath().admin,
        storeModule: DataModules.TERMS_AND_CONDITIONS
      },
      { root: true }
    );
  },
  listTranslations: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `${getters.translationApiPath().admin}/${payload.id}`,
        storeModule: DataModules.TERMS_AND_CONDITIONS
      },
      { root: true }
    );
  },
  get: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${
          payload.params?.brand_id
            ? getters.apiPath().client
            : getters.apiPath().contextual
        }/${payload.id}`,
        storeModule: DataModules.TERMS_AND_CONDITIONS
      },
      { root: true }
    );
  },
  create: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/create",
      {
        ...payload,
        path: getters.apiPath().admin,
        storeModule: DataModules.TERMS_AND_CONDITIONS
      },
      { root: true }
    );
  },
  duplicate: ({ dispatch, getters }, { id, data }) => {
    return dispatch(
      "data/create",
      {
        path: `${getters.apiPath().admin}/${id}/duplicate`,
        storeModule: DataModules.TERMS_AND_CONDITIONS,
        data
      },
      { root: true }
    );
  },
  openPreviewModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/admin/settings/billing/termsAndConditionsModal.vue"
            ),
          ...payload
        }
      },
      { root: true }
    );
  },
  openPreviewMarkdownModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/admin/settings/billing/termsAndConditions/previewMarkdownModal.vue"
            ),
          ...payload
        }
      },
      { root: true }
    );
  },
  openVersionHistoryModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/admin/settings/billing/termsAndConditions/versionHistoryModal.vue"
            ),
          ...payload
        }
      },
      { root: true }
    );
  },
  openTranslationModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/admin/settings/billing/termsAndConditions/termsAndConditionsTranslationModal.vue"
            ),
          ...payload
        }
      },
      { root: true }
    );
  },
  saveTranslation: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/create",
      {
        ...payload,
        path: `${getters.translationApiPath().admin}/${payload.id}`,
        storeModule: DataModules.TERMS_AND_CONDITIONS
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
