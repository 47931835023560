import type { IFilter } from "@/models/tables";
import { FilterOperators, FilterTypes } from "@/data/table";
import i18n from "@/i18n";

// Params

export const NameFilter: () => IFilter = () => ({
  key: "name",
  label: i18n.t("_.name"),
  type: FilterTypes.STRING,
  operator: FilterOperators.CONTAINS
});

export const CodeFilter: () => IFilter = () => ({
  key: "code",
  label: i18n.t("_.code"),
  type: FilterTypes.STRING,
  operator: FilterOperators.CONTAINS
});

export const DefaultOrderTemplatesFilters: () => IFilter[] = () => [
  NameFilter(),
  CodeFilter()
];
