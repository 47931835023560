import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import { Methods } from "@/models/methods";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  productFieldsValuesScope: () => id => {
    return `$provision_product_${id}_fields_values`;
  },
  productFieldsScope: () => id => {
    return `$provision_product_${id}_fields`;
  },
  productAttributesValuesScope: () => id => {
    return `$provision_product_${id}_attributes_values`;
  },
  productAttributesScope: () => id => {
    return `$provision_product_${id}_attributes`;
  },
  productSetupFunctionsScope: () => id => {
    return `$provision_product_${id}_setup_unctions`;
  }
};

const actions: ActionTree<IDataState, IState> = {
  listProductFields: ({ dispatch }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `api/admin/products/${payload?.id}/provision_fields`,
        params: {
          limit: 0,
          with_staged_imports: 1
        },
        storeModule: DataModules.PROVISIONING
      },
      { root: true }
    );
  },
  listProductFieldsValues: ({ dispatch }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `api/admin/products/${payload?.id}/provision_fields/values`,
        params: {
          limit: 0,
          with_staged_imports: 1
        },
        storeModule: DataModules.PROVISIONING
      },
      { root: true }
    );
  },
  updateProductFieldsValues: ({ dispatch }, payload) => {
    return dispatch(
      "data/update",
      {
        ...payload,
        path: `api/admin/products/${payload?.id}/provision_fields/values`,
        storeModule: DataModules.PROVISIONING
      },
      { root: true }
    );
  },
  checkProductFieldsValues: ({ dispatch }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PATCH,
        path: `api/admin/products/${payload?.id}/provision_fields/values/check`,
        storeModule: DataModules.PROVISIONING
      },
      { root: true }
    );
  },
  listProductAttributes: ({ dispatch }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `api/admin/products/${payload?.id}/provision_configuration_attributes`,
        params: {
          limit: 0,
          with_staged_imports: 1
        },
        storeModule: DataModules.PROVISIONING
      },
      { root: true }
    );
  },
  listProductAttributesValues: ({ dispatch }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `api/admin/products/${payload?.id}/provision_configuration_attributes/values`,
        params: {
          limit: 0,
          with_staged_imports: 1
        },
        storeModule: DataModules.PROVISIONING
      },
      { root: true }
    );
  },
  updateProductAttributesValues: ({ dispatch }, payload) => {
    return dispatch(
      "data/update",
      {
        ...payload,
        path: `api/admin/products/${payload?.id}/provision_configuration_attributes/values`,
        storeModule: DataModules.PROVISIONING
      },
      { root: true }
    );
  },
  listProductSetupFunctions: ({ dispatch }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `api/admin/products/${payload?.id}/provision_setup_functions`,
        params: {
          limit: 0,
          with_staged_imports: 1,
          with: "blueprint_function"
        },
        storeModule: DataModules.PROVISIONING
      },
      { root: true }
    );
  },
  updateProductSetupFunctions: ({ dispatch }, payload) => {
    return dispatch(
      "data/update",
      {
        ...payload,
        path: `api/admin/products/${payload?.id}/provision_setup_functions`,
        storeModule: DataModules.PROVISIONING
      },
      { root: true }
    );
  },
  openManageProvisionFieldModal: async ({ dispatch }, payload) => {
    return new Promise(resolve => {
      dispatch(
        "ui/open/slideModal",
        {
          config: {
            component: () =>
              import(
                "@/components/app/admin/catalogue/products/provisioning/manageProvisionFieldModal.vue"
              ),
            props: payload,
            onCancel: () => {
              resolve({
                cancelled: true
              });
            },
            events: {
              success: (results: any) => {
                resolve({
                  cancelled: false,
                  results
                });
              }
            }
          }
        },
        { root: true }
      );
    });
  },
  openManageSetupFunctionsModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/admin/catalogue/products/provisioning/manageSetupFunctionsModal.vue"
            ),
          ...payload
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  modules: {
    basketProducts: require("./basketProducts").default,
    blueprints: require("./blueprints").default,
    brand: require("./brand").default,
    categories: require("./categories").default,
    configurations: require("./configurations").default,
    contractProducts: require("./contractProducts").default,
    functions: require("./functions").default,
    providers: require("./providers").default,
    requests: require("./requests").default
  }
};
