export default [
  {
    path: "settings",
    component: {
      name: "AdminAccountSettings",
      template: `<router-view v-bind="$attrs" />`
    },
    meta: {
      title: "_.settings"
    },
    children: [
      {
        path: "",
        name: "admin__upmindSettings",
        redirect: { name: "admin__upmindProfile" }
      },
      {
        path: "profile",
        name: "admin__upmindProfile",
        component: () =>
          import("@/views/admin/upmind/settings/profile/index.vue"),
        meta: {
          title: "_.profile",
          backTo: "_.profile"
        }
      },
      {
        path: "notifications",
        name: "admin__upmindNotifications",
        component: () =>
          import("@/views/admin/upmind/settings/notifications/index.vue"),
        meta: {
          title: "_.notifications",
          backTo: "_.notifications"
        }
      }
    ]
  }
];
