import { mapState } from "vuex";
import type { IState } from "@/store";
import type { IUser } from "@/models/users";

export default {
  computed: {
    ...mapState({
      loggedUser: (state: IState): IUser => state.user
    })
  }
};
