import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import type { ApiPathGetter } from "@/models/api";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath: (): ApiPathGetter => () => {
    const admin = "api/admin/documentation";
    return { admin };
  },
  scope: () => id => {
    return `$documentation_${id}`;
  },
  categoriesScope: () => {
    return "$documentation_categories";
  },
  categoryDocsScope: () => categorySlug => {
    return `$documentation_categories_${categorySlug}`;
  },
  docsSearchScope: () => {
    return "$documentation_search";
  }
};

const actions: ActionTree<IDataState, IState> = {
  listCategories: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `${getters.apiPath().admin}/categories`,
        storeModule: DataModules.DOCUMENTATION
      },
      { root: true }
    );
  },

  listCategoryDocuments: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `${getters.apiPath().admin}/categories/${
          payload.categorySlug
        }/documents`,
        storeModule: DataModules.DOCUMENTATION
      },
      { root: true }
    );
  },

  getDocument: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        path: `${getters.apiPath().admin}/documents/${payload.document_slug}`,
        storeModule: DataModules.DOCUMENTATION
      },
      { root: true }
    );
  },
  searchDocuments: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `${getters.apiPath().admin}`,
        storeModule: DataModules.DOCUMENTATION,
        ignoreStored: true,
        params: {
          search: payload.queryText
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
