export enum HookCodes {
  ACCOUNT_CREATED = "account_created_hook",
  ACCOUNT_MULTI_CURRENCY_BALANCE = "account_multi_currency_balance_hook",
  API_USER_CREATED = "api_user_created_hook",
  API_USER_DELETED = "api_user_deleted_hook",
  API_USER_REFRESHED = "api_user_refreshed_hook",
  API_USER_REVOKED = "api_user_revoked_hook",
  API_USER_UPDATED = "api_user_updated_hook",
  BASKET_ABANDONED = "basket_abandoned_hook",
  BASKET_CREATED = "basket_created_hook",
  BASKET_DELETED = "basket_deleted_hook",
  BASKET_LOCALISE = "basket_localise_hook",
  BASKET_RECALCULATED = "basket_recalculated_hook",
  BASKET_UPDATED = "basket_updated_hook",
  BRAND_CREATED = "brand_created_hook",
  BRAND_DELETED = "brand_deleted_hook",
  BRAND_UPDATED = "brand_updated_hook",
  BULK_ACTION_COMPLETED = "bulk_action_completed_hook",
  BULK_ACTION_INCOMPLETE = "bulk_action_incomplete_hook",
  BULK_ACTION_SCHEDULED = "bulk_action_scheduled_hook",
  BULK_ACTION_STARTED = "bulk_action_started_hook",
  BULK_ACTION_UPDATED = "bulk_action_updated_hook",
  CLIENT_ADDED_TWO_FACTOR_AUTH = "client_added_two_factor_auth_hook",
  CLIENT_ADDRESS_CREATED = "clients_address_created_hook",
  CLIENT_ADDRESS_DELETED = "clients_address_deleted_hook",
  CLIENT_ADDRESS_UPDATED = "clients_address_updated_hook",
  CLIENT_CLOSED_TICKET = "client_closed_ticket_hook",
  CLIENT_COMPANY_CREATED = "clients_company_created_hook",
  CLIENT_COMPANY_DELETED = "clients_company_deleted_hook",
  CLIENT_COMPANY_UPDATED = "clients_company_updated_hook",
  CLIENT_CONSOLIDATION_INVOICES_DISPATCHED = "client_consolidation_invoices_dispatched_hook",
  CLIENT_DELETED = "client_deleted_hook",
  CLIENT_EMAIL_CREATED = "clients_email_created_hook",
  CLIENT_EMAIL_DELETED = "clients_email_deleted_hook",
  CLIENT_EMAIL_UPDATED = "clients_email_updated_hook",
  CLIENT_EMAIL_VERIFICATION_REQUIRED = "clients_email_verification_required_hook",
  CLIENT_EMAIL_VERIFIED = "clients_email_verified_hook",
  CLIENT_EXCEEDED_MAX_INCORRECT_LOGINS = "client_exceeded_max_incorrect_logins_hook",
  CLIENT_LOGGED_IN = "client_logged_in_hook",
  CLIENT_LOGIN_EMAIL_UPDATED = "client_login_email_updated_hook",
  CLIENT_OPENED_NEW_TICKET = "client_opened_new_ticket_hook",
  CLIENT_PASSWORD_CHANGED = "client_password_changed_hook",
  CLIENT_PASSWORD_WAS_RESET = "client_password_was_reset_hook",
  CLIENT_PAYMENT_DETAIL_CREATED = "clients_payment_detail_created_hook",
  CLIENT_PAYMENT_DETAIL_DELETED = "clients_payment_detail_deleted_hook",
  CLIENT_PAYMENT_DETAIL_EXPIRY = "clients_payment_detail_expiry_hook",
  CLIENT_PAYMENT_DETAIL_UPDATED = "clients_payment_detail_updated_hook",
  CLIENT_PHONE_CREATED = "clients_phone_created_hook",
  CLIENT_PHONE_DELETED = "clients_phone_deleted_hook",
  CLIENT_PHONE_UPDATED = "clients_phone_updated_hook",
  CLIENT_PHONE_VERIFIED = "clients_phone_verified_hook",
  CLIENT_POSTED_TICKET_MESSAGE = "client_posted_ticket_message_hook",
  CLIENT_REGISTERED = "client_registered_hook",
  CLIENT_REMOVED_TWO_FACTOR_AUTH = "client_removed_two_factor_auth_hook",
  CLIENT_REQUESTED_PASSWORD_RESET = "client_requested_password_reset_hook",
  CLIENT_TICKET_INACTIVE = "client_inactive_ticket_hook",
  CLIENT_UPDATED = "client_updated_hook",
  CLIENT_UPDATED_TICKET_MESSAGE = "client_updated_ticket_message_hook",
  CLIENT_VAULT_ASSET_CREATED = "client_vault_asset_created_hook",
  CLIENT_VAULT_ASSET_DECRYPTED = "client_vault_asset_decrypted_hook",
  CLIENT_VAULT_ASSET_DELETED = "client_vault_asset_removed_hook",
  CLIENT_VAULT_ASSET_UPDATED = "client_vault_asset_updated_hook",
  CLIENT_VERIFICATION_REQUIRED = "client_verification_required_hook",
  CLIENT_WALLET_BALANCE_ADJUSTED = "client_wallet_balance_adjusted_hook",
  CLIENT_WAS_IMPERSONATED = "client_was_impersonated_hook",
  CONTRACT_ACTIVATED = "contract_activated_hook",
  CONTRACT_AWAITING_ACTIVATION = "contract_awaiting_activation_hook",
  CONTRACT_CANCELLED = "contract_cancelled_hook",
  CONTRACT_CLOSED = "contract_closed_hook",
  CONTRACT_COMPANY_ADDRESS_VAT_UPDATED = "contract_company_address_vat_updated_hook",
  CONTRACT_CREATED = "contract_created_hook",
  CONTRACT_FAILED_RECURRING_PROCESSING = "contract_failed_recurring_processing_hook",
  CONTRACT_PRODUCT_ACTIVATED = "contract_product_activated_hook",
  CONTRACT_PRODUCT_AUTO_EXPIRE_OFF = "contract_product_renew_on_hook",
  CONTRACT_PRODUCT_AUTO_EXPIRE_ON = "contract_product_renew_off_hook",
  CONTRACT_PRODUCT_AWAITING_ACTIVATION = "contract_product_awaiting_activation_hook",
  CONTRACT_PRODUCT_BILLING_CYCLE_CHANGED = "contract_product_billing_cycle_changed_hook",
  CONTRACT_PRODUCT_CANCELLED = "contract_product_cancelled_hook",
  CONTRACT_PRODUCT_CANCEL_INTERVAL_CHANGED = "contract_product_cancel_interval_hook",
  CONTRACT_PRODUCT_CANCEL_REQUEST_APPROVED = "contract_product_cancel_request_approved_hook",
  CONTRACT_PRODUCT_CANCEL_REQUEST_CREATED = "contract_product_cancel_request_created_hook",
  CONTRACT_PRODUCT_CANCEL_REQUEST_DELETED_CLIENT = "contract_product_cancel_request_deleted_client_hook",
  CONTRACT_PRODUCT_CANCEL_REQUEST_DELETED_USER = "contract_product_cancel_request_deleted_user_hook",
  CONTRACT_PRODUCT_CLOSE_INTERVAL_CHANGED = "contract_product_close_interval_hook",
  CONTRACT_PRODUCT_CLOSED = "contract_product_closed_hook",
  CONTRACT_PRODUCT_COMPANY_ADDRESS_VAT_UPDATED = "contract_product_company_address_vat_updated_hook",
  CONTRACT_PRODUCT_CONSOLIDATION_OFF = "contract_product_consolidation_off_hook",
  CONTRACT_PRODUCT_CONSOLIDATION_ON = "contract_product_consolidation_on_hook",
  CONTRACT_PRODUCT_CREATED = "contract_product_created_hook",
  CONTRACT_PRODUCT_CURRENCY_CHANGED = "contract_product_currency_changed_hook",
  CONTRACT_PRODUCT_DUE_DATE_CHANGED = "contract_product_due_date_changed_hook",
  CONTRACT_PRODUCT_EXPIRE_NOTIFICATION = "contract_product_expire_notification_hook",
  CONTRACT_PRODUCT_INVOICE_INTERVAL_CHANGED = "contract_product_invoice_interval_hook",
  CONTRACT_PRODUCT_MANUAL_STATUS_ON = "contract_product_manual_status_on_hook",
  CONTRACT_PRODUCT_OWNERSHIP_CHANGED = "contract_product_ownership_changed_hook",
  CONTRACT_PRODUCT_PACKAGE_CHANGED = "contract_product_package_changed_hook",
  CONTRACT_PRODUCT_PAYMENT_INTERVAL_CHANGED = "contract_product_payment_interval_hook",
  CONTRACT_PRODUCT_PRICE_CHANGED = "contract_product_price_changed_hook",
  CONTRACT_PRODUCT_RENEWED = "contract_product_renewed_hook",
  CONTRACT_PRODUCT_SETUP_REQUIRE_CONFIRMATION = "contract_product_setup_values_require_confirmation_hook",
  CONTRACT_PRODUCT_START_INVOICING = "contract_product_invoicing_start_hook",
  CONTRACT_PRODUCT_STATUS_UPDATED = "contract_product_status_updated_hook",
  CONTRACT_PRODUCT_STOP_INVOICING = "contract_product_invoicing_stop_hook",
  CONTRACT_PRODUCT_SUSPENDED = "contract_product_suspended_hook",
  CONTRACT_PRODUCT_SUSPEND_INTERVAL_CHANGED = "contract_product_suspend_interval_hook",
  CONTRACT_PRODUCT_UNSUSPENDED = "contract_product_unsuspended_hook",
  CONTRACT_SUSPENDED = "contract_suspended_hook",
  CONTRACT_UNSUSPENDED = "contract_unsuspended_hook",
  CONTRACT_UPDATED = "contract_updated_hook",
  CONTRACT_UPDATED_STATUS = "contract_updated_status_hook",
  COST_CREATED = "cost_created_hook",
  COST_DELETED = "cost_deleted_hook",
  COST_UPDATED = "cost_updated_hook",
  CREDIT_DEBIT_TRANSACTION = "credit_debit_transaction_created_hook",
  CREDIT_DEBIT_TRANSACTION_CAPTURED = "credit_debit_transaction_captured_hook",
  CREDIT_DEBIT_TRANSACTION_REFUNDED = "credit_debit_transaction_refunded_hook",
  CSV_EXPORT_GENERATED = "csv_export_generated_hook",
  CUSTOM_FIELD_VALUE_CREATED = "custom_field_value_created_hook",
  CUSTOM_FIELD_VALUE_DELETED = "custom_field_value_deleted_hook",
  CUSTOM_FIELD_VALUE_UPDATED = "custom_field_value_updated_hook",
  GATEWAY_CREATED = "gateway_created_hook",
  GATEWAY_DELETED = "gateway_deleted_hook",
  GATEWAY_UPDATED = "gateway_updated_hook",
  GATEWAY_UPDATED_SETTINGS = "gateway_updated_settings_hook",
  INVOICE_3D_PAYMENT_REQUIRED = "invoice_payment3_d_required_hook",
  INVOICE_ADJUSTED = "invoice_adjusted_hook",
  INVOICE_CANCELLED = "invoice_cancelled_hook",
  INVOICE_CAPTURE_FAILED = "invoice_capture_failed_hook",
  INVOICE_COMMISSIONS_MANUALLY_CALCULATED = "invoice_attempted_commission_calculation_hook",
  INVOICE_CREATED = "invoice_created_hook",
  INVOICE_CREDITED = "invoice_credited_hook",
  INVOICE_DUPLICATED = "invoice_duplicated_hook",
  INVOICE_OVERDUE_NOTIFICATION = "invoice_overdue_notification_hook",
  INVOICE_PAID = "invoice_paid_hook",
  INVOICE_PAID_WITH_NEXT_CARD = "invoice_paid_with_next_card_hook",
  INVOICE_PAYMENT_CARD_EXPIRED = "invoice_payment_card_expired_hook",
  INVOICE_PAYMENT_FAILED = "invoice_payment_failed_hook",
  INVOICE_PAYMENT_MISSING_DATA = "invoice_payment_missing_data_hook",
  INVOICE_PAYMENT_RECEIVED = "invoice_payment_received_hook",
  INVOICE_PAYMENT_REFUNDED = "invoice_payment_refunded_hook",
  INVOICE_PDF_GENERATED = "invoice_pdf_generated_hook",
  INVOICE_PRE_DUE_NOTIFICATION = "invoice_pre_due_notification_hook",
  INVOICE_REFRESHED = "invoice_refreshed_hook",
  INVOICE_REFUNDED = "invoice_refunded_hook",
  INVOICE_REFUND_REQUESTED = "invoice_refund_requested_hook",
  INVOICE_UPDATED_DUE_DATE = "invoice_updated_due_date_hook",
  LEAD_OPENED_NEWT_TICKET = "lead_opened_new_ticket_hook",
  LEAD_POSTED_PRIVATE_TICKET_MESSAGE_TO_LEAD = "user_posted_private_ticket_message_to_lead_hook",
  LEAD_POSTED_TICKET_MESSAGE = "lead_posted_ticket_message_hook",
  OAUTH_CLIENT_CREATED = "oauth_client_created_hook",
  OAUTH_CLIENT_DELETED = "oauth_client_deleted_hook",
  ORDER_PLACED = "order_placed_hook",
  ORGANISATION_ACTIVATED = "organisation_activated_hook",
  ORGANISATION_CREATED = "organisation_created_hook",
  ORGANISATION_UPDATED = "organisation_updated_hook",
  ORGANISATION_VERIFICATION_REQUIRED = "organisation_verification_required_hook",
  PAYMENT_RECONCILIATION_CREATED = "payment_reconciliation_log_created_hook",
  PRICELIST_CREATED = "pricelist_created_hook",
  PRICELIST_DELETED = "pricelist_deleted_hook",
  PRICELIST_UPDATED = "pricelist_updated_hook",
  PRICE_LIST_CREATED = "pricelist_created_hook",
  PRICE_LIST_DELETED = "pricelist_deleted_hook",
  PRICE_LIST_UPDATED = "pricelist_updated_hook",
  PRODUCT_CREATED = "product_created_hook",
  PRODUCT_DELETED = "product_deleted_hook",
  PRODUCT_DISABLED = "product_disabled_hook",
  PRODUCT_ENABLED = "product_enabled_hook",
  PRODUCT_GROUP_CREATED = "products_group_created_hook",
  PRODUCT_GROUP_DELETED = "products_group_deleted_hook",
  PRODUCT_GROUP_UPDATED = "products_group_updated_hook",
  PRODUCT_UPDATED = "product_updated_hook",
  PROMOTION_CREATED = "promotion_created_hook",
  PROMOTION_DELETED = "promotion_deleted_hook",
  PROMOTION_DISABLED = "promotion_disabled_hook",
  PROMOTION_UNDELETED = "promotion_undeleted_hook",
  PROMOTION_UPDATED = "promotion_updated_hook",
  PROVISION_REQUEST_CANCELLED = "provision_request_cancelled",
  PROVISION_REQUEST_DISPATCHED = "provision_request_dispatched",
  PROVISION_REQUEST_DRAFT = "provision_request_draft",
  PROVISION_REQUEST_ERROR = "provision_request_error",
  PROVISION_REQUEST_PENDING = "provision_request_pending",
  PROVISION_REQUEST_SCHEDULED = "provision_request_scheduled",
  PROVISION_REQUEST_SUCCESS = "provision_request_success",
  PROVISION_RESULT_RECEIVED = "provision_result_received_hook",
  RESELLER_ACCOUNT_CREATED = "reseller_account_created_hook",
  RETENTION_FLAGGED = "contract_product_retention_flagged_hook",
  RETENTION_IN_PROGRESS = "contract_product_retention_in_progress_hook",
  RETENTION_LOST = "contract_product_retention_lost_hook",
  RETENTION_NONE = "contract_product_retention_none_hook",
  RETENTION_SAVED = "contract_product_retention_saved_hook",
  TEMPLATE_CONTENT_CREATED = "template_content_created_hook",
  TEMPLATE_CONTENT_DELETED = "template_content_deleted_hook",
  TEMPLATE_CONTENT_UPDATED = "template_content_updated_hook",
  TEMPLATE_CREATED = "template_created_hook",
  TEMPLATE_DELETED = "template_deleted_hook",
  TEMPLATE_DISABLED = "template_disabled_hook",
  TEMPLATE_ENABLED = "template_enabled_hook",
  TEMPLATE_MAPPING_CREATED = "hook_template_mapping_created_hook",
  TEMPLATE_MAPPING_DELETED = "hook_template_mapping_deleted_hook",
  TEMPLATE_MAPPING_DISABLED = "hook_template_mapping_disabled_hook",
  TEMPLATE_MAPPING_ENABLED = "hook_template_mapping_enabled_hook",
  TEMPLATE_MAPPING_UPDATED = "hook_template_mapping_updated_hook",
  TEMPLATE_UPDATED = "template_updated_hook",
  TICKET_CLIENT_REPLIED = "ticket_client_replied_hook",
  TICKET_CLOSED = "ticket_closed_hook",
  TICKET_IN_PROGRESS = "ticket_in_progress_hook",
  TICKET_OPENED = "ticket_opened_hook",
  TICKET_REOPENED = "ticket_reopened_hook",
  TICKET_WAITING_RESPONSE = "ticket_waiting_response_hook",
  UPDATE_TICKET = "update_ticket_hook",
  UPDATE_TICKET_STATUS = "update_ticket_status_hook",
  UPMIND_PAY_ACCOUNT_CREATED = "upmind_pay_account_created_hook",
  UPMIND_PAY_ACCOUNT_UPDATED = "upmind_pay_account_updated_hook",
  USER_ADDED_TWO_FACTOR_AUTH = "user_added_two_factor_auth_hook",
  USER_ASSIGNED_TO_TICKET = "user_assigned_to_ticket_hook",
  USER_CLOSED_TICKET = "user_closed_ticket_hook",
  USER_CREATED = "user_created_hook",
  USER_CREATED_UNVERIFIED_CLIENT = "user_created_unverified_client_hook",
  USER_CREATED_VERIFIED_CLIENT = "user_created_verified_client_hook",
  USER_DELETED = "user_deleted_hook",
  USER_DELETED_PRIVATE_TICKET_MESSAGE = "user_deleted_private_ticket_message_hook",
  USER_DELETED_TICKET_MESSAGE = "user_deleted_ticket_message_hook",
  USER_EXCEEDED_MAX_INCORRECT_LOGINS = "user_exceeded_max_incorrect_logins_hook",
  USER_LOGGED_IN = "user_logged_in_hook",
  USER_OPENED_NEW_TICKET = "user_opened_new_ticket_hook",
  USER_PASSWORD_CHANGED = "user_password_changed_hook",
  USER_PASSWORD_WAS_RESET = "user_password_was_reset_hook",
  USER_POSTED_PRIVATE_TICKET_MESSAGE = "user_posted_private_ticket_message_hook",
  USER_POSTED_TICKET_MESSAGE = "user_posted_ticket_message_hook",
  USER_REMOVED_TWO_FACTOR_AUTH = "user_removed_two_factor_auth_hook",
  USER_REQUESTED_PASSWORD_RESET = "user_requested_password_reset_hook",
  USER_TICKET_INACTIVE = "user_inactive_ticket_hook",
  USER_TOOK_TICKET = "user_take_ticket_hook",
  USER_UPDATED = "user_updated_hook",
  USER_UPDATED_PRIVATE_TICKET_MESSAGE = "user_updated_private_ticket_message_hook",
  USER_UPDATED_TICKET_MESSAGE = "user_updated_ticket_message_hook",
  USER_VERIFICATION_REQUIRED = "user_verification_required_hook",
  USER_WAS_IMPERSONATED = "user_was_impersonated_hook",
  VOUCHER_CREATED = "voucher_created_hook",
  WALLET_REFUND_CREATED = "wallet_refund_created_hook",
  WALLET_REFUND_REQUEST_CREATED = "wallet_refund_request_created_hook",
  WALLET_REFUND_REQUEST_UPDATED = "wallet_refund_request_updated_hook",
  WEBHOOK_ENDPOINT_CREATED = "webhook_endpoint_created_hook",
  WEBHOOK_ENDPOINT_DELETED = "webhook_endpoint_deleted_hook",
  WEBHOOK_ENDPOINT_UPDATED = "webhook_endpoint_updated_hook"
}

export enum AppHookCodes {
  ADD_CATALOGUE_PRODUCT_COMPLETED = "add_catalogue_product_completed",
  ADD_CLIENT_ABANDONED = "add_client_abandoned",
  ADD_CLIENT_COMPLETED = "add_client_completed",
  ADD_TO_BASKET_SUCCESS = "add_to_basket_success",
  ADMIN_USER_LOGGED_IN = "admin_user_logged_in",
  CLIENT_REGISTERED = "client_registered",
  DEMO_DATA_SEEDED = "demo_data_seeded"
}
