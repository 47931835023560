import type { IDataState } from "@/store/modules/data";

const initialState = {} as IDataState;

export default {
  namespaced: true,
  state: initialState,
  modules: {
    categories: require("./categories").default
  }
};
