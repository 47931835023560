import i18n from "@/i18n";
import { DataModules } from "@/store/modules/data/modules";

export enum UpmindObjectTypes {
  ACCOUNT = "account",
  ACCOUNT_TYPE = "account_type",
  ACCOUNTS_STATUS = "accounts_status",
  AFFILIATE = "affiliate",
  AFFILIATE_ACCOUNT = "affiliate_account",
  AFFILIATE_ACCOUNT_LINK = "affiliate_account_link",
  AFFILIATE_ACCOUNT_REFERRAL = "affiliate_account_referral",
  AFFILIATE_COOKIE = "affiliate_cookie",
  AFFILIATE_PRODUCT = "affiliate_product",
  AFFILIATE_PRODUCTS_PRICE = "affiliate_products_price",
  AFFILIATE_PROVIDER = "affiliate_provider",
  APPLICATION_LINK = "application_link",
  BILLING_CYCLE = "billing_cycle",
  BRAND = "brand",
  BRAND_HOOK_TEMPLATE = "brand_hook_template",
  BULK_ACTION = "bulk_action",
  BULK_ACTION_OBJECTS = "bulk_action_objects",
  BULK_ACTION_RESULT = "bulk_action_result",
  CARD_TYPES = "card_types",
  CLIENT = "client",
  CLIENT_NOTE = "client_note",
  CLIENTS_ACCOUNT = "clients_account",
  CLIENTS_ADDRESS = "clients_address",
  CLIENTS_COMPANY = "clients_company",
  CLIENTS_EMAIL = "clients_email",
  CLIENTS_IMPORTS = "clients_imports",
  CLIENTS_PAYMENT_DETAIL = "clients_payment_detail",
  CLIENTS_PHONE = "clients_phone",
  CONTRACT = "contract",
  CONTRACT_PRODUCT_DAYS_CORRECTION = "contract_product_days_correction",
  CONTRACT_PRODUCTS_PROMOTION = "contract_products_promotion",
  CONTRACT_PROMOTION = "contract_promotion",
  CONTRACT_PROMOTION_DATA = "contract_promotion_data",
  CONTRACT_REQUEST = "contract_request",
  CONTRACTS_FIELD = "contracts_field",
  CONTRACTS_FIELDS_VALUE = "contracts_fields_value",
  CONTRACTS_PRODUCT = "contracts_product",
  CONTRACTS_SERVICE = "contracts_service",
  CONTRACTS_SUBSCRIPTION = "contracts_subscription",
  COST = "cost",
  COUNTRY = "country",
  CREDIT_DEBIT_TRANSACTION = "credit_debit_transaction",
  CURRENCY = "currency",
  CUSTOM_FIELD = "custom_field",
  CUSTOM_FIELD_VALUE = "custom_field_value",
  DATA_RULE = "data_rule",
  DELEGATED_ACCESS = "delegated_access",
  DEPARTMENT = "department",
  DEPARTMENT_USER = "department_user",
  DOMAINS_PRODUCT = "domains_product",
  DYNAMIC_TRANSLATION = "dynamic_translation",
  EMAIL = "email",
  EMAIL_ATTACHMENT = "email_attachment",
  EMAIL_TICKET_PIPE_LOG = "email_ticket_pipe_log",
  EMAILS_DATA = "emails_data",
  EXCHANGE_RATE = "exchange_rate",
  EXPORTS_SOURCE = "exports_source",
  EXPORTS_SOURCES_ACCOUNT = "exports_sources_account",
  EXTERNAL_EXPORT = "external_export",
  EXTERNAL_IMPORT = "external_import",
  EXTERNAL_IMPORTS_RELATION = "external_imports_relation",
  FILE = "file",
  FILTER = "filter",
  FRAUD_CHECK_SETTING = "fraud_check_setting",
  FRAUD_EVENT = "fraud_event",
  FUNCTIONALITY = "functionality",
  FUNCTIONALITY_CATEGORY = "functionality_category",
  GATEWAY = "gateway",
  GATEWAY_PROVIDER = "gateway_provider",
  GATEWAY_PROVIDER_FIELD = "gateway_provider_field",
  GATEWAYS_BRAND = "gateways_brand",
  GATEWAYS_SETTING = "gateways_setting",
  GRANT_REQUEST = "grant_request",
  GROUP = "group",
  GROUPS_FUNCTIONALITY = "groups_functionality",
  GROUPS_HIDDEN_FIELD = "groups_hidden_field",
  HIDDEN_FIELD = "hidden_field",
  HOOK = "hook",
  HOOK_LOG = "hook_log",
  IMAGE = "image",
  IMAGE_CATEGORY = "image_category",
  IMAGE_CATEGORY_SIZE = "image_category_size",
  IMPORT = "import",
  IMPORT_ID_MAPPING = "import_id_mapping",
  IMPORT_LOG = "import_log",
  IMPORT_SOURCE = "import_source",
  IMPORT_SOURCE_CREDENTIALS = "import_source_credentials",
  IMPORT_SOURCE_DATA = "import_source_data",
  IMPORT_STEP = "import_step",
  INTERACTION = "interaction",
  INTERACTION_CATEGORY = "interaction_category",
  INTERACTION_CHANNEL = "interaction_channel",
  INTERACTION_THREAD = "interaction_thread",
  INVOICE = "invoice",
  INVOICE_DATA = "invoice_data",
  INVOICE_FILE = "invoice_file",
  INVOICE_NUMBER = "invoice_number",
  INVOICE_PAYMENT = "invoice_payment",
  INVOICE_PRODUCT = "invoice_product",
  INVOICE_PRODUCTS_PROMOTION = "invoice_products_promotion",
  INVOICE_PRODUCTS_VOUCHER = "invoice_products_voucher",
  INVOICE_PROMOTION = "invoice_promotion",
  INVOICE_VOUCHER = "invoice_voucher",
  INVOICES_CATEGORY = "invoices_category",
  INVOICES_SUBSCRIPTION = "invoices_subscription",
  LANGUAGE = "language",
  LEAD = "lead",
  LEAD_CLIENT_IMPORT = "lead_client_import",
  LOG_CALLBACK_LOG = "log_callback_log",
  LOG_EVENT = "log_event",
  LOGIN_ATTEMPTS = "login_attempts",
  LOGIN_PROVIDER = "login_provider",
  LOGO = "logo",
  MESSAGE = "message",
  NOTIFICATION_CHANNEL = "notification_channel",
  NOTIFICATION_CHANNEL_RECIPIENT_TYPE = "notification_channel_recipient_type",
  NOTIFICATION_RECIPIENT_TYPE = "notification_recipient_type",
  OAUTH_APPLICATION = "oauth_application",
  OAUTH_APPLICATION_ACCESS_TOKEN = "oauth_application_access_token",
  OAUTH_APPLICATION_AUTHORIZATION = "oauth_application_authorization",
  OAUTH_CLIENT = "oauth_client",
  OAUTH_REFRESH_TOKEN = "oauth_refresh_token",
  OAUTH_SESSION = "oauth_session",
  OBJECT_HOOK_NOTIFICATION_TEMPLATE = "object_hook_notification_template",
  OBJECT_NUMBER = "object_number",
  OBJECT_TAG = "object_tag",
  ORGANISATION = "organisation",
  PAYMENT_RECONCILIATION_LOG = "payment_reconciliation_log",
  PAYMENT_TYPE = "payment_type",
  PAYMENTS_EVENTS_LOG = "payments_events_log",
  PAYMENTS_LOG = "payments_log",
  PENDING_PAYMENT = "pending_payment",
  PREMIUM_DOMAIN = "premium_domain",
  PREMIUM_DOMAINS_CATEGORY = "premium_domains_category",
  PREMIUM_DOMAINS_PRICE = "premium_domains_price",
  PREMIUM_DOMAINS_PRODUCT = "premium_domains_product",
  PRICELIST = "pricelist",
  PRICELISTS_PRODUCT = "pricelists_product",
  PRIORITY = "priority",
  PRODUCT = "product",
  PRODUCT_CATEGORY_MAP = "product_category_map",
  PRODUCT_MIGRATION = "product_migration",
  PRODUCT_RELATED = "product_related",
  PRODUCTS_ATTRIBUTE = "products_attribute",
  PRODUCTS_CATEGORY = "products_category",
  PRODUCTS_GROUP = "products_group",
  PRODUCTS_GROUPS_PRODUCT = "products_groups_product",
  PRODUCTS_OPTION = "products_option",
  PRODUCTS_PROVISION_PROVIDER = "products_provision_provider",
  PRODUCTS_RELATED_PRODUCT = "products_related_product",
  PRODUCTS_SET_PRODUCT = "products_set_product",
  PRODUCTS_SUB_PRODUCT = "products_sub_product",
  PROMOTION = "promotion",
  PROMOTION_BILLING_CYCLES = "promotion_billing_cycles",
  PROMOTION_CURRENCIES = "promotion_currencies",
  PROMOTION_PRICELISTS = "promotion_pricelists",
  PROMOTIONS_CLIENT = "promotions_client",
  PROMOTIONS_MANDATORY_PRODUCT = "promotions_mandatory_product",
  PROMOTIONS_PRODUCT = "promotions_product",
  PROVISION_AFFILIATE_PRODUCTS_FIELDS_VALUE = "provision_affiliate_products_fields_value",
  PROVISION_BLUEPRINT = "provision_blueprint",
  PROVISION_BLUEPRINT_FIELD = "provision_blueprint_field",
  PROVISION_BLUEPRINT_FIELD_VALUE = "provision_blueprint_field_value",
  PROVISION_BLUEPRINT_FUNCTION = "provision_blueprint_function",
  PROVISION_BLUEPRINT_FUNCTION_PARAMETER = "provision_blueprint_function_parameter",
  PROVISION_BLUEPRINT_FUNCTION_TRIGGER = "provision_blueprint_function_trigger",
  PROVISION_BLUEPRINT_RESULT_ACTION = "provision_blueprint_result_action",
  PROVISION_BLUEPRINT_RESULT_ACTION_LOG = "provision_blueprint_result_action_log",
  PROVISION_CALLBACK_LOG = "provision_callback_log",
  PROVISION_CATEGORY = "provision_category",
  PROVISION_CATEGORY_ATTRIBUTE_ID = "provision_category_attribute_id",
  PROVISION_CENTER = "provision_center",
  PROVISION_CONFIGURATION = "provision_configuration",
  PROVISION_CONFIGURATION_ATTRIBUTE = "provision_configuration_attribute",
  PROVISION_CONFIGURATION_ATTRIBUTE_VALUE = "provision_configuration_attribute_value",
  PROVISION_CONFIGURATION_FIELD = "provision_configuration_field",
  PROVISION_DISPATCHER = "provision_dispatcher",
  PROVISION_FIELD = "provision_field",
  PROVISION_FIELDS_VALUE = "provision_fields_value",
  PROVISION_FUNCTION = "provision_function",
  PROVISION_FUNCTION_PARAMETER = "provision_function_parameter",
  PROVISION_FUNCTION_RETURN_DATA = "provision_function_return_data",
  PROVISION_FUNCTIONS_FIELD = "provision_functions_field",
  PROVISION_PRODUCTS_FIELDS_VALUE = "provision_products_fields_value",
  PROVISION_PROVIDER = "provision_provider",
  PROVISION_REQUEST = "provision_request",
  PROVISION_REQUEST_PARAMETER_VALUE = "provision_request_parameter_value",
  PROVISION_RESULT = "provision_result",
  PROVISION_SERVER = "provision_server",
  PROVISION_SERVERS_ORGANISATION = "provision_servers_organisation",
  PROVISION_SERVERS_PROVIDER = "provision_servers_provider",
  QUEUE_MESSAGE = "queue_message",
  RECURRING_INVOICE_LOG = "recurring_invoice_log",
  REGION = "region",
  RELATED_LEAD = "related_lead",
  ROUTE = "route",
  ROUTES_VISIBLE_FIELD = "routes_visible_field",
  SEED_TASK = "seed_task",
  SEGMENT = "segment",
  SETTING_CATEGORY = "setting_category",
  SETTING_FIELD = "setting_field",
  SETTING_GROUP = "setting_group",
  SETTING_VALUE = "setting_value",
  SHARED_RESOURCE_TOKEN = "shared_resource_token",
  SIMILAR_PRODUCT = "similar_product",
  STATUS = "status",
  SUPPORT_EMAIL = "support_email",
  TAG = "tag",
  TAX_BUSINESS_TYPE = "tax_business_type",
  TAX_TAG = "tax_tag",
  TAX_TAG_LOCATION = "tax_tag_location",
  TAX_TEMPLATE = "tax_template",
  TAX_TYPE = "tax_type",
  TEMPLATE = "template",
  TEMPLATES_CATEGORY = "templates_category",
  TEMPLATES_CONTENT = "templates_content",
  TEMPLATES_PARAM = "templates_param",
  TERMS_AND_CONDITIONS = "terms_and_conditions",
  TERMS_AND_CONDITIONS_ACCEPTANCE = "terms_and_conditions_acceptance",
  TERMS_AND_CONDITIONS_DATA = "terms_and_conditions_data",
  TICKET = "ticket",
  TICKET_DEPARTMENT = "ticket_department",
  TICKET_MESSAGE = "ticket_message",
  TICKET_USER = "ticket_user",
  TLD = "tld",
  TRACKED_REFERRAL = "tracked_referral",
  UNIT = "unit",
  UPMIND_PAY_ACCOUNT = "upmind_pay_account",
  UPMIND_PAY_MCC = "upmind_pay_mcc",
  USER = "user",
  USER_BRAND_GROUP = "user_brand_group",
  VALUE_CONFLICT_RESOLUTION_POLICY = "value_conflict_resolution_policy",
  VOUCHER = "voucher",
  VOUCHERS_PRODUCT = "vouchers_product",
  WALLET = "wallet",
  WALLET_REFUND = "wallet_refund",
  WALLET_REFUND_REQUEST = "wallet_refund_request"
}

export const UpmindObjectMap = () => ({
  [UpmindObjectTypes.ACCOUNT]: { key: "account", name: i18n.t("_.account") },
  [UpmindObjectTypes.ACCOUNT_TYPE]: {
    key: "account_type",
    name: i18n.t("_.account_type")
  },
  [UpmindObjectTypes.ACCOUNTS_STATUS]: {
    key: "accounts_status",
    name: i18n.t("_.accounts_status")
  },
  [UpmindObjectTypes.AFFILIATE]: {
    key: "affiliate",
    name: i18n.t("_.affiliate")
  },
  [UpmindObjectTypes.AFFILIATE_ACCOUNT]: {
    key: "affiliate_account",
    name: i18n.t("_.affiliate_account")
  },
  [UpmindObjectTypes.AFFILIATE_ACCOUNT_LINK]: {
    key: "affiliate_account_link",
    name: i18n.t("_.affiliate_account_link")
  },
  [UpmindObjectTypes.AFFILIATE_ACCOUNT_REFERRAL]: {
    key: "affiliate_account_referral",
    name: i18n.t("_.affiliate_account_referral")
  },
  [UpmindObjectTypes.AFFILIATE_COOKIE]: {
    key: "affiliate_cookie",
    name: i18n.t("_.affiliate_cookie")
  },
  [UpmindObjectTypes.AFFILIATE_PRODUCT]: {
    key: "affiliate_product",
    name: i18n.t("_.affiliate_product")
  },
  [UpmindObjectTypes.AFFILIATE_PRODUCTS_PRICE]: {
    key: "affiliate_products_price",
    name: i18n.t("_.affiliate_products_price")
  },
  [UpmindObjectTypes.AFFILIATE_PROVIDER]: {
    key: "affiliate_provider",
    name: i18n.t("_.affiliate_provider")
  },
  [UpmindObjectTypes.APPLICATION_LINK]: {
    key: "application_link",
    name: i18n.t("_.application_link")
  },
  [UpmindObjectTypes.BILLING_CYCLE]: {
    key: "billing_cycle",
    name: i18n.t("_.billing_cycle")
  },
  [UpmindObjectTypes.BRAND]: { key: "brand", name: i18n.t("_.brand") },
  [UpmindObjectTypes.BRAND_HOOK_TEMPLATE]: {
    key: "brand_hook_template",
    name: i18n.t("_.brand_hook_template")
  },
  [UpmindObjectTypes.BULK_ACTION]: {
    key: "bulk_action",
    name: i18n.t("_.bulk_action")
  },
  [UpmindObjectTypes.BULK_ACTION_OBJECTS]: {
    key: "bulk_action_objects",
    name: i18n.t("_.bulk_action_objects")
  },
  [UpmindObjectTypes.BULK_ACTION_RESULT]: {
    key: "bulk_action_result",
    name: i18n.t("_.bulk_action_result")
  },
  [UpmindObjectTypes.CARD_TYPES]: {
    key: "card_types",
    name: i18n.t("_.card_types")
  },
  [UpmindObjectTypes.CLIENT]: { key: "client", name: i18n.t("_.client") },
  [UpmindObjectTypes.CLIENT_NOTE]: {
    key: "client_note",
    name: i18n.t("_.vault.client_note")
  },
  [UpmindObjectTypes.CLIENTS_ACCOUNT]: {
    key: "clients_account",
    name: i18n.t("_.clients_account")
  },
  [UpmindObjectTypes.CLIENTS_ADDRESS]: {
    key: "clients_address",
    name: i18n.t("_.clients_address")
  },
  [UpmindObjectTypes.CLIENTS_COMPANY]: {
    key: "clients_company",
    name: i18n.t("_.clients_company")
  },
  [UpmindObjectTypes.CLIENTS_EMAIL]: {
    key: "clients_email",
    name: i18n.t("_.clients_email")
  },
  [UpmindObjectTypes.CLIENTS_IMPORTS]: {
    key: "clients_imports",
    name: i18n.t("_.clients_imports")
  },
  [UpmindObjectTypes.CLIENTS_PAYMENT_DETAIL]: {
    key: "clients_payment_detail",
    name: i18n.t("_.clients_payment_detail")
  },
  [UpmindObjectTypes.CLIENTS_PHONE]: {
    key: "clients_phone",
    name: i18n.t("_.clients_phone")
  },
  [UpmindObjectTypes.CONTRACT]: {
    key: "contract",
    name: i18n.t("_.contract")
  },
  [UpmindObjectTypes.CONTRACT_PRODUCT_DAYS_CORRECTION]: {
    key: "contract_product_days_correction",
    name: i18n.t("_.contract_product_days_correction")
  },
  [UpmindObjectTypes.CONTRACT_PRODUCTS_PROMOTION]: {
    key: "contract_products_promotion",
    name: i18n.t("_.contract_products_promotion")
  },
  [UpmindObjectTypes.CONTRACT_PROMOTION]: {
    key: "contract_promotion",
    name: i18n.t("_.contract_promotion")
  },
  [UpmindObjectTypes.CONTRACT_PROMOTION_DATA]: {
    key: "contract_promotion_data",
    name: i18n.t("_.contract_promotion_data")
  },
  [UpmindObjectTypes.CONTRACT_REQUEST]: {
    key: "contract_request",
    name: i18n.t("_.contract_request")
  },
  [UpmindObjectTypes.CONTRACTS_FIELD]: {
    key: "contracts_field",
    name: i18n.t("_.contracts_field")
  },
  [UpmindObjectTypes.CONTRACTS_FIELDS_VALUE]: {
    key: "contracts_fields_value",
    name: i18n.t("_.contracts_fields_value")
  },
  [UpmindObjectTypes.CONTRACTS_PRODUCT]: {
    key: "contracts_product",
    name: i18n.t("_.contracts_product")
  },
  [UpmindObjectTypes.CONTRACTS_SERVICE]: {
    key: "contracts_service",
    name: i18n.t("_.contracts_service")
  },
  [UpmindObjectTypes.CONTRACTS_SUBSCRIPTION]: {
    key: "contracts_subscription",
    name: i18n.t("_.contracts_subscription")
  },
  [UpmindObjectTypes.COST]: { key: "cost", name: i18n.t("_.cost") },
  [UpmindObjectTypes.COUNTRY]: { key: "country", name: i18n.t("_.country") },
  [UpmindObjectTypes.CREDIT_DEBIT_TRANSACTION]: {
    key: "credit_debit_transaction",
    name: i18n.t("_.credit_debit_transaction")
  },
  [UpmindObjectTypes.CURRENCY]: {
    key: "currency",
    name: i18n.t("_.currency")
  },
  [UpmindObjectTypes.CUSTOM_FIELD]: {
    key: "custom_field",
    name: i18n.t("_.custom_field")
  },
  [UpmindObjectTypes.CUSTOM_FIELD_VALUE]: {
    key: "custom_field_value",
    name: i18n.t("_.custom_field_value")
  },
  [UpmindObjectTypes.DATA_RULE]: {
    key: "data_rule",
    name: i18n.t("_.data_rule")
  },
  [UpmindObjectTypes.DELEGATED_ACCESS]: {
    key: "delegated_access",
    name: i18n.t("_.delegated_access")
  },
  [UpmindObjectTypes.DEPARTMENT]: {
    key: "department",
    name: i18n.t("_.department")
  },
  [UpmindObjectTypes.DEPARTMENT_USER]: {
    key: "department_user",
    name: i18n.t("_.department_user")
  },
  [UpmindObjectTypes.DOMAINS_PRODUCT]: {
    key: "domains_product",
    name: i18n.t("_.domains_product")
  },
  [UpmindObjectTypes.DYNAMIC_TRANSLATION]: {
    key: "dynamic_translation",
    name: i18n.t("_.dynamic_translation")
  },
  [UpmindObjectTypes.EMAIL]: { key: "email", name: i18n.t("_.email") },
  [UpmindObjectTypes.EMAIL_ATTACHMENT]: {
    key: "email_attachment",
    name: i18n.t("_.email_attachment")
  },
  [UpmindObjectTypes.EMAIL_TICKET_PIPE_LOG]: {
    key: "email_ticket_pipe_log",
    name: i18n.t("_.email_ticket_pipe_log")
  },
  [UpmindObjectTypes.EMAILS_DATA]: {
    key: "emails_data",
    name: i18n.t("_.emails_data")
  },
  [UpmindObjectTypes.EXCHANGE_RATE]: {
    key: "exchange_rate",
    name: i18n.t("_.exchange_rate")
  },
  [UpmindObjectTypes.EXPORTS_SOURCE]: {
    key: "exports_source",
    name: i18n.t("_.exports_source")
  },
  [UpmindObjectTypes.EXPORTS_SOURCES_ACCOUNT]: {
    key: "exports_sources_account",
    name: i18n.t("_.exports_sources_account")
  },
  [UpmindObjectTypes.EXTERNAL_EXPORT]: {
    key: "external_export",
    name: i18n.t("_.external_export")
  },
  [UpmindObjectTypes.EXTERNAL_IMPORT]: {
    key: "external_import",
    name: i18n.t("_.external_import")
  },
  [UpmindObjectTypes.EXTERNAL_IMPORTS_RELATION]: {
    key: "external_imports_relation",
    name: i18n.t("_.external_imports_relation")
  },
  [UpmindObjectTypes.FILE]: { key: "file", name: i18n.t("_.file") },
  [UpmindObjectTypes.FILTER]: { key: "filter", name: i18n.t("_.filter") },
  [UpmindObjectTypes.FRAUD_CHECK_SETTING]: {
    key: "fraud_check_setting",
    name: i18n.t("_.fraud_check_setting")
  },
  [UpmindObjectTypes.FRAUD_EVENT]: {
    key: "fraud_event",
    name: i18n.t("_.fraud_event")
  },
  [UpmindObjectTypes.FUNCTIONALITY]: {
    key: "functionality",
    name: i18n.t("_.functionality")
  },
  [UpmindObjectTypes.FUNCTIONALITY_CATEGORY]: {
    key: "functionality_category",
    name: i18n.t("_.functionality_category")
  },
  [UpmindObjectTypes.GATEWAY]: { key: "gateway", name: i18n.t("_.gateway") },
  [UpmindObjectTypes.GATEWAY_PROVIDER]: {
    key: "gateway_provider",
    name: i18n.t("_.gateway_provider")
  },
  [UpmindObjectTypes.GATEWAY_PROVIDER_FIELD]: {
    key: "gateway_provider_field",
    name: i18n.t("_.gateway_provider_field")
  },
  [UpmindObjectTypes.GATEWAYS_BRAND]: {
    key: "gateways_brand",
    name: i18n.t("_.gateways_brand")
  },
  [UpmindObjectTypes.GATEWAYS_SETTING]: {
    key: "gateways_setting",
    name: i18n.t("_.gateways_setting")
  },
  [UpmindObjectTypes.GRANT_REQUEST]: {
    key: "grant_request",
    name: i18n.t("_.grant_request")
  },
  [UpmindObjectTypes.GROUP]: { key: "group", name: i18n.t("_.group") },
  [UpmindObjectTypes.GROUPS_FUNCTIONALITY]: {
    key: "groups_functionality",
    name: i18n.t("_.groups_functionality")
  },
  [UpmindObjectTypes.GROUPS_HIDDEN_FIELD]: {
    key: "groups_hidden_field",
    name: i18n.t("_.groups_hidden_field")
  },
  [UpmindObjectTypes.HIDDEN_FIELD]: {
    key: "hidden_field",
    name: i18n.t("_.hidden_field")
  },
  [UpmindObjectTypes.HOOK]: { key: "hook", name: i18n.t("_.hook") },
  [UpmindObjectTypes.HOOK_LOG]: {
    key: "hook_log",
    name: i18n.t("_.hook_log")
  },
  [UpmindObjectTypes.IMAGE]: { key: "image", name: i18n.t("_.image") },
  [UpmindObjectTypes.IMAGE_CATEGORY]: {
    key: "image_category",
    name: i18n.t("_.image_category")
  },
  [UpmindObjectTypes.IMAGE_CATEGORY_SIZE]: {
    key: "image_category_size",
    name: i18n.t("_.image_category_size")
  },
  [UpmindObjectTypes.IMPORT]: { key: "import", name: i18n.t("_.import") },
  [UpmindObjectTypes.IMPORT_ID_MAPPING]: {
    key: "import_id_mapping",
    name: i18n.t("_.import_id_mapping")
  },
  [UpmindObjectTypes.IMPORT_LOG]: {
    key: "import_log",
    name: i18n.t("_.import_log")
  },
  [UpmindObjectTypes.IMPORT_SOURCE]: {
    key: "import_source",
    name: i18n.t("_.import_source")
  },
  [UpmindObjectTypes.IMPORT_SOURCE_CREDENTIALS]: {
    key: "import_source_credentials",
    name: i18n.t("_.import_source_credentials")
  },
  [UpmindObjectTypes.IMPORT_SOURCE_DATA]: {
    key: "import_source_data",
    name: i18n.t("_.import_source_data")
  },
  [UpmindObjectTypes.IMPORT_STEP]: {
    key: "import_step",
    name: i18n.t("_.import_step")
  },
  [UpmindObjectTypes.INTERACTION]: {
    key: "interaction",
    name: i18n.t("_.interaction")
  },
  [UpmindObjectTypes.INTERACTION_CATEGORY]: {
    key: "interaction_category",
    name: i18n.t("_.interaction_category")
  },
  [UpmindObjectTypes.INTERACTION_CHANNEL]: {
    key: "interaction_channel",
    name: i18n.t("_.interaction_channel")
  },
  [UpmindObjectTypes.INTERACTION_THREAD]: {
    key: "interaction_thread",
    name: i18n.t("_.interaction_thread")
  },
  [UpmindObjectTypes.INVOICE]: { key: "invoice", name: i18n.t("_.invoice") },
  [UpmindObjectTypes.INVOICE_DATA]: {
    key: "invoice_data",
    name: i18n.t("_.invoice_data")
  },
  [UpmindObjectTypes.INVOICE_FILE]: {
    key: "invoice_file",
    name: i18n.t("_.invoice_file")
  },
  [UpmindObjectTypes.INVOICE_NUMBER]: {
    key: "invoice_number",
    name: i18n.t("_.invoice_number")
  },
  [UpmindObjectTypes.INVOICE_PAYMENT]: {
    key: "invoice_payment",
    name: i18n.t("_.invoice_payment")
  },
  [UpmindObjectTypes.INVOICE_PRODUCT]: {
    key: "invoice_product",
    name: i18n.t("_.invoice_product")
  },
  [UpmindObjectTypes.INVOICE_PRODUCTS_PROMOTION]: {
    key: "invoice_products_promotion",
    name: i18n.t("_.invoice_products_promotion")
  },
  [UpmindObjectTypes.INVOICE_PRODUCTS_VOUCHER]: {
    key: "invoice_products_voucher",
    name: i18n.t("_.invoice_products_voucher")
  },
  [UpmindObjectTypes.INVOICE_PROMOTION]: {
    key: "invoice_promotion",
    name: i18n.t("_.invoice_promotion")
  },
  [UpmindObjectTypes.INVOICE_VOUCHER]: {
    key: "invoice_voucher",
    name: i18n.t("_.invoice_voucher")
  },
  [UpmindObjectTypes.INVOICES_CATEGORY]: {
    key: "invoices_category",
    name: i18n.t("_.invoices_category")
  },
  [UpmindObjectTypes.INVOICES_SUBSCRIPTION]: {
    key: "invoices_subscription",
    name: i18n.t("_.invoices_subscription")
  },
  [UpmindObjectTypes.LANGUAGE]: {
    key: "language",
    name: i18n.t("_.language")
  },
  [UpmindObjectTypes.LEAD]: { key: "lead", name: i18n.t("_.lead") },
  [UpmindObjectTypes.LEAD_CLIENT_IMPORT]: {
    key: "lead_client_import",
    name: i18n.t("_.lead_client_import")
  },
  [UpmindObjectTypes.LOG_CALLBACK_LOG]: {
    key: "log_callback_log",
    name: i18n.t("_.log_callback_log")
  },
  [UpmindObjectTypes.LOG_EVENT]: {
    key: "log_event",
    name: i18n.t("_.log_event")
  },
  [UpmindObjectTypes.LOGIN_ATTEMPTS]: {
    key: "login_attempts",
    name: i18n.t("_.login_attempts")
  },
  [UpmindObjectTypes.LOGIN_PROVIDER]: {
    key: "login_provider",
    name: i18n.t("_.login_provider")
  },
  [UpmindObjectTypes.LOGO]: { key: "logo", name: i18n.t("_.logo") },
  [UpmindObjectTypes.MESSAGE]: { key: "message", name: i18n.t("_.message") },
  [UpmindObjectTypes.NOTIFICATION_CHANNEL]: {
    key: "notification_channel",
    name: i18n.t("_.notification_channel")
  },
  [UpmindObjectTypes.NOTIFICATION_CHANNEL_RECIPIENT_TYPE]: {
    key: "notification_channel_recipient_type",
    name: i18n.t("_.notification_channel_recipient_type")
  },
  [UpmindObjectTypes.NOTIFICATION_RECIPIENT_TYPE]: {
    key: "notification_recipient_type",
    name: i18n.t("_.notification_recipient_type")
  },
  [UpmindObjectTypes.OAUTH_APPLICATION]: {
    key: "oauth_application",
    name: i18n.t("_.oauth_application")
  },
  [UpmindObjectTypes.OAUTH_APPLICATION_ACCESS_TOKEN]: {
    key: "oauth_application_access_token",
    name: i18n.t("_.oauth_application_access_token")
  },
  [UpmindObjectTypes.OAUTH_APPLICATION_AUTHORIZATION]: {
    key: "oauth_application_authorization",
    name: i18n.t("_.oauth_application_authorization")
  },
  [UpmindObjectTypes.OAUTH_CLIENT]: {
    key: "oauth_client",
    name: i18n.t("_.oauth_client")
  },
  [UpmindObjectTypes.OAUTH_REFRESH_TOKEN]: {
    key: "oauth_refresh_token",
    name: i18n.t("_.oauth_refresh_token")
  },
  [UpmindObjectTypes.OAUTH_SESSION]: {
    key: "oauth_session",
    name: i18n.t("_.oauth_session")
  },
  [UpmindObjectTypes.OBJECT_HOOK_NOTIFICATION_TEMPLATE]: {
    key: "object_hook_notification_template",
    name: i18n.t("_.object_hook_notification_template")
  },
  [UpmindObjectTypes.OBJECT_NUMBER]: {
    key: "object_number",
    name: i18n.t("_.object_number")
  },
  [UpmindObjectTypes.OBJECT_TAG]: {
    key: "object_tag",
    name: i18n.t("_.object_tag")
  },
  [UpmindObjectTypes.ORGANISATION]: {
    key: "organisation",
    name: i18n.t("_.organisation")
  },
  [UpmindObjectTypes.PAYMENT_RECONCILIATION_LOG]: {
    key: "payment_reconciliation_log",
    name: i18n.t("_.payment_reconciliation_log")
  },
  [UpmindObjectTypes.PAYMENT_TYPE]: {
    key: "payment_type",
    name: i18n.t("_.payment_type")
  },
  [UpmindObjectTypes.PAYMENTS_EVENTS_LOG]: {
    key: "payments_events_log",
    name: i18n.t("_.payments_events_log")
  },
  [UpmindObjectTypes.PAYMENTS_LOG]: {
    key: "payments_log",
    name: i18n.t("_.payments_log")
  },
  [UpmindObjectTypes.PENDING_PAYMENT]: {
    key: "pending_payment",
    name: i18n.t("_.pending_payment")
  },
  [UpmindObjectTypes.PREMIUM_DOMAIN]: {
    key: "premium_domain",
    name: i18n.t("_.premium_domain")
  },
  [UpmindObjectTypes.PREMIUM_DOMAINS_CATEGORY]: {
    key: "premium_domains_category",
    name: i18n.t("_.premium_domains_category")
  },
  [UpmindObjectTypes.PREMIUM_DOMAINS_PRICE]: {
    key: "premium_domains_price",
    name: i18n.t("_.premium_domains_price")
  },
  [UpmindObjectTypes.PREMIUM_DOMAINS_PRODUCT]: {
    key: "premium_domains_product",
    name: i18n.t("_.premium_domains_product")
  },
  [UpmindObjectTypes.PRICELIST]: {
    key: "pricelist",
    name: i18n.t("_.pricelist")
  },
  [UpmindObjectTypes.PRICELISTS_PRODUCT]: {
    key: "pricelists_product",
    name: i18n.t("_.pricelists_product")
  },
  [UpmindObjectTypes.PRIORITY]: {
    key: "priority",
    name: i18n.t("_.priority")
  },
  [UpmindObjectTypes.PRODUCT]: { key: "product", name: i18n.t("_.product") },
  [UpmindObjectTypes.PRODUCT_CATEGORY_MAP]: {
    key: "product_category_map",
    name: i18n.t("_.product_category_map")
  },
  [UpmindObjectTypes.PRODUCT_MIGRATION]: {
    key: "product_migration",
    name: i18n.t("_.product_migration")
  },
  [UpmindObjectTypes.PRODUCT_RELATED]: {
    key: "product_related",
    name: i18n.t("_.product_related")
  },
  [UpmindObjectTypes.PRODUCTS_ATTRIBUTE]: {
    key: "products_attribute",
    name: i18n.t("_.products_attribute")
  },
  [UpmindObjectTypes.PRODUCTS_CATEGORY]: {
    key: "products_category",
    name: i18n.t("_.products_category")
  },
  [UpmindObjectTypes.PRODUCTS_GROUP]: {
    key: "products_group",
    name: i18n.t("_.products_group")
  },
  [UpmindObjectTypes.PRODUCTS_GROUPS_PRODUCT]: {
    key: "products_groups_product",
    name: i18n.t("_.products_groups_product")
  },
  [UpmindObjectTypes.PRODUCTS_OPTION]: {
    key: "products_option",
    name: i18n.t("_.products_option")
  },
  [UpmindObjectTypes.PRODUCTS_PROVISION_PROVIDER]: {
    key: "products_provision_provider",
    name: i18n.t("_.products_provision_provider")
  },
  [UpmindObjectTypes.PRODUCTS_RELATED_PRODUCT]: {
    key: "products_related_product",
    name: i18n.t("_.products_related_product")
  },
  [UpmindObjectTypes.PRODUCTS_SET_PRODUCT]: {
    key: "products_set_product",
    name: i18n.t("_.products_set_product")
  },
  [UpmindObjectTypes.PRODUCTS_SUB_PRODUCT]: {
    key: "products_sub_product",
    name: i18n.t("_.products_sub_product")
  },
  [UpmindObjectTypes.PROMOTION]: {
    key: "promotion",
    name: i18n.t("_.promotion")
  },
  [UpmindObjectTypes.PROMOTION_BILLING_CYCLES]: {
    key: "promotion_billing_cycles",
    name: i18n.t("_.promotion_billing_cycles")
  },
  [UpmindObjectTypes.PROMOTION_CURRENCIES]: {
    key: "promotion_currencies",
    name: i18n.t("_.promotion_currencies")
  },
  [UpmindObjectTypes.PROMOTION_PRICELISTS]: {
    key: "promotion_pricelists",
    name: i18n.t("_.promotion_pricelists")
  },
  [UpmindObjectTypes.PROMOTIONS_CLIENT]: {
    key: "promotions_client",
    name: i18n.t("_.promotions_client")
  },
  [UpmindObjectTypes.PROMOTIONS_MANDATORY_PRODUCT]: {
    key: "promotions_mandatory_product",
    name: i18n.t("_.promotions_mandatory_product")
  },
  [UpmindObjectTypes.PROMOTIONS_PRODUCT]: {
    key: "promotions_product",
    name: i18n.t("_.promotions_product")
  },
  [UpmindObjectTypes.PROVISION_AFFILIATE_PRODUCTS_FIELDS_VALUE]: {
    key: "provision_affiliate_products_fields_value",
    name: i18n.t("_.provision_affiliate_products_fields_value")
  },
  [UpmindObjectTypes.PROVISION_BLUEPRINT]: {
    key: "provision_blueprint",
    name: i18n.t("_.provision_blueprint")
  },
  [UpmindObjectTypes.PROVISION_BLUEPRINT_FIELD]: {
    key: "provision_blueprint_field",
    name: i18n.t("_.provision_blueprint_field")
  },
  [UpmindObjectTypes.PROVISION_BLUEPRINT_FIELD_VALUE]: {
    key: "provision_blueprint_field_value",
    name: i18n.t("_.provision_blueprint_field_value")
  },
  [UpmindObjectTypes.PROVISION_BLUEPRINT_FUNCTION]: {
    key: "provision_blueprint_function",
    name: i18n.t("_.provision_blueprint_function")
  },
  [UpmindObjectTypes.PROVISION_BLUEPRINT_FUNCTION_PARAMETER]: {
    key: "provision_blueprint_function_parameter",
    name: i18n.t("_.provision_blueprint_function_parameter")
  },
  [UpmindObjectTypes.PROVISION_BLUEPRINT_FUNCTION_TRIGGER]: {
    key: "provision_blueprint_function_trigger",
    name: i18n.t("_.provision_blueprint_function_trigger")
  },
  [UpmindObjectTypes.PROVISION_BLUEPRINT_RESULT_ACTION]: {
    key: "provision_blueprint_result_action",
    name: i18n.t("_.provision_blueprint_result_action")
  },
  [UpmindObjectTypes.PROVISION_BLUEPRINT_RESULT_ACTION_LOG]: {
    key: "provision_blueprint_result_action_log",
    name: i18n.t("_.provision_blueprint_result_action_log")
  },
  [UpmindObjectTypes.PROVISION_CALLBACK_LOG]: {
    key: "provision_callback_log",
    name: i18n.t("_.provision_callback_log")
  },
  [UpmindObjectTypes.PROVISION_CATEGORY]: {
    key: "provision_category",
    name: i18n.t("_.provision_category")
  },
  [UpmindObjectTypes.PROVISION_CATEGORY_ATTRIBUTE_ID]: {
    key: "provision_category_attribute_id",
    name: i18n.t("_.provision_category_attribute_id")
  },
  [UpmindObjectTypes.PROVISION_CENTER]: {
    key: "provision_center",
    name: i18n.t("_.provision_center")
  },
  [UpmindObjectTypes.PROVISION_CONFIGURATION]: {
    key: "provision_configuration",
    name: i18n.t("_.provision_configuration")
  },
  [UpmindObjectTypes.PROVISION_CONFIGURATION_ATTRIBUTE]: {
    key: "provision_configuration_attribute",
    name: i18n.t("_.provision_configuration_attribute")
  },
  [UpmindObjectTypes.PROVISION_CONFIGURATION_ATTRIBUTE_VALUE]: {
    key: "provision_configuration_attribute_value",
    name: i18n.t("_.provision_configuration_attribute_value")
  },
  [UpmindObjectTypes.PROVISION_CONFIGURATION_FIELD]: {
    key: "provision_configuration_field",
    name: i18n.t("_.provision_configuration_field")
  },
  [UpmindObjectTypes.PROVISION_DISPATCHER]: {
    key: "provision_dispatcher",
    name: i18n.t("_.provision_dispatcher")
  },
  [UpmindObjectTypes.PROVISION_FIELD]: {
    key: "provision_field",
    name: i18n.t("_.provision_field")
  },
  [UpmindObjectTypes.PROVISION_FIELDS_VALUE]: {
    key: "provision_fields_value",
    name: i18n.t("_.provision_fields_value")
  },
  [UpmindObjectTypes.PROVISION_FUNCTION]: {
    key: "provision_function",
    name: i18n.t("_.provision_function")
  },
  [UpmindObjectTypes.PROVISION_FUNCTION_PARAMETER]: {
    key: "provision_function_parameter",
    name: i18n.t("_.provision_function_parameter")
  },
  [UpmindObjectTypes.PROVISION_FUNCTION_RETURN_DATA]: {
    key: "provision_function_return_data",
    name: i18n.t("_.provision_function_return_data")
  },
  [UpmindObjectTypes.PROVISION_FUNCTIONS_FIELD]: {
    key: "provision_functions_field",
    name: i18n.t("_.provision_functions_field")
  },
  [UpmindObjectTypes.PROVISION_PRODUCTS_FIELDS_VALUE]: {
    key: "provision_products_fields_value",
    name: i18n.t("_.provision_products_fields_value")
  },
  [UpmindObjectTypes.PROVISION_PROVIDER]: {
    key: "provision_provider",
    name: i18n.t("_.provision_provider")
  },
  [UpmindObjectTypes.PROVISION_REQUEST]: {
    key: "provision_request",
    name: i18n.t("_.provision_request")
  },
  [UpmindObjectTypes.PROVISION_REQUEST_PARAMETER_VALUE]: {
    key: "provision_request_parameter_value",
    name: i18n.t("_.provision_request_parameter_value")
  },
  [UpmindObjectTypes.PROVISION_RESULT]: {
    key: "provision_result",
    name: i18n.t("_.provision_result")
  },
  [UpmindObjectTypes.PROVISION_SERVER]: {
    key: "provision_server",
    name: i18n.t("_.provision_server")
  },
  [UpmindObjectTypes.PROVISION_SERVERS_ORGANISATION]: {
    key: "provision_servers_organisation",
    name: i18n.t("_.provision_servers_organisation")
  },
  [UpmindObjectTypes.PROVISION_SERVERS_PROVIDER]: {
    key: "provision_servers_provider",
    name: i18n.t("_.provision_servers_provider")
  },
  [UpmindObjectTypes.QUEUE_MESSAGE]: {
    key: "queue_message",
    name: i18n.t("_.queue_message")
  },
  [UpmindObjectTypes.RECURRING_INVOICE_LOG]: {
    key: "recurring_invoice_log",
    name: i18n.t("_.recurring_invoice_log")
  },
  [UpmindObjectTypes.REGION]: { key: "region", name: i18n.t("_.region") },
  [UpmindObjectTypes.RELATED_LEAD]: {
    key: "related_lead",
    name: i18n.t("_.related_lead")
  },
  [UpmindObjectTypes.ROUTE]: { key: "route", name: i18n.t("_.route") },
  [UpmindObjectTypes.ROUTES_VISIBLE_FIELD]: {
    key: "routes_visible_field",
    name: i18n.t("_.routes_visible_field")
  },
  [UpmindObjectTypes.SEED_TASK]: {
    key: "seed_task",
    name: i18n.t("_.seed_task")
  },
  [UpmindObjectTypes.SEGMENT]: { key: "segment", name: i18n.t("_.segment") },
  [UpmindObjectTypes.SETTING_CATEGORY]: {
    key: "setting_category",
    name: i18n.t("_.setting_category")
  },
  [UpmindObjectTypes.SETTING_FIELD]: {
    key: "setting_field",
    name: i18n.t("_.setting_field")
  },
  [UpmindObjectTypes.SETTING_GROUP]: {
    key: "setting_group",
    name: i18n.t("_.setting_group")
  },
  [UpmindObjectTypes.SETTING_VALUE]: {
    key: "setting_value",
    name: i18n.t("_.setting_value")
  },
  [UpmindObjectTypes.SHARED_RESOURCE_TOKEN]: {
    key: "shared_resource_token",
    name: i18n.t("_.shared_resource_token")
  },
  [UpmindObjectTypes.SIMILAR_PRODUCT]: {
    key: "similar_product",
    name: i18n.t("_.similar_product")
  },
  [UpmindObjectTypes.STATUS]: { key: "status", name: i18n.t("_.status") },
  [UpmindObjectTypes.SUPPORT_EMAIL]: {
    key: "support_email",
    name: i18n.t("_.support_email")
  },
  [UpmindObjectTypes.TAG]: { key: "tag", name: i18n.t("_.tag") },
  [UpmindObjectTypes.TAX_BUSINESS_TYPE]: {
    key: "tax_business_type",
    name: i18n.t("_.tax_business_type")
  },
  [UpmindObjectTypes.TAX_TAG]: { key: "tax_tag", name: i18n.t("_.tax_tag") },
  [UpmindObjectTypes.TAX_TAG_LOCATION]: {
    key: "tax_tag_location",
    name: i18n.t("_.tax_tag_location")
  },
  [UpmindObjectTypes.TAX_TEMPLATE]: {
    key: "tax_template",
    name: i18n.t("_.tax_template")
  },
  [UpmindObjectTypes.TAX_TYPE]: {
    key: "tax_type",
    name: i18n.t("_.tax_type")
  },
  [UpmindObjectTypes.TEMPLATE]: {
    key: "template",
    name: i18n.t("_.template")
  },
  [UpmindObjectTypes.TEMPLATES_CATEGORY]: {
    key: "templates_category",
    name: i18n.t("_.templates_category")
  },
  [UpmindObjectTypes.TEMPLATES_CONTENT]: {
    key: "templates_content",
    name: i18n.t("_.templates_content")
  },
  [UpmindObjectTypes.TEMPLATES_PARAM]: {
    key: "templates_param",
    name: i18n.t("_.templates_param")
  },
  [UpmindObjectTypes.TERMS_AND_CONDITIONS]: {
    key: "terms_and_conditions",
    name: i18n.t("_.terms_and_conditions")
  },
  [UpmindObjectTypes.TERMS_AND_CONDITIONS_ACCEPTANCE]: {
    key: "terms_and_conditions_acceptance",
    name: i18n.t("_.terms_and_conditions_acceptance")
  },
  [UpmindObjectTypes.TERMS_AND_CONDITIONS_DATA]: {
    key: "terms_and_conditions_data",
    name: i18n.t("_.terms_and_conditions_data")
  },
  [UpmindObjectTypes.TICKET]: { key: "ticket", name: i18n.t("_.ticket") },
  [UpmindObjectTypes.TICKET_DEPARTMENT]: {
    key: "ticket_department",
    name: i18n.t("_.ticket_department")
  },
  [UpmindObjectTypes.TICKET_MESSAGE]: {
    key: "ticket_message",
    name: i18n.t("_.ticket_message")
  },
  [UpmindObjectTypes.TICKET_USER]: {
    key: "ticket_user",
    name: i18n.t("_.ticket_user")
  },
  [UpmindObjectTypes.TLD]: { key: "tld", name: i18n.t("_.tld") },
  [UpmindObjectTypes.TRACKED_REFERRAL]: {
    key: "tracked_referral",
    name: i18n.t("_.tracked_referral")
  },
  [UpmindObjectTypes.UNIT]: { key: "unit", name: i18n.t("_.unit") },
  [UpmindObjectTypes.UPMIND_PAY_ACCOUNT]: {
    key: "upmind_pay_account",
    name: i18n.t("_.upmind_pay_account")
  },
  [UpmindObjectTypes.UPMIND_PAY_MCC]: {
    key: "upmind_pay_mcc",
    name: i18n.t("_.upmind_pay_mcc")
  },
  [UpmindObjectTypes.USER]: { key: "user", name: i18n.t("_.user") },
  [UpmindObjectTypes.USER_BRAND_GROUP]: {
    key: "user_brand_group",
    name: i18n.t("_.user_brand_group")
  },
  [UpmindObjectTypes.VALUE_CONFLICT_RESOLUTION_POLICY]: {
    key: "value_conflict_resolution_policy",
    name: i18n.t("_.value_conflict_resolution_policy")
  },
  [UpmindObjectTypes.VOUCHER]: { key: "voucher", name: i18n.t("_.voucher") },
  [UpmindObjectTypes.VOUCHERS_PRODUCT]: {
    key: "vouchers_product",
    name: i18n.t("_.vouchers_product")
  },
  [UpmindObjectTypes.WALLET]: { key: "wallet", name: i18n.t("_.wallet") },
  [UpmindObjectTypes.WALLET_REFUND]: {
    key: "wallet_refund",
    name: i18n.t("_.wallet_refund")
  },
  [UpmindObjectTypes.WALLET_REFUND_REQUEST]: {
    key: "wallet_refund_request",
    name: i18n.t("_.wallet_refund_request")
  }
});

export const UpmindObjectToDataModuleMapping = {
  [UpmindObjectTypes.INVOICE]: DataModules.INVOICES,
  [UpmindObjectTypes.CLIENT]: DataModules.CLIENTS,
  [UpmindObjectTypes.USER]: DataModules.USERS,
  [UpmindObjectTypes.CONTRACTS_PRODUCT]: DataModules.CONTRACTS_PRODUCTS
};
