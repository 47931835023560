import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import { Methods } from "@/models/methods";
import type { ApiPathGetter } from "@/models/api";
import type { IProvisionRequest } from "@/models/provisioning";
import { ProvisionRequestStatusCodes as PRSC } from "@/data/enums/contractProducts";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath: (): ApiPathGetter => () => {
    const admin = `api/admin/provision/requests`;
    return { admin };
  },
  singleScope: () => requestId => {
    return `$provision_request_${requestId}`;
  },
  payloadScope: () => requestId => {
    return `$provision_request_${requestId}_payload`;
  },
  requestFieldsScope: () => requestId => {
    return `$provision_request_${requestId}_fields`;
  },
  canCancel:
    (_state, _getters, _rootState, rootGetter) =>
    (request: IProvisionRequest) => {
      const statuses = [
        PRSC.PROVISION_REQUEST_PENDING,
        PRSC.PROVISION_REQUEST_DRAFT,
        PRSC.PROVISION_REQUEST_SCHEDULED,
        PRSC.PROVISION_REQUEST_DISPATCHED
      ];
      return (
        request.status &&
        statuses.includes(request.status.code) &&
        !request.staged_import &&
        rootGetter["user/can"]("cancel_provision_request")
      );
    },
  canResolve:
    (_state, _getters, _rootState, rootGetter) =>
    (request: IProvisionRequest) => {
      return (
        request.unresolved && rootGetter["user/can"]("update_provision_request")
      );
    },
  canRetry:
    (_state, _getters, _rootState, rootGetter) =>
    (request: IProvisionRequest) => {
      return (
        request.can_retry &&
        !request.staged_import &&
        rootGetter["user/can"]("retry_provision_request")
      );
    }
};

const actions: ActionTree<IDataState, IState> = {
  get: ({ dispatch, getters }, { requestId, payload } = { payload: {} }) => {
    // Set default params
    payload.params = {
      with_staged_imports: 1,
      with: [
        "action_logs.action",
        "actor",
        "blueprint_function",
        "client",
        "configuration",
        "hook_log",
        "hook_log.hook",
        "order_product",
        "results",
        "scheduler_cron",
        "status"
      ].join(),
      ...payload.params
    };
    return dispatch(
      "data/get",
      {
        ...payload,
        method: Methods.GET,
        path: `${getters.apiPath().admin}/${requestId}`,
        storeModule: DataModules.PROVISIONING_REQUESTS
      },
      { root: true }
    );
  },
  getPayload: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        method: Methods.GET,
        path: `${getters.apiPath().admin}/${payload.id}/payload`,
        params: { with_staged_imports: 1 },
        storeModule: DataModules.PROVISIONING_REQUESTS
      },
      { root: true }
    );
  },
  listRequestFields: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload.id}/fields`,
        params: { with_staged_imports: 1, limit: 0 },
        storeModule: DataModules.PROVISIONING_REQUESTS
      },
      { root: true }
    );
  },
  openRequestModal: ({ dispatch }, payload) =>
    dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/admin/provisioning/provisionRequestModal.vue"
            ),
          ...payload
        }
      },
      { root: true }
    ),
  openRetryRequestModal: async ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import("@/components/app/admin/provisioning/retryRequestModal.vue"),
          ...payload
        }
      },
      { root: true }
    );
  },

  openViewFieldsValuesModal: async ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/admin/provisioning/viewFieldsValuesModal.vue"
            ),
          ...payload
        }
      },
      { root: true }
    );
  },

  retryRequest: ({ dispatch, getters }, { id, data }) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.POST,
        path: `${getters.apiPath().admin}/${id}/retry`,
        requestConfig: {
          data: {
            ...data,
            dispatch_now: true
          }
        },
        storeModule: DataModules.PROVISIONING_REQUESTS
      },
      { root: true }
    );
  },

  retryProvisionRequest: async ({ dispatch }, { id, onSuccess, onFinish }) => {
    try {
      const { data } = await dispatch("listRequestFields", {
        id,
        storeData: false,
        returnData: false,
        ignoreStored: false
      });

      if (data.length) {
        dispatch("openRetryRequestModal", {
          props: { requestId: id, requestFields: data },
          onCancel: onFinish,
          events: { success: onSuccess, close: onFinish }
        });
      } else {
        const retryResult = await dispatch("retryRequest", { id });
        onSuccess(retryResult);
        onFinish();
      }
    } catch (error) {
      dispatch("api/handleError", error, {
        root: true
      });
      onFinish();
    }
  },

  resolveRequest: ({ dispatch, getters }, { id }) => {
    return dispatch(
      "data/update",
      {
        path: `${getters.apiPath().admin}/${id}`,
        data: {
          unresolved: false
        },
        storeModule: DataModules.PROVISIONING_REQUESTS
      },
      { root: true }
    );
  },

  openPayloadModal: async ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/admin/provisioning/provisionRequestPayloadModal.vue"
            ),
          ...payload
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
