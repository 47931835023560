import _ from "@/boot/lodash";
import store from "@/store";
import { BrandConfigKeys } from "@/data/constants";
import { DataModules } from "@/store/modules/data/modules";
import type { Route } from "vue-router";
import { AdminRoutes, RegexMatch } from "@/data/enums/router";
import type { IClient } from "@upmind-automation/types";

export const clientOverview = ({ client }) => {
  return [
    {
      label: "_.overview",
      to: (vm: any) => {
        return {
          name: "adminClientOverview",
          params: vm.$route.params
        };
      }
    },
    {
      label: "_.orders",
      to: (vm: any) => {
        return {
          name: "adminClientOrders",
          params: vm.$route.params
        };
      },
      isActive: (currentRoute: Route, isActive) => {
        const regex = new RegExp(
          `^/admin/clients/(${RegexMatch.UUID})/(orders|baskets)/?`,
          "g"
        );
        if (currentRoute.path.match(regex)) return true;
        return isActive;
      },
      children: [
        {
          label: "_.orders",
          to: (vm: any) => {
            return {
              name: "adminClientOrders",
              params: vm.$route.params
            };
          }
        },
        {
          label: "_.baskets",
          to: (vm: any) => {
            return {
              name: "adminClientBaskets",
              params: vm.$route.params
            };
          }
        }
      ]
    },
    {
      label: "_.products_and_services",
      to: (vm: any) => {
        return {
          name: "adminClientCProds",
          params: vm.$route.params
        };
      },
      children: [
        {
          label: "_.all",
          to: (vm: any) => {
            return {
              name: "admin__clientAllProducts",
              params: vm.$route.params
            };
          }
        },
        {
          label: "_.subscriptions",
          to: (vm: any) => {
            return {
              name: "admin__clientSubscriptions",
              params: vm.$route.params
            };
          }
        },
        {
          label: "_.one_time_purchases",
          to: (vm: any) => {
            return {
              name: "admin__clientOTPs",
              params: vm.$route.params
            };
          }
        }
      ]
    },
    {
      label: "_.billing",
      to: (vm: any) => {
        return {
          name: "adminClientBilling",
          params: vm.$route.params
        };
      },
      children: [
        {
          label: "_.invoices",
          to: (vm: any) => {
            return {
              name: "adminClientInvoices",
              params: vm.$route.params
            };
          }
        },
        {
          label: "_.legacy_invoices",
          to: (vm: any) => {
            return {
              name: "adminClientLegacyInvoices",
              params: vm.$route.params
            };
          },
          if: (vm: any) => {
            const { clientId } = vm?.$route?.params || {};
            const client: IClient = store.getters[`data/single`]({
              storeModule: DataModules.CLIENTS,
              scope:
                store.getters[`data/${DataModules.CLIENTS}/scope`](clientId)
            });
            return !!client?.has_legacy_invoices;
          }
        },
        {
          label: "_.credit_notes",
          to: (vm: any) => {
            return {
              name: "adminClientCreditNotes",
              params: vm.$route.params
            };
          }
        },
        {
          label: "_.transactions",
          to: (vm: any) => {
            return {
              name: "adminClientTransactions",
              params: vm.$route.params
            };
          }
        },
        {
          label: "_.payment_methods",
          to: (vm: any) => {
            return {
              name: "adminClientPaymentMethods",
              params: vm.$route.params
            };
          }
        },
        {
          label: "_.account_credit",
          to: (vm: any) => {
            return {
              name: "adminClientAccountCredit",
              params: vm.$route.params
            };
          }
        },
        {
          label: "_.settings",
          to: (vm: any) => {
            return {
              name: AdminRoutes.CLIENT_SETTINGS_BILLING,
              params: vm.$route.params
            };
          }
        }
      ]
    },
    {
      label: "_.tickets",
      to: (vm: any) => {
        return {
          name: "adminClientSupportTickets",
          params: vm.$route.params
        };
      }
    },
    {
      label: "_.accounts",
      to: (vm: any) => {
        return {
          name: AdminRoutes.CLIENT_ACCOUNTS,
          params: vm.$route.params
        };
      },
      children: [
        {
          label: "_.affiliate_account",
          to: (vm: any) => {
            return {
              name: AdminRoutes.CLIENT_ACCOUNTS_AFFILIATE_ACCOUNT,
              params: vm.$route.params
            };
          },
          if: () => {
            const config = store.getters["brand/config"];
            return _.get(config, BrandConfigKeys.UPMIND_AFFILIATES_ENABLED);
          }
        },
        {
          label: "_.child_accounts",
          to: (vm: any) => {
            return {
              name: AdminRoutes.CLIENT_ACCOUNTS_CHILD_ACCOUNTS,
              params: vm.$route.params
            };
          },
          if: () =>
            _.isNull(client.parent_client_config) &&
            client.child_client_configs_count > 0
        }
      ]
    },
    {
      label: "_.logs",
      to: (vm: any) => {
        return {
          name: "adminClientLogs",
          params: vm.$route.params
        };
      },
      children: [
        {
          label: "_.email_history",
          to: (vm: any) => {
            return {
              name: "adminClientEmailHistory",
              params: vm.$route.params
            };
          }
        },
        {
          label: "_.provision_requests_log",
          to: {
            name: AdminRoutes.CLIENT_LOGS_PROVISION_REQUESTS
          },
          if: () => store.getters[`org/hasProvisioningEnabled`]
        }
      ]
    },
    {
      label: "_.settings",
      to: (vm: any) => {
        return {
          name: AdminRoutes.CLIENT_SETTINGS_PROFILE,
          params: vm.$route.params
        };
      },
      isActive: (currentRoute: Route, isActive: boolean) => {
        const regex = new RegExp(
          `/admin/clients/${RegexMatch.UUID}/(profile|security|billing-settings|notifications)/?`,
          "g"
        );
        if (currentRoute.path.match(regex)) return true;
        return isActive;
      },
      children: [
        {
          label: "_.profile",
          to: (vm: any) => {
            return {
              name: AdminRoutes.CLIENT_SETTINGS_PROFILE,
              params: vm.$route.params
            };
          }
        },
        {
          label: "_.security",
          to: (vm: any) => {
            return {
              name: AdminRoutes.CLIENT_SETTINGS_SECURITY,
              params: vm.$route.params
            };
          }
        },
        {
          label: "_.billing",
          to: (vm: any) => {
            return {
              name: AdminRoutes.CLIENT_SETTINGS_BILLING,
              params: vm.$route.params
            };
          }
        },
        {
          label: "_.notifications",
          to: (vm: any) => {
            return {
              name: AdminRoutes.CLIENT_NOTIFICATIONS,
              params: vm.$route.params
            };
          }
        }
      ]
    }
  ];
};
