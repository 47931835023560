import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import type { ApiPathGetter } from "@/models/api";
import { TemplateTypes } from "@/data/enums/templates";
import { Methods } from "@/models/methods";
import type { CreateElement } from "vue";
import type { Vue as VueInterface } from "vue/types/vue";
import { ModalCancelBy, ModalCancelByAll } from "@/data/enums";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext }): ApiPathGetter =>
    () => {
      const admin = "api/admin/templates";
      const client = "api/templates";
      const contextual = isAdminContext ? admin : client;
      return { client, admin, contextual };
    },
  scope: () => id => {
    return `$template_${id}`;
  },
  withParam:
    () =>
    ({
      type,
      returnDefault = false
    }: {
      type: TemplateTypes;
      returnDefault: Boolean;
    }) => {
      const defaultWithParams = [
        "mappings.hook",
        "recipient_type",
        "channel",
        "category"
      ].join();

      if (returnDefault) return defaultWithParams;

      switch (type) {
        case TemplateTypes.NOTIFICATION:
          return [
            "contents",
            "category.params",
            "mappings.hook",
            "recipient_type.category.params",
            "channel"
          ].join();
        case TemplateTypes.CLIENT_TEMPLATE:
          return [
            "contents",
            "category",
            "mappings.hook",
            "recipient_type",
            "channel"
          ].join();
        case TemplateTypes.DOCUMENT:
          return ["contents", "original_template"].join();
        default:
          return "";
      }
    },
  canGet: (_state, _getters, _rootState, rootGetter) => () => {
    return rootGetter["user/can"]("get_template");
  },
  canList: (_state, _getters, _rootState, rootGetter) => () => {
    return rootGetter["user/can"]("list_templates");
  },
  canCreate: (_state, _getters, _rootState, rootGetter) => () => {
    return rootGetter["user/can"]("create_template");
  },
  canEdit: (_state, _getters, _rootState, rootGetter) => () => {
    return rootGetter["user/can"]("update_template");
  },
  canDelete: (_state, _getters, _rootState, rootGetter) => () => {
    return rootGetter["user/can"]("delete_template");
  },
  canDuplicate: (_state, _getters, _rootState, rootGetter) => () => {
    return rootGetter["user/can"]("duplicate_template");
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath().contextual,
        storeModule: DataModules.TEMPLATES
      },
      { root: true }
    );
  },
  get: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath(payload).contextual}/${payload.id}`,
        storeModule: DataModules.TEMPLATES
      },
      { root: true }
    );
  },
  create: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/create",
      {
        ...payload,
        path: getters.apiPath(payload).admin,
        storeModule: DataModules.TEMPLATES
      },
      { root: true }
    );
  },
  update: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/update",
      {
        ...payload,
        path: `${getters.apiPath().contextual}/${payload.id}`,
        storeModule: DataModules.TEMPLATES
      },
      { root: true }
    );
  },
  remove: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/remove",
      {
        ...payload,
        path: `${getters.apiPath().contextual}/${payload.id}`,
        storeModule: DataModules.TEMPLATES
      },
      { root: true }
    );
  },
  duplicate: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.POST,
        path: `${getters.apiPath().admin}/${payload.id}/duplicate`
      },
      { root: true }
    );
  },
  send: ({ dispatch }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.POST,
        path: `api/admin/notifications/templates/${payload.id}/send`,
        requestConfig: { data: payload.data }
      },
      { root: true }
    );
  },
  openSendTestEmailModal: async ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import("@/components/app/global/templates/sendTestEmailModal.vue"),
          ...payload
        }
      },
      { root: true }
    );
  },
  openSelectSegmentObjectModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/global/templates/selectSegmentObjectModal.vue"
            ),
          ...payload
        }
      },
      { root: true }
    );
  },
  openSendEmailModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/windowModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/admin/notifications/templates/sendEmailDropdownModal.vue"
            ),
          canCancel: ["button", "x"],
          width: 480,
          ...payload
        }
      },
      { root: true }
    );
  },
  openViewContentModal: async ({ dispatch }, payload) => {
    const TemplateProvider = await import(
      "@/components/app/global/templates/templateProvider.vue"
    );
    const TemplateContentModal = await import(
      "@/components/app/global/templates/templateContentModal.vue"
    );

    const modal = await dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: {
            render(this: VueInterface, h: CreateElement) {
              return h(TemplateProvider.default, {
                props: {
                  templateId: payload.props.templateId,
                  templateType: payload.props.templateType,
                  handleLoading: false,
                  onNotFound: () => {
                    modal.close();
                  }
                },
                on: payload.events,
                scopedSlots: {
                  default: () => {
                    return h(TemplateContentModal.default, {
                      props: { title: payload.props.title },
                      on: {
                        close: () => modal.close()
                      }
                    });
                  }
                }
              });
            }
          },
          canCancel: ModalCancelByAll,
          ...payload
        }
      },
      { root: true }
    );
    return modal;
  },
  openAddEditModal: async ({ dispatch }, payload) => {
    const TemplateProvider = await import(
      "@/components/app/global/templates/templateProvider.vue"
    );
    const TemplateAddEditModal = await import(
      "@/components/app/global/templates/templateAddEditModal.vue"
    );

    const modal = await dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: {
            render(this: VueInterface, h: CreateElement) {
              return h(TemplateProvider.default, {
                props: {
                  ...payload.props,
                  handleLoading: false,
                  onNotFound: () => {
                    modal.close();
                  }
                },
                on: {
                  changed: params => {
                    if (payload.events["changed"])
                      payload.events.changed(params);
                  }
                },
                scopedSlots: {
                  default: () => {
                    return h(TemplateAddEditModal.default, {
                      attrs: this.$attrs,
                      on: {
                        ...payload.events,
                        close: () => modal.close()
                      }
                    });
                  }
                }
              });
            }
          },
          canCancel: [ModalCancelBy.BUTTON],
          ...payload
        }
      },
      { root: true }
    );
    return modal;
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  modules: {
    contents: require("./contents").default,
    client: require("./client").default
  }
};
