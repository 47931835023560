import Vue from "vue";
import type { MutationTree, ActionTree, GetterTree } from "vuex";
import Vuex from "vuex";
import { Contexts } from "@upmind-automation/types";
import type { IDataModuleState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { IApiState } from "@/store/modules/api";
import type { IAuthState } from "@/store/modules/auth";
import type { IBrandState } from "@/store/modules/brand";
import type { IConstantsState } from "@/store/modules/constants";
import type { IDemoDataState } from "@/store/modules/demoData";
import type { IOrgState } from "@/store/modules/org";
import type { ISocketMessage } from "@/models/socket";
import type { ISocketState } from "@/store/modules/socket";
import type { ISortableCategoriesState } from "@/store/modules/sortableCategories";
import type { IUiState } from "@/store/modules/ui";
import type { IUpmState } from "@/store/modules/upm";
import type { IUser } from "@/models/users";
import type { IUserNotification } from "@/models/userNotifications";
import type { IUtilsState } from "@/store/modules/utils";
import type { Ii18nState } from "@/store/modules/i18n";
import router from "@/router";
import _ from "@/boot/lodash";

Vue.use(Vuex);

export interface IState {
  api: IApiState;
  auth: IAuthState;
  brand: IBrandState;
  constants: IConstantsState;
  context: Contexts;
  data: IDataModuleState;
  demoData: IDemoDataState;
  i18n: Ii18nState;
  org: IOrgState;
  socket: ISocketState;
  sortableCategories: ISortableCategoriesState;
  ui: IUiState;
  upm: IUpmState;
  user: IUser;
  utils: IUtilsState;
}

const initialState = () => {
  return {
    context: "" // Important – inital context must be ambiguous
  };
};

const getters: GetterTree<IState, IState> = {
  isAdminContext: ({ context }) => {
    return context === Contexts.ADMIN;
  },
  isAuthenticatedAdminContext: (s, getters, rS, rootGetters) => {
    return getters.isAdminContext && rootGetters["auth/admin/isAuthenticated"];
  },
  isClientContext: ({ context }) => {
    return context === Contexts.CLIENT;
  },
  isGuestContext: ({ context }) => {
    return context === Contexts.GUEST;
  },
  isUpmindContext: () => {
    const hostnames = import.meta.env.VITE_APP_UPMIND_HOSTNAMES || "";
    return hostnames.split(",").includes(window.location.hostname);
  },
  /** `isMockClientContext` must be a function as `router` is non-reactive */
  isMockClientContext: () => () => {
    return _.some(
      router.currentRoute.matched,
      route => route.meta?.mockClientContext
    );
  }
};

const actions: ActionTree<IState, IState> = {
  SOCKET_receive_msg: ({ dispatch }, notification: IUserNotification) => {
    return dispatch(
      `data/${DataModules.USER_NOTIFICATIONS}/addNewUnread`,
      notification
    );
  },
  SOCKET_provision_result_received: (
    { dispatch },
    notification: ISocketMessage
  ) => {
    return dispatch(
      `data/${DataModules.PROVISIONING_FUNCTIONS}/handleSocketMessage`,
      notification
    );
  }
};

const mutations: MutationTree<IState> = {
  SOCKET_ticket_new_message: (state: IState, payload: ISocketMessage) => {
    window.$rootVue.$bus.$emit("socket-ticket-new-message", payload);
  },
  SOCKET_ticket_delete_message: (state: IState, payload: ISocketMessage) => {
    window.$rootVue.$bus.$emit("socket-ticket-delete-message", payload);
  },
  SOCKET_ticket_update_message: (state: IState, payload: ISocketMessage) => {
    window.$rootVue.$bus.$emit("socket-ticket-update-message", payload);
  },
  SOCKET_ticket_update: (state: IState, payload: ISocketMessage) => {
    window.$rootVue.$bus.$emit("socket-ticket-update", payload);
  },
  SOCKET_ticket_status_change: (state: IState, payload: ISocketMessage) => {
    window.$rootVue.$bus.$emit("socket-ticket-status-update", payload);
  },
  SOCKET_basket_recalculated: (state: IState, payload: ISocketMessage) => {
    window.$rootVue.$bus.$emit("socket-basket-recalculated", payload);
  },
  context: (state, context: string) => {
    Vue.set(state, "context", context);
  },
  user: (state, user: IUser) => {
    Vue.set(state, "user", user);
  },
  setMeta: (state, meta: { [key: string]: any }) => {
    Vue.set(state.user, "meta", meta);
  }
};

const modules = {
  api: require("./modules/api").default,
  auth: require("./modules/auth").default,
  brand: require("./modules/brand").default,
  constants: require("./modules/constants").default,
  data: require("./modules/data").default,
  demoData: require("./modules/demoData").default,
  i18n: require("./modules/i18n").default,
  org: require("./modules/org").default,
  socket: require("./modules/socket").default,
  sortableCategories: require("./modules/sortableCategories").default,
  toast: require("./modules/toast").default,
  ui: require("./modules/ui").default,
  upm: require("./modules/upm").default,
  user: require("./modules/user").default,
  utils: require("./modules/utils").default
};

const store = new Vuex.Store({
  state: initialState() as IState,
  mutations,
  getters,
  actions,
  modules
});

export default store;
