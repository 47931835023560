import _ from "@/boot/lodash";
import type { ActionTree, GetterTree } from "vuex";
import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ApiPathGetter } from "@/models/api";
import type { IState } from "@/store";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath: (): ApiPathGetter => () => {
    const admin = `api/admin/oauth_applications/access_tokens`;
    return { admin };
  },
  scope: () => code => {
    return `$oauth_token_${code}`;
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload = {}) => {
    return dispatch(
      "data/list",
      {
        ..._.merge(payload, {
          params: {
            limit: 1000
          }
        }),
        path: getters.apiPath().admin,
        storeModule: DataModules.OAUTH_TOKENS
      },
      { root: true }
    );
  },
  get: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload.uuid}`,
        storeModule: DataModules.OAUTH_TOKENS
      },
      { root: true }
    );
  },
  update: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/update",
      {
        data: payload.data,
        path: `${getters.apiPath().admin}/${payload.uuid}`,
        storeModule: DataModules.OAUTH_TOKENS
      },
      { root: true }
    );
  },
  remove: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/remove",
      {
        path: `${getters.apiPath().admin}/${payload.uuid}`,
        storeModule: DataModules.OAUTH_TOKENS
      },
      { root: true }
    );
  },
  openEditModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/windowModal",
      {
        config: {
          component: () =>
            import("@/components/app/admin/oauth/editOAuthTokenModal.vue"),
          width: 540,
          ...payload
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
