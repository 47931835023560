import i18n from "@/i18n";
import store from "@/store";
import _ from "@/boot/lodash";
import type { NavigationGuard, RouteConfig } from "vue-router";
import { QUERY_PARAMS, BrandConfigKeys, CheckoutFlows } from "@/data/constants";
import { ClientRoutes } from "@/data/enums/router";

const guardStore: NavigationGuard = async (to, from, next) => {
  if (!store.getters["brand/showStore"]) {
    await store.dispatch(
      "api/handleErrorSnackbar",
      i18n.t("_sentence.store_currently_unavailable_msg", {
        brand: store.state.brand?.name
      })
    );
    return next({ name: ClientRoutes.DASHBOARD });
  }
  if (store.getters["auth/client/isAuthenticated"])
    await store.dispatch("auth/client/prepare");
  return next();
};

const guardFromComplete: NavigationGuard = (to, from, next) => {
  if (from.name === "orderCompleteStep") return next({ name: "orderShopStep" });
};

const guardFromOnePageCheckout: NavigationGuard = (to, from, next) => {
  const flow = store.state.brand?.config[BrandConfigKeys.CHECKOUT_FLOW];
  if (flow === CheckoutFlows.ONE_PAGE)
    return next({ name: "orderCheckoutStep" });
};

export default [
  {
    path: "/order/_",
    component: () => import("@/views/order/rapid/index.vue"),
    meta: { allowGuestContext: true },
    props: ({ query }) => ({ query })
  },
  {
    path: "/order",
    component: () => import("@/views/order/index.vue"),
    beforeEnter: guardStore,
    props: to => ({
      routeClientId: to.query[QUERY_PARAMS.CLIENT_ID] || "",
      routeAccountId: to.query[QUERY_PARAMS.ACCOUNT_ID] || "",
      routeBasketId: to.query[QUERY_PARAMS.BASKET_ID] || "",
      quantity: _.toNumber(to.query[QUERY_PARAMS.QUANTITY]) || 1,
      billingTerm: _.toNumber(to.query[QUERY_PARAMS.BILLING_CYCLE_MONTHS]) || 0,
      routeOrderId: to.query[QUERY_PARAMS.ORDER_ID] || "",
      routeCrossSell: !!to.query[QUERY_PARAMS.CROSS_SELL_PRODUCT] || false,
      routeReadMore: !!to.query[QUERY_PARAMS.READ_MORE] || false,
      routeCurrencyCode: _.upperCase(
        _.toString(to.query[QUERY_PARAMS.CURRENCY_CODE] || "")
      ),
      routeCoupons: to.query[QUERY_PARAMS.COUPONS] || ""
    }),
    meta: {
      allowGuestContext: true
    },
    children: [
      {
        path: "checkout",
        component: () => {
          const { config } = store.state.brand;
          if (config[BrandConfigKeys.CHECKOUT_FLOW] === CheckoutFlows.ONE_PAGE)
            return import("@/views/order/onePage/index.vue");
          return import("@/views/order/stepped/index.vue");
        },
        props: {
          basketRoutes: require("./menu").default
        },
        children: [
          {
            path: "",
            name: "orderCheckoutStep",
            component: () =>
              import(
                "@/components/app/global/baskets/basket/checkout/index.vue"
              ),
            meta: {
              title: "_.checkout"
            },
            beforeEnter: (to, from, next) => {
              guardFromComplete(to, from, next);
              return next();
            }
          }
        ]
      },
      {
        path: "",
        name: "order",
        redirect: "shop",
        component: () => import("@/views/order/stepped/index.vue"),
        meta: {
          title: "_.order",
          section: {
            header: "_.order",
            icon: "shopping-basket"
          }
        },
        props: {
          basketRoutes: require("./menu").default
        },
        children: [
          {
            path: "shop",
            name: "orderShopStep",
            component: () =>
              import("@/components/app/global/baskets/basket/shop/index.vue"),
            meta: {
              title: "_.shop"
            }
          },
          {
            path: "product",
            name: "orderProductViewStep",
            component: () =>
              import(
                "@/components/app/global/baskets/basket/productView/index.vue"
              ),
            beforeEnter: (to, from, next) => {
              guardFromComplete(to, from, next);
              return next();
            },
            meta: {
              title: "_.shop"
            }
          },
          {
            path: "basket",
            name: "orderBasketStep",
            component: () =>
              import(
                "@/components/app/global/baskets/basket/basketView/index.vue"
              ),
            meta: {
              title: "_.basket"
            },
            beforeEnter: (to, from, next) => {
              guardFromComplete(to, from, next);
              guardFromOnePageCheckout(to, from, next);
              return next();
            }
          },
          {
            path: "authenticate",
            name: "orderAuthenticateStep",
            component: () =>
              import(
                "@/components/app/global/baskets/basket/authenticate/index.vue"
              ),
            beforeEnter: (to, from, next) => {
              guardFromComplete(to, from, next);
              guardFromOnePageCheckout(to, from, next);
              return next();
            },
            meta: {
              title: "_.authenticate"
            }
          },
          {
            path: "complete",
            name: "orderCompleteStep",
            component: () =>
              import(
                "@/components/app/global/baskets/basket/complete/index.vue"
              ),
            meta: {
              title: "_.complete"
            }
          }
        ]
      }
    ]
  }
] as RouteConfig[];
