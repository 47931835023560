import store from "@/store";
import type { Component } from "vue";
import { ClientRoutes, RegexMatch } from "@/data/enums/router";
import type { NavigationGuardNext, Route } from "vue-router";
import { BrandConfigKeys } from "@/data/constants";
import { IsActiveParam, IsClosedParam } from "@/data/filters/tickets";

// Components
import ClientSupportMenu from "@/views/client/support/supportMenu.vue";
import ClientDefaultLayout from "@/skeleton/client/loggedIn/defaultLayout.vue";
import ClientSupportTicketLayout from "@/components/app/client/support/clientSupportTicketLayout.vue";
import TicketsListing from "@/components/app/global/tickets/ticketsListing.vue";

export default [
  {
    path: "support",
    components: {
      default: {
        name: "ClientSupport",
        render: h => h("router-view")
      } as Component,
      layout: {
        name: "ClientSupportLayout",
        render: h =>
          h(ClientDefaultLayout, {
            scopedSlots: {
              menu: () =>
                h(ClientSupportMenu, {
                  props: {
                    routes: require("./menu").supportMenu
                  }
                })
            }
          })
      } as Component
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext) => {
      if (
        store.getters["brand/config"][
          BrandConfigKeys.UI_CLIENT_APP_DISABLE_SUPPORT_SYSTEM
        ] ??
        false
      ) {
        store.dispatch("ui/endRouting");
        return next({ name: ClientRoutes.DASHBOARD, replace: true });
      }
      next();
    },
    meta: {},
    children: [
      {
        path: "",
        name: ClientRoutes.SUPPORT,
        redirect: "tickets"
      },
      {
        path: "tickets",
        component: {
          name: "ClientSupportTickets",
          template: `<router-view />`
        },
        meta: {
          title: "_.tickets"
        },
        children: [
          {
            path: "",
            name: ClientRoutes.SUPPORT_TICKETS,
            component: () => import("@/views/client/support/tickets/index.vue"),
            redirect: { name: "client__activeSupportTickets" },
            props: {
              routes: require("./menu").ticketsTabigation
            },
            children: [
              {
                path: "active",
                name: "client__activeSupportTickets",
                component: TicketsListing,
                props: () => ({
                  routeFilters: () => ({
                    ...IsActiveParam()
                  }),
                  showStaged: true
                }),
                meta: {
                  backTo: "_.client_active_support_tickets",
                  title: "_.client_active_support_tickets"
                }
              },
              {
                path: "closed",
                name: "client__closedSupportTickets",
                component: TicketsListing,
                props: () => ({
                  routeFilters: () => ({
                    ...IsClosedParam()
                  }),
                  showStaged: true
                }),
                meta: {
                  backTo: "_.client_closed_support_tickets",
                  title: "_.client_closed_support_tickets"
                }
              }
            ]
          },
          {
            path: "add",
            name: ClientRoutes.SUPPORT_TICKETS_ADD,
            component: () =>
              import("@/views/client/support/tickets/ticket/index.vue"),
            meta: {
              title: "_.add"
            }
          },
          {
            path: `:ticketId(${RegexMatch.UUID})`,
            name: ClientRoutes.SUPPORT_TICKET,
            props: true,
            components: {
              default: {
                name: "SupportTicketWrapper",
                render: h => h("router-view")
              } as Component,
              layout: {
                functional: true,
                render: h => h(ClientSupportTicketLayout, {})
              } as Component
            },
            meta: {
              title: ({ ticketRef }) => ticketRef
            }
          }
        ]
      }
    ]
  }
];
