import i18n from "@/i18n";
import type { IMenuRoute } from "@/models/routes";

export const UpmindInvoicesRoutes: () => IMenuRoute[] = () => [
  {
    label: i18n.t("_.all"),
    to: {
      name: "admin__upmindAllInvoices"
    }
  },
  {
    label: i18n.t("_.paid"),
    to: {
      name: "admin__upmindPaidInvoices"
    }
  },
  {
    label: i18n.t("_.unpaid"),
    to: {
      name: "admin__upmindUnpaidInvoices"
    }
  },
  {
    label: i18n.t("_.credited"),
    to: {
      name: "admin__upmindCreditedInvoices"
    }
  }
];

export function UpmindOrderTabigation(): IMenuRoute[] {
  return [
    {
      label: i18n.t("_.summary"),
      to: (vm: any) => {
        return {
          name: "admin__upmindOrderSummary",
          params: vm.$route.params
        };
      }
    },
    {
      label: i18n.t("_.invoices"),
      to: (vm: any) => {
        return {
          name: "admin__upmindOrderInvoices",
          params: vm.$route.params
        };
      }
    },
    {
      label: i18n.t("_.credit_notes"),
      to: (vm: any) => {
        return {
          name: "admin__upmindOrderCreditNotes",
          params: vm.$route.params
        };
      }
    }
  ];
}
