import moment from "moment";

export const $moment = moment;

export default {
  install: (Vue: any) => {
    // Define global '$moment' property
    Object.defineProperty(Vue.prototype, "$moment", {
      value: $moment
    });
  }
};
