export enum UserMetaKeys {
  UI_CATALOGUE_DISPLAY_MODE = "ui/catalogue/displayMode",
  UI_CLIENTS_DISPLAY_MODE = "ui/clients/displayMode",
  UI_CPRODS_VIEW = "ui/cprods/view",
  UI_MENU = "ui/menu",
  UI_NAV_IS_COLLAPSED = "ui/nav/isCollapsed",
  UI_PRODUCTS_EXCLUDE_DELEGATES = "ui/products/excludeDelegated",
  UI_SPAM_CONDITIONS = "ui/settings/spamConditions",
  UI_STATS_TIMEFRAME = "ui/stats/timeframe",
  UI_SETTINGS_CUSTOM_FIELDS = "ui/settings/customFields",
  UI_SUPPORT_AUTO_REFRESH = "ui/support/autoRefresh",
  UI_SUPPORT_DISPLAY_MODE = "ui/support/displayMode",
  UI_SUPPORT_LIMIT = "ui/support/limit",
  UI_SUPPORT_NEW_LINE = "ui/support/newLine",
  UI_SUPPORT_REDIRECT_TO_ALL_TICKETS = "ui/support/redirectToAllTickets",
  UI_SUPPORT_SHOW_OWNER_TAGS = "ui/support/showOwnerTags",
  UI_SUPPORT_SUBMIT_WITH_SHORTCUT = "ui/support/submitWithShortcut",
  UI_TAX_TAGS_VIEW = "ui/taxTags/view",
  UI_TAX_TEMPLATES_VIEW = "ui/taxTemplates/view"
}
