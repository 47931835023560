import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import _ from "@/boot/lodash";
import type { ApiPathGetter } from "@/models/api";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath: (): ApiPathGetter => () => {
    const admin = "api/admin/provision/blueprints";
    return { admin };
  },
  scope: () => id => {
    return `$provision_blueprint_${id}`;
  },
  blueprintsScope: () => {
    return `$provision_blueprints`;
  },
  blueprintSetupFunctionsScope: () => id => {
    return `$provision_blueprints_${id}_functions`;
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath().admin,
        params: _.merge({ limit: 0 }, _.get(payload, "params", {})),
        storeModule: DataModules.PROVISIONING_BLUEPRINTS
      },
      { root: true }
    );
  },

  get: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload?.id}`,
        storeModule: DataModules.PROVISIONING_BLUEPRINTS
      },
      { root: true }
    );
  },

  getSetupFunctions: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload?.id}/functions`,
        storeModule: DataModules.PROVISIONING_BLUEPRINTS
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
