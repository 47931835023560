import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import { Methods } from "@/models/methods";
import type { DelegateObjectTypes } from "@/data/enums/delegates";
import type { ApiPathGetter } from "@/models/api";
import _ from "lodash";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext, isMockClientContext }): ApiPathGetter =>
    () => {
      const admin = "api/admin/delegate_access";
      const client = "api/delegate_access";
      const contextual =
        isAdminContext && !isMockClientContext() ? admin : client;
      return { client, admin, contextual };
    }
};

export const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath().contextual,
        storeModule: DataModules.DELEGATES
      },
      { root: true }
    );
  },
  sendInvite: (
    { dispatch, getters },
    payload: {
      data: {
        object_id: number;
        object_type: DelegateObjectTypes;
        email: string;
      };
    }
  ) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.POST,
        path: getters.apiPath().contextual,
        requestConfig: _.pick(payload, ["data"])
      },
      { root: true }
    );
  },
  acceptInvite: ({ dispatch, getters }, hash: string) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PATCH,
        path: `${getters.apiPath().contextual}/accept/${hash}`
      },
      { root: true }
    );
  },
  revokeAccess: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.DELETE,
        path: `${getters.apiPath().contextual}/${payload?.accessId}`
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
