import Vue from "vue";
import _ from "@/boot/lodash";
import type { MutationTree } from "vuex";
interface IPromise {
  count: number;
  promise: Promise<any>;
  cancelRequest: () => void;
}
export interface IRequestsState {
  [hash: string]: IPromise;
}

const mutations: MutationTree<IRequestsState> = {
  setRequest: (
    state,
    {
      hash,
      promise,
      cancelRequest
    }: { hash: string; promise: Promise<any>; cancelRequest: () => void }
  ) => {
    const request = _.get(state, hash, null);
    if (!request) {
      Vue.set(state, hash, { promise, count: 1, cancelRequest });
      return;
    }

    Vue.set(state[hash], "count", ++request.count);
  },
  unsetRequest: (state: IRequestsState, hash: string) => {
    Vue.delete(state, hash);
  },
  cancelRequests: (
    state: IRequestsState,
    hashes: { [hash: string]: number }
  ) => {
    _.forEach(hashes, (count, hash) => {
      const request = _.get(state, hash, null);
      if (!request) {
        return;
      }
      state[hash].count -= count;
      if (state[hash].count) {
        return;
      }
      if (_.isFunction(request.cancelRequest)) {
        request.cancelRequest();
      }
      Vue.delete(state, hash);
    });
  }
};

export default {
  namespaced: false,
  state: {} as IRequestsState,
  mutations
};
