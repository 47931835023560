<template>
  <transition name="slide-transition">
    <div
      v-if="isActive"
      class="slider-modal"
      :style="{ height: dynamicHeight }"
    >
      <div class="slider-modal-background" @click="cancel('outside')" />
      <div
        class="animation-content"
        :class="{ 'modal-content': !hasModalCard }"
        :style="{ right }"
      >
        <component
          :is="component"
          v-if="component"
          v-bind="props"
          v-on="events"
          @close="close"
        />
        <div v-else-if="content" v-html="content" />
        <slot v-else />
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import Vue, { defineComponent } from "vue";
import Modal from "buefy/src/components/modal/Modal.vue";
import { ConfirmModalModes } from "@/data/enums";
import type { BModalComponent } from "buefy/types/components";

const BuefyModalComponent = Vue.extend(Modal);

export default defineComponent({
  name: "SlideModal",
  mixins: [BuefyModalComponent],
  props: {
    modalHash: { type: String, default: null }
  },
  data: () => ({
    areYouSureModal: null as BModalComponent | null,
    blockCancellation: false,
    keepModalOpen: false
  }),
  computed: {
    dynamicHeight(): string {
      return `${this.$store.state.ui.window.height}px`;
    },
    sliderObject() {
      return {
        id: this.$_.get(this, "_uid"),
        hash: this.modalHash || this.$_.get(this, "_uid")
      };
    },
    right() {
      const sliders = this.sliders.slice().reverse();
      const index = sliders.indexOf(this.sliderObject);
      const right = this.$_.get([0, 25, 50, 75], `[${index}]`, 100);
      return `${right}%`;
    },
    currentlyActive() {
      return this.$_.isEqual(this.$_.last(this.sliders), this.sliderObject);
    },
    sliders() {
      return this.$store.state.ui.sliders;
    },
    parentSlideModal() {
      return this.getParentModal(this);
    }
  },
  beforeCreate() {
    this["__SLIDE__"] = true;
  },
  created() {
    this.$store.commit("ui/setSlider", this.sliderObject);
  },
  beforeDestroy() {
    this.$store.commit("ui/unsetSlider", this.sliderObject);
  },
  methods: {
    setBlockCancellation(val) {
      this.blockCancellation = val;
    },
    close() {
      if (this.areYouSureModal) {
        return;
      }

      if (
        this.$_.isFunction(
          this.$_.get(BuefyModalComponent, "options.methods.close.call")
        )
      ) {
        BuefyModalComponent.options.methods.close.call(this);
      }
    },
    cancel(method) {
      // This prevents all modals to close on ESC
      if (method === "escape" && !this.currentlyActive) {
        return;
      }

      this.areYouSureModal = null;

      if (
        this.blockCancellation ||
        this.$_.get(this, "cancelOptions", []).indexOf(method) < 0
      ) {
        if (this.blockCancellation) {
          this.$store
            .dispatch("ui/open/confirmModal", {
              config: {
                props: {
                  closeButtonText: this.$t("_.no"),
                  confirmButtonText: this.$t("_.yes"),
                  mode: ConfirmModalModes.BUTTON,
                  startProcessingAtConfirm: false,
                  title: this.$t("_sentence.action_in_process"),
                  message: this.$t(
                    "_sentence.are_you_sure_you_want_to_close_this_modal"
                  )
                },
                events: {
                  confirmed: () => {
                    this.areYouSureModal = null;
                    this.close();

                    this.onCancel(method);
                  },
                  close: () => {
                    this.areYouSureModal?.close();
                    this.areYouSureModal = null;
                  }
                }
              }
            })
            .then(modal => (this.areYouSureModal = modal));
        }
        return;
      }

      this.areYouSureModal && this.areYouSureModal.close();

      this.onCancel(method);
      this.close();
    },
    getParentModal(vue) {
      if (!vue) {
        return null;
      }
      return ["BModal"].includes(this.$_.get(vue, "constructor.options.name"))
        ? vue
        : this.getParentModal(vue.$parent);
    }
  }
});
</script>

<style lang="scss" scoped>
.slider-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 40;

  .slider-modal-background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba($dark, 0.5);
    transition: opacity ease-out 0.3s;
  }

  .animation-content {
    z-index: 1;
    position: absolute;
    width: 100%;
    max-width: 50rem;
    top: 0;
    bottom: 0;
    right: 0;
    margin: 0;
    box-shadow: -1px 0px 1rem 0px rgba($dark, 0.2);
    transition: transform 0.15s linear, right 0.15s linear;

    & > * {
      height: 100%;
    }
  }
}

.slide-transition-enter-active,
.slide-transition-leave-active {
  transition: none 0.3s 0s;
}

.slide-transition-enter-active .animation-content {
  transition-duration: 0.15s, 0.3s;
  transition-timing-function: ease-out, linear;
}

.slide-transition-leave-active .animation-content {
  transition-duration: 0.3s, 0.15s;
  transition-timing-function: ease-in, linear;
}

.slide-transition-enter,
.slide-transition-leave-to {
  .slider-modal-background {
    opacity: 0;
  }

  .animation-content {
    transform: translateX(100%);
  }
}
</style>
