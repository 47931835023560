import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree } from "vuex";
import type { ApiPathGetter } from "@/models/api";
import type { IState } from "@/store";
import _ from "@/boot/lodash";

const initialState = {} as IDataState;

const getters = {
  apiPath: (): ApiPathGetter => payload => {
    const admin = _.join(
      _.compact([
        "api/admin/templates/client_area/mappings",
        payload?.objectType,
        payload?.objectId
      ]),
      "/"
    );

    return { admin, contextual: admin };
  },
  listFunctionalities: () => {
    return "list_client_area_template_mappings";
  },
  canCreate: (_state, _getters, _rootState, rootGetters) => () => {
    return rootGetters["user/can"]("create_client_area_template_mapping");
  },
  canDelete: (_state, _getters, _rootState, rootGetters) => () => {
    return rootGetters["user/can"]("delete_client_area_template_mapping");
  },
  withParam: () => () => {
    return ["object", "slot", "template"].join();
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath(payload).contextual,
        storeModule: DataModules.TEMPLATES_CLIENT_MAPPINGS,
        params: {
          with_staged_imports: 1,
          ...payload.params
        }
      },
      { root: true }
    );
  },
  create: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/create",
      {
        ...payload,
        path: getters.apiPath(payload).admin,
        storeModule: DataModules.TEMPLATES_CLIENT_MAPPINGS
      },
      { root: true }
    );
  },
  remove: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/remove",
      {
        ...payload,
        path: `${getters.apiPath().contextual}/${payload.id}`,
        storeModule: DataModules.TEMPLATES_CLIENT_MAPPINGS
      },
      { root: true }
    );
  },
  openCreateModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import("@/components/app/admin/clientTemplates/mappingModal.vue"),
          ...payload
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
