import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree } from "vuex";
import type { IState } from "@/store";
import { Methods } from "@/models/methods";
import type { ApiPathGetter } from "@/models/api";
import type { IAppliedTaxTag } from "@/models/tax";
import { TaxTagTypes } from "@/data/enums/tax";
import _ from "@/boot/lodash";

const initialState = {} as IDataState;

const getters = {
  apiPath: (): ApiPathGetter => () => {
    const admin = `api/admin/tax_tags`;
    return { admin };
  },
  /**
   * @name computeAppliedTagName
   * @desc This getter computes the applied tax tag name into the format `VAT (20%)`.
   */
  computeAppliedTagName: () => (tag?: IAppliedTaxTag) => {
    return _.compact([
      // Tag name
      tag?.tax_tag_name?.replace(/\d*%$/, ""),
      // Append percentage (if SECONDARY % rate)
      tag?.for_company
        ? tag.tax_tag_company_type === TaxTagTypes.PERCENT &&
          `(${tag.tax_tag_company_amount}%)`
        : // Append percentage (if DEFAULT % rate)
          tag?.tax_tag_type === TaxTagTypes.PERCENT &&
          `(${tag.tax_tag_amount}%)`
    ]).join(" ");
  },
  scope: () => id => {
    return `$taxTag${id}`;
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath().admin,
        storeModule: DataModules.TAX_TAGS
      },
      { root: true }
    );
  },
  get: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload.id}`,
        storeModule: DataModules.TAX_TAGS
      },
      { root: true }
    );
  },
  create: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/create",
      {
        ...payload,
        path: getters.apiPath().admin,
        storeModule: DataModules.TAX_TAGS
      },
      { root: true }
    );
  },
  update: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.PUT,
        path: `${getters.apiPath().admin}/${payload.id}`,
        storeModule: DataModules.TAX_TAGS,
        requestConfig: { data: payload.data }
      },
      { root: true }
    );
  },
  remove: async ({ dispatch, getters }, payload) => {
    await dispatch(
      "data/remove",
      {
        ...payload,
        keys: [payload.id],
        path: `${getters.apiPath().admin}/${payload.id}`,
        storeModule: DataModules.TAX_TAGS
      },
      { root: true }
    );
  },
  openAddEditModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/global/tax/tags/addEditTaxTagFormModal.vue"
            ),
          width: 540,
          ...payload
        }
      },
      { root: true }
    );
  },
  openManageModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import("@/components/app/global/tax/tags/manageTaxTagsModal.vue"),
          width: 540,
          ...payload
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
