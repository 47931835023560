<script lang="ts">
import { defineComponent } from "vue";
import Vue from "vue";
import RequestPromises from "@/mixins/requestPromises";
import { PaymentMethodType } from "@/models/selectPaymentMethod";
import { QUERY_PARAMS } from "@/data/constants";
import ExternalAuthHandler from "@/components/app/global/auth/externalAuthHandler.vue";
import type { Location as VueLocation } from "vue-router";
import type { IPaymentDetail } from "@/models/paymentDetails";
import type {
  IAutoPay,
  IInitPay,
  IExternalAuthRedirect
} from "@/models/externalAuth";

type BuildRouteParams = {
  returnLocation: VueLocation;
  paymentMethodType?: null | PaymentMethodType;
  autoPay?: IAutoPay | null;
  initPay?: IInitPay | null;
  params?: { [key: string]: any };
};

export default defineComponent({
  name: "ExternalAuthMixin",
  mixins: [RequestPromises],
  data: () => ({
    isStoreSuccess: false,
    isPaymentSuccess: false,
    isPaymentAborted: false,
    isStoreAborted: false,
    isOmniBridgeAborted: false,
    paymentMethodTypeQueryParam: null as null | PaymentMethodType,
    showPendingPaymentQueryParam: null as boolean | null,
    /**
     * It will trigger programmatic payment
     * with the payment detail from QUERY_PARAMS.PAYMENT_DETAILS_ID only when QUERY_PARAMS.STORE_SUCCESS is true
     *
     */
    autoPayQueryParam: {} as IAutoPay,
    initPayQueryParam: {} as IInitPay,
    paymentDetailIdFromExternalAuth: "" as IPaymentDetail["id"],
    externalAuthHandler: null as any
  }),
  computed: {
    isSuccess() {
      return this.isStoreSuccess || this.isPaymentSuccess;
    },
    isAborted() {
      return (
        this.isStoreAborted || this.isPaymentAborted || this.isOmniBridgeAborted
      );
    }
  },
  created() {
    const ExternalAuthHandlerClass = Vue.extend(ExternalAuthHandler);
    this.externalAuthHandler = new ExternalAuthHandlerClass({
      parent: this.$parent
    });
  },
  beforeDestroy() {
    this.externalAuthHandler.$destroy();
  },
  methods: {
    /**
     * Created Vue Route Resolver
     * @returns
     */
    buildResolver({
      returnLocation,
      params,
      paymentMethodType = null,
      autoPay = undefined,
      initPay = undefined
    }: BuildRouteParams) {
      const queryParamAutoPay = !this.$_.isEmpty(autoPay)
        ? {
            [QUERY_PARAMS.AUTO_PAY]: encodeURIComponent(
              btoa(JSON.stringify(autoPay))
            )
          }
        : {};

      const queryParamInitPay = !this.$_.isEmpty(initPay)
        ? {
            [QUERY_PARAMS.INIT_PAY]: encodeURIComponent(
              btoa(JSON.stringify(initPay))
            )
          }
        : {};

      const queryParamPaymentMethodType =
        paymentMethodType &&
        Object.values(PaymentMethodType).includes(paymentMethodType)
          ? { [QUERY_PARAMS.PAYMENT_METHOD_TYPE]: paymentMethodType }
          : {};

      return this.$router.resolve(
        this.$_.merge(returnLocation, {
          query: {
            ...queryParamAutoPay,
            ...queryParamInitPay,
            ...queryParamPaymentMethodType,
            ...params
          }
        })
      );
    },
    /**
     * Creates encoded query string that has success and failed routes pointing to provided location
     * @returns {String}
     */
    buildReturnUrl({
      externalAuthReturnLocation,
      paymentMethodType = null,
      autoPay = undefined,
      initPay = undefined
    }: {
      externalAuthReturnLocation: VueLocation;
      paymentMethodType?: null | PaymentMethodType;
      autoPay?: IAutoPay | null;
      initPay?: IInitPay | null;
    }) {
      const successRoute = this.buildResolver({
        returnLocation: externalAuthReturnLocation,
        paymentMethodType,
        autoPay,
        initPay,
        params: {
          [QUERY_PARAMS.STORE_SUCCESS]: "1",
          [QUERY_PARAMS.PAYMENT_SUCCESS]: "true"
        }
      }).route;

      const failedRoute = this.buildResolver({
        returnLocation: externalAuthReturnLocation,
        paymentMethodType,
        autoPay,
        initPay,
        params: {
          [QUERY_PARAMS.STORE_SUCCESS]: "0",
          [QUERY_PARAMS.PAYMENT_SUCCESS]: "false"
        }
      }).route;

      const query = {
        success: encodeURIComponent(
          `${location.origin}${successRoute.fullPath}`
        ),
        failed: encodeURIComponent(`${location.origin}${failedRoute.fullPath}`)
      };

      return `?${QUERY_PARAMS.SUCCESS}=${query.success}&${QUERY_PARAMS.FAILED}=${query.failed}`;
    },
    redirectToExternalAuth({
      url,
      method = "GET",
      fields = {},
      returnLocation = {}
    }: IExternalAuthRedirect) {
      /**
       * Inject aborted state for cases when user click back from the browser
       */
      if (returnLocation) {
        const route = this.buildResolver({
          returnLocation,
          params: {
            [QUERY_PARAMS.STORE_SUCCESS]: "-1",
            [QUERY_PARAMS.PAYMENT_SUCCESS]: "false",
            [QUERY_PARAMS.PAYMENT_DETAILS_ID]: ""
          }
        }).route;
        window.history.replaceState(
          "",
          "",
          `${location.origin}${route.fullPath}`
        );
      }

      // Redirect
      return this.externalAuthHandler.redirectToExternalUrl({
        url,
        method,
        fields
      });
    },
    showMessage() {
      if (this.isAborted) this.onAborted();
      else if (this.isSuccess) this.onSuccess();
      else this.onFail();
    },
    checkExternalAuthQueryParams({ showMessage = true } = {}) {
      const query = this.$route.query;
      this.paymentMethodTypeQueryParam = query[
        QUERY_PARAMS.PAYMENT_METHOD_TYPE
      ] as PaymentMethodType;

      const canContinue = () => {
        if (this.paymentMethodTypeQueryParam === PaymentMethodType.GATEWAY_CARD)
          return (
            this.$_.has(query, QUERY_PARAMS.PAYMENT_SUCCESS) ||
            this.$_.has(query, QUERY_PARAMS.ABORT)
          );
        else
          return (
            this.$_.has(query, [QUERY_PARAMS.STORE_SUCCESS]) &&
            this.$_.has(query, [QUERY_PARAMS.PAYMENT_DETAILS_ID])
          );
      };

      if (!canContinue()) return;

      try {
        this.isStoreSuccess = query[QUERY_PARAMS.STORE_SUCCESS] === "1";
        this.isPaymentSuccess = query[QUERY_PARAMS.PAYMENT_SUCCESS] === "true";

        this.isOmniBridgeAborted = this.$_.has(query, QUERY_PARAMS.ABORT);
        this.isStoreAborted = query[QUERY_PARAMS.STORE_SUCCESS] === "-1";
        this.isPaymentAborted = query[QUERY_PARAMS.PAYMENT_SUCCESS] === "false";

        // Auto pay
        try {
          this.autoPayQueryParam = this.$_.has(query, QUERY_PARAMS.AUTO_PAY)
            ? JSON.parse(
                atob(decodeURIComponent(query[QUERY_PARAMS.AUTO_PAY] as string))
              )
            : undefined;
        } catch {
          this.autoPayQueryParam = undefined;
        }

        // Init pay
        try {
          this.initPayQueryParam = this.$_.has(query, QUERY_PARAMS.INIT_PAY)
            ? JSON.parse(
                atob(decodeURIComponent(query[QUERY_PARAMS.INIT_PAY] as string))
              )
            : undefined;
        } catch {
          this.initPayQueryParam = undefined;
        }

        this.showPendingPaymentQueryParam = false;

        this.paymentDetailIdFromExternalAuth = query[
          QUERY_PARAMS.PAYMENT_DETAILS_ID
        ] as string;
      } catch {
        // Mask error
      }

      if (showMessage) this.showMessage();

      // Remove query params
      this.removeQueryParams([
        QUERY_PARAMS.STORE_SUCCESS,
        QUERY_PARAMS.PAYMENT_SUCCESS,
        QUERY_PARAMS.ABORT,
        QUERY_PARAMS.PAYMENT_DETAILS_ID,
        QUERY_PARAMS.PAYMENT_METHOD_TYPE,
        QUERY_PARAMS.AUTO_PAY,
        QUERY_PARAMS.INIT_PAY
      ]);
    },
    removeQueryParams(params) {
      this.$router
        .replace({
          query: this.$_.omit(this.$route.query, params)
        })
        .catch(() => {
          /** Mask error */
        });
    },
    onExternalStoreSuccess() {
      this.$buefy.snackbar.open({
        message: this.$t("_sentence.payments.verify_method_success") as string,
        actionText: this.$t("_action.dismiss") as string,
        type: "is-success"
      });
    },
    onExternalStoreFail() {
      const message =
        this.paymentMethodTypeQueryParam === PaymentMethodType.GATEWAY_CARD
          ? this.$t(
              "_sentence.payments.authorization_failed_when_adding_payment_method"
            )
          : this.$t("_sentence.payments.verify_method_failed");

      this.$buefy.snackbar.open({
        message: message as string,
        actionText: this.$t("_action.dismiss") as string,
        type: "is-danger",
        indefinite: true
      });
    },
    onPaymentSuccess() {
      this.$buefy.snackbar.open({
        message: this.$t("_sentence.3d_payment_succeeds") as string,
        actionText: this.$t("_action.dismiss") as string,
        type: "is-success"
      });
    },
    onPaymentFail() {
      this.$buefy.snackbar.open({
        message: this.$t("_sentence.3d_payment_failed") as string,
        actionText: this.$t("_action.dismiss") as string,
        type: "is-danger",
        indefinite: true
      });
    },
    onExternalAborted() {
      this.$buefy.snackbar.open({
        message: this.$t("_sentence.payments.verify_method_aborted") as string,
        actionText: this.$t("_action.dismiss") as string,
        type: "is-warning"
      });
    },
    onOmniBridgeAborted() {
      this.$buefy.snackbar.open({
        message: this.$t(
          "_sentence.payments.verify_method_omni_bridge_aborted"
        ) as string,
        actionText: this.$t("_action.dismiss") as string,
        type: "is-warning"
      });
    },
    onFail() {
      if (!this.isPaymentSuccess) this.onPaymentFail();
      else this.onExternalStoreFail();
    },
    onSuccess() {
      if (this.isPaymentSuccess) this.onPaymentSuccess();
      else this.onExternalStoreSuccess();
    },
    onAborted() {
      if (this.isOmniBridgeAborted) this.onOmniBridgeAborted();
      else this.onExternalAborted();
    }
  }
});
</script>
