import UField from "@/components/app-base/UField.vue";
import { registerComponents } from "@/helpers/async";
const manualImports = ["UField"];

export default {
  install: (Vue: any) => {
    registerComponents(Vue, import.meta.glob("./*.vue"), manualImports);

    // Register manual imports
    Vue.component("u-field", UField);
  }
};
