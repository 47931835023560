import type { Component, CreateElement } from "vue";
import ClientDefaultLayout from "@/skeleton/client/loggedIn/defaultLayout.vue";
import { ClientRoutes } from "@/data/enums/router";

export default [
  {
    path: "~/:slug",
    components: {
      default: {
        name: "CustomPageWrapper",
        // `<router-view :key />` is required to prevent component re-use
        template: `<router-view v-bind="$attrs" :key='$route.params.slug' />`
      },
      layout: {
        render: (h: CreateElement) => h(ClientDefaultLayout)
      } as Component
    },
    props: true,
    children: [
      {
        path: "",
        name: ClientRoutes.CUSTOM_PAGE,
        components: {
          default: () => import("@/views/client/custom/index.vue")
        }
      }
    ]
  }
];
