import type { ActionTree, GetterTree } from "vuex";
import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ApiPathGetter } from "@/models/api";
import type { IState } from "@/store";
import type { IVaultAsset } from "@/models/vaultAssets";
import type { ILead } from "@/models/leads";
import { BrandConfigKeys } from "@/data/constants";
import _ from "@/boot/lodash";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath: (): ApiPathGetter => id => {
    const admin = `api/admin/leads/${id}/vault`;
    return { admin };
  },
  canShowNotesAndSecrets: (s, g, rs, rg) => {
    const config = rg["brand/config"];
    return _.get(
      config,
      BrandConfigKeys.CLIENT_NOTES_AND_SECRETS_ENABLED,
      false
    );
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath(payload?.id).admin,
        storeModule: DataModules.LEADS_VAULT_ASSETS,
        params: {
          ...payload.params,
          with_staged_imports: 1 // check change
        }
      },
      { root: true }
    );
  },
  create: ({ dispatch, getters }, { id, data }) => {
    return dispatch(
      "data/create",
      {
        path: getters.apiPath(id).admin,
        storeModule: DataModules.LEADS_VAULT_ASSETS,
        data
      },
      { root: true }
    );
  },
  update: ({ dispatch, getters }, { id, assetId, data }) => {
    return dispatch(
      "data/update",
      {
        path: `${getters.apiPath(id).admin}/${assetId}`,
        storeModule: DataModules.LEADS_VAULT_ASSETS,
        data
      },
      { root: true }
    );
  },
  remove: (
    { dispatch, getters },
    { id, assetId }: { id: ILead["id"]; assetId: IVaultAsset["id"] }
  ) => {
    return dispatch(
      "data/remove",
      {
        path: `${getters.apiPath(id).admin}/${assetId}`,
        storeModule: DataModules.LEADS_VAULT_ASSETS
      },
      { root: true }
    );
  },
  decrypt: ({ dispatch, getters }, { id, assetId }) => {
    return dispatch(
      "data/get",
      {
        path: `${getters.apiPath(id).admin}/${assetId}/decrypt`,
        storeModule: DataModules.LEADS_VAULT_ASSETS
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
