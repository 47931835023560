import { FilterOperators } from "@/data/table";
import { serialiseConfig } from "@/helpers/table";
import { ProvisionRequestStatusCodes as PRSC } from "@/data/enums/contractProducts";
import { AdminRoutes } from "@/data/enums/router";

const createRouteForStatus = (status: PRSC) => {
  return {
    name: AdminRoutes.LOGS_PROVISION_REQUESTS,
    query: {
      PRLP1: serialiseConfig({
        constFilters: [
          {
            key: "status.code",
            operator: FilterOperators.EQUAL,
            value: [status]
          }
        ]
      })
    }
  };
};

export const provisionRequestsLogQuickFilters = [
  {
    label: "_.all",
    to: { name: AdminRoutes.LOGS_PROVISION_REQUESTS }
  },
  {
    label: "_.completed",
    to: createRouteForStatus(PRSC.PROVISION_REQUEST_SUCCESS)
  },
  {
    label: "_.errored",
    to: createRouteForStatus(PRSC.PROVISION_REQUEST_ERROR)
  },
  {
    label: "_.cancelled",
    to: createRouteForStatus(PRSC.PROVISION_REQUEST_CANCELLED)
  },
  {
    label: "_.scheduled",
    to: createRouteForStatus(PRSC.PROVISION_REQUEST_SCHEDULED)
  },
  {
    label: "_.pending",
    to: createRouteForStatus(PRSC.PROVISION_REQUEST_PENDING)
  },
  {
    label: "_.dispatched",
    to: createRouteForStatus(PRSC.PROVISION_REQUEST_DISPATCHED)
  },
  {
    label: "_.unresolved",
    to: {
      name: AdminRoutes.LOGS_PROVISION_REQUESTS,
      query: {
        PRLP1: serialiseConfig({
          constFilters: [
            {
              key: "unresolved",
              operator: FilterOperators.EQUAL,
              value: "1"
            }
          ]
        })
      }
    }
  }
];
