import type { Component } from "vue";
import type { NavigationGuardNext, Route } from "vue-router";
import { InvoiceStatusGroups } from "@/data/enums/invoice";
import { ClientRoutes, RegexMatch } from "@/data/enums/router";
import { $bus } from "@/boot/event-bus";
import {
  InvoiceStatusParam,
  DefaultInvoicesFilters,
  InvoiceStatusFilter
} from "@/data/filters/invoice";

// Components
import ClientDefaultLayout from "@/skeleton/client/loggedIn/defaultLayout.vue";
import ClientMenu from "@/skeleton/client/loggedIn/menu.vue";
import InvoicesListing from "@/components/app/global/invoices/invoicesListing.vue";
import {
  DateCancelledSorter,
  DefaultInvoicesSorters
} from "@/data/sorters/invoices";
import _ from "@/boot/lodash";

export default [
  {
    path: "billing",
    components: {
      default: () => import("@/views/client/index.vue"),
      layout: {
        render: h =>
          h(ClientDefaultLayout, {
            scopedSlots: {
              menu: () =>
                h(ClientMenu, {
                  props: {
                    routes: require("./menu").default
                  }
                })
            }
          })
      } as Component
    },
    meta: {},
    children: [
      {
        path: "",
        name: ClientRoutes.BILLING,
        redirect: { name: ClientRoutes.ORDERS }
      },
      {
        path: "orders",
        component: {
          name: "ClientOrdersWrapper",
          render: h => h("router-view")
        } as Component,
        meta: {
          breadcrumb: {
            label: "_.orders"
          }
        },
        children: [
          {
            path: "",
            name: ClientRoutes.ORDERS,
            component: () => import("@/views/client/billing/orders/index.vue")
          },
          {
            path: `:orderId(${RegexMatch.UUID})`,
            name: ClientRoutes.ORDER,
            meta: {
              title: "_.order_id",
              breadcrumb: {
                label: "_.order"
              }
            },
            component: () =>
              import("@/views/client/billing/orders/order/index.vue"),
            redirect: { name: ClientRoutes.ORDER_SUMMARY },
            children: [
              {
                path: "overview",
                name: ClientRoutes.ORDER_SUMMARY,
                component: () =>
                  import("@/components/app/global/orders/orderSummary.vue"),
                props: true,
                meta: {
                  breadcrumb: {
                    label: "_.overview"
                  }
                },
                beforeEnter: async (
                  to: Route,
                  from: Route,
                  next: NavigationGuardNext
                ) => {
                  $bus.$emit("orderProvider::reloadData");
                  next();
                }
              },
              {
                path: "invoices",
                name: ClientRoutes.ORDER_INVOICES,
                props: true,
                component: () =>
                  import("@/components/app/global/orders/orderInvoices.vue"),
                meta: {
                  title: "_.invoices",
                  breadcrumb: {
                    label: "_.invoices"
                  }
                }
              },
              {
                path: "credit-notes",
                name: ClientRoutes.ORDER_CREDIT_NOTES,
                props: true,
                component: () =>
                  import("@/components/app/global/orders/orderCreditNotes.vue"),
                meta: {
                  title: "_.credit_notes",
                  breadcrumb: {
                    label: "_.credit_notes"
                  }
                }
              }
            ]
          }
        ]
      },
      {
        path: "invoices",
        component: () => import("@/views/client/billing/invoices/index.vue"),
        meta: {
          title: "_.invoices"
        },
        children: [
          {
            path: "",
            name: ClientRoutes.INVOICES,
            redirect: { name: ClientRoutes.INVOICES_ALL },
            component: {
              name: "ClientInvoicesListView",
              template: `<router-view :key="$route.name"/>`
            },
            children: [
              {
                path: "all",
                name: ClientRoutes.INVOICES_ALL,
                component: InvoicesListing,
                props: {
                  availableFilters: () => [
                    ...DefaultInvoicesFilters(),
                    InvoiceStatusFilter()
                  ]
                }
              },
              {
                path: "paid",
                name: ClientRoutes.INVOICES_PAID,
                component: InvoicesListing,
                props: {
                  manualFilters: () =>
                    InvoiceStatusParam(...InvoiceStatusGroups.PAID)
                },
                meta: {
                  title: "_.paid_invoices"
                }
              },
              {
                path: "unpaid",
                name: ClientRoutes.INVOICES_UNPAID,
                component: InvoicesListing,
                props: {
                  manualFilters: () =>
                    InvoiceStatusParam(...InvoiceStatusGroups.UNPAID)
                },
                meta: {
                  title: "_.unpaid_invoices"
                }
              },
              {
                path: "credited",
                name: ClientRoutes.INVOICES_CREDITED,
                component: InvoicesListing,
                props: {
                  availableSorters: () =>
                    _.orderBy(
                      [...DefaultInvoicesSorters(), DateCancelledSorter()],
                      ["label"],
                      ["asc"]
                    ),
                  manualFilters: () =>
                    InvoiceStatusParam(...InvoiceStatusGroups.CREDITED)
                },
                meta: {
                  title: "_.credited_invoices"
                }
              }
            ]
          },
          {
            path: `:invoiceId(${RegexMatch.UUID})`,
            redirect: { name: ClientRoutes.INVOICE },
            component: {
              name: "ClientInvoiceWrapper",
              // `<router-view :key />` is required to prevent component re-use
              template: `<router-view v-bind="$attrs" :key='$route.params.invoiceId' />`
            },
            meta: {
              title: "_.invoice_#",
              breadcrumb: {
                label: "_action.edit"
              }
            },
            props: true,
            children: [
              {
                path: "view",
                name: ClientRoutes.INVOICE,
                components: {
                  default: () =>
                    import("@/views/client/billing/invoices/invoice/index.vue")
                },
                props: (route: Route) => ({
                  invoiceId: route.params.invoiceId
                })
              }
            ]
          },
          {
            path: "*",
            component: () => import("@/views/error/index.vue"),
            props: { code: 404 }
          }
        ]
      },
      {
        path: "legacy-invoices",
        component: {
          name: "ClientBillingLegacyInvoices",
          template: `<router-view v-bind="$attrs" />`
        },
        meta: {
          breadcrumb: {
            label: "_.legacy_invoices"
          }
        },
        children: [
          {
            path: "",
            name: ClientRoutes.LEGACY_INVOICES,
            component: () =>
              import("@/views/client/billing/legacy-invoices/index.vue")
          },
          {
            path: `:invoiceId(${RegexMatch.UUID})`,
            name: ClientRoutes.LEGACY_INVOICE,
            components: {
              default: () =>
                import(
                  "@/views/client/billing/legacy-invoices/invoice/index.vue"
                )
            },
            meta: {
              title: "_.invoice_#",
              breadcrumb: {
                label: "_action.edit"
              }
            }
          }
        ]
      },
      {
        path: "credit-notes",
        component: {
          name: "ClientBillingCreditNotes",
          template: `<router-view />`
        },
        meta: {
          breadcrumb: {
            label: "_.credit_notes"
          }
        },
        children: [
          {
            path: "",
            name: ClientRoutes.CREDIT_NOTES,
            component: () =>
              import("@/views/client/billing/credit-notes/index.vue")
          },
          {
            path: `:invoiceId(${RegexMatch.UUID})`,
            name: ClientRoutes.CREDIT_NOTE,
            components: {
              default: () =>
                import(
                  "@/views/client/billing/credit-notes/credit-note/index.vue"
                )
            },
            props: {
              default: (route: Route) => {
                return {
                  invoiceId: route.params.invoiceId
                };
              }
            },
            meta: {
              title: "_.invoice_#",
              breadcrumb: {
                label: "_action.edit"
              }
            }
          }
        ]
      },
      {
        path: "payment-methods",
        name: ClientRoutes.PAYMENT_METHODS,
        component: () =>
          import("@/views/client/billing/payment-methods/index.vue"),
        meta: {
          title: "_.payment_methods"
        }
      },
      {
        path: "account-credit",
        name: ClientRoutes.ACCOUNT_CREDIT,
        meta: {
          title: "_.account_credit"
        },
        component: () =>
          import("@/views/client/billing/account-credit/index.vue")
      },
      {
        path: "settings",
        name: ClientRoutes.BILLING_SETTINGS,
        meta: {
          title: "_.billing_settings"
        },
        component: () => import("@/views/client/billing/settings/index.vue")
      }
    ]
  }
];
